import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { StateContext } from '../../../../../context'
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

let propiedadesEspecialidades = {
    urlData: "dic/diccionarios/especialidadescpe",
    campoEtiqueta: "Codigo,Etiqueta",
    required: false,
    name: "combo-especialidadescpe",
    disabled: false,
    label: "Especialidad",
    value: '',
    multiple: false,
    xs:4,
}

let propiedadesNumCenso = {
    urlData: "dic/diccionarios/censocpe",
    campoEtiqueta: "Censo,Nombre,Apellido1,Apellido2",
    required: false,
    name: "combo-ncenso",
    disabled: false,
    label: "Nº Censo",
    value: [],
    multiple: true,
    xs:4,
    
}

const FormularioOperacionEspecialidadesCPE = ({control, onChangeStep}) => {
    let { id, modo } = useParams();
    const {state, dispatch} = useContext(StateContext)
    const { operacion } = state
    const [personal, setPersonal] = useState({})

    const [dieta, setDieta] = useState(0)
    const [desplazamiento, setDesplazamiento] = useState(0)
    
    const [ncenso, setNCenso] = useState('')
    const [cantidades, setCantidades] = useState([
        {id:1, especialidad: 'Capataces',  cantidad: 0},
        {id:2, especialidad: 'Apuntadores',  cantidad: 0},
        {id:3, especialidad: 'Oficiales',  cantidad: 0},
        {id:4, especialidad: 'Especialistas',  cantidad: 0}
    ])

    const handleRemoveElement = (element) =>{
        const elementoAEliminar = operacion.listadoPersonal.find(el => {
            return parseInt(el.censoCPE) === parseInt(element)
        })

        dispatch({type: "REMOVE_LISTADO_ESPECIALIDADES_CPE", 
        payload: elementoAEliminar})
    }

    const renderRow = (row) => {
        let listadoARepresentar;
        if(operacion.listadoPersonal && operacion.listadoPersonal !== null){
            
            switch (row.id)
            {
                case 1:
                    listadoARepresentar = operacion.listadoPersonal.filter(element => {
                        return element.Fk_Categoria_CPE === 1
                    })
                    break;
                case 2: 
                    listadoARepresentar = operacion.listadoPersonal.filter(element => {
                        return element.Fk_Categoria_CPE === 2
                    })
                    break;
                case 3:
                    listadoARepresentar = operacion.listadoPersonal.filter(element => {
                        return (element.Fk_Categoria_CPE === 3 || element.Fk_Categoria_CPE === 4)
                    })
                    break;
                case 4:
                    listadoARepresentar = operacion.listadoPersonal.filter(element => {
                        return (element.Fk_Categoria_CPE === 5 || element.Fk_Categoria_CPE === 6)
                    })
                    break;
                default: break;
            } 
       
       return(
           listadoARepresentar.map((option) => 
            <Chip 
                avatar={parseInt(option.adscrito)>0 ? (<Avatar>A</Avatar>) : null}
                key={option.censoCPE}
                label={option.censoCPE} 
                onDelete={() => handleRemoveElement(option.censoCPE)} 
                color={parseInt(option.dieta)>0 ? "secondary" : "primary"}
            />
        )
       )
    }
    }

    const columns = [
        { field: 'especialidad', headerName: 'Especialidad', width: 110 },
        { field: 'cantidad', headerName: 'Cantidad', width: 85 },
        { field: '', headerName: '',  flex: 1,
            renderCell: (params) => (
                parseInt(params.row.cantidad) > 0 ? 
                renderRow(params.row) : ''
              ), },
    ];

    useEffect(() => {
        /*FALTA COMPROBAR SI YA EXISTE EL Nº CENSO */
        if(operacion.listadoPersonal && operacion.listadoPersonal !== null){  
            let capataces = operacion.listadoPersonal.filter(personal => personal.Fk_Categoria_CPE === 1).length
            let apuntadores = operacion.listadoPersonal.filter(personal => personal.Fk_Categoria_CPE === 2).length
            let oficiales = operacion.listadoPersonal.filter(personal => personal.Fk_Categoria_CPE === 3 || personal.Fk_Categoria_CPE === 4).length
            let especialistas = operacion.listadoPersonal.filter(personal => personal.Fk_Categoria_CPE === 5 || personal.Fk_Categoria_CPE === 6).length
                       
            setCantidades([
                {id:1, especialidad: 'Capataces',  cantidad: `${capataces}`},
                {id:2, especialidad: 'Apuntadores',  cantidad: `${apuntadores}`},
                {id:3, especialidad: 'Oficiales',  cantidad: `${oficiales}`},
                {id:4, especialidad: 'Especialistas',  cantidad: `${especialistas}`}
            ])

        }else{
            setCantidades([
                {id:1, especialidad: 'Capataces',  cantidad: 0},
                {id:2, especialidad: 'Apuntadores',  cantidad: 0},
                {id:3, especialidad: 'Oficiales',  cantidad: 0},
                {id:4, especialidad: 'Especialistas',  cantidad: 0}
            ])
        }

    },[operacion,operacion.listadoPersonal])
    //operacion, operacion.listadoPersonal

    useEffect(() => {
        if (typeof operacion !== "undefined") {             
            if(Object.keys(operacion).length > 0){                
              if(operacion.elementoActualizar && typeof operacion.elementoActualizar !== "undefined"){
                if (operacion.elementoActualizar.tipo === "cpe"){
                    actualizaCostesPedido()
                }
              }
            }
        }
        
    }, [])

    const handleClick = async () => { 
        if(propiedadesNumCenso.value && personal)  {  
            propiedadesNumCenso.value.forEach(element =>
            {
                    const getData = async () => {
                        const fetchData = async () => {
                            const calculoCostesUrl = 'operaciones/calculocostescpe'
                            const user = JSON.parse(localStorage.getItem("user"));
                            const AuthStr = "Bearer ".concat(user.access_token);
                            const SERVER_URL = `${process.env.REACT_APP_API_URL}${calculoCostesUrl}`;
                                       
                            const datos = {
                                especialidadCPE: personal.Codigo, 
                                categoriaCPE: personal.Fk_Categoria_CPE, 
                                Fk_Especialidad_CPE: personal.Id,                       
                                numCenso: element,
                                mercancia: operacion.listadoMercancias,
                                pedidoCPE: operacion.datosPedido,
                                dieta: dieta,
                                desplazamiento: desplazamiento,
                                Fk_Operacion: id
                            }
        
                            await axios
                            .put(SERVER_URL, { 
                                headers: {        
                                    "Content-Type": "application/json",
                                    Authorization: AuthStr,
                                  },                                             
                              datos,
                            })
                            .then((response) => {
                              /* MODIFICAR Id, está impidiendo añadir más elementos */
                              dispatch({type: "ADD_LISTADO_ESPECIALIDADES_CPE", 
                                payload: {                            
                                    censoCPE: element.Censo,
                                    Fk_Categoria_CPE: personal.Fk_Categoria_CPE, //personal.Id,
                                    Fk_Especialidad_CPE: personal.Id,
                                    Cod_Categoria_CPE: personal.Codigo,
                                    Id: element.Censo.Id, //personal.Id,
                                    dieta: dieta,
                                    desplazamiento: desplazamiento,
                                    costes: response.data,
                                    adscrito: element.Situacion
                                }}) 
                            })
                            .catch((error) => {
                              console.log("error " + error);
                            });   
                                          
                        };
                        fetchData();
                    };
                    getData();   
            });
            propiedadesNumCenso.value = []  
            propiedadesEspecialidades.value=null
            setDieta(0)
            setDesplazamiento(0)
            setPersonal(null)
            
        }
    }

    const handleChangeCenso = (valor) => {
        propiedadesNumCenso.value = valor
        setNCenso(valor)
    }

    const handleChangeCombo = (valor) => {
        propiedadesEspecialidades.value = valor
        setPersonal(valor)
    }

    const handleChangeDieta = (event) => {
        setDieta(event.target.checked ? 1 : 0);
    };

    const handleChangeDesplazamiento = (event) => {
        setDesplazamiento(event.target.checked ? 1 : 0);
    };

    const handleChageStep = (valor) => {
        if(onChangeStep)
            onChangeStep(valor)
    }

    const handleCleanForm = () => {
        
        dispatch({type: "UPDATE_OPERACION", payload: "cpe"})     
        if(onChangeStep)
            onChangeStep(0) 
    }

    const actualizaCostesPedido = () => {
        if(operacion.CPEEspecialidades.length > 0){
            const CPEEspecialidades = operacion.CPEEspecialidades[0].filter((elemento) => elemento.Fk_Operacion_Cpe === operacion.elementoActualizar.elemento)
                CPEEspecialidades.forEach(                
                function(a){ 

                    const fetchData = async () => {
                        
                        const fetchData2 = async () => {
                            const calculoCostesUrl = 'operaciones/calculocostescpe'
                            const user = JSON.parse(localStorage.getItem("user"));
                            const AuthStr = "Bearer ".concat(user.access_token);
                            const SERVER_URL = `${process.env.REACT_APP_API_URL}${calculoCostesUrl}`;
                                       
                            const datos = {
                                especialidadCPE: a.CodEspecialidad, 
                                categoriaCPE: a.Fk_Especialidad,                        
                                numCenso: a.Censo,
                                mercancia: operacion.listadoMercancias,
                                pedidoCPE: operacion.datosPedido,
                                dieta: a.Dieta,
                                desplazamiento: a.Desplazamiento
                            }
        
                            await axios
                            .put(SERVER_URL, { 
                                headers: {        
                                    "Content-Type": "application/json",
                                    Authorization: AuthStr,
                                  },                                             
                              datos,
                            })
                            .then((response) => {
                              
                              dispatch({type: "ADD_LISTADO_ESPECIALIDADES_CPE", 
                                payload: {   
                                    Id: a.Id,
                                    censoCPE: a.NCenso,
                                    Fk_Categoria_CPE: a.Fk_Categoria_Cpe,
                                    Fk_Especialidad_CPE: a.Fk_Especialidad,
                                    Cod_Categoria_CPE: a.CodEspecialidad,
                                    IdCenso: a.Censo.Id,
                                    dieta: a.Dieta,
                                    desplazamiento: a.Desplazamiento,  
                                    costes: response.data,
                                    adscrito: a.Censo.Situacion
                                }}) 
                                
                            })
                            .catch((error) => {
                              console.log("error " + error);
                            });          
                        };
                        fetchData2();
                    }
                    fetchData();   
                }
            )
        }
    }

return (
    <>    
    <Grid container spacing={1} className="CPE1 mt-2">
        <FormFieldComboUncontrolled propiedades={propiedadesEspecialidades} onChangeValue={handleChangeCombo} />
        <FormFieldComboUncontrolled propiedades={propiedadesNumCenso} onChangeValue={handleChangeCenso} />

        <FormControlLabel
        control={<Checkbox checked={parseInt(dieta) !== 0} onChange={handleChangeDieta} name="dieta" />}
        label="Dieta"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={parseInt(desplazamiento) !== 0}
            onChange={handleChangeDesplazamiento}
            name="desplazamiento"            
          />
        }
        label="Desplazamiento"
      />
        
    <Grid item xs={2} className="" style={{marginLeft: 3}}>
    <Button className="" variant="contained" color="primary" size="large" onClick={handleClick}>
        AÑADIR
    </Button> 
    
    </Grid> 
    <Grid item xs={1} className="" style={{marginLeft: 3}}>
    {modo === "W"  && 
        <IconButton color="primary" aria-label="actualizar costes" onClick={() => {actualizaCostesPedido()}}>
            <AutorenewIcon />
        </IconButton> 
    }       

    </Grid>
    <Grid item xs={12} className="" style={{ display: 'flex', flexWrap: 'wrap',  height: 280, marginRight:10 }}>
    <DataGrid 
        rows={cantidades} 
        columns={columns} 
        pageSize={100} 
        checkboxSelection = {false}
        hideFooter = {true}
        hideFooterPagination = {true}
        hideFooterRowCount = {true}
        hideFooterSelectedRowCount = {true}
    />
    </Grid>
        <Grid item xs={6}></Grid>
            <Grid item xs={2}>
                <Button className="float-right" variant="contained" color="primary" 
                    onClick={() => handleCleanForm() }>
                    CANCELAR
                </Button>
            </Grid>   
            <Grid item xs={2}>
                <Button className="float-right" variant="contained" color="primary" 
                    onClick={() => handleChageStep(2) }>
                    ANTERIOR
                </Button>
            </Grid>
                
            <Grid item xs={2}>
                <Button className="float-right" variant="contained" color="primary" 
                    onClick={() => handleChageStep(4) }>
                    GUARDAR
                </Button>
            </Grid>   
    </Grid> 
    </>
    )
}
export default FormularioOperacionEspecialidadesCPE;