import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { StateContext } from '../../../../../context'
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'
import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'
import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'
import FormFieldNumberUncontrolled from '../../../../../components/Common/FormFieldNumberUncontrolled'
import { getItems } from "../../../../../tools/metodos";

let propiedadesProveedores = {
    urlData: "cuentas/proveedores",
    campoEtiqueta: "Nombre_Comercial",
    required: false,
    name: "combo-proveedores",
    disabled: false,
    label: "Proveedor *",
    value: "",
    multiple: false,
    xs:3,
}

let propiedadesFacturaProveedor = {
    required: false,
    name: "factura-proveedor",
    disabled: false,
    label: "Nº Factura",
    value: "",
    xs:2,
}
  
let propiedadesFechaFacturaProveedor = {
    xs:2, 
    required: false,
    label: "Fecha Factura",
    name: "fechafacturaproveedor",
    type: "date",    
    value: ''
}

let propiedadesFacturaProveedorCoste = {
    xs:2,
    required: false,
    label: "Coste *",
    name: "costefacturaproveedor",
    type: "number",    
    value: ''
}

let propiedadesFacturaProveedorCantidad = {
    xs:2, 
    required: false,
    label: "Cantidad *",
    name: "cantidadfacturaproveedor",
    type: "number",    
    value: ''
}

let propiedadesDescripcionFacturaProveedor = {
    required: false,
    name: "descripcion-factura-proveedor",
    disabled: false,
    label: "Descripción *",
    value: "",
    xs:11,
    lineas: 2
}
  
const FormularioOperacionProveedor = () => { 
    let { id } = useParams(); 
    const {state, dispatch} = useContext(StateContext)
    const { operacion } = state

    const [listadoRecursosProveedor, setListadoRecursosProveedor] = useState([])
    const [refrescar, setRefrescar] = useState(false)

    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'idProveedor', headerName: 'idProveedor', hide: true },
        { field: 'item', headerName: 'Item nº', width: 90 },
        { field: 'descripcion', headerName: 'Descripción', width: 300 },
        { field: 'cantidad', headerName: 'Cantidad', width: 90 },
        { field: 'tarifa', headerName: 'Tarifa', width: 100 },
        { field: '', headerName: '', width: 120, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
              ), },
    ];

    const handleRemoveElement = (element) =>{
        const elementoAEliminar = listadoRecursosProveedor.find(el => {
            return el.id === element.id
        })

        dispatch({type: "REMOVE_LISTADO_PROVEEDOR", 
        payload: elementoAEliminar})
    }

    const resetForm = () => {
        propiedadesProveedores.value=""
        propiedadesFacturaProveedor.value=""
        propiedadesFechaFacturaProveedor.value=""
        propiedadesFacturaProveedorCoste.value=""
        propiedadesFacturaProveedorCantidad.value=""
        propiedadesDescripcionFacturaProveedor.value=""
        setListadoRecursosProveedor([])
    }

    useEffect(() => {
        typeof operacion.listadoProveedor === 'undefined' || 
        operacion.listadoProveedor === null ?  
        resetForm(): 
        setListadoRecursosProveedor(operacion.listadoProveedor)
    }, [operacion.listadoProveedor])

    const handleFormErrors = () => {
        let errores = 0; 
        if(propiedadesProveedores.value.length === 0) errores++;
        if(propiedadesFacturaProveedorCantidad.value.length === 0) errores++;
        if(propiedadesFacturaProveedorCoste.value.length === 0) errores++;
        if(propiedadesDescripcionFacturaProveedor.value.length === 0) errores++;
        //if(listadoRecursosProveedor.length === 0) errores++;
        console.log(errores)
        return errores === 0;
    }

    
    const handleClick = () => {
        if( handleFormErrors() ){
            dispatch({type: "ADD_LISTADO_PROVEEDOR", 
            payload: {
                id: Date.now(), 
                idProveedor: propiedadesProveedores.value.Id,
                item: listadoRecursosProveedor.length+1,
                descripcion: propiedadesDescripcionFacturaProveedor.value,
                cantidad: propiedadesFacturaProveedorCantidad.value,
                tarifa: propiedadesFacturaProveedorCoste.value,
                facturaProveedor: propiedadesFacturaProveedor.value,
                fechaFacturaProveedor: propiedadesFechaFacturaProveedor.value
            }})

            propiedadesProveedores.value = ''
            propiedadesFacturaProveedor.value =''
            propiedadesFechaFacturaProveedor.value =''
            propiedadesFacturaProveedorCantidad.value =''
            propiedadesFacturaProveedorCoste.value = ''
            propiedadesDescripcionFacturaProveedor.value = ''

            setRefrescar(!refrescar)
        }
        else{
            alert("Debe añadir los campos obligatorios")
        }
    }

    const handleChangeCombo = (valor) => {
        propiedadesProveedores.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFacturaProveedor = (valor) => {
        //AVISAR SI EXISTE EL PARTE
        propiedadesFacturaProveedor.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFechaFacturaProveedor = (valor) => {
        propiedadesFechaFacturaProveedor.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFacturaProveedorCantidad = (valor) => {
        propiedadesFacturaProveedorCantidad.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFacturaProveedorCoste = (valor) => {
        propiedadesFacturaProveedorCoste.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFacturaProveedorDescripcion = (valor) => {
        propiedadesDescripcionFacturaProveedor.value = valor
        setRefrescar(!refrescar)
    }

    const handleCleanForm = () => {
        dispatch({type: "CLEAR_LISTADO_PROVEEDOR"})     
    }


    const handleSubmitForm = async () => {

        if(listadoRecursosProveedor.length === 0)
        {
            alert("Debe introducir al menos una factura de proveedor")
            return
        }


        let continuar = true
        //AVISAR SI EXISTE EL PARTE
        const SERVER_URL_PARTES = `${process.env.REACT_APP_API_URL}operaciones/existefacturaproveedor?facturaproveedor=${listadoRecursosProveedor[0].facturaProveedor}`
 
        let items = await getItems(SERVER_URL_PARTES);

        if(isNaN(items)){
            let text = "Este número de parte ya está en el sistema en las siguientes operaciones:\n" + items + "\n¿Desea Continuar?";
            if (window.confirm(text) == true) {
                continuar = true;
            } else {
                continuar = false
            }
        }
        if (!continuar) return;


        
        let datosFormulario = {};

        datosFormulario.listadoProveedor = operacion.listadoProveedor
        datosFormulario.tipoForm = "proveedor"

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/${id}`


        await axios
        .put(SERVER_URL, {
        headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
        },
        datosFormulario,
        })
        .then((response) => {       
        
        if(!isNaN(response.data)){
            if(parseInt(response.data) === parseInt(id)){      
            operacion.actualizarDetalle = true                 
                dispatch({type: "UPDATE_OPERACION", 
                payload: "proveedor",
                })
                alert("Parte guardado correctamente")
                //setOpenAlert(true)
            }
        }
        })
        .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
        });
        
    }


    return(
    <>
    <Grid container spacing={1} className="PROVEEDOR mt-2 borderFormulario">
    
    <FormFieldComboUncontrolled propiedades={propiedadesProveedores} onChangeValue={handleChangeCombo} />
    <FormFieldTextUncontrolled propiedades={propiedadesFacturaProveedor} onChangeValue={handleChangeFacturaProveedor}/>
    <FormFieldDateUncontrolled propiedades={propiedadesFechaFacturaProveedor} onChangeValue={handleChangeFechaFacturaProveedor}/>
    <FormFieldNumberUncontrolled propiedades={propiedadesFacturaProveedorCantidad} onChangeValue={handleChangeFacturaProveedorCantidad}/>
    <FormFieldNumberUncontrolled propiedades={propiedadesFacturaProveedorCoste} onChangeValue={handleChangeFacturaProveedorCoste}/>
    <FormFieldTextUncontrolled propiedades={propiedadesDescripcionFacturaProveedor} onChangeValue={handleChangeFacturaProveedorDescripcion}/>
    
    <Grid item xs={1} className="" >
        <Button className="" variant="contained" color="primary" size="large" onClick={handleClick}>
            AÑADIR
        </Button>   
    </Grid>

    
    <Grid item xs={12} className="PROVEEDOR mt-2" style={{height: 300}}>
        <DataGrid 
            rows={listadoRecursosProveedor} 
            columns={columns} 
            pageSize={100} 
            checkboxSelection = {false}
            hideFooter = {true}
            hideFooterPagination = {true}
            hideFooterRowCount = {true}
            hideFooterSelectedRowCount = {true}
        />
    </Grid>
    
    </Grid>

    <Grid container  spacing={1} className="">
        <Grid item xs={8}></Grid>
         <Grid item xs={2}>
            <Button className="float-right" variant="contained" color="primary" 
                onClick={() => handleCleanForm() }>
                CANCELAR
            </Button>
        </Grid>   

         <Grid item xs={2}>
            <Button className="float-right" variant="contained" color="primary" 
                disabled = {listadoRecursosProveedor.length === 0}
                onClick={() => handleSubmitForm()}>
                GUARDAR
            </Button>
        </Grid> 
        </Grid>  


    </>
    )
}

export default FormularioOperacionProveedor;