import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";

const SearchInput = ({ onClick }) => {
  const [texto, setTexto] = useState("");
  const estilosCSS = {
    height: "25px",
    marginTop: "3px",
  };
  const estiloBoton = { height: "20px", marginTop: "1px", marginRight: "2px" };
  const estiloInput = { borderColor: "transparent !important" };

  const handleClick = () => {
    if (typeof onClick !== "undefined") {
      onClick(texto);
    }
  };

  const actualizaTexto = async (valor) => {
    setTexto(valor);
  };

  return (
    <div
      style={estilosCSS}
      className="ui action input float-right bg-white mr-2 rounded"
    >
      <input
        type="text"
        style={estiloInput}
        placeholder="Buscar..."
        onChange={(e) => actualizaTexto(e.target.value)}
      />
      <button
        style={estiloBoton}
        className="ui icon button bg-comercial bg-white border border-white rounded-left rounded-right"
        onClick={() => handleClick()}
      >
        <i aria-hidden="true" className="search icon text-white"></i>
      </button>
    </div>
  );
};
export default SearchInput;
