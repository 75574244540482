import React,  { useState, useEffect }  from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {usePlanificacion} from './planificacion-context'

import FichaEntidad from '../../../../../components/Common/FichaEntidad'

  const FichaPlanificacion = () => {   
    let { id } = useParams();
    const { datosPlanificacion, setActualizarCargaPlanificacion } = usePlanificacion();  
    const [campos, setCampos] = useState([])  

    const classText= "Texto14"

    useEffect(() => {

      setActualizarCargaPlanificacion(true)
      return () => {
        setActualizarCargaPlanificacion(false)
      }

    }, [])

    useEffect(() => {
      
      if(Object.keys(datosPlanificacion).length > 0){
        const clientes = datosPlanificacion.CuentasCliente.map(a => a.Razon_Social).join(", ");
        console.log(datosPlanificacion)
        let pedidoCliente = "";
        if (datosPlanificacion.PedidoCliente.length >=1){
          pedidoCliente = datosPlanificacion.PedidoCliente[0].Codigo;
        }

        let cotizacion = "";
        if (datosPlanificacion.Cotizacion.length >=1){
          cotizacion = datosPlanificacion.Cotizacion[0].Codigo;
        }
        
        setCampos([
          {
            xs:4,
            classsLabel: "",
            label: "Fecha de operativa:",
            value: convertDate(datosPlanificacion.Fecha_Operativa),
            classText: classText
          },
          {
            xs:2,
            classsLabel: "",
            label: "Hora inicio",
            value: datosPlanificacion.ETA,
            classText: classText
          },
          {
            xs:2,
            classsLabel: "",
            label: "Nota de pedido:",
            value: pedidoCliente,
            classText: classText
          },
          {
            xs:2,
            classsLabel: "",
            label: "Cotización:",
            value: cotizacion,
            classText: classText
          },
          {
            xs:2,
            classsLabel: "",
            label: "Carpeta poseidón:",
            value: datosPlanificacion.codCarpetaPoseidon,
            classText: classText
          },
          {
            xs:12,
            classsLabel: "",
            label: "",
            value: "",
            classText: classText
          },
          {
            xs:4,
            classsLabel: "",
            label: "Cliente(s):",
            value: clientes,
            classText: classText
          },
          {
            xs:4,
            classsLabel: "",
            label: "Contacto:",
            value: datosPlanificacion.Contacto ? datosPlanificacion.Contacto.Nombre : "",
            classText: classText
          },     
          {
            xs:4,
            classsLabel: "",
            label: "Referencia de cliente:",
            value: datosPlanificacion.Referencia_Cliente,
            classText: classText
          },
          {
            xs:12,
            classsLabel: "",
            label: "",
            value: "",
            classText: classText
          },
          {
            xs:4,
            classsLabel: "",
            label: "Procedencia:",
            value: datosPlanificacion.Procedencia ? datosPlanificacion.Procedencia.Etiqueta : "",
            classText: classText
          }, 
          {
            xs:4,
            classsLabel: "",
            label: "Destino:",
            value: datosPlanificacion.Destino ? datosPlanificacion.Destino.Etiqueta : "",
            classText: classText
          },
          {
            xs:12,
            classsLabel: "",
            label: "",
            value: "",
            classText: classText
          },
          {
            xs:4,
            classsLabel: "",
            label: "Localización:",
            value: datosPlanificacion.Localizacion ? datosPlanificacion.Localizacion.Etiqueta : "",
            classText: classText
          }, 
          {
            xs:4,
            classsLabel: "",
            label: "Buque:",
            value: datosPlanificacion.Buque ? datosPlanificacion.Buque.Nombre : "",
            classText: classText
          }, 
          {
            xs:4,
            classsLabel: "",
            label: "Operativa:",
            value: datosPlanificacion.Operativa ? datosPlanificacion.Operativa : "",
            classText: classText
          }, 
          {
            xs:4,
            classsLabel: "",
            label: "Localización 2:",
            value: datosPlanificacion.Localizacion2 ? datosPlanificacion.Localizacion2.Etiqueta : "",
            classText: classText
          }, 
          {
            xs:4,
            classsLabel: "",
            label: "Buque 2:",
            value: datosPlanificacion.Buque2 ? datosPlanificacion.Buque2.Nombre : "",
            classText: classText
          }, 
          {
            xs:12,
            classsLabel: "",
            label: "",
            value: "",
            classText: classText
          },
          {
            xs:12,
            classsLabel: "",
            label: "Descripción:",
            value: datosPlanificacion.Descripcion,
            classText: classText
          },
          {
            xs:12,
            classsLabel: "",
            label: "Archivos adjuntos:",
            value: datosPlanificacion.ficheros,
            classText: classText,
            type: "file"
          },
        ])

      }

    },[datosPlanificacion])  


    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }
    

    return(
      <FichaEntidad
      campos = {campos}
      urlClick = {`/operativas/planificaciones/planificacion/${id}/W`}
      />
    )  
    
  };
  export default FichaPlanificacion;  