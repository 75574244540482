import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';

export default function PINOperacion({Formulario, Listado, widthF, widthL}) {  

  const [actualizar, setActualizar] = useState(0)
  const actualizarListado = ( list ) => {   
    setActualizar(list)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={widthF}> <Formulario actualizaListado={actualizarListado} /> </Grid>
      <Grid item xs={widthL}> <Listado actualizarListado={actualizar} /> </Grid>
    </Grid>
       
  );

}