import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from '../../../../../hooks/useAxios'
import FichaEntidad from '../../../../../components/Common/FichaEntidad'


const FichaCuentaIdentificacion = () => {    
    let { id } = useParams();    

    const { response/*, loading, error*/ } = useAxios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}cuentas/${id}`,
        /*
        headers: { // no need to stringify
          accept: '* / *'
        },        
        data: {  // no need to stringify
            userId: 1,
            id: 19392,
            title: 'title',
            body: 'Sample text',
        },
        */
    });

    const [campos, setCampos] = useState([])      
    
    const classText= "Texto14"   

    useEffect(() => {  
        
        if(!response) {return}
        if(Object.keys(response).length > 0){
            /*
            let nombreEmpresaMatriz = "";
            if (Array.isArray(response.EmpresaMatriz) && response.EmpresaMatriz.length > 0) {
                nombreEmpresaMatriz = response.EmpresaMatriz[0].Razon_Social;
            }
            */
        
          setCampos([
            {
                classsLabel: "",
                label: "Cliente:",
                value: response.CodigoCliente,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Proveedor:",
                value: response.CodigoProveedor,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Acreedor:",
                value: response.CodigoAcreedor,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Situación:",
                value: response.Estado.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Tipología:",
                value: response.Tipologias,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Sector/Actividad:",
                value: response.Sectores,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "CIF:",
                value: response.CIF,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "VAT:",
                value: response.Vat_Number,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Cod. Agente Poseidón:",
                value: response.CodAgente,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Nombre Comercial:",
                value: response.Nombre_Comercial,
                classText: classText,
                xs:8
            },
            {
                classsLabel: "",
                label: "Teléfono:",
                value: response.Telefono,
                classText: classText,
                link:`tel:${response.Telefono}`,
                xs:4
            },
            {
                classsLabel: "",
                label: "Razón Social:",
                value: response.Razon_Social,
                classText: classText,
                xs:8
            },
            {
                classsLabel: "",
                label: "Email:",
                value: response.Email,
                classText: classText,
                link:`mailto:${response.Email}`,
                xs:4
            },
            /*
            {
                classsLabel: "",
                label: "Grupo:",
                value: nombreEmpresaMatriz,
                classText: classText,
                xs:4
            },
            */
            {
                classsLabel: "",
                label: "Grupo:",
                value: response.Grupo,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "WEB:",
                value: response.Web,
                classText: classText,
                link:`${response.Web}`,
                xs:4
            },
            {
                classsLabel: "",
                label: "Estado:",
                value: response.Activo === 1 ? "Activa" : "Inactiva",
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: response.Observaciones,
                classText: classText,
                xs:12
            },
          ])
        }  

    },[response])  

    return(
        <>
        <h6 className="texto-comercial" id="title">
          Identificación
        </h6>
        <FichaEntidad
        campos = {campos}
        urlClick = {`/crm/cuentas/cuenta/${id}/W`}
        />
        </>
      )
}
export default FichaCuentaIdentificacion;

/*
    useEffect(() => {                
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();        
        
        const fetchData = async () => {  
            try{
                const respCuenta = await axios(`${process.env.REACT_APP_API_URL}cuentas/${id}`, { cancelToken: source.token });    
                setCuenta(respCuenta.data);      
            }catch (error) {                
                if (axios.isCancel(error)) {                    
                    //console.log("cancelled", error);
                } else {
                    throw error;
                }
            } 
        };
        fetchData();  
        
        
        return () => { source.cancel(); }  
                

    }, [id]);
    */