import React from "react";
import Nav from "../../components/Common/Header";
import Menuizq from "../../components/Common/Menuizq";
import Menuder from "../../components/Common/Menuder";
import { AlmacenamientoProvider } from "../views/Main/Produccion/Operativas/almacenamiento-context";
import MaintenanceModeComponent from "../../components/Common/MaintenanceModeComponent";

export default ({ children }) => {  
  const maintenanceMode = children?.props?.children[0]?.key === 'true'

  return (
    <>
    {maintenanceMode && (
      <MaintenanceModeComponent />
    )}
    {!maintenanceMode && (
      <AlmacenamientoProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children}
        <Menuder />
      </div>
    </div>
    </AlmacenamientoProvider>
    )}
    </>
  );    
};

/*
return (
    <AlmacenamientoProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children}
        <Menuder />
      </div>
      
    </div>
    </AlmacenamientoProvider>
  );
  */
