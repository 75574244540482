const botoneraMantenimiento = [
     {
       etiqueta: "Editar",
       url: '/servicio/maquinarias/maquinaria/filaSeleccionada/W',
       activo: false,
     }, 


     {
       etiqueta: "Crear",
       url: '/servicio/maquinarias/maquinaria',
       activo: true,
     }, 
   ];

   export {botoneraMantenimiento};

   const botoneraReparacion = [
     {
       etiqueta: "Editar",
       url: '/servicio/instalaciones/instalacion/filaSeleccionada/W',
       activo: false,
     }, 
     {
       etiqueta: "Crear",
       url: '/servicio/instalaciones/instalacion',
       activo: true,
     }, 
   ];

   export {botoneraReparacion};

   const botoneraMaquinaria = [
    {
      etiqueta: "Editar",
      url: '/servicio/maquinarias/fichamaquinaria/filaSeleccionada/W',
      activo: false,
    }, 


    {
      etiqueta: "Crear",
      url: '/servicio/maquinarias/fichamaquinaria',
      activo: true,
    }, 
  ];
  export {botoneraMaquinaria};