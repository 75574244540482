import React, { useState }  from "react";
import SearchBar from "../../../../../components/Common/SearchBar";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import {COLUMNS_PEDIDOS} from './PedidosConf'

const Pedidos = () => {
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [tipoPedido, setTipoPedido]=useState(localStorage.getItem('idTipoPedido') || "Abiertas");    
  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }
  const BOTONERA_ACCIONES = [
    { etiqueta: "Crear Nota de Pedido", url: "/ventas/pedidos/pedido", activo: true },
    {
      etiqueta: "Editar Nota de Pedido",
      url: "/ventas/pedidos/pedido/filaSeleccionada/W",
      activo: false,
    },
  ];

  const handleOperacion = (idO) =>{  
    localStorage.setItem('idTipoPedido', idO);     
    setTipoPedido(idO)
  }  

  const filtroToolbar = true
  const filtroToolbarOpciones = ['Pdtes. Planificar', 'Planificados', 'Todos']
  const urlDatos = tipoPedido === 'Pdtes. Planificar' ? "pedidoclientes?estado=1" : (tipoPedido === 'Planificados' ? "pedidoclientes?estado=2" : "pedidoclientes");
  const urlDoubleClick = "/ventas/pedidos/pedido/"
  
  return (           
    <div className="col-8 col-main-home ">            
      <SearchBar
        titulo="Ventas"
        claseCabecera = "bg-comercial"
        buscar={false}
        imagenCabecera={"4-2-1-pedidos_clientes.png"}
      />   
      
      <div className="bg-cuerpo p-10px">
        <div className="bg-white p-10px">
          <div className="p-1 bg-white mb-3">
            <h6 className="texto-comercial float-left" id="title">
            Pedidos de cliente
            </h6>
          </div>
          <hr className="m-0 bg-comercial mb-1" />
          <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
           
           <CuerpoTablaPiezas
              columnas = {COLUMNS_PEDIDOS}
              urlDatos = {urlDatos}
              urlDoubleClick = {urlDoubleClick}
              columnId = "Id"
              getFilaSeleccionada={marcarFila}
              filtroToolbar={filtroToolbar}
              filtroToolbarOpciones = {filtroToolbarOpciones}
              filtroToolbarValor = {tipoPedido}
              filtroToolbarChangeValor={handleOperacion}
              filtroEjercicio = {true}
            /> 
          </div>
        </div>

        <div className="bg-cuerpo">
          <div className="bg-white">
            <TableAcciones                
              filaSeleccionada={filaSeleccionada}                
              botonaccion={BOTONERA_ACCIONES}
            />            
          </div>
        </div>

      </div>
        
    </div>   
  );
};

export default Pedidos;