import React, { useState, useEffect, useContext  } from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { useParams } from "react-router-dom";
import DetalleOperacionCPE from './DetalleOperacionCPE'
import DetalleOperacionGesport from './DetalleOperacionGesport'
import DetalleOperacionProveedor from './DetalleOperacionProveedor'
import { StateContext } from '../../../../../context'

import Button from '@material-ui/core/Button';


const DetalleOperacionMano = () => { 
    let { id } = useParams(); 
    const {state } = useContext(StateContext)
    const { operacion } = state
    const [isLoading, setIsLoading] = useState(false);
    const [oper, setOper] = useState([])

    useEffect(() => {
      setIsLoading(true)      
      setOper(operacion)
      setIsLoading(false)
    },[operacion]) 

    useEffect(() => {
      
        const abortController = new AbortController();
        setIsLoading(true)
        const fetchData = async () => {  
          const respPedido = await axios(
              `${process.env.REACT_APP_API_URL}operaciones/${id}`
          );
            
          if (Object.keys(respPedido.data).length > 0) {
            setOper(respPedido.data)
          }
          setIsLoading(false)
          return () => abortController.abort();
        }
        fetchData(); 
        
    }, [])

    const handlePrintDetalles = async () => {
      //console.log(manos)
      //console.log(datosJornadas)
      //let datos=[];

      let urlGenerarFicha = "operaciones/generapdfdetalle/"

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = process.env.REACT_APP_API_URL + urlGenerarFicha + id;
      console.log(SERVER_URL)        
      const res = await axios.get(
          SERVER_URL 
          /*, 
          { headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
          }              
          },
          {params: { estado: ''}} 
          */
          );
      console.log(res.data)
      window.open(res.data, "_blank")
  }
    
    return (
        <>
        {!isLoading && (   
          <>   
            <div className="col-12 d-flex overflow-auto justify-content-center">
              <span className="col-10 float-left"></span>
              <span className="col-2 float-right">
                  <Button onClick={() => handlePrintDetalles()} color="primary">
                          IMPRIMIR
                  </Button>
                  
              </span>            
            </div>
            <DetalleOperacionCPE operacion={oper}/>
            <DetalleOperacionGesport operacion={oper}/>
            <DetalleOperacionProveedor operacion={oper}/>
          </>    
        )}

        {isLoading && 
        <div>
              <LinearProgress />
        </div>
      }
        </>
    )
}

export default DetalleOperacionMano;