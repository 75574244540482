import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";

import { CircularProgress } from '@material-ui/core';
import FormularioOperaciones from "./FormularioOperaciones";

import MigasDePan from "../../../../../components/Common/MigasDePan";

const PendientesFormulario = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
      
  },[])

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar Operacion" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/finanzas/tesoreria` :
          modo === "R" ? 
            `/finanzas/tesoreria/PendientesFormulario/${id}/W` : 
            `/finanzas/tesoreria/PendientesFormulario/${id}/R`,
          activo: true,
        },
        {
          etiqueta: "Guardar",
          form:'formulario-utilidades-Operaciones',
          activo: modo === "R" ? false : true,
        },
    ];

    const urlsMigasdepan = [
      {titulo: "Finanzas", colorTexto: "texto-gestion", urlTo: "/finanzas/tesoreria"},
      {titulo: "Tesorería", colorTexto: "texto-gestion", urlTo: `/finanzas/tesoreria/PendientesFormulario/${id}/${modo}`},
    ]

    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Finanzas"
              claseCabecera = "bg-gestion"
              buscar={false}
              imagenCabecera={"6-2-1-tesoreria.png"}
            />
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                  <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={""}/>            
                    {/*
                    <div className="p-1 bg-white mb-4">
                      <h6 className="texto-gestion float-left" id="title">
                        Operación
                      </h6>
                    </div>
                    <hr className="m-0 bg-gestion mb-4" />
                    */}
    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                        <FormularioOperaciones tipoForm = {"pendientes"}/>     
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
          </div>
        </>
      );

}
export default PendientesFormulario;