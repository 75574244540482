import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
        padding: theme.spacing(1),
        margin: '10px',        
        backgroundColor: '#FFF',
    },    
    padding16: {
      padding: theme.spacing(1),      
    },  
    padding16Last: {
      padding: theme.spacing(1),    
      textAlign: 'end',  
    }, 
    ventasnetas: {
      fontSize: 18,
      fontFamily: 'Open Sans',
      fontWeight: 300,
      color: '#505050'
    },  
    ventasnetasimporte: {
      fontSize: 18,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      color: '#90b9e8',
      marginLeft: '10px',
    },  
}));

const VentasActuales = ({totalVentas, porcentajeTotalVentas, portcentajeObjetivoVentas, fechas}) => {  
    const classes = useStyles();
    const DAYS_PER_YEAR = 365;
    const fechasSeparadas = fechas.split("&")
    const fechasReSeparadas = fechasSeparadas.map((fecha) => fecha.split("=")[1])
    
    const numberFormatter = Intl.NumberFormat('de-DE');
    const ventas = numberFormatter.format(totalVentas);
    const numeroDeDiasAProyectar = numeroDiasProyeccion(fechasReSeparadas)
    const proyeccion = numberFormatter.format(totalVentas / numeroDeDiasAProyectar * DAYS_PER_YEAR );
    //console.log(totalVentas)
    return (
      <div className={classes.root}>
        <div className="row">
          <div className="col-6">
            <div className={classes.padding16}>
              <Typography className={classes.ventasnetas} gutterBottom>Ventas netas <span className={classes.ventasnetasimporte}>{ventas} €</span> <span className={classes.ventasnetasimporte}>({porcentajeTotalVentas}%)</span></Typography>
            </div>
          </div>
          <div className="col-6">
            <div className={classes.padding16Last}>
              <Typography className={classes.ventasnetas} gutterBottom>Proyección anual <span className={classes.ventasnetasimporte}>{proyeccion} €</span></Typography>
            </div>
          </div>
        </div>
      </div>
    );
}
export default VentasActuales;

const numeroDiasProyeccion = fechasReSeparadas => {
  const fechaInicio = new Date(fechasReSeparadas[0]);
  const fechaFin = new Date(fechasReSeparadas[1]);
  const diferenciaFechas = fechaFin - fechaInicio;
  const DIA_EN_MILISEGUNDOS = 1000 * 60 * 60 * 24;
  const numeroDias = Math.ceil(((diferenciaFechas / DIA_EN_MILISEGUNDOS) +1 ));
  return numeroDias
}