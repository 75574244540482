import React from "react";
import {TEXT_LABELS} from "../../../../../configuraciones/conf"

const classDiv3 = "col-3 mt-2 mb-2"

const OPTIONS_PROYECTOS = {
    filterType: 'checkbox',
    print: false,
    download: false,
    pagination: false,
    selectableRows: 'single',
    expandableRows: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    elevation:0,
    textLabels: TEXT_LABELS,
    
};

const CAMPOS_PROYECTOS =[
  {
    urlData: "dic/diccionarios/proyectostipos",
    campoEtiqueta: "Etiqueta",
    required: true,
    label: "Tipo",
    name: "tipo",
    disabled: false,
    type: "combo",           
    multiple:false,   
    value: '',
    xs:4
  },    
  {    
    required: true,
    label: "Nombre:",
    name: "nombre",
    type: "text",    
    disabled: false, 
    value: '',                
    xs:4
  },   
  {
    urlData: "cuentas/clientes",
    campoEtiqueta: "Nombre_Comercial",
    required: false,
    label: "Cliente",
    name: "nombreComercial",
    disabled: false,
    type: "combo",           
    multiple:true,   
    value: [],
    xs:4,
   //onChangeValue: handleChangeCuenta
  },        
  {
    urlData: "contactos",
    campoEtiqueta: "Nombre",
    required: false,
    label: "Contacto",
    name: "contacto",
    disabled: false,
    type: "combo",           
    multiple:true,   
    value: [],
    xs:4
  },
  {
    urlData: "dic/diccionarios/listadousuarios",
    campoEtiqueta: "nombre",
    campoId:"id",
    required: false,
    label: "Empleado",
    name: "empleado",
    disabled: false,
    type: "combo",           
    multiple:true,   
    value: [],
    xs:4
  },
  {
    urlData: "dic/diccionarios/listadousuarios",
    campoEtiqueta: "nombre",
    required: false,
    label: "Supervisor",
    name: "supervisor",
    disabled: false,
    type: "combo",           
    multiple:false,   
    value: '',
    xs:4
  },
  /*
  {
    urlData: "oportunidads/listadooportunidades",
    campoEtiqueta: "Codigo",
    required: true,
    label: "Oportunidad",
    name: "oportunidad",
    disabled: true,
    type: "combo",           
    multiple:false,   
    value: '',
    xs:4
  },  
  {
    urlData: "actividads/listadoactividades",
    campoEtiqueta: "Codigo",
    required: true,
    label: "Actividad",
    name: "actividad",
    disabled: true,
    type: "combo",           
    multiple:true,   
    value: [],
    xs:4
  },
  */
  {
      urlData: "dic/diccionarios/listadovisibilidades",
      campoEtiqueta: "Etiqueta",
      required: false,
      label: "Visibilidad",
      name: "visibilidad",
      type: "combo",  
      value: '',
      disabled: false,
      multiple:false,
      xs:4
  },            
  {
    required: false,
    label: "Fecha inicio",
    name: "fechainicio",
    type: "date",  
    disabled: false,
    value: '',
    xs:4
  },
  {
      required: false,
      label: "Fecha estimada cierre",
      name: "fechacierre",
      type: "date",  
      value: '',
      disabled : false,
      xs:4
  },
  {
      urlData: "dic/diccionarios/proyectosestados",
      campoEtiqueta: "Etiqueta",
      required: false,
      label: "Estado",
      name: "estado",
      disabled: false,
      type: "combo",   
      value: '',
      multiple:false,
      xs:4
  },
  {
      urlData: "dic/diccionarios/proyectosresultados",
      campoEtiqueta: "Etiqueta",
      required: false,
      label: "Resultado",
      name: "resultado",
      disabled: false,
      type: "combo",   
      value: '',
      multiple:false,
      xs:4
  },
  {
      required: false,
      label: "Previsión de gastos",
      name: "previsiogastos",
      type: "number",              
      value: '',
      disabled:false,
      xs:4
  },
  {
      required: false,
      label: "Presupuesto asignado",
      name: "presupuesto",
      type: "switch",              
      value: 0,
      disabled: false,
      xs:4
  },
  {
      required: false,
      label: "Proyecto recurrente",
      name: "recurrente",
      type: "switch",              
      value: 0,
      disabled: false,
      xs:4
  },
  {
      required: false,
      label: "Descripción",
      name: "descripcion",
      type: "textArea",
      lineas: 4,                
      value: '',
      disabled: false,
      xs:6
  },
  {
      required: false,
      label: "Notas",
      name: "notas",
      type: "textArea",
      lineas: 4,  
      value: '',
      disabled: false,                
      xs:6
  }, 
  {
      classButton: classDiv3,
      required: false,
      label: "",
      name: "ficherosadjuntos",
      type: "filesupload",   
      tabla: "mtms_proyectos_adjuntos",
      disabled: false,
      xs:5
  },            
  {
      required: false,
      label: "",
      name: "ficherosremotos",
      type: "files", 
      ficheros: [],    
      tabla: "mtms_proyectos_adjuntos",
      disabled: false,
      xs:4
  }, 
]

export {CAMPOS_PROYECTOS};

const COLUMNS_PROYECTOS = [
    {
      name: "Id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
       }
    },
    {
      name: "Codigo",
      label: "Código",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <a href={`/crm/proyectos/proyecto/${tableMeta.rowData[0]}/R`}>{value}</a>
        );
        }
     }
    },
     {
        name: "Nombre",
        label: "Nombre",
        options: {
         filter: true,
         sort: true,
        }
       },
      {
       name: "Descripcion",
       label: "Descripcion",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "Fecha_Inicio",
        label: "Fecha inicio",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
      {
        name: "Fecha_Cierre",
        label: "Fecha Cierre",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
      
      {
       name: "Estado",
       label: "Estado",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
       name: "Prevision",
       label: "Prevision",
       options: {
        filter: true,
        sort: true,
       }
      },      
      
      {
        name: "Presupuesto",
        label: "Presupuesto",
        options: {
         filter: true,
         sort: true,
        }   
      },
      {
        name: "Visibilidad",
        label: "Visibilidad",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
      {
        name: "Supervisor",
        label: "Supervisor",
        options: {
         filter: true,
         sort: true,
        }   
      },
      {
        name: "AreaNegocio",
        label: "Area de negocio",
        options: {
         filter: true,
         sort: true,
        }   
      }
      
      

];
  
export {COLUMNS_PROYECTOS,OPTIONS_PROYECTOS};