import React, { useState } from "react";
import Tablero from "./Tablero";
import Utilidades from "./Utilidades";
import Configuracion from "./Configuracion";
import Pasos from "../../../../../components/Common/Pasos";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import { botoneraCajas, botoneraCategoria, botoneraConceptos, botoneraInstrumentos } from './BotonesConf'
import { botoneraUtilidadesOperaciones, botoneraUtilidadesPendientes } from "./BotonesConf"
import SearchBar from "../../../../../components/Common/SearchBar";
import Cobros from "./Cobros";
import Pagos from "./Pagos";

const GestionTesoreria = () => {

  const [idOperacion, setIdOperacion] = useState(parseInt(localStorage.getItem('idOpTesoreria')) || 0);
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [idSubOpUtilidades,setIdSubOpUtilidades] = useState(localStorage.getItem('idSubOpUtilidades') || "Vista Diaria");
  const [idSubOpConfiguracionTesoreria, setIdSubOpConfiguracionTesoreria] = useState(localStorage.getItem('idSubOpConfiguracionTesoreria') || "Cajas");

  const handleChange = (idO)=> {
    setIdSubOpUtilidades(idO)
  }

  const verTabla = (idOperacion) => {
    localStorage.setItem('idOpTesoreria', idOperacion);
    setIdOperacion(idOperacion)
  }

  const filaMarcada = (fila) => {
    setFilaSeleccionada(fila);
  }

  let botonaccionCuerpoTabla = []

  const handleIdSubOp = (idSubOp) => {
    setIdSubOpConfiguracionTesoreria(idSubOp)
  }

  /*
  switch (idOperacion) {
    
    case 1:
      if(idSubOpUtilidades === "Operaciones")
        botonaccionCuerpoTabla = botoneraUtilidadesOperaciones;
      if(idSubOpUtilidades === "Pendientes")
        botonaccionCuerpoTabla = botoneraUtilidadesPendientes;
      break;

    case 2:
      if(idSubOpConfiguracionTesoreria === "Cajas")
        botonaccionCuerpoTabla = botoneraCajas;
      if(idSubOpConfiguracionTesoreria === "Categorias")
        botonaccionCuerpoTabla = botoneraCategoria;
      if(idSubOpConfiguracionTesoreria === "Conceptos")
        botonaccionCuerpoTabla = botoneraConceptos;
      if(idSubOpConfiguracionTesoreria === "Instrumentos")
        botonaccionCuerpoTabla = botoneraInstrumentos;
      break;

    default:
      botonaccionCuerpoTabla = botoneraUtilidadesOperaciones;
      break;
    
  }
  */

  return (
    <div className="col-main-home ">
      <SearchBar
        titulo="Finanzas"
        claseCabecera="bg-gestion"
        buscar={false}
        imagenCabecera={"6-2-1-tesoreria.png"}
      />

      <div className="bg-cuerpo p-10px">
        <div className="bg-white p-10px">
          <div className="p-1 bg-white mb-3">
            <h6 className="texto-gestion float-left" id="title">
              Tesorería
            </h6>
          </div>
          <hr className="m-0 bg-gestion mb-1" />
          <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
          <Pasos pasos={['COBROS', 'PAGOS']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
            {idOperacion === 0 && <Cobros />}
            {idOperacion === 1 && <Pagos />}
            {/*
            <Pasos pasos={['TABLERO', 'UTILIDADES', 'CONFIGURACION']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
            {idOperacion === 0 && <Tablero />}
            {idOperacion === 1 && <Utilidades idFilaMarcada={filaMarcada} handleChange={handleChange}/>}
            {idOperacion === 2 && <Configuracion idSubOp={handleIdSubOp} idFilaMarcada={filaMarcada}/>}
            */}
          </div>
        </div>
        
          <div className="bg-cuerpo">
            <div className="bg-white">
              <TableAcciones
                filaSeleccionada={filaSeleccionada}
                botonaccion={botonaccionCuerpoTabla}
              />
            </div>
          </div>

      </div>
    </div>

  );
}
export default GestionTesoreria;