import React, { useState, useEffect, useRef } from "react";
import axios from "axios"
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FormFieldNumberUncontrolled from '../../../../../components/Common/FormFieldNumberUncontrolled'
import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'
import FormFieldAutoCompleteUncontrolled from '../../../../../components/Common/FormFieldAutoCompleteUncontrolled'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import FormFieldDateUncontrolled from "../../../../../components/Common/FormFieldDateUncontrolled";
import { useAlmacenamiento } from "./almacenamiento-context";
import { CircularProgress } from "@material-ui/core";

const FormularioAlmacenamientoMateriales = () => {  
    const { modo } = useParams();
    const [openAlert, setOpenAlert] = useState({estado: false, mensaje: "", tipo:"success"});
    const [valores, setValores]  = useState({'pieza': '', 'cantidad': '', 'metros': '', 'deposito': '', 'fechaentrada': '', 'fechasalida': ''})
    const { listadoMaterialesAlmacenamiento, setListadoMaterialesAlmacenamiento } = useAlmacenamiento(); 
    
    const deshabilitado = modo === "R" ? true : false;
    
    let campos = [
        //0 PIEZAS
        {
            urlData: "piezas",
            campoEtiqueta: "codigoNombre",
            required: false,
            label: "Piezas*",
            name: "pieza",    
            disabled: deshabilitado,
            type: "combo",           
            multiple:false,   
            value: valores.pieza,               
            xs:4
        },
        //1 CANTIDAD
        {
            required: false,
            label: "Cantidad*",
            name: "unidades",
            type: "number",    
            disabled: deshabilitado, 
            value: valores.cantidad,                 
            xs:3
        },
        //1 METROS CUADRADOS
        {
            required: false,
            label: "Metros cuadrados",
            name: "metros",
            type: "number",    
            disabled: deshabilitado, 
            value: valores.metros,                  
            xs:3
        },
        //3 DEPOSITOS
        {                
            urlData: "almacens",
            campoEtiqueta: "Nombre",
            required: false,
            label: "Depósito*",
            name: "almacen",
            disabled: deshabilitado,
            type: "combo",           
            multiple:false,   
            value: valores.deposito, 
            //onChangeValue: handleChange,
            xs:4
        }, 
        //4 FECHA ENTRADA
        {
            required: false,
            disabled: deshabilitado,
            label: "Fecha entrada*",
            name: "fechaEntrada",
            type: "date",  
            value: valores.fechaentrada,
            xs:3
        },
        //5 SALIDA ESTIMADA
        {
            required: false,
            disabled: deshabilitado,
            label: "Salida estimada",
            name: "salidaEstimada",
            type: "date",  
            value: valores.fechasalida,
            xs:3
        },
    ]

    const handleCloseAlert = (event, reason) => {  
        if (reason === 'clickaway') {
          return;
        }  
        setOpenAlert({estado: false, mensaje: "", tipo:"success"});
    }; 

    const handleChangePieza = (valor) =>{
        console.log(valor)
        setValores({...valores, 'pieza': valor})
    }

    const handleChangeCantidad = (valor) =>{
        setValores({...valores, 'cantidad': valor})
    }

    const handleChangeMetros = (valor) =>{
        setValores({...valores, 'metros': valor})
    }

    const handleChangeDeposito = (valor) =>{
        setValores({...valores, 'deposito': valor})
    }    

    const handleChangeFechaEntrada = (valor) =>{
        setValores({...valores, 'fechaentrada': valor})        
    }

    const handleChangeFechaSalida = (valor) =>{
        setValores({...valores, 'fechasalida': valor})   
    }

    const handleClick = () => {
        console.log(valores)
        if(!valores.pieza){
            setOpenAlert({estado: true, mensaje: "Debe seleccionar una pieza", tipo:"error"});
            return
        }
        if(!valores.cantidad){
            setOpenAlert({estado: true, mensaje: "Debe introducir una cantidad", tipo:"error"});
            return
        }
        if(!valores.deposito){
            setOpenAlert({estado: true, mensaje: "Debe seleccionar un depósito", tipo:"error"});
            return
        }
        if(!valores.fechaentrada){
            setOpenAlert({estado: true, mensaje: "Debe introducir una fecha de entrada", tipo:"error"});
            return
        }

        if(valores.fechasalida){
            let date1 = new Date(valores.fechaentrada);
            let date2 = new Date(valores.fechasalida);
            console.log(date1)
            console.log(date2)
            let diffDays = date2.getTime() - date1.getTime(); 
            console.log(date2.getTime())
            console.log(date1.getTime())          
            console.log(diffDays)  
            if(diffDays < 0){
                setOpenAlert({estado: true, mensaje: "La fecha de salida estimada debe ser posterior a la fecha de entrada", tipo:"error"});
                return
            }            
        }        

        let material = {
            id:uuidv4(), 
            idPieza: valores.pieza.id,
            nombrePieza: valores.pieza.Nombre,
            cantidad: valores.cantidad,
            metros: valores.metros,
            deposito: valores.deposito,
            fechaEntrada: valores.fechaentrada,
            fechaSalidaEstimada: valores.fechasalida,   
            asignarSalida: false,
            codigoSalida: null,
            fechaSalida: null,
                     
        }
        setListadoMaterialesAlmacenamiento([...listadoMaterialesAlmacenamiento, material])
        setValores({'pieza': '', 'cantidad': '', 'metros': '', 'deposito': '', 'fechaentrada': '', 'fechasalida': ''})
    }

    return (    
    <>    
        <Grid container  spacing={1} className="mt-2 borderFormulario">            
            <FormFieldAutoCompleteUncontrolled propiedades={campos[0]} onChangeValue={handleChangePieza} />                
            <FormFieldNumberUncontrolled propiedades={campos[1]} onChangeValue={handleChangeCantidad}/*onChangeValue={handleChangeComboCategoria}*/ />                
            <FormFieldNumberUncontrolled propiedades={campos[2]} onChangeValue={handleChangeMetros}/*onChangeValue={handleChangeComboCategoria}*/ />                
            <FormFieldAutoCompleteUncontrolled propiedades={campos[3]} onChangeValue={handleChangeDeposito}/*onChangeValue={handleChangeComboSubcategoria}*/ />                
            <FormFieldDateUncontrolled propiedades={campos[4]} onChangeValue={handleChangeFechaEntrada}/*onChangeValue={handleChangeComboSubcategoria}*//>
            <FormFieldDateUncontrolled propiedades={campos[5]} onChangeValue={handleChangeFechaSalida}/*onChangeValue={handleChangeComboSubcategoria}*//>
            
            <Grid item xs={2} className="" >
              <Button className="" variant="outlined" size="large" onClick={handleClick} disabled={deshabilitado}>
              AÑADIR
              </Button>                         
            </Grid>    
        </Grid>             
             
        {openAlert.estado && 
            <AlertaMensaje 
            mensaje={openAlert.mensaje} 
            isOpen={openAlert.estado} 
            tipoMensaje={openAlert.tipo}
            cerrar={handleCloseAlert}
            />
        }         
    </>  
    );   
}

export default FormularioAlmacenamientoMateriales;