import React, { createContext, useState, useMemo, useContext } from "react";

const PlanificacionContext = createContext();

export function PlanificacionProvider(props) {
    const [planificacion, setPlanificacion] = useState(0)
    const [codigoPlanificacion, setCodigoPlanificacion] = useState("")
    const [datosFormulario, setDatosFormulario] = useState({  
        cliente:[],              
        operativa: [],
        servicios: []
      })
    const [datosJornadas, setDatosJornadas] = useState([])
    const [datosPresupuesto, setDatosPresupuesto] = useState({})
    const [pasoActivo, setPasoActivo] = useState(0)
    const [manoActiva, setManoActiva] = useState(0)
    const [costesDirectos, setCostesDirectos] = useState(0)
    const [datosPlanificacion, setDatosPlanificacion] = useState({})
    const [datosDesplegables, setDatosDesplegables] = useState({})
    const [manosPlanificacion, setManosPlanificacion] = useState([])
    const [manosMercancia, setManosMercancia] = useState([])
    const [datosMercancia, setDatosMercancia] = useState([])
    const [actualizarCargaPlanificacion, setActualizarCargaPlanificacion] = useState(false)
    
    const value = useMemo(() => {
        return ({
            planificacion, setPlanificacion,
            codigoPlanificacion, setCodigoPlanificacion,
            datosFormulario, setDatosFormulario,
            datosJornadas, setDatosJornadas,
            datosPresupuesto, setDatosPresupuesto,
            pasoActivo, setPasoActivo,
            manoActiva, setManoActiva,
            costesDirectos, setCostesDirectos,
            datosPlanificacion, setDatosPlanificacion,
            datosDesplegables, setDatosDesplegables,
            manosPlanificacion, setManosPlanificacion,
            manosMercancia, setManosMercancia,
            datosMercancia, setDatosMercancia,
            actualizarCargaPlanificacion, setActualizarCargaPlanificacion
        })
},[
    planificacion, setPlanificacion, 
    codigoPlanificacion, setCodigoPlanificacion,
    datosFormulario, setDatosFormulario,
    datosJornadas, setDatosJornadas,
    datosPresupuesto, setDatosPresupuesto,
    pasoActivo, setPasoActivo,
    manoActiva, setManoActiva,
    costesDirectos, setCostesDirectos,
    datosPlanificacion, setDatosPlanificacion,
    datosDesplegables, setDatosDesplegables,
    manosPlanificacion, setManosPlanificacion,
    manosMercancia, setManosMercancia,
    datosMercancia, setDatosMercancia,
    actualizarCargaPlanificacion, setActualizarCargaPlanificacion
])

return <PlanificacionContext.Provider value={value} {...props} />
}

export function usePlanificacion(){
    const context = useContext(PlanificacionContext)

    if(!context){
        throw new Error('usePlanificacion debe estar dentro del proveedor PlanificacionContext')
    }

    return context;
}