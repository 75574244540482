import React from "react";
import Nav from "../../components/Common/Header";
import {PlanificacionProvider} from '../views/Main/Produccion/Operativas/planificacion-context'
import MaintenanceModeComponent from "../../components/Common/MaintenanceModeComponent";

export default ({ children }) => {   
  const maintenanceMode = children?.props?.children[0]?.key === 'true'

  return (
    <>
    {maintenanceMode && (
      <MaintenanceModeComponent />
    )}
    {!maintenanceMode && (
      <PlanificacionProvider>
    <div style={{maxHeight: "100vh", minHeight: "100vh"}}>
      <Nav />
      <div className="row ml-0 mr-0">
        {children}
      </div>
    </div>
    </PlanificacionProvider>
    )}
    </>
  );  
};

/*
return (
    <PlanificacionProvider>
    <div style={{maxHeight: "100vh", minHeight: "100vh"}}>
      <Nav />
      <div className="row ml-0 mr-0">
        {children }
      </div>
      
    </div>
    </PlanificacionProvider>
  );
  */
