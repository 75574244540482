import React from "react";
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class opciones extends React.Component {
  
  static propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired,
  };
  

  render() {
    const { value, index, change, opciones = ["Activado", "Inactivo"] } = this.props;
    //console.log(this.props)
    return (
      <FormControl>
        <Select value={value} onChange={event => change(event.target.value, index)} style={{fontSize: 'inherit'}}>
          { opciones.map((opcion, index) =>
            <MenuItem key={index} value={opcion}>{opcion}</MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
}

export default opciones;