import React, { useEffect, useState, useRef } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import {columnsHojaDePedido,columnsOrdenDeCompra,columnsFacturaDeCompra,columnsFacturaDeCompraCPE} from './ColumnasConf'
//import {botoneraHojaDePedido,botoneraOrdenDeCompra,botoneraFacturaDeCompra} from './BotonesConf'
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";
import axios from "axios";
import FiltroListado from "./FiltroListado";
import { useStyles } from "../../../../../styles/styles";


const GestionCompra = (props) => {     
  const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOpCompras')) || 0);
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [tipoFacturaCompras, setTipoFacturaCompras]=useState(localStorage.getItem('idTipoFacturaCompras') || "Servicios");    
  const [tipoPedidoCompras, setTipoPedidoCompras]=useState(localStorage.getItem('idTipoPedidoCompras') || "Abiertas");
  const [tipoOrdenCompras, setTipoOrdenCompras]=useState(localStorage.getItem('idTipoOrdenCompras') || "Abiertas");
  const [abrirDialogo, setAbrirDialogo] = useState(false)
  const entidadEliminar = useRef({'tipo': '', 'valor': -1, 'mensaje': ''});
  const [isLoading, setIsLoading] = useState(false);
  const [tiposFactura, setTiposFactura] = useState([]);
  const [url, setUrl] = useState("")
  const facturaClonar = useRef(-1);
  const [abrirDialogoClonar, setAbrirDialogoClonar] = useState(false)


  const classes = useStyles();

  useEffect(() => {      

    const fetchData = async () => {
      setIsLoading(true)       
          const respFacturaCompra = await axios(
              `${process.env.REACT_APP_API_URL}dic/diccionarios/tiposfacturadecompra`
          );    
          //console.log(respFacturaCompra.data)
          let tiposDeFacturaDeCompra = respFacturaCompra.data
          tiposDeFacturaDeCompra.push({Id: 99, Tipo: 'TODAS', Activo: 1})
          setTiposFactura(tiposDeFacturaDeCompra)              
      
      setIsLoading(false);
  };    
  fetchData();  
  },[]);

  useEffect(() => { 
    //console.log(tiposFactura)
    //console.log(idOperacion)
  },[tiposFactura]);

  const botoneraHojaDePedido = [
    {
      etiqueta: "Editar",
      url: '/administracion/compras/hojaspedidos/filaSeleccionada/W',
      activo: false,
    }, 

    {
      etiqueta: "Eliminar",
      function: (e) => eliminarEntidad('hojaPedido', e, 'Hoja de Pedido'),
      activo: false,
    }, 

    {
      etiqueta: "Crear",
      url: '/administracion/compras/hojaspedidos',
      activo: true,
    }, 
  ];

  const botoneraOrdenDeCompra = [
    {
      etiqueta: "Editar",
      url: '/administracion/compras/ordenescompras/filaSeleccionada/W',
      activo: false,
    }, 

    {
      etiqueta: "Eliminar",
      function: (e) => eliminarEntidad('ordenCompra', e, 'Orden de Compra'),
      activo: false,
    }, 

    {
      etiqueta: "Crear",
      url: '/administracion/compras/ordenescompras',
      activo: true,
    }, 
  ];

  const botoneraFacturaDeCompra = [
    tipoFacturaCompras === "CPE" ? {
      etiqueta: "Clonar",
      function: () => handleClickClonaFacturaCompra(filaSeleccionada),
      activo: false,
    } : {}, 
    {
      etiqueta: "Editar",
      url: '/administracion/compras/facturascompras/filaSeleccionada/W',
      activo: false,
    }, 

    {
      etiqueta: "Eliminar",
      function: (e) => eliminarEntidad('factura', e, 'Factura de Compra'),
      activo: false,
    }, 

    {
      etiqueta: "Crear",
      url: '/administracion/compras/facturascompras',
      activo: true,
    }, 
  ];

  const handleClickClonaFacturaCompra = async (fila) => {  
    facturaClonar.current = fila
    setAbrirDialogoClonar(true)

  }

  const handleCerrarDialogoClonar = (valor) => {
    setAbrirDialogoClonar(false)

    if(valor){
      const fetchData = async () => {
      let datos = {"fila": facturaClonar.current}
      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}facturascompras/clonafactura`    
      //console.log(SERVER_URL)
      //console.log(datos)

      await axios
      .post( SERVER_URL, { 
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
        datos,
      })
      .then((response) => {     
        console.log(response.data)     
        if(!isNaN(response.data)){          
          if(parseInt(response.data)){  
            window.location.reload();
            /* 
            history.push({
              pathname: `/ventas/productos`,       
            });
            */
          }
        }
      })
      .catch((error) => {
        console.log("error " + error);
        console.log(error.response);
        console.log(error.mensaje)
      });
      };
      fetchData();
    }

  }

  const eliminarEntidad = (tipo, id, mensaje) => {
    entidadEliminar.current = {'tipo': tipo, 'valor': id, 'mensaje': mensaje}       
    setAbrirDialogo(true)
  }
  
  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const verTabla = (idOperacion) => {
    localStorage.setItem('idOpCompras', idOperacion); 
    setIdOperacion(idOperacion)    
  }    

  const handleOperacion = (idO) =>{   
    switch(idOperacion){
      case 0:
        localStorage.setItem('idTipoPedidoCompras', idO);     
        setTipoPedidoCompras(idO)
        break;
      case 1:
        localStorage.setItem('idTipoOrdenCompras', idO);     
        setTipoOrdenCompras(idO)
        break;
      case 2:
        localStorage.setItem('idTipoFacturaCompras', idO);     
        setTipoFacturaCompras(idO)
        break;
      default:
        break;
    } 
  }  

  const handleCerrarDialogo = (valor) => {     
    setAbrirDialogo(false)

    if(valor){   
      let urlEntidad = ""; 
      if (entidadEliminar.current.tipo === "hojaPedido") urlEntidad = "hojaspedidos";
      if (entidadEliminar.current.tipo === "ordenCompra") urlEntidad = "ordenescompras";
      if (entidadEliminar.current.tipo === "factura") urlEntidad = "facturascompras";

      const fetchData = async () => {  
        setIsLoading(true)
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + urlEntidad;
        
        await axios
        .delete(SERVER_URL + "/" + entidadEliminar.current.valor, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },        
        })
        .then((response) => {   
          //console.log(response.data)  
            
          if(!isNaN(response.data)){ 
            if(parseInt(response.data) === parseInt(entidadEliminar.current.valor)){
              entidadEliminar.current = {'tipo': '', 'valor': -1, 'mensaje': ''} 
              setIsLoading(false)
              //window.location.reload();
            }   
            if(response.data === -1){
              alert("La " + entidadEliminar.current.mensaje + " seleccionada no es posible eliminarla." )
              entidadEliminar.current = {'tipo': '', 'valor': -1, 'mensaje': ''} 
              setIsLoading(false)              
            }
          } else{
            //console.log(response.data) 
            alert(response.data);  
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.log("error " + error);
          setIsLoading(false)
        });  
      }
      fetchData();
    }      
  }

  const handleUrlFecha = (url) =>{  
    //console.log(url)
    setUrl(url)     
   }
  
  let columnasCuerpoTabla = columnsHojaDePedido;
  let urlDatosCuerpoTabla = "hojaspedidos?estado=" + tipoPedidoCompras + url;
  let urlDoubleClickCuerpoTabla = "compras/hojaspedidos/";
  let columnIdCuerpoTabla = "Id";
  let botonaccionCuerpoTabla = botoneraHojaDePedido;
  let footer = [{campo: 'importe', titulo: 'Importe'}]

  if(idOperacion === 1 ){
    botonaccionCuerpoTabla= botoneraOrdenDeCompra 
    columnasCuerpoTabla = columnsOrdenDeCompra
    urlDatosCuerpoTabla ="ordenescompras?estado=" + tipoOrdenCompras + url;
    urlDoubleClickCuerpoTabla = "compras/ordenescompras/"
    columnIdCuerpoTabla="Id"
  }

  if(idOperacion === 2 ){
    botonaccionCuerpoTabla= botoneraFacturaDeCompra 
    tipoFacturaCompras === "CPE" ? columnasCuerpoTabla = columnsFacturaDeCompraCPE : columnasCuerpoTabla = columnsFacturaDeCompra
    urlDatosCuerpoTabla = "facturascompras?tipoFactura=" + tipoFacturaCompras + url
    urlDoubleClickCuerpoTabla = "compras/facturascompras/"
    columnIdCuerpoTabla = "Id"
  }

  let filtroToolbar = true
  let filtroToolbarOpciones = [] 
  
  if(idOperacion === 0){
    filtroToolbarOpciones = ['Pendientes', 'Aprobados', 'Rechazados', 'Todos']
  }  

  if(idOperacion === 1){
    filtroToolbarOpciones = ['Pdtes. Enviar', 'Enviados', 'Todos']
  }  

  if(idOperacion === 2){   
    let tiposDeFacturastr = tiposFactura.map(a => a.Tipo);    
    filtroToolbarOpciones = tiposDeFacturastr;
  }

  let filtro = "";
  switch(idOperacion){
    case 0:
      filtro = tipoPedidoCompras
      break;
    case 1:
      filtro = tipoOrdenCompras
      break;
    case 2:
      filtro = tipoFacturaCompras
      break;
    default:
      break;
  }

  console.log(urlDatosCuerpoTabla)
  const mensaje = "¿Desea eliminar la " + entidadEliminar.current.mensaje + "?"
  return (           
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Administración"
          claseCabecera = "bg-gestion"
          buscar={false}
          imagenCabecera={"6-1-1-compras.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
              <h6 className="texto-gestion float-left" id="title">
                Compra
              </h6>
            </div>
            <hr className="m-0 bg-gestion mb-1" />
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
             
             <Pasos pasos={['Hoja de Pedido', 'Orden de Compra', 'Factura de Compra']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
             
             <FiltroListado urlfecha={handleUrlFecha}/>

             { abrirDialogo && (<AlertaDialogo titulo={mensaje} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
             { abrirDialogoClonar && (<AlertaDialogo titulo={"¿Desea clonar la factura?"} abrir={abrirDialogoClonar} cerrar={handleCerrarDialogoClonar} textoBoton2={"Clonar"}/>)}
              {!isLoading && (
                <CuerpoTablaPiezas
                    columnas = {columnasCuerpoTabla}
                    urlDatos = {urlDatosCuerpoTabla}
                    urlDoubleClick = {urlDoubleClickCuerpoTabla}
                    columnId = {columnIdCuerpoTabla}
                    getFilaSeleccionada={marcarFila}
                    filtroToolbar={filtroToolbar}
                    filtroToolbarOpciones = {filtroToolbarOpciones}
                    filtroToolbarValor = {filtro}
                    filtroToolbarChangeValor={handleOperacion}
                    customFooter={footer} 
                    filtroEjercicio = {true}
                  /> 
                  )}
                {isLoading && 
                  <div className={classes.linear}>
                        <LinearProgress />
                  </div>
                }
            </div>
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
              <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla}
              />            
            </div>
          </div>

        </div>
          
      </div>   
    
  );
}
export default GestionCompra;