const COLUMNS_PEDIDOS = [
    {
      name: "Id",
      label: "Id",
      options: {
       filter: false,
       sort: false,
       display: false,
      }
     },
    {
     name: "Fecha_Solicitud",
     label: "Fecha Solicitud",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
      name: "Codigo",
      label: "Código",
      options: {
       filter: false,
       sort: true,
      }
     },
    {
     name: "Cuenta",
     label: "Cuenta",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "Nombre",
      label: "Contacto",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
      name: "Muelle",
      label: "Muelle",
      options: {
       filter: true,
       sort: true,
      }   
     },
     {
      name: "Buque",
      label: "Buque",
      options: {
       filter: true,
       sort: true,
      }   
     },
     {
      name: "Fecha_Servicio",
      label: "Fecha Operativa",
      options: {
       filter: false,
       sort: true,
       
      }   
     },
     {
      name: "Presupuesto",
      label: "Presupuesto",
      options: {
       filter: true,
       sort: true,
       
      }   
     },   
   ];

export {COLUMNS_PEDIDOS};