import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const Lista = (areasHorizontales, areasVerticales) => {
    let arrayLetra = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','Ñ','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    let arrayAlmacen = []
    let contador= 0

    for(let i=0; i< areasHorizontales; i++){
        for(let j=1; j <= areasVerticales; j++){
            arrayAlmacen[contador] = { Id: i + '-' + j, Etiqueta: arrayLetra[i] + '-' + j }
            contador++
        }
    }
    return arrayAlmacen.sort()
}

const FormularioTransferir = ({datos}) => {
    const classes = useStyles(); 
    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [Transferir, setTransferir] = useState([])     

    useEffect(() => {  
        if(datos){
            setTransferir(datos)
        }
    }, [datos]);

    /*
    const handleChange = (valor) => {
        if (valor) {
            setSecciones(Lista(valor.areasHorizontal, valor.areasVertical))
        }
        else{
            setSecciones([])
        }
    }
    */   

    useEffect(() => { 
        if(Object.keys(Transferir).length > 0){ 
            console.log(Transferir)
            setDatosCargados(false)
            setCampos([           
                {            
                    urlData: "almacens",
                    campoEtiqueta: "Nombre",
                    required: true,
                    label: "Depósito:",
                    name: "almacen",
                    disabled: false,
                    type: "combo",
                    value: "", 
                    //onChangeValue: handleChange,             
                    xs:12
                },            
                {            
                    required: true,
                    label: "Cantidad:",
                    name: "cantidad",
                    disabled: false,
                    type: "number",
                    value: "",       
                    min: 1, 
                    max: Transferir.unidades,                 
                    xs:12
                },  
            ])
            setDatosCargados(true)
        }         
    },[Transferir]);


    if (datoscargados){
    return (
        <FormularioEntidad 
        urlForm = "almacenamientos/transferir"
        urlGoto = "/operativas/almacenamientos/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}

export default FormularioTransferir;