import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'
import { convertDate } from "../../../../../tools/metodos";


const FichaEnfermedad = () => {
    let { id } = useParams();

    const [enfermedad, setSeleccion] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSeleccion = await axios(`${process.env.REACT_APP_API_URL}rrhhsspartesenfermedads/${id}`);    
          setSeleccion(respSeleccion.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(enfermedad).length > 0){  
        
            setCampos([
                /*
            {
                classsLabel: "",
                label: "Tipología:",
                value: enfermedad.Tipologia.Etiqueta,
                classText: classText,
                xs:4
            },
            */
            {
                classsLabel: "",
                label: "Fecha de baja:",
                value: convertDate(enfermedad.Fecha),
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Fecha de alta:",
                value:  convertDate(enfermedad.FechaAlta),
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Trabajador:",
                value: enfermedad.NombreEmpleado,
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Parte de accidente:",
                value: enfermedad.ParteAccidente ? enfermedad.ParteAccidente.Codigo:"",
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: enfermedad.Observaciones,
                classText: classText,
                xs:12
            },
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: enfermedad.ficheros,
                 classText: classText,
                 type: "file",
                 xs:4
                },
            
        ])
    }
},[enfermedad]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/rrhh/talento/enfermedad/${id}/W`}
    />
    </>
  )
}
export default FichaEnfermedad;