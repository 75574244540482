import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaSeleccion = () => {
    let { id } = useParams();

    const [seleccion, setSeleccion] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSeleccion = await axios(`${process.env.REACT_APP_API_URL}rrhhseleccions/${id}`);    
          setSeleccion(respSeleccion.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(seleccion).length > 0){  
        
            setCampos([
            {
                classsLabel: "",
                label: "Candidatura:",
                value: seleccion.Candidatura.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Codigo Candidatura:",
                value: seleccion.Codigo_Candidatura,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Estado:",
                value: seleccion.Estado.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "fechaRecepcion:",
                value: seleccion.Fecha_Recepcion,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Puesto:",
                value: seleccion.Puesto.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Departamento:",
                value: seleccion.Departamento.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Nombre:",
                value: seleccion.Nombre,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Apellidos:",
                value: seleccion.Apellidos,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: seleccion.Observaciones,
                classText: classText,
                xs:12
            },
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: seleccion.ficheros,
                 classText: classText,
                 type: "file",
                 xs:4
                },
            
        ])
    }
},[seleccion]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/rrhh/talento/seleccions/${id}/W`}
    />
    </>
  )
}
export default FichaSeleccion;