import React, {useState, useEffect} from "react"
import axios from "axios";

import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@mui/x-data-grid';
import SaveIcon from '@material-ui/icons/Save';
import AlertaMensaje from "../../../../../components/Common/AlertaMensaje";

const TablaCuentasVentasCategorias = ({tipo, urlController, urlGetData, handleClick}) => {
    const [categorias, setCategorias] = useState([]) 
    const [openAlert,setOpenAlert] = useState({
        open : false,
        tipoMensaje : "success",
        mensaje : ""
    });  

    const columns = [
        { field: 'id', headerName: 'Id', width: 90, hide: true },
        { field: 'etiqueta', headerName: 'Etiqueta', width: 160 },
        { field: 'cuentaContable', headerName: 'Cuenta', width: 150, editable: true },
        { field: 'Guardar', headerName: '', width: 50, 
            renderCell: (params) => {                
                return <SaveIcon onClick={() => handleSaveElement(params.row)}/>                               
            }, 
        },
    ];

    const fetchData = async () => {
        let user = JSON.parse(localStorage.getItem("user"));            
        const respData = await axios( process.env.REACT_APP_API_URL + urlGetData);
        if (respData.data) {              
            setCategorias(respData.data); 
        }
    };

    useEffect(() => {    
        fetchData();
        return () => {setCategorias([])}
    }, [urlGetData]) 

    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {            
            if(field === 'cuentaContable'){
                const updatedRows = categorias.map((row) => {
                    if (row.id === id) {  
                      return { ...row,  cuentaContable: value};
                    }
                    return row;
                  });                 
                setCategorias(updatedRows)
            }
        }, [categorias]
    )

    const handleSaveElement = (element) => {        
        
        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlController}/${element.id}`;

        let datos = {}
        datos.idCategoria = element.id
        datos.cuentaContable = element.cuentaContable 
        

        axios
          .put(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {        
            
            if(!isNaN(response.data)){              
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "Categoría actualizada correctamente"
              })    
              
            }else{

              setOpenAlert({
                open: true,
                tipoMensaje: "error",
                mensaje: "Ha ocurrido un error al actualizar la Categoría"
              })
            }            
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
    }

    const handleRowClick = (row) => {        
        if(handleClick)
            handleClick(row.id)
    }

    const handleCloseAlert = (event, reason)=>{
        if(reason === "clickaway"){
          return
        }
        setOpenAlert({...openAlert,open:false});
      }
    
    let unidades = 0

    return (
        <>        
        <Grid item xs={12} className="" style={{height: 250}}>
        <DataGrid 
                //disableColumnMenu={true}
                rows={categorias} 
                columns={columns}    
                //autoHeight = {true}   
                density = {'compact'}
                //pageSize={100} 
                //checkboxSelection = {false}
                //hideFooter = {false}
                hideFooterPagination = {true}
                //hideFooterRowCount = {false}
                //hideFooterSelectedRowCount = {true}
                onCellEditCommit={handleCellEditCommit}
                onRowClick={handleRowClick}
                />
        {
        openAlert.open && (
            <AlertaMensaje 
                mensaje={openAlert.mensaje} 
                isOpen={openAlert.open} 
                tipoMensaje={openAlert.tipoMensaje}
                cerrar={handleCloseAlert}
            />
                  
              )
            }
        </Grid>
        </>
    )

}

export default TablaCuentasVentasCategorias;