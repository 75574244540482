export const equipoprincipalpropio = [
    {
      url: '/cotizacion/1111_Grua_Portico.jpg',
      title: 'Grúa pórtico (STS)',
      width: '100%',
      categoria: "Grúas",
      categoriaPadre: "Equipo principal propio",
      grupo: '1111'
    },
    {
      url: '/cotizacion/1112_Grua_RTG.jpg',
      title: 'Grúa apiladora (RTG)',
      width: '100%',
      categoria: "Grúas",
      categoriaPadre: "Equipo principal propio",
      grupo: '1112'
    },
    {
      url: '/cotizacion/1113_Reach_Stacker.jpg',
      title: 'Reachstacker',
      width: '100%',
      categoria: "Grúas",
      categoriaPadre: "Equipo principal propio",
      grupo: '1113'
    },
    {
      url: '/cotizacion/1121_mafi.jpg',
      title: 'Cabeza Tractora 4x4 (RoRo)',
      width: '100%',
      categoria: "Máquinas",
      categoriaPadre: "Equipo principal propio",
      grupo: '1121'
  
    },
    {
      url: '/cotizacion/1122_Trompo.jpg',
      title: 'Cabeza tractora 4x2 (Terminal)',
      width: '100%',
      categoria: "Máquinas",
      categoriaPadre: "Equipo principal propio",
      grupo: '1122'
    },
];

export const equipoauxiliarpropio = [

  {
    url: '/cotizacion/1211_Plataforma_elevadora_articulada.jpg',
    title: 'Plataforma elevdora',
    width: '100%',
    categoria: "Elevación",
    categoriaPadre: "Equipo auxiliar propio",
    grupo: '1124'
  },
  {
    url: '/cotizacion/1123_Carretilla_Elevadora.jpg',
    title: 'Carretilla elevadora',
    width: '100%',
    categoria: "Máquinas",
    categoriaPadre: "Equipo principal propio",
    grupo: '1123'
  },
  {
    url: '/cotizacion/1221_Transpaleta_propia.jpg',
    title: 'Transpaleta',
    width: '100%',
    categoria: "Elevación",
    categoriaPadre: "Equipo auxiliar propio",
    grupo: '1221' 
  },
    /*
    {
      url: '/cotizacion/0000_sin_imagen.jpg',
      title: 'Transpaleta eléctrica',
      width: '100%',
      categoria: "Elevación",
      categoriaPadre: "Equipo auxiliar propio",
      grupo: '1222'
      
      
    },
    {
      url: '/cotizacion/0000_sin_imagen.jpg',
      title: 'Transpaleta manual',
      width: '100%',
      categoria: "Otros",
      categoriaPadre: "Equipo auxiliar propio",
      grupo: '1221'
    },
    */
    {
      url: '/cotizacion/1241_maquinaria_propia_furgoneta.jpg',
      title: 'Vehículos',
      width: '100%',
      categoria: "Vehículos",
      categoriaPadre: "Equipo auxiliar propio",
      grupo: '1241'
      
    },
];

export const consumible = [
    {
      url: '/cotizacion/1311_Consumible_Amarre.jpg',
      title: 'Amarre',
      width: '100%',
      categoria: "Amarre",
      categoriaPadre: "Consumible",
      grupo: '1311'
    },
    {
      url: '/cotizacion/0000_sin_imagen.jpg',
      title: 'Fijación / Protección',
      width: '100%',
      categoria: "Fijación/Protección",
      categoriaPadre: "Consumible",
      grupo: '1321'
    }, 
];

export const manoObraPropia = [
    {
     url: '/cotizacion/1411_Jefe_Operaciones.jpg',
     title: 'Jefe de operaciones',
     width: '100%',
     categoria: "Operaciones",
     categoriaPadre: "Mano obra propia",
     grupo: '1411'
   },
   {    
     url: '/cotizacion/1412_Operario.jpg',
     title: 'Operario',
     width: '100%',
     categoria: "Operaciones",
     categoriaPadre: "Mano obra propia",
     grupo: '1412'
   },  
];
 
export const herramientas = [
   {
     url: '/cotizacion/1551_Herramientas.jpg',
     title: 'Herramientas',
     width: '100%',
     categoria: "Operaciones",
     categoriaPadre: "Herramientas",
     grupo: '1511'
   },
];
 
export const otroGastos = [
   {
     url: '/cotizacion/1611_Otros_Gastos.jpg',
     title: 'Otros gastos',
     width: '100%',
     categoria: "Transporte",
     categoriaPadre: "Otros gastos",
     grupo: '1611,1612,1613'
   },
   /*
   {
     url: '/cotizacion/1611_Otros_Gastos.jpg',
     title: 'Otros gastos',
     width: '100%',
     categoria: "Otros",
     categoriaPadre: "Otros gastos",
     grupo: '1614'
   },
   */
];
 
export const equipoprincipalexterno = [
   {
     url: '/cotizacion/2111_Grua_Movil_Telescopica.jpg',
     title: 'Cabeza tractora',
     width: '100%',
     categoria: "Grúas",
     categoriaPadre: "Equipo principal externo",
     grupo: '2111'
     
   },
   /*
   {
     url: '/cotizacion/2121_Carretilla_electrica_elevadora.jpg',
     title: 'Máquinas',
     width: '100%',
     categoria: "Máquinas",
     categoriaPadre: "Equipo principal externo",
     grupo: '2121'
   },  
   */
];
 
export const equipoauxiliarexterno = [
   {
     url: '/cotizacion/2211_Plataforma_elevadora_articulada.jpg',
     title: 'Elevación',
     width: '100%',
     categoria: "Elevación",
     categoriaPadre: "Equipo auxiliar externo",
     grupo: '2211'
   },
];
 
export const manoobraexterna = [
   {
     url: '/cotizacion/2311_Capataz_General.jpg',
     title: 'Mando',
     width: '100%',
     categoria: "Mando",
     categoriaPadre: "Mano de obra externa",
     grupo: '2311,2312,2313'
   },
 
   {
     url: '/cotizacion/2321_Transtainero.jpg',
     title: 'Oficial',
     width: '100%',
     categoria: "Oficial",
     categoriaPadre: "Mano de obra externa",
     grupo: '2321,2322,2323,2324,2325'
   },  
   {
     url: '/cotizacion/2331_Especialista.jpg',
     title: 'Especialista',
     width: '100%',
     categoria: "Especialista",
     categoriaPadre: "Mano de obra externa",
     grupo: '2331'
   }, 
   /* 
   {
     url: '/cotizacion/2341_Mano_Minima.jpg',
     title: 'Mano mínima',
     width: '100%',
     categoria: "Mano mínima",
     categoriaPadre: "Mano de obra externa",
     grupo: '2341'
   }, 
   */
];

export const depositos = [
  {
    url: '/cotizacion/2411_depositos_propio_exterior.jpg',
    title: 'Depósito exterior',
    width: '100%',
    categoria: "Sin techo",
    categoriaPadre: "Depósito",
    grupo: '2411'
  },

  {
    url: '/cotizacion/2401_depositos_propio_interior.jpg',
    title: 'Depósito interior',
    width: '100%',
    categoria: "Con techo",
    categoriaPadre: "Depósito",
    grupo: '2401'
  }, 
]

export const categoriasPresupuesto = [ 
    2111, 2121, 1611, 1612, 1613, 1614, 2211
];

/*
{
    url: '/cotizacion/carretillero_ajeno.jpg',
    title: 'Carretillero ajeno',
    width: '100%',
  },
  {
    url: '/cotizacion/especialista_ajeno.jpg',
    title: 'Especialista ajeno',
    width: '100%',
  },
  {
    url: '/cotizacion/gruero_ajeno.jpg',
    title: 'Gruero ajeno',
    width: '100%',
  },
  {
    url: '/cotizacion/oficial_ajeno.jpg',
    title: 'Oficial',
    width: '100%',
  },
  {
    url: '/cotizacion/picheador_ajeno.jpg',
    title: 'Picheador',
    width: '100%',
  },
  {
    url: '/cotizacion/reach_stacker_ajeno.jpg',
    title: 'Reach stacker',
    width: '100%',
  },
  {
    url: '/cotizacion/transtainero_ajeno.jpg',
    title: 'Transtainero',
    width: '100%',
  },
  {
    url: '/cotizacion/trompista_ajeno.jpg',
    title: 'Trompista',
    width: '100%',
  },
  {
    url: '/cotizacion/manipulador_pesca_ajeno.jpg',
    title: 'Manipulador pesca ajeno',
    width: '100%',
  },
  {
    url: '/cotizacion/mano_minima.jpg',
    title: 'Mano mínima',
    width: '100%',
  },
  {
    url: '/cotizacion/maquinista_elevador_ajeno.jpg',
    title: 'Maquinista elevador',
    width: '100%',
  },
  {
    url: '/cotizacion/maquinista_grua_ajeno.jpg',
    title: 'Maquinista grúa',
    width: '100%',
  },
*/

/*
const materiales = [
  {
    url: '/cotizacion/materiales.jpg',
    title: 'Materiales',
    width: '100%',
  },  
];
*/
