import axios from "axios";

export const getItems = async(url) => {
  return new Promise(async(resolve, reject) => {
    await axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};


export const convertDate = (inputFormat, format = "dd/mm/yyyy") => {
  //console.log(inputFormat)
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  let resultado = "";
  if(inputFormat){
    switch(format){
      case "dd/mm/yyyy":
        resultado = [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
      break;

      case "yyyy-MM-dd":
        resultado = [d.getFullYear(), pad(d.getMonth()+1), pad(d.getDate())].join('-')
      break;

      default:
        resultado = [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }
  }
  return resultado;
  
} 

export const getDateToday = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0');
  let yyyy = today.getFullYear();
  let hoy = yyyy + '-' + mm + '-' + dd;
  return hoy;
}