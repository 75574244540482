import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const MigasDePan = ({clases, urls, codigo=""}) => {
    const numeroEnlaces = urls.length 
    
    function handleClick(event, index) {
        if(index === numeroEnlaces-1)
            event.preventDefault();
      }

    const getLink = (url, index) => {
        const colorLink = url.colorTexto + (numeroEnlaces-1)===index ? "btn" : ""
        return(
            <Link 
                key={index} 
                className={colorLink} 
                to={url.urlTo} 
                onClick={(e) => handleClick(e, index)}
            >
                {url.titulo}
            </Link>
        )
    }   

    return(   
    <div>
        <div className="p-1 bg-white mb-4">
            <h6 className={clases} id="title">
                <Breadcrumbs aria-label="breadcrumb">
                {urls.map((url, index) => (
                    getLink(url, index)             
                ))}                            
                </Breadcrumbs>                    
            </h6>
            <h6 className="texto-comercial float-right font-weight-bold" id="title">
                {codigo}
            </h6>    
        </div>
        <hr className="m-0 bg-comercial mb-2" />
   </div>
    )}

export default MigasDePan;