import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';

const FormFieldTimeUncontrolled = ({ propiedades, onChangeTime }) => {      

    const [time, setTime] = useState('');

    const handleChange = (event) => {
        onChangeTime(event.target.name, event.target.value);
        setTime(event.target.value);
    };

    useEffect(() => {
        setTime(propiedades.value)
    },[propiedades.value])

    return (
    <>
    {/* Campo Hora */}
    <Grid item xs={propiedades.xs} className={propiedades.classes} >
        <TextField     
            required={propiedades.required}
            id={propiedades.name}
            name={propiedades.name}
            disabled = {propiedades.disabled}
            type="time"
            label={propiedades.label}
            variant="outlined"
            size="small"
            fullWidth
            //defaultValue={propiedades.value}
            value={time} 
            onChange={handleChange}
            className=""
            InputLabelProps={{
                shrink: true,
            }}
        />
    </Grid>
    </>
    )
}

export default FormFieldTimeUncontrolled;