import React, { useState, useEffect, useContext } from "react";

import Button from '@material-ui/core/Button';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { TextField } from "@material-ui/core";


import { StateContext } from '../../../../../context'
import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'
import axios from "axios";

let propiedadesPlusMercancias = {
    urlData: "dic/diccionarios/grupomercancias",
    campoEtiqueta: "Etiqueta",
    required: true,
    name: "combogrupomercancia",
    disabled: false,
    label: "Plus",
    value: "",
    multiple: false,
    xs:4,
}

let propiedadesMercancias = {
    urlData: "dic/diccionarios/subtipomercancias/0",
    campoEtiqueta: "Etiqueta",
    required: false,
    name: "combosubtipomercancia",
    disabled: false,
    label: "Mercancía",
    value: "",
    multiple: false,
    xs:4,
}

let propiedadesTiposMercancias = {
    urlData: "dic/diccionarios/tipomercancias",
    campoEtiqueta: "Id,Etiqueta",
    required: true,
    name: "combomercancia",
    disabled: false,
    label: "Clase",
    value: "",
    multiple: false,
    xs:4,
}
  
let propiedadesMovimientos = {
    urlData: "dic/diccionarios/tipomovimientos",
    campoEtiqueta: "Id,Etiqueta",
    required: false,
    name: "combo-movimiento",
    disabled: false,
    label: "Movimientos",
    value: "",
    multiple: false,
    xs:4,
}
  
let propiedadesNumBultos = {
    xs:2,
    required: false,
    label: "Nº Bultos",
    name: "nbultos",
    type: "number",    
    value: 0
}

let propiedadesNumKilos = {
    xs:2,
    required: false,
    label: "Toneladas",
    name: "nkilos",
    type: "number",    
    value: 0
}

const FormularioOperacionMercanciasCPE = ({control, onChangeStep}) => {
    const {state, dispatch} = useContext(StateContext)
    const { operacion } = state
    const [listadoMercancias, setListadoMercancias] = useState([])
    const [refrescar, setRefrescar] = useState(false)
    

    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'idPlus', headerName: 'Cod. Plus' },
        { field: 'idClaseMercancia', headerName: 'idClaseMercancia', hide: true },
        { field: 'idTipoMercancia', headerName: 'idTipoMercancia', hide: true },
        { field: 'idMovimiento', headerName: 'idMovimiento', hide: true },
        { field: 'clase', headerName: 'Clase', width: 200 },
        { field: 'mercancia', headerName: 'Mercancía', width: 200 },
        { field: 'movimiento', headerName: 'Movimiento', width: 100 },

        /*{ field: 'nbultos', headerName: 'Bultos', width: 100 },*/
        { field: 'nbultos', headerName: 'Bultos',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 100, sortable: true, editable: true },

        /*{ field: 'nkilos', headerName: 'Toneladas', width: 100 },*/
        { field: 'nkilos', headerName: 'Toneladas',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 100, sortable: true, editable: true },
        
        { field: '', headerName: '', width: 120, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
              ), },
    ];

    const handleRemoveElement = (element) =>{
        const elementoAEliminar = operacion.listadoMercancias.find(el => {
            return el.id === element.id
        })
        dispatch({type: "REMOVE_LISTADO_MERCANCIAS_CPE", 
        payload: elementoAEliminar})
    }

    useEffect(() => {        
        typeof operacion.listadoMercancias === 'undefined' || 
        operacion.listadoMercancias === null ?  
        setListadoMercancias([]): 
        setListadoMercancias(operacion.listadoMercancias)       
    },[operacion.listadoMercancias])

    useEffect(() => {
        
        if (typeof operacion !== "undefined") {             
            if(Object.keys(operacion).length > 0){                
              if(operacion.elementoActualizar && typeof operacion.elementoActualizar !== "undefined"){
                  
                if (operacion.elementoActualizar.tipo === "cpe"){
                    if(operacion.CPEMercancias.length > 0){
                        const CPEMercancias = operacion.CPEMercancias[0].filter((elemento) => elemento.Fk_Operacion_Cpe === operacion.elementoActualizar.elemento)
                        
                        CPEMercancias.forEach(                
                            function(a){
                                const fetchData = async () => {
                                    const respDatosTipoMercancia = await axios(process.env.REACT_APP_API_URL + propiedadesTiposMercancias.urlData);
                                    const TipoMercanciaSelected = respDatosTipoMercancia.data.filter((elemento) => parseInt(elemento.Id) === parseInt(a.Fk_TipoMercancia))                                    
                                    const MercanciaSeleccionada = TipoMercanciaSelected.length > 0 ? TipoMercanciaSelected[0].Etiqueta : null
            
                                    const respDatosSubTipoMercancia = await axios(process.env.REACT_APP_API_URL + propiedadesMercancias.urlData);
                                    const SubtipoMercanciaSelected = respDatosSubTipoMercancia.data.filter((elemento) => parseInt(elemento.Id) === parseInt(a.Fk_SubtipoMercancia))
                                    const SubtipoMercanciaSeleccionada = SubtipoMercanciaSelected.length > 0 ? SubtipoMercanciaSelected[0].Etiqueta : null
                                    
                                    const respDatosMovimiento = await axios(process.env.REACT_APP_API_URL + propiedadesMovimientos.urlData);
                                    const MovimientoSelected = respDatosMovimiento.data.filter((elemento) => parseInt(elemento.Id) === parseInt(a.Fk_Tipo_Movimiento))
                                    const MovimientoSeleccionado = MovimientoSelected.length > 0 ? MovimientoSelected[0].Etiqueta : null

                                    dispatch({type: "ADD_LISTADO_MERCANCIAS_CPE", 
                                    payload: {
                                        id: a.Id, 
                                        idPlus: a.Fk_CodPlus,
                                        idClaseMercancia: a.Fk_TipoMercancia,
                                        idTipoMercancia: a.Fk_SubtipoMercancia,
                                        idMovimiento: a.Fk_Tipo_Movimiento,
                                        clase: MercanciaSeleccionada,
                                        mercancia: SubtipoMercanciaSeleccionada,
                                        movimiento: MovimientoSeleccionado,
                                        nbultos: a.NumBultos,
                                        nkilos: a.NumKilos,
                                    }})            
                                };
                                fetchData();
                            }
                        ) 
                    } 
                }
              }
            }
        }  

        propiedadesTiposMercancias.value = ""
        propiedadesMercancias.value = ""
        propiedadesMovimientos.value = ""
        propiedadesNumBultos.value = 0
        propiedadesPlusMercancias.value = ""
        propiedadesNumKilos.value = 0

    }, [])

    const handleClick = () => { 
        if(propiedadesTiposMercancias.value && 
            propiedadesMovimientos.value && 
            ( propiedadesNumKilos.value > 0 ||  propiedadesNumBultos.value > 0 ||
            ( (propiedadesTiposMercancias.value.Id === 31 || propiedadesTiposMercancias.value.Id === 131) && ( propiedadesNumKilos.value === 0 ||  propiedadesNumBultos.value === 0) ) 
            )){
           
            dispatch({type: "ADD_LISTADO_MERCANCIAS_CPE", 
            payload: {
                id: Date.now(), 
                idPlus: propiedadesPlusMercancias.value.Id,
                idClaseMercancia: propiedadesTiposMercancias.value.Id,
                idTipoMercancia: propiedadesMercancias.value ? propiedadesMercancias.value.Id : 0,
                idMovimiento: propiedadesMovimientos.value.Id,
                clase: propiedadesTiposMercancias.value.Etiqueta,
                mercancia: propiedadesMercancias.value ? propiedadesMercancias.value.Etiqueta : "",
                movimiento: propiedadesMovimientos.value.Etiqueta,
                nbultos: propiedadesNumBultos.value,
                nkilos: propiedadesNumKilos.value,
            }})
            
            propiedadesNumBultos.value = 0
            propiedadesNumKilos.value = 0
        }
    }

    const handleChangeComboMercancias = (valor) => {
        propiedadesMercancias.value = valor
    }

    const handleChangeComboMovimientos = (valor) => {
        propiedadesMovimientos.value = valor
    }

    const handleChangeNumMovs = (valor) => {
        propiedadesNumBultos.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeNumKilos = (valor) => {
        propiedadesNumKilos.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeComboGrupoMercancias = (valor) => { 
        if(valor){
            propiedadesPlusMercancias.value = valor   
        }
    }

    const handleChangeComboTipoMercancias = (valor) => {   
        if(valor){       
            propiedadesMercancias.urlData = 'dic/diccionarios/subtipomercancias/' + valor.Id   
            propiedadesTiposMercancias.value = valor
        }
    }

    const handleChageStep = (valor) => {
        if(onChangeStep)
            onChangeStep(valor)
    }

    const handleCleanForm = () => {
        dispatch({type: "UPDATE_OPERACION", payload: "cpe"}) 
        if(onChangeStep)
            onChangeStep(0)     
    }

    const actualizaDatosMercancia = async (mercanciasModificadas) => {

        await dispatch({type: "REMOVE_LISTADO_MERCANCIAS_CPE", 
            payload: mercanciasModificadas
        })

        await dispatch({type: "ADD_LISTADO_MERCANCIAS_CPE", 
            payload: mercanciasModificadas
        })

    }

    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => { 

        const updatedRows = listadoMercancias.map((row) => {
            if (row.id === id) { 
                if(field === 'nbultos')
                    return { ...row,  nbultos: value};
                if(field === 'nkilos')
                    return { ...row,  nkilos: value};
            }
            return row;
        });  
        
        const listadoActualizadas = updatedRows.filter( mercancia => parseInt(mercancia.id) === parseInt(id) )
       
        if(listadoActualizadas.length > 0)
            actualizaDatosMercancia(listadoActualizadas[0])
        }
    )

   

    return (
    <>     
    <Grid container spacing={1} className="CPE1 mt-2">
        <FormFieldComboUncontrolled propiedades={propiedadesPlusMercancias} onChangeValue={handleChangeComboGrupoMercancias} />
        <FormFieldComboUncontrolled propiedades={propiedadesTiposMercancias} onChangeValue={handleChangeComboTipoMercancias} />
        
        <FormFieldComboUncontrolled propiedades={propiedadesMercancias} onChangeValue={handleChangeComboMercancias} />
        <FormFieldComboUncontrolled propiedades={propiedadesMovimientos} onChangeValue={handleChangeComboMovimientos} />
        <FormFieldTextUncontrolled propiedades={propiedadesNumBultos} onChangeValue={handleChangeNumMovs}/>
        <FormFieldTextUncontrolled propiedades={propiedadesNumKilos} onChangeValue={handleChangeNumKilos}/>
        
        <Grid item xs={3} className="" style={{marginLeft: 12}}>
            <Button className="" variant="contained" color="primary" onClick={handleClick}>
                AÑADIR
            </Button> 
            
        </Grid>



        <Grid item xs={12} className="" style={{ display: 'flex', flexWrap: 'wrap',  height: 280, marginLeft: 0 }}>
            <DataGrid 
                disableColumnMenu={true}
                rows={listadoMercancias} 
                columns={columns} 
                pageSize={100} 
                checkboxSelection = {false}
                hideFooter = {true}
                hideFooterPagination = {true}
                hideFooterRowCount = {true}
                hideFooterSelectedRowCount = {true}
                onCellEditCommit={handleCellEditCommit}
            />
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={2}>
            <Button className="float-right" variant="contained" color="primary" 
                onClick={() => handleCleanForm() }>
                CANCELAR
            </Button>
         </Grid>   
        <Grid item xs={2}>
            <Button className="float-right" variant="contained" color="primary" 
                onClick={() => handleChageStep(1) }>
                ANTERIOR
            </Button>
        </Grid>
                
        <Grid item xs={2}>
            <Button className="float-right" variant="contained" color="primary" 
                onClick={() => handleChageStep(3) }>
                SIGUIENTE
            </Button>
         </Grid>   
    </Grid>
    </>
    )
}
export default FormularioOperacionMercanciasCPE;