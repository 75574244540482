import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
//Ficha entidad general para reutilizar en cada ficha
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioAlmacen = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [almacen, setAlmacen] = useState([]) 


    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([           
            {
                required: true,
                label: "Fecha Creacion",
                name: "fechaCreacion",
                type: "date",  
                value: '',
                xs:3
            },
            {
                required: true,
                label: "Tamaño(m2):",
                name: "superficie",
                type: "number",    
                disabled: false, 
                value: "",                
                xs:3
            },
            {
                urlData: "listas/almacens/listadoalmacen",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Nombre depósito:",
                name: "nombre",
                disabled: false,
                type: "text",
                value: "",              
                xs:3
            },
            {
                urlData: "dic/diccionarios/estadosalmacen",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Estado:",
                name: "estado",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: "",
                xs:3
            },
            {
                urlData: "dic/diccionarios/tipologiasalmacen",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Tipología:",
                name: "tipologia",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: "",
                xs:3
            },
            {
                required: true,
                label: "Nº áreas horizontal:",
                name: "areasHorizontal",
                type: "number",    
                disabled: false, 
                value: "",                
                xs:3
            },
            {
                required: true,
                label: "Nº áreas vertical:",
                name: "areasVertical",
                type: "number",    
                disabled: false, 
                value: "",                
                xs:3
            },         
            {
                required: false,
                label: "Observaciones",
                name: "observaciones",
                type: "textArea",
                lineas: 4,                
                value: '',
                xs:12
            },
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_actividades_adjuntos",
                xs:12
              },            
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: almacen.ficheros,    
                tabla: "mtms_actividades_adjuntos",
                xs:12,
                modelo: "api\\modules\\v1\\models\\almacenes\\AlmacenAdjuntos",
                carpeta: "depositos",
              },
            
            
         
    ])

    const fetchData = async () => { 
        if (modo === "W") {
            const respAlmacen = await axios(
                `${process.env.REACT_APP_API_URL}almacens/${id}`
            );                
            setAlmacen(respAlmacen.data)  
        }
        setDatosCargados(true);  
    };    
    fetchData();       
},[]);

useEffect(() => {            
    if (typeof almacen !== "undefined") {        
      if(Object.keys(almacen).length > 0){              
        setDatosCargados(false)
        campos[0].value = almacen.fechaCreacion;
        campos[1].value = almacen.superficie;
        campos[2].value = almacen.nombre;
        campos[3].value = almacen.estado;
        campos[4].value = almacen.tipologia;
        campos[5].value = almacen.areasHorizontal;
        campos[6].value = almacen.areasVertical;
        campos[7].value = almacen.observaciones;            
        campos[9].ficheros = almacen.ficheros;
        setDatosCargados(true)  
      }
  }
  },[almacen])

if (datoscargados){
    return (        
        <FormularioEntidad 
        urlForm = "almacens"
        urlGoto = "/operativas/almacenamiento/almacen/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioAlmacen;