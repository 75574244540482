import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldDate from "./FormFieldDate"
import FormFieldSelect from "./FormFieldSelect"
import FormFieldText from "./FormFieldText"
import FormFieldTextCIF from "./FormFieldTextCIF"
import FormFieldNumber from "./FormFieldNumber"
import FormFieldLineSeparator from "./FormFieldLineSeparator"
import FormFieldTextArea from "./FormFieldTextArea"
import FormFieldFiles from "./FormFieldFiles"
import FormFieldFileUpload from "./FormFieldFileUpload"
import FormFieldTime from "./FormFieldTime"
import FormFieldCombo from "./FormFieldCombo"
import FormFieldSwitch from "./FormFieldSwitch"
import FormFieldCheckBox from "./FormFieldCheckBox"
import FormFieldComboArray from "./FormFieldComboArray"
import FormFieldAvatar from "./FormFieldAvatar";
import AlertaMensaje from "./AlertaMensaje";

//import FormularioDatPersonales from "../../containers/views/Main/myAccount/FormularioDatPersonales";

const useStyles = makeStyles((theme) => ({
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  
  }));


const FormularioEntidadDialog = ({ 
  campos, urlForm, urlGoto, action, 
  type="add", value=null, clases="p-1 bg-white mb-2 p3-form m-0",
  mensajeErr ="Fallo al guardar los datos", mensajeSuccess="Datos guardados correctamente",
  formName = "formularioEntidad", textoBoton2 = "GUARDAR"
}) => {
    const { id, modo } = useParams();
    const { control, handleSubmit } = useForm();  
    const [grabandoFormulario, setGrabandoFormulario] = React.useState(false);
    const classes = useStyles();
    const history = useHistory(); 
    const [ficheros, setFicheros] = useState([])
    const [nombresFicheros, setNombresFicheros] = useState([])
    const [openAlert,setOpenAlert] = useState({
      open : false,
      tipoMensaje : "success",
      mensaje : ""
    });  


    const handleCloseAlert = (event, reason)=>{
      if(reason === "clickaway"){
        return
      }
      setOpenAlert({...openAlert,open:false});
    }

    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }

    const handleSubmitPost = async (datos, event) => {
      event.stopPropagation();
      event.preventDefault();

      if(formName !== event.target.name) return;
      
      
      if (type === "view") return;
      setGrabandoFormulario(true);  
      let idElemento=null

      if(value===null){
        idElemento=id;
      }
      else{
        idElemento=value;
      }
      
      datos.IdEntidad = id
      datos.ficheros = ficheros
      datos.nombresFicheros = nombresFicheros
      
      const user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlForm}`;
      
      datos.username=user.username
      datos.userid=user.id

      if (/*modo === "W" ||*/ type === "edit" ) {
        axios
          .put(SERVER_URL + "/" + idElemento, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {    
            //console.log(response.data)    
            if(!isNaN(response.data)){              
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : mensajeSuccess
              })    
              handleClick(id)
              if(action) action()
            }else{

              setOpenAlert({
                open: true,
                tipoMensaje: "error",
                mensaje: mensajeErr
              })
            }            
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
      } 
      //else
      if(type === "add"){
      
        await axios
          .post(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {   
            //console.log(response.data)
            if(!isNaN(response.data)){
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : mensajeSuccess
              })
             
              if(action) {
                setTimeout(function () {
                action();
                }, 500);
              }
            }else{
              setOpenAlert({
                open : true,
                tipoMensaje : "error",
                mensaje : mensajeErr
              })
            }
            event.preventDefault();
            event.stopPropagation();
            return;
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
        }
        
      setGrabandoFormulario(false);
    }

    const comboChange=(valor)=>{
      //console.log(valor)
    }

    const handleClick = (id) => { 
      if(urlGoto){
        if (modo === "W" || type === "edit" || id) {
          history.push({
            pathname: `${urlGoto}${id}/R`,
            //action: action + Math.floor(Math.random() * (9999))
          }); 
        }else{
          history.push({
            pathname: `${urlGoto}`,
            action: action + Math.floor(Math.random() * (9999))
          });
        }
      }     
    };

    if(campos.length > 0){
    return(
    <>  
    <div className="rounded border border-formulario p1-form mb-3">
        <div className="p-2 bg-white mb-2  m-0">
            <div className={clases}>
            <form
            id="formularioEntidadDialog"
            name="formularioEntidadDialog"
                  onSubmit={handleSubmit(handleSubmitPost)}
                  
                  
                  className="row"
                >
                <Grid container spacing={3}>
                {campos.map((element, index) => (
                <React.Fragment key={index}>                
                    {element.type === "date" && (
                        <FormFieldDate 
                            key={index} 
                            propiedades={element} 
                            control={control}
                        />
                    )}  

                    {element.type === "select" && (
                        <FormFieldSelect 
                            key={index} 
                            propiedades={element}
                            control={control} 
                        />
                    )}
                   
                    {element.type === "switch" && (
                        <FormFieldSwitch 
                            key={index} 
                            propiedades={element}
                            control={control}  
                        />
                    )} 
                    
                    
                    {element.type === "text" && (
                      <FormFieldText 
                            key={index} 
                            propiedades={element}
                            control={control}  
                        />
                    )}  

                    {element.type === "textCIF" && (
                      <FormFieldTextCIF 
                            key={index} 
                            propiedades={element}
                            control={control}  
                        />
                    )}  

                    {element.type === "number" && (
                        <FormFieldNumber
                            key={index} 
                            propiedades={element}
                            control={control} 
                        />
                    )}    

                    {element.type === "lineseparator" && (
                        <FormFieldLineSeparator 
                          key={index} 
                          propiedades={element} />
                    )}
                                       
                    {element.type === "textArea" && (
                        <FormFieldTextArea 
                          key={index} 
                          propiedades={element}
                          control={control}
                        />
                    )}

                    {element.type === "filesupload" && (
                        <FormFieldFileUpload 
                          key={index} 
                          propiedades={element}
                          control={control} 
                          handleFicheros={handleFicheros}                          
                        />
                    )}
                    
                    {element.type === "files" && (
                        <FormFieldFiles 
                          key={index} 
                          propiedades={element}
                          control={control} 
                          ficheros={handleFicheros}
                        />
                    )}  
                    {element.type === "time" && (
                        <FormFieldTime 
                          key={index} 
                          propiedades={element}
                          control={control} 
                        />
                    )} 
                    {element.type === "combo" && (
                        <FormFieldCombo 
                          key={index} 
                          propiedades={element} 
                          control={control} 
                          onChangeValue={comboChange}
                        />
                    )}
                     {//llamar aqui a comboArray
                      element.type === "comboArray" && (
                        <FormFieldComboArray 
                          key={index} 
                          propiedades={element} 
                          control={control} 
                        />
                      )}
                    {element.type === "checkbox" && (
                        <FormFieldCheckBox 
                          key={index} 
                          propiedades={element} 
                          control={control} 
                        />
                    )}
                    {element.type === "avatar" && (
                      <FormFieldAvatar
                      key={index} 
                      propiedades={element} 
                      control={control} 
                      handleFicheros={handleFicheros}
                      />
                     )}
                     
                </React.Fragment>
                )
                )}
                 {/* BOTON */}
                 <div className="col-12 pt-4">
                  {!grabandoFormulario && /*(modo === "W" || typeof modo === "undefined") &&*/ (type === "edit" || type === "add") && (
                    <>
                      <button className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation">
                        {textoBoton2}
                      </button>                      
                    </>
                  )}
                  </div>
                  </Grid>

            </form>
            {grabandoFormulario && (
                <div className={(classes.linear, "mt-4")}>
                    <LinearProgress />
                </div>
            )}
            {
              openAlert.open && (
                  <AlertaMensaje 
                    mensaje={openAlert.mensaje} 
                    isOpen={openAlert.open} 
                    tipoMensaje={openAlert.tipoMensaje}
                    cerrar={handleCloseAlert}
                  />
                  
              )
            }
            </div>
        </div>
    </div>            
    </>
    ) 
    }
    else return null;
}

export default FormularioEntidadDialog;