import React, { useState, useEffect } from "react";
import axios from "axios";

import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'

import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { TextField } from "@material-ui/core";

import SaveIcon from '@material-ui/icons/Save';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const FormularioTarifasCPE = () => {
    const [listadoSalariosCPE, setListadoSalariosVariosCPE] = useState([])
    const [openAlert, setOpenAlert] = useState(false);

    const columns =  [
        { field: 'id', headerName: 'Nº', width: 50, sortable: false, hide: false },
        { field: 'Etiqueta', headerName: 'Concepto', width: 200, sortable: true, hide: false },
        { field: 'Descripcion', headerName: 'Descripción', width: 300, sortable: true, hide: false },
        { field: 'Unidad', headerName: 'Unidad', width: 80, sortable: false, hide: false },
        { field: 'Importe', headerName: 'Cifra',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 120, sortable: true, editable: true },

        { field: '1', headerName: 'Ver', width: 80, sortable: false, 
            renderCell: (params) => (
                <PictureAsPdfIcon onClick={() => {                    
                    viewPdf(params.row)                                       
                }}/>
              ), 
        },       

        { field: '2', headerName: 'Guardar', width: 80, sortable: false, 
            renderCell: (params) => (
                <SaveIcon onClick={() => {                    
                    updateSalarios(params.row)                                       
                }}/>
              ), 
        },        
    ]

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }  
        setOpenAlert(false);
    }; 

    const fetchData = async () => {
        const urlDatos = "salarioscpes"
        axios.get(process.env.REACT_APP_API_URL + urlDatos)
        .then((response) => { 
            setListadoSalariosVariosCPE(response.data);           
        })
        .catch((error) => {
            console.log(error);
            setListadoSalariosVariosCPE([]);
        }) 
    };

    useEffect(() => {         
        fetchData();            
    }, [openAlert]);

    const handleCellEditCommit = React.useCallback(        
        ({ id, field, value }) => {  
          if (field === 'Importe') {
            const updatedRows = listadoSalariosCPE.map((row) => {
              if (row.id === id) {  
                return { ...row,  Importe: value };
              }
              return row;
            });           
            setListadoSalariosVariosCPE(updatedRows)         
          }
    }, [listadoSalariosCPE],);

    const updateSalarios = async (datos) => {  
        //console.log("updateSalarios")    
        //console.log(datos)  
         

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}salarioscpes`

        datos.userid = user.id         

        await axios
          .put(`${SERVER_URL}/${datos.id}`, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {  
              //console.log(response.data)
            if(!isNaN(response.data.id)){                                     
                setOpenAlert(true)              
            }
            })
            .catch((error) => {
              console.log("error " + error);
        });
    }

    const viewPdf = async (datos) => {
        //console.log("viewpdf");
        //console.log(datos)

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}salarioscpes/generapdf`

        datos.userid = user.id     
        
        let config = {
            headers: {'Authorization': AuthStr},
            params: {
              datos: datos
            },
        }

        await axios.get(SERVER_URL, config)
        .then((response) => { 
            //console.log(response.data) 
            window.open(response.data, "_blank")
            if(!isNaN(response.data)){                                     
                setOpenAlert(true)              
            }
        })
        .catch((error) => {
            console.log("error " + error);
        });
    }

    return (
        <>
        <h3>TARIFAS CPE</h3>
        <Grid container  spacing={1} className="mt-1">
        <Grid item xs={12} className="" style={{height: 400}}>
            <DataGrid 
              disableColumnMenu={true}
              //disableColumnReorder={false}   
              rows={listadoSalariosCPE} 
              columns={columns} 
              pageSize={100} 
              checkboxSelection = {false}
              hideFooter = {true}
              hideFooterPagination = {true}
              hideFooterRowCount = {true}
              hideFooterSelectedRowCount = {true}
              onCellEditCommit={handleCellEditCommit}
              />
            {openAlert && 
            <AlertaMensaje 
              mensaje={"Tarifa guardada correctamente!"} 
              isOpen={openAlert} 
              tipoMensaje="success"
              cerrar={handleCloseAlert}
            />
            } 
        </Grid>
        </Grid>
        </>
    )

}
export default FormularioTarifasCPE;