import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FiltroListado from "./FiltroListado";

const useStyles = makeStyles((theme) => ({    
       
    table: {
      minWidth: 700,
    },
    celda:{
      borderWidth: '1px',
      borderColor: '#000',
      borderStyle: 'solid',
      padding: '0px !important', 
      backgroundColor: 'white',
      fontSize: '12pt',
      color: '#505050', 
    },
    subcelda:{
        paddingLeft: '10px !important', 
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
        backgroundColor: 'white',
        fontSize: '10pt',
        color: '#505050', 
      },
    celdaAlta:{
        borderWidth: '1px',
        borderColor: '#000',
        borderStyle: 'solid',
        padding: '0px !important',
        height: '10px',
        backgroundColor: 'white',
    },
    celdaAltaFechas:{
        /*borderWidth: '1px',*/
        borderColor: '#000',
        borderStyle: 'solid',
        padding: '0px !important',
        height: '10px',
        backgroundColor: 'white',
        fontSize: '12pt',
        color: '#505050', 
        borderTopWidth: '1px !important',
        borderLeftWidth: '0px !important',
        borderRightWidth: '0px !important',
        borderBottomWidth: '1px !important', 
        borderBottomStyle: 'solid !important',
        borderTopStyle: 'solid !important',     
    },
    celdaNegrita:{
      borderWidth: '1px',
      borderColor: '#000',
      borderStyle: 'solid',
      padding: '0px !important',
      fontWeight: 'bold',
      fontSize: '12pt',
      color: '#505050', 
    },
    textoNegrita: {fontWeight: 'bold'},
    textoRojo:{color: '#F00 !important'}
}));


const TablaCuentaExplotacion = ({urlFechas}) => {
    const classes = useStyles();

    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
   
    const zerofilledDay = ('00'+day).slice(-2);
    const zerofilledMonth = ('00'+month).slice(-2);

    const fechaFin = year + "-" + zerofilledMonth + "-" + zerofilledDay
    const fechaInicio = year + "-01-01"

    const urlInicio = "?fechainicial="+fechaInicio+"&fechafinal="+fechaFin
    
    const [cuentaExplotacionRequest, setCuentaExplotacionRequest] = useState({
        loading: true,
        datos: null,
        url: urlInicio,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
    })

    const { loading, datos, url } = cuentaExplotacionRequest;

    useEffect(() => {
        
      if (loading) {
        const fetchData = async () => {     
          try{       
            const respPyG = await axios(process.env.REACT_APP_API_URL + "dic/diccionarios/cuentaexplotacion"+url);
            console.log(process.env.REACT_APP_API_URL + "dic/diccionarios/cuentaexplotacion"+url)
            //console.log(respPyG.data)
            if (respPyG.data) {
                if(urlFechas){
                    //console.log(url)
                    urlFechas(url)
                }
                setCuentaExplotacionRequest(prevCuentaExplotacion => {
                    return {
                        ...prevCuentaExplotacion,
                        loading: false,
                        datos: respPyG.data,
                    }
                })
            }
          }catch(e){
            setCuentaExplotacionRequest(prevCuentaExplotacion => {
                return {
                    ...prevCuentaExplotacion,
                    loading: false,
                    datos: null,
                    url: "",
                }
            })
            //console.log(e)
          }
          //setIsLoading(false);
        };
        fetchData();
      }          
    }, [cuentaExplotacionRequest]); 

    const obtenCifra = (cuenta, year) => {
        //console.log(cuenta, year, datos[cuenta] ? datos[cuenta][year].valor : '')
        var formatter = new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
          });
          
        if(datos[cuenta]) {
            if(datos[cuenta][year]) {
                return formatter.format(datos[cuenta][year].valor)
            }
                
        }
        else return formatter.format(0); 
    }

    const obtenPeso = (cuenta, year) => {
        if(datos[cuenta]) {
            if(datos[cuenta][year]) {
                return parseFloat(datos[cuenta][year].peso).toFixed(2)+" %";
            }
        }
        else return parseFloat(0).toFixed(2)+" %"
    }

    const obtenVariacion = (cuenta) => {
        if(datos[cuenta]) {
            if(datos[cuenta][year]) {
                return parseFloat(datos[cuenta].variacion).toFixed(2)+" %";
            }
        }
        else return parseFloat(0).toFixed(2)+" %";
    }

    let cifraA85_1 = 0;
    let pesoA85_1 = 0;
    let cifraA85_2 = 0;
    let pesoA85_2 = 0;
    let variacionA85 = 0;

    let cifraA86_1 = 0;
    let pesoA86_1 = 0;
    let cifraA86_2 = 0;
    let pesoA86_2 = 0;
    let variacionA86 = 0;



    if(datos){
        cifraA85_1 = parseFloat(obtenCifra("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1))
        pesoA85_1 = parseFloat(obtenPeso("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1))
        cifraA85_2 = parseFloat(obtenCifra("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear()))
        pesoA85_2 = parseFloat(obtenPeso("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear()))
        variacionA85 = parseFloat(obtenVariacion("A85"))

        cifraA86_1 = parseFloat(obtenCifra("A86", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1))
        pesoA86_1 = parseFloat(obtenPeso("A86", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1))
        cifraA86_2 = parseFloat(obtenCifra("A86", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear()))
        pesoA86_2 = parseFloat(obtenPeso("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear()))
        variacionA86 = parseFloat(obtenVariacion("A86"))
    }

    const handleUrlFecha = (valores) =>{  
        setCuentaExplotacionRequest(prevCuentaExplotacion => {
            return {
                ...prevCuentaExplotacion,
                loading: true,
                datos: null,
                url: valores.url,
                fechaInicio: valores.fechaInicio,
                fechaFin: valores.fechaFin
            }
        })
    }    

    return (   
      <div className="mt-5">    
        {!loading && (
          <>
          <FiltroListado urlfecha={handleUrlFecha} fechaInicial={cuentaExplotacionRequest.fechaInicio} fechaFinal={cuentaExplotacionRequest.fechaFin}/>
          <TableContainer component={Paper}>
          <Table  className={classes.table} aria-label="spanning table">
              {/* CABECERA */}
              <TableHead>
                  <TableRow >
                      <TableCell className={classes.celdaAltaFechas} align="center" colSpan={3}></TableCell>
                      <TableCell className={classes.celdaAltaFechas} colSpan={4} align="center" >{new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1}</TableCell>
                      <TableCell className={classes.celdaAltaFechas} colSpan={4} align="center" >{new Date(cuentaExplotacionRequest.fechaInicio).getFullYear()}</TableCell>
                      <TableCell className={classes.celdaAltaFechas} colSpan={4} rowSpan={2} align="center" >Var (%)</TableCell>
                  </TableRow>
                  <TableRow >
                      <TableCell className={classes.celdaAltaFechas} colSpan={3} align="left" ></TableCell>
                      <TableCell className={classes.celdaAltaFechas} colSpan={2} align="center" >Cifra</TableCell>
                      <TableCell className={classes.celdaAltaFechas} colSpan={2} align="center" >Peso</TableCell>
                      <TableCell className={classes.celdaAltaFechas} colSpan={2} align="center" >Cifra</TableCell>
                      <TableCell className={classes.celdaAltaFechas} colSpan={2} align="center" >Peso</TableCell>
                  </TableRow>
              </TableHead>

        {/* CUERPO rowSpan={2} colSpan={3}*/}
        <TableBody id="tablaCuentaExplotacion">
          <TableRow > 
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="left" colSpan={3}>Ingresos brutos</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenCifra("A70", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenPeso("A70", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenCifra("A70", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenPeso("A70", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={4}>{obtenVariacion("A70")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Descuentos y bonificaciones</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A66", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A66", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A66", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A66", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A66")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="left" colSpan={3}>Ingresos netos</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenCifra("A71", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenPeso("A71", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenCifra("A71", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenPeso("A71", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={4}>{obtenVariacion("A71")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="left" colSpan={3}>Coste de las ventas</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenCifra("A60", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenPeso("A60", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenCifra("A60", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenPeso("A60", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={4}>{obtenVariacion("A60")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Proveedor servicios C.P.E.</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A600", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A600", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A600", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A600", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A600")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Proveedores maquinaria</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A601", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A601", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A601", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A601", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A601")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Tasas Autoridad Portuaria Las Palmas</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A602", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A602", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A602", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A602", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A602")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Cuota de estiba</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A603", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A603", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A603", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A603", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A603")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Otros costes de las ventas</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A604", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A604", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A604", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A604", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A604")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={classes.celdaNegrita} align="left" colSpan={3}>Margen Bruto</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A80", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A80", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A80", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A80", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={4}>{obtenVariacion("A80")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
          <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="left" colSpan={3}>Gastos de estructura</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenCifra("A61", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenPeso("A61", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenCifra("A61", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={2}>{obtenPeso("A61", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={`${classes.celda} ${classes.textoNegrita}`} align="center" colSpan={4}>{obtenVariacion("A61")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Gastos de personal</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A610", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A610", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A610", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A610", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A610")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Compra de aprovisionamientos</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A611", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A611", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A611", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A611", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A611")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Compra de suministros</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A612", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A612", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A612", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A612", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A612")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Tributos y tasas</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A613", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A613", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A613", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A613", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A613")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Servicios profesionales independientes</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A614", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A614", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A614", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A614", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A614")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Mantenimiento y reparación</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A615", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A615", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A615", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A615", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A615")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Otros servicios exteriores</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A616", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A616", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A616", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A616", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A616")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.subcelda} align="left" colSpan={3}>Otros gastos de estructura</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A617", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A617", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenCifra("A617", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={2}>{obtenPeso("A617", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.subcelda} align="center" colSpan={4}>{obtenVariacion("A617")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={classes.celdaNegrita} align="left" colSpan={3}>Resultado Operativo (EBITDA)</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A81", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A81", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A81", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A81", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={4}>{obtenVariacion("A81")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={classes.celda} align="left" colSpan={3}>Amortización inmovilizado</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A62", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A62", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A62", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A62", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={4}>{obtenVariacion("A62")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={classes.celdaNegrita} align="left" colSpan={3}>Resultado Explotación (EBIT)</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A82", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A82", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A82", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A82", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={4}>{obtenVariacion("A82")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={classes.celda} align="left" colSpan={3}>Ingresos financieros</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A72", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A72", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A72", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A72", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={4}>{obtenVariacion("A72")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.celda} align="left" colSpan={3}>Gastos financieros</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A63", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A63", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A63", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A63", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={4}>{obtenVariacion("A63")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.celdaNegrita} align="left" colSpan={3}>Resultado financiero</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A83", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A83", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A83", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A83", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={4}>{obtenVariacion("A83")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={classes.celda} align="left" colSpan={3}>Ingresos extraordinarios</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A72", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A72", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A72", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A72", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={4}>{obtenVariacion("A72")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.celda} align="left" colSpan={3}>Gastos extraordinarios</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A64", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A64", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A64", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A64", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={4}>{obtenVariacion("A64")}</TableCell>
          </TableRow>

          <TableRow > 
              <TableCell className={classes.celdaNegrita} align="left" colSpan={3}>Resultado extraordinario</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A84", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A84", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenCifra("A84", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={2}>{obtenPeso("A84", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celdaNegrita} align="center" colSpan={4}>{obtenVariacion("A84")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={classes.celdaNegrita} align="left" colSpan={3}>Resultados antes de impuestos (EBT)</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${cifraA85_1 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={2}>{obtenCifra("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${pesoA85_1 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={2}>{obtenPeso("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${cifraA85_2 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={2}>{obtenCifra("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${pesoA85_2 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={2}>{obtenPeso("A85", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${variacionA85 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={4}>{obtenVariacion("A85")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow > 
              <TableCell className={classes.celda} align="left" colSpan={3}>Impuesto de Sociedades</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A65", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A65", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenCifra("A65", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={2}>{obtenPeso("A65", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={classes.celda} align="center" colSpan={4}>{obtenVariacion("A65")}</TableCell>
          </TableRow>

          <TableRow ><TableCell className={classes.celdaAlta} align="left" colSpan={15}></TableCell></TableRow>

          <TableRow> 
              <TableCell className={classes.celdaNegrita} align="left" colSpan={3}>Resultado NETO EJERCICIO</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${cifraA86_1 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={2}>{obtenCifra("A86", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${pesoA86_1 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={2}>{obtenPeso("A86", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear() -1)}</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${cifraA86_2 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={2}>{obtenCifra("A86", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${pesoA86_2 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={2}>{obtenPeso("A86", new Date(cuentaExplotacionRequest.fechaInicio).getFullYear())}</TableCell>
              <TableCell className={` ${classes.celdaNegrita} ${variacionA85 < 0 ? classes.textoRojo : ''} `} align="center" colSpan={4}>{obtenVariacion("A86")}</TableCell>
          </TableRow>

      </TableBody>
        </Table>
            </TableContainer>
            </>
        )}
        {loading && (
          <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
        )} 
      </div>   
    )
}

export default TablaCuentaExplotacion;