import React from "react";
import AuthRoute from "../components/AuthRoute";

//VENTAS
//PEDIDOS
import Pedidos from "../containers/views/Main/Comercial/Ventas/Pedidos";
import Pedido from "../containers/views/Main/Comercial/Ventas/Pedido";

//COTIZACIONES
import Cotizaciones from "../containers/views/Main/Comercial/Ventas/Cotizaciones";
import Cotizacion from "../containers/views/Main/Comercial/Ventas/Cotizacion";

//PRODUCTOS
import Productos from "../containers/views/Main/Comercial/Ventas/Productos";
import Portfolio from "../containers/views/Main/Comercial/Ventas/Portfolio";
import Tarifa from "../containers/views/Main/Comercial/Ventas/Tarifa";

export const pedidos = (<AuthRoute path="/ventas/pedidos" exact component={Pedidos} type="private" />)
export const pedido = (<AuthRoute path="/ventas/pedidos/pedido" exact component={Pedido} type="private" />)
export const pedidomodo = ( <AuthRoute path="/ventas/pedidos/pedido/:id/:modo" exact component={Pedido} type="private" />)

export const cotizaciones = ( <AuthRoute path="/ventas/cotizaciones" exact component={Cotizaciones} type="private" />)
export const cotizacion = ( <AuthRoute path="/ventas/cotizaciones/cotizacion" exact component={Cotizacion} type="private" />)
export const cotizacionmodo = (<AuthRoute path="/ventas/cotizaciones/cotizacion/:id/:modo" exact component={Cotizacion} type="private" />)

export const productos = (<AuthRoute path="/ventas/productos" exact component={Productos} type="private" />)
export const portfolio = ( <AuthRoute path="/ventas/productos/portfolio" exact component={Portfolio} type="private" />)
export const portfoliomodo = (<AuthRoute path="/ventas/productos/portfolio/:id/:modo" exact component={Portfolio} type="private" />)
export const tarifa = (<AuthRoute path="/ventas/productos/tarifa" exact component={Tarifa} type="private" />)
export const tarifamodo = (<AuthRoute path="/ventas/productos/tarifa/:id/:modo" exact component={Tarifa} type="private" />)