const columnsEmpleados = [
    {
        name: "idDatosPersonales",
        label: "Nº",
        options: {
        filter: true,
        sort: false,
        }
     },  
    {
        name: "Fecha_Alta",
        label: "Fecha Alta",
        options: {
         filter: true,
         sort: false,
        }
       },
       {
        name: "Nombre",
        label: "Nombre",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Apellidos",
        label: "Apellidos",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Puesto",
        label: "Puesto",
        options: {
         filter: true,
         sort: true,
        }
       },  
       {
        name: "Departamento",
        label: "Departamento",
        options: {
         filter: true,
         sort: true,
        }
       },
         {
          name: "Estado",
          label: "Estado",
          options: {
           filter: true,
           sort: true,
           
          }   
         },   
];

export {columnsEmpleados};

const columnsVacaciones = [
        {
            name: "Id",
            label: "Nº",
            options: {
            filter: true,
            sort: false,
            }
         },  
        {
            name: "Departamento",
            label: "Departamento",
            options: {
             filter: true,
             sort: false,
            }
           },
           {
            name: "Trabajador",
            label: "Trabajador",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "Fecha_Inicio",
            label: "Fecha Inicio",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "Fecha_Fin",
            label: "Fecha Fin",
            options: {
             filter: true,
             sort: true,
            }
           },  
           {
            name: "Dias",
            label: "Días",
            options: {
             filter: true,
             sort: true,
            }
           },  
];
    
export {columnsVacaciones};

const columnsPersonal = [
            {
                name: "Id",
                label: "Nº",
                options: {
                filter: true,
                sort: false,
                }
             },  
            {
                name: "Departamento",
                label: "Departamento",
                options: {
                 filter: true,
                 sort: false,
                }
               },
               {
                name: "Trabajador",
                label: "Trabajador",
                options: {
                 filter: true,
                 sort: true,
                }
               },
               {
                name: "Detalle",
                label: "Detalle",
                options: {
                 filter: true,
                 sort: true,
                }
               },
               {
                name: "Fecha",
                label: "Fecha",
                options: {
                 filter: true,
                 sort: true,
                }
               },  
               {
                name: "Importe",
                label: "Importe",
                options: {
                 filter: true,
                 sort: true,
                }
               },  
];
        
export {columnsPersonal};