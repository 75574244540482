import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import { useParams } from "react-router-dom";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import JornadasOperacion from "./JornadasOperacion"
import DocumentacionOperacion from "./DocumentacionOperacion"
import FormularioPedidoProductos from "../../../../../components/Common/FormularioPedidoProductos"

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    }, 
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepper: {        
      display: "flex",
      padding: "12px"      
    }
}));
  
function getSteps() {
    return ['Cargar Partes Operaciones', 'Productos', 'Documentación'/*, 'Cerrar Carpeta Operativa'*/];
}

const PasosOperacion = (props) => {  
    //let { id, modo } = useParams(); 
    const [ pasoActivo, setPasoActivo ] = useState(0);      
    const classes = useStyles();    
    const steps = getSteps(); 
       
    const handleStep = (step) => () => {
      setPasoActivo(step)   
    };

    const getStepContent = (step) => {
      switch (step) {
        case 0: 
            return <JornadasOperacion  />;
         
        case 1:
          return <FormularioPedidoProductos urlGetData={"operaciones"} urlPostData={"operacionesproductos"}/>
            
        case 2:          
            return <DocumentacionOperacion  />;
                
        default:
          return 'Cerrar carpeta operativa';
      }
    }

    return (    
      <div className="bg-white mb-2 pt-3 pl-3 pr-3 pb-0 m-0">
        <div className={classes.root}>
          <Stepper nonLinear activeStep={pasoActivo} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>  
            
        <div>
          <div className={classes.instructions}>{getStepContent(pasoActivo)}</div>
        </div>                    
      </div>
      );
}
export default PasosOperacion;