import React from "react";
import AuthRoute from "../components/AuthRoute";

//CRM
//CUENTAS
import Cuentas from "../containers/views/Main/Comercial/Crm/Cuentas";
import Cuenta from "../containers/views/Main/Comercial/Crm/Cuenta";

//OPORTUNIDADES
import Oportunidades from "../containers/views/Main/Comercial/Crm/Oportunidades";
import Oportunidad from "../containers/views/Main/Comercial/Crm/Oportunidad";

//ACTIVIDADES
import Actividades from "../containers/views/Main/Comercial/Crm/Actividades";
import Actividad from "../containers/views/Main/Comercial/Crm/Actividad";

//BUQUES
import Buques from "../containers/views/Main/Comercial/Crm/Buques";
import FichaBuques from "../containers/views/Main/Comercial/Crm/FichaBuques";
import FormularioBuques from "../containers/views/Main/Comercial/Crm/FormularioBuques";

//PROYECTOS
import Proyectos from "../containers/views/Main/Comercial/Crm/Proyectos";
import Proyecto from "../containers/views/Main/Comercial/Crm/Proyecto";
import Localizaciones from "../containers/views/Main/Comercial/Crm/Localizaciones";
import FichaLocalizacion from "../containers/views/Main/Comercial/Crm/FichaLocalizacion";
import FormularioLocalizacion from "../containers/views/Main/Comercial/Crm/FormularioLocalizacion";

export const cuentas = (<AuthRoute path="/crm/cuentas" exact component={Cuentas} type="private" />)
export const cuenta = (<AuthRoute path="/crm/cuentas/cuenta" exact component={Cuenta} type="private" />)
export const cuentamodo = (<AuthRoute path="/crm/cuentas/cuenta/:id/:modo" exact component={Cuenta} type="private" />)

export const oportunidades = (<AuthRoute path="/crm/oportunidades" exact component={Oportunidades} type="private" />)
export const oportunidad = (<AuthRoute path="/crm/oportunidades/oportunidad" exact component={Oportunidad} type="private" />)
export const oportunidadmodo = (<AuthRoute path="/crm/oportunidades/oportunidad/:id/:modo" exact component={Oportunidad} type="private" />)

export const actvidades = (<AuthRoute path="/crm/actividades" exact component={Actividades} type="private" />)
export const actvidad = (<AuthRoute path="/crm/actividades/actividad" exact component={Actividad} type="private" />)
export const actvidadmodo = (<AuthRoute path="/crm/actividades/actividad/:id/:modo" exact component={Actividad} type="private" />)

export const proyectos =(<AuthRoute path="/crm/proyectos" exact component={Proyectos} type="private" />)
export const proyecto =(<AuthRoute path="/crm/proyectos/proyecto" exact component={Proyecto} type="private" />)
export const proyectomodo =(<AuthRoute path="/crm/proyectos/proyecto/:id/:modo" exact component={Proyecto} type="private" />)

export const buques = (<AuthRoute path="/crm/buques" exact component={Buques} type="private" />)
export const fichabuques = (<AuthRoute path="/crm/buques/:id/R" exact component={FichaBuques} type="private" />)
export const formulariobuques = (<AuthRoute path="/crm/buques/:id/W" exact component={FormularioBuques} type="private" />)
export const formulariobuques2 = (<AuthRoute path="/crm/buque" exact component={FormularioBuques} type="private" />)

export const localizaciones = (<AuthRoute path="/crm/localizaciones" exact component={Localizaciones} type="private" />)
export const fichalocalizaciones = (<AuthRoute path="/crm/localizaciones/:id/R" exact component={FichaLocalizacion} type="private" />)
export const formulariolocalizaciones = (<AuthRoute path="/crm/localizaciones/:id/W" exact component={FormularioLocalizacion} type="private" />)
export const formulariolocalizaciones2 = (<AuthRoute path="/crm/localizacion" exact component={FormularioLocalizacion} type="private" />)