import React, { useState, useEffect } from "react";

import axios from "axios";

import CrearContactoForm from "./CrearContactoForm";
import TableHeader from "../Common/TableHeader";
import TableBody from "../Common/TableBody";

import Loading from "../../../../components/Common/Loading";
import "semantic-ui-css/semantic.min.css";

const Contactos = ({ idCuenta }) => {
  const [cuentas, setCuentas] = useState([]);
  const [orderASC] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [lastFieldOrder] = useState("");
  const [filaActiva] = useState(true);
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [
    mostrarFormularioCrearContacto,
    setMostrarFormularioCrearContacto,
  ] = useState(false);

  const headerTitulos = [
    { campo: "Id", etiqueta: "" },
    { campo: "Foto", etiqueta: "Foto" },
    { campo: "Nombre", etiqueta: "Nombre" },
    { campo: "Departamento", etiqueta: "Departamento" },
    { campo: "Cargo", etiqueta: "Cargo" },
    { campo: "Email", etiqueta: "Email" },
    { campo: "Movil", etiqueta: "Móvil" },
  ];

  const urlAccionDobleClick = ""; 

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const respEmpresas = await axios(
        process.env.REACT_APP_API_URL + "cuentas/contactos/" + idCuenta
      );
      setCuentas(respEmpresas.data);
    };
    fetchData();
    setIsLoading(false);
    
  }, [idCuenta]);


  const handleClickCreateFormContacto = (valor) => {
    setMostrarFormularioCrearContacto(valor);
  };
  const handleClick = () => {
    setRefresh(!refresh);
  };

  const handleClickBody = (valor) => {
    setFilaSeleccionada(valor);
  };

  const handleDoubleClickBody = (valor) => {
    setFilaSeleccionada(valor);
    setMostrarFormularioCrearContacto(true);
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-4">
              <h6 className="texto-comercial" id="title">
                Contactos
              </h6>

              <hr className="m-0 bg-comercial" />
            </div>
            {mostrarFormularioCrearContacto && (
              <div className="bg-white mb-3">
                <CrearContactoForm
                  idContacto={filaSeleccionada}
                  onSubmitFormContacto={() => handleClickCreateFormContacto()}
                />
              </div>
            )}
            <div className="bg-white cuerpo-h2  overflow-auto overflow-hiddens table-responsive">
              <table id="cuentas" className="table table-hover">
                <thead>
                  <tr className="text-center bg-white">
                    <TableHeader
                      titulos={headerTitulos}
                      cuentasMH={cuentas}
                      orderASCMH={orderASC}
                      lastFieldOrderMH={lastFieldOrder}
                      onClick={() => handleClick()}
                    />
                  </tr>
                </thead>
                <tbody className="text-center">
                  <TableBody
                    camposTB={headerTitulos}
                    cuentasTB={cuentas}
                    fila={filaActiva}
                    onDoubleClick={(a) => handleDoubleClickBody(a)}
                    accionDobleClick={urlAccionDobleClick}
                    onClick={(a) => handleClickBody(a)}
                  />
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
      <div>{isLoading && <Loading isLoading={isLoading} />}</div>
    </>
  );
};

export default Contactos;