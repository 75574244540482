import React, { useState, useEffect } from "react";

import { makeStyles, withStyles  } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { useForm } from "react-hook-form";

import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'
import FormFieldAutoCompleteUncontrolled from "../../../../../components/Common/FormFieldAutoCompleteUncontrolled";
import FormFieldAutoCompleteArrayUncontrolled from "../../../../../components/Common/FormFieldAutoCompleteArrayUncontrolled";

export default function FiltroListadoMaquinaria({urlfecha, firstDay, lastDay, showPrintButton = false, handlePrintReport = null}) { 

    const { control } = useForm();

    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1, 
          padding: theme.spacing(1),
          margin: '10px',        
          backgroundColor: '#FFF',
        },
    }));

    const StyledButton = withStyles((theme) => ({
        root: {
          color: 'black',
          backgroundColor: '#c4c4c4',
          height: 32,
          marginTop: 0,          
          width: 60,
          '&:hover': {
            backgroundColor: '#777777',
          },
        },
    }))(Button);

    const classes = useStyles();
    const[fechaInicio, setFechaInicio]=useState(firstDay);
    const[fechaFin, setFechaFin]=useState(lastDay);    
    
    const [campoSubcategorias, setCampoSubcategorias] = useState({
    
        urlData: 'dic/diccionarios/subcategoriasrecursospropios/0',
        campoEtiqueta: "Etiqueta",
        required: false,
        name: "Subcategoria",
        disabled: false,        
        label: "Subcategoría",
        value: '',
        multiple: false,
        xs: 8
    })

    const [campoElemento, setCampoElemento] = useState({    
        urlData: "dic/diccionarios/listadomaquinariapropia",
        campoEtiqueta: "Nombre",
        required: false,
        name: "areatrabajo",
        disabled: false,
        label: "Elemento",
        type:'combo',
        value: '',
        multiple: false,
        xs: 4         
    })

    const [campoTipologia, setCampoTipologia] = useState({        
        campoEtiqueta: "Etiqueta",
        required: false,
        label: "Tipología",
        name: "tipologia",
        disabled: false,
        type: "comboArray", 
        options:[{Id: 'Mantenimiento', Etiqueta: 'Mantenimiento'}, {Id: 'Reparación', Etiqueta: 'Reparación'}, {Id: 'Todos', Etiqueta: 'Todos'}], 
        multiple:false,   
        value: '',
        xs:4           
    })

    let propiedadesFechaInicio = {
        required: true,
        label: "Fecha Inicio",
        name: "fechaInicio",
        type: "date",    
        value: fechaInicio,
        xs:2
    }
          
    let propiedadesFechaFin = {
        required: true,
        label: "Fecha Fin",
        name: "fechaFin",
        type: "date",    
        value: fechaFin,
        xs:2
    }

    const handleChangeFechaInicio = (valor) => {    
        setFechaInicio(valor)
    }
    
    const handleChangeFechaFin = (valor) => {    
        setFechaFin(valor)
    }

    const handleChangeComboSubcategorias = (valor) => {         
        setCampoSubcategorias({...campoSubcategorias, value: valor })
    } 

    const handleChangeComboElemento = (valor) => {            
        setCampoElemento({...campoElemento, value: valor })
    } 

    const handleChangeComboTipologia = (valor) => {         
        setCampoTipologia({...campoTipologia, value: valor})
    }

    const handleSetURL = async () => { 
        const SERVER_URL = "?fechainicial=" + fechaInicio + "&fechafinal=" + fechaFin + "&subcategoria=" + (campoSubcategorias?.value?.Id ?? - 1 ) + "&elemento=" + (campoElemento?.value?.Id ?? -1) + "&tipologia=" + (campoTipologia?.value?.Id ?? -1);
        urlfecha(SERVER_URL)
    }

    const handleSetFecha = async () => { 
        const SERVER_URL = "";   
        setFechaInicio('') 
        setFechaFin('')
        setCampoSubcategorias({...campoSubcategorias, value: ""})
        setCampoElemento({...campoElemento, value: "" })
        handleChangeComboTipologia(null)
        setCampoTipologia({...campoTipologia, value: "" })        
        urlfecha(SERVER_URL)
    }

    const handlePrint = () => {
        const SERVER_URL = "?fechainicial=" + fechaInicio + "&fechafinal=" + fechaFin;   
        if(handlePrintReport)
          handlePrintReport(SERVER_URL)
    }

    return(
    <div className={classes.root}>
        <Grid container spacing={1} className="" style={{backgroundColor: "#FFF"}}>
            <FormFieldDateUncontrolled propiedades={propiedadesFechaInicio} onChangeValue={handleChangeFechaInicio} />
            <FormFieldDateUncontrolled propiedades={propiedadesFechaFin} onChangeValue={handleChangeFechaFin} />

            <FormFieldAutoCompleteUncontrolled propiedades={campoSubcategorias} onChangeValue={handleChangeComboSubcategorias} />         
            <FormFieldAutoCompleteUncontrolled propiedades={campoElemento} onChangeValue={handleChangeComboElemento} /> 
            <FormFieldAutoCompleteArrayUncontrolled propiedades={campoTipologia} onChangeValue={handleChangeComboTipologia} />                
        
            <Grid item xs={1} className="" ></Grid>
           
            <Grid item xs={1} className="" >
                {showPrintButton && (
                    <StyledButton size="small" className={classes.margin}
                    onClick={handlePrint}>
                    Imprimir
                    </StyledButton>
                )}
            </Grid>
            
            <Grid item xs={1} className="" >
                <StyledButton size="small" className={classes.margin}
                onClick={handleSetURL}>
                Filtrar
                </StyledButton>
            </Grid>      

            <Grid item xs={1} className="" >
                <StyledButton size="small" className={classes.margin}
                onClick={handleSetFecha}>
                Limpiar
                </StyledButton>
            </Grid> 
        </Grid>
    </div> 
    )
}