const columnsDepositos = [
  {
   name: "id",
   label: "Nº",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "codigo",
   label: "Codigo",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "nombre",
   label: "Nombre",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "estado",
   label: "Estado",
   options: {
    filter: true,
    sort: true,
    
   }   
  },
];

export {columnsDepositos};

const columnsExistenciasAlmacenaje = [
{
 name: "categoria",
 label: "Categoría",
 options: {
  filter: true,
  sort: false,
 }
},
{
 name: "grupo",
 label: "Grupo",
 options: {
  filter: true,
  sort: true,
 }
},
{
 name: "material",
 label: "Nombre Material",
 options: {
  filter: true,
  sort: true,
 }
},
//este campo es ampliable hay que mirar como se hace
{
 name: "existencias",
 label: "Existencias",
 options: {
  filter: true,
  sort: true,
 }
},
{
 name: "inventario",
 label: "Inventario",
 options: {
  filter: true,
  sort: true,
 }
},  

  
];

export {columnsExistenciasAlmacenaje};


const columnsMaterialesListado = [
 {
   name: "id",
   label: "Nº",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "categoria",
   label: "Categoría",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "grupo",
   label: "Grupo",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "Nombre",
   label: "Material",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "codigo",
   label: "Codigo",
   options: {
    filter: true,
    sort: true,
   }
  },  
  {
   name: "ean",
   label: "EAN",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "marca",
   label: "Marca",
   options: {
    filter: true,
    sort: true,
    
   }   
  }, 
  {
     name: "proveedor",
     label: "Proveedor",
     options: {
      filter: true,
      sort: true,
      
     }   
    },
    {
     name: "fechaCreacion",
     label: "Fecha Alta",
     options: {
      filter: true,
      sort: true,
      
     }   
    },
    {
     name: "deposito",
     label: "Depósito",
     options: {
      filter: true,
      sort: true,
      
     }   
    },    
    
];

export {columnsMaterialesListado};

const columnsMaterialesSalida = [
 {
   name: "id",
   label: "Nº",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "tipologia",
   label: "Tipología",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "fecha",
   label: "Fecha",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "codigo",
   label: "Código",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "departamento",
   label: "Departamento",
   options: {
    filter: true,
    sort: true,
   }
  },  
  {
   name: "personal",
   label: "Personal",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "unidades",
   label: "Unidades",
   options: {
    filter: true,
    sort: true,
   }
  },  
  {
   name: "importe",
   label: "Importe",
   options: {
    filter: true,
    sort: true,
    
   }   
  }, 
  {
     name: "deposito",
     label: "Depósito",
     options: {
      filter: true,
      sort: true,
      
     }   
    },
    {
     name: "notaSalida",
     label: "Nota salida",
     options: {
      filter: true,
      sort: true,
      
     }   
    },
    {
     name: "salida",
     label: "Salida",
     options: {
      filter: true,
      sort: true,
      
     }   
    },
    {
     name: "estado",
     label: "Estado",
     options: {
      filter: true,
      sort: true,
      
     }   
    },    
    
];

export {columnsMaterialesSalida};

const columnsMaterialesEntrada = [
 {
   name: "id",
   label: "Nº",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "tipologia",
   label: "Tipología",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "fecha",
   label: "Fecha",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "codigo",
   label: "Código",
   options: {
    filter: true,
    sort: true,
   }
  }, 
  {
   name: "tercero",
   label: "Tercero",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "unidades",
   label: "Unidades",
   options: {
    filter: true,
    sort: true,
   }
  },  
  {
   name: "importe",
   label: "Importe",
   options: {
    filter: true,
    sort: true,
    
   }   
  }, 
    {
     name: "notaEntrada",
     label: "Nota entrada:",
     options: {
      filter: true,
      sort: true,
      
     }   
    },
    {
     name: "fechaEntrada",
     label: "Fecha entrada:",
     options: {
      filter: true,
      sort: true,
      
     }   
    },
    {
     name: "estado",
     label: "Estado",
     options: {
      filter: true,
      sort: true,
      
     }   
    },    
    
];

export {columnsMaterialesEntrada};

const columnsExistenciasInventario = [
 {
   name: "id",
   label: "Nº",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "ean",
   label: "EAN",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "descripcion",
   label: "Descripción del artículo",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "unidades",
   label: "UDS",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "categoria",
   label: "Categoría",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "marca",
   label: "Marca",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "deposito",
   label: "Almacén",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "entradas",
   label: "Entradas",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "salidas",
   label: "Salidas",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "existencias",
   label: "Existencias",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "tarifa",
   label: "Tarifa Promedio",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "euros",
   label: "Euros",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "porcentaje",
   label: "%",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "porcentajeAcumulado",
   label: "% Acumulado",
   options: {
    filter: true,
    sort: false,
   }
  },
    
];

export {columnsExistenciasInventario};

