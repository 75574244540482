import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioPiezas = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [pieza, setPieza] = useState([]) 

    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([    
            {
                name: "nombre",
                label: "Nombre Pieza:",
                required: true,
                classes: "",
                disabled: false,
                value: '',
                xs:4,                
                type: "text", 
            },
            {
                urlData: "dic/diccionarios/categoriaspieza",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Categoria:",
                name: "categoria",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:4,
                DialogForm: [
                    {                
                      required: true,
                      label: "Nombre:",
                      name: "Etiqueta",
                      disabled: false,
                      type: "text",
                      value: "",   
                      xs:12
                    }]
            },
            {
                type: "lineseparator",
                xs:4
            },
            {
                required: true,
                label: "Medida largo:",
                name: "largo",
                type: "number",    
                disabled: false, 
                value: '',                
                xs:4
            },
            {
                required: true,
                label: "Medida ancho:",
                name: "ancho",
                type: "number",    
                disabled: false, 
                value: '',                
                xs:4
            },
            {
                required: true,
                label: "Medida alto:",
                name: "alto",
                type: "number",    
                disabled: false, 
                value: '',                
                xs:4
            },
            {
                required: true,
                label: "Fecha creacion",
                name: "fechaCreacion",
                type: "date",  
                value: '',
                xs:4
            },
            {
                required: true,
                label: "Peso:",
                name: "peso",
                type: "number",    
                disabled: false, 
                value: '',                
                xs:4
            },
            {
                urlData: "dic/diccionarios/estadosalmacen",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Estado:",
                name: "estado",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: "",
                xs:4
            },
            {
                required: false,
                label: "Descripción",
                name: "descripcion",
                type: "textArea",
                lineas: 4,                
                value: '',
                xs:12
            },            
            {
                required: false,
                label: "Observaciones",
                name: "observaciones",
                type: "textArea",
                lineas: 4,                
                value: '',
                xs:12
            },
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_actividades_adjuntos",
                xs:12
              },            
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: pieza.ficheros,    
                tabla: "mtms_actividades_adjuntos",
                modelo: "api\\modules\\v1\\models\\piezas\\PiezaAdjuntos",
                carpeta: "piezas",
                xs:12
              },            
        ])

        const fetchData = async () => { 
            if (modo === "W") {
                const respPieza = await axios(
                    `${process.env.REACT_APP_API_URL}piezas/${id}`
                );                
                setPieza(respPieza.data)  
            }
            setDatosCargados(true);
        };    
        fetchData();   

    },[]);

    useEffect(() => {            
    if (typeof pieza !== "undefined") {        
      if(Object.keys(pieza).length > 0){   
        setDatosCargados(false)
        campos[0].value = pieza.nombre;
        campos[1].value = pieza.categoria;
        campos[3].value = pieza.largo;
        campos[4].value = pieza.ancho;
        campos[5].value = pieza.alto;
        campos[6].value = pieza.fechaCreacion;
        campos[7].value = pieza.peso;
        campos[8].value = pieza.estado;
        campos[9].value = pieza.descripcion;        
        campos[10].value = pieza.observaciones;
        campos[12].ficheros = pieza.ficheros; 
        setDatosCargados(true)  
      }
  }
    },[pieza])

    if (datoscargados){
    return (
        <FormularioEntidad 
            urlForm = "piezas"
            urlGoto = "/operativas/almacenamiento/piezas/"
            campos={campos}
        />    
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}

export default FormularioPiezas;