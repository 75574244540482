import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";


const propiedadesMateriales = {
    urlData: "materials",
    campoEtiqueta: "Nombre",
    required: true,
    name: "descripcion",
    disabled: false,
    label: "Materiales",
    value: "",
    multiple: false,
    xs:4,
}

const propiedadesDeposito = {
    urlData: "depositos",
    campoEtiqueta: "codigo",
    required: true,
    name: "deposito",
    disabled: false,
    label: "Depósito",
    value: "",
    multiple: false,
    xs:4,
}

const propiedadesCantidad = {
    xs:2,
    required: false,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    value: ''
} 



const FormularioManteniminetoMateriales = ({listadotabla}) => {  

    const [openMaterial, setOpenMaterial] = useState(false)
    const [openDeposito, setOpenDeposito] = useState(false)
    const [selectedEan, setSelectedEan] = useState(null)
    const [selectedDeposito, setSelectedDeposito] = useState(null)
    const [ean, setEan] = useState([]);
    const { id, modo } = useParams();
    const [deposito, setDeposito] = useState([]);
    const [cantidad, setCantidad] = useState(1)
    const loading = openMaterial && ean.length === 0;
    const loading2 = openDeposito && deposito.length === 0;

    useEffect(() => {
        (async () => {
            const respMaterial = await axios(process.env.REACT_APP_API_URL + propiedadesMateriales.urlData);
            setEan(Object.keys(respMaterial.data).map((key) => respMaterial.data[key]))
            
            const respDeposito = await axios(process.env.REACT_APP_API_URL + propiedadesDeposito.urlData);
            setDeposito(Object.keys(respDeposito.data).map((key) => respDeposito.data[key]))
          })();
    }, [])

    const getOptionLabelMaterial = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesMateriales.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionLabelDeposito = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesDeposito.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedMaterial = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedEan(value)
        }
        return option.Id === value.Id
    }

    const getOptionSelectedDeposito = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedDeposito(value)
        }
        return option.Id === value.Id
    }

    const handleChangeMaterial = (e, v) => { 
        setSelectedEan(v)
    }

    const handleChangeDeposito = (e, v) => {         
        setSelectedDeposito(v)
    }

    const handleChangeCantidad = (event) => { 
        setCantidad(event.target.value)
    }

    const renderInputMaterial = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesMateriales.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const renderInputDeposito = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesDeposito.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const handleClick = () => {   
        console.log(selectedEan)         
        listadotabla({
            id:uuidv4(),
            recursos:"Material",
            descripcion:selectedEan.Nombre,
            fecha:"",
            horainicio:"",
            horafin:"",
            codigo:selectedEan.id,
            deposito:selectedDeposito.codigo,
            codigoDeposito:selectedDeposito.id,
            cantidad:cantidad
        })
    }

    return (
    <>
    <Grid container  spacing={1} className="mt-2">
        
    <Grid item xs={propiedadesMateriales.xs} className="">              
        <Autocomplete
            id={propiedadesMateriales.name}
            name={propiedadesMateriales.name}
            getOptionLabel= { getOptionLabelMaterial }
            getOptionSelected={(option, value) => getOptionSelectedMaterial(option, value)}
            open={openMaterial}
            loadingText="Cargando..."
            onOpen={() => { setOpenMaterial(true); }}
            onClose={() => { setOpenMaterial(false); }}   
            loading={loading}
            label={propiedadesMateriales.label}        
            renderInput={renderInputMaterial}
            onChange={(e, v, r) => handleChangeMaterial(e, v, r)}  
            options={ean}
            value={selectedEan}
            style={{  marginLeft: 0 }}
            disabled={ modo === "R" ? true : false }
        />            
    </Grid>

    <Grid item xs={propiedadesDeposito.xs} className="">       
        <Autocomplete
            id={propiedadesDeposito.name}
            name={propiedadesDeposito.name}
            getOptionLabel= { getOptionLabelDeposito }
            getOptionSelected={(option, value) => getOptionSelectedDeposito(option, value)}
            open={openDeposito}
            loadingText="Cargando..."
            onOpen={() => { setOpenDeposito(true); }}
            onClose={() => { setOpenDeposito(false); }}   
            loading={loading2}
            label={propiedadesDeposito.label}        
            renderInput={renderInputDeposito}
            onChange={(e, v, r) => handleChangeDeposito(e, v, r)}  
            options={deposito}
            value={selectedDeposito}
            style={{  marginLeft: 0 }}
            disabled={ modo === "R" ? true : false }
        />   
    </Grid>

    <Grid item xs={propiedadesCantidad.xs} className="">
        <TextField                 
            required = {propiedadesCantidad.required}                
            id={propiedadesCantidad.name}
            name={propiedadesCantidad.name}
            disabled={ modo === "R" ? true : false }
            label={propiedadesCantidad.label}        
            value={cantidad} 
            type="number"
            onChange={handleChangeCantidad} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField>      
    </Grid>
    
    <Grid item xs={2} className="" >
    <Button disabled={ modo === "R" ? true : false } className="" variant="outlined" size="large" onClick={handleClick}>
        AÑADIR
    </Button> 
    </Grid>

    </Grid>
    </>
    )
}
export default FormularioManteniminetoMateriales;