import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";

import axios from "axios";

import LinearProgress from "@material-ui/core/LinearProgress";

import { StateContext } from '../../../../../context'
import FormularioOperacionCPE from './FormularioOperacionCPE'
import FormularioOperacionGesport from './FormularioOperacionGesport'
import FormularioOperacionProveedor from './FormularioOperacionProveedor'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import { useStyles } from "../../../../../styles/styles";

const FormularioOperacionMano = ({index, tipoForm}) => { 
  const methods = useForm();  
  const classes = useStyles();
  let { id } = useParams();
  const { control, handleSubmit} = useForm();   
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(StateContext)
  const { operacion } = state 

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert(false);
  };    


const handleSubmitPost = async (datos) => {
  //setGrabandoFormulario(true);  
  let datosFormulario = {};
  datosFormulario.clientes = datos.clientes
  datosFormulario.buque = datos.buques ? datos.buques.Id : null
  datosFormulario.muelle = datos.muelles ? datos.muelles.Id : null
  datosFormulario.operativas = datos.operativas
  datosFormulario.fechasoperativa = datos.fechasoperativa
  datosFormulario.manoplanificada = datos.manoplanificada
  switch(tipoForm){
    case "cpe":
      //console.log(operacion)
      datosFormulario.jornada = datos.jornadas.Id      
      datosFormulario.pedidoCPE = operacion.pedidoCPE
      datosFormulario.listadoMercancias = operacion.listadoMercancias
      datosFormulario.listadoPersonal = operacion.listadoPersonal
      break;
    case "gesport":     
    console.log(operacion.pedidoGesport)
    console.log(operacion.listadoGesport)
      datosFormulario.numparte = operacion.pedidoGesport ? operacion.pedidoGesport.numParte : null
      datosFormulario.fechaparte = operacion.pedidoGesport ? operacion.pedidoGesport.fechaParte : null
      datosFormulario.listadoGesport = operacion.listadoGesport
      break;
    case "proveedor":        
      datosFormulario.listadoProveedor = operacion.listadoProveedor
      break;
    default:
  }
  let user = JSON.parse(localStorage.getItem("user"));
  const AuthStr = "Bearer ".concat(user.access_token);
  const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/${id}`

  console.log(datosFormulario)
  await axios
    .put(SERVER_URL, {
      headers: {        
        "Content-Type": "application/json",
        Authorization: AuthStr,
      },
      datosFormulario,
    })
    .then((response) => {       
      console.log(response.data) 
      if(!isNaN(response.data.Id)){
        if(parseInt(response.data.Id) === parseInt(id)){      
          operacion.actualizarDetalle = true                 
            dispatch({type: "UPDATE_OPERACION", 
              payload: tipoForm,
            })
            setOpenAlert(true)
        }
      }
      })
      .catch((error) => {
        console.log("error " + error);
  });
}

return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-operacion-mano'
            onSubmit={handleSubmit((data) => {
              handleSubmitPost(data);
            })}
            className=""
          >
          
          {tipoForm === "cpe" && (            
            <FormularioOperacionCPE control={control} />            
          )}
          

          {tipoForm === "gesport" && (
            <FormularioOperacionGesport />            
          )}
          {tipoForm === "proveedor" && (            
            <FormularioOperacionProveedor/>
          )}
          </form>
          </FormProvider>

          {openAlert && 
          <AlertaMensaje 
            mensaje={"¡Parte guardado correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }  
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
</>
);    
};

export default FormularioOperacionMano;

/*
useEffect(() => {
    if (typeof operacion !== "undefined") { 
      if(Object.keys(operacion).length > 0){
        if(typeof operacion.elementoActualizar !== "undefined"){
          if (operacion.elementoActualizar.tipo === "cpe"){
            const CPEElement = operacion.CPE.filter((elemento) => elemento.Id === operacion.elementoActualizar.elemento)
            
            const abortController = new AbortController();
            const fetchData = async () => {
              
              
              setIsLoading(true)
              
              setIsLoading(false)
              return () => abortController.abort();
            };
            fetchData();
            }
        }
      }
    }
  }, [])
  */
/*
//setJornada(propiedadesJornadas.value)
              //setManoPlanificada(propiedadesManosPlanificadas.value)
*/
/*
useEffect(() => {
    if (typeof operacion !== "undefined") { 
      if(Object.keys(operacion).length > 0){
        console.log(operacion)
        setIsLoading(true)  
        setIsLoading(false)        
      }
    }
  }, [operacion])
  */
/*
              const respDatos = await axios(process.env.REACT_APP_API_URL + propiedadesJornadas.urlData);
              const jornadaSelected = respDatos.data.filter((elemento) => parseInt(elemento.Id) === parseInt(CPEElement[0].Fk_Jornada))
              propiedadesJornadas.value = jornadaSelected[0]
              */

              //const respDatosMP = await axios(process.env.REACT_APP_API_URL + propiedadesManosPlanificadas.urlData);
              //const MPSelected = respDatosMP.data.filter((elemento) => parseInt(elemento.Id) === parseInt(CPEElement[0].Fk_Mano_Planificada))
             //console.log(MPSelected)
             // if (MPSelected.length > 0){                
             //   propiedadesManosPlanificadas.value = MPSelected[0]
             //   console.log(MPSelected[0])
             // }              

//propiedadesFechaOperacion.value = operacion.Fecha_Operativa 
        //propiedadesBuques.value = operacion.Buque[0]
        //propiedadesClientes.value = operacion.Cuentas
        //propiedadesMuelles.value = operacion.Localizacion[0]
        //propiedadesOperativas.value = operacion.Operativas

  //const [jornada, setJornada] = useState(null)
  //const [manoPlanificada, setManoPlanificada] = useState(null)
  //propiedadesManosPlanificadas.urlData= `operaciones/manosplanificacion/${id}`

/*
const handleJornada = (valor) => {
  setJornada(valor)
}

const handleManoPlanificada = (valor) => {  
  setManoPlanificada(valor)
}
*/

/*<FormularioOperacionDatos control={control} />*/
          /*
            <Grid className="borderFormulario" container spacing={1}> 
              <FormFieldCombo propiedades={propiedadesClientes} control={control}/>
              <FormFieldCombo propiedades={propiedadesBuques} control={control}/>
              <FormFieldCombo propiedades={propiedadesMuelles} control={control}/>
              <FormFieldCombo propiedades={propiedadesOperativas} control={control}/>
              <FormFieldDate propiedades={propiedadesFechaOperacion} control={control} />
              {tipoForm === "cpe" && ( 
              <FormFieldCombo propiedades={propiedadesJornadas} onChangeValue={handleJornada} control={control}/>
              )}
              <FormFieldCombo propiedades={propiedadesManosPlanificadas} onChangeValue={handleManoPlanificada} control={control}/>
            </Grid>
          
*/

/*
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import Grid from '@material-ui/core/Grid';
import FormularioOperacionDatos from './FormularioOperacionDatos'
*/

/*
let propiedadesBuques = {
  urlData: "dic/diccionarios/buques",
  campoEtiqueta: "Nombre",
  required: true,
  name: "buques",
  disabled: true,
  label: "Buque",
  value: '',
  multiple: false,
  xs: 4
}
*/

/*
let propiedadesClientes = {
  urlData: "cuentas/clientes",
  campoEtiqueta: "Razon_Social",
  required: true,
  name: "clientes",
  disabled: true,
  label: "Cliente(s)",
  value: [],
  multiple: true,
  xs: 4
}
*/
/*
let propiedadesMuelles = {
  urlData: "dic/diccionarios/localizaciones",
  campoEtiqueta: "Etiqueta",
  required: true,
  name: "muelles",
  disabled: true,
  label: "Muelle",
  value: '',
  multiple: false,
  xs: 4
}
*/
/*
let propiedadesOperativas = {
  urlData: "dic/diccionarios/operativas",
  campoEtiqueta: "Etiqueta",
  required: true,
  name: "operativas",
  disabled: false,
  label: "Operación",
  value: [],
  multiple: true,
  xs: 3
}
*/

/*
let propiedadesFechaOperacion = {
  required: true,
  label: "Fecha de operativa",
  name: "fechasoperativa",
  type: "date",    
  value: '',
  xs: 4
}
*/

/*
let propiedadesJornadas = {
  urlData: "dic/diccionarios/listadojornadas",
  campoEtiqueta: "Etiqueta",
  required: true,
  name: "jornadas",
  label: "Jornada",
  value: null,
  multiple: false,
  xs: 4
}
*/

/*
let propiedadesManosPlanificadas = {  
  campoEtiqueta: "Etiqueta",
  required: false,
  name: "manoplanificada",
  label: "Mano planificada",
  value: null,
  mutiple: false,
  xs: 4
}
*/