const botoneraGastos = [
    {
      etiqueta: "Editar",
      url: '/finanzas/periodificacion/gastos/filaSeleccionada/W',
      activo: false,
    },            
    {
      etiqueta: "Crear",
      url: '/finanzas/periodificacion/gastos',
      activo: true,
    },
];
export {botoneraGastos};
  
const botoneraIngresos = [
    {
      etiqueta: "Editar",
      url: '/finanzas/periodificacion/ingresos/filaSeleccionada/W',
      activo: false,
    },            
    {
      etiqueta: "Crear",
      url: '/finanzas/periodificacion/ingresos',
      activo: true,
    },
];
export {botoneraIngresos};

const columnsGastos = [
    {
        name: "Id",
        label: "Nº",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "nombreCuenta",
        label: "Cuenta",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Concepto",
        label: "Concepto",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Base_Imponible",
        label: "Base Imponible",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "valorIgic",
        label: "Igic",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "valorTotal",
        label: "Total",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Fecha_Inicio",
        label: "Fecha Inicio",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Numero_Meses",
        label: "Meses",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "nombreCuenta_Analitica",
        label: "Cuenta Analítica",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "nombreCuenta_PyG",
        label: "Cuenta PyG",
        options: {
            filter: true,
            sort: true,
        }
    },       
];
export {columnsGastos};

const columnsIngresos = [
    {
        name: "Id",
        label: "Nº",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "nombreCuenta",
        label: "Cuenta",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Concepto",
        label: "Concepto",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Base_Imponible",
        label: "Base Imponible",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "valorIgic",
        label: "Igic",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "valorTotal",
        label: "Total",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Fecha_Inicio",
        label: "Fecha Inicio",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Numero_Meses",
        label: "Meses",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "nombreCuenta_Analitica",
        label: "Cuenta Analítica",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "nombreCuenta_PyG",
        label: "Cuenta PyG",
        options: {
            filter: true,
            sort: true,
        }
    },
];
export {columnsIngresos};