import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SearchBar from "../../../../../components/Common/SearchBar";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import FormularioProyecto from "./FormularioProyecto"
import FichaProyecto from "./FichaProyecto"
import MigasDePan  from "../../../../../components/Common/MigasDePan";

const Proyecto = (props) => {    
    return (<MainComponent />)
}
export default Proyecto;

const MainComponent = () => {
  return (
  <>
    <div className="col-8 col-main-home ">
      <SearchBar
        titulo="CRM"
        claseCabecera = "bg-comercial"
        buscar={false}
        imagenCabecera={"4-1-4-proyectos.png"}
      />
      <ChildComponent/>
    </div>
  </>
  )
}

const ChildComponent = () => {
  let { id, modo } = useParams();
  const [codigo, setCodigo] = useState("")

  const urlsMigasdepan = [
    {titulo: "Proyectos", colorTexto: "texto-comercial", urlTo: "/crm/proyectos"},
    {titulo: "Proyecto", colorTexto: "texto-comercial", urlTo: `/crm/proyectos/proyecto/${id}/${modo}`},
  ]

  const botoneraAcciones = [    
    {
      etiqueta: modo === "R" ? "Editar Proyecto" : "Cancelar Edición",
      url: 
      typeof id === "undefined" ? `/crm/proyectos/` :
      modo === "R" ? 
        `/crm/proyectos/proyecto/${id}/W` : 
        `/crm/proyectos/proyecto/${id}/R`,
      activo: true,
    },
  ];

  const changeCode = (code) => {      
    setCodigo(code)
  }

  return(
  <>
    <div className="bg-cuerpo p-10px">
      <div className="bg-white p-10px">
        <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo} />
        <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
          {modo === "R" && <FichaProyecto setCodigo={changeCode}/>}    
          {modo !== "R" && <FormularioProyecto setCodigo={changeCode}/>}   
        </div>
      </div>          
      <TableAcciones
        filaSeleccionada={-1}
        botonaccion={botoneraAcciones}
      />
    </div>    
  </>
  )
}