import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { login } from "../../../actions/auth";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default connect(({ reducerApi }) => ({ reducerApi }), {
  login,
})((props) => {
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const submitForm = () => {
    if (username === "" || password === "") {
      setError("Debe rellenar ambos campos");
      return;
    }

    props.login({ username, password });
  };

  return (
    <div className="container ">
      <div className="row align-items-center max-vh-height">
        <div className="col-6 offset-3 mr-auto bg-white p-4 rounded">
          <span className="font-weight-bolder">Iniciar sesión</span>
          <hr></hr>
          <div className="">
            <form>
              <div className="row">
                <label
                  htmlFor="email"
                  className="col-sm-6 col-form-label mt-2 text-right"
                >
                  Usuario
                </label>
                <div className="col-sm-6">
                  <TextField
                    id="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    className="form-input mt-2"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className=" row">
                <label
                  htmlFor="password"
                  className="col-sm-6 col-form-label mt-2 text-right"
                >
                  Contraseña
                </label>
                <div className="col-sm-6">
                  <TextField
                    id="password"
                    variant="outlined"
                    size="small"
                    fullWidth
                    className="form-input mt-2"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className=" row">
                <Link
                  to={"/recuperarpassword"}
                  className="col-sm-6 col-form-label mt-2 text-right"
                >
                  <label htmlFor="" className="">
                    ¿Olvido su contraseña?
                  </label>
                </Link>
                <div className="col-sm-6">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="form-input mt-2"
                    onClick={submitForm}
                  >
                    ACCEDER
                  </Button>
                </div>
              </div>

              <div className=" col-sm-12 mt-3">
                {props.reducerApi.error && (
                  <Alert severity="error" onClick={() => setError(null)}>
                    {props.reducerApi.error.message}
                  </Alert>
                )}

                {error && (
                  <Alert severity="error" onClick={() => setError(null)}>
                    {error}
                  </Alert>
                )}
              </div>
              <div className=" col-sm-12 mt-3 align-items-center mr-auto">
                {props.reducerApi.isLoading &&
                  "COMPROBANDO USUARIO Y CONTRASEÑA..."}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
