import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { StateContext } from '../../../../../context'
import { getItems } from "../../../../../tools/metodos";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

import { NUMERO_HORAS_JORNADA, MILISEGUNDOS_DIA } from "../../../../../configuraciones/conf"
import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'
import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'
import FormFieldTimeUncontrolled from '../../../../../components/Common/FormFieldTimeUncontrolled'
import FormFieldNumberUncontrolled from '../../../../../components/Common/FormFieldNumberUncontrolled'
import FormFieldTextAreaUncontrolled from '../../../../../components/Common/FormFieldTextAreaUncontrolled'

let propiedadesManosPlanificadas = { 
    urlData: "", 
    campoEtiqueta: "Etiqueta",
    required: false,
    name: "manoplanificada",
    label: "Mano planificada",
    value: null,
    mutiple: false,
    xs: 3
}

let propiedadesJornadas = {
    urlData: "dic/diccionarios/listadojornadas",
    campoEtiqueta: "Etiqueta",
    required: true,
    name: "jornadas",
    label: "Jornada",
    value: null,
    multiple: false,
    xs: 3
}

let propiedadesNumPedido = {
    required: true,
    name: "numeropedido",
    disabled: false,
    label: "Nº Pedido",
    value: "",
    xs:3,
}

let propiedadesModalidad = {
    urlData: "dic/diccionarios/modalidadestrabajo",
    campoEtiqueta: "Etiqueta",
    campoDescripcion: "Descripcion",
    required: true,
    name: "combomodalidad",
    disabled: false,
    label: "Modalidad de trabajo",
    value: "",
    multiple: false,
    xs:6,
}

let propiedadesOperativas = {
    urlData: "dic/diccionarios/operativasmercancias",
    campoEtiqueta: "Id,Etiqueta",
    required: true,
    name: "combooperativasmercancias",
    disabled: false,
    label: "Operativa",
    value: "",
    multiple: false,
    xs:6,
}

let propiedadesFechaPedido = {
    required: true,
    label: "Fecha de pedido",
    name: "fechapedido",
    type: "date",    
    value: '',
    xs: 3
}

let propiedadesHoraInicio = {
    xs:3,
    required: true,
    label: "Hora inicio",
    name: "horainicio",
    type: "time",    
    value: ''
}

let propiedadesHoraFin = {
    xs:3,
    required: true,
    label: "Hora fin",
    name: "horafin",
    type: "time",    
    value: ''
}

let propiedadesHorasExtra = {
    xs:3,
    required: false,
    label: "Horas Extra",
    name: "horasextra",
    type: "number",    
    value: 0
}

let propiedadesHorasTrabajadas = {
    xs:3,
    required: false,
    label: "Horas Trabajadas",
    name: "horasttrabajadas",
    type: "number",    
    value: 0
}

let propiedadesObservaciones = {
    xs:12, 
    required: false,
    label: "Observaciones",
    name: "observaciones",
    type: "textArea",    
    value: '',
    lineas: 2
}

function calculaHorasTotales(horaInicio, horaFin){
    let dInicio = new Date()
    let hours = horaInicio.substring(0, 2);
    let minutes = horaInicio.substring(3, 5);
    dInicio.setHours(hours);
    dInicio.setMinutes(minutes);
    dInicio.setSeconds("00");    

    let dFin = new Date()
    hours = horaFin.substring(0, 2);
    minutes = horaFin.substring(3, 5);
    dFin.setHours(hours);
    dFin.setMinutes(minutes);

    let diferenciaHora = 0
    diferenciaHora =  dFin - dInicio
    diferenciaHora = diferenciaHora < 0 ? diferenciaHora + MILISEGUNDOS_DIA : diferenciaHora
    let horasExtras = 0;
    let horasTrabajadas = 0;            
         
    horasTrabajadas = Math.round(diferenciaHora/3600000);
    horasExtras = Math.round(diferenciaHora/3600000)-NUMERO_HORAS_JORNADA;
            
    if ( horasExtras <= 0 ){        
        horasExtras=0;
    }
    
    return [horasTrabajadas, horasExtras]
}

const FormularioOperacionPedidoCPE = ({control, onChangeStep}) => { 
    let { id } = useParams();    
    const { state, dispatch } = useContext(StateContext)    
    const { operacion } = state     
    const [isLoading, setIsLoading] = useState(false)   

    propiedadesManosPlanificadas.urlData= `operaciones/manosplanificacion/${id}`

    const handleChageStep = async (valor) => {  
        let continuar = true
        //AVISAR SI EXISTE EL PARTE
        const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/existepartecpe?partecpe=${operacion.datosPedido.numPedido}`
        let items = await getItems(SERVER_URL);
        if(isNaN(items)){
            let text = "Este número de parte ya está en el sistema en las siguientes operaciones:\n" + items + "\n¿Desea Continuar?";
            if (window.confirm(text) == true) {
                continuar = true;
            } else {
                continuar = false
            }
        }

        if(continuar && onChangeStep)
            onChangeStep(valor)
    }    

    useEffect(() => {        
        if (typeof operacion !== "undefined") {             
            if(Object.keys(operacion).length > 0){      
                
                if(operacion.datosPedido && Object.keys(operacion.datosPedido).length === 0){
                    propiedadesFechaPedido.value = ""
                    propiedadesManosPlanificadas.value = null 
                    propiedadesJornadas.value = null
                    propiedadesNumPedido.value = ""
                    propiedadesModalidad.value = ""
                    propiedadesOperativas.value = ""
                    propiedadesHoraInicio.value = "";
                    propiedadesHoraFin.value = "";
                    propiedadesHorasExtra.value = 0;
                    propiedadesHorasTrabajadas.value = 0;
                    propiedadesObservaciones.value = "";
                }
                if(operacion.datosPedido && Object.keys(operacion.datosPedido).length > 0){
                    //console.log(operacion.datosPedido)
                    setIsLoading(true)
                    propiedadesFechaPedido.value = operacion.datosPedido.fechaPedido
                    propiedadesManosPlanificadas.value = operacion.datosPedido.manoPlanificada
                    propiedadesJornadas.value = operacion.datosPedido.jornada
                    propiedadesNumPedido.value = operacion.datosPedido.numPedido
                    propiedadesModalidad.value = operacion.datosPedido.modalidad
                    propiedadesOperativas.value = operacion.datosPedido.operativa
                    propiedadesHoraInicio.value = operacion.datosPedido.horaInicio
                    propiedadesHoraFin.value = operacion.datosPedido.horaFin
                    propiedadesHorasExtra.value = operacion.datosPedido.horasExtra
                    propiedadesHorasTrabajadas.value = operacion.datosPedido.horasTrabajadas
                    propiedadesObservaciones.value = operacion.datosPedido.observaciones 
                    setIsLoading(false)
                }                
            }
        }        
    },[operacion])    

    const handleChangeFechaPedido = (valor) => {        
        propiedadesFechaPedido.value = valor
        dispatch({type: "SET_DATOS_PEDIDO", payload: {'fechaPedido': valor}})                 
    }

    const handleManoPlanificada = (valor) => {   
        //console.log(valor)  
        if(valor){            
            propiedadesManosPlanificadas.value = valor     
            if(valor.Jornada)  {
                propiedadesJornadas.value = valor.Jornada  
                propiedadesHoraInicio.value = valor.Jornada.Hora_Inicio
                propiedadesHoraFin.value = valor.Jornada.Hora_Fin

                const horasTotales = calculaHorasTotales( propiedadesHoraInicio.value, propiedadesHoraFin.value)
                propiedadesHorasTrabajadas.value = horasTotales[0]
                propiedadesHorasExtra.value = horasTotales[1]

            }    

            dispatch({type: "SET_DATOS_PEDIDO", payload: {
                'manoPlanificada': valor, 
                'jornada': propiedadesJornadas.value,
                'horaInicio': propiedadesHoraInicio.value,
                'horaFin': propiedadesHoraFin.value,
                'horasTrabajadas': propiedadesHorasTrabajadas.value,
                'horasExtra': propiedadesHorasExtra.value
            }})   
        }
    }

    const handleJornada = (valor) => {        
        
        propiedadesJornadas.value = valor     
        propiedadesHoraInicio.value = valor.Hora_Inicio
        propiedadesHoraFin.value = valor.Hora_Fin
        const horasTotales = calculaHorasTotales(propiedadesHoraInicio.value, propiedadesHoraFin.value)
        propiedadesHorasTrabajadas.value = horasTotales[0]
        propiedadesHorasExtra.value = horasTotales[1]

        dispatch({type: "SET_DATOS_PEDIDO", payload: {
            'jornada': valor,
            'horaInicio': propiedadesHoraInicio.value,
            'horaFin': propiedadesHoraFin.value,
            'horasTrabajadas': propiedadesHorasTrabajadas.value,
            'horasExtra': propiedadesHorasExtra.value
        }})                    
    } 
    
    const handleChangeNumPedido = (valor) => {
        propiedadesNumPedido.value = valor
        dispatch({type: "SET_DATOS_PEDIDO", payload: {'numPedido': valor}})    
    }

    const handleChangeComboModalidad = (valor) => {       
        propiedadesModalidad.value = valor
        dispatch({type: "SET_DATOS_PEDIDO", payload: {'modalidad': valor}})        
    }

    const handleChangeComboOPerativas = (valor) => {        
        propiedadesOperativas.value = valor
        dispatch({type: "SET_DATOS_PEDIDO", payload: {'operativa': valor}})  
    }    

    const onChangeTime = (nombre, valor) => {        
        let horasTotales = 0
  
        switch(nombre){
            case 'horainicio':                
                horasTotales = calculaHorasTotales(valor, propiedadesHoraFin.value)                
                propiedadesHoraInicio.value = valor
                propiedadesHorasTrabajadas.value = horasTotales[0]
                propiedadesHorasExtra.value = horasTotales[1]
                break;
            case 'horafin':
                horasTotales = calculaHorasTotales(propiedadesHoraInicio.value, valor)
                propiedadesHoraFin.value = valor
                propiedadesHorasTrabajadas.value = horasTotales[0]
                propiedadesHorasExtra.value = horasTotales[1]                
                break;
            default:
        }

        dispatch({type: "SET_DATOS_PEDIDO", payload: {            
            'horaInicio': propiedadesHoraInicio.value,
            'horaFin': propiedadesHoraFin.value,
            'horasTrabajadas': propiedadesHorasTrabajadas.value,
            'horasExtra': propiedadesHorasExtra.value
        }})  
        
    }

    const handleChangeHorasExtra = (valor) => {
        propiedadesHorasExtra.value = valor
        dispatch({type: "SET_DATOS_PEDIDO", payload: {'horasExtra': valor}})
    }

    const handleChangeHorasTrabajadas = (valor) => {
        propiedadesHorasTrabajadas.value = valor
        dispatch({type: "SET_DATOS_PEDIDO", payload: {'horasTrabajadas': valor}})
    }

    const handleChangeObservaciones = (valor) => {        
        propiedadesObservaciones.value = valor
        dispatch({type: "SET_DATOS_PEDIDO", payload: {'observaciones': valor}})        
    } 
    
    const handleCleanForm = () => {
        /*
        propiedadesFechaPedido.value = ""
        propiedadesManosPlanificadas.value = ""
        propiedadesJornadas.value = ""
        propiedadesNumPedido.value = ""
        propiedadesHoraInicio.value = ""
        propiedadesHoraFin.value = ""
        propiedadesHorasExtra.value = 0
        propiedadesHorasTrabajadas.value = 0
        propiedadesModalidad.value = ""
        propiedadesOperativas.value = ""
        propiedadesObservaciones.value = ""
        */
        dispatch({type: "UPDATE_OPERACION", payload: "cpe"})    
        if(onChangeStep)
            onChangeStep(0) 
    }

    return (       
    <>
        {isLoading && (
            <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
        )} 
        {!isLoading && (
        <>
            <Grid container spacing={1} className="borderFormulario CPE1 mt-2"> 
                <FormFieldDateUncontrolled propiedades={propiedadesFechaPedido} onChangeValue={handleChangeFechaPedido} />                    
                <FormFieldComboUncontrolled propiedades={propiedadesManosPlanificadas} onChangeValue={handleManoPlanificada} /> 
                <FormFieldComboUncontrolled propiedades={propiedadesJornadas} onChangeValue={handleJornada} /*control={control}*//>  
                <FormFieldTextUncontrolled propiedades={propiedadesNumPedido} onChangeValue={handleChangeNumPedido} />

                <FormFieldTimeUncontrolled propiedades={propiedadesHoraInicio} onChangeTime={onChangeTime} />
                <FormFieldTimeUncontrolled propiedades={propiedadesHoraFin} onChangeTime={onChangeTime}/>
                <FormFieldNumberUncontrolled propiedades={propiedadesHorasExtra} onChangeValue={handleChangeHorasExtra} />
                <FormFieldNumberUncontrolled propiedades={propiedadesHorasTrabajadas} onChangeValue={handleChangeHorasTrabajadas} />
                    
                <FormFieldComboUncontrolled propiedades={propiedadesModalidad} onChangeValue={handleChangeComboModalidad} />
                <FormFieldComboUncontrolled propiedades={propiedadesOperativas} onChangeValue={handleChangeComboOPerativas} />
                    
                <FormFieldTextAreaUncontrolled propiedades={propiedadesObservaciones} onChangeValue={handleChangeObservaciones} />                
            </Grid>
            <Grid className="" container spacing={1}> 
                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                    <Button className="float-right" variant="contained" color="primary" 
                        onClick={() => handleCleanForm() }>
                        CANCELAR
                    </Button>
                </Grid>   
                    <Grid item xs={2}>
                        <Button className="float-right" variant="contained" color="primary" 
                            onClick={() => handleChageStep(0) }>
                            ANTERIOR
                        </Button>
                    </Grid>
                    
                    <Grid item xs={2}>
                        <Button className="float-right" variant="contained" color="primary" 
                            onClick={() => handleChageStep(2) }>
                            SIGUIENTE
                        </Button>
                    </Grid>  
            </Grid>     
        </>
        )}
    </>
    )


}

export default FormularioOperacionPedidoCPE;