import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import axios from "axios";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";


const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const PeriodificacionFormulario = () => { 
    const { tipo, id, modo } = useParams();           
    const classes = useStyles(); 
    const history = useHistory();
    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [periodificacion, setPeriodificacion] = useState([]) 
    const [proveedor, setProveedor] = useState() 

    const esIngresos = tipo.includes("ingresos")    

    const classDiv3 = "col-3 mt-2 mb-2" 
    
    const botoneraAccionesIngresos = [    
      {
        etiqueta: modo === "R" ? "Editar ingreso" : "Cancelar",
        url: 
        typeof id === "undefined" ? `/finanzas/periodificacion/` :
        modo === "R" ? 
          `/finanzas/periodificacion/ingresos/${id}/W` : 
          `/finanzas/periodificacion/ingresos/${id}/R`,
        activo: true,          
      },
      /*
      {
        etiqueta: "Crear ingreso",
        url: "/finanzas/periodificacion/ingresos/",
        activo: true,
      },
      */
    ];

    const botoneraAccionesGastos = [    
        {
          etiqueta: modo === "R" ? "Editar gasto" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/finanzas/periodificacion/` :
          modo === "R" ? 
            `/finanzas/periodificacion/gastos/${id}/W` : 
            `/finanzas/periodificacion/gastos/${id}/R`,
          activo: true,          
        },
        /*
        {
          etiqueta: "Crear gasto",
          url: "/finanzas/periodificacion/gastos/",
          activo: true,
        },
        */
    ];

    const botoneraAcciones = esIngresos ? botoneraAccionesIngresos : botoneraAccionesGastos
        
    const urlsMigasdepan = [
      {titulo: "Periodificación", colorTexto: "texto-comercial", urlTo: "/finanzas/periodificacion"},
      {titulo: esIngresos ? "Ingreso" : "Gasto", colorTexto: "texto-comercial", urlTo: `/finanzas/periodificacion/${esIngresos ? "ingresos" : "gastos"}/${id}/${modo}`},
    ]

    const handleChangeCuenta = (valor) => {      
      setProveedor(valor)
      if (typeof campos !== "undefined") {        
        if(Object.keys(campos).length > 0){   
          campos[8].value = ""
          campos[9].value = ""
        }
      }
    }

    const handleChangeIgic = (valor) => {  
      console.log(valor)  
      console.log(campos)
      //console.log(parseFloat(periodificacion.Base_Imponible)+(periodificacion.Base_Imponible*parseFloat(periodificacion.Igic.Valor)/100))  
      /*
      setProveedor(valor)
      if (typeof campos !== "undefined") {        
        if(Object.keys(campos).length > 0){   
          campos[8].value = ""
          campos[9].value = ""
        }
      }
      */
    }

    const handleChangeBaseImponible = (valor) => {  
      console.log(valor)    
      /*
      setProveedor(valor)
      if (typeof campos !== "undefined") {        
        if(Object.keys(campos).length > 0){   
          campos[8].value = ""
          campos[9].value = ""
        }
      }
      */
    }

    useEffect(() => {
      if (modo==="R") {
        history.push({
          pathname: esIngresos ? `/finanzas/periodificacion/ingresos/${id}/W` : `/finanzas/periodificacion/gastos/${id}/W`,      
        });
      }
    },[])    

    useEffect(() => {  
      console.log(esIngresos)    
      let tabla = "mtms_periodificacion_gastos_adjuntos"
      let modelo = "api\\modules\\v1\\models\\periodificacion\\PeriodificacionGastosAdjuntos"
      let carpeta = "periodificacion-gastos"
      if(esIngresos){
        tabla = "mtms_periodificacion_ingresos_adjuntos"
        modelo = "api\\modules\\v1\\models\\periodificacion\\PeriodificacionIngresosAdjuntos"
        carpeta = "periodificacion-ingresos"
      }
        setCampos([  

          {
            urlData: "cuentas",
            campoEtiqueta: "Razon_Social",
            required: true,
            label: "Cuenta",
            name: "cuenta",
            disabled: false,
            type: "combo",           
            multiple:false,   
            value: '',
            xs:4,
            onChangeValue: handleChangeCuenta
         },
         {
          required: true,
          label: "Concepto:",
          name: "concepto",
          type: "text",    
          disabled: false, 
          value: '',                
          xs:8
        },
        {
          required: true,
          label: "Base imponible",
          name: "baseimponible",
          type: "number",                 
          value: '',          
          xs:4,
          onChangeValue: handleChangeBaseImponible
        }, 
        {
          urlData: "dic/diccionarios/igic",
          campoEtiqueta: "Etiqueta,Valor",
          campoId: "Id",
          required: true,
          label: "IGIC",
          name: "igic",
          disabled: false,
          type: "combo",
          multiple: false, 
          value: "",          
          xs:4,
          onChangeValue: handleChangeIgic
        },
        {
          required: true,
          label: "Total",
          name: "total",
          type: "number", 
          disabled: true,                
          value: '',          
          xs:4
        }, 
        {
          required: true,
          label: "Fecha inicio",
          name: "fechainicio",
          type: "date",  
          value: '',
          xs:4
        },
        {
          required: true,
          label: "Nº meses",
          name: "nmeses",
          type: "number",                 
          value: '',          
          xs:4
        },
        {
          required: true,
          label: "Código periodificación:",
          name: "codigo",
          type: "text",    
          disabled: false, 
          value: '',                
          xs:4
        },
        {
          urlData: proveedor ? `dic/diccionarios/cuentasanaliticas/${proveedor.Id}` : "dic/diccionarios/cuentasanaliticas/0",
          campoEtiqueta: "Codigo,Etiqueta",
          campoId: "Id",
          campoDesactivar: "Mostrar",
          required: true,
          label: "Cuenta analítica",
          name: "cuentaAnalitica",
          disabled: true,
          type: "combo",
          multiple: false, 
          value: "",          
          xs:6,
        },
        {
          urlData: proveedor ? `dic/diccionarios/cuentaspyg/${proveedor.Id}` : "dic/diccionarios/cuentaspyg/0",
          campoEtiqueta: "Codigo,Etiqueta",
          campoId: "Id",
          campoDesactivar: "Mostrar",
          required: true,
          label: "Cuenta pérdidas y ganancias",
          name: "cuentaPyG",
          disabled: true,
          type: "combo",
          multiple: false, 
          value: "",          
          xs:6,
        },    
        {
          required: false,
          label: "Observaciones",
          name: "Observaciones",
          type: "textArea",
          lineas: 4,  
          value: '',                
          xs:6
        }, 
        {
          classButton: classDiv3,
          required: false,
          label: "",
          name: "ficherosadjuntos",
          type: "filesupload",   
          tabla: tabla,
          xs:5
        },            
        {
          required: false,
          label: "",
          name: "ficherosremotos",
          type: "files", 
          ficheros: [],    
          tabla: tabla,
          modelo: modelo,
          carpeta: carpeta,
          xs:4
        },        
        ])
       
        if (!datoscargados && modo === "W") {
          const fetchData = async () => {             
                  const urlDatos = esIngresos ? "periodificacioningresos" : "periodificaciongastos"
                  const respPeriodificacion = await axios(
                      `${process.env.REACT_APP_API_URL}${urlDatos}/${id}`
                  );                
                  setPeriodificacion(respPeriodificacion.data) 
                  setDatosCargados(true);       
          };    
          fetchData();  
        }

        if (!modo) {      
          setDatosCargados(true);
        }       
    }, [ modo, id])

    useEffect(() => {            
        if (typeof periodificacion !== "undefined") {        
          if(Object.keys(periodificacion).length > 0){ 
            console.log(periodificacion)
            setDatosCargados(false)     
            campos[0].value = periodificacion.Cuenta  
            campos[1].value = periodificacion.Concepto  
            campos[2].value = periodificacion.Base_Imponible
            campos[3].value = periodificacion.Igic
            campos[4].value = parseFloat(periodificacion.Base_Imponible)+(periodificacion.Base_Imponible*parseFloat(periodificacion.Igic.Valor)/100)
            campos[5].value = periodificacion.Fecha_Inicio
            campos[6].value = periodificacion.Numero_Meses 
            campos[7].value = periodificacion.Codigo_Periodificacion 
            campos[8].value = periodificacion.CuentaAnalitica 
            campos[8].disabled = false
            campos[9].value = periodificacion.CuentaPyG
            campos[9].disabled = false
            campos[10].value = periodificacion.Observaciones
            campos[12].ficheros = periodificacion.ficheros;
            setProveedor(periodificacion.Cuenta) 
            setDatosCargados(true)  
          }
      }
    },[periodificacion])

    
  
      if (typeof campos !== "undefined") {        
        if(Object.keys(campos).length > 0){   
          //console.log(campos)
          //console.log(proveedor)
          campos[8].urlData = proveedor ? `dic/diccionarios/cuentasanaliticas/${proveedor.Id}` : "dic/diccionarios/cuentasanaliticas/0"
          campos[8].disabled = proveedor ? false : true
          campos[9].urlData = proveedor ? `dic/diccionarios/cuentaspyg/${proveedor.Id}` : "dic/diccionarios/cuentaspyg/0"
          campos[9].disabled = proveedor ? false : true
        }
      }
  
      const codigo = typeof datos !== "undefined" ? periodificacion.codigo : "";
      const urlGoto = esIngresos ?  "/finanzas/periodificacion/ingresos/" : "/finanzas/periodificacion/gastos/" 
      const urlForm = esIngresos ? "periodificacioningresos" : "periodificaciongastos"

      if (datoscargados){
        return (    
             <div className="col-8 col-main-home ">
                <SearchBar
                titulo="Finanzas"
                claseCabecera = "bg-gestion"
                buscar={false}
                imagenCabecera={"6-2-1-tesoreria.png"}
                />
                <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                          
                    <FormularioEntidad 
                        urlForm = { urlForm }
                        urlGoto = { urlGoto }
                        campos={campos}
                        action = {() => setTimeout(function () {window.location.reload();}, 1000)}
                        type="add"
                    />
                          
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
              </div>
               
        
        )
        }
        else {
        return (              
            <div className={classes.linear}>
                <CircularProgress />
            </div>
        );
        }  
}

export default PeriodificacionFormulario;