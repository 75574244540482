import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";


class MenuizqGestion extends Component { 

  render() {
    const { isAuthUser, user: { isContanetActive } } = this.props.reducerApi;   
    return (
      <div className="col-12 p-0">
        <div className="card mb-0">

          <div className="bg-gestion p-0" id="headingGestion">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-white"
                type="button"
              >
                GESTIÓN
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-caret-down-fill float-right"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </button>
            </h2>
          </div>

          <div className="wrapper card-header p-2 ">
            <nav id="sidebar">
              
              <ul className="list-unstyled components">
                
                <li className=" p-gestion mb-1">
                  <a
                    href="#gestionSubmenu1"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    Administración
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2 "
                    id="gestionSubmenu1"
                  >
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/administracion/compras" : "/"}
                        activeClassName="activeGestion"
                      >
                        {"Compras"}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/administracion/ventas" : "/"}
                        activeClassName="activeGestion"
                      >
                        {"Facturas ventas"}
                      </NavLink>
                    </li>
                    {isContanetActive && (
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/administracion/exportacion" : "/"}
                        activeClassName="activeGestion"
                      >
                        {"Exportación datos"}
                      </NavLink>
                    </li>
                    )}
                  </ul>
                </li>
                {/*
                <li className=" p-gestion mb-1">
                  <a
                    href="#gestionSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    Compras
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2 "
                    id="gestionSubmenu"
                  >
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/compras/pedidos" : "/"}
                        className=""
                      >
                        {"Hojas de pedido"}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/compras/gestioncompras" : "/"}
                        className=""
                      >
                        {"Gestión compras"}
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className=" p-gestion mb-1">
                  <a
                    href="#facturasSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    Facturas
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2"
                    id="facturasSubmenu"
                  >
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/facturas/compras" : "/"}
                        className=""
                      >
                        {"Compras"}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/facturas/ventas" : "/"}
                        className=""
                      >
                        {"Ventas"}
                      </NavLink>
                    </li>
                  </ul>
                </li>
                */}
                <li className=" p-gestion mb-1">
                  <a
                    href="#finanzasSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    Finanzas
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2"
                    id="finanzasSubmenu"
                  >
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/finanzas/tesoreria" : "/"}
                        activeClassName="activeGestion"
                      >
                        {"Tesorería"}
                      </NavLink>
                    </li>
                    {/*
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/finanzas/periodificacion" : "/"}
                        activeClassName="activeGestion"
                      >
                        {"Periodificación"}
                      </NavLink>
                    </li>
                    */}
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/finanzas/explotacion" : "/"}
                        activeClassName="activeGestion"
                      >
                        {"Explotación"}
                      </NavLink>
                    </li>
                    {/*
                    <li className="nav-item">
                      <NavLink
                        to={isAuthUser ? "/finanzas/controlgestion" : "/"}
                        activeClassName="activeGestion"
                      >
                        {"Control gestión"}
                      </NavLink>
                    </li>
                    */}
                  </ul>
                </li>

                <li className=" p-gestion mb-1">
                  <a href="#rrhhSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >RRHH</a>
                  <ul className="collapse list-unstyled pl-2 m-2" id="rrhhSubmenu" >
                  {/* OPCIÓN MENÚ AMPLIO
                    <li className=" p-gestion mb-1">
                      <a href="#rrhhSubmenu2" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >Talento</a>
                      <ul className="collapse list-unstyled pl-2 m-2" id="rrhhSubmenu2" >
                        <li className="nav-item">
                          <NavLink to={isAuthUser ? "/rrhh/talento" : "/"} className="" >{"Selección"} </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to={isAuthUser ? "/rrhh/gestionpersonal" : "/"} className="" >{"Formacion"} </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to={isAuthUser ? "/rrhh/talento" : "/"} className="" >{"Seguridad"} </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to={isAuthUser ? "/rrhh/gestionpersonal" : "/"} className="" >{"Documentación"} </NavLink>
                        </li>
                      </ul>
                    </li>
                    */}

                    <li className="nav-item">
                      <NavLink to={isAuthUser ? "/rrhh/talento" : "/"} activeClassName="activeGestion" >{"Talento"} </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={isAuthUser ? "/rrhh/gestionpersonal" : "/"} activeClassName="activeGestion" >{"Gestión personal"} </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={isAuthUser ? "/rrhh/gestioncpe" : "/"} activeClassName="activeGestion" >{"Gestión CPE"} </NavLink>
                    </li>
                  </ul>
                </li>

                <li className=" p-gestion mb-1">
                  <a href="#informesSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle" >Informes</a>
                  <ul className="collapse list-unstyled pl-2 m-2" id="informesSubmenu" >
                  <li className="nav-item">
                      <NavLink to={isAuthUser ? "/informes/general" : "/"} activeClassName="activeGestion" >{"General"} </NavLink>
                    </li>
                  <li className="nav-item">
                      <NavLink to={isAuthUser ? "/informes/rentabilidad" : "/"} activeClassName="activeGestion" >{"Rentabilidad"} </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={isAuthUser ? "/informes/ventas" : "/"} activeClassName="activeGestion" >{"Ventas"} </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={isAuthUser ? "/informes/maquinaria" : "/"} activeClassName="activeGestion" >{"Maquinaria"} </NavLink>
                  </li>

                   
                    {/*
                      <li className="nav-item">
                        <NavLink to={isAuthUser ? "/rrhh/gestionpersonal" : "/"} activeClassName="activeGestion" >{"Gestión personal"} </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={isAuthUser ? "/rrhh/gestioncpe" : "/"} activeClassName="activeGestion" >{"Gestión CPE"} </NavLink>
                      </li>
                    */}
                  </ul>
                </li>
              
              
              </ul>
            </nav>
          </div>
        
        </div>
      </div>
    );
  }
}

export default connect(({ reducerApi }) => ({ reducerApi }))(MenuizqGestion);
