import React, { useState, useEffect, useContext  } from "react";
import { StateContext } from '../../../../../context'
import { useParams } from "react-router-dom";

import axios from "axios";

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import FormularioOperacionEspecialidadesCPE from './FormularioOperacionEspecialidadesCPE'
import FormularioOperacionMercanciasCPE from './FormularioOperacionMercanciasCPE'
import FormularioOperacionDatos from './FormularioOperacionDatos'
import FormularioOperacionPedidoCPE from './FormularioOperacionPedidoCPE'

function compruebaDatosPantalla2(datosPedido){
    let errores = 0;    
    if(datosPedido){
        if(!datosPedido.fechaPedido) errores++;
        if(!datosPedido.jornada) errores++;
        if(!datosPedido.numPedido) errores++;
        if(!datosPedido.horaInicio) errores++;
        if(!datosPedido.horaFin) errores++;
        if(!datosPedido.modalidad) errores++;
        if(!datosPedido.operativa) errores++;
    }else errores++;    
    return errores === 0;
}

function compruebaDatosPantalla3(listadoMercancia){
    let errores = 0;    
    if(!listadoMercancia || listadoMercancia.length === 0){ 
        errores++;          
    }   
    return errores === 0;
}

function compruebaDatosPantalla4(listadoPersonal){    
    let errores = 0;    
    if(!listadoPersonal || listadoPersonal.length === 0){ 
        errores++;          
    }   
    return errores === 0;
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;    

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}
    
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const FormularioOperacionCPE = ({control=null}) => {   
    let { id } = useParams();      
    const { state, dispatch } = useContext(StateContext)    
    const { operacion } = state   

    const [mercanciaDisabled, setMercanciaDisabled] = useState(false)
    const [cpeDisabled, setCpeDisabled] = useState(false)    
    
    const [value, setValue] = React.useState(0);   

    useEffect(() => {
        return function cleanup() {
            dispatch({type: "UPDATE_OPERACION", payload: "cpe"})
            handleChageStep(0);
        };
    }, [])

    const guardaDatosOperacion = () => {
        handleSubmitPostCPE() 
    }

    const handleSubmitPostCPE = async () => {
        let datosFormulario = {};
        datosFormulario.Id = operacion.datosPedido.IdpedidoCPE || null
        datosFormulario.Fk_Operacion = id
        datosFormulario.fechasoperativa = operacion.Fecha_Operativa
        datosFormulario.Fk_Jornada = operacion.datosPedido.jornada.Id
        datosFormulario.NPedido = operacion.datosPedido.numPedido 
        datosFormulario.Fecha_Pedido = operacion.datosPedido.fechaPedido 
        datosFormulario.Hora_Inicio = operacion.datosPedido.horaInicio 
        datosFormulario.Hora_Fin = operacion.datosPedido.horaFin
        datosFormulario.Horas_Extra = operacion.datosPedido.horasExtra
        datosFormulario.Horas_Trabajadas = operacion.datosPedido.horasTrabajadas
        datosFormulario.Observaciones = operacion.datosPedido.observaciones
        datosFormulario.Fk_Mano_Planificada = operacion.datosPedido.manoPlanificada ? operacion.datosPedido.manoPlanificada.Id : null
        datosFormulario.Fk_TipoMercancia = operacion.datosPedido.operativa.Id
        datosFormulario.Fk_Modalidad_Trabajo = operacion.datosPedido.modalidad.Id
        datosFormulario.listadoMercancias = operacion.listadoMercancias
        datosFormulario.listadoPersonal = operacion.listadoPersonal
        datosFormulario.tipoForm = "cpe"
        datosFormulario.cuentas = operacion.CuentasCliente

        handleSubmitPost(datosFormulario, "cpe")
    }

    const handleSubmitPost = async (datosFormulario, tipoForm) => {

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}operaciones/${id}`
      
        await axios
          .put(SERVER_URL, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datosFormulario,
          })
          .then((response) => {
            if(!isNaN(response.data)){
              if(parseInt(response.data) === parseInt(id)){      
                operacion.actualizarDetalle = true  
                dispatch({type: "UPDATE_OPERACION", 
                    payload: tipoForm,
                })
                alert("Parte guardado correctamente")
              }
            }
            })
            .catch((error) => {
                dispatch({type: "UPDATE_OPERACION", 
                    payload: tipoForm,
                })
              console.log("error " + error);
        });
    }

    const handleChageStep = (valor) => {
        switch(valor){
            case 0:                             
                setValue(valor)
                break;
            
            case 1:                
                if(operacion.Fecha_Operativa) setValue(valor)
                else alert("Debe seleccionar una fecha de operación")
                break;             

            case 2:
                if(compruebaDatosPantalla2(operacion.datosPedido))
                    setValue(valor)
                else alert("Compruebe los datos obligatorios");
                break;

            case 3:
                if(compruebaDatosPantalla3(operacion.listadoMercancias))
                    setValue(valor)
                else alert("Debe añadir al menos un tipo de mercancía");
                break;

            case 4:
                if(compruebaDatosPantalla4(operacion.listadoPersonal)){
                    guardaDatosOperacion()
                    setValue(0)
                }
                    
                else alert("Debe añadir al menos a un estibador");                
                break;

            default:
            break;
        }
        
    }   
    
    return (
    <>
    <Paper className="mt-4" square>
        <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
        >
            <Tab label="DATOS OPERATIVA"/>
            <Tab label="DATOS PEDIDO" />
            <Tab label="MERCANCÍA" disabled={mercanciaDisabled}/>
            <Tab label="CPE" disabled={cpeDisabled}/>                
        </Tabs>
    </Paper>

    <TabPanel value={value} index={0}>
        <FormularioOperacionDatos control={control} onChangeStep={handleChageStep}/>
    </TabPanel>
        
    <TabPanel value={value} index={1}>   
        <FormularioOperacionPedidoCPE control={control} onChangeStep={handleChageStep}/>            
    </TabPanel>

    <TabPanel value={value} index={2}>
        <Grid container spacing={1} className="borderFormulario CPE1 mt-2">
            <Grid item xs={12} className="" >
            <FormularioOperacionMercanciasCPE control={control} onChangeStep={handleChageStep}/>
            </Grid>            
        </Grid>
    </TabPanel>

    <TabPanel value={value} index={3}>
        <Grid container spacing={1} className="borderFormulario CPE1 mt-2">           
            <Grid item xs={12} className="" >
            <FormularioOperacionEspecialidadesCPE control={control} onChangeStep={handleChageStep}/>
            </Grid>
        </Grid>
    </TabPanel>        
    </>
    )
}

export default FormularioOperacionCPE;