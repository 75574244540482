import React, { useState, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import {columnsProductos, columnsTarifas} from './ProductosConf'
import {botoneraProductos, botoneraTarifas, handleClickClonaTarifa, ClonaTarifa} from './BotonesConf'
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";

import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

const Productos = (props) => { 
  const history = useHistory();     
  const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOpProductos')) || 0);   
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const columnasCuerpoTabla = useRef("");  
  const urlDatosCuerpoTabla = useRef("");
  const urlDoubleClickCuerpoTabla = useRef("");
  const columnIdCuerpoTabla = useRef("");
  const botonaccionCuerpoTabla= useRef("");
  const tarifaEliminar = useRef(-1);
  const productoEliminar = useRef(-1);
  const [abrirDialogo, setAbrirDialogo] = useState(false)
  const [abrirDialogoProducto, setAbrirDialogoProducto] = useState(false)

  //url: '/ventas/productos/portfolio/filaSeleccionada/W',
  const botoneraProductos = [
    {
       etiqueta: "Editar",
       url: '/ventas/productos/portfolio/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       function: () => handleClickDeleteProducto(filaSeleccionada),
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/ventas/productos/portfolio',
       activo: true,
     }, 
   ];

  const botoneraTarifas = [
    {
      etiqueta: "Editar",
      url: '/ventas/productos/tarifa/filaSeleccionada/W',
      activo: false,
    }, 
    {
     etiqueta: "Clonar",
     function: () => handleClickClonaTarifa(filaSeleccionada),
     activo: false,
   }, 

    {
      etiqueta: "Eliminar",
      function: () => handleClickDeleteTarifa(filaSeleccionada),
      activo: false,
    }, 

    {
      etiqueta: "Crear",
      url: '/ventas/productos/tarifa/',
      activo: true,
    }, 
  ];

  const handleClickDeleteProducto = async (fila) => {
    console.log(fila)
    productoEliminar.current = fila
    setAbrirDialogoProducto(true)
  }

  const handleClickDeleteTarifa = async (fila) => {
    console.log(fila)
    tarifaEliminar.current = fila
    setAbrirDialogo(true)
  }

  const handleCerrarDialogoProducto = (valor) => {  
    console.log(valor)     
    setAbrirDialogoProducto(false)

    if(valor){    
      const fetchData = async () => {          
      
          let user = JSON.parse(localStorage.getItem("user"));
          const AuthStr = "Bearer ".concat(user.access_token);
          const SERVER_URL = `${process.env.REACT_APP_API_URL}productos/${productoEliminar.current}`    
          console.log(SERVER_URL)    
    
          await axios
          .delete( SERVER_URL, { 
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },        
          })
          .then((response) => {     
            console.log(response.data)     
            if(!isNaN(response.data)){          
              if(parseInt(response.data)){  
                productoEliminar.current = -1
                window.location.reload();
                /* 
                history.push({
                  pathname: `/ventas/productos`,       
                });
                */
              }
            }
          })
          .catch((error) => {
            console.log("error " + error);
          }); 
         
      }
      fetchData();
    }      
  }

  const handleCerrarDialogo = (valor) => {  
    console.log(valor)     
    setAbrirDialogo(false)

    if(valor){    
      const fetchData = async () => {          
      
          let user = JSON.parse(localStorage.getItem("user"));
          const AuthStr = "Bearer ".concat(user.access_token);
          const SERVER_URL = `${process.env.REACT_APP_API_URL}tarifas/${tarifaEliminar.current}`    
          console.log(SERVER_URL)    
    
          await axios
          .delete( SERVER_URL, { 
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },        
          })
          .then((response) => {     
            console.log(response.data)     
            if(!isNaN(response.data)){          
              if(parseInt(response.data)){  
                tarifaEliminar.current = -1
                window.location.reload();
                /* 
                history.push({
                  pathname: `/ventas/productos`,       
                });
                */
              }
            }
          })
          .catch((error) => {
            console.log("error " + error);
          }); 
         
      }
      fetchData();
    }      
  }



  const handleClickClonaTarifa = async (fila) => {  
    
    if(fila){
      let datos = {"fila": fila}
      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}tarifas/clonatarifa`    
      console.log(SERVER_URL)
      console.log(datos)

      await axios
      .post( SERVER_URL, { 
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
        datos,
      })
      .then((response) => {     
        console.log(response.data)     
        if(!isNaN(response.data)){          
          if(parseInt(response.data)){  
            window.location.reload();
            /* 
            history.push({
              pathname: `/ventas/productos`,       
            });
            */
          }
        }
      })
      .catch((error) => {
        console.log("error " + error);
      });
    }
  }

  const marcarFila = (fila) => {    
    setFilaSeleccionada(fila);
  }

  const getId = (idOperacion) => { 
    localStorage.setItem('idOpProductos', idOperacion)  
    setIdOperacion(idOperacion)
  }    


  if(idOperacion === 0 ){
    columnasCuerpoTabla.current = columnsProductos;
    urlDatosCuerpoTabla.current ="productos";
    urlDoubleClickCuerpoTabla.current = "productos/portfolio/";
    columnIdCuerpoTabla.current = "Id";
    botonaccionCuerpoTabla.current = botoneraProductos;
  }

  if(idOperacion === 1 ){
    columnasCuerpoTabla.current = columnsTarifas;
    urlDatosCuerpoTabla.current ="tarifas";
    urlDoubleClickCuerpoTabla.current = "productos/tarifa/";
    columnIdCuerpoTabla.current = "Id";
    botonaccionCuerpoTabla.current = botoneraTarifas;
  }
  
  return (          
      <div className="col-8 col-main-home "> 
        <SearchBar
          titulo="Ventas"
          claseCabecera = "bg-comercial"
          buscar={false}
          imagenCabecera={"4-2-3-productos.png"}
        />    

        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">

            <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Productos
              </h6>
            </div>

            <hr className="m-0 bg-comercial mb-1" />

            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
              <Pasos pasos={['Portfolio', 'Tarifas Clientes']} idPasoActivo={idOperacion} changePasoActivo={getId} />
                  
              <CuerpoTablaPiezas
                  columnas = {columnasCuerpoTabla.current}
                  urlDatos = {urlDatosCuerpoTabla.current}
                  urlDoubleClick = {urlDoubleClickCuerpoTabla.current}
                  columnId = {columnIdCuerpoTabla.current}
                  getFilaSeleccionada={marcarFila}
                /> 

            </div>

          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
              <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla.current}
              />            
            </div>
          </div>   


          <div>
        
      </div>


        </div>

        { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la tarifa?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
        { abrirDialogoProducto && (<AlertaDialogo titulo={"¿Desea eliminar el producto?"} abrir={abrirDialogoProducto} cerrar={handleCerrarDialogoProducto}/>)}
      </div>   
  );
}
export default Productos;
