import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import { useParams } from "react-router-dom";
import {usePlanificacion} from './planificacion-context'
import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'
//import Button from '@material-ui/core/Button';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuidv4 } from 'uuid';
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import Loading from "../../../../../components/Common/Loading";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import IconButton from '@material-ui/core/IconButton';
//import { TextField } from "@material-ui/core";

let propiedadesPlusMercancias = {
  urlData: "dic/diccionarios/grupomercancias",
  campoEtiqueta: "Etiqueta",
  campoId: "Id",
  required: false,
  name: "combogrupomercancia",
  disabled: false,
  label: "Plus",
  value: "",
  multiple: false,
  xs:2,
}

let propiedadesTiposMercancias = {
  urlData: "dic/diccionarios/tipomercancias",
  campoEtiqueta: "Id,Etiqueta",
  campoId: "Id",
  required: false,
  name: "combomercancia",
  disabled: false,
  label: "Clase",
  value: "",
  multiple: false,
  xs:3,
}

let propiedadesMercancias = {
  urlData: "dic/diccionarios/subtipomercancias/0",
  campoEtiqueta: "Etiqueta",
  campoId: "Id",
  required: false,
  name: "combosubtipomercancia",
  disabled: false,
  label: "Mercancía",
  value: "",
  multiple: false,
  xs:2,
}

let propiedadesMovimientos = {
  urlData: "dic/diccionarios/tipomovimientos",
  campoEtiqueta: "Etiqueta",
  campoId: "Id",
  required: false,
  name: "combo-movimiento",
  disabled: false,
  label: "Movimientos",
  value: "",
  multiple: false,
  xs:2,
}

let propiedadesNumBultos = {
  xs:1,
  required: false,
  label: "Nº Bultos",
  name: "nbultos",
  type: "number",    
  value: ''
}

let propiedadesNumKilos = {
  xs:1,
  required: false,
  label: "Toneladas",
  name: "nkilos",
  type: "number",    
  value: ''
}

let propiedadesDescripcionMercancia = {
  xs:12,
  required: false,
  label: "Descripción trabajo",
  name: "descripcionMercancia",
  type: "text",    
  value: '',
  classes: 'd-none'
}
  
const FormularioPlanificacionManoMercancias = ({index, setAlert}) => {
  let { id, modo } = useParams();
    const { control, handleSubmit, setValue  } = useForm();
    const [turno, setTurno] = useState("") 
    const [empleados, setEmpleados] = useState([])
    const [jornadas, setJornadas] = useState([])
    const [bultos, setBultos] = useState(0)
    const [kilos, setKilos] = useState(0)    
    const [openAlert, setOpenAlert] = React.useState(false);
    const modoLectura = modo === "R" ? true : false
    const [idManoActiva, setIdManoActiva] = useState(0);   
    const [listadoRecursos, setListadoRecursos] = useState([])  
    const [listadoMercancias, setListadoMercancias] = useState([])
    const [isLoading, setIsLoading] = useState(false); 

    const columns = [
      { field: 'id', headerName: 'id', hide: true },
      { field: 'idPlus', headerName: 'idPlus', width: 80, hide: true },
      { field: 'idClaseMercancia', headerName: 'idClaseMercancia', hide: true },
      { field: 'idTipoMercancia', headerName: 'idTipoMercancia', hide: true },
      { field: 'idMovimiento', headerName: 'idMovimiento', hide: true },
      { field: 'idMano', headerName: 'idMano', hide: true },
      { field: 'Mano', headerName: 'Mano', hide: true },
      { field: 'plus', headerName: 'Plus', width: 100 },
      { field: 'clase', headerName: 'Clase', width: 200 },
      { field: 'mercancia', headerName: 'Mercancía', width: 150 },
      { field: 'movimiento', headerName: 'Movimiento', width: 100 },
      { field: 'nbultos', headerName: 'Bultos', width: 80 },
      { field: 'nkilos', headerName: 'Toneladas', width: 80 },           
      { field: 'idRecurso', headerName: 'idRecurso', hide: true },
      { field: 'recurso', headerName: 'Recurso', width: 180 },
      { field: 'cantidad', headerName: 'Cantidad', width: 80 },
      { field: 'coste', headerName: 'Coste', width: 80 },
      { field: 'total', headerName: 'Total', width: 100 },
      { field: '', headerName: '', width: 80, 
          renderCell: (params) => (
              <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
          ), },
  ];

    const {planificacion, manosPlanificacion, manosMercancia, setManosMercancia, 
        datosMercancia, setDatosMercancia, datosJornadas, setDatosJornadas} = usePlanificacion();
    
    const handleRemoveElement = async (element) =>{
      const nuevoListado2 = datosJornadas.filter(el => {        
        return parseInt(el.elementos.Id) !== parseInt(element.idRecurso)
      })      
      setDatosJornadas(nuevoListado2)  
    }
    

    useEffect(() => {      
      let filas=[]
      const datosMano = datosJornadas.filter(datosMano => parseInt(datosMano.elementos.mano) === parseInt(index))
     
      datosMano.forEach(datoJornada => {
        let fila = {
          id: uuidv4(),
          idPlus: datoJornada.elementos.plus ? datoJornada.elementos.plus.Id : null,
          idClaseMercancia: datoJornada.elementos.clase ? datoJornada.elementos.clase.Id : null,
          idTipoMercancia: datoJornada.elementos.mercancia ? datoJornada.elementos.mercancia.Id : null,
          idMovimiento: datoJornada.elementos.movimiento ? datoJornada.elementos.movimiento.Id : null,
          clase: datoJornada.elementos.clase ? datoJornada.elementos.clase.Etiqueta : datoJornada.elementos.descripcionMercancia ? datoJornada.elementos.descripcionMercancia : null,
          mercancia: datoJornada.elementos.mercancia ? datoJornada.elementos.mercancia.Etiqueta : null,
          movimiento: datoJornada.elementos.movimiento ? datoJornada.elementos.movimiento.Etiqueta : null,
          nbultos: datoJornada.elementos.bultos ? datoJornada.elementos.bultos : null,
          nkilos: datoJornada.elementos.kilos ? datoJornada.elementos.kilos : null,          
          idRecurso: datoJornada.elementos.Id,
          recurso: datoJornada.elementos.Nombre,
          cantidad: datoJornada.elementos.cantidad,
          coste: datoJornada.elementos.Coste,
          total: parseFloat(datoJornada.elementos.Coste) * parseFloat(datoJornada.elementos.cantidad),
        }        
        filas.push(fila)
      })
        setListadoRecursos(filas)
      return () => {}
    }, [datosJornadas])

  const {manoActiva, setManoActiva} = usePlanificacion();
    useEffect(() => {     
      if(index){      
        manosPlanificacion.forEach(element => {     
          if(element.Mano === index){        
            setIsLoading(true) 
                          
            setIdManoActiva(element.Id);
            setIsLoading(false) 
          }        
        });  
    }
    },[index, manosPlanificacion]);


    useEffect(() => {        
        const Mano = manosPlanificacion.filter(mano => mano.Mano === index)      
        if(Mano.length > 0 ) {
          setIdManoActiva(Mano[0].Id) 
        }     
        return () => {}
    }, [])

    
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };  

    const handleChangeComboGrupoMercancias = (valor) => {    
        if(valor){      
          propiedadesPlusMercancias.value = valor 
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "plus")      
          manoMercanciaTmp.push({nombre:"plus", valor: valor})
          setManosMercancia(manoMercanciaTmp) 
        } 
        else{
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "plus")
          setManosMercancia(manoMercanciaTmp)
        }
    }
    
    const handleChangeComboTipoMercancias = (valor) => {   
      
      if(valor){      
        if(valor.Id === 9998 || valor.Id === 9999) {
          propiedadesDescripcionMercancia.classes = ""
          //propiedadesDescripcionMercancia.value = ""
        }else{
          propiedadesDescripcionMercancia.classes = "d-none"
          propiedadesDescripcionMercancia.value = ""
        }
        propiedadesMercancias.urlData = 'dic/diccionarios/subtipomercancias/' + valor.Id   
        propiedadesTiposMercancias.value = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "clase")
        manoMercanciaTmp.push({nombre:"clase", valor: valor})
        setManosMercancia(manoMercanciaTmp)
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "clase")
        setManosMercancia(manoMercanciaTmp)
      }        
    }
    
    const handleChangeComboMercancias = (valor) => {
        if(valor){
          propiedadesMercancias.value = valor
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "mercancia")
          manoMercanciaTmp.push({nombre:"mercancia", valor: valor})
          setManosMercancia(manoMercanciaTmp)        
        }
        else{
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "mercancia")
          setManosMercancia(manoMercanciaTmp)
        }
    }
    
    const handleChangeComboMovimientos = (valor) => {
        if(valor){
          propiedadesMovimientos.value = valor
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "movimiento")
          manoMercanciaTmp.push({nombre:"movimiento", valor: valor})
          setManosMercancia(manoMercanciaTmp)       
        }   
        else{
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "movimiento")
          setManosMercancia(manoMercanciaTmp)
        } 
    }
    
    const handleChangeNumMovs = (valor) => {
        if(valor){
          propiedadesNumBultos.value = valor
          //setBultos(valor)
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "bultos")
          manoMercanciaTmp.push({nombre:"bultos", valor: valor})
          setManosMercancia(manoMercanciaTmp)  
        }
        else{
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "bultos")
          propiedadesNumBultos.value = ''
          //setBultos('')
          setManosMercancia(manoMercanciaTmp)
    
        } 
    }
    
    const handleChangeNumKilos = (valor) => { 
          
        if(valor){
          //setKilos(valor)
          propiedadesNumKilos.value = valor
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "kilos")
            manoMercanciaTmp.push({nombre:"kilos", valor: valor})
            setManosMercancia(manoMercanciaTmp)    
        }
        else{
          let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "kilos")
          propiedadesNumKilos.value = ''
          //setKilos('')
          setManosMercancia(manoMercanciaTmp)
          
        } 
    }

    const handleChangeDescripcionMercancia = (valor) => {
       
      if(valor){
        propiedadesDescripcionMercancia.value = valor
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "descripcionMercancia")
          manoMercanciaTmp.push({nombre:"descripcionMercancia", valor: valor})
          setManosMercancia(manoMercanciaTmp)    
      }
      else{
        let manoMercanciaTmp = manosMercancia.filter(item => item.nombre !== "descripcionMercancia")
        propiedadesDescripcionMercancia.value = ''
        setManosMercancia(manoMercanciaTmp)      
      }        
    }

    const handleSubmitPost = async (datos) => {    

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = process.env.REACT_APP_API_URL +  'planificacionmanorecursos';     
  
      if(id) datos["idPlanificacion"] = id;    
      else datos["idPlanificacion"] = planificacion; 
  
      datos["idMano"] = manoActiva;        
        
      let rec = datosJornadas.filter(     
        (dato) => parseInt(dato.elementos.mano) === parseInt(index)
      )    
      datos["recursos"] = rec;   
      

          
      if (idManoActiva > 0) {        
        await axios
          .put(SERVER_URL + "/" + idManoActiva, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {
            if(!isNaN(response.data)){            
              setOpenAlert(true);
              setIdManoActiva(response.data)
            }          
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
      } 
      else{       
        await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {      
          if(!isNaN(response.data)){
            setOpenAlert(true);
            setIdManoActiva(response.data)
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
        });
      }
    }    

    const calculaCosteTotalManos = (mano=0) => {
      let costeTotalMano = 0;  
      let ajusteCPE = 0;
      if(datosJornadas.length > 0 ){       
        datosJornadas.forEach(element => { 
          if(mano){
            if(mano === parseInt(element.elementos.mano)){
              let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
              let cantidad = parseFloat(element.elementos.cantidad)
              costeTotalMano +=  coste * cantidad;     
              ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0
            } 
          }else{
            let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
            let cantidad = parseFloat(element.elementos.cantidad)
            costeTotalMano +=  coste * cantidad;     
            ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0             
          }
        });  
      }
      return (costeTotalMano+ajusteCPE).toFixed(2)
    }

    const actualizaCostesMano = async () => {
      const datosManoActual = datosJornadas.filter(item => item.elementos.mano === index && item.elementos.EspecialidadCPE) 
      
      if(datosManoActual.length > 0){
        
        let plus = manosMercancia.filter(item => item.nombre === "plus")      
        let clase = manosMercancia.filter(item => item.nombre === "clase")
        let mercancia = manosMercancia.filter(item => item.nombre === "mercancia")
        let movimiento = manosMercancia.filter(item => item.nombre === "movimiento")
        let bultos = manosMercancia.filter(item => item.nombre === "bultos")
        let kilos = manosMercancia.filter(item => item.nombre === "kilos")
        
        const manoAModificar = manosPlanificacion.filter(item => item.Id === idManoActiva)
        let datos = {
            fecha: manoAModificar[0].Fecha,
            jornada: manoAModificar[0].Turno,
            horasExtra: manoAModificar[0].Horas_Extra,
            plus: plus.length > 0 ? plus[0].valor : null, 
            clase: clase.length > 0 ? clase[0].valor : null,
            mercancia: mercancia.length > 0 ? mercancia[0].valor : null, 
            movimiento: movimiento.length > 0 ? movimiento[0].valor : null, 
            bultos: bultos.length > 0 ? bultos[0].valor : null,
            kilos: kilos.length > 0 ? kilos[0].valor : null,
            mano: manoActiva,
            idManoActiva: idManoActiva,
            idOperativa: manoAModificar[0].TipoOperativa.Id,
            idPlanificacion: id ? id : planificacion,
            modoTrabajo: manoAModificar[0].ModalidadTrabajo,
        }

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL +  'planificaciones/actualizarcostesmano'; 

        if (idManoActiva > 0) {        
          await axios
            .put(SERVER_URL, {
              headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              datos,
            })
            .then((response) => {
              
              const elementosActualizados = response.data

              let datosManoActualizar = datosJornadas.filter(item => item.elementos.mano === index) 
              let datosOtrasManos = datosJornadas.filter(item => item.elementos.mano !== index) 
              
              elementosActualizados.forEach(element => {
                datosManoActualizar.forEach(elemento => {
                  if(elemento.elementos.id === element.Id && elemento.elementos.EspecialidadCPE){
                    
                    elemento.elementos.Coste = element.Total
                    elemento.elementos.bultos = bultos.length > 0 ? bultos[0].valor : null
                    elemento.elementos.clase = clase.length > 0 ? clase[0].valor : null
                    elemento.elementos.idClaseMercancia = clase.length > 0 ? clase[0].valor.Id : null
                    elemento.elementos.idMovimiento = movimiento.length > 0 ? movimiento[0].valor.Id : null
                    elemento.elementos.idTipoMercancia = mercancia.length > 0 ? mercancia[0].valor.Id : null
                    elemento.elementos.idPlus = plus.length > 0 ? plus[0].valor.Id : null
                    elemento.elementos.kilos = kilos.length > 0 ? kilos[0].valor : null
                    elemento.elementos.mercancia = mercancia.length > 0 ? mercancia[0].valor : null
                    elemento.elementos.movimiento = movimiento.length > 0 ? movimiento[0].valor : null
                  }
                });
              });
              setDatosJornadas([...datosOtrasManos, ...datosManoActualizar])
              
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
            });
        }
      }
    }

    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);  
    let costeTotalMano = 0; 
    costeTotalMano = numberFormat2.format(calculaCosteTotalManos(index))

    return (
      <>    
      {!isLoading && (
        <>
          {/* FORMULARIO */}
          <div className="">
            <form
              onSubmit={handleSubmit((datos) => {
              handleSubmitPost(datos);
              })}
              className="row"
            >
           
            <Grid container spacing={1} className="CPE1 mt-2"> 
  
                <FormFieldCombo propiedades={propiedadesPlusMercancias} control={control} onChangeValue={handleChangeComboGrupoMercancias} />
                <FormFieldCombo propiedades={propiedadesTiposMercancias} control={control} onChangeValue={handleChangeComboTipoMercancias} />
                <FormFieldCombo propiedades={propiedadesMercancias} control={control} onChangeValue={handleChangeComboMercancias} />
                <FormFieldCombo propiedades={propiedadesMovimientos} control={control} onChangeValue={handleChangeComboMovimientos} />
                <FormFieldTextUncontrolled propiedades={propiedadesNumBultos} control={control} onChangeValue={handleChangeNumMovs}/>
                <FormFieldTextUncontrolled propiedades={propiedadesNumKilos} control={control} onChangeValue={handleChangeNumKilos}/>
                <FormFieldTextUncontrolled propiedades={propiedadesDescripcionMercancia} control={control} onChangeValue={handleChangeDescripcionMercancia}/>
                  
                {modo === "W"  && 
                  <IconButton color="primary" aria-label="actualizar costes" onClick={() => {actualizaCostesMano()}}>
                    <AutorenewIcon />
                  </IconButton> 
                }       

                <Grid item xs={12} className="" style={{ display: 'flex', flexWrap: 'wrap',  height: 320, marginLeft: 0 }}>
                  <DataGrid 
                    rows={listadoRecursos} 
                    columns={columns} 
                    pageSize={20} 
                    checkboxSelection = {false}
                    hideFooter = {true}
                    hideFooterPagination = {true}
                    hideFooterRowCount = {true}
                    hideFooterSelectedRowCount = {true}
                  />
                </Grid>
            </Grid>
  
  
                {openAlert && 
                <AlertaMensaje 
                  mensaje={"¡Mano guardada correctamente!"} 
                  isOpen={openAlert} 
                  tipoMensaje="success"
                  cerrar={handleCloseAlert}
                />
                }  
  
                <div className="col-12 pt-2 mb-4">  
                  {modo === "W"  &&                   
                  <button 
                  disabled={modoLectura}
                  className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation">
                    GRABAR
                  </button>
                  }                
                </div>
            </form>      
          </div>  
        </>
      )}
      <div>{isLoading && <Loading isLoading={isLoading} />}</div>
  
      </>
        );    
    


    return (
        <Grid container spacing={1} className="borderFormulario CPE1 mt-2">
          <FormFieldComboUncontrolled propiedades={propiedadesPlusMercancias} onChangeValue={handleChangeComboGrupoMercancias} control={control}/>
          <FormFieldComboUncontrolled propiedades={propiedadesTiposMercancias} onChangeValue={handleChangeComboTipoMercancias} control={control}/>
          <FormFieldComboUncontrolled propiedades={propiedadesMercancias} onChangeValue={handleChangeComboMercancias} control={control} />
          <FormFieldComboUncontrolled propiedades={propiedadesMovimientos} onChangeValue={handleChangeComboMovimientos} control={control}/>
          <FormFieldTextUncontrolled propiedades={propiedadesNumBultos} onChangeValue={handleChangeNumMovs}/>
          <FormFieldTextUncontrolled propiedades={propiedadesNumKilos} onChangeValue={handleChangeNumKilos}/>
          {/*
          <div className="col-2 pt-2">  
            <Button className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation" 
            variant="contained" onClick={() => handleAddMercancia()}>Añadir</Button>
          </div> 
          */}

          <Grid item xs={12} className="" style={{ display: 'flex', flexWrap: 'wrap',  height: 320, marginLeft: 0 }}>
          <DataGrid 
            rows={listadoRecursos} 
            columns={columns} 
            pageSize={20} 
            checkboxSelection = {false}
            hideFooter = {true}
            hideFooterPagination = {true}
            hideFooterRowCount = {true}
            hideFooterSelectedRowCount = {true}
          />
          </Grid>                  
        </Grid>
    )
};


  export default FormularioPlanificacionManoMercancias;