import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import PasosPlanificacion from "./PasosPlanificacion"
import {usePlanificacion} from './planificacion-context'
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

const Planificacion = (props) => {  
    let { id, modo } = useParams(); 
    const { codigoPlanificacion } = usePlanificacion(); 
    const history = useHistory(); 
    const [abrirDialogo, setAbrirDialogo] = useState(false)
    //const planificacionValidar = useRef(-1);


    const urlsMigasdepan = [
      {titulo: "Planificaciones", colorTexto: "texto-comercial", urlTo: "/operativas/planificaciones"},
      {titulo: "Planificación", colorTexto: "texto-comercial", urlTo: `/operativas/planificaciones/planificacion/${id}/${modo}`},
    ]

    const handleClickValidarPlanificacion = async (id) => {

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}planificaciones/validaplanificacion`    

      await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          id,
        })
        .then((response) => { 
          if(!isNaN(response.data)){ 
            if(parseInt(response.data) === parseInt(id)){   
              history.push({
                pathname: `/operativas/planificaciones/`,       
              });
            }
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
        });
    }

  const {
    datosPlanificacion, setDatosPlanificacion, setDatosDesplegables, 
    datosFormulario, setDatosFormulario, setCodigoPlanificacion, 
    setPlanificacion, costesDirectos, setCostesDirectos, 
    datosPresupuesto, setDatosPresupuesto, setDatosMercancia,
    datosJornadas, setDatosJornadas, setManosPlanificacion,
    actualizarCargaPlanificacion, setActualizarCargaPlanificacion} = usePlanificacion();

    const cargaDatos = () => {
      const fetchData = async () => {          
        const respPedido = await axios(
          `${process.env.REACT_APP_API_URL}planificaciones/${id}`
        );        
                  
        if (Object.keys(respPedido.data).length > 0) {           
          setDatosPlanificacion(respPedido.data);
          setCodigoPlanificacion(respPedido.data.Codigo)          
          setCostesDirectos(respPedido.data.costesDirectos)
          setDatosPresupuesto({
            Beneficio_Industrial: respPedido.data.Beneficio_Industrial,
            Costes_Indirectos: respPedido.data.Costes_Indirectos,
            Fecha_Presupuesto: respPedido.data.Fecha_Presupuesto,
            Descripcion: respPedido.data.Descripcion_Presupuesto,
            costesDirectos: respPedido.data.costesDirectos
          }) 

          let notaPedido="";  
          if(respPedido.data.PedidoCliente && respPedido.data.PedidoCliente.length > 0 ){
            notaPedido = respPedido.data.PedidoCliente[0].Codigo
          }

          let cotizacion="";  
          if(respPedido.data.Cotizacion && respPedido.data.Cotizacion.length > 0 ){
            cotizacion = respPedido.data.Cotizacion[0].Codigo
          }           
          
          setDatosFormulario({
            Fecha_Solicitud: respPedido.data.Fecha_Solicitud,
            canal: respPedido.data.Fk_Fuente,
            Fk_Planificacion: respPedido.data.Id,
            //cliente: idsClientes,
            Clientes: respPedido.data.CuentasCliente,
            contacto: respPedido.data.Fk_Contacto,
            Contactos: respPedido.data.Contacto,
            Referencia_Cliente: respPedido.data.Referencia_Cliente,
            Fecha_Operativa: respPedido.data.Fecha_Operativa,
            Fk_Localizacion: respPedido.data.Fk_Localizacion,
            Localizacion: respPedido.data.Localizacion,
            Fk_Localizacion2: respPedido.data.Fk_Localizacion2,
            Localizacion2: respPedido.data.Localizacion2,
            Fk_Procedencia: respPedido.data.Fk_Procedencia, 
            Procedencia: respPedido.data.Procedencia, 
            Fk_Destino: respPedido.data.Fk_Destino,   
            Destino: respPedido.data.Destino,               
            buque: respPedido.data.Fk_Buque,
            Buque: respPedido.data.Buque,
            buque2: respPedido.data.Fk_Buque2,
            Buque2: respPedido.data.Buque2,
            Operativa: respPedido.data.Operativa,
            //operativa: idsOperativas, 
            //servicios: idsServicios,  
            //Toneladas_Carga: respPedido.data.Toneladas_Carga,
            //Toneladas_Descarga: respPedido.data.Toneladas_Descarga,
            //Movimientos_Carga: respPedido.data.Movimientos_Carga,
            //Movimientos_Descarga: respPedido.data.Movimientos_Descarga,
            //Movimientos_Remociones: respPedido.data.Movimientos_Remociones,
            Descripcion: respPedido.data.Descripcion,              
            notapedido: notaPedido, 
            cotizacion: cotizacion,           
            ficheros: respPedido.data.ficheros,
            eta: respPedido.data.ETA,
            codCarpetaPoseidon: respPedido.data.codCarpetaPoseidon,
          })
        }
      }

      let respManosPlanificacion;

      const fetchDataNumManos = async () => {                 
        respManosPlanificacion = await axios(
          `${process.env.REACT_APP_API_URL}planificaciones/manosplanificacion/${id}`
        )   
        if (Object.keys(respManosPlanificacion.data).length > 0) {          
          let elementos = [];              
          respManosPlanificacion.data.forEach(element => {            
            const fetchDataManos = async () => {                            
              const respRecursosManos = await axios(`${process.env.REACT_APP_API_URL}planificacionesmanos/${element.Id}`);
              if(respRecursosManos.data){   
                respRecursosManos.data.recursos.forEach(element => {    
                  elementos.push({ elementos: element});          
                });
                setDatosJornadas( elementos );
              }                                      
            }      
            fetchDataManos();
          })                 
        }
        setManosPlanificacion(respManosPlanificacion.data);
      } 

      fetchData();     
      fetchDataNumManos();

    }

    useEffect(() => {
      const fetchDataDesplegables = async () => {
        const respFormulario = await axios(
          process.env.REACT_APP_API_URL + "dic/diccionarios/formulariocrearpedidocliente"
        ); 
  
        setDatosDesplegables({
          buques: respFormulario.data.buques,
          clientes: respFormulario.data.clientes,
          contactos: respFormulario.data.contactos,
          canales: respFormulario.data.canalSolicitud,
          muelles: respFormulario.data.localizaciones,
          operativas: respFormulario.data.tiposOperativas,            
          servicios: respFormulario.data.servicios,  
          categorias: respFormulario.data.categorias,    
          puertos: respFormulario.data.puertos, 
          productos: respFormulario.data.productos, 
        });
      }  
      fetchDataDesplegables();  
      cargaDatos();
    },[]) 
  
    useEffect(() => {  
      if( (modo === "R" || modo === "W") && id > 0 ){ 
        cargaDatos(); 
      }

    },[id])  

    useEffect(() => {
      if (actualizarCargaPlanificacion) cargaDatos();
      return () => {
        setActualizarCargaPlanificacion(false)
      }
    }, [actualizarCargaPlanificacion])

    const activaValidacion = datosPlanificacion.Estado !== 2 && modo === "W"


    const handleCerrarDialogo = (valor) => {       
      setAbrirDialogo(false)
      if(valor){    
        const fetchData = async () => {  
          let user = JSON.parse(localStorage.getItem("user"));
          const AuthStr = "Bearer ".concat(user.access_token);
          const SERVER_URL = `${process.env.REACT_APP_API_URL}planificaciones/validaplanificacion`    

          await axios
            .post(SERVER_URL, {
              headers: {        
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              id,
            })
            .then((response) => { 
              if(!isNaN(response.data)){ 
                if(parseInt(response.data) === parseInt(id)){   
                  history.push({
                    pathname: `/operativas/planificaciones/`,       
                  });
                }
              }
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
            });

        }
        fetchData();
      }
    }

    const validarPlanificacion = (id) => {      
      setAbrirDialogo(true)
    }
   
    const botoneraAcciones = [ 
      {
        etiqueta: modo === "R" ? "Editar Planificación" : "Cancelar Edición",
        url: 
        typeof id === "undefined" ? `/operativas/planificaciones/` :
        modo === "R" ? 
          `/operativas/planificaciones/planificacion/${id}/W` : 
          `/operativas/planificaciones/planificacion/${id}/R`,          
        activo: true,
      },
      {
        etiqueta: "Validar Planificación",
        function: () => validarPlanificacion(id), //handleClickValidarPlanificacion(id),
        activo: activaValidacion,
      }, 
  ];

  const codigo = codigoPlanificacion
  return (
    <>        
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Operativas"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-2-1-planificacion.png"}
        />   
        <>
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>            
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
            { abrirDialogo && (<AlertaDialogo titulo={"¿Desea validar la planificación?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo} textoBoton2={"VALIDAR"}/>)}
              <PasosPlanificacion />
            </div>
          </div>
          <TableAcciones
            filaSeleccionada={-1}
            botonaccion={botoneraAcciones}
          />
        </div>
        </>       
      </div>     
    </>
  );
}

export default Planificacion;


