import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
//import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'
import FormularioMultiple from './FormularioMultiple';

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioTarifas = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 
    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [tarifas, setTarifas] = useState([]) 
    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 
        setCampos([
            {
                urlData: "cuentas/listadoclientes",
                campoEtiqueta: "Razon_Social",
                required: true,
                label: "Cliente:",
                name: "Cliente",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },   
            {
                required: true,
                label: "Fecha:",
                name: "Fecha_Alta",
                type: "date",  
                value: '',
                xs:4
            },   
            {
                urlData: "dic/diccionarios/estadostarifacliente",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Estado:",
                name: "Estado",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            }, 
            {
                required: false,
                label: "Observaciones",
                name: "Observaciones",
                type: "textArea",
                lineas: 4,                
                value: '',
                xs:12
            },
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_actividades_adjuntos",
                xs:5
            },            
            {
                required: false,
                label: "Documentacion",
                name: "ficheros",
                type: "files", 
                ficheros: tarifas.ficheros,    
                tabla: "mtms_actividades_adjuntos",
                modelo: "api\\modules\\v1\\models\\tarifas\\TarifaAdjuntos",
                carpeta: "tarifa",
                xs:4
            },                      
    ])
    const fetchData = async () => { 
        if (modo === "W") {
            const respTarifas = await axios(
                `${process.env.REACT_APP_API_URL}tarifas/${id}`
            );                
            setTarifas(respTarifas.data)  
        }
        setDatosCargados(true);
    };    
    fetchData();       
    },[]);

    useEffect(() => {            
        if (typeof tarifas !== "undefined") {        
        if(Object.keys(tarifas).length > 0){              
            setDatosCargados(false)
            campos[0].value = tarifas.Cliente;
            campos[1].value = tarifas.Fecha_Alta;
            campos[2].value = tarifas.Estado;
            campos[3].value = tarifas.Observaciones;
            campos[5].value = tarifas.ficheros;
            setDatosCargados(true)  
        }
    }
    },[tarifas])

if (datoscargados){
    return (
        <FormularioMultiple
            urlForm = "tarifas"
            urlGoto = "/ventas/productos/tarifas"
            campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}

export default FormularioTarifas;