import React, { useState, useEffect } from "react";
import axios from "axios";

import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'

import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { TextField } from "@material-ui/core";

import SaveIcon from '@material-ui/icons/Save';

const FormularioGastosVariosCPE = () => {
    const [listadoGastosVariosCPE, setListadoGastosVariosCPE] = useState([])
    const [openAlert, setOpenAlert] = useState(false);

    const columns =  [
        { field: 'id', headerName: 'Nº', width: 50, sortable: false, hide: false },
        { field: 'Etiqueta', headerName: 'Concepto', width: 250, sortable: true, hide: false },
        { field: 'Descripcion', headerName: 'Descripción', width: 350, sortable: true, hide: false },
    
        { field: 'Importe', headerName: 'Cifra',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 120, sortable: true, editable: true },
        { field: 'Unidad', headerName: 'Unidad', width: 80, sortable: false, hide: false },
    
        { field: '', headerName: '', width: 50, sortable: false, 
            renderCell: (params) => (
                <SaveIcon onClick={() => {                    
                    updateGastosVarios(params.row)                                       
                }}/>
              ), 
        },        
    ]

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }  
        setOpenAlert(false);
    };    

    const fetchData = async () => {
        const urlDatos = "gastosvarioscpes"
        axios.get(process.env.REACT_APP_API_URL + urlDatos)
        .then((response) => {          
            console.log(response.data)
          setListadoGastosVariosCPE(response.data);           
        })
        .catch((error) => {
            console.log(error);
            setListadoGastosVariosCPE([]);
        }) 
    };

    useEffect(() => {         
        fetchData();            
    }, [openAlert]); 

    const handleCellEditCommit = React.useCallback(        
      ({ id, field, value }) => {  
        if (field === 'Importe') {
          const updatedRows = listadoGastosVariosCPE.map((row) => {
            if (row.id === id) {  
              return { ...row,  Importe: value };
            }
            return row;
          });           
          setListadoGastosVariosCPE(updatedRows)         
        }
    }, [listadoGastosVariosCPE],);

    const updateGastosVarios = async (datos) => {        

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}gastosvarioscpes`

        datos.userid = user.id         

        await axios
          .put(`${SERVER_URL}/${datos.id}`, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {  
            if(!isNaN(response.data)){                                     
                setOpenAlert(true)              
            }
            })
            .catch((error) => {
              console.log("error " + error);
        });
    }

    return (
    <>
        <h3>GASTOS VARIOS CPE</h3>        
        <Grid container  spacing={1} className="mt-1">
        <Grid item xs={12} className="" style={{height: 400}}>
            <DataGrid 
              disableColumnMenu={true}
              //disableColumnReorder={false}   
              rows={listadoGastosVariosCPE} 
              columns={columns} 
              pageSize={100} 
              checkboxSelection = {false}
              hideFooter = {true}
              hideFooterPagination = {true}
              hideFooterRowCount = {true}
              hideFooterSelectedRowCount = {true}
              onCellEditCommit={handleCellEditCommit}
              />
            {openAlert && 
            <AlertaMensaje 
              mensaje={"Gasto guardado correctamente!"} 
              isOpen={openAlert} 
              tipoMensaje="success"
              cerrar={handleCloseAlert}
            />
            } 
        </Grid>
        </Grid>
    </>
    )

}
export default FormularioGastosVariosCPE;