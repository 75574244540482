import React, { useState } from "react";
import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { v4 as uuidv4 } from 'uuid';

import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'
import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'
import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'
import FormFieldNumberUncontrolled from '../../../../../components/Common/FormFieldNumberUncontrolled'

let propiedadesProveedores = {
    urlData: "cuentas/proveedores",
    campoEtiqueta: "Nombre_Comercial",
    required: false,
    name: "combo-proveedores",
    disabled: false,
    label: "Proveedor *",
    value: "",
    multiple: false,
    xs:3,
}

let propiedadesFacturaProveedor = {
    required: false,
    name: "factura-proveedor",
    disabled: false,
    label: "Nº Factura",
    value: "",
    xs:2,
}
  
let propiedadesFechaFacturaProveedor = {
    xs:2, 
    required: false,
    label: "Fecha Factura",
    name: "fechafacturaproveedor",
    type: "date",    
    value: ''
}

let propiedadesFacturaProveedorCoste = {
    xs:2,
    required: false,
    label: "Coste *",
    name: "costefacturaproveedor",
    type: "number",    
    value: ''
}

let propiedadesFacturaProveedorCantidad = {
    xs:2, 
    required: false,
    label: "Cantidad *",
    name: "cantidadfacturaproveedor",
    type: "number",    
    value: ''
}

let propiedadesDescripcionFacturaProveedor = {
    required: false,
    name: "descripcion-factura-proveedor",
    disabled: false,
    label: "Descripción *",
    value: "",
    xs:11,
    lineas: 2
}
  
const FormularioMantenimientoProveedor = ({listadotabla}) => { 

    const [listadoRecursosProveedor, setListadoRecursosProveedor] = useState([])
    const [refrescar, setRefrescar] = useState(false)

    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'idProveedor', headerName: 'idProveedor', hide: true },
        { field: 'item', headerName: 'Item nº', width: 90 },
        { field: 'descripcion', headerName: 'Descripción', width: 300 },
        { field: 'cantidad', headerName: 'Cantidad', width: 90 },
        { field: 'coste', headerName: 'Tarifa', width: 100 },
        { field: '', headerName: '', width: 120, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
              ), },
    ];

    const handleRemoveElement = (element) =>{
        const elementoAEliminar = listadoRecursosProveedor.find(el => {
            return el.id === element.id
        })

        //dispatch({type: "REMOVE_LISTADO_PROVEEDOR", payload: elementoAEliminar})
    }
    
    const handleClick = () => {
        listadotabla({
            id:uuidv4(),
            recursos: "Proveedor",
            descripcion: propiedadesDescripcionFacturaProveedor.value,
            fecha: propiedadesFechaFacturaProveedor.value,
            codigo:  propiedadesProveedores.value.Id,
            idProveedor: propiedadesProveedores.value.Id,
            item: listadoRecursosProveedor.length+1,
           
            cantidad: propiedadesFacturaProveedorCantidad.value,
            tarifa: propiedadesFacturaProveedorCoste.value,
            coste: propiedadesFacturaProveedorCoste.value * propiedadesFacturaProveedorCantidad.value,
            facturaProveedor: propiedadesFacturaProveedor.value,
            fechaFacturaProveedor: propiedadesFechaFacturaProveedor.value
        })

        if( propiedadesProveedores.value && listadoRecursosProveedor){

            propiedadesProveedores.value = ''
            propiedadesFacturaProveedor.value =''
            propiedadesFechaFacturaProveedor.value =''
            propiedadesFacturaProveedorCantidad.value =''
            propiedadesFacturaProveedorCoste.value = ''
            propiedadesDescripcionFacturaProveedor.value = ''

            setRefrescar(!refrescar)
        }
    }

    const handleChangeCombo = (valor) => {
        propiedadesProveedores.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFacturaProveedor = (valor) => {
        propiedadesFacturaProveedor.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFechaFacturaProveedor = (valor) => {
        propiedadesFechaFacturaProveedor.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFacturaProveedorCantidad = (valor) => {
        propiedadesFacturaProveedorCantidad.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFacturaProveedorCoste = (valor) => {
        propiedadesFacturaProveedorCoste.value = valor
        setRefrescar(!refrescar)
    }

    const handleChangeFacturaProveedorDescripcion = (valor) => {
        propiedadesDescripcionFacturaProveedor.value = valor
        setRefrescar(!refrescar)
    }

    return(
    <>
    <Grid container spacing={1} className="">
    
        <FormFieldComboUncontrolled propiedades={propiedadesProveedores} onChangeValue={handleChangeCombo} />
        <FormFieldTextUncontrolled propiedades={propiedadesFacturaProveedor} onChangeValue={handleChangeFacturaProveedor}/>
        <FormFieldDateUncontrolled propiedades={propiedadesFechaFacturaProveedor} onChangeValue={handleChangeFechaFacturaProveedor}/>
        <FormFieldNumberUncontrolled propiedades={propiedadesFacturaProveedorCantidad} onChangeValue={handleChangeFacturaProveedorCantidad}/>
        <FormFieldNumberUncontrolled propiedades={propiedadesFacturaProveedorCoste} onChangeValue={handleChangeFacturaProveedorCoste}/>
        <FormFieldTextUncontrolled propiedades={propiedadesDescripcionFacturaProveedor} onChangeValue={handleChangeFacturaProveedorDescripcion}/>
        
        <Grid item xs={1} className="" >
            <Button className="" variant="outlined" size="large" onClick={handleClick}>
                AÑADIR
            </Button>   
        </Grid>
    
    </Grid>
    </>
    )
}

export default FormularioMantenimientoProveedor;