import React from "react";

const getCostesTotales = (datosJornadas) => {
    let costeTotal = 0   

    if(datosJornadas && datosJornadas.length > 0){        
        datosJornadas.forEach(datoJornada => {

            let coste = parseFloat(
                datoJornada.elementos.esPresupuesto === "1" ? 
                datoJornada.elementos.Importe : 
                datoJornada.elementos.Coste);

            let cantidad = parseInt(datoJornada.elementos.cantidad)    
             
            let costeRecurso =  parseFloat(cantidad * coste); 
            let ajusteCPE = 0;

            if(datoJornada.elementos.CategoriaPadre === "Mano de obra externa"){            
                ajusteCPE = costeRecurso*4/100
            }
            costeTotal += costeRecurso + ajusteCPE 
        })
    }
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);
    return numberFormat2.format(costeTotal)
}

const RecursosManoTotalCostes = ({datosJornadas}) =>
{    
    const gastosTotales = getCostesTotales(datosJornadas)
    
    return(
        <>
        <div className="col-12 d-flex overflow-auto justify-content-center borderFormulario mt-4 mb-4">
        <span className="col-11 font-weight-bold float-left">Total costes operativa</span>
        <span className="col-1 font-weight-bold float-right ">{gastosTotales}</span>            
        </div>
        </>
    )
}

export default RecursosManoTotalCostes;