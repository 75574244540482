import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { StateContext } from '../../../../../context'

const propiedadesConsumible = {
    urlData: "dic/diccionarios/listadoconsumiblespropios",
    campoEtiqueta: "Nombre",
    required: true,
    name: "combo-consumible",
    disabled: false,
    label: "Consumible",
    value: "",
    multiple: false,
    xs:7,
}

const propiedadesCantidad = {
    xs:3,
    required: false,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    value: ''
}

const FormularioOperacionGesportConsumible = () => {
    const { dispatch} = useContext(StateContext)

    const [open, setOpen] = useState(false)
    const [selectedConsumible, setSelectedConsumible] = useState(null)
    const [maquinaria, setMaquinaria] = useState([]);
    const [cantidad, setCantidad] = useState(0)
    const loading = open && maquinaria.length === 0;

    useEffect(() => {
        (async () => {
            const respMaquinaria = await axios(process.env.REACT_APP_API_URL + propiedadesConsumible.urlData);
            setMaquinaria(Object.keys(respMaquinaria.data).map((key) => respMaquinaria.data[key]))           
          })();
    }, [])

    const getOptionLabelMaquinaria = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesConsumible.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedMaquinaria = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedConsumible(value)
        }
        return option.Id === value.Id
    }

    const handleChangeMaquinaria = (e, v) => { 
        setSelectedConsumible(v)
    }

    const handleChangeCantidad = (event) => { 
        setCantidad(event.target.value)
    }

    const renderInputMaquinaria = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesConsumible.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const handleClick = () => { 

        dispatch({type: "ADD_LISTADO_GESPORT", 
        payload: {
            recurso:'consumible',
            nombre: selectedConsumible.Nombre,
            id: uuidv4(),
            cantidad: cantidad,
            coste: selectedConsumible.Coste,
            total: selectedConsumible.Coste * cantidad
        }})

        setSelectedConsumible(null)
        setCantidad(0)
    }

    return (
        <>
        <Grid container  spacing={1} className="mt-2">
        <Grid item xs={propiedadesConsumible.xs} className="">
        <Autocomplete
            id={propiedadesConsumible.name}
            name={propiedadesConsumible.name}
            getOptionLabel= { getOptionLabelMaquinaria }
            getOptionSelected={(option, value) => getOptionSelectedMaquinaria(option, value)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            label={propiedadesConsumible.label}        
            renderInput={renderInputMaquinaria}
            onChange={(e, v, r) => handleChangeMaquinaria(e, v, r)}  
            options={maquinaria}
            value={selectedConsumible}
            style={{  marginLeft: 0 }}
        />            
        </Grid>

    
        <Grid item xs={propiedadesCantidad.xs} className="">
        <TextField                 
            required = {propiedadesCantidad.required}                
            id={propiedadesCantidad.name}
            name={propiedadesCantidad.name}
            disabled = {propiedadesCantidad.disabled}
            label={propiedadesCantidad.label}        
            value={cantidad} 
            type="number"
            onChange={handleChangeCantidad} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField>      
        </Grid>
        <Grid item xs={2} className="" >
        <Button className="" variant="contained" color="primary" size="large" onClick={handleClick}>
            AÑADIR
        </Button> 
        </Grid>

        </Grid>
        </>
    )
}
export default FormularioOperacionGesportConsumible;