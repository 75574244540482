const botoneraSoporte = [
    // { etiqueta: "Crear Operación", url: "/operativas/operaciones/operacion", activo: true },
    {
       etiqueta: "Eliminar",
       url: '/operativas/almacenamiento/piezas/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/postventa/soportes/soporte/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/postventa/soportes/soporte',
       activo: true,
     }, 
   ];

   export {botoneraSoporte};

   const botoneraListado = [
    // { etiqueta: "Crear Operación", url: "/operativas/operaciones/operacion", activo: true },
    {
       etiqueta: "Eliminar",
       url: '/operativas/almacenamiento/piezas/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/operativas/almacenamiento/piezas/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/postventa/soportes/soporte',
       activo: true,
     }, 
   ];

   export {botoneraListado};

   const botoneraCPE = [
    // { etiqueta: "Crear Operación", url: "/operativas/operaciones/operacion", activo: true },
    {
       etiqueta: "Eliminar",
       url: '/operativas/almacenamiento/piezas/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/operativas/almacenamiento/piezas/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/postventa/soportes/soporte',
       activo: true,
     }, 
   ];

   export {botoneraCPE};