import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'
import SearchBar from "../../../../../components/Common/SearchBar";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import MigasDePan from "../../../../../components/Common/MigasDePan";

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioLocalizacion = () => {
    const { id } = useParams();
    const classes = useStyles(); 
    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos,setCampos] = useState([])  
    const [seleccion, setseleccion] = useState({}) 

    let CAMPOS_FORMULARIO_LOCALIZACION = [           
      {
        required: false,
        label: "Código:",
        name: "Codigo",
        disabled: false,
        type: "text",
        value: "",              
        xs:3
      },
      {
        required: true,
        label: "Nombre:",
        name: "Etiqueta",
        disabled: false,
        type: "text",
        value: "",              
        xs:6
      }, 
      {
        campoEtiqueta: "Etiqueta",
        required: true,
        label: "Estado",
        name: "Activo",
        type: "comboArray", 
        options:[{Id: '1', Etiqueta: 'Activo'}, {Id: '0', Etiqueta: 'Inactivo'}], 
        multiple:false,   
        value: "",
        xs:3
      },  
    ];

    useEffect(() => {
      setCampos(CAMPOS_FORMULARIO_LOCALIZACION)     
                   
      const fetchData = async () => {             
        if (id) {
          const respseleccion = await axios(`${process.env.REACT_APP_API_URL}localizacions/${id}`);
          setseleccion(respseleccion.data); 
        }
        setDatosCargados(true);
      }

      fetchData();

      return () => {
          setseleccion({}) 
          setDatosCargados(true);
          setCampos([])
      } 
    },[]);

    useEffect(() => {  
      if (typeof seleccion !== "undefined") {        
        if(Object.keys(seleccion).length > 0){ 
          const options= [{Id: 0, Etiqueta: 'Inactivo'}, {Id: 1, Etiqueta: 'Activo'}];
          const activo = options.filter((element)=> element.Id === seleccion.Activo);
          setDatosCargados(false);            
          campos[0].value = seleccion.Codigo;
          campos[1].value = seleccion.Etiqueta;
          campos[2].value = activo[0];
          setDatosCargados(true)  
        }
      }
    },[seleccion])

  const botoneraAcciones = [
    {
      etiqueta: "Cancelar",
      url: '/crm/localizaciones',
      activo: true,
    } 
  ];

  const urlsMigasdepan = [
    {titulo: "CRM", colorTexto: "texto-comercial", urlTo: "/crm/localizaciones"},
    {titulo: "Localizaciones", colorTexto: "texto-comercial",urlTo:""},
  ]

  if (datoscargados){
    return ( 
      <>
        <div className="col-8 col-main-home ">
          <SearchBar
            titulo="Localizaciones"
            claseCabecera = "bg-comercial"
            buscar={false}
            imagenCabecera={"4-1-3-actividades.png"}
          />
          <div className="bg-cuerpo p-10px">
            <div className="bg-white p-10px">
              <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan}/>
              <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                <h6 className="texto-comercial" id="title">          
                </h6>
                <FormularioEntidad 
                  urlForm = "localizacions"
                  urlGoto = "/crm/localizaciones/"
                  campos={campos}
                  type={ Object.keys(seleccion).length > 0 ? "edit" : "add" }
                /> 
              </div>
            </div>

            <TableAcciones
              botonaccion={botoneraAcciones}
            />
          </div>
        </div>
      </>       
    )
  }
  else {
    return (              
      <div className={classes.linear}>
        <CircularProgress />
      </div>
    );
  }

}
export default FormularioLocalizacion;