import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'


const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioEnfermedad = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [enfermedad, setEnfermedad] = useState([]) 
    const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(); 


    const classDiv3 = "col-3 mt-2 mb-2"

    const handleChangeEmpleado = (valor) => {
        console.log(valor)
        if(valor){
          setEmpleadoSeleccionado(valor)      
        }
    }

    useEffect(() => { 

        setCampos([ 
            /* 
            {
                urlData: "dic/diccionarios/tipologiaspartesenfermedadrrhh",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Tipologia:",
                name: "tipologia",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            }, 
            */        
            {
                required: true,
                label: "Fecha de baja:",
                name: "fecha",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:6
            },
            {
                required: false,
                label: "Fecha de alta:",
                name: "fechaalta",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:6
            },
            {
                urlData: "dic/diccionarios/listadoempleadosactivos",
                campoEtiqueta: "nombre",
                required: true,
                label: "Trabajador:",
                name: "trabajador",
                disabled: false,
                type: "combo",
                value: "",              
                xs:6,
                onChangeValue: handleChangeEmpleado,
            },
            {
                urlData: "rrhhsspartesaccidentes",
                campoEtiqueta: "Codigo",
                required: false,
                label: "Parte de accidente:",
                name: "parteAccidente",
                disabled: false,
                type: "combo",
                value: "",              
                xs:6
            },
            {
                required: true,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",
                lineas: 4,    
                disabled: false, 
                value: "",                
                xs:12
            },  
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_rrhh_partes_enfermedad_adjuntos",
                xs:12
              },            
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: enfermedad.ficheros,    
                tabla: "mtms_rrhh_partes_enfermedad_adjuntos",
                modelo: "api\\modules\\v1\\models\\rrhh\\RRHHPartesEnfermedadAdjuntos",
                carpeta: "rrhh//partes-enfermedad",
                xs:12
              },                
        ])

        const fetchData = async () => { 
            if (modo === "W") {
                const respenfermedad = await axios(
                    `${process.env.REACT_APP_API_URL}rrhhsspartesenfermedads/${id}`
                );                
                setEnfermedad(respenfermedad.data)  
            }
            setDatosCargados(true);
        };  

        fetchData();  

    },[]);

    useEffect(() => {            
        if (typeof enfermedad !== "undefined") {        
            if(Object.keys(enfermedad).length > 0){              
                setDatosCargados(false)
                campos[0].value = enfermedad.Fecha;
                campos[1].value = enfermedad.FechaAlta;
                campos[2].value = enfermedad.Empleado;
                campos[3].value = enfermedad.ParteAccidente;
                campos[4].value = enfermedad.Observaciones;            
                campos[6].ficheros = enfermedad.ficheros;
                setDatosCargados(true)  
            }
        }
    },[enfermedad])

    useEffect(() => { 
        if(empleadoSeleccionado){
            console.log(empleadoSeleccionado) 
            campos[3].urlData = "rrhhsspartesaccidentes?empleado=" + empleadoSeleccionado.id
        }
    },[empleadoSeleccionado])

if (datoscargados){
    return ( 
        <FormularioEntidad 
        urlForm = "rrhhsspartesenfermedads"
        urlGoto = "/rrhh/talento/enfermedad/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioEnfermedad;