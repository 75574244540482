import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import { useHistory } from "react-router";
import { CircularProgress } from '@material-ui/core';
import FormularioMantenimiento from "./FormularioMantenimiento";
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import SearchBar from "../../../../../components/Common/SearchBar";

const Maquinaria = (props) => {
  const history = useHistory();
  let { id, modo } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [datos, setDatos] = useState();
/*
  useEffect(() => {
    if (modo==="R") {
      history.push({
        pathname: `/servicio/maquinarias/maquinaria/${id}/W`,       
      });
    }
  },[modo])
*/
    useEffect(() => {
        setIsLoading(true)
    },[props.location.action])

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respAlmacen = await axios( process.env.REACT_APP_API_URL + "mantenimientos/" + id);
            if (respAlmacen.data) {              
              setDatos(respAlmacen.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar Maquinaria" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/servicio/maquinarias` :
          modo === "R" ? 
            `/servicio/maquinarias/maquinaria/${id}/W` : 
            `/servicio/maquinarias`,
          activo: true,
        },
        {
          etiqueta: "Guardar",
          form:'formulario-maquinaria',
          activo: modo === "R" ? true : true,
        }        
    ];

    const urlsMigasdepan = [
      {titulo: "Maquinaria", colorTexto: "texto-comercial", urlTo: "/servicio/maquinarias"},
      {titulo: "Maquinaria", colorTexto: "texto-comercial", urlTo: `/servicio/maquinarias/maquinaria/${id}/${modo}`},
    ]   

    const codigo = typeof datos !== "undefined" ? datos.codigo : "";

    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Servicios"
              claseCabecera = "bg-produccion"
              buscar={false}
              imagenCabecera={"5-4-1-mantenimiento.png"}
            />
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                  <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                               
    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                                              
                    {modo === "R" && 
                        <FormularioMantenimiento />}   

                      {modo !== "R" && 
                        <FormularioMantenimiento />}   
                          
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default Maquinaria;