import React, { useState, useEffect } from "react";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";


const ListadoInformeMaquinaria = ({urlInforme, filtroFechas, returnData = null}) => {    
    
    const urlDoubleClick = ""
    const filtroToolbar = false    
    const cloumnsMaquinaria = [
      {
        name: "Id",
        label: "Id",
        options: {
         filter: false,
         sort: true,
         display: false,
        }
       },
       {
        name: "Categoria",
        label: "Categoría",
        options: {
         filter: true,
         sort: true,
        }
       },       
       {
        name: "Subcategoria",
        label: "Subcategoría",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Elemento",
        label: "Elemento",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Tipologia",
        label: "Tipología",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Fecha",
        label: "Fecha",
        options: {
         filter: true,
         sort: true,
         
        }
       },
       {
        name: "Codigo",
        label: "Código Carpeta",
        options: {
         filter: true,
         sort: true,
         
        }
       },
       {
        name: "Importe",
        label: "Importe",
        options: {
          filter: true,
          sort: true,
          sortCompare: (order) => {
           return (obj1, obj2) => {
             let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
             let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
             return (val1 - val2) * (order === 'asc' ? 1 : -1);
           };
          },        
          setCellProps: () => { return { align:"right"} }
        }
       },       
       {
        name: "Descripcion",
        label: "Descripción",
        options: {
         filter: true,
         sort: true,
         
        }
       }
    ];

    const footer = [        
        {campo: 'Importe', titulo: 'Total importe', formato: "currency"},
    ] 

    const urlDatos = `informes/${urlInforme}${filtroFechas}`
    
    return(
      <CuerpoTablaPiezas
        columnas = {cloumnsMaquinaria}
        urlDatos = {urlDatos}
        urlDoubleClick = {urlDoubleClick}
        columnId = "Id"
        getFilaSeleccionada={null}        
        filtroToolbar={filtroToolbar}
        filtroToolbarOpciones = {null}
        filtroToolbarValor = {''}
        filtroToolbarChangeValor={null}
        filtroEjercicio = {false}
        paginacion = {true}
        returnData={returnData}
        customFooter={footer} 
      /> 
    )
}

export default ListadoInformeMaquinaria;