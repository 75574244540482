import React, { useState } from "react"

import SearchBar from "../../../../../components/Common/SearchBar";
import Pasos from "../../../../../components/Common/Pasos";

import FormularioExportacion from "./FormularioExportacion";
import ConfiguracionExportacion from "./ConfiguracionExportacion";

const Exportacion = () => {
    const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOpExportacion')) || 0);

    const handleChangePasoActivo = (idOpExportacion) => {         
        localStorage.setItem('idOpExportacion', idOpExportacion);   
        setIdOperacion(idOpExportacion)
    }  

    return (
    <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Administración"
          claseCabecera = "bg-gestion"
          buscar={false}
          imagenCabecera={"6-1-2-facturas-ventas.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
            <div className="bg-white p-10px">
                <div className="p-1 bg-white mb-3">
                <h6 className="texto-gestion float-left" id="title">
                    Exportación de datos
                </h6>
                </div>
                <hr className="m-0 bg-gestion mb-1" />
                <div className="principal bg-white cuerpo-h-planning-semanal pr-2 overflow-auto overflow-hiddens table-responsive">
                    <Pasos pasos={['Exportación', 'Configuración']} idPasoActivo={idOperacion} changePasoActivo={handleChangePasoActivo} />                    
                    {idOperacion===0 && ( <FormularioExportacion /> )} 
                    {idOperacion===1 && ( <ConfiguracionExportacion /> )} 
                </div>
            </div>
        </div>
    </div>
    )
}

export default Exportacion;