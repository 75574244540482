import React, { useState } from "react";
import CuerpoTablaNoUrl from "../../../../../components/Common/CuerpoTablaNoUrl";
import {columnsExistenciasAlmacenaje,columnsExistenciasInventario} from "./LogisticaConf";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";

const Existencias = (props) => { 
  let columnasCuerpoTabla = columnsExistenciasInventario
  let urlDatosCuerpoTabla = "logisticas/inventario"
               
  return (           
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Logística interna"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-1-1-existencias.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Existencias
              </h6>
            </div>
            <hr className="m-0 bg-comercial mb-1" />
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                
                      
                <CuerpoTablaNoUrl
                columnas = {columnasCuerpoTabla}
                urlDatos = {urlDatosCuerpoTabla}
              /> 
            </div>
          </div>        

        </div>          
      </div>   
    
  );
}
export default Existencias;