import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import Avatar from "@material-ui/core/Avatar";

const tdItem = (cuenta, campos) => {
  return campos.map((key, index) => {
    let clases = index === 0 ? " rounded-left" : "";
    clases += index === campos.length - 1 ? " rounded-right" : "";
    clases += " border-0";
    let tdElement = cuenta[key.campo];
    
    if (key.campo === "Foto") {
      tdElement = (
        <Avatar alt="NF" src={cuenta[key.campo]} className={clases} />
      );
    }

    return (
      <td
        key={index}
        className={clases}
        style={{ verticalAlign: "center !important" }}
      >
        {tdElement}
      </td>
    );
  });
};

const TableBody = (props) => {
  const [filas, setFilas] = useState(props.cuentasTB);
  const [campos, setCampos] = useState(props.camposTB);
  const [filaActiva, setFilaActiva] = useState(true);
  const [accion, setAccion] = useState(props.accionDobleClick);
  const history = useHistory();

  useEffect(() => {
    setFilas(props.cuentasTB);
    setCampos(props.camposTB);
    setAccion(props.accionDobleClick);
  }, [props.cuentasTB, props.camposTB, props.accionDobleClick]);

  if (filas) {
    return filas.map((cuenta, index) => {
      let campoId = campos[0];
      const idC = cuenta[campoId.campo];
      let clases = filaActiva === idC ? "bg-seleccionado text-white" : "";

      return (
        <tr
          key={index}
          onClick={() => {
            setFilaActiva(idC);
            props.onClick(idC);
          }}
          onDoubleClick={() => {
            
            if (accion) {
              const regex = /{idC}/gi;
              let accionDB = accion.replace(regex, idC);
              history.push(accionDB);
            } else {
              if(props.onDoubleClick){
              props.onDoubleClick(idC);
              }
              else{ return }
            }
          }}
          className={clases}
        >
          {tdItem(cuenta, campos)}
        </tr>
      );
    });
  }
};

export default TableBody;
