import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useParams } from "react-router-dom";

export default function BotonesMantenimiento({tipoFormulario,changeTipoFormulario, maquinaria}) {
 const { modo } = useParams();

 const handleRadioChange = (e) => {
  changeTipoFormulario(e.target.value)
 }

  return (
    <>
        <div className="d-flex flex-column align-items-end">
          <FormControl component="fieldset" className="">
            <RadioGroup row aria-label="position" name="position" value={tipoFormulario} onChange={(e) => handleRadioChange(e)}>
              <FormControlLabel
                value="empleado"
                control={<Radio color="primary" />}
                label="Empleado"
                disabled= {modo === "R" ? true : false}
              />     
              {maquinaria && (         
                <FormControlLabel
                  value="maquinaria"
                  control={<Radio color="primary" />}
                  label="Maquinaria"
                  disabled= {modo === "R" ? true : false}
                />
              )}

              <FormControlLabel
                value="materiales"
                control={<Radio color="primary" />}
                label="Materiales"
                disabled= {modo === "R" ? true : false}
              />

              <FormControlLabel
                value="proveedores"
                control={<Radio color="primary" />}
                label="Proveedores"
                disabled= {modo === "R" ? true : false}
              />

            </RadioGroup>
          </FormControl>
        </div>
    </>
  );
}