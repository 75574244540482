import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class MenuizqComercial extends Component {
  render() {
    const { isAuthUser } = this.props.reducerApi;
    
    return (
      <div className="col-12 p-0">
        <div className="card mb-2">
          <div className="bg-comercial p-0" id="headingComercial">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left text-white"
                type="button"
              >
                CONFIGURACION
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-caret-down-fill float-right"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </button>
            </h2>
          </div>

          <div className="wrapper card-header p-2 ">
            <nav id="sidebar">
              <ul className="list-unstyled components">
                <li className="active  mb-1">
                  <a
                    href="#configSubmenu"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle p-comercial"
                  >
                    Tablas 
                  </a>
                  <ul
                    className="collapse list-unstyled pl-2 m-2 "
                    id="configSubmenu"
                  >
                    <li className="nav-item p-comercial">
                      <Link to={isAuthUser ? "/config/tablas" : "/"} className="">
                        {"Tablas editables"}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>

            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ reducerApi }) => ({ reducerApi }))(MenuizqComercial);
