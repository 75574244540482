import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioMateriales = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 
    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [Materiales, setMateriales] = useState([]) 

    useEffect(() => { 

        setCampos([  
            //0
            {
                required: true,
                label: "Código:",
                name: "ean",
                type: "text",    
                disabled: false, 
                value: '',                
                xs:4
            },
            //1
            {
                required: true,
                label: "Nombre material:",
                name: "nombre",
                type: "text",    
                disabled: false, 
                value: '',                
                xs:4
            }, 
            //2
            {
                urlData: "dic/diccionarios/unidadproducto",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Tipo Unidad:",
                name: "tipoUnidad",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:4
            },
            //3

            {
                urlData: "dic/diccionarios/categoriasmaterial",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Categoría:",
                name: "categoria",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:4
            },
            //4
            {
                urlData: "dic/diccionarios/gruposmaterial",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Grupo:",
                name: "grupo",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:4
            },
            //5
            {
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Marca:",
                name: "marca",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:4,

                urlData: "marcamaterials",
                DialogForm: 
                [{                
                    required: true,
                    label: "Marca:",
                    name: "etiqueta",
                    disabled: false,
                    type: "text",
                    value: "",              
                    xs:12
                }]
            },  
            //6  
            {
                required: false,
                label: "Descripcion:",
                name: "descripcion",
                type: "textArea",
                lineas: 4,                
                value: '',
                xs:6
            },
            //7
            {
                required: false,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",
                lineas: 4,                
                value: '',
                xs:6
            },
    ])

    const fetchData = async () => { 
        if (modo === "W") {
            const respMateriales = await axios(
                `${process.env.REACT_APP_API_URL}materials/${id}`
            );                
            setMateriales(respMateriales.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[]);


useEffect(() => {            
    if (typeof Materiales !== "undefined") {        
      if(Object.keys(Materiales).length > 0){              
        setDatosCargados(false)              
        campos[3].value = Materiales.categoria;
        campos[4].value = Materiales.grupo;
        campos[5].value = Materiales.marca;        
        campos[0].value = Materiales.ean;
        campos[2].value = Materiales.tipoUnidad;        
        campos[1].value = Materiales.nombre;
        campos[6].value = Materiales.descripcion;
        campos[7].value = Materiales.observaciones; 
        setDatosCargados(true)  
      }
  }
  },[Materiales])

if (datoscargados){
    return (    
        <FormularioEntidad 
            urlForm = "materials"
            urlGoto = "/logistica/materials/material/"
            campos={campos}
            type="add"
        />   
    
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioMateriales;