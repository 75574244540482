export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RECOVERYPASS = "RECOVERYPASS";

export const login = (user) => {
  return {
    type: LOGIN,
    payload: user,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
    payload: []
  };
};

export const recoveryPassword = (email) => {
  return {
    type: RECOVERYPASS,
    payload: email,
  };
};
