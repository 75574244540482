import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaTransferir = ({datos}) => {
    let { id } = useParams();

    const [Transferir, setTransferir] = useState([])  
    const [campos, setCampos] = useState([]) 
    useEffect(() => {  
        if(datos){
            setTransferir(datos)
        }
    }, [datos]);
    
    //Imprimimos los datos 
    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(Transferir).length > 0){  
            console.log(Transferir)
            setCampos([
            {
                classsLabel: "",
                label: "Cliente:",
                value: Transferir.cliente.Razon_Social,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Pieza:",
                value: Transferir.pieza.Codigo + ' ' + Transferir.pieza.Nombre,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Depósito:",
                value: Transferir.almacen.Nombre,
                classText: classText,
                xs:12
            },           
            {
                classsLabel: "",
                label: "Cantidad:",
                value: Transferir.unidades,
                classText: classText,
                xs:12
            },
        ])
    }
},[Transferir]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/produccion/operativas/Transferir/${id}/W`}
    />
    </>
  )
}
export default FichaTransferir;