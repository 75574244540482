import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'
import FormFieldAutoCompleteUncontrolled from "../../../../../components/Common/FormFieldAutoCompleteUncontrolled";

export default function FiltroListadoOperativas({urlfecha, firstDay, lastDay, showPrintButton = false, handlePrintReport = null}) { 

    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1, 
          padding: theme.spacing(1),
          margin: '10px',        
          backgroundColor: '#FFF',
        },
      }));

    const classes = useStyles();
    const[fechaInicio, setFechaInicio]=useState(firstDay);
    const[fechaFin, setFechaFin]=useState(lastDay);    

    const [campoOperativas, setCampoOerativas] = useState({
        urlData: "dic/diccionarios/operativas",
        campoEtiqueta: "Etiqueta",
        required: false,
        name: "operativa",      
        label: "Operativa",
        value: "",
        multiple: false,
        xs:4,
    })

    const [campoClientes, setCampoClientes] = useState({
        urlData: "cuentas/clientes",
        campoEtiqueta: "Razon_Social",
        required: false,
        name: "Cliente",
        label: "Cliente",
        value: [],
        multiple: true,
        disabled: false,
        xs: 8,
    })

    const [campoJornadas, setCampoJornadas] = useState({
        urlData: "dic/diccionarios/listadojornadas",
        campoEtiqueta: "Etiqueta",
        required: false,
        name: "jornada",      
        label: "Jornada",
        value: "",
        multiple: false,      
        xs:4,
    })
   

    const handleChangeComboOperativa = (valor) => {         
        setCampoOerativas({...campoOperativas, value: valor })
    } 

    const handleChangeComboCliente = (valor) => {            
        setCampoClientes({...campoClientes, value: valor })
    } 

    const handleChangeComboJornada = (valor) => { 
        setCampoJornadas({...campoJornadas, value: valor})
    }
      
    const StyledButton = withStyles((theme) => ({
        root: {
          color: 'black',
          backgroundColor: '#c4c4c4',
          height: 32,
          marginTop: 0,          
          width: 60,
          '&:hover': {
            backgroundColor: '#777777',
          },
        },
    }))(Button);

  let propiedadesFechaInicio = {
    required: true,
    label: "Fecha Inicio",
    name: "fechaInicio",
    type: "date",    
    value: fechaInicio,
    xs:2
  }
      
  let propiedadesFechaFin = {
    required: true,
    label: "Fecha Fin",
    name: "fechaFin",
    type: "date",    
    value: fechaFin,
    xs:2
  }

  useEffect(() => {
    setFechaInicio(firstDay)
    setFechaFin(lastDay)
    setCampoJornadas({...campoJornadas, value: ""})
    setCampoClientes({...campoClientes, value: [] })
    setCampoOerativas({...campoOperativas, value: "" })

  }, [])

  const handleChangeFechaInicio = (valor) => {    
    setFechaInicio(valor)
  }

  const handleChangeFechaFin = (valor) => {    
    setFechaFin(valor)
  }

  const handleSetURL = async () => { 

    const initialValue = "";
    const sumWithInitial = campoClientes.value.reduce(
      (accumulator, currentValue) => accumulator + currentValue.Id + ",",
      initialValue,
    );
    const clientes = sumWithInitial.substring(0, sumWithInitial.length-1)
    const SERVER_URL = "?fechainicial=" + fechaInicio + "&fechafinal=" + fechaFin + "&operativa=" + (campoOperativas?.value?.Id ?? - 1 ) + "&jornada=" + (campoJornadas?.value?.Id ?? -1) + "&clientes=" + (clientes ?? -1) ;   
    
    urlfecha(SERVER_URL)
  }

  const handleSetFecha = async () => { 
    const SERVER_URL = "";   
    setFechaInicio('') 
    setFechaFin('')
    setCampoJornadas({...campoJornadas, value: ""})
    setCampoClientes({...campoClientes, value: [] })
    setCampoOerativas({...campoOperativas, value: "" })
    urlfecha(SERVER_URL)
  }

  const handlePrint = () => {
    const SERVER_URL = "?fechainicial=" + fechaInicio + "&fechafinal=" + fechaFin;   
    if(handlePrintReport)
      handlePrintReport(SERVER_URL)
  }


  return(
    <div className={classes.root}> 
  
    <Grid container spacing={1} className="" style={{backgroundColor: "#FFF"}}>
        <FormFieldDateUncontrolled propiedades={propiedadesFechaInicio} onChangeValue={handleChangeFechaInicio} />
        <FormFieldDateUncontrolled propiedades={propiedadesFechaFin} onChangeValue={handleChangeFechaFin} />
        <FormFieldAutoCompleteUncontrolled propiedades={campoClientes} onChangeValue={handleChangeComboCliente} /> 
        
        <FormFieldAutoCompleteUncontrolled propiedades={campoOperativas} onChangeValue={handleChangeComboOperativa} /> 
        <FormFieldAutoCompleteUncontrolled propiedades={campoJornadas} onChangeValue={handleChangeComboJornada} />                
        
        <Grid item xs={1} className="" ></Grid>
           
        <Grid item xs={1} className="" >
            {showPrintButton && (
                <StyledButton size="small" className={classes.margin}
                onClick={handlePrint}>
                Imprimir
                </StyledButton>
            )}
        </Grid>
        
        <Grid item xs={1} className="" >
            <StyledButton size="small" className={classes.margin}
            onClick={handleSetURL}>
            Filtrar
            </StyledButton>
        </Grid>      

        <Grid item xs={1} className="" >
            <StyledButton size="small" className={classes.margin}
            onClick={handleSetFecha}>
            Limpiar
            </StyledButton>
        </Grid>   

      
    </Grid>
    </div>
  )
}