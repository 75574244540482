import React, { useEffect, useRef } from "react";
import Grid from '@material-ui/core/Grid';

import {useFacturaVenta} from './facturaventa-context'

import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'

const FormularioFacturaRectificativaDatos = ({datosFactura, changeCliente, changeFicheros, control}) => {  
    const { setListadoProductosFacturaVenta } = useFacturaVenta();  
    
    const hayCliente = useRef(false);
    const Cliente = useRef({});

    const handleChangeCliente=(valor)=>{        
        hayCliente.current = valor === null ? false : true   
        Cliente.current = valor     
        changeCliente(valor)   
    }   
      
    const handleFicheros = (files, filenames) => {  
        changeFicheros(files, filenames)        
    }
    
    
    const handleChange=(valor) => {  
        if(valor){
            let productosArray = valor.productos
            productosArray.forEach((element) => {
                element.jornada = element.jornada ?? 'J1 lunes a viernes 08:00 14:00'
            });
            setListadoProductosFacturaVenta(productosArray)
        }        
    }   

    const valoresFactura = Object.keys(datosFactura).length > 0 || (Cliente.current && Object.keys(Cliente.current).length > 0)        
    hayCliente.current = valoresFactura ? true : false  

    useEffect(() => {
        handleChangeCliente(datosFactura.Cliente)
        return () => {
            
        }
    }, [datosFactura])

    let campos = [        
       // 0
        {
            urlData: "cuentas/clientes",
            campoEtiqueta: "Nombre_Comercial",
            required: true,
            name: "Cliente",
            disabled: false,
            label: "Cliente",
            type:'combo',
            value: valoresFactura ? datosFactura.Cliente : "",
            multiple: false,
            xs: 3,
            onChangeValue: handleChangeCliente
        }, 
        // 1  
        {
            required: true,
            label: "Fecha Factura",
            name: "fechaFactura",
            type: "date",    
            disabled: !hayCliente.current,
            value: valoresFactura ? datosFactura.Fecha_Factura : "",
            xs: 3
        },  
        // 2 
        {
            urlData: !hayCliente.current ? "facturasunicas/xcliente/0" : "facturasunicas/xcliente/" + Cliente.current.Id,
            campoEtiqueta: "Codigo",
            required: true,
            name: "facturaOrigen",
            disabled: !hayCliente.current,
            label: "Factura origen",
            type:'combo',
            value: valoresFactura ? datosFactura.facturaOrigen : "",
            multiple: false,
            xs: 3,
            onChangeValue: handleChange
        },         
        // 3       
        {
            urlData: "dic/diccionarios/facturaventasestados",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "Estado",
            disabled: !hayCliente.current,
            type:'combo',
            label: "Estado",
            value: valoresFactura ? datosFactura.Estado : "",
            multiple: false,
            xs: 3
        },
        // 4
        {
            required: false,
            name: "observaciones",
            disabled: !hayCliente.current,
            label: "Observaciones",
            value: valoresFactura ? datosFactura.Observaciones : "",
            type: 'textarea',
            lineas:3,
            xs: 6
        },  
        // 5    
        {
            required: false,
            name: "textoFactura",
            disabled: !hayCliente.current,
            label: "Texto para factura",
            value: valoresFactura ? datosFactura.textoFactura : "",
            type: 'textarea',
            lineas:3,
            xs: 6
        },
        //6  
        {
            required: false,
            label: "Documentacion",
            name: "ficherosadjuntos",
            type: "filesupload",   
            tabla: "mtms_hoja_pedido_adjuntos",
            ficheros: valoresFactura ? datosFactura.ficheros : "",
            xs:6
        },
        // 7
        {
            required: false,
            label: "Documentacion",
            name: "ficheros",
            type: "files", 
            ficheros: valoresFactura ? datosFactura.ficheros : "",   
            tabla: "mtms_hoja_pedido_adjuntos",
            modelo: "api\\modules\\v1\\models\\facturaventa\\FacturaRectificativaAdjuntos",
            carpeta: "facturas_rectificativas",
            xs:12
        }, 
    ]
    
    const numCampos = Object.keys(campos).length    
        
    return (
    <>
    { numCampos > 0 && (
        <Grid className="borderFormulario" container spacing={1}>  
            <FormFieldCombo propiedades={campos[0]} control={control}/> 
            <FormFieldDate propiedades={campos[1]} control={control}/>
            <FormFieldCombo propiedades={campos[2]} control={control}/>              
            <FormFieldCombo propiedades={campos[3]} control={control}/>     
              
            <FormFieldTextArea propiedades={campos[4]} control={control}/>
            <FormFieldTextArea propiedades={campos[5]} control={control}/>

            <FormFieldFiles propiedades={campos[7]} control={control} ficheros={handleFicheros}/>
            <FormFieldFileUpload propiedades={campos[6]} control={control} handleFicheros={handleFicheros}/>              
          </Grid>  
    )}
    </>
    )
}

export default FormularioFacturaRectificativaDatos;