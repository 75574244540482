import React, { useState, useEffect } from "react"
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

import { useForm, FormProvider } from "react-hook-form";

import FormFieldDate from "../../../../../components/Common/FormFieldDate";
import FormFieldSwitch from "../../../../../components/Common/FormFieldSwitch";
import FormFieldLineSeparator from "../../../../../components/Common/FormFieldLineSeparator";

const campos = [
    {
        required: true,
        label: "Fecha Inicio",
        name: "fechaInicio",
        type: "date",    
        value: '',
        xs: 3
    }, 
    {
        required: true,
        label: "Fecha Fin",
        name: "fechaFin",
        type: "date",    
        value: '',
        xs: 3
    },
    {        
        type: "lineseparator",
        xs: 1
    },
    {
        required: true,
        label: "Ventas",
        name: "ventas",
        type: "switch",    
        value: true,
        xs: 2
    },
    {
        required: true,
        label: "Compras",
        name: "compras",
        type: "switch",    
        value: true,
        xs: 2
    },
]

const FormularioExportacion = () => {
    const methods = useForm();
    const { control, handleSubmit} = useForm();
    const [datosContanet, setDatosContanet] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    
    const fetchData = async () => {
      let user = JSON.parse(localStorage.getItem("user"));  
      
      const respData = await axios( process.env.REACT_APP_API_URL + "exportaciondatos/contanet");
       
      if (respData.data) {   
        //console.log(respData.data)           
        setDatosContanet(respData.data); 
      }
  };

  useEffect(() => {    
      fetchData();
      return () => {setDatosContanet([])}
  }, [])   

    const handleSubmitPost = async (datos) => { 
        const obj = JSON.parse(datosContanet.parametros);
        const urlPostData = 'exportaciondatos/exportar';        
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}`
        //console.log(datos)
        setIsLoading(true) 
        await axios
        .post(`${SERVER_URL}`, {
          headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/plain" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download =  `Conta${obj.campoNumeroEmpresaContanet}.sec`;
          link.href = url;
          link.click();
          
          if(!isNaN(response.data)){
            if(parseInt(response.data)){                
              //setOpenAlert({estado: true, mensaje: "¡Producto añadido correctamente!", tipo:"success"})
            }
          }
        })
        .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
        });
        setIsLoading(false)
    }

    const numCampos = Object.keys(campos).length 
    return(
        <div className="mt-5">
        <FormProvider {...methods}>
            <form
              id='formulario-exportacion-datos'
              onSubmit={handleSubmit((data) => {
              handleSubmitPost(data);
              })}
              className=""
            >
            { numCampos > 0 && (
            <Grid className="borderFormulario" container spacing={1}>
                <FormFieldDate propiedades={campos[0]} control={control}/>
                <FormFieldDate propiedades={campos[1]} control={control}/>
                <FormFieldLineSeparator propiedades={campos[2]} />
                <FormFieldSwitch propiedades={campos[3]} control={control} /*onChangeValue={handleChangeVentas}*//>
                <FormFieldSwitch propiedades={campos[4]} control={control} /*onChangeValue={handleChangeCompras}*//>

                <button 
                      className="mt-5 mb-5 w-100 float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation">
                      EXPORTAR
                </button> 
            </Grid>)}
            </form>
          </FormProvider>
          {isLoading && (
          <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
          )} 
        </div> 
    )
}

export default FormularioExportacion;