import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useParams } from "react-router-dom";

const FormFieldText = ({ propiedades, control }) => { 
    const { modo } = useParams();
    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )
    
    return ( 
    <Hidden  xlDown = {propiedades.hidden ? true : false } >              
    <Grid item xs={propiedades.xs} className={propiedades.classes} >     
    <Controller                        
        as={TextField}   
        required = {propiedades.required}
        control={control}
        id={propiedades.name}
        name={propiedades.name}
        disabled={deshabilitado} //{propiedades.disabled ? true : modo==="R"  ? true : false}
        label={propiedades.label}
        defaultValue={propiedades.value ?? ''}
        variant="outlined"
        size="small"
        fullWidth
        InputLabelProps={{
            shrink: true,
        }}
        >
        
       </Controller>        
    </Grid>  
    </Hidden>     
    )
}
export default FormFieldText;






/*
import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';

const FormFieldText = ({ propiedades, control, onChangeValue=null }) => { 

    const handleChange = (v) => {
        if(onChangeValue){
            onChangeValue(v)
        } 
    }
    
    return (               
    <Grid item xs={propiedades.xs} className="" > 
    <Controller  
        name={propiedades.name}
        control={control}
        defaultValue={propiedades.value}
        render={({onChange, ...props}) => (
            <TextField
                required = {propiedades.required}
                id={propiedades.name}
                disabled = {propiedades.disabled}
                label={propiedades.label}
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                //value={propiedades.value}
                onChange={data => handleChange(data.target.value)}
            />
        )}
    > 
    </Controller> 
    </Grid>       
    )
}
export default FormFieldText;
*/