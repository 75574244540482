import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import FormularioPlanificacion from "./FormularioPlanificacion"
import FichaPlanificacion from "./FichaPlanificacion"
import JornadasPlanificacion from "./JornadasPlanificacion"
import FormularioPedidoProductos from "../../../../../components/Common/FormularioPedidoProductos"
import PlanificacionFTO from "./PlanificacionFTO";
import {usePlanificacion} from './planificacion-context'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    }, 
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepper: {        
      display: "flex",
      padding: "8px"      
    }
}));

const PasosPlanificacion = (props) => {  
    let { modo } = useParams();     
    const { pasoActivo, setPasoActivo, datosPlanificacion } = usePlanificacion();    
    const classes = useStyles();        

    function getSteps() {
      if(modo !== "R" && modo !== "W")
      {
        return ['Abrir Operativa'];
      }else{
        return ['Abrir Operativa', 'Productos', 'Planificar Operativa', 'Emitir Ficha Operativa'];
      }
    }

    const steps = getSteps(); 
       
    const handleStep = (step) => () => {
      setPasoActivo(step)   
    };

    const getStepContent = (step) => {
      switch (step) {
        case 0:          
          if(modo === "R") return <FichaPlanificacion />;          
          else return <FormularioPlanificacion />;
        case 1:
          return <FormularioPedidoProductos urlGetData={"planificaciones"} urlPostData={"planificacionesproductos"}/>            
        case 2:          
          return <JornadasPlanificacion />; 
          case 3:
            return <PlanificacionFTO />               
        default:
          return <FichaPlanificacion />;
      }
    }

    return (    
      <div className="bg-white mb-2  pl-3 pr-3 pb-0 m-0">
        <div className={classes.root}>
          <Stepper nonLinear activeStep={pasoActivo} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>  
            
        <div>
          <div className={classes.instructions}>{getStepContent(pasoActivo)}</div>
        </div>                    
      </div>
      );
}
export default PasosPlanificacion;