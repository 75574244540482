import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import MUIDataTable from "mui-datatables";
import SearchBar from "../../../../../components/Common/SearchBar";
import {COLUMNS_BANCOS, OPTIONS_BANCOS} from './CuentaConf'
import {COLUMNS_CONTACTOS, OPTIONS_CONTACTOS} from './CuentaConf'
import {COLUMNS_DIRECCIONES, OPTIONS_DIRECCIONES} from './CuentaConf'
import { CircularProgress } from '@material-ui/core';
import FichaCuentaIdentificacion from "./FichaCuentaIdentificacion"
import FichaCuentaCondiciones from "./FichaCuentaCondiciones"
import FormularioCuentaIdentificacion from "./FormularioCuentaIdentificacion"
import FormularioCuentaCondiciones from "./FormularioCuentaCondiciones"

import MigasDePan  from "../../../../../components/Common/MigasDePan";
import TablaExtraCostesCliente from '../../../../../components/Common/TablaExtraCostesCliente'

const Cuenta = (props) => {
  let { id, modo } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [datos, setDatos] = useState({
    cuenta: [],        
    modoIdentificacion: modo ? modo : "C",
    modoBanco: "R",
    modoDireccion: "R",
    modoCondiciones: "R",
    modificarDireccion: 0,
    modificarBanco: 0,
  });

  useEffect(() => {
    setIsLoading(true)    
  },[props.location.key])

  useEffect(() => { 
    if (isLoading && (modo === "R" || modo === "W")) {
      const fetchData = async () => {
        const respCuenta = await axios( process.env.REACT_APP_API_URL + "cuentas/" + id);
        if (respCuenta.data) {
         
          let direcciones = []
          if(respCuenta.data.Direcciones){            
            direcciones = respCuenta.data.Direcciones.map((direccion, index) => {                            
              return {
                Calle: direccion.Calle, 
                Ciudad: direccion.Ciudad, 
                Codigo_Postal: direccion.Codigo_Postal,
                Pais: direccion.Fk_Pais[0]['etiqueta'],
                Tipologia: direccion.Fk_Tipologia[0]['Etiqueta'],
                Id: direccion.Id,
                Letra: direccion.Letra,
                Numero: direccion.Numero,
                Piso: direccion.Piso,
                Provincia: direccion.Provincia,
                Fk_Tipologia: direccion.Fk_Tipologia[0]['Id'],
                Fk_Pais: direccion.Fk_Pais[0]['id'],
                PaisAF: direccion.Fk_Pais[0]
              }
            })            
          }
          setDatos({ ...datos, cuenta: respCuenta.data, direcciones: direcciones});
          setIsLoading(false);
        }
      };
      fetchData();
    }  

    if (!modo) {      
      setIsLoading(false);
    }
  }, [isLoading, datos, modo, id]);  

  const botoneraAcciones = [    
    {
      etiqueta: modo === "R" ? "Editar Cuenta" : "Cancelar Edición",
      url: 
      typeof id === "undefined" ? `/crm/cuentas/` :
      modo === "R" ? 
        `/crm/cuentas/cuenta/${id}/W` : 
        `/crm/cuentas/cuenta/${id}/R`,
      activo: true,
    },
  ];  

  const urlsMigasdepan = [
    {titulo: "Cuentas", colorTexto: "texto-comercial", urlTo: "/crm/cuentas/"},
    {titulo: "Cuenta", colorTexto: "texto-comercial", urlTo: `/crm/cuentas/cuenta/${id}/${modo}`},
  ]

  const esCliente = datos.cuenta.length === 0 ? false : datos.cuenta.Es_Cliente
  const isReadingMode  = modo === "R"

  return (
    <>
      <div className="col-8 col-main-home ">
        <SearchBar
          titulo="CRM"
          claseCabecera = "bg-comercial"
          buscar={false}
          imagenCabecera={"4-1-1-cuentas.png"}
        />

        {!isLoading && (
          <>
            <div className="bg-cuerpo p-10px">
              <div className="bg-white p-10px">                              
                <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={datos.cuenta.Razon_Social}/>
                                            
                <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                      
                  {isReadingMode && <FichaCuentaIdentificacion />}
                  {isReadingMode && <FichaCuentaCondiciones />}

                  { !isReadingMode && <FormularioCuentaIdentificacion />}

                  { !isReadingMode && <FormularioCuentaCondiciones />}
                      
                  { isReadingMode && (
                  <>
                    {esCliente === 1 && (
                      <SUBTABLAELEMENTOS title = 'Extracostes' children={<TablaExtraCostesCliente />} />
                    )}

                    <SUBTABLAELEMENTOS title = 'Direcciones' children={
                      <MUIDataTable
                            title={""}
                            className={"mt-1"}
                            data={datos.direcciones}
                            columns={COLUMNS_DIRECCIONES}
                            options={OPTIONS_DIRECCIONES}
                          />
                    } />

                    <SUBTABLAELEMENTOS title = 'Bancos' children={
                        <MUIDataTable
                          title={""}
                          className={"mt-1"}
                          data={datos.cuenta.Bancos}
                          columns={COLUMNS_BANCOS}
                          options={OPTIONS_BANCOS}
                        />
                    } />
                    
                    <SUBTABLAELEMENTOS title = 'Contactos' children={
                        <MUIDataTable
                        title={""}
                        className={"mt-1 mb-2"}
                        data={datos.cuenta.Contactos}
                        columns={COLUMNS_CONTACTOS}
                        options={OPTIONS_CONTACTOS}
                      />
                    } />
                  </>)}
                </div>
              </div>
              
              <TableAcciones
                filaSeleccionada={-1}
                botonaccion={botoneraAcciones}
              />
              
            </div>
          </>
        )}
        <div>{isLoading && <CircularProgress />}</div>
      </div>
    </>
  );
};

export default Cuenta;

const SUBTABLAELEMENTOS = ({title, children}) =>{
  return(
    <div className="mt-4">
      <h6 className="texto-comercial" id="title">{title}</h6>
      <div className="rounded border border-formulario p1-form">
       {children}
      </div>
    </div>
  )
}