import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'
import SearchBar from "../../../../../components/Common/SearchBar";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import MigasDePan from "../../../../../components/Common/MigasDePan";

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));


const FormularioBuques = () => {
    const { id } = useParams();
    const classes = useStyles(); 
    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos,setCampos] = useState([])  
    const [seleccion, setseleccion] = useState({}) 

    let CAMPOS_FORMULARIO_BUQUE = [           
      {
        required: false,
        label: "IMO:",
        name: "IMO",
        disabled: false,
        type: "text",
        value: "",              
        xs:3
      },
      {
        required: true,
        label: "Nombre:",
        name: "Nombre",
        disabled: false,
        type: "text",
        value: "",              
        xs:3
      },
      {
        urlData:"dic/diccionarios/buquestipo",
        campoEtiqueta: "Etiqueta",
        required: false,
        label: "Tipo de buque",
        name: "Fk_Tipo_Buque",
        disabled: false,
        type: "combo",           
        multiple:false,   
        value: "",
        xs:3
      },
      {
        required: false,
        label: "Codigo Buque (Poseidón):",
        name: "CodBuque",
        disabled: false,
        type: "text",             
        value: "",
        xs:3
      },
      {
        required: false,
        label: "Eslora:",
        name: "Eslora",
        disabled: false,
        type: "number",             
        value: "",
        xs:3
      },
      {
        required: false,
        label: "Manga:",
        name: "Manga",
        disabled: false,
        type: "number",             
        value: "",
        xs:3
      },
      {
        required: false,
        label: "Calado:",
        name: "Calado",
        disabled: false,
        type: "number",             
        value: "",
        xs:3
      },
      {
        campoEtiqueta: "Etiqueta",
        required: true,
        label: "Estado",
        name: "Activo",
        type: "comboArray", 
        options:[{Id: '1', Etiqueta: 'Activo'}, {Id: '0', Etiqueta: 'Inactivo'}], 
        multiple:false,   
        value: "",
        xs:3
      },  
    ];

    useEffect(() => {
      setCampos(CAMPOS_FORMULARIO_BUQUE)     
                   
      const fetchData = async () => {             
        if (id) {
          const respseleccion = await axios(`${process.env.REACT_APP_API_URL}buques/${id}`);
          setseleccion(respseleccion.data); 
        }
        setDatosCargados(true);
      }

      fetchData();

      return () => {
          setseleccion({}) 
          setDatosCargados(true);
          setCampos([])
      } 
    },[]);

    useEffect(() => {  
      if (typeof seleccion !== "undefined") {        
        if(Object.keys(seleccion).length > 0){ 
          const options= [{Id: 0, Etiqueta: 'Inactivo'}, {Id: 1, Etiqueta: 'Activo'}];
          const activo = options.filter((element)=> element.Id === seleccion.Activo);
          setDatosCargados(false);            
          campos[0].value = seleccion.IMO;
          campos[1].value = seleccion.Nombre;
          campos[2].value = seleccion.Fk_Tipo_Buque;
          campos[3].value = seleccion.CodBuque;
          campos[4].value = seleccion.Eslora; 
          campos[5].value = seleccion.Manga;  
          campos[6].value = seleccion.Calado;           
          campos[7].value = activo[0];
          setDatosCargados(true)  
        }
      }
    },[seleccion])

  const botoneraAcciones = [
    {
      etiqueta: "Cancelar",
      url: '/crm/buques',
      activo: true,
    } 
  ];

  const urlsMigasdepan = [
    {titulo: "CRM", colorTexto: "texto-comercial", urlTo: "/crm/buques"},
    {titulo: "Buques", colorTexto: "texto-comercial",urlTo:""},
  ]

  if (datoscargados){
    return ( 
      <>
        <div className="col-8 col-main-home ">
          <SearchBar
            titulo="Buques"
            claseCabecera = "bg-comercial"
            buscar={false}
            imagenCabecera={"6-3-2-rrhh.png"}
          />
          <div className="bg-cuerpo p-10px">
            <div className="bg-white p-10px">
              <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan}/>
              <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                <h6 className="texto-comercial" id="title">          
                </h6>
                <FormularioEntidad 
                  urlForm = "buques"
                  urlGoto = "/crm/buques/"
                  campos={campos}
                  type={ Object.keys(seleccion).length > 0 ? "edit" : "add" }
                /> 
              </div>
            </div>

            <TableAcciones
              botonaccion={botoneraAcciones}
            />
          </div>
        </div>
      </>       
    )
  }
  else {
    return (              
      <div className={classes.linear}>
        <CircularProgress />
      </div>
    );
  }

}
export default FormularioBuques;