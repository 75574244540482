import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function TipoAlmacen({opera, subOperacion}) {  
 const [tipoFormulario, setTipoFormulario] = useState(subOperacion)
   
 const handleRadioChange = (e) => {  
  setTipoFormulario(e.target.value)
  opera(e.target.value)
 } 

  return (        
    <div className="d-flex flex-column align-items-center">
        <FormControl component="fieldset" className="">
            <RadioGroup row aria-label="position" name="position" value={tipoFormulario} onChange={(e) => handleRadioChange(e)}>
            <FormControlLabel
                value="Contenedor"
                control={<Radio color="primary" />}
                label="Contenedor"
              />
              <FormControlLabel
                value="Rodante"
                control={<Radio color="primary" />}
                label="Rodante"
              />
              <FormControlLabel
                value="mGeneral"
                control={<Radio color="primary" />}
                label="M.General"
              />
            </RadioGroup>
        </FormControl>
    </div>    
  );
}