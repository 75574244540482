import React, { useEffect, useState, useRef } from "react";
import CuerpoTabla from "../../../../../components/Common/CuerpoTabla";
import {COLUMNS_PROYECTOS} from './ProyectosConf'
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useStyles } from "../../../../../styles/styles";
import useAxios
 from "../../../../../components/Common/FetchRemoteObjects";

const Proyectos = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const urlDatos = "proyectos/listadoproyectosempleado/" + user.id
  const [isLoading, setIsLoading] = useState(false);
  const entidadEliminar = useRef(-1);
  const [abrirDialogo, setAbrirDialogo] = useState(false)
  const [idProyectoEliminar, setIdProyectoEliminar] = useState(-1)
  const {loading, deleteData} = useAxios()
  const classes = useStyles();

  const BOTONERA_ACCIONES = [
    { etiqueta: "Crear Proyecto", url: "/crm/proyectos/proyecto", activo: true },
    {
      etiqueta: "Editar Proyecto",
      url: "/crm/proyectos/proyecto/filaSeleccionada/W",
      activo: false,
    }, 
    { etiqueta: "Eliminar Proyecto", function: (e) => eliminarEntidad(e), activo: false },
  ];  

  const eliminarEntidad = (id) => {
    entidadEliminar.current = id       
    setAbrirDialogo(true)
  }
  
  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

 useEffect(() => {
  if(idProyectoEliminar !== -1){
    deleteData('proyectos/' + idProyectoEliminar);
  }
 }, [idProyectoEliminar])
  
  const handleCerrarDialogo = (valor) => { 
    setAbrirDialogo(false)
    if(valor){  
      setIdProyectoEliminar(entidadEliminar.current)
    }
  }

  return (
  <>
  { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar el proyecto?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
  {!isLoading && (
    <CuerpoTabla
      tituloSearchBar = "CRM"
      claseBGSearchBar = "bg-comercial"
      titulo="Proyectos"
      columnas = {COLUMNS_PROYECTOS}
      botonera = {BOTONERA_ACCIONES}
      urlDatos ={urlDatos}
      urlDoubleClick = "proyectos/proyecto/"
      columnId="Id"
      imagenCabecera={"4-1-4-proyectos.png"}
    />
    )}
    {isLoading && 
      <div className={classes.linear}>
            <LinearProgress />
      </div>
  }
  </>
  )  
};

export default Proyectos;



/*
      MÉTODO ANTERIOR
      const fetchData = async () => {  
        setIsLoading(true)
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'proyectos';
        await axios
        .delete(SERVER_URL + "/" + entidadEliminar.current, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },        
        })
        .then((response) => {         
          if(!isNaN(response.data)){ 
            if(response.data === entidadEliminar.current){
              entidadEliminar.current = -1 
              setIsLoading(false)
              //window.location.reload();
            }          
          } else{
            alert(response.data);  
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
          setIsLoading(false)
        });  
      }
      fetchData();
*/