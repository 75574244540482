import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';

import Grid from '@material-ui/core/Grid';
import { TextField } from "@material-ui/core";
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'

import { useParams } from "react-router-dom";
import { useAlmacenamiento } from "./almacenamiento-context";

const TablaAlmacenamientoMateriales = () => {  
    const { modo } = useParams();
    const { listadoMaterialesAlmacenamiento, setListadoMaterialesAlmacenamiento } = useAlmacenamiento(); 
    const deshabilitado = modo === "R" ? true : false;     

    function renderEditDepositoCell(props){
        const { id, value, api, field } = props;        

        const handleChange = async (valor) => {            
            api.setEditCellValue({ id, field, value: valor }, valor);
            const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                if (row.id === id) {  
                  return { ...row,  deposito: valor};
                }
                return row;
            });              
            setListadoMaterialesAlmacenamiento(updatedRows)    

            const isValid = await api.commitCellChange({ id, field });
            if (isValid) {
                api.setCellMode(id, field, 'view');
            }            
        };

        const campoDepositos = 
        {
            urlData: "almacens",
            campoEtiqueta: "Nombre",
            required: false,
            label: "",
            name: "almacen",
            disabled: deshabilitado,
            type: "combo",           
            multiple:false,   
            value: '', 
            onChangeValue: handleChange,
            xs:12
        }

        return(
            <FormFieldComboUncontrolled propiedades={{...campoDepositos, value: value }} />
        )
    }  

    function renderEditFechaSalida(props){
        const { id, value, api, field } = props;
        let nuevoValor = value
        console.log(id, value, field)

        const handleChange = async (event) =>{            
            nuevoValor = event.target.value
            console.log(nuevoValor)
            const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                if (row.id === id) {  
                  return { ...row,  fechaSalida: nuevoValor};
                }
                return row;
            });           
            console.log(updatedRows)   
            setListadoMaterialesAlmacenamiento(updatedRows)    

            const isValid = await api.commitCellChange({ id, field });
            console.log(id, field, isValid)
            if (isValid) {
                api.setCellMode(id, field, 'view');
            }
        }


        return(
            <TextField value={nuevoValor}  type="date" disabled={deshabilitado} onChange={handleChange}/>
        )
    }

    function renderEditCodigoSalidaCell(props){
        const { id, value, api, field } = props;         
        let nuevoValor = value
        const handleChange = async (event) =>{            
            nuevoValor = event.target.value
            const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                if (row.id === id) {  
                  return { ...row,  codigoSalida: nuevoValor};
                }
                return row;
            });              
            setListadoMaterialesAlmacenamiento(updatedRows)    

            const isValid = await api.commitCellChange({ id, field });
            if (isValid) {
                api.setCellMode(id, field, 'view');
            }
        }

        return(
            <TextField value={nuevoValor}  disabled={deshabilitado} onChange={handleChange}/>
        )
        
    }

    function renderAsignarSalida(props){
        const { id } = props;   
        if(!deshabilitado && !props.row.asignarSalida && props.row.codigoSalida === null ){
            return(
                <EventBusyIcon onClick={() => {
                    const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                        if (row.id === id) {  
                            return { ...row,  asignarSalida: true};
                        }
                        return row;
                    });                        
                    setListadoMaterialesAlmacenamiento(updatedRows) 
                }}/>  
            )
        }

        if(!deshabilitado && props.row.asignarSalida && props.row.codigoSalida === null){
            return(
                <EventAvailableIcon onClick={() => {
                    const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                        if (row.id === id) {  
                            return { ...row,  asignarSalida: false};
                        }
                        return row;
                    });                    
                    setListadoMaterialesAlmacenamiento(updatedRows) 
                }}/>
            )            
        }        

    }
    
    const columns = [
        { field: 'id', headerName: 'id', width: 0, sortable: false, hide: true },
        { field: 'idPieza', headerName: 'idPieza', width: 0, sortable: false, hide: true },
        { field: 'nombrePieza', headerName: 'Pieza', width: 200, sortable: false, hide: false },
        { field: 'cantidad', headerName: 'Cantidad',  type: 'number',            
        renderCell: (params) => ( 
            <TextField type="number" value={params.value}  disabled={deshabilitado} /> ),   width: 100, sortable: false, editable: !deshabilitado },
        { field: 'metros', headerName: 'Mts cuadrados',  type: 'number',            
        renderCell: (params) => ( 
            <TextField type="number" value={params.value} disabled={deshabilitado} /> ),  width: 120, sortable: false, editable: !deshabilitado },          
        
        { field: 'deposito', headerName: 'Depósito', width: 150, sortable: false, editable: !deshabilitado,
        renderCell: (params) => (   
            <TextField value={params.value.Nombre}  disabled={deshabilitado} />           
        ), 
        renderEditCell: renderEditDepositoCell, },          
        { field: 'fechaEntrada', headerName: 'Fecha entrada', width: 120, sortable: false, hide: false },
        { field: 'fechaSalidaEstimada', headerName: 'Salida estimada', width: 130, sortable: false },  
        { field: 'fechaSalida', headerName: 'Fecha salida', type: 'date', width: 150, sortable: false, hide: false, editable: !deshabilitado,
        renderEditCell: renderEditFechaSalida, },
        { field: 'unidadesSalen', headerName: 'Uds. Salen',  type: 'number',            
        renderCell: (params) => (                        
            <TextField type="number" value={params.value}  disabled={deshabilitado} /> ),   
            width: 100, sortable: false, editable: !deshabilitado },
        { field: 'codigoSalida', headerName: 'Cód. salida', width: 120, sortable: false, hide: false, editable: !deshabilitado, 
        renderEditCell:  renderEditCodigoSalidaCell, },

        { field: 'asignarSalida', headerName: 'asignarSalida', width: 120, sortable: false, hide: true },
                
        { field: 'Eliminar', headerName: '', width: 80, sortable: false, 
            renderCell: (params) => (
                !deshabilitado && 
                    <DeleteIcon onClick={() => {
                        const elementosNoEliminar = listadoMaterialesAlmacenamiento.filter(el => el.id !== params.row.id)  
                        const elementoEliminar =  listadoMaterialesAlmacenamiento.filter(el => el.id === params.row.id)      
                        console.log(elementoEliminar, elementosNoEliminar)
                        setListadoMaterialesAlmacenamiento(elementosNoEliminar)                    
                    }}/>            
              ),
        },   
        { field: 'Asignar salida', headerName: '', width: 80, sortable: false, renderCell: renderAsignarSalida, },      
    ] 
    
    const handleCellEditCommit1 = React.useCallback(
    ({ id, field, value }) => {  

        function pad2(n) {
            return (n < 10 ? '0' : '') + n;
        }       

        if(field === "metros"){
            if(value){
                try{
                    const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                        if (row.id === id) {  
                            return { ...row,  metros: value};
                        }
                        return row;
                    });
                    setListadoMaterialesAlmacenamiento(updatedRows)                 
                }catch(error){
                    console.log(error)
                }
            }
        }
        
        if(field === "cantidad"){
            if(value){
                try{
                    const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                        if (row.id === id) {  
                            return { ...row,  cantidad: value};
                        }
                        return row;
                    });
                    setListadoMaterialesAlmacenamiento(updatedRows)                 
                }catch(error){
                    console.log(error)
                }
            }
        }

        if(field === "unidadesSalen"){
            if(value){
                try{
                    const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                        if (row.id === id) {                              
                            let cuantasSalen = 0;
                            cuantasSalen = parseFloat(row.cantidad)-value >= 0 ? value : parseFloat(row.cantidad)
                            if(row.codigoSalida)
                                cuantasSalen = ''
                            
                            return { ...row,  unidadesSalen: cuantasSalen};
                        }
                        return row;
                    });
                    setListadoMaterialesAlmacenamiento(updatedRows)                 
                }catch(error){
                    console.log(error)
                }
            }
        }

        if(field === "fechaSalida"){            
            if(value){
                try{
                    let mes = pad2(value.getMonth()+1);
                    let dia = pad2(value.getDate());
                    let anio= value.getFullYear();
                    let formattedDate =  anio + "-" + mes + "-" + dia; 

                    const updatedRows = listadoMaterialesAlmacenamiento.map((row) => {
                        if (row.id === id) {                          
                            let fEntrada = new Date(row.fechaEntrada)
                            let fSalida = new Date(formattedDate)
                            let dias = Math.round((fSalida-fEntrada)/(1000*60*60*24))
                            if(dias < 0 ){
                                alert("La fecha de salida no puede ser anterior a la fecha de entrada")
                                return row
                            }                        
                            return { ...row,  fechaSalida: formattedDate};
                        }
                        return row;
                    });                         
                    setListadoMaterialesAlmacenamiento(updatedRows) 
                }catch(error){
                    console.log(error)
                }
                 
            }
        }

    }, [listadoMaterialesAlmacenamiento], );        
        
    
    return(     
        <>
        <Grid container  spacing={1} className="mt-1">
        <Grid item xs={12} className="" style={{height: 220}}>
            <DataGrid 
                disableColumnMenu={true} 
                rows={listadoMaterialesAlmacenamiento} 
                columns={columns} 
                pageSize={100} 
                checkboxSelection = {false}
                hideFooter = {true}
                hideFooterPagination = {true}
                hideFooterRowCount = {true}
                hideFooterSelectedRowCount = {true}
                onCellEditCommit={handleCellEditCommit1}
            />
        </Grid>
        </Grid>
        </>
    )

}
export default TablaAlmacenamientoMateriales;