import React, { useState, useRef } from "react";
import CuerpoTabla from "../../../../../components/Common/CuerpoTabla";
import {COLUMNS_OPORTUNIDADES} from './OportunidadesConf'
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";
import { useStyles } from "../../../../../styles/styles";


const Oportunidades = () => {

  const user = JSON.parse(localStorage.getItem("user"));
  const urlDatos = "oportunidads/listadooportunidadesempleado/" + user.id

  const [isLoading, setIsLoading] = useState(false);
  const entidadEliminar = useRef(-1);
  const [abrirDialogo, setAbrirDialogo] = useState(false)

  const classes = useStyles();

  const BOTONERA_ACCIONES = [
    { etiqueta: "Crear Oportunidad", url: "/crm/oportunidades/oportunidad", activo: true },
    {
      etiqueta: "Editar Oportunidad",
      url: "/crm/oportunidades/oportunidad/filaSeleccionada/W",
      activo: false,
    }, 
    { etiqueta: "Eliminar Oportunidad", function: (e) => eliminarEntidad(e), activo: false },
  ];  

  const eliminarEntidad = (id) => {
    entidadEliminar.current = id       
    setAbrirDialogo(true)
  }

  const handleCerrarDialogo = (valor) => {       
    setAbrirDialogo(false)
    if(valor){    
      const fetchData = async () => {  
        setIsLoading(true)
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'oportunidads';
        await axios
        .delete(SERVER_URL + "/" + entidadEliminar.current, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },        
        })
        .then((response) => {         
          if(!isNaN(response.data)){ 
            if(response.data === entidadEliminar.current){
              entidadEliminar.current = -1 
              setIsLoading(false)
              //window.location.reload();
            }          
          } else{
            alert(response.data);  
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
          setIsLoading(false)
        });  
      }
      fetchData();
    }      
  }

  return (
 <>
  { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la oportunidad?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
    {!isLoading && (
    <CuerpoTabla
      tituloSearchBar = "CRM"
      claseBGSearchBar = "bg-comercial"
      titulo="Oportunidades"
      columnas = {COLUMNS_OPORTUNIDADES}
      botonera = {BOTONERA_ACCIONES}
      urlDatos = {urlDatos}
      urlDoubleClick = "oportunidades/oportunidad/"
      columnId="Id"
      imagenCabecera={"4-1-2-oportunidades.png"}
    />
    )}
    {isLoading && 
      <div className={classes.linear}>
            <LinearProgress />
      </div>
    }
     </>
  )  
};

export default Oportunidades;