import React, { createContext, useState, useMemo, useContext } from "react";

const AlmacenamientoContext = createContext();

export function AlmacenamientoProvider(props) {
    const [listadoMaterialesAlmacenamiento, setListadoMaterialesAlmacenamiento] = useState([])
    
    const value = useMemo(() => {
        return ({
            listadoMaterialesAlmacenamiento, setListadoMaterialesAlmacenamiento,           
        })
    },[
        listadoMaterialesAlmacenamiento, setListadoMaterialesAlmacenamiento,       
    ])

    return <AlmacenamientoContext.Provider value={value} {...props} />
}

export function useAlmacenamiento(){
    const context = useContext(AlmacenamientoContext)   

    if(!context){
        throw new Error('useAlmacenamiento debe estar dentro del proveedor AlmacenamientoContext')
    }

    return context;
}