import React, { useState, useEffect } from "react";
import FormFieldDateUncontrolled from "../../../../components/Common/FormFieldDateUncontrolled";
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export default function FiltroListado({urlfecha, firstDay, lastDay, showPrintButton = false, handlePrintReport = null}) { 

    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1, 
          padding: theme.spacing(1),
          margin: '10px',        
          backgroundColor: '#FFF',
        },
      }));

    const classes = useStyles();
    const[fechaInicio, setFechaInicio]=useState(firstDay);
    const[fechaFin, setFechaFin]=useState(lastDay);    
      
    const StyledButton = withStyles((theme) => ({
        root: {
          color: 'black',
          backgroundColor: '#c4c4c4',
          height: 32,
          marginTop: 0,          
          width: 60,
          '&:hover': {
            backgroundColor: '#777777',
          },
        },
    }))(Button);

  let propiedadesFechaInicio = {
    required: true,
    label: "Fecha Inicio",
    name: "fechaInicio",
    type: "date",    
    value: fechaInicio,
    xs:12
  }
      
  let propiedadesFechaFin = {
    required: true,
    label: "Fecha Fin",
    name: "fechaFin",
    type: "date",    
    value: fechaFin,
    xs:12
  }

  useEffect(() => {
    setFechaInicio(firstDay)
    setFechaFin(lastDay)
  }, [])

  const handleChangeFechaInicio = (valor) => {
    setFechaInicio(valor)
  }

  const handleChangeFechaFin = (valor) => {
    setFechaFin(valor)
  }

  const handleGetFecha = async () => { 
    const SERVER_URL = "?fechainicial=" + fechaInicio + "&fechafinal=" + fechaFin;    
    urlfecha(SERVER_URL)
  }

  const handleSetFecha = async () => { 
    const SERVER_URL = "";   
    setFechaInicio('') 
    setFechaFin('')
    urlfecha(SERVER_URL)
  }

  const handlePrint = () => {
    const SERVER_URL = "?fechainicial=" + fechaInicio + "&fechafinal=" + fechaFin;   
    if(handlePrintReport)
      handlePrintReport(SERVER_URL)
  }


  return(
    <div className={classes.root}> 
  
    <Grid container spacing={1} className="" style={{backgroundColor: "#FFF"}}>

      <Grid item xs={5} className="" ></Grid>

      <Grid item xs={2} className="" >
        <FormFieldDateUncontrolled propiedades={propiedadesFechaInicio} onChangeValue={handleChangeFechaInicio} />
      </Grid> 

      <Grid item xs={2} className="" >   
        <FormFieldDateUncontrolled propiedades={propiedadesFechaFin} onChangeValue={handleChangeFechaFin} />
      </Grid>

      <Grid item xs={1} className="" >
        <StyledButton size="small" className={classes.margin}
          onClick={handleGetFecha}>
          Filtrar
        </StyledButton>
      </Grid>      

      <Grid item xs={1} className="" >
        <StyledButton size="small" className={classes.margin}
          onClick={handleSetFecha}>
          Limpiar
        </StyledButton>
      </Grid> 
      {showPrintButton && (    
      <Grid item xs={1} className="" >
        <StyledButton size="small" className={classes.margin}
          onClick={handlePrint}>
          Imprimir
        </StyledButton>
      </Grid> 
      )}
    </Grid>
    </div>
  )
}

