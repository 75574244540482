import MUIDataTable from "mui-datatables";
import React from "react";
import { useParams } from "react-router-dom";
import AlertaMensaje from "../../../../components/Common/AlertaMensaje";
import CuerpoTabla from "../../../../components/Common/CuerpoTabla";
import CuerpoTablaDatos from "../../../../components/Common/CuerpoTablaDatos";
//import SearchBar from "../Common/SearchBar";
//import TableAcciones from "../Common/TableAcciones";
import FormConfigTabla from "./FormConfigTabla";
import SearchBar from "../../../../components/Common/SearchBar";
import TableAcciones from "../../../../components/Common/TableAcciones";


const EditConfigTabla =  () => {

    let {nombre_tabla} = useParams();

  const BOTONERA_ACCIONES = [
    { etiqueta: "Cancelar", url:"/config/tablas", activo: true }
  ];  


  return (

    <>
  <div className="col-main-home">

  <SearchBar
    titulo="Configuracion"
    claseCabecera="bg-comercial"
    buscar={false}         
  />

<div className="bg-cuerpo p-10px">

           <FormConfigTabla/>

  <TableAcciones                             
      botonaccion={BOTONERA_ACCIONES}
  />

  <AlertaMensaje/>
</div>
</div>
    </>
    
  )  
}
export default EditConfigTabla;
