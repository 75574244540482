import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'


const CuentasPyGManuales = ({listadoDatos}) => {
    const { control } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [datos, setDatos] = useState();
    const [datosCuentas, setDatosCuentas] = useState({
        Enero: 0, Febrero: 0, Marzo: 0, Abril: 0, Mayo: 0, Junio: 0, Julio: 0, Agosto: 0, Septiembre: 0, Octubre: 0, Noviembre: 0, Diciembre: 0, 
        year: new Date().getFullYear(), cuenta: -1
    });
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    let propiedadesJornadas = {
        urlData: "dic/diccionarios/cuentaspygrellenarmanualmente",
        campoEtiqueta: "Codigo,Etiqueta",
        required: true,
        name: "cuentas",
        disabled: false,
        label: "Cuentas",
        value: '',
        multiple: false,
        xs: 12
    }

    useEffect(() => {
        if (isLoading) {
          const fetchData = async () => {            
            const respPyG = await axios(process.env.REACT_APP_API_URL + "dic/diccionarios/cuentaspygrellenarmanualmente");
            console.log(process.env.REACT_APP_API_URL + "dic/diccionarios/cuentaspygrellenarmanualmente")
            if (respPyG.data) {
              setDatos(respPyG.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }          
    }, []);

    useEffect(() => {
        if(listadoDatos)
            listadoDatos(datosCuentas);
    }, [datosCuentas]);

    useEffect(() => {
        if(datosCuentas.cuenta > 0 && datosCuentas.year > 0)
        {
            console.log(datosCuentas)
            console.log(process.env.REACT_APP_API_URL + "diccuentapygdatos/datoscuentayear/" + datosCuentas.cuenta + "/" + datosCuentas.year)
            const fetchData = async () => {  
                const respPyG = await axios(process.env.REACT_APP_API_URL + "diccuentapygdatos/datoscuentayear/" + datosCuentas.cuenta + "/" + datosCuentas.year);
                if (Object.keys(respPyG.data).length > 0) {
                    setDatosCuentas(respPyG.data);                    
                }else{
                    setDatosCuentas({
                        ...datosCuentas, Enero: 0, Febrero: 0, Marzo: 0, Abril: 0, Mayo: 0, Junio: 0, Julio: 0, Agosto: 0, Septiembre: 0, Octubre: 0, Noviembre: 0, Diciembre: 0
                    });
                }
                setIsLoading(false);
            };
            fetchData();
        }        
    }, [datosCuentas.year, datosCuentas.cuenta]);

    const handleCuentaClick = (cuenta) => {
        setDatosCuentas({ ...datosCuentas, 'cuenta': cuenta.Id });
    }

    const handleCuenta = (cuenta) => {
        console.log(cuenta)
        setDatosCuentas({ ...datosCuentas, 'cuenta': cuenta.Id });
    }

    const handleYearClick = (year) => {
        setDatosCuentas({ ...datosCuentas, 'year': year });
    }

    const handleChange = (prop) => (event) => { 
        setDatosCuentas({ ...datosCuentas, [prop]: event.target.value });
    };  

    
    const total = parseFloat(datosCuentas.Enero) + parseFloat(datosCuentas.Febrero) +
        parseFloat(datosCuentas.Marzo) + parseFloat(datosCuentas.Abril) + parseFloat(datosCuentas.Mayo) + 
            parseFloat(datosCuentas.Junio) + parseFloat(datosCuentas.Julio) + parseFloat(datosCuentas.Agosto) +
                parseFloat(datosCuentas.Septiembre) + parseFloat(datosCuentas.Octubre) + parseFloat(datosCuentas.Noviembre) +
                    parseFloat(datosCuentas.Diciembre);
      

    return (        
        <div className="row">        
        {!isLoading && (
        <>
          <div className="col-6">
            <div className="row no-gutters">
                <div className="col-5">
                    <Button fullWidth color={new Date().getFullYear()-1 === datosCuentas.year ? "primary" : "default" } style={{ marginTop: '20px', marginLeft: '20px' }} variant="contained" onClick={()=>handleYearClick(new Date().getFullYear()-1)}>{new Date().getFullYear()-1}</Button>            
                </div>
                <div className="col-1"></div>
                <div className="col-5">
                    <Button fullWidth color={new Date().getFullYear() === datosCuentas.year ? "primary" : "default" } style={{ marginTop: '20px', marginLeft: '20px' }} variant="contained" onClick={()=>handleYearClick(new Date().getFullYear())}>{new Date().getFullYear()}</Button>            
                </div>
            </div>

            <div className="row no-gutters mt-5">
                <div className="col-12 mt-2">
                    <FormFieldComboUncontrolled propiedades={propiedadesJornadas} onChangeValue={handleCuenta} control={control}/>
                </div>
            </div>
            {/*
            { datos.map((cuentapyg) => (
                <Button key={cuentapyg.Id} className="col-3 ml-1 mr-1" fullWidth color={cuentapyg.Id === datosCuentas.cuenta ? "primary" : "default"} style={{ marginTop: '20px' }} variant="contained" onClick={()=>handleCuentaClick(cuentapyg)}>{cuentapyg.Codigo} {" "} {cuentapyg.Etiqueta}</Button>            
            ))}
            */}

          </div> 
          <div className="col-2"></div> 
          <div className="pl-1 mt-3 col-2">
            <div className="row">   
                { meses.map((mes) => (
                    <div key={mes} className="col-12"> 
                        <FormControl fullWidth className="">
                            <InputLabel htmlFor={mes.toUpperCase()}>{mes.toUpperCase()}</InputLabel>
                            <Input
                                id={mes}
                                type="number"
                                style={{margin: '0px'}}
                                value={datosCuentas[mes]}
                                onChange={handleChange(mes)}
                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                            />
                        </FormControl>
                    </div>
                ))}   
                <div className="col-12 mt-5"> 
                        <FormControl fullWidth className="">
                            <InputLabel htmlFor="total">TOTAL EJERCICIO</InputLabel>
                            <Input
                                id="totalEjercicio"
                                type="number"
                                style={{margin: '0px'}}
                                value={total}
                                disabled = {true}
                                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                            />
                        </FormControl>
                    </div>
            </div>
          </div> 
        
        </>       
        )}
        {isLoading && (
            <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
        )} 
        </div>        
    );
}
export default CuentasPyGManuales;