import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";

const TablaExtraCostesCliente = () => {  
    const { id, modo } = useParams();
    const [listadoProductosInicial, setListadoProductosInicial] = useState([])        

    const columns = [    
        { field: 'id', headerName: 'Id', width: 100, sortable: false, hide: true },
        { field: 'Jornada', headerName: 'Turno', width: 340 },//EAN
        { field: 'Porcentaje', headerName: 'Porcentaje',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 150, sortable: false, editable: true },
        
        { field: 'Guardar', headerName: '', width: 50, 
            renderCell: (params) => {
                const disabled= modo === "W" && id ? false : false
                if(!disabled)
                    return <SaveIcon onClick={() => handleSaveElement(params.row)}/>
                
                return null;
            }, 
        },
    ];

    const handleSaveElement = async (element) => {
        let datos = {};
        datos = element

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}cuenta/extracostes/${element.id}`
      
      
        await axios
        .put(`${SERVER_URL}`, {
          headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
         
          if( Array.isArray(response.data))
          {
            setListadoProductosInicial(response.data)
          }
          
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
      });
      
    }

    useEffect(() => { 
        let isMounted = true;
        const fetchData = async () => {
            const respCuenta = await axios(`${process.env.REACT_APP_API_URL}cuentas/gettablaextracostes/${id}`);
            if (respCuenta.data && isMounted) {
                setListadoProductosInicial(respCuenta.data)
            }
        };
        fetchData();

        return () => {
            isMounted = false;
            setListadoProductosInicial([])
        }
    }, [id]); 
    
    return(
        <>        
        <Grid item xs={12} className="" style={{height: 280}}>
            <DataGrid 
                disableColumnMenu={true}
                rows={listadoProductosInicial} 
                columns={columns} 
                pageSize={100} 
                checkboxSelection = {false}
                hideFooter = {true}
                hideFooterPagination = {true}
                hideFooterRowCount = {true}
                hideFooterSelectedRowCount = {true}
                //onCellEditCommit={handleCellEditCommit}
                />
        </Grid>
        </>
    )
}
export default TablaExtraCostesCliente;