import React, { createContext, useState, useMemo, useContext } from "react";

const OperacionContext = createContext();

export function OperacionProvider(props) {
    const [operacion, setOperacion] = useState(0)
    const [codigoOperacion, setCodigoOperacion] = useState("")
    const [datosFormulario, setDatosFormulario] = useState({})
    const [datosJornadas, setDatosJornadas] = useState([])
    const [datosPresupuesto, setDatosPresupuesto] = useState({})
    const [pasoActivo, setPasoActivo] = useState(0)
    const [manoActiva, setManoActiva] = useState(0)
    const [costesDirectos, setCostesDirectos] = useState(0)
    const [datosOperacion, setDatosOperacion] = useState({})
    const [datosDesplegables, setDatosDesplegables] = useState({})
    const [manosOperacion, setManosOperacion] = useState([])
    const [documentosTDR, setDocumentosTDR] = useState([])
    
    const value = useMemo(() => {
        return ({
            operacion, setOperacion,
            codigoOperacion, setCodigoOperacion,
            datosFormulario, setDatosFormulario,
            datosJornadas, setDatosJornadas,
            datosPresupuesto, setDatosPresupuesto,
            pasoActivo, setPasoActivo,
            manoActiva, setManoActiva,
            costesDirectos, setCostesDirectos,
            datosOperacion, setDatosOperacion,
            datosDesplegables, setDatosDesplegables,
            manosOperacion, setManosOperacion,
            documentosTDR, setDocumentosTDR
        })
},[
    operacion, setOperacion, 
    codigoOperacion, setCodigoOperacion,
    datosFormulario, setDatosFormulario,
    datosJornadas, setDatosJornadas,
    datosPresupuesto, setDatosPresupuesto,
    pasoActivo, setPasoActivo,
    manoActiva, setManoActiva,
    costesDirectos, setCostesDirectos,
    datosOperacion, setDatosOperacion,
    datosDesplegables, setDatosDesplegables,
    manosOperacion, setManosOperacion,
    documentosTDR, setDocumentosTDR  
])

return <OperacionContext.Provider value={value} {...props} />
}

export function useOperacion(){
    const context = useContext(OperacionContext)

    if(!context){
        throw new Error('useOperacion debe estar dentro del proveedor OperacionContext')
    }

    return context;
}