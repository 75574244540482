import React, { useState, useEffect, useContext } from "react";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import {columnsDepositos} from "./LogisticaConf";
import {botoneraDepositos} from "./BotonesConf";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";

const Depositos = (props) => {  
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  let urlDatosCuerpoTabla = "depositos";
  let urlDoubleClickCuerpoTabla = "depositos/deposito/";
  let columnIdCuerpoTabla = "id";

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }  
               
  return (           
    <div className="col-8 col-main-home ">            
      <SearchBar
        titulo="Logística interna"
          claseCabecera = "bg-produccion"
        buscar={false}
        imagenCabecera={"5-1-3-depositos.png"}
      />   
        
      <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Almacenes
              </h6>
            </div>
            <hr className="m-0 bg-comercial mb-1" />
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                <CuerpoTablaPiezas
                columnas = {columnsDepositos}
                urlDatos = {urlDatosCuerpoTabla}
                urlDoubleClick = {urlDoubleClickCuerpoTabla}
                columnId = {columnIdCuerpoTabla}
                getFilaSeleccionada = {marcarFila}
              /> 
            </div>
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
            <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botoneraDepositos}
              />  
            </div>
          </div>

        </div>
          
      </div>   
    
  );
}
export default Depositos;