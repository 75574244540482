import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'


const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioFicherosDocumentacionPersonal = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [reconocimiento, setReconocimiento] = useState([]) 


    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([      
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_rrhh_datos_personales_adjuntos",
                xs:12
              },            
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: reconocimiento.ficheros,    
                tabla: "mtms_rrhh_datos_personales_adjuntos",
                modelo: "api\\modules\\v1\\models\\rrhh\\RRHHDatosPersonalesAdjuntos",
                carpeta: "usuarios//documentacion//personal",
                
                xs:12
              },  
              {
                required: false,
                label: "Id Usuario",
                name: "idUsuario",
                type: "text", 
                disabled: modo === "R" ? true : false,             
                value: localStorage.getItem('idUsuario') ,
                classes: "d-none",
                xs:4
            },            
    ])

    const fetchData = async () => { 
        if (modo === "W" || modo === "R") {
            const respreconocimiento = await axios(
                `${process.env.REACT_APP_API_URL}rrhhdatospersonalesadjuntos/${localStorage.getItem('idUsuario')}`
            );  
            setReconocimiento(respreconocimiento.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[]);

useEffect(() => {            
    if (typeof reconocimiento !== "undefined") {        
      if(Object.keys(reconocimiento).length > 0){               
        setDatosCargados(false)           
        campos[1].ficheros= reconocimiento;
        campos[2].value = localStorage.getItem('idUsuario');
        setDatosCargados(true)  
      }
  }
  },[reconocimiento])

if (datoscargados){
    return ( 
        <FormularioEntidad 
        urlForm = "rrhhdatospersonalesadjuntos"
        campos={campos}
        type="onlyadd"
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioFicherosDocumentacionPersonal;