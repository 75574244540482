import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';

const FormularioEntradaInicial = ({listadoEntradas,ListadoEntradaFinal}) => {   

    const [listadoEntradaInicial, setListadoEntradaInicial] = useState([])   
    const { modo } = useParams();

    const columns = [       
        { field: 'codigo', headerName: 'Código', width: 100 },
        { field: 'descripcion', headerName: 'Descripción artículo', width: 150 },
        { field: 'unidad', headerName: 'Unidad', width: 90 },
        { field: 'cantidad', headerName: 'Cantidad', width: 90 },
        { field: 'precioUnidad', headerName: 'Precio Ud.', width: 90 },
        { field: 'igic', headerName: '% IGIC', width: 90 },        
        { field: 'precioTotal', headerName: 'Total', width: 120 },        
        { field: 'depositos', headerName: 'Almacén', width: 100 },
        { field: '', headerName: '', width: 80, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
              ), },
    ];
    
    const resetForm = () => {
        setListadoEntradaInicial([])
    }

    useEffect(() => {
        console.log(listadoEntradas)
        if(  typeof listadoEntradas === 'undefined' ||
            (Array.isArray(listadoEntradas) && listadoEntradas.length === 0 )
        ){        
            resetForm()
        }else{
            setListadoEntradaInicial(listadoEntradas)
        }       
    },[listadoEntradas])

    const handleRemoveElement = (element) =>{        
        if (modo==="R") return;

        const elementoAEliminar = listadoEntradaInicial.filter (el => {
            return el.id !== element.id
        })        
        ListadoEntradaFinal(elementoAEliminar)
    }  
    
    return(
        <>
        <Grid container  spacing={1} className="mt-2">
            <Grid item xs={12} className="" style={{height: 280}}>
                <DataGrid 
                    rows={listadoEntradaInicial} 
                    columns={columns} 
                    pageSize={100} 
                    checkboxSelection = {false}
                    hideFooter = {true}
                    hideFooterPagination = {true}
                    hideFooterRowCount = {true}
                    hideFooterSelectedRowCount = {true}
                />
            </Grid>
        </Grid>
        </>
    )
}
export default FormularioEntradaInicial;