import React, { createContext, useState, useMemo, useContext } from "react";

const FacturaCompraContext = createContext();


export function FacturaCompraProvider(props) {
    const [listadoProductosFacturaCompra, setListadoProductosFacturaCompra] = useState([])

    const value = useMemo(() => {
        return ({
            listadoProductosFacturaCompra, setListadoProductosFacturaCompra

        })
    },[
        listadoProductosFacturaCompra, setListadoProductosFacturaCompra
    ])

    return <FacturaCompraContext.Provider value={value} {...props} />
}


export function useFacturaCompra(){
    const context = useContext(FacturaCompraContext)

    if(!context){
        throw new Error('useFacturaCompra debe estar dentro del proveedor FacturaCompraContext')
    }

    return context;
}