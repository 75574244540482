import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({         
    table: {
      minWidth: 700,
    },
    celda:{
      borderWidth: '1px',
      borderColor: '#000',
      borderStyle: 'solid',
      padding: '2px !important'
    },
    celdaNegrita:{
      borderWidth: '1px',
      borderColor: '#000',
      borderStyle: 'solid',
      padding: '2px !important',
      fontWeight: 'bold'
    }
})); 

const ListadoProductos = ({listadoProductos}) => {
    const classes = useStyles(); 
          
    return (
    <>
    {Object.keys(listadoProductos).length > 0 && (
    <TableContainer  component={Paper}>
        <Table  className={classes.table} aria-label="spanning table">
            {/* CABECERA */}
            <TableHead>
                <TableRow >                        
                    <TableCell  className={classes.celdaNegrita} align="center"  colSpan={3}>Id</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center"  colSpan={3}>Operativa</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center"  colSpan={3}>Categoría</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center"  colSpan={3}>Subcategoría</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center" colSpan={3}>Código</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center" colSpan={3}>Nombre</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center"  colSpan={3}>Medida</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center"  colSpan={3}>Periodicidad</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center"  colSpan={3}>Estado</TableCell>
                    <TableCell  className={classes.celdaNegrita} align="center" colSpan={3}>Tarifa(€)</TableCell>                        
                </TableRow>
            </TableHead>

            <TableBody>
            <>
            {listadoProductos.map((option, index) => (
                <TableRow key={index} > 
                    <TableCell   className={classes.celda} align="center"  colSpan={3}>{option.Id} </TableCell>     
                    <TableCell   className={classes.celda} align="center"  colSpan={3}>{option.Operativa}</TableCell>   
                    <TableCell   className={classes.celda} align="center"  colSpan={3}>{option.categoria}</TableCell> 
                    <TableCell   className={classes.celda} align="center"  colSpan={3}>{option.Subcategoria}</TableCell>                                                             
                    <TableCell   className={classes.celda} align="center"  colSpan={3}>{option.codigo}</TableCell>
                    <TableCell   className={classes.celda} align="left"  colSpan={3}>{option.nombre}</TableCell>
                    <TableCell   className={classes.celda} align="center"  colSpan={3}>{option.unidad_medida}</TableCell>   
                    <TableCell   className={classes.celda} align="center"  colSpan={3}>{option.periodicidad}</TableCell> 
                    <TableCell   className={classes.celda} align="center"  colSpan={3}>{option.estado}</TableCell>                                                                 
                    <TableCell   className={classes.celda} align="right"  colSpan={3}>{option.tarifa}</TableCell>
                </TableRow>
            ))}
            </>

            </TableBody>

        </Table>
    </TableContainer>  
    )}
    </>
    )
}

export default ListadoProductos