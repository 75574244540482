import React, {useState} from "react";
import { useParams } from "react-router-dom";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { makeStyles } from "@material-ui/core/styles";
import FormularioPedido from "./FormularioPedido"
import FichaPedido from "./FichaPedido"

import FormularioPedidoProductos from "../../../../../components/Common/FormularioPedidoProductos"

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    }, 
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepper: {        
      display: "flex",
      padding: "12px"      
    }
}));



const PasosPedido = ({getCodigo}) => {  
    let { modo } = useParams(); 
    const classes = useStyles();    
    const steps = getSteps(); 
    const [ pasoActivo, setPasoActivo ] = useState(0);
    
    function getSteps() {
      if(modo !== "R" && modo !== "W")
          {
            return ['Datos'];
          }else{
            return ['Datos', 'Productos'];
          }
        //return ['Datos', 'Productos'];
    }

    const handleStep = (step) => () => {
        setPasoActivo(step)   
    };

    const getCodigoPedido = (valor) => {
        getCodigo(valor) 
    }

    const getStepContent = (step) => {
      console.log(step)
        switch (step) {
          case 0:          
            if(modo === "R")
            {
              return <FichaPedido getCodigo={getCodigoPedido}/>;            
            }
            else
            {
              return <FormularioPedido getCodigo={getCodigoPedido}/>;
            }
            
          case 1:
            return <FormularioPedidoProductos urlGetData={"pedidoclientes"} urlPostData={"pedidoclienteproductos"} />;            
                    
          default:
            return <FichaPedido getCodigo={getCodigoPedido}/>;
        }
    }

    return (
        <div className="bg-white mb-2 pt-3 pl-3 pr-3 pb-0 m-0">
        <div className={classes.root}>
          <Stepper nonLinear activeStep={pasoActivo} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepButton
                  onClick={handleStep(index)}
                  className={pasoActivo === index ? "fondo-comercial" : ""}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>  
            
        <div>
          <div className={classes.instructions}>{getStepContent(pasoActivo)}</div>
        </div>                    
      </div>
    )

}
export default PasosPedido;