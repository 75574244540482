import React from "react";
import "semantic-ui-css/semantic.min.css";
import SearchInput from "./SearchInput";
const SearchBar = (props) => {

  return (
    <div className={`row cabecera ${props.claseCabecera} card no-gutters`}>
      <div className="col-12">
        <span id="title" className="text-white pl-2 p-2 float-left">
          {props.titulo}
        </span>
        <span id="title-image" className="float-right mr-3">
        <img
            src={`${process.env.REACT_APP_IMAGES}${props.imagenCabecera}`}
            width="32"
            height="32"
            alt=""
            loading="lazy"
          />
        </span>
      </div>
      {props.buscar && (
        <div className="col-4">
          <SearchInput onClick={props.onClick} />
        </div>
      )}
    </div>
  );
};
export default SearchBar;
