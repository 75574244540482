import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldComboUncontrolled from '../../../../../components/Common/FormFieldComboUncontrolled'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";

/*Importar aqui los formularios de los cuales se va a tirar*/
import FormularioTablaHojaDePedidos from "./FormularioTablaHojaDePedidos";
//esto es para la tabla
import TablaCompra from "./TablaCompra";
import { useHistory } from "react-router";
import { useStyles } from "../../../../../styles/styles";




const FormularioOrdenDeCompra = ({index, tipoForm}) => { 
 
  
  const [OrdenCompra, setOrdenCompra] = useState([]) 
  const [hojaPedido, setHojaPedido] = useState([]) 
  const [campos, setCampos] = useState([]) 
  const { id, modo } = useParams();
  //array para cargar los datos de los formularios correspondientes
  const [listadoCompra, setListadoCompra] = useState([])
  const history = useHistory();  
  const methods = useForm();
  
  const classes = useStyles();;
  const { control, handleSubmit, setValue} = useForm();  

  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ficheros, setFicheros] = useState([])
  const [nombresFicheros, setNombresFicheros] = useState([])  


    const handleListadoTabla=(valor) => {
      //console.log(valor)
      //console.log(listadoCompra)
      setListadoCompra([...listadoCompra,valor])
    }

    const handleRemoveElement = (element) =>{
      setListadoCompra(element) 
    }

    
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };  
 
    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }

    useEffect(() => {      
      
      setCampos([
        //0
          {
            urlData: "hojaspedidos/cerradas",
            campoEtiqueta: "Codigo",
            campoId: "Id",
            required: true,
            name: "hojaPedido",
            disabled: false,
            label: "Hoja de Pedido",
            type:'combo',
            value: '',
            multiple: false,
            xs: 4
          },
          //1
          {
            urlData: "dic/diccionarios/listadousuarios",
            campoEtiqueta: "nombre",
            campoId: "id",
            required: true,
            name: "nombre",
            disabled: false,
            label: "Solicitante",
            type:'combo',
            value: '',
            multiple: false,
            xs: 4
          },
          //2
          //Tipo combo
          {
              urlData: "dic/diccionarios/getdepartamentos",
              campoEtiqueta: "Etiqueta",
              campoId: "Id",
              required: true,
              name: "departamentos",
              disabled: false,
              type:'combo',
              label: "Departamentos",
              value: '',
              multiple: false,
              xs: 4
            },
            //3
          //Tipo fecha
          {
            required: true,
            label: "Fecha Compra",
            name: "fechaCompra",
            type: "date",    
            value: '',
            xs: 4
            },
            //4
            //Tipo combo
            {
              urlData: "dic/diccionarios/listadousuarios",
              campoEtiqueta: "nombre",
              required: false,
              name: "encargado",
              disabled: false,
              label: "Autorizado",
              type:'combo',
              value: '',
              multiple: false,
              xs: 4
            },
            //5
          //Tipo combo
          {
              urlData: "dic/diccionarios/estadosordencompra",
              campoEtiqueta: "Etiqueta",
              required: true,
              name: "estado",
              disabled: false,
              type:'combo',
              label: "Estado",
              value: '',
              multiple: false,
              xs: 4
          },
          //6
          {
            required: false,
            name: "observaciones",
            disabled: false,
            label: "Observaciones",
            value: '',
            type: 'textarea',
            lineas:3,
            xs: 6
          },
          //7
          {
            required: false,
            label: "Documentacion",
            name: "ficherosadjuntos",
            type: "filesupload",   
            tabla: "mtms_orden_compra_adjuntos",
            xs:6
          },
          //8
          {
            required: false,
            label: "Documentacion",
            name: "ficheros",
            type: "files", 
            ficheros:"",    
            tabla: "mtms_orden_compra_adjuntos",
            modelo: "api\\modules\\v1\\models\\ordencompra\\OrdenCompraAdjuntos",
            carpeta: "orden-compra",
            xs:12
          }
    

      ])

      const fetchData = async () => {
        setIsLoading(true) 
        if (modo === "W") {

            const respOrdenCompra = await axios(
                `${process.env.REACT_APP_API_URL}ordenescompras/${id}`
            );             
            console.log(respOrdenCompra.data)   
            setOrdenCompra(respOrdenCompra.data)  
        }
        setIsLoading(false);

      };    
      fetchData();       
    },[]);
    

    useEffect(() => {
  if (typeof OrdenCompra !== "undefined") { 
    if(Object.keys(OrdenCompra).length > 2){
      setIsLoading(true)  
      console.log(OrdenCompra)
      campos[0].value = OrdenCompra.HojaPedido;
      campos[1].value = OrdenCompra.Solicita;
      campos[2].value = OrdenCompra.Departamento;
      campos[3].value = OrdenCompra.Fecha_Compra;
      campos[4].value = OrdenCompra.Autoriza;
      campos[5].value = OrdenCompra.Estado;           
      campos[6].value = OrdenCompra.Observaciones;
      //campos[7].ficheros = OrdenCompra.ficheros;
      campos[8].ficheros = OrdenCompra.ficheros;
      setListadoCompra(OrdenCompra.articulos);
      setIsLoading(false)        
    }
  }
    }, [OrdenCompra])



const handleSubmitPost = async (datos) => {
  datos.ficheros=ficheros
  datos.nombresFicheros=nombresFicheros
  datos.listadotabla=listadoCompra

  console.log(datos)
  

  let user = JSON.parse(localStorage.getItem("user"));
  const AuthStr = "Bearer ".concat(user.access_token);
  const SERVER_URL = `${process.env.REACT_APP_API_URL}ordenescompras`


    if (modo==="W") {
      await axios
        .put(`${SERVER_URL}/${id}`, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          console.log(response.data) 
          if(!isNaN(response.data)){
            if(parseInt(response.data) === parseInt(id)){                       
                setOpenAlert(true)
                setTimeout(function () {
                  window.location.reload();   
                }
                , 1500);
            }
          }
          })
          .catch((error) => {
            console.log("error " + error);
      });
    }
    else{
      await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          console.log(response) 
          if(!isNaN(response.data)){                                
                setOpenAlert(true)
                setTimeout(function () {
                  history.push({
                    pathname: `/administracion/compras/ordenescompras/${response.data}/W`,
                    //action: action + Math.floor(Math.random() * (9999))
                  });   
                }
                , 1500);
          }
          })
          .catch((error) => {
            console.log("error " + error);
      });
    }
}

const handleChangeHojaPedido = (hojaPedidobbdd) => {  
  console.log(hojaPedidobbdd)

  if (hojaPedidobbdd && typeof hojaPedidobbdd !== "undefined") { 
    if(Object.keys(hojaPedidobbdd).length > 2){  
      console.log(hojaPedidobbdd)
      setValue("nombre", hojaPedidobbdd.Solicita);
      setValue("departamentos", hojaPedidobbdd.Departamento);    
      setHojaPedido(hojaPedidobbdd)      
      setListadoCompra(hojaPedidobbdd.articulos)      
    }
  }else{
    setValue("nombre", "");
      setValue("departamentos", "");    
      setHojaPedido([])      
      setListadoCompra([]) 
  }
}

return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-OrdenDeCompra'
            onSubmit={handleSubmit((data) => {
            handleSubmitPost(data);
            })}
            className=""
          >

          <Grid className="borderFormulario" container spacing={1}> 
              <FormFieldComboUncontrolled propiedades={campos[0]} control={control} onChangeValue={handleChangeHojaPedido}/>
              <FormFieldCombo propiedades={campos[1]} control={control}/>
              <FormFieldCombo propiedades={campos[2]} control={control}/>              
              <FormFieldDate propiedades={campos[3]} control={control}/>
              <FormFieldCombo propiedades={campos[4]} control={control}/>
              <FormFieldCombo propiedades={campos[5]} control={control}/>
              <FormFieldTextArea propiedades={campos[6]} control={control}/>
              <FormFieldFiles propiedades={campos[8]} control={control} ficheros={handleFicheros}/>
              <FormFieldFileUpload propiedades={campos[7]} control={control} handleFicheros={handleFicheros}/>
          </Grid>

            <FormularioTablaHojaDePedidos listadotabla= {handleListadoTabla}/>
                        
          </form>
          </FormProvider>
          <TablaCompra listadoCompra={listadoCompra} ListadoCompraFinal={handleRemoveElement} />
          {openAlert && 
          <AlertaMensaje 
            mensaje={"¡Orden de compra guardada correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          } 
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
        </>
    )
};

export default FormularioOrdenDeCompra;