import React, { useState, useEffect } from "react"

import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from '@material-ui/core/styles';

import FormularioExportacionGeneral from "./FormulariExportacionGeneral";
import TablasCuentasVentas from "./TablasCuentasVentas"
import TablaIgic from "./TablaIgic";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },  
}));

const ConfiguracionExportacion = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true); 

    useEffect(() => {   
        const fetchData = async () => {
            setIsLoading(true)           
            /*
            const resFacturaUnica = await axios(
                `${process.env.REACT_APP_API_URL}facturasunicas/${id}`
            );                
            setFacturaUnica(resFacturaUnica.data)
            */          
            setIsLoading(false);
        };    
        fetchData();     
    },[]);

    return (
    <>    
    {!isLoading && (
        <>
        <div className={classes.root}>
            <FormularioExportacionGeneral />
            <TablasCuentasVentas />
            <TablaIgic />
        </div>
        </>
    )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
    </>
    )
}

export default ConfiguracionExportacion;