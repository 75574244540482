import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

const FormFieldSelect = ({ propiedades, control }) => {  
    const { modo } = useParams();
    const campoId = typeof propiedades.campoId !== 'undefined' ? propiedades.campoId : 'Id'
    const campoEtiqueta = typeof propiedades.campoEtiqueta !== 'undefined' ? propiedades.campoEtiqueta : 'Etiqueta'
    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )
   
    return (               
    <Grid item xs={propiedades.xs} className={propiedades.classes} >  
    <Controller                        
        as={TextField}   
        control={control}
        name={propiedades.name}
        onChange={() => console.log("hellow")}
        id={propiedades.name}
        select
        label={propiedades.label}
        required = {propiedades.required}
        disabled={deshabilitado} //{modo==="R" || propiedades.disabled ?true:false}
        variant="outlined"
        size="small"
        fullWidth
        defaultValue={propiedades.value}  
        SelectProps={{
            multiple: propiedades.multiple,            
        }}
    >
        {propiedades.opciones && propiedades.opciones.map((option) => (
            <MenuItem key={option[campoId]} value={option[campoId]}>
            {option[campoEtiqueta]}
        </MenuItem>
        ))}
    </Controller> 
    </Grid>       
    )
}
export default FormFieldSelect;