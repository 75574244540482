import React from "react";
import { useParams } from "react-router-dom";
import CuerpoTabla from "../../../../components/Common/CuerpoTabla";



const ConfigTabla =  () => {

    let {nombre_tabla} = useParams();
    
    let url = `dicconfigs/${nombre_tabla}`;
    
   

  const BOTONERA_ACCIONES = [
    { etiqueta: "Mostrar Tabla ", url: "#", activo: true },
    {
      etiqueta: "Editar Tabla",
      url:  `/config/tablas/${nombre_tabla}/E`,
      activo: true,
    }, 
  ];  

  const COLUMNS_TABLASEDIT = [
      {
      name: "Id",
      label: "Id",
      options: {
       filter: false,
       sort: true,
       display: true,
      }
     },
     {
      name: "Etiqueta",
      label: "Etiqueta",
      options: {
       filter: false,
       sort: true,
       display: true,
      }
     }
  ]

  return (
    <CuerpoTabla
      tituloSearchBar = "Configuracion"
      claseBGSearchBar = "bg-comercial"
      titulo="Tablas editables" 
      columnas = {COLUMNS_TABLASEDIT}
      botonera = {BOTONERA_ACCIONES}
      urlDatos = {url}
      urlDoubleClick ={`${nombre_tabla}/E`}
      columnId="Numero"
      dobleclick = {false}
    />
  )  
}

export default ConfigTabla;