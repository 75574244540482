const COLUMNS_OPORTUNIDADES = [
  {
    name: "Id",
    label: "Id",
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
     }
  },
    {
     name: "Estado",
     label: "Estado",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "Nombre",
      label: "Nombre",
      options: {
       filter: true,
       sort: true,
      }
     },
     /*
    {
     name: "Sector",
     label: "Sector",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "Areas",
     label: "Áreas",
     options: {
      filter: true,
      sort: true,
     }
    },
    */
    {
     name: "Cuentas",
     label: "Cuentas",
     options: {
      filter: true,
      sort: true,
     }   
    },
    {
      name: "Contactos",
      label: "Contactos",
      options: {
       filter: true,
       sort: true,
      }   
    }, 
    {
      name: "Empleados",
      label: "Empleados",
      options: {
       filter: true,
       sort: true,
      }   
    }, 
    {
      name: "Fecha_Cierre",
      label: "Fecha Cierre",
      options: {
       filter: true,
       sort: true,
      }   
    },   
];
export {COLUMNS_OPORTUNIDADES};
