import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress } from '@material-ui/core';
import FormFieldCombo from "./FormFieldCombo";

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 300,
      maxWidth: 300,
      maxHeight: 27,
      borderColor: "#e5e5e5 !important",
      borderWidth: "1px !important",
    },
}));

const DialogImportData = ({
    isOpen, handleCloseDialog, url_datos, titulo, 
    descripcion, tituloSelect, SetDatos, campoId = "Id", 
    campoCodigo="Codigo", getDataFromServer=true,
    url_datos_seleccionados=""
}) => {
    const { control } = useForm(); 
    const classes = useStyles();
    const [maxWidth] = useState("md");
    const [isLoading, setIsLoading] = useState(true);
    const [datos, setDatos] = useState([])

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {      
            console.log(process.env.REACT_APP_API_URL + url_datos)
            const respPedidos = await axios(process.env.REACT_APP_API_URL + url_datos);  
            console.log(respPedidos.data)      
            respPedidos.data.forEach(element => {  
                //console.log(element)        
                if(element.Operativa){
                    element.Operativa = Array.from(new Set(element.Operativa.split(','))).toString();            
                }
                if(element.Servicios){
                    element.Servicios = Array.from(new Set(element.Servicios.split(','))).toString();            
                }
                });            
                setDatos(respPedidos.data);
        };
        fetchData();
        setIsLoading(false); 

        return () => {
            setDatos([])
        }
        
    },[isOpen, url_datos])

    const handleClose = () => {
        handleCloseDialog()
    };

    const handleMaxWidthChange = (valor) => {
        //console.log(valor)
        if (valor){            
            if(getDataFromServer){
                const fetchData = async () => {     
                    //console.log(`${process.env.REACT_APP_API_URL}${url_datos}/${valor[campoId]}`)
                const respCotizacion = await axios(
                    `${process.env.REACT_APP_API_URL}${url_datos}/${valor[campoId]}`
                ); 
                SetDatos(respCotizacion.data)
                if (Object.keys(respCotizacion.data).length > 0) {
                    handleClose()
                }
                }
                fetchData();
            }
            else{
                const fetchData = async () => {     
                    //console.log(`${process.env.REACT_APP_API_URL}${url_datos_seleccionados}${valor[campoId]}`)
                const respCotizacion = await axios(
                    `${process.env.REACT_APP_API_URL}${url_datos_seleccionados}${valor[campoId]}`
                ); 
                //console.log(respCotizacion.data)
                SetDatos(respCotizacion.data)
                if (Object.keys(respCotizacion.data).length > 0) {
                    handleClose()
                }
                }
                fetchData();            
            }
        }
    };      

    const propiedadesCampo = {    
        urlData: url_datos,    
        campoEtiqueta: campoCodigo,
        campoId: campoId,
        required: false,
        name: "codigo",
        disabled: false,
        label: tituloSelect,
        value: "",
        multiple: false,
        xs:12,
    }    

    return (
        <div className="col-12  mt-2 mb-1  ">
            <Dialog                    
                maxWidth={maxWidth}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">{titulo}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    {descripcion}
                </DialogContentText>
                {!isLoading && (
                <>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>                   
                    
                    <FormFieldCombo
                    propiedades = {propiedadesCampo}
                    onChangeValue={handleMaxWidthChange}
                    control = {control}
                    />
                    
                    </FormControl>                        
                </form>
                </>
                )}
                {isLoading && (
                <>
                <div><CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /></div>
                </>
                )}
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Cerrar
                </Button>
                </DialogActions>
            </Dialog>   
        </div>    

    )
}

export default DialogImportData;