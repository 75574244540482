import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {TEXT_LABELS} from "../../../../../configuraciones/conf"
import MUIDataTable from "mui-datatables";
import  {COLUMNS_ACTIVIDADES, CAMPOS_ACTIVIDADES}  from "./ActividadesColumnasConf";
import {COLUMNS_PROYECTOS,CAMPOS_PROYECTOS} from "./FichaOportunidadConf.js";
import AddButtonToolbar from "../../../../../components/Common/AddButtonToolBar";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaOportunidad = ({setCodigo}) => {
    let { id } = useParams();

    const [oportunidad, setOportunidad] = useState([])  
    const [campos, setCampos] = useState([])  
    const [datos, setDatos] = useState([]);
    const [datosAct,setDatosAct] = useState([]); 
    const [open, setOpen] = useState(false) 

    useEffect(() => {        
        const fetchData = async () => {
          const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}oportunidads/${id}`);    
          setOportunidad(respOportunidad.data)  
          setCodigo(respOportunidad.data.Codigo) 
        };
        fetchData();  
    }, [id]);

    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }  

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(oportunidad).length > 0){            
          setCampos([
            {
                classsLabel: "",
                label: "Nombre:",
                value: oportunidad.Nombre,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                campoEtiqueta: 'Razon_Social',
                label: "Cliente(s):",
                value: oportunidad.Cuentas,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                campoEtiqueta: 'Nombre',
                label: "Contacto(s):",
                value: oportunidad.Contactos,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
              },            
            {
                classsLabel: "",
                campoEtiqueta: 'nombre',
                label: "Empleado(s):",
                value: oportunidad.Empleados || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Supervisor:",
                value: oportunidad.Supervisor?.nombre || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Visibilidad:",
                value: oportunidad.Visibilidad?.Etiqueta || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Área de negocio:",
                value: oportunidad.AreaNegocio?.Etiqueta || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fuente de información:",
                value: oportunidad.FuenteInformacion?.Etiqueta || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Fecha de inicio:",
                value: oportunidad.Fecha_Inicio ? convertDate(oportunidad.Fecha_Inicio) : '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha estimada de cierre:",
                value: oportunidad.Fecha_Cierre ? convertDate(oportunidad.Fecha_Cierre) : '',
                classText: classText,
                //link:`tel:${oportunidad.Telefono}`,
                xs:4
            },
            {
                classsLabel: "",
                label: "Previsión de gastos:",
                value: oportunidad.Prevision || 0 + " €", 
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
              },
            {
                classsLabel: "",
                label: "Presupuesto asignado:",
                value: oportunidad.Presupuesto === "1" ? "SÍ" : "NO",
                classText: classText,
                //link:`mailto:${oportunidad.Email}`,
                xs:4
            },
            {
                classsLabel: "",
                label: "Resultado :",
                value: oportunidad.Oportunidad ? oportunidad.Oportunidad === "1" ? "Perdida" : "Ganada" : "",
                classText: classText,
                //link:`mailto:${oportunidad.Email}`,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
              },
            {
                classsLabel: "",
                label: "Descripción:",
                value: oportunidad.Descripcion || '',
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Notas:",
                value: oportunidad.Notas || '',
                classText: classText,
                //link:`${oportunidad.Web}`,
                xs:6
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
              },
            {
                classsLabel: "",
                label: "Archivos adjuntos:",
                value: oportunidad.ficheros,
                classText: classText,
                type: "file",
                xs:7
              },
            
          ])
        }
    },[oportunidad])  

    const accion = (tipo) =>{
        setOpen(true)
        
        const fetchData = async () => {            

            if(tipo==="ACTIVIDAD"){
                const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}oportunidads/listadoactividades/${id}`);    
                setDatosAct(respOportunidad.data)  
                setOpen(false)
            }

            if(tipo==="PROYECTO"){
                const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}oportunidads/listadoproyectos/${id}`);    
                setDatos(respOportunidad.data) 
                setOpen(false) 
            }
        };   

        fetchData(); 
    };

    CAMPOS_PROYECTOS.push({    
        required: false,
        label: "",
        name: "TipoOrigen",
        type: "text",    
        disabled: false, 
        value: 'Fk_Oportunidad',                
        xs:4,
        classes: 'd-none',
        hidden: false,
    })

    CAMPOS_ACTIVIDADES.push({    
        required: false,
        label: "",
        name: "TipoOrigen",
        type: "text",    
        disabled: false, 
        value: 'Fk_Oportunidad',                
        xs:4,
        classes: 'd-none',
        hidden: false,
    })

    const OPTIONS_PROYECTOS = {
        filterType: 'checkbox',
        print: false,
        download: false,
        pagination: false,
        selectableRows: 'single',
        expandableRows: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        elevation:0,
        customToolbar: () => {
          return (
            <AddButtonToolbar 
            title = "Formulario Proyecto"
            urlForm = "proyectos" 
            action = {() => accion("PROYECTO")}
            cerrar={open}
            urlGoto = "" // vacio 
            campos = {CAMPOS_PROYECTOS}
            toolTipTitle = "Añadir Proyecto"
            type = "add"
            value = {id}
            />
          );
        },  
        textLabels: TEXT_LABELS,
        
    };

    const OPTIONS_ACTIVIDADES = {
        filterType: 'checkbox',
        print: false,
        download: false,
        pagination: false,
        selectableRows: 'single',
        expandableRows: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        elevation:0,
    
         customToolbar: () => {
          return (
            <AddButtonToolbar 
            title = "Formulario Actividad"
            urlForm = "actividads" 
            action = {() => accion("ACTIVIDAD")}
            cerrar={open}
            urlGoto = "" // vacio 
            campos = {CAMPOS_ACTIVIDADES}
            toolTipTitle = "Añadir actividad"
            type = "add"
            value = {id}
            />
          );
        },    
        textLabels: TEXT_LABELS,
    };

    //Recoge los datos de los proyectos
    useEffect(() => {        
        const fetchData = async () => {
          const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}oportunidads/listadoproyectos/${id}`);    
          setDatos(respOportunidad.data)  
        };
        fetchData();  
    }, [id]);

    //Recoge los datos de las actividades relacionadas
    useEffect(() => {        
        const fetchData = async () => {
          const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}oportunidads/listadoactividades/${id}`);    
          setDatosAct(respOportunidad.data)  
        };
        fetchData();  
    }, [id]);

    return(
        <>
        <h6 className="texto-comercial" id="title">          
        </h6>
        <FichaEntidad
        campos = {campos}
        urlClick = {`/crm/oportunidades/oportunidad/${id}/W`}
        />

        <div className="mt-4">
            <h6 className="texto-comercial" id="title">
                 Proyectos
            </h6>
            <div className="rounded border border-formulario p1-form">
                <MUIDataTable
                    title={""}
                    className={"mt-1"}
                    data={datos}
                    columns={COLUMNS_PROYECTOS}
                    options={OPTIONS_PROYECTOS}
                />
            </div>
        </div>

        <div className="mt-4">
            <h6 className="texto-comercial" id="title">
                Actividades
            </h6>
            <div className="rounded border border-formulario p1-form">
                <MUIDataTable
                title={""}
                className={"mt-1"}
                data={datosAct}
                columns={COLUMNS_ACTIVIDADES}
                options={OPTIONS_ACTIVIDADES}
                />
            </div>
        </div>
        </>
      )

}
export default FichaOportunidad;