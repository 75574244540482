import React, { useState, useRef } from "react";
import CuerpoTabla from "../../../../../components/Common/CuerpoTabla";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";

import Opciones from '../Ventas/Opciones';
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";
import { COLUMNS_ACTIVIDADES, BOTONERA_ACCIONES } from "./ActividadesConf";
import { useStyles } from "../../../../../styles/styles";

const Actividades = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [abrirDialogo, setAbrirDialogo] = useState(false)

  const user = JSON.parse(localStorage.getItem("user"));
  const urlDatos = "actividads/listadoactividadesempleado/" + user.id

  const entidadEliminar = useRef(-1);
  const classes = useStyles();
  
  const COLUMN_ACTIVIDADES_RESULTADO = 
  {
    name: "Resultado",
    label: "Estado",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Opciones
            value={value}
            index={tableMeta.columnIndex}
            opciones = {["Completada", "Pendiente"]}
            change={event => {
              updateValue(event)
              actualizaValor(event, tableMeta.rowData[0])
            }}
          />
        );
      },
    }
  };

  const actualizaValor = (valor, indice) => {
    const fetchData = async () => {
      setIsLoading(true)
      await axios.get( 
        process.env.REACT_APP_API_URL + "actividads/actualizaestadoactividad/" + indice,
        {params: { estado: valor}}
      );
      setIsLoading(false);
    };
    fetchData();
  }

  const BOTON_ACCION_ELIMINAR =
  { 
    etiqueta: "Eliminar Actividad", 
    function: (e) => eliminarEntidad(e), activo: false 
  }

  const eliminarEntidad = (id) => {
    entidadEliminar.current = id       
    setAbrirDialogo(true)
  }
  
  const handleCerrarDialogo = (valor) => {       
    setAbrirDialogo(false)
    if(valor){    
      const fetchData = async () => {  
        setIsLoading(true)
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'actividads';
        await axios
        .delete(SERVER_URL + "/" + entidadEliminar.current, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },        
        })
        .then((response) => {  
          //console.log(response.data)       
          if(!isNaN(response.data)){ 
            if(response.data === entidadEliminar.current){
              entidadEliminar.current = -1 
              setIsLoading(false)
              //window.location.reload();
            }          
          } else{
            alert(response.data);  
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
          setIsLoading(false)
        });  
      }
      fetchData();
    }      
  }

  return (
  <>
  { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la actividad?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
  {!isLoading && (
    <CuerpoTabla
      tituloSearchBar = "CRM"
      claseBGSearchBar = "bg-comercial"
      titulo="Actividades"
      columnas = {[...COLUMNS_ACTIVIDADES, COLUMN_ACTIVIDADES_RESULTADO]}
      botonera = {[...BOTONERA_ACCIONES, BOTON_ACCION_ELIMINAR]}
      urlDatos ={urlDatos}
      urlDoubleClick = "/crm/actividades/actividad/"
      columnId="Id"
      imagenCabecera={"4-1-3-actividades.png"}
    />
  )}
  {isLoading && 
    <div className={classes.linear}>
      <LinearProgress />
    </div>
  }
  </>
  )  
};

export default Actividades;