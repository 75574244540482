import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import MenuizqComercial from "./MenuizqComercial";
import MenuizqProduccion from "./MenuizqProduccion";
import MenuizqGestion from "./MenuizqGestion";
import MenuizqDicConf from "./MenuizqDicConf";

class Menuizq extends Component {
  render() {
    const { isAuthUser } = this.props.reducerApi;
    const estiloButton = { padding: "7px", maxHeight: "90vh", overflow: "auto" };

    if (isAuthUser) {
      return (
        <div className="col-2 col-menu-izq p-0 pl-2 pr-2">
          {/*
          <Link to={isAuthUser ? "/CMO/CuadroDeMandos" : "/"} className="">
            <button type="button" className="btn btn-info mb-2 btn-block">
              Cuadro de mandos
            </button>
          </Link>
          */}
          <div className="bg-cuerpo" style={estiloButton}>
            <div className="accordion" id="accordionMenu">
              <MenuizqComercial />
              <MenuizqProduccion />
              <MenuizqGestion />
              {/*<MenuizqDicConf/>*/}
            </div>
          </div>        
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect(({ reducerApi }) => ({ reducerApi }))(Menuizq);
