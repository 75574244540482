import React, { useState, useEffect } from "react"
import axios from "axios";

import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@mui/x-data-grid';
import SaveIcon from '@material-ui/icons/Save';

import AlertaMensaje from "../../../../../components/Common/AlertaMensaje";

const TablaIgic = () => {
    const [igics, setIgics] = useState([]) 
    const [openAlert,setOpenAlert] = useState({
        open : false,
        tipoMensaje : "success",
        mensaje : ""
    });  

    const columns = [
        { field: 'id', headerName: 'Id', width: 90, hide: true },
        { field: 'etiqueta', headerName: 'Etiqueta', width: 250 },
        { field: 'valor', headerName: 'Cuota', width: 120, type: "number", editable: false },
        { field: 're', headerName: 'R.E.', width: 120, type: "number", editable: false },
        { field: 'claveop', headerName: 'Clave OP', width: 130, editable: false },
        { field: 'posicioncontanet', headerName: 'Posición Contanet', type: "number", width: 200, editable: true },
        
        { field: 'Guardar', headerName: '', width: 50, 
            renderCell: (params) => {                
                return <SaveIcon onClick={() => handleSaveElement(params.row)}/>                               
            },             
        },
        
    ];

    const fetchData = async () => {
        //let user = JSON.parse(localStorage.getItem("user"));            
        const respData = await axios( process.env.REACT_APP_API_URL + "dic/dicigics");
        if (respData.data) {              
            setIgics(respData.data); 
        }
    };

    useEffect(() => {    
        fetchData();
        return () => {setIgics([])}
    }, [])  

    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {            
            if(field === 'posicioncontanet'){
                const updatedRows = igics.map((row) => {
                    if (row.id === id) {  
                      return { ...row,  posicioncontanet: value};
                    }
                    return row;
                  });                 
                setIgics(updatedRows)
            }
        }, [igics]
    )

    const handleSaveElement = (element) => {        
        
        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${"dic/dicigics"}/${element.id}`;
        //console.log(SERVER_URL)
        let datos = {}
        datos.idIgic = element.id
        datos.posicioncontanet = element.posicioncontanet 
        
        axios
          .put(SERVER_URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {        
            
            if(!isNaN(response.data)){              
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "IGIC actualizado correctamente"
              })    
              
            }else{

              setOpenAlert({
                open: true,
                tipoMensaje: "error",
                mensaje: "Ha ocurrido un error al actualizar la Categoría"
              })
            }            
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });
    }

    const handleCloseAlert = (event, reason)=>{
        if(reason === "clickaway"){
          return
        }
        setOpenAlert({...openAlert,open:false});
    }

    return (
        <div className="mt-2">
        <Grid className="borderFormulario" container spacing={1}>
            <Grid item xs={12} className="" style={{height: 250}}>
            <DataGrid 
                //disableColumnMenu={true}
                rows={igics} 
                columns={columns}    
                //autoHeight = {true}   
                density = {'compact'}
                //pageSize={100} 
                //checkboxSelection = {false}
                //hideFooter = {false}
                hideFooterPagination = {true}
                //hideFooterRowCount = {false}
                //hideFooterSelectedRowCount = {true}
                onCellEditCommit={handleCellEditCommit}
                //onRowClick={handleRowClick}
                />
        {
        openAlert.open && (
            <AlertaMensaje 
                mensaje={openAlert.mensaje} 
                isOpen={openAlert.open} 
                tipoMensaje={openAlert.tipoMensaje}
                cerrar={handleCloseAlert}
            />
                  
              )
            }
            </Grid>
        </Grid>
    </div>
    )
}

export default TablaIgic;