import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import  {COLUMNS_ACTIVIDADES, CAMPOS_ACTIVIDADES}  from "./ActividadesColumnasConf";
import {TEXT_LABELS} from "../../../../../configuraciones/conf";
import AddButtonToolbar from "../../../../../components/Common/AddButtonToolBar";
import MUIDataTable from "mui-datatables";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'


const FichaProyecto = ({setCodigo}) => {
    let { id } = useParams();
    const [proyecto, setProyecto] = useState([])  
    const [campos, setCampos] = useState([])  
    const [datos, setDatos] = useState([])
    const [open, setOpen] = useState(false) 

    useEffect(() => {        
        const fetchData = async () => {
          const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}proyectos/${id}`);    
          setProyecto(respOportunidad.data)
          setCodigo(respOportunidad.data.Codigo)      
        };
        fetchData();  
    }, [id]);

    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }  

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(proyecto).length > 0){   
          const tipoOtro = proyecto.TipoOtros ? proyecto.TipoOtros : ""           
          setCampos([
            {
                classsLabel: "",
                label: "Tipo:",
                value: proyecto.Tipo.Etiqueta + " " + tipoOtro,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Nombre:",
                value: proyecto.Nombre,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                campoEtiqueta: 'Razon_Social',
                label: "Cliente(s):",
                value: proyecto.Cuentas,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            }, 
            {
                classsLabel: "",
                campoEtiqueta: 'Nombre',
                label: "Contacto(s):",
                value: proyecto.Contactos,
                classText: classText,
                xs:4
            },                       
            {
                classsLabel: "",
                campoEtiqueta: 'nombre',
                label: "Empleado(s):",
                value: proyecto.Empleados,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Supervisor:",
                value: proyecto.Supervisor?.nombre || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Oportunidad:",
                value: proyecto.Oportunidad?.Codigo || "",
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                campoEtiqueta: 'Codigo',
                label: "Actividad(es):",
                value: proyecto.Actividades || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Visibilidad:",
                value: proyecto.Visibilidad?.Etiqueta || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },            
            {
                classsLabel: "",
                label: "Fecha de inicio:",
                value: proyecto.Fecha_Inicio ? convertDate(proyecto.Fecha_Inicio) : '',
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Fecha estimada de cierre:",
                value: proyecto.Fecha_Cierre ? convertDate(proyecto.Fecha_Cierre) : '',
                classText: classText,
                //link:`tel:${proyecto.Telefono}`,
                xs:3
            },
            {
                classsLabel: "",
                label: "Estado:",
                value: proyecto.Estado?.Etiqueta || '', 
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Resultado:",
                value: proyecto.Resultado?.Etiqueta || "", 
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },            
            {
                classsLabel: "",
                label: "Previsión de gastos:",
                value: proyecto.Prevision || 0 + " €", 
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Presupuesto asignado:",
                value: !!proyecto.Presupuesto ? "SÍ" : "NO",
                classText: classText,                
                xs:3
            },
            {
                classsLabel: "",
                label: "Proyecto recurrente:",
                value: !!proyecto.Recurrente ? "SÍ" : "NO",
                classText: classText,                
                xs:3
            },  
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Descripción:",
                value: proyecto.Descripcion || '',
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Notas:",
                value: proyecto.Notas || '',
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Archivos adjuntos:",
                value: proyecto.ficheros,
                classText: classText,
                type: "file",
                xs:7
            },            
          ])
        }
    },[proyecto]) 
    
    const handleClose = () => {
        setOpen(false)
    };
    const accion = () =>{
        setOpen(true)
        const fetchData = async () => {            
            const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}proyectos/listadoactividades/${id}`);    
            setDatos(respOportunidad.data)                       
        };    
        fetchData(); 
    };

    CAMPOS_ACTIVIDADES.push({    
        required: false,
        label: "",
        name: "TipoOrigen",
        type: "text",    
        disabled: false, 
        value: 'Fk_Proyecto',                
        xs:4,
        classes: 'd-none',
        hidden: false,
    })

    const OPTIONS_ACTIVIDADES = {
        filterType: 'checkbox',
        print: false,
        download: false,
        pagination: false,
        selectableRows: 'single',
        expandableRows: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        elevation:0,
    
         customToolbar: () => {
          return (
            <AddButtonToolbar 
            title = "Formulario Actividades"
            urlForm = "actividads" 
            action = {accion}
            cerrar={open}
            urlGoto = "" // vacio 
            campos = {CAMPOS_ACTIVIDADES}
            toolTipTitle = "Añadir actividad"
            type = "add"
            value = {id}
            />
          );
        },    
        textLabels: TEXT_LABELS,
    };

    useEffect(() => {        
        const fetchData = async () => {
          const respOportunidad = await axios(`${process.env.REACT_APP_API_URL}proyectos/listadoactividades/${id}`);    
          setDatos(respOportunidad.data)
        };
        fetchData();  
    }, [id]);    
    
    return(
        <>
        <h6 className="texto-comercial" id="title">          
        </h6>
        <FichaEntidad
        campos = {campos}
        urlClick = {`/crm/proyectos/proyecto/${id}/W`}
        />
        <div className="mt-4">
            <h6 className="texto-comercial" id="title">
                Actividades
            </h6>
            <div className="rounded border border-formulario p1-form">
                <MUIDataTable
                  title={""}
                  className={"mt-1"}
                  data={datos}
                  columns={COLUMNS_ACTIVIDADES}
                  options={OPTIONS_ACTIVIDADES}
                />
            </div>
        </div>
        </>
      )

}
export default FichaProyecto;