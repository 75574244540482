import React, { useEffect } from "react";
import { connect } from "react-redux";
import Agenda from './Agenda';
import {useFacturaVenta} from '../../containers/views/Main/Gestion/Facturas/facturaventa-context'
import Grid from '@material-ui/core/Grid';

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

function strip(number) {    
    return (parseFloat(number).toPrecision(12));
}

function calculaTotales(props) {
   
    if (props.length === 0) return null; 
    let igic = 0  
    let irpf = 0  
    let baseImponibleTotal = 0
    let TotalFactura = 0;   

    if(props.length > 0){
        props.forEach(element => { 
            //console.log(element)
            
            const elDto = Number.parseFloat(element.descuento, 10)/100;
            const elCantidad = Number.parseFloat(element.cantidad, 10);
            const elPrecioUd = Number.parseFloat(element.precioUnidad, 10);            
            const baseImponible =  elCantidad*elPrecioUd - (elCantidad*elPrecioUd*elDto);
            const elImporteExtraCoste = element.importeExtraCoste ? Number.parseFloat(element.importeExtraCoste, 10) : 0;

            const elIgic = (Number.parseFloat(element.baseImponible, 10) + elImporteExtraCoste) * ( Number.parseFloat(element.Igic, 10)/100)
            const elIrpf = (Number.parseFloat(element.baseImponible, 10) + elImporteExtraCoste) * ( Number.parseFloat(element.Irpf, 10)/100)
            
            baseImponibleTotal +=baseImponible
               
            igic += elIgic 
            irpf += elIrpf 
            TotalFactura +=  baseImponible + parseFloat(baseImponible*elIgic) - parseFloat(baseImponible*elIrpf)            
        })        
    }

    return {       
        baseImponibleTotal: roundToTwo(baseImponibleTotal),
        igic: roundToTwo(igic),
        irpf: roundToTwo(irpf),
        TotalFactura:  roundToTwo(TotalFactura),
    }
}

function extraCosteComponente (option, index){
    const claseSpanL = "p-0 font-weight-bold h6 text-left w-100"
    const claseSpanR = "p-0 font-weight-bold h6 text-right w-100"
    
    if(option && option !== 0)
    {
        const totalExtraCostes = Math.round((option + Number.EPSILON) * 100) / 100;
        return (
        <React.Fragment key={index}>
            <Grid className="text-left" item xs={7} >
                <span className={claseSpanL}>Extracostes J{index+3}:</span>
            </Grid> 
            <Grid className="text-right" item xs={5} >                
                <span className={claseSpanR}>{totalExtraCostes.toFixed( 2 )} €</span>
            </Grid> 
        </React.Fragment>  
        )
    }else return null

}

function CustomFooterStatusComponent({calculos}) {     
    let igic = calculos ? calculos.igic : 0   
    let irpf = calculos ? calculos.irpf : 0
    let baseImponibleTotal = calculos ? calculos.baseImponible : 0
    let TotalFactura = calculos ? calculos.total : 0
    
    const extraCostes = calculos ? [
        calculos.extraCosteJ3, calculos.extraCosteJ4, calculos.extraCosteJ5, calculos.extraCosteJ6, 
        calculos.extraCosteJ7, calculos.extraCosteJ8, calculos.extraCosteJ9, calculos.extraCosteJ10, 
    ] : [0,0,0,0,0,0,0, 0]

    const claseSpanL = "p-0 font-weight-bold h6 text-left w-100"
    const claseSpanR = "p-0 font-weight-bold h6 text-right w-100"
    const claseGrid = "badge badge-info mt-1 mb-1"
    console.log(TotalFactura)
    return (
        <Grid container className={claseGrid} spacing={2} direction="row" justify="center" alignItems="center">  
            <Grid className="text-left" item xs={7} >
                <span className={claseSpanL}>Base imponible:</span>
            </Grid> 
            <Grid className="text-right" item xs={5} >
                <span className={claseSpanR}>{baseImponibleTotal.toFixed( 2 )} €</span>
            </Grid> 
            
            {extraCostes.map((option, index) => (                
                extraCosteComponente(option, index)
            ))}
                      
            <Grid className="text-left" item xs={7} >
                <span className={claseSpanL}>IGIC:</span>
            </Grid> 
            <Grid className="text-right" item xs={5} >
                <span className={claseSpanR}>{roundToTwo(igic).toFixed( 2 )} €</span>
            </Grid>    
            <Grid className="text-left" item xs={7} >
                <span className={claseSpanL}>IRPF:</span>
            </Grid> 
            <Grid className="text-right" item xs={5} >
                <span className={claseSpanR}>{roundToTwo(irpf).toFixed( 2 )} €</span>
            </Grid>           
            <Grid className="text-left" item xs={7} >
                <span className={claseSpanL}>Total:</span>
            </Grid> 
            <Grid className="text-right" item xs={5} >
                <span className={claseSpanR}>{roundToTwo(TotalFactura).toFixed(2)} €</span>
            </Grid>  
        </Grid>
    );
} 

function calculaProductosExtracoste (listadoProductosFacturaVenta)
{
    let totalExtracostes = {
        "extraCoste3": 0,  "extraCoste4": 0,  "extraCoste5": 0,  "extraCoste6": 0, 
        "extraCoste7": 0,  "extraCoste8": 0,  "extraCoste9": 0,  "extraCoste10": 0, 
    }
    
    //const totalExtraCostes = Math.round((option + Number.EPSILON) * 100) / 100;
    listadoProductosFacturaVenta.forEach(producto => {
        //console.log(producto)
        let jornadaSplit = []
        if(producto.jornada)
            jornadaSplit = producto.jornada.split(" ")
        let jornada = "1"
        if(jornadaSplit.length > 0)
            jornada = jornadaSplit[0].substring(1);
        
    
        //console.log(producto["importeExtraCoste"])
        //const totalExtraCostes1 = Math.round((parseFloat(producto["importeExtraCoste"]) + Number.EPSILON) * 100) / 100;
        //console.log(producto["importeExtraCoste"])
        //console.log(totalExtraCostes1)
        let resultado = Math.round((parseFloat(producto["importeExtraCoste"]) + Number.EPSILON) * 100) / 100;
        //console.log(resultado)
        totalExtracostes["extraCoste" + jornada] += resultado
        //console.log(totalExtracostes)
    })
    return totalExtracostes
}
export { CustomFooterStatusComponent };

const MenuDerFacturaVenta = (props) => {
    
    const {datosFactura, listadoProductosFacturaVenta, totalFacturaVenta, setTotalFacturaVenta, operacion} = useFacturaVenta();
    const { isAuthUser } = props.reducerApi;  
    const calculoTotal= calculaTotales(listadoProductosFacturaVenta) 
    //console.log(calculoTotal)
    useEffect(() => {
        console.log("[]")
        if(calculoTotal){            
            let calculaProductosConExtracostes = calculaProductosExtracoste (listadoProductosFacturaVenta)
            let totalExtraCostes = calculaProductosConExtracostes ? 
            calculaProductosConExtracostes.extraCoste3 + calculaProductosConExtracostes.extraCoste4 +
            calculaProductosConExtracostes.extraCoste5 + calculaProductosConExtracostes.extraCoste6 +
            calculaProductosConExtracostes.extraCoste7 + calculaProductosConExtracostes.extraCoste8 +
            calculaProductosConExtracostes.extraCoste9 + calculaProductosConExtracostes.extraCoste10
            : 0 
            if(Object.keys(datosFactura).length > 0){

                totalExtraCostes = parseFloat(datosFactura.extraCosteJ3) + parseFloat(datosFactura.extraCosteJ4) + parseFloat(datosFactura.extraCosteJ5) + 
                parseFloat(datosFactura.extraCosteJ6) + parseFloat(datosFactura.extraCosteJ7) + parseFloat(datosFactura.extraCosteJ8) + parseFloat(datosFactura.extraCosteJ9)
                
                calculaProductosConExtracostes.extraCoste3 = parseFloat(datosFactura.extraCosteJ3)
                calculaProductosConExtracostes.extraCoste4 = parseFloat(datosFactura.extraCosteJ4)
                calculaProductosConExtracostes.extraCoste5 = parseFloat(datosFactura.extraCosteJ5)
                calculaProductosConExtracostes.extraCoste6 = parseFloat(datosFactura.extraCosteJ6)
                calculaProductosConExtracostes.extraCoste7 = parseFloat(datosFactura.extraCosteJ7)
                calculaProductosConExtracostes.extraCoste8 = parseFloat(datosFactura.extraCosteJ8)
                calculaProductosConExtracostes.extraCoste9 = parseFloat(datosFactura.extraCosteJ9)
                calculaProductosConExtracostes.extraCoste10 = parseFloat(datosFactura.extraCosteJ10)
            }

            setTotalFacturaVenta({
                baseImponible: calculoTotal.baseImponibleTotal, 
                igic: calculoTotal.igic, 
                irpf: calculoTotal.irpf,
                extraCostes:  totalExtraCostes,
                total: (totalExtraCostes + calculoTotal.baseImponibleTotal) - calculoTotal.irpf + calculoTotal.igic,
                extraCosteJ3: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste3 : 0,
                extraCosteJ4: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste4 : 0,
                extraCosteJ5: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste5 : 0,
                extraCosteJ6: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste6 : 0,
                extraCosteJ7: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste7 : 0,
                extraCosteJ8: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste8 : 0,
                extraCosteJ9: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste9 : 0,
                extraCosteJ10: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste10 : 0,
            })
        }

    }, [])

    useEffect(() => {  
        console.log("[listadoProductosFacturaVenta]")
        if(calculoTotal){
            const calculaProductosConExtracostes = calculaProductosExtracoste (listadoProductosFacturaVenta)
            let totalExtraCostes = calculaProductosConExtracostes ? 
            calculaProductosConExtracostes.extraCoste3 + calculaProductosConExtracostes.extraCoste4 +
            calculaProductosConExtracostes.extraCoste5 + calculaProductosConExtracostes.extraCoste6 +
            calculaProductosConExtracostes.extraCoste7 + calculaProductosConExtracostes.extraCoste8 +
            calculaProductosConExtracostes.extraCoste9 + calculaProductosConExtracostes.extraCoste10
            : 0 
            
            setTotalFacturaVenta({
                baseImponible: calculoTotal.baseImponibleTotal, 
                igic: calculoTotal.igic, 
                irpf: calculoTotal.irpf,
                extraCostes:  totalExtraCostes,
                total: (roundToTwo(totalExtraCostes) + calculoTotal.baseImponibleTotal) - calculoTotal.irpf + calculoTotal.igic,
                extraCosteJ3: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste3 : 0,
                extraCosteJ4: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste4 : 0,
                extraCosteJ5: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste5 : 0,
                extraCosteJ6: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste6 : 0,
                extraCosteJ7: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste7 : 0,
                extraCosteJ8: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste8 : 0,
                extraCosteJ9: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste9 : 0,
                extraCosteJ10: calculaProductosConExtracostes ? calculaProductosConExtracostes.extraCoste10 : 0,
            })
        }else{
            setTotalFacturaVenta({
                baseImponible:0, 
                igic: 0, 
                irpf: 0,
                extraCostes:  0,
                total: 0                
            })
        } 
        return () => {
            
        }
    }, [listadoProductosFacturaVenta]) 

    console.log(totalFacturaVenta)
    //console.log(totalFacturaVenta.extraCostes)
    //console.log(strip(totalFacturaVenta.extraCostes))
    if (isAuthUser) {
        return (
          <>
          <div className="col col-menu-der p-0 pl-2 pr-2">
          <Agenda />
            <div className="col p-0 pl-0 pr-0 col-menu-der-recursos">     
                {/*COSTES*/}
                <div className="bg-cuerpo p-10px mt-2">
                    <div className="bg-white p-10px">
                        <Grid item xs={12} className="" /*style={{height: 40}}*/>
                            <CustomFooterStatusComponent calculos={totalFacturaVenta} />
                        </Grid>   
                    </div>
                </div>
            </div>
          </div>
          </>
          )
        } else {
            return  null
        }
}

export default connect(({ reducerApi }) => ({ reducerApi }))(MenuDerFacturaVenta);
