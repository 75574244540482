import React, { useState } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import FormularioGastosVariosCPE from "./FormularioGastosVariosCPE";
import FormularioTarifasCPE from "./FormularioTarifasCPE";
import {columnsEmpleadosCPE, botoneraCensoCPE} from './GestionCPEConf';

const GestionCPE = (props) => {    
    const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOperacionCPE')) || 0);
    const [filaSeleccionada, setFilaSeleccionada] = useState(-1); 
    
    const marcarFila = (fila) => {
        setFilaSeleccionada(fila);
    }

    const verTabla = (idOperacion) => {   
        localStorage.setItem('idOperacionCPE', idOperacion);  
        setIdOperacion(idOperacion)
    }  

    let columnasCuerpoTabla = columnsEmpleadosCPE;
    let urlDatosCuerpoTabla = "censocpes";
    let urlDoubleClickCuerpoTabla = "/rrhh/gestioncpe/personalcpe/";
    let columnIdCuerpoTabla = "Id";
    let botonaccionCuerpoTabla = botoneraCensoCPE;  

    if(idOperacion === 1){
      columnasCuerpoTabla = columnsEmpleadosCPE;
      urlDatosCuerpoTabla = "censocpes";
      urlDoubleClickCuerpoTabla = "/rrhh/gestioncpe/personalcpe/";
      columnIdCuerpoTabla = "Id";
      botonaccionCuerpoTabla = [];  
    }

    if(idOperacion === 2){
      botonaccionCuerpoTabla = []
    }



    return (           
        <div className="col-8 col-main-home ">            
          <SearchBar
            titulo="RRHH"
            claseCabecera = "bg-gestion"
            buscar={false}
            imagenCabecera={"6-3-2-rrhh.png"}
          />   
              
          <div className="bg-cuerpo p-10px">
            <div className="bg-white p-10px">
              <div className="p-1 bg-white mb-3">
                    <h6 className="texto-comercial float-left" id="title">
                      Gestion CPE
                    </h6>
                  </div>
               <hr className="m-0 bg-comercial mb-1" />
              <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">             
                <Pasos pasos={['CENSO', 'TARIFAS', 'VARIOS']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
                {idOperacion === 0 && (
                  <CuerpoTablaPiezas
                      columnas = {columnasCuerpoTabla}
                      urlDatos = {urlDatosCuerpoTabla}
                      urlDoubleClick = {urlDoubleClickCuerpoTabla}
                      columnId = {columnIdCuerpoTabla}
                      getFilaSeleccionada={marcarFila}
                  /> 
                )}                

                {idOperacion === 1 && (
                  <FormularioTarifasCPE />
                )}
                
                {idOperacion === 2 && (
                  <FormularioGastosVariosCPE />
                )}
              </div>
            </div>
      
            <div className="bg-cuerpo">
                <div className="bg-white">
                    <TableAcciones                
                      filaSeleccionada={filaSeleccionada}                
                      botonaccion={botonaccionCuerpoTabla}
                    />            
                </div>
            </div>
      
          </div>
                
        </div>   
          
        );
}
export default GestionCPE;