import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller } from "react-hook-form";
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

const FormFieldCheckBox = ({ propiedades, control }) => { 
  
  const [state, setState] = useState(!!propiedades.value);  

  const handleChange = (checked) => {    
    setState( checked );
  };  

  return (    
      <Grid item xs={propiedades.xs} className={propiedades.classes} >    
        <FormControlLabel   
          control={
            <Controller
              control={control}
              name={propiedades.name}
              defaultValue={state}
              render={(
                { onChange, onBlur, value={state}, name },          
              ) => (
                <Checkbox
                  onBlur={onBlur}
                  onChange={(e) => {
                    handleChange(e.target.checked)
                    onChange(e.target.checked)
                    
                  }}
                  checked={state}   
                />
              )}            
            />          
          }
          label={propiedades.label}
          labelPlacement="end"
        />     
      </Grid> 
  );
}

export default FormFieldCheckBox;