import axios from "axios";

export const CUENTAS_GET = "CUENTAS_GET";
export const CUENTAS_ERROR = "CUENTAS_ERROR";
export const CUENTAS_SUCCESS = "CUENTAS_SUCCESS";


const SERVER_URL = process.env.REACT_APP_API_URL + "currencies";

export function cuentasGET() {
  return function action(dispatch) {
    dispatch({ type: CUENTAS_GET });

    const request = axios({
      method: "GET",
      url: `${SERVER_URL}`,
      headers: [],
    });

    return request.then(
      (response) => dispatch(getCuentasSucees(response)),
      (err) => dispatch(getCuentasError(err))
    );
  };
}

export function getCuentasSucees(cuentas) {
  return {
    type: CUENTAS_SUCCESS,
    payload: cuentas,
  };
}

export function getCuentasError(error) {
  return {
    type: CUENTAS_ERROR,
    payload: error,
  };
}
