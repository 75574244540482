import axios from "axios";
import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useParams } from "react-router-dom";
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import { Icon, Label } from 'semantic-ui-react'
import ReactHtmlParser from 'react-html-parser'; 
import DialogForm from "./DialogForm";
/*
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);
*/

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function FormFieldCombo({propiedades, onChangeValue=null, valor=null, control=null}) {

  const [open, setOpen] = useState(false);
  const [openForm,setOpenForm] = useState(false);
  const [add,setAdd] = useState(false);    
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [selectedCategory, setSelectedCategory] = useState(propiedades.value || (propiedades.multiple === true ? [] : ''));
  const { modo } = useParams();

  useEffect(() => {   
    let active = true;
    if (!loading) {
      //return undefined;
    }
    (async () => {      
      const respClientes = await axios(process.env.REACT_APP_API_URL + propiedades.urlData);      
      if (active) {
          if(respClientes.data.length > 0 ){            
            setOptions(Object.keys(respClientes.data).map((key) => respClientes.data[key]))
          } else {
            const etiquetas = propiedades.campoEtiqueta.split(',')
            const etiqueta = etiquetas[0];
            const sinDatos = [{ 
              Id: -1,
              [etiqueta]: "Sin datos"
            }]
            setOptions(sinDatos)
          }
      }
    })();
    return () => {
      active = false;
    };
  }, [loading, propiedades.urlData, propiedades.campoEtiqueta]);

  useEffect(() => {
    if (!open) {      
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {    
    setSelectedCategory(propiedades.value)
  }, [propiedades.value])

  const handleClose = () => {

    // let camposAux = [...campos];

    // camposAux[0].value = "NOTA DE USUARIO"
    // camposAux[1].value = []
    // camposAux[2].value = ""
    // camposAux[3].value = ""
    // camposAux[4].value = ""
    // camposAux[5].value = ""

    setOpenForm(false)

  };

  const accion = () =>{
    handleClose()
  };

  const renderInput = (params) => { 
    const multipleRequired = Array.isArray(selectedCategory) && selectedCategory.length === 0
    const singleRequired = (selectedCategory === "" || selectedCategory === null) && propiedades.required;
    const isRequired = (singleRequired || multipleRequired) && propiedades.required

    return (
    <TextField  
      {...params}
      label={propiedades.label}
      //disabled={modo==="R"  || propiedades.disabled===true ?true:false}
      required={isRequired}
      variant="outlined"      
      size="small"      
      fullWidth      
      InputProps={{
        ...params.InputProps,
        endAdornment: (
        <>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {add ? <Button onClick={()=>setOpenForm(true)} style={{marginRight: 30}}>+</Button>: null}
          {params.InputProps.endAdornment}
        </>
      ),
    }}             
    />
    )
  }
  
  const handleChange = (v) => {  
    
    if(onChangeValue)
      onChangeValue(v)
      
    if(propiedades.onChangeValue)
      propiedades.onChangeValue(v)     
      
    setSelectedCategory(v)
  }  

  const getOptionLabel = (option) => {   
    let etiqueta = "";
    if(option){            
      const etiquetas = propiedades.campoEtiqueta.split(',')
      etiquetas.forEach(element => {
        etiqueta += option[element] + " " 
      });      
    }      
    return etiqueta  
  }   

  const renderOption = (option) => {    
    let etiqueta = "";
    const etiquetas = propiedades.campoEtiqueta.split(',')
      etiquetas.forEach(element => {
        etiqueta += option[element] + " " 
      });  
    
    let descripcion = false;
    let textoDescripcion = "";
    if(typeof(option["DescripcionInfo"]) !== 'undefined'){
        descripcion = option["DescripcionInfo"].length > 0
        if (descripcion){
          textoDescripcion = option["DescripcionInfo"]
        }
    }

    return(
      <React.Fragment>        
        {etiqueta}
        {descripcion && 
        <HtmlTooltip
          title={
            <React.Fragment>            
              <Typography color="inherit">                           
                <div> { ReactHtmlParser (textoDescripcion) } </div>
                </Typography>
            </React.Fragment>
          }
        >
          <IconButton aria-label="delete">
            <InfoIcon />
           </IconButton>   
        </HtmlTooltip>
      }       
      </React.Fragment>
    )
  } 

  const deshabilitado = (
    typeof(propiedades.disabled) === "undefined" ? 
    modo==="R" ?  true : false : propiedades.disabled
  )

  const getOptionSelected = (option) => {      
    
    const campoId = propiedades.campoId ? propiedades.campoId : "Id"    

    if(!selectedCategory) return true; 
    
    if(propiedades.multiple === true){
      const estaArray = selectedCategory.filter(selected => selected[campoId] == option[campoId])
      
      return estaArray.length > 0
    }   

    
    
    return option[campoId] == selectedCategory[campoId]
  }

  const getOptionDisabled = (option) => {
    const campoDesactivar = propiedades.campoDesactivar ? propiedades.campoDesactivar : "Desactivar"
    
    return parseInt(option[campoDesactivar]) === 0
  }

  //console.log(propiedades.name, selectedCategory)
  return (    
    <Hidden  xlDown = {propiedades.hidden ? true : false } >
    <Grid item xs={propiedades.xs} className={propiedades.classes} >
    <Controller    
    name={propiedades.name}    
    control={control}    
    defaultValue={ selectedCategory ?? ''}   
    render={({onChange, ...props}) => (             
          <Autocomplete           
            multiple = {propiedades.multiple}  
            id={'_'+propiedades.name}
            getOptionSelected={(option) => getOptionSelected(option)}
            getOptionDisabled={(option) => getOptionDisabled(option)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            options={options}
            noOptionsText={
            propiedades.DialogForm ? 
            <Button onMouseDown={(e)=>
              {
                setOpenForm(true)
                
              }}>Añadir registro</Button> : 
              "No hay datos disponibles"
            }
            getOptionLabel={getOptionLabel}
            renderInput={renderInput}
            renderOption={renderOption}
            disabled={deshabilitado} //{(modo==="R" || propiedades.disabled===true ) ? true:false}
            onChange={(e, data) => {
              handleChange(data)              
              onChange(data)
              }}
            {...props}
          />        
      )}    
      />
    </Grid>
    <DialogForm
        title={"Añadir registro"}
        isOpen={openForm} 
        handleCloseDialog={handleClose}
        urlForm={propiedades.urlDataDialogForm ? propiedades.urlDataDialogForm : propiedades.urlData}
        action={handleClose}
        campos={propiedades.DialogForm}
        urlGoto={""}
        type="add"
        // value={value}
      />
    </Hidden>
    
  )
}