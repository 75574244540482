import React, { useState, useEffect } from "react";
import axios from "axios";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import AddButtonToolbar from "./AddButtonToolBar"
import {CAMPOS_CONTACTOS} from '../../containers/views/Main/Comercial/Crm/CuentaConf'

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
//import CardMedia from '@material-ui/core/CardMedia';
//import CardContent from '@material-ui/core/CardContent';
//import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';

//import FavoriteIcon from '@material-ui/icons/Favorite';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

//import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
//import InputBase from '@material-ui/core/InputBase';
//import Divider from '@material-ui/core/Divider';
////import MenuIcon from '@material-ui/icons/Menu';
//import SearchIcon from '@material-ui/icons/Search';
//import DirectionsIcon from '@material-ui/icons/Directions';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({    
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      marginTop: '3px !important',
      color: '#505050'
    },    

    detalles: {
        display: 'block'
    },
    root: {
        maxWidth: 345,    
        minHeight: 80,
        marginBottom: 10  
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      avatar: {
        backgroundColor: red[500],
      },

      rootPaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px'
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
      }, 
      nombre: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        color: '#505050'
      },
      puesto: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightRegular,
        color: '#9c9c9c'
      },
      mediosContacto: {
        backgroundColor: "#efefef"
      }
}));

const AgendaContactos = (props) => {
    const classes = useStyles();
    const [cerrarDialogo, setCerrarDialogo] = useState();
    const [contactos, setContactos] =useState([])
    const [contactosList, setContactosList] =useState([])
    const [expanded, setExpanded] = useState(false);
    const [expandedList, setExpandedList] = useState({});

    const handleExpandClick = (id) => {         
      setExpandedList({[id]: !expanded});
      setExpanded(!expanded)
    };

    let campos = [...CAMPOS_CONTACTOS];  

    campos.splice(0,0, {
        urlData: "cuentas/clientes",
        campoEtiqueta: "Nombre_Comercial",
        required: true,
        label: "Cliente",
        name: "nombreComercial",
        disabled: false,
        type: "combo",           
        multiple:false,   
        value: '',
        xs:12
    })  
    
    const accion = () => {        
        setCerrarDialogo(true)
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        const fetchData = async () => {
            const respContactos = await axios( process.env.REACT_APP_API_URL + "contactosagendas/" + user.id);
            if (respContactos.data) {              
              setContactos(respContactos.data);  
              setContactosList(respContactos.data); 
            }
          };
          fetchData();          
        return () => {}
    }, [])    

    const buscaContacto = () => {
      setContactosList(contactos)
    }
    const [name, setName] = useState('');
    const handleChange = (event) => {          
      setName(event.target.value);
    };

    useEffect(() => { 
      const result = contactos.filter(contacto => 
        contacto.Nombre.toUpperCase().includes(name.toUpperCase()) || contacto.Apellidos.toUpperCase().includes(name.toUpperCase())
      );
      setContactosList(result)      
      return () => {}

    }, [name, contactos])
    
    return (
    <Accordion>
      <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
        >
            <span id="title-image" className="float-left mr-2 ">
            <img
                src={`${process.env.REACT_APP_IMAGES}2-3-contactos.png`}
                width="24"
                height="24"
                alt=""
                loading="lazy"
            />
            </span> 
            <Typography className={classes.heading}>Contactos</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.detalles}>

        <Paper component="form" className={classes.rootPaper}>
          <TextField id="standard-name" label="Buscar contacto" value={name} onChange={handleChange}/>          
          
          <IconButton 
            className={classes.iconButton} 
            aria-label="search"
            onClick={() => buscaContacto()}
            >
              <HighlightOffIcon />
          </IconButton>          
        </Paper>
            
        {contactosList.map((option, index) => (                
          <Card key={option.Id} className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                  {option.Nombre.charAt(0) + option.Apellidos.charAt(0)}
                </Avatar>
              }  
              action={
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedList[option.Id],
                  })}
                  onClick={() => handleExpandClick(option.Id)}
                  aria-expanded={expandedList[option.Id]}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              }                      
              title= <Typography className={classes.nombre}>{option.Nombre + ' ' + option.Apellidos}</Typography>
              subheader= <Typography className={classes.puesto}>{option.Cargo}</Typography>
            />                          
            <Collapse className={classes.mediosContacto} id={option.Id} in={expandedList[option.Id]} timeout="auto" unmountOnExit>                      
              <Typography variant="caption" color="textSecondary" component="p">
                {option.Movil && (
                  <>
                  <a href={`tel:${option.Movil}`}>
                    <IconButton aria-label="móvil">                             
                      <PhoneIphoneIcon /> 
                    </IconButton>
                    {option.Movil}
                  </a>
                  <br />
                  </>
                )}
                {option.Telefono && (
                  <>
                  <a href={`tel:${option.Telefono}`}>
                    <IconButton aria-label="teléfono">                             
                      <PhoneIcon /> 
                    </IconButton>
                    {option.Telefono}
                  </a>
                  <br /> 
                  </> 
                )}
                {option.Email && (
                  <>
                  <a href={`mailto:${option.Email}`}>
                    <IconButton aria-label="email">
                      <EmailIcon /> 
                    </IconButton>
                    {option.Email}
                  </a>
                  <br />
                  </>
                )}
                {option.Linkedin && (
                  <>
                  <a href={option.Linkedin} rel="noopener noreferrer" target="_blank">
                    <IconButton aria-label="linkedin">                             
                      <LinkedInIcon /> 
                    </IconButton>
                    {option.Linkedin}  
                  </a>
                  <br />
                  </>
                )}                       
              </Typography>
            </Collapse>  
          </Card>
        ))}

        <Typography>
          Crear contacto
          <AddButtonToolbar 
            title = "Formulario Contactos"
            urlForm = "contactos"
            action = {accion}
            urlGoto = ""
            campos = {campos}
            toolTipTitle = "Añadir contacto"
            cerrar={cerrarDialogo}
          />
        </Typography>

      </AccordionDetails>
    </Accordion> 
    )

}
export default AgendaContactos;