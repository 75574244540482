import React, { useState } from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

const FormFieldDate = ({ propiedades, control, onChange=null }) => {      
    const { modo } = useParams();
    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )
    const [time, setTime] = useState('');

    const handleChange = (event) => {
        setTime(event.target.value);

    };

    return (
    <>
    {/* Campo Hora */}
    <Grid item xs={propiedades.xs} className="" >
        <Controller                        
            as={TextField}
            required={propiedades.required}
            control={control}
            id={propiedades.name}
            name={propiedades.name}
            disabled = {deshabilitado} //{propiedades.disabled}
            type="time"
            label={propiedades.label}
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={propiedades.value}
            //value={time} 
            //onChange={handleChange}
            className=""
            InputLabelProps={{
                shrink: true,
            }}
        />
    </Grid>
    </>
    )
}

export default FormFieldDate;