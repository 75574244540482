const botoneraDepositos = [
  {
    etiqueta: "Editar",
    url: '/logistica/depositos/deposito/filaSeleccionada/W',
    activo: false,
  }, 
  {
    etiqueta: "Crear",
    url: '/logistica/depositos/deposito',
    activo: true,
  }, 
];

export {botoneraDepositos};

const botoneraMaterialesListado = [
 {
    etiqueta: "Clonar",
    url: '/logistica/materials/material/filaSeleccionada/W',
    activo: false,
  }, 

  {
    etiqueta: "Editar",
    url: '/logistica/materials/material/filaSeleccionada/W',
    activo: false,
  }, 

  {
    etiqueta: "Crear",
    url: '/logistica/materials/material',
    activo: true,
  }, 
];

export {botoneraMaterialesListado};

const botoneraMaterialesEntradas = [
 {
    etiqueta: "Eliminar",
    url: '/logistica/materials/material/filaSeleccionada/W',
    activo: false,
  }, 

  {
    etiqueta: "Editar",
    url: '/logistica/materials/entradas/filaSeleccionada/W',
    activo: false,
  }, 

  {
    etiqueta: "Crear",
    url: '/logistica/materials/entradas',
    activo: true,
  }, 
];

export {botoneraMaterialesEntradas};

const botoneraMaterialesSalidas = [
  {
     etiqueta: "Eliminar",
     url: '/logistica/materials/salidas/filaSeleccionada/W',
     activo: false,
   }, 
 
   {
     etiqueta: "Editar",
     url: '/logistica/materials/salidas/filaSeleccionada/W',
     activo: false,
   }, 
 
   {
     etiqueta: "Crear",
     url: '/logistica/materials/salidas',
     activo: true,
   }, 
 ];
 
 export {botoneraMaterialesSalidas};

