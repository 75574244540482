import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
        padding: theme.spacing(1),
        margin: '10px',        
        backgroundColor: '#FFF',
    },    
    padding16: {
      padding: theme.spacing(0),      
    },   
    padding16C: {
        padding: theme.spacing(1),   
        textAlign: 'center',   
      }, 
    ventasactuales: {
      fontSize: 18,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      color: '#505050'
    },  
    ventasnetasleft: {
      fontSize: 18,
      fontFamily: 'Open Sans',
      fontWeight: 300,
      color: '#505050',
      textAlign: "left"
    }, 
    ventasnetasright: {
      fontSize: 18,
      fontFamily: 'Open Sans',
      fontWeight: 300,
      color: '#505050',
      textAlign: "right"
    },  
    ventasnetasimporte: {
      fontSize: 18,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      color: '#90b9e8',
      marginLeft: '10px',
      textAlign: "right"
    },  
    
    ventasnetascomparativa: {
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      color: '#90b9e8'
    },  
}));

const TextoImporteRentabilidad = ({texto, importe, putHr = false}) => {
  const classes = useStyles();
  return (
    <>
    <div className="col-6">
      <div className={classes.padding16}>
        <Typography className={classes.ventasnetasleft} gutterBottom>{texto}</Typography>
      </div>
      {putHr && (<hr></hr>)}
      </div>

      <div className="col-6">
      <div className={classes.padding16}>
        <Typography className={classes.ventasnetasright} gutterBottom><span className={classes.ventasnetasimporte}>{importe} €</span></Typography>
      </div>
      {putHr && (<hr></hr>)}
    </div>
    </>
  )
}

const MargenBruto = ({margenBruto, porcentaje, textoMargen}) => {
  const classes = useStyles();
  return(
    <>
    <div className="col-6">
      <div className={classes.padding16}>
        <Typography className={classes.ventasnetasleft} gutterBottom>{textoMargen}:              
        </Typography>
      </div>
    </div>   
    <div className="col-6">
      <div className={classes.padding16}>
        <Typography className={classes.ventasnetasright} gutterBottom>
          <span className={classes.ventasnetasimporte}>{margenBruto} € </span>
        </Typography>
      </div>
    </div>   
    <div className="col-6 offset-6">
      <div className={classes.padding16}>
        <Typography className={classes.ventasnetasright} gutterBottom>
          <span className={classes.ventasnetasimporte}> {porcentaje} %</span>
        </Typography>
      </div>
    </div>
    </>
  )
    
}

const RentabilidadTotal = ({titulo, tituloCostes, tituloMargen, urlInforme, filtroFechas}) => {
    const classes = useStyles();
    const [datosVentas, setDatosVentas] = useState({
      costes: 0,
      diferencia: 0,
      ventas: 0
    }) 
   
    useEffect(() => {         
      const fetchData = async () => {                     
        const respdatos = await axios(`${process.env.REACT_APP_API_URL}informes/${urlInforme}${filtroFechas}`)         
          setDatosVentas(respdatos.data)        
        };    
        fetchData();  
    }, [filtroFechas])

    const numberFormatter = Intl.NumberFormat('de-DE');
    const ventas = numberFormatter.format(datosVentas.ventas);
    const costes = numberFormatter.format(datosVentas.costes);
    const margenBruto = numberFormatter.format(datosVentas.diferencia);
    const porcentaje = numberFormatter.format(datosVentas.porcentaje);


    return (
    <div className={classes.root}>    
        <div className={classes.padding16}>      
          <Typography className={classes.ventasactuales}>{titulo}</Typography> 
          <hr></hr>
        </div>
        <div className="row">
          <TextoImporteRentabilidad texto={"Ventas Netas:"} importe={ventas} />
          <TextoImporteRentabilidad texto={tituloCostes} importe={costes} putHr={true}/>
          <MargenBruto margenBruto={margenBruto} porcentaje={porcentaje} textoMargen={tituloMargen}/>
        </div> 
    </div>
    );
}
export default RentabilidadTotal;