import React from "react";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldText from "../../../../../components/Common/FormFieldText";

const FormularioAlmacenamientoDatos = ({ datos, changeFicheros, control }) => {
    const { modo } = useParams();
    const handleFicheros = (files, filenames) => {  
        changeFicheros(files, filenames)        
    }      

    const deshabilitado = modo === "R" ? true : false;

    const classDiv3 = "col-3 mt-2 mb-2"
    let campos = [
        //0
        {   
            urlData: "cuentas/clientes",
            campoEtiqueta: "Nombre_Comercial",
            required: true,
            label: "Cliente:",
            name: "cliente",  
            disabled: deshabilitado,
            type: "combo",           
            multiple:false,   
            value: datos ? datos.cliente : "",              
            xs:4
        },
        //1
        {
            required: true,
            label: "Referencia",
            name: "referencia",
            type: "text",  
            disabled: deshabilitado,
            value: datos ? datos.referencia : "",
            xs:4
        }, 
        //2
        {            
            urlData: "dic/diccionarios/estadosalmacen",
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Estado",
            name: "estado",
            disabled: deshabilitado,
            type: "combo",           
            multiple:false,   
            value: datos ? datos.estado : "",  
            xs:4
        },
        //3
        {
            classButton: classDiv3,
            required: false,
            disabled: deshabilitado,
            label: "Documentacion",
            name: "ficherosadjuntos",
            type: "filesupload",   
            tabla: "mtms_almacenes_almacenajes_adjuntos",
            xs:12
        },   
        //4         
        {
            required: false,
            disabled: deshabilitado,
            label: "Documentacion",
            name: "ficherosremotos",
            type: "files", 
            ficheros: datos ? datos.ficheros : "",    
            tabla: "mtms_almacenes_almacenajes_adjuntos",
            xs:12,
            modelo: "api\\modules\\v1\\models\\almacenalmacenajes\\AlmacenAlmacenajesAdjuntos",
            carpeta: "almacenamientos",
        },
        //5
        {
            required: false,
            disabled: deshabilitado,
            label: "Observaciones",
            name: "observaciones",
            type: "textArea",
            lineas: 3,                
            value: datos ? datos.observaciones : "",
            xs:12
        },
    ]

    const numCampos = Object.keys(campos).length 

    return (
    <>
    { numCampos > 0 && (
        <Grid className="borderFormulario" container spacing={1}>  
        <FormFieldCombo propiedades={campos[0]} control={control}/>
        <FormFieldText propiedades={campos[1]} control={control}/>
        <FormFieldCombo propiedades={campos[2]} control={control}/>
        <FormFieldTextArea propiedades={campos[5]} control={control}/>        
        <FormFieldFiles propiedades={campos[4]} control={control} ficheros={handleFicheros}/>
        <FormFieldFileUpload propiedades={campos[3]} control={control} handleFicheros={handleFicheros}/>                      
        </Grid> 
    )}   
    </>
    )
}
export default FormularioAlmacenamientoDatos;