import {
  CUENTAS_GET,
  CUENTAS_SUCCESS,
  CUENTAS_ERROR,
} from "../actions/comercial/crm/Cuentas";

export default (
  state = {
    cuentas: [],
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case CUENTAS_GET:
      return {
        ...state,
        cuentas: [],
        isLoading: true,
        error: null,
      };

    case CUENTAS_SUCCESS:
      return {
        ...state,
        cuentas: action.payload.data,
        isLoading: false,
        error: null,
      };

    case CUENTAS_ERROR:
      let error = action.payload || { message: action.payload.message };
      return {
        ...state,
        cuentas: null,
        isLoading: false,
        error: error,
      };

    default:
      return state;
  }
};
