import React from "react";
import RecursosManoDetalleManoObraDesgloce from "./RecursosManoDetalleManoObraDesgloce"

const getCosteTotalManodeObra = (datosJornadas) => {
    let manoObraPropia = []
    let manoObraExterna = []

    if(datosJornadas && datosJornadas.length > 0){
        manoObraPropia = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Mano de obra propia")
        manoObraExterna = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Mano de obra externa")  
    }

    let costeTotalManodeObra = 0;
    let costeTotalManodeObraPropia = 0;
    let costeTotalManodeObraExterna = 0;
    let unidadesTotalManodeObraPropia=0;
    let unidadesTotalManodeObraExterna=0;    

    manoObraPropia.forEach(manoObra => {
        costeTotalManodeObra += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        costeTotalManodeObraPropia += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        unidadesTotalManodeObraPropia += parseInt(manoObra.elementos.cantidad)

    })

    manoObraExterna.forEach(manoObra => {
        costeTotalManodeObra += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        costeTotalManodeObraExterna += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        unidadesTotalManodeObraExterna += parseInt(manoObra.elementos.cantidad)
    })

    return {
        costeTotalManodeObra: costeTotalManodeObra, 
        costeTotalManodeObraPropia: costeTotalManodeObraPropia,
        costeTotalManodeObraExterna: costeTotalManodeObraExterna,
        unidadesTotalManodeObraPropia: unidadesTotalManodeObraPropia,
        unidadesTotalManodeObraExterna: unidadesTotalManodeObraExterna,
        ajustePrecioCPE: costeTotalManodeObraExterna*4/100
    }
}

const RecursosManoDetalleManoObraCabecera = ({manos, datosJornadas}) => 
{ 
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);

    const costeTotalManoObraAgrupado = getCosteTotalManodeObra(datosJornadas)
    const costeTotalManoObra = numberFormat2.format(costeTotalManoObraAgrupado.costeTotalManodeObra + parseFloat(costeTotalManoObraAgrupado.ajustePrecioCPE))    
    
    return (
        <>
        <div className="col-12 mt-4 d-flex overflow-auto justify-content-center">
            <span className="col-11 float-left">Mano de obra</span><span className="col-1 float-right">{costeTotalManoObra}</span>            
        </div>
        <hr style={{height:1, width:'100%'}} className="m-0 bg-comercial mb-4" />

        {manos &&  manos.map((mano, index) => (
            <React.Fragment key={index}>
            <div className="borderFormulario">
                <div className="col-12 d-flex overflow-auto justify-content-center borderFormulario mb-4">
                    <div className="col-1 float-left">Jornada </div>
                    <div className="col-5 float-right">{mano.Jornada}</div>
                    <div className="col-3 float-left"></div>
                    <div className="col-1 float-left">Mano </div>
                    <div className="col-2 float-right">{mano.Mano}</div>
                </div>

                <RecursosManoDetalleManoObraDesgloce 
                    datosJornadas={datosJornadas} 
                    mano = {mano.Mano}              
                />
                
            </div>
            </React.Fragment>
        ))}   
        </>
    )

}

export default RecursosManoDetalleManoObraCabecera;