import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router";

import FormularioFacturaProductos from '../../../../../components/Common/FormularioFacturaProductos'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import TablaFacturaProductos from '../../../../../components/Common/TablaFacturaProductos'
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from '@material-ui/core/styles';

import {useFacturaVenta} from './facturaventa-context'
import FormularioFacturaUnicaDatos from "./FormularioFacturaUnicaDatos";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
}));

const FormularioFacturaUnica = ({index, tipoForm}) => {   
  const { listadoProductosFacturaVenta, totalFacturaVenta, setListadoProductosFacturaVenta } = useFacturaVenta(); 
  const methods = useForm(); 
  const classes = useStyles();
  const { control, handleSubmit} = useForm(); 
  const { id, modo } = useParams();
  const [FacturaUnica, setFacturaUnica] = useState({}) 
  const [ficheros, setFicheros] = useState([])
  const [nombresFicheros, setNombresFicheros] = useState([])
  const [cliente, setCliente] = useState({})  
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const history = useHistory(); 
    
  const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
  };      
    
  useEffect(() => {   
    const fetchData = async () => {
      setIsLoading(true) 
      if (modo === "W") {
          const resFacturaUnica = await axios(
              `${process.env.REACT_APP_API_URL}facturasunicas/${id}`
          );                
          setFacturaUnica(resFacturaUnica.data)
      }
      setIsLoading(false);
  };    
  fetchData();     
  },[]);

  const handleChangeCliente=(valor)=>{   
    setCliente(valor)      
  }

  const handleChangeFicheros = (fich, nobresFich) => {
    setFicheros(fich)
    setNombresFicheros(nobresFich)
  }

  useEffect(() => {
    if (typeof FacturaUnica !== "undefined") { 
      if(Object.keys(FacturaUnica).length > 0){  
        setIsLoading(true)        
        setCliente(FacturaUnica.Cliente ? FacturaUnica.Cliente : {})
        setListadoProductosFacturaVenta(FacturaUnica.productos)        
        console.log(FacturaUnica.productos);
        setIsLoading(false)  
      }
    }
  }, [FacturaUnica])

  const handleSubmitPost = async (datos) => { 
     
    datos.FechaFactura = datos.fechaFactura
    datos.Cliente = datos.Cliente.Id
    datos.Estado = datos.Estado.Id
    datos.Observaciones = datos.observaciones
    datos.ficheros=ficheros
    datos.nombresFicheros=nombresFicheros
    //datos.Fecha_Operativa = datos.fechaOperativa
    
    datos.baseImponible = totalFacturaVenta.baseImponible
    datos.totalImpuestos = totalFacturaVenta.igic
    datos.totalIRPF = totalFacturaVenta.irpf
    datos.extraCosteJ3 = totalFacturaVenta.extraCosteJ3
    datos.extraCosteJ4 = totalFacturaVenta.extraCosteJ4
    datos.extraCosteJ5 = totalFacturaVenta.extraCosteJ5
    datos.extraCosteJ6 = totalFacturaVenta.extraCosteJ6
    datos.extraCosteJ7 = totalFacturaVenta.extraCosteJ7
    datos.extraCosteJ8 = totalFacturaVenta.extraCosteJ8
    datos.extraCosteJ9 = totalFacturaVenta.extraCosteJ9
    datos.extraCosteJ10 = totalFacturaVenta.extraCosteJ10
    datos.totalFactura = totalFacturaVenta.total    
    datos.listadotabla=listadoProductosFacturaVenta

    console.log(datos.listadotabla)
    
    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = `${process.env.REACT_APP_API_URL}facturasunicas`

    datos.userid = user.id

      if (modo==="W") {
        await axios
          .put(`${SERVER_URL}/${id}`, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {       
            //console.log(response.data) 
            if(!isNaN(response.data)){              
              if(parseInt(response.data) === parseInt(id)){                       
                  setOpenAlert(true)                  
                  setTimeout(function () {
                    window.location.reload();   
                   }, 500);                   
              }              
            }
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
            });
      }
      else{
        await axios
          .post(SERVER_URL, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {       
            //console.log(response.data) 
            if(!isNaN(response.data)){
              if(parseInt(response.data)){                       
                  setOpenAlert(true)
                  history.push({
                    pathname: `/administracion/ventas/facturasunicas/${response.data}/W`,
                    //action: action + Math.floor(Math.random() * (9999))
                  });
                  
                  setTimeout(function () {
                    window.location.reload();   
                   }, 500);
                   
              }
            }
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
        });
      }
  }

return (
<>    
  {!isLoading && (
  <>
   <div className={classes.root}>
    <FormProvider {...methods}>
      <form
        id='formulario-FacturaUnica'
        onSubmit={handleSubmit((data) => {
        handleSubmitPost(data);
        })}
        className=""
      >           
        <FormularioFacturaUnicaDatos datosFactura={FacturaUnica} changeCliente={handleChangeCliente} changeFicheros={handleChangeFicheros} control={control} /*importProductFromOrigin={handleImportProduct}*/ />                               
        <FormularioFacturaProductos cliente={cliente} />
        <TablaFacturaProductos tipoFactura={"unica"}/>
      </form>
    </FormProvider>
    {openAlert && 
          <AlertaMensaje 
            mensaje={"Factura guardada correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          } 
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
        </>
    )
};

export default FormularioFacturaUnica;