import React, { useState } from "react";
import Pasos from "../../../../../components/Common/Pasos";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import {botoneraGastos, botoneraIngresos} from "./PeriodificacionCof"
import {columnsGastos, columnsIngresos} from "./PeriodificacionCof"

const Periodificacion = () => {
    const [idPeriodificacion, setIdPeriodificacion] = useState(parseInt(localStorage.getItem('idOpPeriodificacion')) || 0);
    const [filaSeleccionada, setFilaSeleccionada] = useState(-1);

    const marcarFila = (fila) => {
      setFilaSeleccionada(fila);
    }

    let botonera="";
    let columnas="";
    let urlDatosCuerpoTabla="";
    let urlDoubleClick="";

    const verTabla = (idPeriodificacion) => {
        localStorage.setItem('idOpPeriodificacion', idPeriodificacion);
        setIdPeriodificacion(idPeriodificacion)
    }      

    switch (idPeriodificacion) {
        case 0:
          botonera = botoneraGastos
          columnas = columnsGastos
          urlDatosCuerpoTabla = "periodificaciongastos"
          urlDoubleClick = "/finanzas/periodificacion/gastos/"

          break;

        case 1:
          botonera = botoneraIngresos
          columnas = columnsIngresos
          urlDatosCuerpoTabla = "periodificacioningresos"
          urlDoubleClick = "/finanzas/periodificacion/ingresos/"

          break;        

        default:
          botonera = botoneraGastos
          columnas = columnsGastos
          urlDatosCuerpoTabla = "periodificaciongastos";
          urlDoubleClick = "/finanzas/periodificacion/gastos/"
        break;
        
    }

    return (
        <div className="col-main-home ">
          <SearchBar
            titulo="Finanzas"
            claseCabecera="bg-gestion"
            buscar={false}
            imagenCabecera={"6-2-1-tesoreria.png"}
          />
    
          <div className="bg-cuerpo p-10px">
            <div className="bg-white p-10px">
              <div className="p-1 bg-white mb-3">
                <h6 className="texto-gestion float-left" id="title">
                  Periodificación
                </h6>
              </div>
              <hr className="m-0 bg-gestion mb-1" />
              <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                <Pasos pasos={['GASTOS', 'INGRESOS']} idPasoActivo={idPeriodificacion} changePasoActivo={verTabla} />                
                <CuerpoTablaPiezas                  
                  columnas={columnas}                 
                  urlDatos={urlDatosCuerpoTabla}
                  urlDoubleClick={urlDoubleClick}
                  dobleclick={true}
                  columnId="Id"
                  getFilaSeleccionada={marcarFila}
                />               
              </div>
            </div>
            
              <div className="bg-cuerpo">
                <div className="bg-white">
                  <TableAcciones
                    filaSeleccionada={filaSeleccionada}
                    botonaccion={botonera}
                  />
                </div>
              </div>
    
          </div>
        </div>
    
      );
}

export default Periodificacion;