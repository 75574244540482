import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";

import { CircularProgress } from '@material-ui/core';
import FormularioInstrumentos from "./FormularioInstrumentos";

const Instrumentos = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [datos, setDatos] = useState();

    useEffect(() => {
        setIsLoading(true)
    },[props.location.action])

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
              //cambiar url de endpoint
            const respAlmacen = await axios( process.env.REACT_APP_API_URL + "tesoreriainstrumentos/" + id);
            if (respAlmacen.data) {              
              setDatos(respAlmacen.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const botoneraAcciones = [    
        {
          etiqueta: "Guardar",
          url: `/finanzas/tesoreria/`,
          activo: true,
        },
        {
            etiqueta: "Cancelar",
            url: `/finanzas/tesoreria/`,
            activo: true,
          },
    ];

    const codigo = typeof datos !== "undefined" ? datos.Codigo : "";

    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Finanzas"
              claseCabecera = "bg-gestion"
              buscar={false}
              imagenCabecera={"6-2-1-tesoreria.png"}
            />
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <div className="p-1 bg-white mb-4">
                      <h6 className="texto-gestion float-left" id="title">
                        Instrumentos
                      </h6>
                      <h6 className="texto-gestion float-right" id="title">
                        {codigo}
                      </h6>
                    </div>
    
                    <hr className="m-0 bg-comercial mb-4" />
    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                      {modo !== "R" && 
                        <FormularioInstrumentos />}   
                          
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default Instrumentos;