import React from "react";
import Nav from "../../components/Common/Header";
import Menuizq from "../../components/Common/Menuizq";
import MenuDerOperaciones from "../../components/Common/MenuDerOperaciones";
import {OperacionProvider} from '../views/Main/Produccion/Operativas/operacion-context'
import MaintenanceModeComponent from "../../components/Common/MaintenanceModeComponent";

export default ({ children }) => {  
  const maintenanceMode = children.props.children[0]?.key === 'true'

  return (
    <>
    {maintenanceMode && (
      <MaintenanceModeComponent />
    )}
    {!maintenanceMode && (
      <OperacionProvider>
      <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
        <Nav />
        <div className="row min-vh-90 ml-0 mr-0">
          <Menuizq />
          {children}
          <MenuDerOperaciones />
        </div>
      </div>
      </OperacionProvider>
    )}
    </>
  );
};

/*
return (
    <OperacionProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children}
        <MenuDerOperaciones />
      </div>
      
    </div>
    </OperacionProvider>
  );
  */