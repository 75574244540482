import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'


const FichaPersonal = () => {
    let { id } = useParams();

    const [personal, setSeleccion] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSeleccion = await axios(`${process.env.REACT_APP_API_URL}rrhhgastospersonals/${id}`);    
          setSeleccion(respSeleccion.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(personal).length > 0){  
        console.log(personal)
            setCampos([
            {
                classsLabel: "",
                label: "Trabajador:",
                value: personal.Trabajador.nombre,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Departamento:",
                value: personal.Departamento.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Detalle:",
                value: personal.Detalle.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha:",
                value: personal.Fecha,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Importe:",
                value: personal.Importe,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: personal.Observaciones,
                classText: classText,
                xs:12
            },
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: personal.ficheros,
                 classText: classText,
                 type: "file",
                 xs:4
                },
            
        ])
    }
},[personal]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/rrhh/talento/personal/${id}/W`}
    />
    </>
  )
}
export default FichaPersonal;