import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
//Ficha entidad general para reutilizar en cada ficha
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaSubEntrada = () => {
    let { id } = useParams();

    const [SubEntrada, setSubEntrada] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSubEntrada = await axios(`${process.env.REACT_APP_API_URL}SubEntrada/${id}`);    
          setSubEntrada(respSubEntrada.data)  
        };
        fetchData();  
    }, [id]);
    //Recogemos la fecha por dia/mes+1/año
    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    } 

    //Imprimimos los datos 
    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(SubEntrada).length > 0){  
        
            setCampos([
            {
                classsLabel: "",
                label: "Cliente:",
                value: SubEntrada.cliente,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha entrada:",
                value: SubEntrada.fechaentrada,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Salida estimada:",
                value: SubEntrada.salidaestimada,
                classText: classText,
                xs:4
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {
                classsLabel: "",
                label: "Depósito:",
                value: SubEntrada.almacen,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Unidades:",
                value: SubEntrada.unidades,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Días estancia:",
                value: SubEntrada.diasestancia,
                classText: classText,
                xs:6
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {
                classsLabel: "",
                label: "Ubicación:",
                value: SubEntrada.ubicacion,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: SubEntrada.observaciones,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Estado:",
                value: SubEntrada.estado,
                classText: classText,
                xs:4
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {
                classsLabel: "",
                label: "Documentación:",
                value: SubEntrada.documentacion,
                classText: classText,
                xs:4
            },
            
        ])
    }
},[SubEntrada]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    //Es otro urlClick donde redirige para editar
    urlClick = {`/produccion/operativas/SubEntrada/${id}/W`}
    />
    </>
  )
}
export default FichaSubEntrada;