const columnsHojaDePedido = [
    {
      name: "Id",
      label: "Nº",
      options: {
       filter: true,
       sort: true,
      }
     },  
    {
        name: "Fecha_Pedido",
        label: "Fecha",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Codigo",
        label: "Pedido",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "proveedor",
        label: "Proveedor",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "importe",
        label: "Importe",
        options: {
         filter: true,
         sort: true,
        }
       },  
       {
        name: "autorizado",
        label: "Autorizado",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Fecha_Autorizacion",
        label: "Fecha Autorización",
        options: {
         filter: true,
         sort: true,
         
        }   
       },  
       {
          name: "estado",
          label: "Estado",
          options: {
           filter: true,
           sort: true,
           
          }   
         },  
         
    ];
  
    export {columnsHojaDePedido};

    const columnsOrdenDeCompra = [
      {
        name: "Id",
        label: "Nº",
        options: {
         filter: true,
         sort: true,
        }
       },  
       {
        name: "fechaCompra",
        label: "Fecha",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "ordenCompra",
        label: "Orden",
        options: {
         filter: true,
         sort: true,
        }
       },   
         {
          name: "proveedor",
          label: "Proveedor",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
          name: "autorizado",
          label: "Autorizado",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
          name: "importe",
          label: "Importe",
          options: {
           filter: true,
           sort: true,
          }
         }, 
         {
            name: "estado",
            label: "Estado",
            options: {
             filter: true,
             sort: true,
             
            }   
           },  
           
      ];
    
      export {columnsOrdenDeCompra};


      const columnsFacturaDeCompra = [
        {
          name: "Id",
          label: "Nº",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
          name: "tipoOrigen",
          label: "Tipo de factura",
          options: {
           filter: true,
           sort: true,
          }
         }, 
         {
          name: "origen",
          label: "Origen",
          options: {
           filter: true,
           sort: true,
          }
         },
         /*
         {
          name: "fechaCompra",
          label: "Fecha compra",
          options: {
           filter: true,
           sort: true,
          }
         },         
         {
          name: "Codigo",
          label: "Orden Compra",
          options: {
           filter: true,
           sort: true,
          }
         },   
         */
         {
          name: "fechaFactura",
          label: "Fecha",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
          name: "NumFactura",
          label: "Factura",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
          name: "RecapitulativaCPE",
          label: "Recapitulativa",
          options: {
           filter: true,
           sort: true,
          }
         }, 
          
        {
            name: "proveedor",
            label: "Proveedor",
            options: {
             filter: true,
             sort: true,
            }
           }, 
           {
            name: "importe",
            label: "Importe",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "importeIgic",
            label: "IGIC",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "irpfImporte",
            label: "IRPF",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "Total_Factura",
            label: "Total",
            options: {
             filter: true,
             sort: true,
            }
           },
           //Total_Factura
           /*
           {
            name: "centroCoste",
            label: "Centro de costes",
            options: {
             filter: true,
             sort: true,
            }
           }, 
           */
           {
              name: "estado",
              label: "Estado",
              options: {
               filter: true,
               sort: true,
               
              }   
             },  
             
      ];
      
      export {columnsFacturaDeCompra};

      const columnsFacturaDeCompraCPE = [
        {
          name: "Id",
          label: "Nº",
          options: {
           filter: true,
           sort: false,
          }
         },
         {
          name: "tipoOrigen",
          label: "Tipo de factura",
          options: {
           filter: true,
           sort: true,
          }
         }, 
         {
          name: "origen",
          label: "Origen",
          options: {
           filter: true,
           sort: true,
          }
         },
         /*
         {
          name: "fechaCompra",
          label: "Fecha compra",
          options: {
           filter: true,
           sort: true,
          }
         },         
         {
          name: "Codigo",
          label: "Orden Compra",
          options: {
           filter: true,
           sort: true,
          }
         },   
         */
         {
          name: "fechaFactura",
          label: "Fecha",
          options: {
           filter: true,
           sort: true,
          }
         },
         {
          name: "NumFactura",
          label: "Factura",
          options: {
           filter: true,
           sort: true,
          }
         }, 
         {
          name: "RecapitulativaCPE",
          label: "Recapitulativa",
          options: {
           filter: true,
           sort: true,
          }
         }, 
        {
            name: "proveedor",
            label: "Proveedor",
            options: {
             filter: true,
             sort: false,
            }
           },
           
           {
            name: "importe",
            label: "Importe factura",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "importeIgic",
            label: "IGIC",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "irpfImporte",
            label: "IRPF",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "Total_Factura",
            label: "Total",
            options: {
             filter: true,
             sort: true,
            }
           },
           {
            name: "importePartesCPE",
            label: "Importe partes CPE",
            options: {
             filter: true,
             sort: true,
            }
           },
           /*
           {
            name: "centroCoste",
            label: "Centro de costes",
            options: {
             filter: true,
             sort: true,
            }
           }, 
           */
           {
              name: "estado",
              label: "Estado",
              options: {
               filter: true,
               sort: true,
               
              }   
             },  
             
      ];
      
      export {columnsFacturaDeCompraCPE};
