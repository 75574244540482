import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';

const FormFieldDateUncontrolled = ({ propiedades, onChangeValue=null }) => {  
    
    const handleChange = (v) => {
        if(onChangeValue){
            onChangeValue(v)
        } 
    }

    return (
    <>
    {/* Campo Fecha */}
    <Grid item xs={propiedades.xs} className="" >
        <TextField
            required = {propiedades.required}
            id={propiedades.name}
            type="date"
            disabled = {propiedades.disabled}
            label={propiedades.label}
            variant="outlined"
            size="small"
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            value={propiedades.value}
            onChange={data => handleChange(data.target.value)}
        />
        
    </Grid>
    </>
    )
}

export default FormFieldDateUncontrolled;
/*
<div className={propiedades.classDiv}>
<Controller                        
            as={TextField}
            required={propiedades.required}
            control={control}
            id={propiedades.name}
            name={propiedades.name}
            disabled = {propiedades.disabled}
            type="date"
            label={propiedades.label}
            variant="outlined"
            size="small"
            fullWidth
            defaultValue={propiedades.value}
            className=""
            InputLabelProps={{
                shrink: true,
            }}
        />
</div>
*/