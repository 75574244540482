import React, { useState, useEffect, useContext  } from 'react';
import { StateContext } from '../../../../../context'
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const DetalleOperacionProveedor = ({operacion}) => {
    const { state, dispatch } = useContext(StateContext)   
    const [actualizado, setActualizado] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [pedidoEliminar, setPedidoEliminar] = useState({Id:'', NFactura:''})

    useEffect(() => {  
        if(Object.keys(operacion).length > 0)
        {setActualizado(true)}
    }, [operacion])    

    const handleClose = () => {
        setOpen(false);
    };

    const Eliminar = (idElemento) => {
        const pedidoAEliminar = operacion.Proveedores.filter(pedido => pedido.Id === idElemento)        
        if(pedidoAEliminar.length > 0){
            setPedidoEliminar(pedidoAEliminar[0])
            setOpen(true);
        }
    }

    const handleCloseEliminar = async (nPedido) => {    
       
        const id = nPedido;
        const url = 'operaciones/eliminapedidoproveedor'
        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${url}`;   
        
        await axios.post(SERVER_URL, {
            headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
            },
            id
        })
        .then((response) => {
            
            if (response.data === id ){ 
                dispatch({type: "RELOAD_DETALLE", payload: true}) 
            }
          })
          .catch((error) => {
            console.log("error " + error);
          });
        setOpen(false);
    }

    return(
        <React.Fragment>
        <h5>PROVEEDOR</h5> 
            <Grid container spacing={1} className="mt-2 borderFormulario">
            {actualizado && (
                <React.Fragment>
                {operacion.Proveedores.map((option) => (
                    <React.Fragment key={option.Id}>
                    <Grid container spacing={1} className="mt-2 ">
                        <Accordion className="mb-2" style={{width:'100%'}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"                            
                            >
                                <Grid container spacing={1} className="mt-2 mb-2">
                                    <Grid item xs={3} className="mt-2" >
                                        <h6>Nº Factura: {option['NFactura']}</h6>
                                    </Grid>
                                    <Grid item xs={4} className="mt-2" >
                                        <h6>Proveedor: {option['Fk_Cuenta']}</h6>
                                    </Grid>
                                    <Grid item xs={4} className="mt-2" >
                                        <h6>Fecha factura: {option['Fecha_Factura']}</h6>
                                    </Grid>
                                    <Grid item xs={1} className="mt-2 " >
                                        {/*
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => {event.stopPropagation(); alert("HOLA CARACOLA A EDITAR")}}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<EditIcon />}                            
                                        />   
                                        */} 
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => {event.stopPropagation(); Eliminar(option['Id'])}}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<DeleteIcon />}                            
                                        />  
                                    </Grid>
                                </Grid>
                            </AccordionSummary>                        
                            <AccordionDetails>
                                <Grid container spacing={1} className="mt-2 ">
                                    <Grid item xs={3} className="mt-2" >                            
                                        <h6>Descripción: {option['Descripcion']}</h6>                               
                                    </Grid>
                                    <Grid item xs={3} className="mt-2" >                                    
                                        <h6>Cantidad: {option['Cantidad']}</h6>   
                                    </Grid>
                                    <Grid item xs={3} className="mt-2" >                                                
                                        <h6>Coste: {option['Coste']} €</h6> 
                                    </Grid>
                                    <Grid item xs={3} className="mt-2" > 
                                        <h6>Mano Planificada: {option['Fk_Mano_Planificada']}</h6>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion> 
                    </Grid>       
                    <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Desea eliminar la factura de proveedor "} {pedidoEliminar.NFactura} {" ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Si elimina la factura no se podrá recuperar.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleCloseEliminar(pedidoEliminar.Id)} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            </React.Fragment>                 
                    </React.Fragment>
                ))}
                </React.Fragment>
            )}
            </Grid>
        </React.Fragment> 
    )
}
export default DetalleOperacionProveedor;