import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { StateContext } from '../../../../../context'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'

let propiedadesClientes = {
    urlData: "cuentas/clientes",
    campoEtiqueta: "Nombre_Comercial",
    required: true,
    name: "clientes",
    disabled: false,
    label: "Cliente(s)",
    value: [],
    multiple: true,
    xs: 12
}

let propiedadesBuques = {
    urlData: "dic/diccionarios/buques",
    campoEtiqueta: "Nombre",
    required: true,
    name: "buques",
    disabled: true,
    label: "Buque",
    value: '',
    multiple: false,
    xs: 4
}

let propiedadesBuques2 = {
    urlData: "dic/diccionarios/buques",
    campoEtiqueta: "Nombre",
    required: false,
    name: "buques2",
    disabled: true,
    label: "Buque 2",
    value: '',
    multiple: false,
    xs: 4
}

let propiedadesMuelles = {
    urlData: "dic/diccionarios/localizaciones",
    campoEtiqueta: "Etiqueta",
    required: true,
    name: "muelles",
    disabled: true,
    label: "Muelle",
    value: '',
    multiple: false,
    xs: 4
}

let propiedadesMuelles2 = {
    urlData: "dic/diccionarios/localizaciones",
    campoEtiqueta: "Etiqueta",
    required: false,
    name: "muelles2",
    disabled: true,
    label: "Muelle 2",
    value: '',
    multiple: false,
    xs: 4
}

let propiedadesFechaOperacion = {
    required: true,
    label: "Fecha de operativa",
    name: "fechasoperativa",
    type: "date",    
    value: '',
    xs: 4
} 

const FormularioOperacionDatos = ({control, onChangeStep}) => {     
    const { state, dispatch } = useContext(StateContext)    
    const { operacion } = state     
    const [isLoading, setIsLoading] = useState(true)     
    let { modo } = useParams(); 

    const handleFechaOperativa = (valor) => {         
        let clone = JSON.parse(JSON.stringify(operacion)); 
        clone.Fecha_Operativa = valor        
        propiedadesFechaOperacion.value =valor  
        dispatch({type: "SET_OPERACION", payload: clone})        
    }

    const handleCuentas = (valor) => {         
        let clone = JSON.parse(JSON.stringify(operacion)); 
        clone.CuentasCliente = valor        
        propiedadesClientes.value =valor  
        dispatch({type: "SET_OPERACION", payload: clone})        
    }

    const handleChageStep = (valor) => {        
        if(onChangeStep)
            onChangeStep(valor)
    }

    useEffect(() => {
        if (operacion && typeof operacion !== "undefined") { 
            
            if(operacion.Fecha_Operativa /*Object.keys(operacion).length > 0*/){    
                             
                setIsLoading(true)
                //console.log(operacion)
                propiedadesFechaOperacion.value = operacion.Fecha_Operativa             
                propiedadesBuques.value = operacion.Buque[0]
                propiedadesClientes.value = operacion.CuentasCliente
                propiedadesMuelles.value = operacion.Localizacion[0]  
                propiedadesBuques2.value = operacion.Buque2[0]
                propiedadesMuelles2.value = operacion.Localizacion2[0] 
                
                setTimeout(function() {
                    setIsLoading(false) 
                }, 500);             
                  
            }
        }
    },[operacion]) 

    const handleCleanForm = () => {
        dispatch({type: "UPDATE_OPERACION", payload: "cpe"}) 
    }
    propiedadesClientes.disabled = modo === "R"
    propiedadesFechaOperacion.disabled = modo === "R"
    
    return (       
    <>
        {isLoading && (
            <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
        )} 
        {!isLoading && (
            <>
            <Grid container spacing={1} className="borderFormulario CPE1 mt-2">                 
                <FormFieldCombo propiedades={propiedadesClientes} control={control} onChangeValue={handleCuentas}/>
                <FormFieldCombo propiedades={propiedadesBuques} control={control}/>
                <FormFieldCombo propiedades={propiedadesMuelles} control={control}/>
                <FormFieldDateUncontrolled propiedades={propiedadesFechaOperacion} onChangeValue={handleFechaOperativa} />          
                <FormFieldCombo propiedades={propiedadesBuques2} control={control}/>
                <FormFieldCombo propiedades={propiedadesMuelles2} control={control}/>  
                
            </Grid>
            <Grid className="" container spacing={1}> 
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
                <Button className="float-right" variant="contained" color="primary" 
                    onClick={() => handleCleanForm() }>
                    CANCELAR
                </Button>
            </Grid>   
            <Grid item xs={2}>
                <Button className="float-right" variant="contained" color="primary" 
                    onClick={() => handleChageStep(1) }>
                    SIGUIENTE
                </Button>
            </Grid>   
            </Grid>
            </>
        )}
    </>
    )
}

export default FormularioOperacionDatos;