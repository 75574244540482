import React, { useState, useEffect, useContext } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";

import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import {columnsSoporte} from './PostventaConf';
import {botoneraSoporte} from './BotonesConf';
import SearchBar from "../../../../../components/Common/SearchBar";
const Soportes = (props) => {

  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  let columnasCuerpoTabla = columnsSoporte;
  let urlDatosCuerpoTabla = "soportes";
  let urlDoubleClickCuerpoTabla = "soportes/soporte/";
  let columnIdCuerpoTabla = "id";
  let botonaccionCuerpoTabla = botoneraSoporte;

return(
  <div className="col-8 col-main-home ">            
  <SearchBar
    titulo="Postventa"
    claseCabecera = "bg-produccion"
    buscar={false}
    imagenCabecera={"5-3-1-soporte.png"}
  />   
  
  <div className="bg-cuerpo p-10px">
    <div className="bg-white p-10px">
      <div className="p-1 bg-white mb-3">
        <h6 className="texto-comercial float-left" id="title">
          Soporte
        </h6>
      </div>
      <hr className="m-0 bg-comercial mb-1" />
      <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">


       <CuerpoTablaPiezas
          columnas = {columnasCuerpoTabla}
          urlDatos = {urlDatosCuerpoTabla}
          urlDoubleClick = {urlDoubleClickCuerpoTabla}
          columnId = {columnIdCuerpoTabla}
          getFilaSeleccionada={marcarFila}
        /> 
      </div>
    </div>

    <div className="bg-cuerpo">
      <div className="bg-white">
        <TableAcciones                
          filaSeleccionada={filaSeleccionada}                
          botonaccion={botonaccionCuerpoTabla}
        />            
      </div>
    </div>

  </div>
    
</div>   

);

} 

export default Soportes;

