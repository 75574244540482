import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

const FormularioManteniminetoInicial = ({listadoMateriales,ListadoMaterialesFinal}) => {  
    const { id, modo } = useParams();
    const [listadoMaterialesInicial, setListadoMaterialesInicial] = useState([])        

    const columns = [        
        { field: 'recursos', headerName: 'Recursos', width: 100 },
        { field: 'descripcion', headerName: 'Descripción', width: 240 },
        { field: 'fecha', headerName: 'Fecha', width: 100 },
        { field: 'horainicio', headerName: 'H.inicio', width: 80 },
        { field: 'horafin', headerName: 'H.fin', width: 80 },
        { field: 'codigo', headerName: 'Código', width: 80 },//EAN
        { field: 'deposito', headerName: 'Depósito', width: 90 },
        { field: 'cantidad', headerName: 'Cantidad', width: 90 },
        { field: 'tarifa', headerName: 'Coste unitario', width: 90 },
        { field: 'coste', headerName: 'Coste total', width: 90 },
        { field: '', headerName: '', width: 120, 
            renderCell: (params) => (               
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>                
              ), },
    ];
    
    const resetForm = () => {
        setListadoMaterialesInicial([])
    }

    useEffect(() => {
        if(  typeof listadoMateriales === 'undefined' ||
            (Array.isArray(listadoMateriales) && listadoMateriales.length === 0 )
        ){        
            resetForm()
        }else{
            setListadoMaterialesInicial(listadoMateriales)
        }
       
    },[listadoMateriales])

    const handleRemoveElement = (element) =>{
        if(modo==="W") {
            const elementoAEliminar = listadoMaterialesInicial.filter (el => {
                return el.id !== element.id
            })

            ListadoMaterialesFinal(elementoAEliminar)
        }
    }   
    
    return(
        <>
        <Grid container  spacing={1} className="mt-2 ">
            <Grid item xs={12} className="" style={{height: 250}}>
                <DataGrid 
                    rows={listadoMaterialesInicial} 
                    columns={columns} 
                    pageSize={100} 
                    checkboxSelection = {false}
                    hideFooter = {true}
                    hideFooterPagination = {true}
                    hideFooterRowCount = {true}
                    hideFooterSelectedRowCount = {true}
                />
            </Grid>
        </Grid>
        </>
    )
}
export default FormularioManteniminetoInicial;
