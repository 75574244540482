import React, { useState, useEffect, useContext  } from 'react';
import { StateContext } from '../../../../../context'
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import { Typography } from '@material-ui/core';

const DetalleOperacionGesport = ({operacion}) => {
    const { state, dispatch } = useContext(StateContext)   
    const [actualizado, setActualizado] = useState(false)
   
    useEffect(() => {  
        if(Object.keys(operacion).length > 0)
        {setActualizado(true)}
    }, [operacion]) 

    const Editar = (idElemento) => {
        console.log(idElemento)
        dispatch({type: "UPDAT_GESPORT_ELEMENT", payload: {tipo: "cpe", elemento: idElemento}})
    }
    
    return(
        <React.Fragment>
        <h5>GESPORT</h5> 
        <Grid container spacing={1} className="mt-2 borderFormulario">
        {actualizado && (
        <React.Fragment>
        {operacion.Gesport.map((option) => (
            <React.Fragment key={option.Id}>
            <Grid container spacing={1} className="mt-2">
            <Accordion className="mb-2" style={{width:'100%'}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{margin:10}}
                >
                    <CabeceraParteGesport datosCabecera={option} operacion={operacion} />
                </AccordionSummary>
                <AccordionDetails>
                    <DetalleParteGesport datosDetalle={option} operacion={operacion} />                   
                </AccordionDetails>
            </Accordion>  
            </Grid>
            </React.Fragment>
        ))}
        </React.Fragment>
        )}
        </Grid>
        </React.Fragment> 
    )
}
export default DetalleOperacionGesport;

const CabeceraParteGesport = ({datosCabecera, operacion}) => {
    const { dispatch } = useContext(StateContext)   
    const [pedidoEliminar, setPedidoEliminar] = useState({Id:'', NParte:''})
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseEliminar = async (nPedido) => {    
       
        const id = nPedido;
        const url = 'operaciones/eliminapedidogesport'
        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${url}`;   
        
        await axios.post(SERVER_URL, {
            headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
            },
            id
        })
        .then((response) => {
            console.log(response.data)            
            if (response.data === id ){ 
                dispatch({type: "RELOAD_DETALLE", payload: true}) 
            }
          })
          .catch((error) => {
            console.log("error " + error);
          });
        setOpen(false);
    }

    const Eliminar = (idElemento) => {  
        
        const pedidoAEliminar = operacion.Gesport.filter(pedido => pedido.Id === idElemento)
        console.log(pedidoAEliminar)
        
        if(pedidoAEliminar.length > 0){
            setPedidoEliminar(pedidoAEliminar[0])
            setOpen(true);
        }
        
    }

    const Imprimir = async (idElemento) => {
        const fetchData = async () => {
            const URL_DATOS = process.env.REACT_APP_API_URL + "operacionesapps/generapartepdf/" + idElemento;
               
            axios.get(URL_DATOS, {})
                .then(({ data }) => {
                    const oDate = new Date();
                    window.open(data +'?qt='+oDate.getTime(), "_blank")
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response);
                 })
                 .finally(function() {
             
                 });
        };
        fetchData(); 
    }

    const calculaCosteMano = ( mano ) => {
        let costeTotal = 0
        operacion.GesportListado.map((especialidad) => (
            especialidad.map((esp) => (
            parseInt(mano) === parseInt(esp.Fk_Operacion_Gesport) ?
                costeTotal += esp.Coste * esp.Cantidad : 0
            ))
        ))
        
        return costeTotal.toFixed(2)
    }

    return (
        <React.Fragment>
            <Grid container spacing={1} className="">
                <Grid item xs={1} className="mt-2" >
                    <h6>Parte<br></br> <b>{datosCabecera['NParte']}</b></h6>                
                </Grid>
                <Grid item xs={2} className="mt-2" >
                    <h6>Fecha<br></br> <b>{datosCabecera?.Fecha_Parte?.split("-").reverse().join("-")}</b></h6>                
                </Grid> 
                <Grid item xs={1} className="mt-2" >
                    <h6>Mano<br></br> <b>{datosCabecera['ManoPlanificada'] ? datosCabecera['ManoPlanificada']['Mano'] : ""}</b></h6>
                </Grid>  
                <Grid item xs={2} className="mt-2" >
                    <h6>Coste<br></br> <b>{calculaCosteMano(datosCabecera["Id"])} {" €"}</b></h6>
                </Grid>
                <Grid item xs={2} className="mt-2" >
                    <h6>Firmado<br></br> <b>{datosCabecera.FirmaCliente ? "SÍ" : "NO"}</b></h6>
                </Grid>
                <Grid item xs={2} className="mt-2 " >                            
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {event.stopPropagation(); Eliminar(datosCabecera['Id'])}}
                        onFocus={(event) => event.stopPropagation()}
                        control={<DeleteIcon />}                            
                    /> 
                </Grid> 
                <Grid item xs={2} className="mt-2 " >                           
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => {event.stopPropagation(); Imprimir(datosCabecera['Id'])}}
                        onFocus={(event) => event.stopPropagation()}
                        control={<PdfIcon />} 
                        label={<Typography style={{marginLeft: 10}}>Ver parte</Typography>}
                                                
                    />  
                </Grid> 
            </Grid>
            
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"¿Desea eliminar el parte de Gesport "} {pedidoEliminar.NParte} {" ?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si elimina el parte no se podrá recuperar.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={() => handleCloseEliminar(pedidoEliminar.Id)} color="primary" autoFocus>
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </React.Fragment>
    )
}

const DetalleParteGesport = ({datosDetalle, operacion}) => {

    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2); 
    const numberFormat = new Intl.NumberFormat('es-ES');  

    return(
        <Grid container spacing={1} className="mt-2 ">
            
            <Grid container spacing={1} className="mt-2" >
            <Grid item xs={5} className="mt-2" ><h6>Recurso</h6></Grid>
            <Grid item xs={1} className="mt-2" ><h6>Inicio</h6></Grid>
            <Grid item xs={1} className="mt-2" ><h6>Fin</h6></Grid>
            <Grid item xs={1} className="mt-2 text-right" ><h6>Cantidad</h6></Grid>
            <Grid item xs={2} className="mt-2 text-right" ><h6>Coste ud.</h6></Grid>
            <Grid item xs={2} className="mt-2 text-right" ><h6>Coste total</h6></Grid>
                {operacion.GesportListado.map((gesportL) => (
                    gesportL.map((gesport) => (
                        gesport['Fk_Operacion_Gesport'] === datosDetalle.Id ?
                        (
                            <React.Fragment key={gesport.Id}>  
                                <Grid item xs={5} className="mt-1" >
                                    <h6>{gesport['Nombre']}</h6>
                                </Grid>    
                                <Grid item xs={1} className="mt-1" >
                                    <h6>{gesport['Hora_Inicio']}</h6>
                                </Grid>
                                <Grid item xs={1} className="mt-1" >
                                    <h6>{gesport['Hora_Fin']}</h6>
                                </Grid>
                                <Grid item xs={1} className="mt-1 text-right" >
                                    <h6>{numberFormat.format(gesport['Cantidad'])}</h6>
                                </Grid>
                                <Grid item xs={2} className="mt-1 text-right" >
                                    <h6>{numberFormat2.format(gesport['Coste'])}</h6>
                                </Grid>
                                <Grid item xs={2} className="mt-1 text-right" >
                                    <h6>{numberFormat2.format(parseFloat(gesport['Coste'])*parseFloat(gesport['Cantidad']))}</h6>
                                </Grid>
                            </React.Fragment>
                        ) : ""  
                        ))
                ))}
            </Grid>
                    
            <Grid container spacing={1} className="mt-2" >
                {datosDetalle.Adjuntos.map((adjunto) => (
                    <React.Fragment key={adjunto.Id}> 
                        <Grid item xs={4} className="mt-1" >                                    
                            <b >
                                <a 
                                    target="_blank" rel="noopener noreferrer" 
                                    href={adjunto.Ubicacion}>{adjunto.Nombre}
                                </a>
                            </b>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>

        </Grid>
    )
}