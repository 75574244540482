import React, { useState } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";


import {columnsSeleccion, columnsFormacion, columnsRecMedico, columnsEnfermedad, columnsAccidente, columnsDocumentacion,columnsEPI} from './TalentoConf'
import {botoneraSeleccion, botoneraFormacion, botoneraRecMedico, botoneraEnfermedad, botoneraAccidente, botoneraDocumentacion,botoneraEPI} from './BotonesConf'
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import TipoSeguridad from "./TipoSeguridad";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";

const Talento = (props) => {     
  const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOperacion')) || 0);
  const [idSubOperacion, setIdSubOperacion]=useState(localStorage.getItem('idSubOperacion') || "RecMed");    
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  
  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const verTabla = (idOperacion) => { 
    localStorage.setItem('idOperacion', idOperacion);   
    setIdOperacion(idOperacion)    
  }  

  const handleOperacion = (idO) =>{
    localStorage.setItem('idSubOperacion', idO);
    setIdSubOperacion(idO)
  } 
  
  let columnasCuerpoTabla = columnsSeleccion;
  let urlDatosCuerpoTabla = "rrhhseleccions";
  let urlDoubleClickCuerpoTabla = "rrhh/talento/seleccions/";
  let columnIdCuerpoTabla = "Id";
  let botonaccionCuerpoTabla = botoneraSeleccion;  

  if(idOperacion === 0){
    columnasCuerpoTabla = columnsSeleccion;
    urlDatosCuerpoTabla = "rrhhseleccions";
    urlDoubleClickCuerpoTabla = "/rrhh/talento/seleccions/";
    columnIdCuerpoTabla = "Id";
    botonaccionCuerpoTabla = botoneraSeleccion
  }  

  if(idOperacion === 1){
    columnasCuerpoTabla = columnsFormacion;
    urlDatosCuerpoTabla = "rrhhformacions";
    urlDoubleClickCuerpoTabla = "/rrhh/talento/formacion/";
    columnIdCuerpoTabla = "Id";
    botonaccionCuerpoTabla = botoneraFormacion
  } 
  
  if(idOperacion === 2){
    if(idSubOperacion === "RecMed"){
      columnasCuerpoTabla = columnsRecMedico;
      urlDatosCuerpoTabla = "rrhhssrecmedicos";
      urlDoubleClickCuerpoTabla = "talento/recmed/";
      columnIdCuerpoTabla = "Id";
      botonaccionCuerpoTabla = botoneraRecMedico
    }

    if(idSubOperacion === "Enfermedad"){
      columnasCuerpoTabla = columnsEnfermedad;
      urlDatosCuerpoTabla = "rrhhsspartesenfermedads";
      urlDoubleClickCuerpoTabla = "talento/enfermedad/";
      columnIdCuerpoTabla = "Id";
      botonaccionCuerpoTabla = botoneraEnfermedad
    }

    if(idSubOperacion === "Accidente"){
      columnasCuerpoTabla = columnsAccidente;
      urlDatosCuerpoTabla = "rrhhsspartesaccidentes";
      urlDoubleClickCuerpoTabla = "talento/accidente/";
      columnIdCuerpoTabla = "Id";
      botonaccionCuerpoTabla = botoneraAccidente
    } 

    if(idSubOperacion === "EPI"){
      columnasCuerpoTabla = columnsEPI;
      urlDatosCuerpoTabla = "rrhhuniformes";
      urlDoubleClickCuerpoTabla = "talento/uniforme/";
      columnIdCuerpoTabla = "Id";
      botonaccionCuerpoTabla = botoneraEPI
    } 
  }

  if(idOperacion === 3){
    columnasCuerpoTabla = columnsDocumentacion;
    urlDatosCuerpoTabla = "rrhhdocumentacions";
    urlDoubleClickCuerpoTabla = "talento/documentacion/";
    columnIdCuerpoTabla = "Id";
    botonaccionCuerpoTabla = botoneraDocumentacion
  } 

  return (           
      <div className="col-8 col-main-home ">     

        <SearchBar
          titulo="RRHH"
          claseCabecera = "bg-gestion"
          buscar={false}
          imagenCabecera={"6-3-1-talento.png"}
        />   
        
        <div className="bg-cuerpo p-10px">

          <div className="bg-white p-10px">

            <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Talento
              </h6>
            </div>

            <hr className="m-0 bg-comercial mb-1" />

            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">             
              <Pasos pasos={['Selección', 'Formación', 'Seguridad y Salud','Documentación talento']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
              {idOperacion===2 && ( <TipoSeguridad opera={handleOperacion} subOperacion={idSubOperacion}/> )}
             <CuerpoTablaPiezas
                columnas = {columnasCuerpoTabla}
                urlDatos = {urlDatosCuerpoTabla}
                urlDoubleClick = {urlDoubleClickCuerpoTabla}
                columnId = {columnIdCuerpoTabla}
                getFilaSeleccionada={marcarFila}
              /> 
            </div>
          
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
              <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla}
              />            
            </div>
          </div>

        </div>
          
      </div>    
  );
}
export default Talento;
