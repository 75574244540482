import React, {useRef, useState} from "react";
import axios from "axios";

import LinearProgress from "@material-ui/core/LinearProgress";

import { useStyles } from "../../../../../styles/styles";

import CuerpoTabla from "../../../../../components/Common/CuerpoTabla";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

const Localizaciones = () => {
    const classes = useStyles();
    const entidadEliminar = useRef(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [abrirDialogo, setAbrirDialogo] = useState(false)
    const urlDatos = "localizacions"
    const BOTONERA_ACCIONES = [
        { etiqueta: "Crear Localización", url: "/crm/localizacion", activo: true },
        { etiqueta: "Editar Localización", url: "/crm/localizaciones/filaSeleccionada/W", activo: false, }, 
        { etiqueta: "Eliminar Localización", function: (e) => eliminarEntidad(e), activo: false },
    ]; 

    const eliminarEntidad = (id) => {
        entidadEliminar.current = id       
        setAbrirDialogo(true)
    }

    const handleCerrarDialogo = (valor) => {       
        setAbrirDialogo(false)
        if(valor){    
          const fetchData = async () => {  
            setIsLoading(true)
            let user = JSON.parse(localStorage.getItem("user"));
            const AuthStr = "Bearer ".concat(user.access_token);
            const SERVER_URL = process.env.REACT_APP_API_URL + 'localizacions';
            await axios
            .delete(SERVER_URL + "/" + entidadEliminar.current, {
              headers: {        
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },        
            })
            .then((response) => {         
                
              if(!isNaN(response.data)){ 
                if(response.data === entidadEliminar.current){
                  entidadEliminar.current = -1 
                  setIsLoading(false)
                  //window.location.reload();
                }          
              } else{
                alert(response.data);  
                setIsLoading(false)
              }
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
              setIsLoading(false)
            });  
          }
          fetchData();
        }      
    }

    return (
        <>
        { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la localización?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
        {!isLoading && (
            <CuerpoTabla
                tituloSearchBar = "CRM"
                claseBGSearchBar = "bg-comercial"
                titulo="Localizaciones"
                columnas = {COLUMNS_LOCALIZACIONES}
                botonera = {BOTONERA_ACCIONES}
                urlDatos ={urlDatos}
                urlDoubleClick = "/crm/localizaciones/"
                columnId="Id"
                imagenCabecera={"4-1-3-actividades.png"}
            />
        )}
        {isLoading && 
      <div className={classes.linear}>
            <LinearProgress />
      </div>
    }
        </>
       
    )  


};

export default Localizaciones;

const COLUMNS_LOCALIZACIONES = [
  {
    name: "Id",
    label: "Id",
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
     }
  },
  {
    name: "Codigo",
    label: "Código",
    options: {
     filter: true,
     sort: true,
    }
   },
    {
     name: "Etiqueta",
     label: "Nombre",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "Activo",
      label: "Activo",
      options: {
       filter: true,
       sort: true,
       display: 'excluded',
      }
     },   
];