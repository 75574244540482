import React, {useState, useEffect} from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useParams } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const FormFieldTextCIF = ({ propiedades, control }) => { 
    const { modo } = useParams();
    const [valor, setValor] = useState("")
    const [errorCif, setErrorCif] = useState({error: false, mensaje: ""})

    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )
    useEffect(() => {        
        setValor(propiedades.value)
        return () => {
            setValor("")
        }
    }, [])

    const isValidCif = (cif) => {
        if (!cif || cif.length !== 9) {
            return false;
        }
    
        var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
        var digits = cif.substr(1, cif.length - 2);
        var letter = cif.substr(0, 1);
        var control = cif.substr(cif.length - 1);
        var sum = 0;
      var i;
        var digit;
    
        if (!letter.match(/[A-Z]/)) {
            return false;
        }
    
        for (i = 0; i < digits.length; ++i) {
            digit = parseInt(digits[i]);
    
            if (isNaN(digit)) {
                return false;
            }
    
            if (i % 2 === 0) {
                digit *= 2;
                if (digit > 9) {
                    digit = parseInt(digit / 10) + (digit % 10);
                }
    
                sum += digit;
            } else {
                sum += digit;
            }
        }
    
        sum %= 10;
        if (sum !== 0) {
            digit = 10 - sum;
        } else {
            digit = sum;
        }
    
        if (letter.match(/[ABEH]/)) {
            return String(digit) === control;
        }
        if (letter.match(/[NPQRSW]/)) {
            return letters[digit] === control;
        }
    
        return String(digit) === control || letters[digit] === control;
    }

    const isValidNif = (nif) => {
        if (!nif || nif.length !== 9) {
            return false;
        }

        var firstLetter = nif.substr(0, 1);
        //console.log(firstLetter)
        if (!firstLetter.match(/[0-9KLMXYZ]/)) {
            return false;
        }

        let dc =  nif.substr(8, 1);
        //console.log(dc)
        let number = 0;
        if(isNaN(firstLetter))
            number = nif.substr(1, 7);
        else
            number = nif.substr(0, 8);
        //console.log(number)
        let lettersOrder ='TRWAGMYFPDXBNJZSQVHLCKET';
        if(lettersOrder[number % 23] === dc.toUpperCase()) {
            return true;
        }
    }

    const handleChange = (value) => {
        if(isValidCif(value) || isValidNif(value)){
            setErrorCif({error: false, mensaje: ""})
        }
        else{ 
            setErrorCif({error: true, mensaje: "CIF no válido"})
        }
        setValor(value)
    }

    return ( 
    <Hidden  xlDown = {propiedades.hidden ? true : false } >              
    <Grid item xs={propiedades.xs} className={propiedades.classes} >
    
        <Controller
            control={control}
            name={propiedades.name}
            defaultValue={valor}            
           
            render={({ onChange }) => (         
                <TextField
                    error = {errorCif.error}
                    required = {propiedades.required}
                    id={propiedades.name}
                    name={propiedades.name}
                    disabled={modo==="R" || propiedades.disabled ?true:false}
                    label={propiedades.label}
                    variant="outlined"
                    size="small"            
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={valor}
                    helperText={errorCif.mensaje}
                    //onChange={data => handleChange(data.target.value)  }
                    onChange={(e) => {
                    handleChange(e.target.value)
                    onChange(e.target.value)                    
                  }}   
                />
            )}
          />
        
        
        
        {/*     
    <Controller                        
        as={TextField} 
        required = {propiedades.required}
        control={control}
        id={propiedades.name}
        name={propiedades.name}
        disabled={deshabilitado} //{propiedades.disabled ? true : modo==="R"  ? true : false}
        label={propiedades.label}
        defaultValue={propiedades.value}
        variant="outlined"
        size="small"
        fullWidth
        InputLabelProps={{
            shrink: true,
        }}
        onChange={(e, data) => {
            console.log(data)
              //handleChange(data)              
              //onChange(data)
              }}
        >
        
       </Controller>   
       */}     
    </Grid>  
    </Hidden>     
    )
}
export default FormFieldTextCIF;