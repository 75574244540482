const COLUMNS_PROYECTOS = [
  {
    name: "Id",
    label: "Id",
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
     }
  },
    {
     name: "Codigo",
     label: "Codigo",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "Estado",
      label: "Estado",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Nombre",
      label: "Nombre",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
     name: "Tipo",
     label: "Tipo",
     options: {
      filter: true,
      sort: true,
     }
    },    
    {
     name: "Cuentas",
     label: "Cuentas",
     options: {
      filter: true,
      sort: true,
     }   
    },
    {
      name: "Contactos",
      label: "Contactos",
      options: {
       filter: true,
       sort: true,
      }   
    }, 
    {
      name: "Empleados",
      label: "Empleados",
      options: {
       filter: true,
       sort: true,
      }   
    }, 
    {
      name: "Oportunidades",
      label: "Oportunidades",
      options: {
       filter: true,
       sort: true,
      }   
    },
    {
      name: "Fecha_Cierre",
      label: "Fecha Cierre",
      options: {
       filter: true,
       sort: true,
      }   
    },   
];
export {COLUMNS_PROYECTOS};
