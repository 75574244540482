import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import { useHistory } from "react-router";
import { CircularProgress } from '@material-ui/core';

import FormularioFacturaDeCompra from "./FormularioFacturaDeCompra";
import MigasDePan  from "../../../../../components/Common/MigasDePan";

const FacturaDeCompra = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory(); 
    const [datos, setDatos] = useState();

    useEffect(() => {
      if (modo==="R") {
        history.push({
          pathname: `/administracion/compras/facturascompras/${id}/W`,       
        });
      }
  },[])

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respFacturaCompra = await axios( process.env.REACT_APP_API_URL + "facturascompras/" + id);
            if (respFacturaCompra.data) {     
              setDatos(respFacturaCompra.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const botoneraAcciones = [    
      {
        etiqueta: modo === "R" ? "Editar Factura" : "Cancelar",
        url: 
        typeof id === "undefined" ? `/administracion/compras` :
        modo === "R" ? 
          `/administracion/compras/facturascompras/${id}/W` : 
          `/administracion/compras`,
        activo: true,
      },
      {
        etiqueta: "Guardar",
        form:'formulario-FacturaCompra',
        activo: modo === "R" ? true : true,
      }  ,
    ];

    const urlsMigasdepan = [
      {titulo: "Facturas de compra", colorTexto: "texto-comercial", urlTo: "/administracion/compras"},
      {titulo: "Factura de compra", colorTexto: "texto-comercial", urlTo: `/administracion/compras/ordenescompra/${id}/${modo}`},
    ]

    const codigo = typeof datos !== "undefined" ? datos.codigo : "";

    return (        
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Administración"
              claseCabecera = "bg-gestion"
              buscar={false}
              imagenCabecera={"6-1-1-compras.png"}
            />
    
            {!isLoading && (              
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                    <div className="principal bg-white cuerpo-h pr-2">   
                        <FormularioFacturaDeCompra />                           
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>              
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        
      );

}
export default FacturaDeCompra;