import axios from "axios";
import { API_REQUEST, apiError, apiSuccess, apiRecoveryPassword } from "../actions/api";
import { setLoader } from "../actions/ui";

export const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);
  //console.log(action)
  if (action.type === API_REQUEST) {
    dispatch(setLoader({ state: true }));
    const { url, method } = action.meta;

    axios({
      method,
      url,
    })
      .then(({ data }) => {       
        
        if(data.codigo){
          if(data.codigo === "error"){
            dispatch(
              apiError({
                error: { message: data.mensaje },
              })
            ); 
          }

          if(data.codigo === "success"){
            dispatch(
              apiRecoveryPassword({
                mensaje: {message: data.mensaje},
              })
            ); 
          }
        }
        
        if (data.access_token) {
          dispatch(apiSuccess({ response: data }));
        }
        if (data.password) {
          dispatch(
            apiError({
              error: { message: "Usuario o Contraseña incorrectos."},
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        dispatch(apiError({ error: error.response.data }));
      });
  }
};
