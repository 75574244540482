import React, { useState, useEffect } from "react";
import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export default function FiltroListadoAuditoria({urlBase, setFechas, firstDay, lastDay}) { 

  const useStyles = makeStyles((theme) => ({
    root: {
    flexGrow: 1,
    },
  }));

  const classes = useStyles();
  const[fechaInicio, setFechaInicio]=useState(firstDay);
  const[fechaFin, setFechaFin]=useState(lastDay);    
      
  const StyledButton = withStyles((theme) => ({
    root: {
      color: 'black',
      backgroundColor: '#c4c4c4',
      height: 32,
      marginLeft: -3,
      marginTop: 0, 
      '&:hover': {
        backgroundColor: '#777777',
      },
    },
  }))(Button);
      
  useEffect(() => {
    setFechaInicio(firstDay)
    setFechaFin(lastDay)
  }, [])  

  const handleChangeFechaInicio = (valor) => {
    setFechaInicio(valor)
  }

  const handleChangeFechaFin = (valor) => {
    setFechaFin(valor)
  }

  const handleGetFecha = async () => {    
    setFechas([fechaInicio, fechaFin])
  }

  let propiedadesFechaInicio = {
    required: true,
    label: "Fecha Inicio",
    name: "fechaInicio",
    type: "date",    
    value: fechaInicio || firstDay,
    xs: 12
  }
      
  let propiedadesFechaFin = {
    required: true,
    label: "Fecha Fin",
    name: "fechaFin",
    type: "date",    
    value: fechaFin || lastDay,
    xs: 12
  }

  return(
    <Grid container spacing={1} className="">
    
      <Grid item xs={5} className="" >
        <FormFieldDateUncontrolled propiedades={propiedadesFechaInicio} onChangeValue={handleChangeFechaInicio} />
      </Grid> 
      <Grid item xs={5} className="" >   
        <FormFieldDateUncontrolled propiedades={propiedadesFechaFin} onChangeValue={handleChangeFechaFin} />
      </Grid>
      <Grid item xs={1} className="" >
        <StyledButton size="small" className={classes.margin}
          onClick={handleGetFecha}>
          Filtrar
        </StyledButton>
      </Grid>
      
    </Grid>
  )
}
