import React from "react";
import moment from "moment";
import Opciones from "../../Comercial/Ventas/Opciones";

const SPACED_DATE_FORMAT = "DD/MM/YYYY";

let columnsFacturasVentas = [
    {
        name: "Id",
        label: "Nº",
        options: {
            filter: false,
            sort: true,
            sortCompare: (order) => {
                return (obj1, obj2) => {          
                    let val1 = parseFloat(obj1.data, 10);
                    let val2 = parseFloat(obj2.data, 10);
                    return (val1 - val2) * (order === 'asc' ? 1 : -1);
                };
            }
        }
    }, 
    {
        name: "tipo",
        label: "Tipo",
        options: {
         filter: false,
         sort: true,
         display: 'excluded',
        }
    }, 
    {
        name: "Fecha_Factura",
        label: "Fecha Factura",
        options: {
            filter: false,
            sort: true,
            customBodyRender: value =>
                moment(new Date(value)).format(SPACED_DATE_FORMAT)      
        }
    },  
    {
        name: "Codigo",
        label: "Cod Factura",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "Cliente",
        label: "Cliente",
        options: {
            filter: true,
            sort: true,       
        }   
    }, 
    {
        name: "Base_Imponible",
        label: "Base imponible",
        options: {
          filter: false,
          sort: true,  
          sortCompare: (order) => {
            return (obj1, obj2) => {            
              let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
              let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }          
        }   
      }, 
      {
        name: "Total_Impuestos",
        label: "Impuestos",
        options: {
          filter: false,
          sort: true,   
          sortCompare: (order) => {
            return (obj1, obj2) => {            
              let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
              let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }       
        }   
      }, 
      {
        name: "IRPF",
        label: "IRPF",
        options: {
          filter: false,
          sort: true,  
          sortCompare: (order) => {
            return (obj1, obj2) => {            
              let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
              let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }       
        }   
      }, 
    {
        name: "Total_Factura",
        label: "Importe",
        options: {
            filter: false,
            sort: true,   
            sortCompare: (order) => {
                return (obj1, obj2) => {            
                    let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
                    let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
                    return (val1 - val2) * (order === 'asc' ? 1 : -1);
                };
            },                
        }   
    }, 
]
export {columnsFacturasVentas};

let columnsFacturasCompras = [
    {
        name: "Id",
        label: "Nº",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "fechaFactura",
        label: "Fecha",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "NumFactura",
        label: "Factura",
        options: {
            filter: true,
            sort: true,
        }
    }, 
    {
        name: "proveedor",
        label: "Proveedor",
        options: {
           filter: true,
           sort: true,
        }
    },
    {
        name: "baseImponible",
        label: "Base imponible",
        options: {
         filter: true,
         sort: true,
        }
      },
      {
        name: "importeIgic",
        label: "Impuestos",
        options: {
         filter: true,
         sort: true,
        }
      },  
      {
        name: "irpfImporte",
        label: "IRPF",
        options: {
         filter: true,
         sort: true,
        }
      }, 
    {
        name: "importe",
        label: "Importe",
        options: {
           filter: true,
           sort: true,
        }
    },
]
export {columnsFacturasCompras}
/*
const actualizaValor = (valor, indice) => {
    console.log(valor, indice)
    
    const fetchData = async () => {
      setIsLoading(true)
      await axios.get( 
        process.env.REACT_APP_API_URL + "actividads/actualizaestadoactividad/" + indice,
        {params: { estado: valor}}
      );
      setIsLoading(false);
    };
    fetchData();
    
}
*/
  