import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from "../../../../../components/Common/FormFieldCombo";
import FormFieldNumber from "../../../../../components/Common/FormFieldNumber";
import FormFieldComboArray from "../../../../../components/Common/FormFieldComboArray";
import FormFieldText from "../../../../../components/Common/FormFieldText";

import { useForm } from "react-hook-form";

const propiedadesArticulo = {
    urlData: "materials",
    campoEtiqueta: "Nombre",
    campoId: "id",
    required: false,
    name: "descripcion",
    disabled: false,
    label: "Materiales",
    value: "",
    multiple: false,
    xs:4,
}

const propiedadesCantidad = {
    xs:2,
    required: false,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    value: ''
}

const propiedadesPrecioUnidad = {
    xs:2,
    required: false,
    label: "Precio .Ud",
    name: "precio",
    type: "number",    
    value: ''
}

const propiedadesIgic = {
    urlData: "dic/diccionarios/igic",
    campoEtiqueta: "Etiqueta,Valor",
    campoId: "Id",
    required: false,
    name: "igic",
    disabled: false,
    label: "Igic",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesIrpf = {
    urlData: "dic/diccionarios/irpf",
    campoEtiqueta: "Etiqueta,Valor",
    campoId: "Id",
    required: false,
    name: "irpf",
    disabled: false,
    label: "Irpf",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesProveedor = {
    urlData: "cuentas/proveedores",
    campoEtiqueta: "Nombre_Comercial",
    campoId: "Id",
    required: false,
    name: "proveedor",
    disabled: false,
    label: "Proveedor",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesDeposito = {
    urlData: "depositos",
    campoEtiqueta: "nombre",
    campoId: "id",
    required: false,
    name: "deposito",
    disabled: false,
    label: "Almacén",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesServicio = {
    xs:4,
    required: false,
    label: "Servicio",
    name: "servicio",
    type: "rext",    
    value: ''
}

const propiedadesProducto = {
    xs:4,
    required: false,
    label: "Producto",
    name: "producto",
    type: "rext",    
    value: ''
}

const FormularioTablaHojaDePedidos = ({listadotabla}) => {

    const { control, setValue, getValues} = useForm();
    const [ tipoPedido, setTipoPedido ] = useState({Id: 'Materiales', Etiqueta: 'Materiales'})
    
    const handleChangeTipo = (valor) => {
        setTipoPedido(valor)
    }
    
    const propiedadesTipoHojaPedido = {
        campoEtiqueta: "Etiqueta",
        required: false,
        label: "Tipo de pedido",
        name: "tipologia",
        disabled: false,
        type: "comboArray", 
        options:[{Id: 'Servicios', Etiqueta: 'Servicios'}, {Id: 'Materiales', Etiqueta: 'Materiales'}, {Id: 'Productos', Etiqueta: 'Productos'}], 
        multiple:false,   
        value: tipoPedido,
        onChangeValue: handleChangeTipo,
        xs:2
    } 

    const handleClick = () => {  
        if(tipoPedido){

            const material = getValues("descripcion")
            const servicio = getValues("servicio") 
            const producto = getValues("producto")
            const cantidad = getValues("cantidad")
            const precio = getValues("precio")
            const proveedor = getValues("proveedor")
            const deposito = getValues("deposito")
            const igic = getValues("igic")
            const irpf = getValues("irpf")

            const esMaterial = (typeof material === "undefined" || material.Id === -1 ) ? false : true;
            const idMaterial = esMaterial === true ? material.id : -1
            const eanMaterial = esMaterial === true ? material.ean : (servicio ? 'servicio' : 'producto')

            const nombreMaterial = esMaterial === true ? material.Nombre : (servicio ? servicio : producto)
            const unidadMaterial = esMaterial === true ? material.tipoUnidad : ''

            let errores = 0;
            let erroresMsg = "";

            if(!tipoPedido) {errores++; erroresMsg += "Debe seleccionar un tipo de pedido\n";}
            if(!nombreMaterial) {errores++; erroresMsg += "Debe seleccionar un material producto, o servicio\n";}
            if(!cantidad) {errores++; erroresMsg += "Debe intriducir una cantidad\n";}
            if(!precio) {errores++; erroresMsg += "Debe intriducir un precio unitario\n";}
            if(!igic) {errores++; erroresMsg += "Debe seleccionar un tipo de IGIC\n";}        
            if(!deposito) {errores++; erroresMsg += "Debe seleccionar un centro de coste\n";}
            
            if(errores > 0){
                alert(erroresMsg)
                return;
            }

            listadotabla({
                id:uuidv4(),
                idArticulo:idMaterial,
                idProveedor:proveedor.Id,
                idDeposito:deposito.id,
                idIgic: igic ? igic.Id : null,
                idIrpf: irpf ? irpf.Id : null,
                codigo: eanMaterial,
                descripcion: nombreMaterial,
                unidad: unidadMaterial,
                cantidad:cantidad,
                precioUnidad:precio,
                precioTotal:cantidad*precio,
                proveedores:proveedor.Razon_Social,
                depositos:deposito.nombre,
                igic: igic ? igic.Valor : null,
                irpf: irpf ? irpf.Valor : null,
            })

            setValue("descripcion", "")
            setValue("cantidad", "")
            setValue("precio", "")
            setValue("igic", "")      
            setValue("irpf", "") 
            setValue("servicio", "")   
            setValue("deposito", "")   
        }
    }

    const esMaterial = tipoPedido && tipoPedido.Id === "Materiales" 
    const esServicio = tipoPedido && tipoPedido.Id === "Servicios"
    const esProducto = tipoPedido && tipoPedido.Id === "Productos"
    


    return (
    <>
    <Grid container  spacing={1} className="mt-2 borderFormulario">        
        
        <FormFieldComboArray propiedades={propiedadesTipoHojaPedido} control={control} />
        
        { esMaterial && (
        <FormFieldCombo propiedades={propiedadesArticulo} control={control}/> )}
        { esServicio && (
        <FormFieldText propiedades={propiedadesServicio} control={control}/>)}
        { esProducto && (
        <FormFieldText propiedades={propiedadesProducto} control={control}/>)}
        
        <FormFieldCombo propiedades={propiedadesProveedor} control={control}/>
        <FormFieldCombo propiedades={propiedadesDeposito} control={control}/>
    

        <FormFieldNumber propiedades={propiedadesCantidad} control={control}/> 
        <FormFieldNumber propiedades={propiedadesPrecioUnidad} control={control}/>
        <FormFieldCombo propiedades={propiedadesIgic} control={control}/>
        <FormFieldCombo propiedades={propiedadesIrpf} control={control}/>
        
        <Grid item xs={2} className="" >
        <Button className="" variant="contained" color="primary" component="span" onClick={handleClick}>
            AÑADIR
        </Button> 
        </Grid>

    </Grid>
    </>
    )
}
export default FormularioTablaHojaDePedidos;

/*
import axios from "axios";
useEffect(() => {
        (async () => {
            const respArticulo = await axios(process.env.REACT_APP_API_URL + propiedadesArticulo.urlData);
            //setArticulo(Object.keys(respArticulo.data).map((key) => respArticulo.data[key]))
            
            const respProveedor = await axios(process.env.REACT_APP_API_URL + propiedadesProveedor.urlData);
            //setProveedor(Object.keys(respProveedor.data).map((key) => respProveedor.data[key]))

            const respDeposito = await axios(process.env.REACT_APP_API_URL + propiedadesDeposito.urlData);
            //setDeposito(Object.keys(respDeposito.data).map((key) => respDeposito.data[key]))
          })();
    }, [])  

import { StateContext } from '../../../../../context'

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';


const [openArticulo, setOpenArticulo] = useState(false)
    const [openProveedor, setOpenProveedor] = useState(false)
    const [openDeposito, setOpenDeposito] = useState(false)
    const [selectedArticulo, setSelectedArticulo] = useState("")
    const [selectedProveedor, setSelectedProveedor] = useState("")
    const [selectedDeposito, setSelectedDeposito] = useState("")
    

const [Proveedor, setProveedor] = useState([]);
    const [Articulo, setArticulo] = useState([]);
    const [deposito, setDeposito] = useState([]);
    
const [cantidad, setCantidad] = useState(0)
    const [precio, setPrecio] = useState(0)
    const loading = openArticulo && Articulo.length === 0;
    const loading2 = openDeposito && deposito.length === 0;
    const loading3 = openProveedor && Proveedor.length === 0;


const getOptionLabelArticulo = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesArticulo.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionLabelProveedor = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesProveedor.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionLabelDeposito = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesDeposito.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedArticulo = (option, value) => {
        console.log(option, value)
        if( option.Id === value.Id ) 
        {
            setSelectedArticulo(value)
        }
        return option.Id === value.Id
    }

    const getOptionSelectedProveedor = (option, value) => {
        console.log(option, value)
        if( option.Id === value.Id ) 
        {
            setSelectedProveedor(value)
        }
        return option.Id === value.Id
    }

    const getOptionSelectedDeposito = (option, value) => {
        console.log(option, value)
        if( option.Id === value.Id ) 
        {
            setSelectedDeposito(value)
        }
        return option.Id === value.Id
    }

    
    const handleChangeArticulo = (e, v) => { 
        console.log(v)
        setSelectedArticulo(v)
    }

    const handleChangeProveedor = (e, v) => { 
        setSelectedProveedor(v)
    }

    const handleChangeDeposito = (e, v) => {         
        setSelectedDeposito(v)
    }

    const handleChangeCantidad = (event) => { 
        setCantidad(event.target.value)
    }

    const handleChangePrecio = (event) => { 
        setPrecio(event.target.value)
    }

    const renderInputArticulo = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesArticulo.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const renderInputProveedor = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesProveedor.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading3 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const renderInputDeposito = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesDeposito.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading2 ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

 
        setSelectedArticulo("");
        setSelectedDeposito("");
        setSelectedProveedor("");
        setPrecio(0);
        setCantidad(0);
        
<Grid item xs={propiedadesArticulo.xs} className="">  
        <Autocomplete
            id={propiedadesArticulo.name}
            name={propiedadesArticulo.name}
            getOptionLabel= { getOptionLabelArticulo }
            getOptionSelected={(option, value) => getOptionSelectedArticulo(option, value)}
            open={openArticulo}
            loadingText="Cargando..."
            onOpen={() => { setOpenArticulo(true); }}
            onClose={() => { setOpenArticulo(false); }}   
            loading={loading}
            label={propiedadesArticulo.label}        
            renderInput={renderInputArticulo}
            onChange={(e, v, r) => handleChangeArticulo(e, v, r)}  
            options={Articulo}
            value={selectedArticulo}
            style={{  marginLeft: 0 }}
        /> 
        </Grid>
 <Grid item xs={propiedadesCantidad.xs} className="">
        <TextField                 
            required = {propiedadesCantidad.required}                
            id={propiedadesCantidad.name}
            name={propiedadesCantidad.name}
            disabled = {propiedadesCantidad.disabled}
            label={propiedadesCantidad.label}        
            value={cantidad} 
            type="number"
            onChange={handleChangeCantidad} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField>      
    </Grid>
<Grid item xs={propiedadesPrecioUnidad.xs} className="">
        <TextField                 
            required = {propiedadesPrecioUnidad.required}                
            id={propiedadesPrecioUnidad.name}
            name={propiedadesPrecioUnidad.name}
            disabled = {propiedadesPrecioUnidad.disabled}
            label={propiedadesPrecioUnidad.label}        
            value={precio} 
            type="number"
            onChange={handleChangePrecio} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField>      
    </Grid>
 <Grid item xs={propiedadesProveedor.xs} className="">              
        <Autocomplete
            id={propiedadesProveedor.name}
            name={propiedadesProveedor.name}
            getOptionLabel= { getOptionLabelProveedor }
            getOptionSelected={(option, value) => getOptionSelectedProveedor(option, value)}
            open={openProveedor}
            loadingText="Cargando..."
            onOpen={() => { setOpenProveedor(true); }}
            onClose={() => { setOpenProveedor(false); }}   
            loading={loading}
            label={propiedadesProveedor.label}        
            renderInput={renderInputProveedor}
            onChange={(e, v, r) => handleChangeProveedor(e, v, r)}  
            options={Proveedor}
            value={selectedProveedor}
            style={{  marginLeft: 0 }}
        />            
    </Grid>

    <Grid item xs={propiedadesDeposito.xs} className="">       
        <Autocomplete
            id={propiedadesDeposito.name}
            name={propiedadesDeposito.name}
            getOptionLabel= { getOptionLabelDeposito }
            getOptionSelected={(option, value) => getOptionSelectedDeposito(option, value)}
            open={openDeposito}
            loadingText="Cargando..."
            onOpen={() => { setOpenDeposito(true); }}
            onClose={() => { setOpenDeposito(false); }}   
            loading={loading2}
            label={propiedadesDeposito.label}        
            renderInput={renderInputDeposito}
            onChange={(e, v, r) => handleChangeDeposito(e, v, r)}  
            options={deposito}
            value={selectedDeposito}
            style={{  marginLeft: 0 }}
        />   
    </Grid>
    


*/