import React, { useState, useRef } from "react";
import axios from "axios";

import TableAcciones from "../../../../../components/Common/TableAcciones";
//import { botoneraPresupuesto } from './BotonesConf';
//import FormularioPresupuestoPR from "./FormularioPresupuestoPR";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";
import CuentasPyGManuales from "./CuentasPyGManuales";
import TablaCuentaExplotacion from "./TablaCuentaExplotacion";
import TablaPeriodificacion from "./TablaPeriodificacion";

import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'


const Explotacion = (props) => {
  const [idOperacion, setIdOperacion] = useState(parseInt(localStorage.getItem('idOpExplotacion')) || 0);
  const [listado, setListado] = useState({})
  const urlFechas = useRef(''); 

  const [openAlert,setOpenAlert] = useState({
    open : false,
    tipoMensaje : "success",
    mensaje : ""
  });  

  const verTabla = (idOperacion) => {
    localStorage.setItem('idOpExplotacion', idOperacion);
    setIdOperacion(idOperacion)
  }
  let botoneraPresupuesto = []
  if(idOperacion === 0)
  {
    botoneraPresupuesto = [
      {
        etiqueta: "Guardar",
        function: (e) => actualizarPyG(e), 
        activo: idOperacion === 0
      },
    ];
  }
  if(idOperacion === 1)
  {
    botoneraPresupuesto = [
      {
        etiqueta: "Imprimir",
        function: (e) => imprimirCuentaExplotacion(e), 
        activo: idOperacion === 1
      },
    ];
  }
  if(idOperacion === 2)
  {
    botoneraPresupuesto = [
      {
        etiqueta: "Imprimir",
        function: (e) => imprimirPeriodificacion(e), 
        activo: idOperacion === 2
      },
    ];
  }

  const imprimirCuentaExplotacion = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = process.env.REACT_APP_API_URL + "dic/diccionarios/imprimircuentaexplotacion" + urlFechas.current
    const res = await axios.get(
      SERVER_URL 
      /*, 
      { headers: {
          "Content-Type": "application/json",
          Authorization: AuthStr,
      }              
      },
      {params: { estado: ''}} 
      */
      );
    //console.log(res)
    //console.log(res.data)
    if(res.status === 200)
      window.open(res.data, "_blank") 
  }

  const imprimirPeriodificacion = async () => {
    console.log('inprimirCuentaExplotacion')
    console.log(urlFechas.current)
    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);

    const SERVER_URL = process.env.REACT_APP_API_URL + "dic/diccionarios/imprimirperiodificacion" + urlFechas.current
    console.log(SERVER_URL)  
    const res = await axios.get(
      SERVER_URL 
      /*, 
      { headers: {
          "Content-Type": "application/json",
          Authorization: AuthStr,
      }              
      },
      {params: { estado: ''}} 
      */
      );
    console.log(res)
    console.log(res.data)
    if(res.status === 200)
      window.open(res.data, "_blank") 
  }

  const actualizarPyG = (e) => {
    const fetchData = async () => {     
        
      let datos = listado
      const user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}diccuentapygdatos`;
      
      datos.username=user.username
      datos.userid=user.id

      //console.log(SERVER_URL)
      //console.log(datos)

    axios
    .post(SERVER_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthStr,
      },
      datos,
    })
    .then((response) => {            
      //console.log(response.data);
            
      if((response.data) === 0){
        setOpenAlert({
          open : true,
          tipoMensaje : "success",
          mensaje : "Datos actualizados correctamente"
        }) 
      }else{  
          setOpenAlert({
            open : true,
            tipoMensaje : "error",
            mensaje : "Error al actualizar los datos"
          }) 
      }
      return;
    })
    .catch((error) => {
      console.log("error " + error);
    });
    

  }
  fetchData();
  }

  const handleDatos = (datos) =>
  {
    //console.log(datos)
    setListado(datos)
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert({...openAlert,open:false});
  }; 

  const handleUrlFechas = (fechas) => {
    urlFechas.current = fechas
  }


  return (
    <div className="col-main-home">
      <SearchBar
        titulo="Finanzas"
        claseCabecera="bg-gestion"
        buscar={false}
        imagenCabecera={"6-2-2-cuenta_explotacion.png"}
      />

      <div className="bg-cuerpo p-10px">
        <div className="bg-white p-10px">
          <div className="p-1 bg-white mb-3">
            <h6 className="texto-comercial float-left" id="title">
              Explotación
            </h6>
          </div>
          <hr className="m-0 bg-comercial mb-1" />
          <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
            <Pasos pasos={['Cuentas', 'Cuenta Explotación', 'Periodificación']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
            {/* 
            <FormularioPresupuestoPR listadoDatos={listado} />
            */}
            { idOperacion === 0 && (<CuentasPyGManuales listadoDatos={handleDatos}/>) }
            { idOperacion === 1 && (<TablaCuentaExplotacion urlFechas={handleUrlFechas}/>) }
            { idOperacion === 2 && (<TablaPeriodificacion urlFechas={handleUrlFechas}/>) }
            
          </div>
          {openAlert && 
              <AlertaMensaje 
                    mensaje={openAlert.mensaje} 
                    isOpen={openAlert.open} 
                    tipoMensaje={openAlert.tipoMensaje}
                    cerrar={handleCloseAlert}
                />
          }
        </div>

        <div className="bg-cuerpo">
          <div className="bg-white">
            <TableAcciones botonaccion={botoneraPresupuesto} />
          </div>

        </div>
      </div>

    </div>

  );
}
export default Explotacion;

