import { useState } from "react";
import axios from "axios";

const useAxios = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  let user = JSON.parse(localStorage.getItem("user"));
  
  const deleteData = async (url) => {
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = `${process.env.REACT_APP_API_URL}${url}`;
    setLoading(true)
    await axios
    .delete(SERVER_URL, {
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },        
    })
    .then((response) => {         
        if(!isNaN(response.data)){ 
          setData(response.data)
          setLoading(false)            
        } else{
          setData(response.data)
          setLoading(false)
        }
    })
    .catch((error) => {
        console.log("error " + error);
        console.log(error?.response?.data); // => the response payload 
        console.log(error?.response?.data?.message)
        console.log({'data': '', 'error': error.message, 'loaded': true})
        setError(error?.response?.data?.message)
        setLoading(false)
    })
  };

  const getData = async (url) => {
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = `${process.env.REACT_APP_API_URL}${url}`;
    setLoading(true)
    await axios
    .get(SERVER_URL, {
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },        
    })
    .then((response) => {         
        if(!isNaN(response.data)){ 
          setData(response.data)
          setLoading(false)            
        } else{
          setData(response.data)
          setLoading(false)
        }
    })
    .catch((error) => {
        console.log("error " + error);
        console.log(error?.response?.data); // => the response payload 
        console.log(error?.response?.data?.message)
        console.log({'data': '', 'error': error.message, 'loaded': true})
        setError(error?.response?.data?.message)
        setLoading(false)
    })
  };

  return { data, loading, error, getData, deleteData}
}

export default useAxios;