import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
//Ficha entidad general para reutilizar en cada ficha
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaAlmacen = () => {
    let { id } = useParams();

    const [almacen, setAlmacen] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respAlmacen = await axios(`${process.env.REACT_APP_API_URL}almacens/${id}`);    
          setAlmacen(respAlmacen.data)  
        };
        fetchData();  
    }, [id]);
    //Recogemos la fecha por dia/mes+1/año
    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    } 

    //Imprimimos los datos 
    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(almacen).length > 0){  
        
            setCampos([            
            {
                classsLabel: "",
                label: "Fecha Creacion:",
                value: almacen.fechaCreacion,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Tamaño(m2):",
                value: almacen.superficie,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Nombre depósito:",
                value: almacen.nombre,
                classText: classText,
                xs:4
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {
                classsLabel: "",
                label: "Estado:",
                value: almacen.estado.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Tipología:",
                value: almacen.tipologia.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {
                classsLabel: "",
                label: "Nº Áreas horizontal:",
                value: almacen.areasHorizontal,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Nº Áreas Vertical:",
                value: almacen.areasVertical,
                classText: classText,
                xs:4
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: almacen.observaciones,
                classText: classText,
                xs:12
            },
            {
                //Cada 3 label con datos hacemos un salto de línea
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
                ///////////////////////////////////////////////////
            },
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: almacen.ficheros,
                 classText: classText,
                 type: "file",
                 xs:4
                },
            
        ])
    }
},[almacen]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    //Es otro urlClick donde redirige para editar
    urlClick = {`/operativas/almacenamiento/almacen/${id}/W`}
    />
    </>
  )
}
export default FichaAlmacen;