import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioFormacion = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [reconocimiento, setReconocimiento] = useState([]) 


    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 
        setCampos([  
            {
                urlData: "dic/diccionarios/tipologiasreconocimientosmedicosrrhh",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Tipologia:",
                name: "tipologia",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },         
            {
                required: true,
                label: "Fecha:",
                name: "fechaAlta",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:6
            },
            {
                urlData: "dic/diccionarios/listadoempleadosactivos",
                campoEtiqueta: "nombre",
                required: true,
                label: "Trabajador:",
                name: "empleado",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            {
                required: false,
                label: "Realizado",
                name: "realizado",
                type: "switch",              
                value: "" ,
                xs:6
              },
            {
                required: true,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",
                lineas: 4,    
                disabled: false, 
                value: "",                
                xs:12
            },  
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_rrhh_reconocimiento_medico_adjuntos",
                xs:12
              },            
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: reconocimiento.ficheros,    
                tabla: "mtms_rrhh_reconocimiento_medico_adjuntos",
                modelo: "api\\modules\\v1\\models\\rrhh\\RRHHReconocimientoMedicoAdjuntos",
                carpeta: "rrhh//reconocimiento-medico",
                xs:12
              },                
        ])

        const fetchData = async () => { 
            if (modo === "W") {
                const respreconocimiento = await axios(
                    `${process.env.REACT_APP_API_URL}rrhhssrecmedicos/${id}`
                );                
                setReconocimiento(respreconocimiento.data)  
            }
            setDatosCargados(true);
    
        };    
        fetchData();       
    },[]);

    useEffect(() => {            
        if (typeof reconocimiento !== "undefined") {        
        if(Object.keys(reconocimiento).length > 0){              
            setDatosCargados(false)
            campos[0].value = reconocimiento.Tipologia;
            campos[1].value = reconocimiento.Fecha;
            campos[2].value = reconocimiento.Empleado;
            campos[3].value = reconocimiento.Realizado === "Sí" ? 1 : 0;
            campos[4].value = reconocimiento.Observaciones;            
            campos[6].ficheros= reconocimiento.ficheros;

            setDatosCargados(true)  
        }
        }
    },[reconocimiento])

    if (datoscargados){
    return (
        <FormularioEntidad 
        urlForm = "rrhhssrecmedicos"
        urlGoto = "/rrhh/talento/recmed/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioFormacion;