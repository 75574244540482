const TEXT_LABELS = {
    body: {
      noMatch: "No se han encontrado resultados",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Siguiente Página",
      previous: "Anterior Página",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar XLSX",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    filter: {
      all: "Todos",
      title: "Filtros",
      reset: "Limpiar",
    },
    viewColumns: {
      title: "Mostrar columnas",
      titleAria: "Show/Hide Table Columns",
    },
    selectedRows: {
      text: "filas(s) seleccionada(s)",
      delete: "Eliminar",
      deleteAria: "Eliminar filas seleccionadas",
    },
}
export { TEXT_LABELS }

const NUMERO_HORAS_JORNADA = 6;
export { NUMERO_HORAS_JORNADA }

const MILISEGUNDOS_DIA = 86400000;
export { MILISEGUNDOS_DIA }