import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaMaterial = () => {
    let { id } = useParams();

    const [Material, setMaterial] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respMaterial = await axios(`${process.env.REACT_APP_API_URL}materials/${id}`);    
          setMaterial(respMaterial.data) 
        };
        fetchData();  
    }, [id]);    
    
    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(Material).length > 0){          
            setCampos([ 
                {
                    classsLabel: "",
                    label: "Código:",
                    value: Material.ean,
                    classText: classText,
                    xs:4
                },
                {
                    classsLabel: "",
                    label: "Nombre material:",
                    value: Material.nombre,
                    classText: classText,
                    xs:4
                }, 
                {
                    classsLabel: "",
                    label: "Tipo unidad:",
                    value: Material.tipoUnidad ? Material.tipoUnidad.Etiqueta : '',
                    classText: classText,
                    xs:4
                },
                {
                    classsLabel: "",
                    label: "Categoria:",
                    value: Material.categoria ? Material.categoria.Etiqueta : '',
                    classText: classText,
                    xs:4
                }, 
                {
                    classsLabel: "",
                    label: "Grupo:",
                    value: Material.grupo ? Material.grupo.Etiqueta : '',
                    classText: classText,
                    xs:4
                },
                {
                    classsLabel: "",
                    label: "Marca:",
                    value: Material.marca ? Material.marca.Etiqueta : '',
                    classText: classText,
                    xs:4
                },                                   
                {
                    classsLabel: "",
                    label: "Descripcion:",
                    value: Material.descripcion ? Material.descripcion : '',
                    classText: classText,
                    xs:6
                },
                {
                    classsLabel: "",
                    label: "Observaciones:",
                    value: Material.observaciones ? Material.observaciones : '',
                    classText: classText,
                    xs:6
                },
        ])
    }
    },[Material]) 

return(       
    <FichaEntidad
        campos = {campos}
        urlClick = {`/produccion/logistica/materials/${id}/W`}
    />    
)}

export default FichaMaterial;