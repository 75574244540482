import React, { useState, useEffect, useRef } from "react";
import axios from "axios"
import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {useFacturaVenta} from '../../containers/views/Main/Gestion/Facturas/facturaventa-context'

import FormFieldNumberUncontrolled from './FormFieldNumberUncontrolled'
import FormFieldTextUncontrolled from './FormFieldTextUncontrolled'
import FormFieldAutoCompleteUncontrolled from './FormFieldAutoCompleteUncontrolled'
import AlertaMensaje from './AlertaMensaje'


const FormularioFacturaProductos = ({cliente}) => {         
    const { listadoProductosFacturaVenta, setListadoProductosFacturaVenta } = useFacturaVenta(); 
    const [openAlert, setOpenAlert] = useState({estado: false, mensaje: "", tipo:"success"});
    const hayCliente = useRef(false);       
    /*
    const [campoOperativas, setCampoOerativas] = useState( 
      {
        urlData: "dic/diccionarios/operativas",
        campoEtiqueta: "Etiqueta",
        required: false,
        name: "operativa",      
        label: "Operativa",
        value: "",
        multiple: false,
        disabled : !hayCliente.current,
        xs:2,   
      }
    )
    */
    /*
    const [campoCategorias, setCampoCategorias] = useState(
      {
        urlData: "dic/diccionarios/categoriasproducto",
        campoEtiqueta: "Etiqueta",
        required: false,
        name: "categoria",        
        label: "Categoría",
        value: "",
        multiple: false,
        disabled : !hayCliente.current,
        xs:2,
      },
    )
    */
      /*
    const [campoSubcategorias, setCampoSubcategorias] = useState({
      urlData: "dic/diccionarios/subcategoriasproducto/0",
      campoEtiqueta: "Etiqueta",
      required: false,
      name: "subcategoria",      
      label: "Subcategoría",
      value: "",
      multiple: false,
      disabled : !hayCliente.current,
      xs:3,
    })
    */

    const [campoProductos, setCampoProductos] = useState({
      urlData: "productos",
      campoEtiqueta: "nombreCompleto",
      required: false,
      name: "producto",      
      label: "Productos",
      value: "",
      multiple: false,
      disabled : !hayCliente.current,
      xs:12,
    })

    const [campoDescripcionProducto, setCampoDescripcionProducto] = useState({
      required: false,
      label: "Descripción producto para factura",      
      name: "descripcionProducto",
      type: "text",  
      value: '',
      disabled : !hayCliente.current,
      xs:12
    })

    const [campoCantidad, setCampoCantidad] = useState({
      required: false,
      label: "Cantidad",      
      name: "cantidad",
      type: "number",  
      value: '',
      disabled : !hayCliente.current,
      xs:2
    })

    const [campoPrecio, setCampoPrecio] = useState({
      required: false,
      label: "Precio Ud.",      
      name: "precioud",
      type: "number",  
      value: '',
      disabled : !hayCliente.current,
      xs:2
    })

    const [campoIgic, setCampoIgic] = useState({
      urlData: "dic/diccionarios/igic",
      campoEtiqueta: "Etiqueta,Valor",
      campoId: "Id",
      required: false,
      name: "igic",
      disabled : !hayCliente.current,
      label: "IGIC",
      value: "",
      multiple: false,
      xs:2,      
    })

    const [campoIrpf, setCampoIrpf] = useState({
      urlData: "dic/diccionarios/irpf",
      campoEtiqueta: "Etiqueta,Valor",
      campoId: "Id",
      required: false,
      name: "irpf",
      disabled : !hayCliente.current,
      label: "IRPF",
      value: "",
      multiple: false,
      xs:2,      
    })

    const [campoDescuento, setCampoDescuento] = useState({
      required: false,
      label: "Descuento %",      
      name: "descuento",
      type: "number",  
      value: '',
      disabled : !hayCliente.current,
      xs:2
    })    

    useEffect(() => {       
      hayCliente.current = cliente ? Object.keys(cliente).length > 0 : false
      //console.log(hayCliente.current, cliente, campoCategorias)   
      setCampoDescuento({...campoDescuento, value: cliente ? cliente.Descuento ? cliente.Descuento : 0 : 0, disabled: !hayCliente.current })   
      
      /*
      setCampoOerativas({...campoOperativas, disabled: !hayCliente.current })
      setCampoCategorias({...campoCategorias, disabled: !hayCliente.current })
      setCampoSubcategorias({...campoSubcategorias, disabled: !hayCliente.current })
      */
      setCampoProductos({...campoProductos, disabled: !hayCliente.current })
      setCampoCantidad({...campoCantidad, disabled: !hayCliente.current })
      setCampoPrecio({...campoPrecio, disabled: !hayCliente.current })
      setCampoIgic({...campoIgic, value: cliente ? cliente.IGIC ? cliente.IGIC : null : null, disabled: !hayCliente.current })  
      setCampoIrpf({...campoIrpf, value: cliente ? cliente.IRPF ? cliente.IRPF : null : null, disabled: !hayCliente.current })
      setCampoDescripcionProducto({...campoDescripcionProducto, disabled: !hayCliente.current })

      

    }, [cliente])

    const handleClick = () => {    
      console.log(campoProductos.value)   
      
      if(!campoProductos.value.idOperativa)
      {
        setOpenAlert({estado: true, mensaje: "El producto que intenta añadir no tiene Operativa definida.", tipo:"error"})
        return;
      }
      /*
      if(!campoOperativas.value){
        setOpenAlert({estado: true, mensaje: "Debe seleccionar una operativa", tipo:"error"});
        return
      }
      */
     /*
      if(!campoCategorias.value){
        setOpenAlert({estado: true, mensaje: "Debe seleccionar una categoría", tipo:"error"});
        return
      }
      */
     /*
      if(!campoSubcategorias.value){
        setOpenAlert({estado: true, mensaje: "Debe seleccionar una subcategoría", tipo:"error"});
        return
      }
      */
      if(!campoProductos.value){
        setOpenAlert({estado: true, mensaje: "Debe seleccionar una producto", tipo:"error"});
        return
      }
      if(!campoCantidad.value){
        setOpenAlert({estado: true, mensaje: "Debe introducir una cantidad", tipo:"error"});
        return
      }
      if(!campoPrecio.value){
        setOpenAlert({estado: true, mensaje: "Debe introducir un precio", tipo:"error"});
        return
      }
      if(!campoIgic.value){
        setOpenAlert({estado: true, mensaje: "Debe seleccionar un tipo de IGIC", tipo:"error"});
        return
      }

      //console.log(campoProductos.value)
      
      let cantidad = parseFloat(campoCantidad.value)
      let precioUnidad = parseFloat(campoPrecio.value)
      let descuento = parseFloat(campoDescuento.value ? campoDescuento.value : 0)
      let igic = parseFloat(campoIgic.value.Valor)
      let irpf = parseFloat(campoIrpf.value ? campoIrpf.value.Valor : 0)

      let baseImponible = cantidad * precioUnidad - (cantidad * precioUnidad * (descuento/100))
      //let subTotal = baseImponible - (baseImponible * (descuento/100))
      let total = parseFloat(baseImponible)  + parseFloat((baseImponible * (igic/100))) - parseFloat((baseImponible * (irpf/100)) )
      let descripcionProducto = campoDescripcionProducto.value ? campoDescripcionProducto.value : campoProductos.value.Nombre
      console.log(campoProductos.value)
      let producto = {
        id:uuidv4(), 
        //idOperativa:campoOperativas.value.Id,
        idOperativa: campoProductos.value.idOperativa,
        //operativa:campoOperativas.value.Etiqueta,
        operativa:campoProductos.value.Operativa,
        //idCategoria: campoCategorias.value ? campoCategorias.value.Id : "",
        idCategoria: campoProductos.value.idCategoria,
        //categoria: campoCategorias.value ? campoCategorias.value.Etiqueta : "", 
        categoria: campoProductos.value.categoria,
        //idSubcategoria: campoSubcategorias.value ? campoSubcategorias.value.Id : "", 
        idSubcategoria: campoProductos.value.idSubcategoria, 
        //subcategoria: campoSubcategorias.value ? campoSubcategorias.value.Etiqueta : "",
        subcategoria: campoProductos.value.Subcategoria, 
        
        idProducto: campoProductos.value.Id,
        codigo: campoProductos.value.Codigo,
        descripcion: campoProductos.value.Nombre,
        producto: descripcionProducto,

        idIgic: campoIgic.value.Id,
        Igic: campoIgic.value.Valor,

        idIrpf: campoIrpf.value.Id,
        Irpf: campoIrpf.value.Valor,
        
        cantidad: cantidad,         
        precioUnidad: precioUnidad,
        baseImponible: baseImponible,
        descuento: descuento,
        precioTotal: total,

        jornada: "J1 lunes a viernes 08:00 14:00",
        importeExtraCoste: 0,
        
        extraCoste3: campoProductos.value.extraCoste ? campoProductos.value.extracostesCliente[0]["Porcentaje"] : 0,//J3
        extraCoste8: campoProductos.value.extraCoste ? campoProductos.value.extracostesCliente[1]["Porcentaje"] : 0,//J8
        extraCoste7: campoProductos.value.extraCoste ? campoProductos.value.extracostesCliente[2]["Porcentaje"] : 0,//J7
        extraCoste4: campoProductos.value.extraCoste ? campoProductos.value.extracostesCliente[3]["Porcentaje"] : 0,//J4
        extraCoste5: campoProductos.value.extraCoste ? campoProductos.value.extracostesCliente[4]["Porcentaje"] : 0,//J5 
        extraCoste6: campoProductos.value.extraCoste ? campoProductos.value.extracostesCliente[5]["Porcentaje"] : 0,//J6
        extraCoste9: campoProductos.value.extraCoste ? campoProductos.value.extracostesCliente[6]["Porcentaje"] : 0,//J9
        extraCoste10: campoProductos.value.extraCoste ? campoProductos.value.extracostesCliente[7]["Porcentaje"] : 0,//J10
      }         
      //console.log(producto)  
      setListadoProductosFacturaVenta([...listadoProductosFacturaVenta, producto])            
      //setCampoOerativas({...campoOperativas, urlData: "dic/diccionarios/operativas", value: null})
      //setCampoCategorias({...campoCategorias, urlData: "dic/diccionarios/categoriasproducto", value: null})
      //setCampoSubcategorias({...campoSubcategorias, urlData: "dic/diccionarios/subcategoriasproducto/0", value: null})
      setCampoProductos({...campoProductos, urlData: "productos", campoEtiqueta: "nombreCompleto", value: null})
      setCampoCantidad({...campoCantidad, value: ""}) 
      setCampoPrecio({...campoPrecio, value: ""}) 
      setCampoDescuento({...campoDescuento, value: cliente ? cliente.Descuento ? cliente.Descuento : 0 : 0})  
      setCampoIgic({...campoIgic, value: cliente ? cliente.IGIC ? cliente.IGIC : null : null})
      setCampoIrpf({...campoIrpf, value: null})
      setCampoDescripcionProducto({...campoDescripcionProducto, value: ""})

    }

    /*
    const handleChangeComboOperativa = (valor) => { 
      setCampoOerativas({...campoOperativas, value: valor })
    } 
    */
    /*
    const handleChangeComboCategoria = (valor) => { 
      setCampoCategorias({...campoCategorias, value: valor})
    }
    */
    /*
    const handleChangeComboSubcategoria = (valor) => { 
      setCampoSubcategorias({...campoSubcategorias, value: valor})
    }
    */

    const handleChangeComboProducto = async (valor) => { 
      //console.log(valor)
      if(valor){      
        console.log(process.env.REACT_APP_API_URL + "cuentas/gettarifaproducto/" + valor.Id + "/" + cliente.Id)  
        await axios(process.env.REACT_APP_API_URL + "cuentas/gettarifaproducto/" + valor.Id + "/" + cliente.Id)
        .then(                             
          (response) => { 
            console.log(response.data)
            //console.log(campoProductos.value)
            setCampoProductos({...campoProductos, value: response.data})
            setCampoPrecio({...campoPrecio, value: response.data.Tarifa})

          },
          (error) => { console.log(error) }
        )
      }      
    } 

    const handleChangeCantidad = (valor) => { 
      setCampoCantidad({...campoCantidad, value: valor})
    } 
    
    const handleChangePrecio = (valor) => { 
      setCampoPrecio({...campoPrecio, value: valor})
    } 

    const handleChangeDescuento = (valor) => { 
      setCampoDescuento({...campoDescuento, value: valor})
    } 

    const handleChangeComboIgic = (valor) => {
      setCampoIgic({...campoIgic, value: valor})      
    }

    const handleChangeComboIrpf = (valor) => {
      setCampoIrpf({...campoIrpf, value: valor})      
    }

    const handleChangeDescripcionProducto = (valor) => {
      setCampoDescripcionProducto({...campoDescripcionProducto, value: valor})
      //console.log(valor)
    }

    const handleCloseAlert = (event, reason) => {  
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert({estado: false, mensaje: "", tipo:"success"});
    };    
    /*
    useEffect(() => {       
      if(campoOperativas.value){         
        setCampoCategorias({...campoCategorias,urlData: `dic/diccionarios/categoriasproductoxoperativa/${campoOperativas.value.Id}`, value: null, disabled: !hayCliente.current})
        setCampoSubcategorias({...campoSubcategorias,urlData: `dic/diccionarios/subcategoriasproductoxoperativa/${campoOperativas.value.Id}`, value: null, disabled: !hayCliente.current})
        setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativa/${campoOperativas.value.Id}`, campoEtiqueta: "Nombre", value: null, disabled: !hayCliente.current})
      }
      else{        
        setCampoCategorias({...campoCategorias,urlData: "dic/diccionarios/categoriasproducto", value: null, disabled: !hayCliente.current, disabled: !hayCliente.current})
        setCampoSubcategorias({...campoSubcategorias,urlData: "dic/diccionarios/subcategoriasproducto/0", value: null, disabled: !hayCliente.current})
        setCampoProductos({...campoProductos,urlData: "productos", campoEtiqueta: "nombre", value: null, disabled: !hayCliente.current})
      }
    },[campoOperativas])
    */
    /*
    useEffect(() => {       
      if(campoCategorias.value){         
        setCampoSubcategorias({...campoSubcategorias,urlData: `dic/diccionarios/subcategoriasproductoxoperativaycategoria/${campoOperativas.value.Id}/${campoCategorias.value.Id}`, value: null, disabled: !hayCliente.current})
        setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativaycategoria/${campoOperativas.value.Id}/${campoCategorias.value.Id}`, campoEtiqueta: "Nombre", value: null, disabled: !hayCliente.current})
      }
      else{
        if(campoOperativas.value){           
          setCampoSubcategorias({...campoSubcategorias,urlData: `dic/diccionarios/subcategoriasproductoxoperativa/${campoOperativas.value.Id}`, value: null, disabled: !hayCliente.current})
          setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativa/${campoOperativas.value.Id}`, campoEtiqueta: "Nombre", value: null, disabled: !hayCliente.current})
        }
        else{          
          setCampoSubcategorias({...campoSubcategorias,urlData: "dic/diccionarios/subcategoriasproducto/0", value: null, disabled: !hayCliente.current})
          setCampoProductos({...campoProductos,urlData: "productos", campoEtiqueta: "nombre", value: null, disabled: !hayCliente.current})
        }
      }
    },[campoCategorias])
    */
   /*
    useEffect(() => { 
      if(campoSubcategorias.value){ 
        setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativaysubcategoria/${campoOperativas.value.Id}/${campoSubcategorias.value.Id}`, campoEtiqueta: "Nombre", value: null, disabled: !hayCliente.current})
      }
      else{
        if(campoCategorias.value){ 
          setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativaycategoria/${campoOperativas.value.Id}/${campoCategorias.value.Id}`, campoEtiqueta: "Nombre", value: null, disabled: !hayCliente.current})
        }
        else{
          if(campoOperativas.value){ 
            setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativa/${campoOperativas.value.Id}`, campoEtiqueta: "Nombre", value: null, disabled: !hayCliente.current})
          }
          else{
            setCampoProductos({...campoProductos,urlData: "productos", campoEtiqueta: "nombre", value: null, disabled: !hayCliente.current})
          }
        }       
      }
    },[campoSubcategorias])
    */
   
   
    return (    
    <>        
     
      <Grid container  spacing={1} className="mt-2 borderFormulario">   
        {/*         
        <FormFieldAutoCompleteUncontrolled propiedades={campoOperativas} onChangeValue={handleChangeComboOperativa} />                
        <FormFieldAutoCompleteUncontrolled propiedades={campoCategorias} onChangeValue={handleChangeComboCategoria} />                
        <FormFieldAutoCompleteUncontrolled propiedades={campoSubcategorias} onChangeValue={handleChangeComboSubcategoria} />  
        */}              
        <FormFieldAutoCompleteUncontrolled propiedades={campoProductos} onChangeValue={handleChangeComboProducto} />                
        <FormFieldTextUncontrolled propiedades={campoDescripcionProducto} onChangeValue={handleChangeDescripcionProducto}/>
        <FormFieldNumberUncontrolled propiedades={campoCantidad} onChangeValue={handleChangeCantidad} />                
        <FormFieldNumberUncontrolled propiedades={campoPrecio} onChangeValue={handleChangePrecio} />                
        <FormFieldAutoCompleteUncontrolled propiedades={campoIgic} onChangeValue={handleChangeComboIgic} />                
        <FormFieldAutoCompleteUncontrolled propiedades={campoIrpf} onChangeValue={handleChangeComboIrpf} />
        <FormFieldNumberUncontrolled propiedades={campoDescuento} onChangeValue={handleChangeDescuento} />                

        <Grid item xs={2} className="" >
          <Button className="" variant="contained" color="primary" component="span" onClick={handleClick} disabled={!hayCliente.current}>
          AÑADIR
          </Button>                         
        </Grid>    
      </Grid>             
         
        {openAlert.estado && 
          <AlertaMensaje 
            mensaje={openAlert.mensaje} 
            isOpen={openAlert.estado} 
            tipoMensaje={openAlert.tipo}
            cerrar={handleCloseAlert}
          />
        }  
    
    </>  
    );    
}
export default FormularioFacturaProductos; 