import React, { useState, useEffect, useRef  } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { TEXT_LABELS} from "../../../../../configuraciones/conf"

import FormFieldFiles from "../../../../../components/Common/FormFieldFiles";

import LinearProgress from "@material-ui/core/LinearProgress";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

import { useStyles } from '../../../../../styles/styles';

const OPTIONS_PINS = { 
  filter: false,
  print: false,
  download: false,
  pagination: false,
  selectableRows: 'single', 
  search: false,
  viewColumns: false,  
  expandableRows: false,
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  expandableRowsHeader: false,
  expandableRowsOnClick: true,
  elevation:0,
  textLabels: TEXT_LABELS
};


export default function ListadoDOPOperacion({actualizarListado}) {
    const { id } = useParams(); 
    const [listadoPin, setListadoPin] = useState([])

    const [isLoading, setIsLoading] = useState(false);
    const entidadEliminar = useRef(-1);
    const [abrirDialogo, setAbrirDialogo] = useState(false)

    const classes = useStyles();
   
    const COLUMNS_PINS = [    
      {
        name: "Id",
        label: "Id",
        options: {     
        sort: true,
        display: 'excluded'
        }
      },
      {
        name: "Nombre",
        label: "Nombre",
        options: {
          sort: true,
          display: 'excluded'
        }
      },    
      {
        name: "Fk_Operacion",
        label: "Fk_Operacion",
        options: {
        sort: true,  
        display: 'excluded'   
        }
      },
      {
        name: "Ubicacion",
        label: "Ubicacion",
        options: {
          sort: true,
          display: 'excluded'
        }
      },
      {
        name: "Activo",
        label: "Activo",
        options: {
          sort: true,
          display: 'excluded'
        }
      }, 
      {
          name: "Ficheros",
          label: "Ficheros",
          options: {
          sort: true,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => { 
            
            if(listadoPin.length > 0){
              return(
                <FormFieldFiles propiedades={{
                  label: tableMeta.tableData[tableMeta.rowIndex][1],
                  name: "files",
                  type: "files", 
                  ficheros: [listadoPin[tableMeta.rowIndex]],   
                  tabla: "mtms_operaciones_adjuntos",
                  modelo: "api\\modules\\v1\\models\\operaciones\\OperacionesAdjuntos",
                  carpeta: "operaciones",
                  xs:12
                  } } />
              )
            }else{return null}
          }
          }
        },
    ];
    

    useEffect(() => { 
      const fetchData = async () => {     
       
        const respListadoPins = await axios(
          `${process.env.REACT_APP_API_URL}operaciones/adjuntos/${id}`
        );  

        let listadoAux = []
        respListadoPins.data.map(listadoPin => {
          listadoAux.push({
            Id: listadoPin.Id,
            Fk_Operacion: listadoPin.Fk_Operacion,
            Nombre: listadoPin.Nombre,
            Ubicacion: listadoPin.Ubicacion,
            Activo: listadoPin.Activo
          })
        })  
        setListadoPin(listadoAux)
      }
      fetchData();

      return () => {        
      }
    }, [actualizarListado])

    const handleCerrarDialogo = (valor) => {       
      setAbrirDialogo(false)
      if(valor){    
        const fetchData = async () => {  
          setIsLoading(true)
          let user = JSON.parse(localStorage.getItem("user"));
          const AuthStr = "Bearer ".concat(user.access_token);
          const SERVER_URL = process.env.REACT_APP_API_URL + 'operacionespins';
          await axios
          .delete(SERVER_URL + "/" + entidadEliminar.current, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },        
          })
          .then((response) => {    
            if(!isNaN(response.data)){
              
              if(parseInt(response.data) === parseInt(entidadEliminar.current)){
                entidadEliminar.current = -1 
                setIsLoading(false)
              }          
            } else{
              alert(response.data);  
              setIsLoading(false)
            }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
            setIsLoading(false)
          });  
        }
        fetchData();
      }      
    }
    

    return (   
      <>
  { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar el parte de incidencias?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
    {!isLoading && (    
    <div className="rounded border border-formulario p-2">
      <MUIDataTable
        title={""}
        className={"mt-1 mt-2"}
        data={listadoPin}
        columns={COLUMNS_PINS}
        options={OPTIONS_PINS}
       />
      </div> 
      )}
    {isLoading && 
      <div className={classes.linear}>
            <LinearProgress />
      </div>
    }

    </>          
  );

}