const botoneraSeleccion = [
    {
       etiqueta: "Clonar",
       url: '/rrhh/talento/seleccions/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/rrhh/talento/seleccions/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/talento/seleccions/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/talento/seleccions',
       activo: true,
     }, 
   ];

   export {botoneraSeleccion};

   const botoneraFormacion = [
    {
       etiqueta: "Clonar",
       url: '/rrhh/talento/formacion/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/rrhh/talento/formacion/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/talento/formacion/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/talento/formacion',
       activo: true,
     }, 
   ];

   export {botoneraFormacion};

   const botoneraRecMedico = [
    {
       etiqueta: "Clonar",
       url: '/rrhh/talento/recmed/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/rrhh/talento/recmed/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/talento/recmed/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/talento/recmed',
       activo: true,
     }, 
   ];

   export {botoneraRecMedico};

   const botoneraEnfermedad = [
    {
       etiqueta: "Clonar",
       url: '/rrhh/talento/enfermedad/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/rrhh/talento/enfermedad/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/talento/enfermedad/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/talento/enfermedad',
       activo: true,
     }, 
   ];

   export {botoneraEnfermedad};

   const botoneraAccidente = [
    {
       etiqueta: "Clonar",
       url: '/rrhh/talento/accidente/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/rrhh/talento/accidente/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/talento/accidente/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/talento/accidente',
       activo: true,
     }, 
   ];

   export {botoneraAccidente};

   const botoneraDocumentacion = [
    {
       etiqueta: "Clonar",
       url: '/rrhh/talento/documentacion/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/rrhh/talento/documentacion/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/talento/documentacion/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/talento/documentacion',
       activo: true,
     }, 
   ];

   export {botoneraDocumentacion};

   const botoneraEmpleados = [
     /*
    {
       etiqueta: "Clonar",
       url: '/rrhh/gestionpersonal/empleados/filaSeleccionada/W',
       activo: false,
     }, 
     */
 
     {
       etiqueta: "Editar",
       url: '/rrhh/gestionpersonal/empleados/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/gestionpersonal/empleados/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/gestionpersonal/empleados',
       activo: true,
     }, 
   ];

   export {botoneraEmpleados};


   const botoneraVacaciones = [
    {
       etiqueta: "Clonar",
       url: '/rrhh/gestionpersonal/vacaciones/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/rrhh/gestionpersonal/vacaciones/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/gestionpersonal/vacaciones/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/gestionpersonal/vacaciones',
       activo: true,
     }, 
   ];

   export {botoneraVacaciones};

   const botoneraPersonal = [
    /*
    {
       etiqueta: "Clonar",
       url: '/rrhh/talento/seleccions/filaSeleccionada/W',
       activo: false,
     }, 
     */
     {
       etiqueta: "Editar",
       url: '/rrhh/gestionpersonal/personal/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/gestionpersonal/personal/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/gestionpersonal/personal',
       activo: true,
     }, 
   ];

   export {botoneraPersonal};



   const botoneraEPI = [
    {
       etiqueta: "Clonar",
       url: '/rrhh/talento/uniforme/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/rrhh/talento/uniforme/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/rrhh/talento/uniforme/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/rrhh/talento/uniforme',
       activo: true,
     }, 
   ];

   export {botoneraEPI};