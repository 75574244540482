import React from "react";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from '@material-ui/core';

const CuerpoTablaDatos = ({
    titulo,
    datos,
    columnas,
    OPTIONS_COLUMNS,
    isLoading

}) => {    
    return(
    <div className="bg-white p-10px">
        <div className="p-1 bg-white mb-4">
            <h6 className="texto-comercial" id="title">
            {titulo}
            </h6>
            <hr className="m-0 bg-comercial" />
        </div>
        <div className="bg-white cuerpo-h  overflow-auto overflow-hiddens table-responsive">
            { !isLoading &&
                (<MUIDataTable
                    title={""}
                    className={"mt-1 mb-2"}
                    data={datos}
                    columns={columnas}
                    options={OPTIONS_COLUMNS}
                />)}
            { isLoading &&
                <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />
            }                  
        </div>
    </div>
    )
}

export default CuerpoTablaDatos;