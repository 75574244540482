const columnsSoporte = [
    {
      name: "id",
      label: "Nº",
      options: {
       filter: true,
       sort: false,
      }
     }, 
    {
      name: "tipologia",
      label: "Tipología",
      options: {
       filter: true,
       sort: false,
      }
     },
     {
      name: "codigo",
      label: "Código",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "fecha",
      label: "Fecha",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "tercero",
      label: "Tercero",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "contacto",
      label: "Contacto",
      options: {
       filter: true,
       sort: true,
      }
     },  
     {
      name: "departamento",
      label: "Departamentos",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
        name: "empleado",
        label: "Empleado",
        options: {
         filter: true,
         sort: true,
        }
       },
     {
      name: "estado",
      label: "Estado",
      options: {
       filter: true,
       sort: true,
      }
     },
          
  ];

  export {columnsSoporte};

  const columnsContenedor = [ 
    {
      name: "Codigo",
      label: "Carpeta",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Nombre",
      label: "Buque",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Operacion",
      label: "Operación",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Fecha_Operativa",
      label: "Fecha Operativa",
      options: {
       filter: true,
       sort: true,
      }
     },
     /*
     {
      name: "Fecha_Pedido",
      label: "Fecha Pedido",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
     {
      name: "Jornada",
      label: "Jornada",
      options: {
       filter: true,
       sort: true,
      }
     },  
     {
        name: "Operarios",
        label: "Operarios",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Hora_Inicio",
        label: "Inicio",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Hora_Fin",
        label: "Fin",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "Horas_Trabajadas",
        label: "Horas",
        options: {
         filter: true,
         sort: true,
        }
       },
       /*
     {
      name: "Hora_Inicio",
      label: "Inicio",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Hora_Fin",
      label: "Fin",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
     {
      name: "Descarga",
      label: "Descarga",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Carga",
      label: "Carga",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Remocion",
      label: "Remoción",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Totales",
      label: "Totales",
      options: {
       filter: true,
       sort: true,
      }
     },
     /*
     {
      name: "Teus",
      label: "TEUS",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
     {
      name: "CapacidadUtilizada",
      label: "Capacidad Utilizada",
      options: {
       filter: true,
       sort: true,
       hint: 'Mide el cociente entre las horas de la operativa y las horas de la jornada',
      }
     },
     /*
     {
      name: "EfectividadOperativa",
      label: "Efectividad operativa",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
     {
      name: "Productividad",
      label: "Productividad",
      options: {
       filter: true,
       sort: true,
       hint: 'Mide los movimientos totales reales realizados por los recursos utilizados (tiempo y mano de obra)',
      }
     },
     /*
     {
      name: "Eficiencia",
      label: "Eficiencia",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Ratio",
      label: "Ratio",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
          
  ];

  export {columnsContenedor};

  const columnsmGeneral = [ 
    {
      name: "Codigo",
      label: "Carpeta",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Nombre",
      label: "Buque",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Operacion",
      label: "Operación",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Fecha_Operativa",
      label: "Fecha Operativa",
      options: {
       filter: true,
       sort: true,
      }
     },
     /*
     {
      name: "Fecha_Pedido",
      label: "Fecha Pedido",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
     {
      name: "Jornada",
      label: "Jornada",
      options: {
       filter: true,
       sort: true,
      }
     },  
     {
        name: "Operarios",
        label: "Operarios",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Hora_Inicio",
        label: "Inicio",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Hora_Fin",
        label: "Fin",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Horas_Trabajadas",
        label: "Horas",
        options: {
         filter: true,
         sort: true,
        }
       },
     {
      name: "Descarga",
      label: "Descarga",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Carga",
      label: "Carga",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Remocion",
      label: "Remoción",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Totales",
      label: "Totales",
      options: {
       filter: true,
       sort: true,
      }
     },
     /*
     {
      name: "Teus",
      label: "TEUS",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
     {
      name: "CapacidadUtilizada",
      label: "Capacidad Utilizada",
      options: {
       filter: true,
       sort: true,
       hint: 'Mide el cociente entre las horas de la operativa y las horas de la jornada',
      }
     },
     /*
     {
      name: "EfectividadOperativa",
      label: "Efectividad operativa",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
     {
      name: "Productividad",
      label: "Productividad",
      options: {
       filter: true,
       sort: true,
       hint: 'Mide los movimientos totales reales realizados por los recursos utilizados (tiempo y mano de obra)',
      }
     },
     /*
     {
      name: "Eficiencia",
      label: "Eficiencia",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Ratio",
      label: "Ratio",
      options: {
       filter: true,
       sort: true,
      }
     },
     */
          
  ];

  export {columnsmGeneral};

  const columnsCPE = [ 
    {
      name: "Id",
      label: "Nº",
      options: {
       filter: true,
       sort: true,
       display:false,
      }
     },
     {
      name: "NCenso",
      label: "Censo",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Nombre",
      label: "Nombre",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Especialidad",
      label: "Especialidad",
      options: {
       filter: true,
       sort: true,
      }
     },  
     {
      name: "Situacion",
      label: "Situación",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Horas_Trabajadas",
      label: "Horas trabajadas",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Tasa_Utilizacion",
      label: "Tasa utilización",
      options: {
       filter: true,
       sort: true,
      }
     },  
    {
      name: "Codigo",
      label: "Carpeta",
      options: {
       filter: true,
       sort: true,
      }
     },
     /*
     {
      name: "Operacion",
      label: "Operacion",
      options: {
       filter: true,
       sort: false,
      }
     },
     */
     {
      name: "Fecha_Pedido",
      label: "Fecha",
      options: {
       filter: true,
       sort: false,
      }
     },
     {
      name: "Jornada",
      label: "Jornada",
      options: {
       filter: true,
       sort: false,
      }
     },
     /*
     {
      name: "Muelle",
      label: "Muelle",
      options: {
       filter: true,
       sort: false,
      }
     },
     */
     {
      name: "Buque",
      label: "Buque",
      options: {
       filter: true,
       sort: true,
      }
     },
     /*
     {
      name: "CodigoEspecialidad",
      label: "Código",
      options: {
       filter: true,
       sort: true,
      }
     },
     
     
     {
        name: "Repetición",
        label: "Repetición",
        options: {
         filter: true,
         sort: true,
        }
       },
       */
  ];

  export {columnsCPE};





