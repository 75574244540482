import React, { useState, useEffect } from "react";
import {columnsContenedor,columnsmGeneral,columnsCPE} from './PostventaConf'
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import TipoListado from "./TipoListado";
import TipoInforme from "./TipoInforme";
import FiltroListadoAuditoria from "./FiltroListadoAuditoria";
import Graficas from './Graficas';
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
firstDay = formatDate(firstDay);
lastDay = formatDate(lastDay);

const Auditoria = (props) => {     
  const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOpAuditoria')) || 0);
  const [idSubOperacionL, setIdSubOperacionL]=useState(localStorage.getItem('idSubOpAuditoriaL') || "Contenedor");     
  const [idSubOperacionI, setIdSubOperacionI]=useState(localStorage.getItem('idSubOpAuditoriaI') || "Contenedor");     
  const [urlDatosCuerpoTabla, setUrlDatos]=useState("auditorias"); 
  const [urlDatosCuerpoTabla2, setUrlDatos2]=useState("auditorias/informecpe");  
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {     
    if(idOperacion === 0){
      setIsLoading(true)     
      const SERVER_URL = "auditorias?tipoMercancia=" + idSubOperacionL + "&fechainicial=" + firstDay + "&fechafinal=" + lastDay; 
      setUrlDatos(SERVER_URL) 
      setIsLoading(false)
    }
    if(idOperacion === 1){
      setIsLoading(true)     
      const SERVER_URL = "auditorias?tipoMercancia=" + idSubOperacionI + "&fechainicial=" + firstDay + "&fechafinal=" + lastDay; 
      setUrlDatos(SERVER_URL) 
      setIsLoading(false)
    }
  }, [idOperacion])

  useEffect(() => {    
    if(idOperacion === 0){
      setIsLoading(true)     
      const SERVER_URL = "auditorias?tipoMercancia=" + idSubOperacionL + "&fechainicial=" + firstDay + "&fechafinal=" + lastDay; 
      setUrlDatos(SERVER_URL) 
      setIsLoading(false)
    }
    if(idOperacion === 1){
      setIsLoading(true)     
      const SERVER_URL = "auditorias?tipoMercancia=" + idSubOperacionI + "&fechainicial=" + firstDay + "&fechafinal=" + lastDay; 
      setUrlDatos(SERVER_URL) 
      setIsLoading(false)
    }

  },[idSubOperacionL, idSubOperacionI])
  

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const verTabla = (idOperacion) => {
    localStorage.setItem('idOpAuditoria', idOperacion);  
    setIdOperacion(idOperacion)    
  }  

  const handleOperacionListado = (idO) =>{    
    localStorage.setItem('idSubOpAuditoriaL', idO);
    setIdSubOperacionL(idO)
  } 

  const handleOperacionInforme = (idO) =>{    
    localStorage.setItem('idSubOpAuditoriaI', idO);
    setIdSubOperacionI(idO)
  } 

  const handleUrlFechaL = (url) =>{    
    columnasCuerpoTabla = columnsContenedor    
    firstDay = url[0];
    lastDay = url[1];    
    setIsLoading(true)     
    const SERVER_URL = "auditorias?tipoMercancia=" + idSubOperacionL + "&fechainicial=" + firstDay + "&fechafinal=" + lastDay; 
    setUrlDatos(SERVER_URL) 
    setIsLoading(false)
    columnIdCuerpoTabla="Id" 
  }

  const handleUrlFechaI = (url) =>{    
    columnasCuerpoTabla = columnsContenedor      
    firstDay = url[0];
    lastDay = url[1];    
    setIsLoading(true)     
    const SERVER_URL = "auditorias?tipoMercancia=" + idSubOperacionI + "&fechainicial=" + firstDay + "&fechafinal=" + lastDay; 
    setUrlDatos(SERVER_URL) 
    setIsLoading(false)
    columnIdCuerpoTabla="Id"
  }

  const handleUrlFechaCPE = (url) =>{
    columnasCuerpoTabla = columnsContenedor
    firstDay = url[0];
    lastDay = url[1];    
    setIsLoading(true)     
    const SERVER_URL = "auditorias/informecpe?tipoMercancia=" + idSubOperacionI + "&fechainicial=" + firstDay + "&fechafinal=" + lastDay; 
    setUrlDatos2(SERVER_URL) 
    setIsLoading(false)
  }
  
  let columnasCuerpoTabla = columnsContenedor;
  let columnIdCuerpoTabla = "Id";  
  let footer = [
    {campo: 'Horas_Trabajadas', titulo: 'Horas', operacion: 'sum'},
    {campo: 'Tasa_Utilizacion', titulo: 'Tasa de utilización', operacion: 'avgTotal'},
  ]


  if(idOperacion === 0 && idSubOperacionL === "Contenedor"){
    columnasCuerpoTabla = columnsContenedor
    columnIdCuerpoTabla="Id"
  }

  if(idOperacion === 0 && idSubOperacionL === "mGeneral"){
    columnasCuerpoTabla = columnsmGeneral
    columnIdCuerpoTabla="Id"
  }

  if(idOperacion === 2 ){
    columnasCuerpoTabla = columnsCPE
    columnIdCuerpoTabla = "Id"
  } 

  return (           
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Postventa"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-3-2-auditoria.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Auditoría
              </h6>
            </div>
            <hr className="m-0 bg-comercial mb-1" />
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
             
             <Pasos pasos={['Listado', 'Informe', 'C.P.E']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
             
            {idOperacion===0 && (              
              <AuditoriaListado 
                isLoading = {isLoading} 
                handleOperacionListado = {handleOperacionListado} 
                idSubOperacionL= {idSubOperacionL} 
                handleUrlFechaL= {handleUrlFechaL} 
                columnasCuerpoTabla= {columnasCuerpoTabla} 
                urlDatosCuerpoTabla= {urlDatosCuerpoTabla} 
                columnIdCuerpoTabla= {columnIdCuerpoTabla} 
                marcarFila = {marcarFila} 
              /> 
            )}

            {idOperacion===1 && ( 
              <AuditoriaInforme 
                handleOperacionInforme = {handleOperacionInforme}
                idSubOperacionI  = {idSubOperacionI}
                handleUrlFechaI  = {handleUrlFechaI}
              />
            )}

            {idOperacion===2 && (
              <AuditoriaCPE 
                handleUrlFechaCPE = {handleUrlFechaCPE}
                columnasCuerpoTabla = {columnasCuerpoTabla} 
                urlDatosCuerpoTabla2 = {urlDatosCuerpoTabla2} 
                columnIdCuerpoTabla = {columnIdCuerpoTabla} 
                marcarFila = {marcarFila}
                footer = {footer}
              />
            )}
            </div>
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">            
            </div>
          </div>
        </div>
      </div> 
  );
}
export default Auditoria;

const AuditoriaListado = ({isLoading, handleOperacionListado, idSubOperacionL, handleUrlFechaL, columnasCuerpoTabla, urlDatosCuerpoTabla, columnIdCuerpoTabla, marcarFila}) => {
  return(
    <>  
      <Grid container spacing={0} className="">
        <Grid item xs={6} className="" >
          <TipoListado opera={handleOperacionListado} subOperacion={idSubOperacionL}/>
        </Grid>
        <Grid item xs={6} className="" >
          <FiltroListadoAuditoria urlBase="auditorias" setFechas={handleUrlFechaL} firstDay={firstDay} lastDay={lastDay}/>
        </Grid>
      </Grid>
      {!isLoading && (
        <CuerpoTablaPiezas
          columnas = {columnasCuerpoTabla}
          urlDatos = {urlDatosCuerpoTabla}
          columnId = {columnIdCuerpoTabla}
          getFilaSeleccionada={marcarFila}
        />  
      )} 
      {isLoading && (
        <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
      )}  
    </>
  )
}

const AuditoriaInforme = ({handleOperacionInforme, idSubOperacionI, handleUrlFechaI}) => {

  return(
    <>
      <Grid container spacing={0} className="">              
        <Grid item xs={6} className="" >
          <TipoInforme opera={handleOperacionInforme} subOperacion={idSubOperacionI}/>
        </Grid>

        <Grid item xs={6} className="" >
          <FiltroListadoAuditoria urlBase="auditorias" setFechas={handleUrlFechaI} firstDay={firstDay} lastDay={lastDay}/>   
        </Grid>
      </Grid> 

      <Graficas subOperacion={idSubOperacionI} fechaInicial={firstDay} fechaFinal={lastDay}/>
    </>
  )
}

const AuditoriaCPE = ({handleUrlFechaCPE, columnasCuerpoTabla, urlDatosCuerpoTabla2, columnIdCuerpoTabla, marcarFila, footer}) => {

  return(
    <>
      <Grid container spacing={0} className="">
        <Grid item xs={6} className="" >
        </Grid>
        <Grid item xs={6} className="" >
          <FiltroListadoAuditoria urlBase="informecpe" setFechas={handleUrlFechaCPE} firstDay={firstDay} lastDay={lastDay}/>                  
        </Grid>
      </Grid>
              
      <CuerpoTablaPiezas
        columnas = {columnasCuerpoTabla}
        urlDatos = {urlDatosCuerpoTabla2}
        columnId = {columnIdCuerpoTabla}
        getFilaSeleccionada={marcarFila}
        customFooter={footer} 
      /> 
    </>
  )
}