import React from "react";

import { Dimmer, Loader, Segment } from "semantic-ui-react";

const Loading = (props) => {
  return (
    <>
      <div className="row">
        {props.isLoading && (
          <div className="bg-cuerpo cuerpo-h col-12">
            <Segment className="cuerpo-h">
              <Dimmer active>
                <Loader size="massive">Cargando</Loader>
              </Dimmer>
            </Segment>
          </div>
        )}
      </div>
    </>
  );
};
export default Loading;
