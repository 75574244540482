const botoneraPiezas = [
    // { etiqueta: "Crear Operación", url: "/operativas/operaciones/operacion", activo: true },
    {
       etiqueta: "Clonar",
       url: '/operativas/almacenamiento/piezas/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/operativas/almacenamiento/piezas/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/operativas/almacenamiento/piezas/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/operativas/almacenamiento/piezas',
       activo: true,
     }, 
   ];

   export {botoneraPiezas};


   const botoneraAlmacen = [
    {
       etiqueta: "Clonar",
       url: '/operativas/almacenamiento/almacen/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Editar",
       url: '/operativas/almacenamiento/almacen/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Eliminar",
       url: '/operativas/almacenamiento/almacen/filaSeleccionada/W',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/operativas/almacenamiento/almacen',
       activo: true,
     }, 
   ];

   export {botoneraAlmacen};

   const botoneraAlmacenamiento = [
    // { etiqueta: "Crear Operación", url: "/operativas/operaciones/operacion", activo: true },
    /*
    {
       etiqueta: "Ver",
       url: '/operativas/almacenamientos/filaSeleccionada/R',
       activo: false,
     }, 
     */
   ];

   export {botoneraAlmacenamiento};
   
/*
   const botoneraDespacho = [
    // { etiqueta: "Crear Operación", url: "/operativas/operaciones/operacion", activo: true },
    {
       etiqueta: "Tique Salida",
       url: '/operativas/almacenamientos/filaSeleccionada/R',
       activo: false,
     }, 
 
   ];

   export {botoneraDespacho};
   */

   const botoneraRecepcion = [
    // { etiqueta: "Crear Operación", url: "/operativas/operaciones/operacion", activo: true },
    /*
    {
       etiqueta: "Transferir",
       url: '/operativas/almacenamientos-transferir/filaSeleccionada',
       activo: false,
     }, 
     */
 
     {
       etiqueta: "Editar",
       url: '/operativas/almacenamientos/filaSeleccionada/R',
       activo: false,
     }, 
 
     {
       etiqueta: "Crear",
       url: '/operativas/almacenamientos',
       activo: true,
     }, 

   ];

   export {botoneraRecepcion};
