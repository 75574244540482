import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TableAcciones from "../../../../components/Common/TableAcciones";
import Contactos from "./Contactos";
import Loading from "../../../../components/Common/Loading";
import SearchBar from "../../../../components/Common/SearchBar";

const CrearContacto = () => {
  let { id, modo } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [datos] = useState({
    cuenta: [],
    modoIdentificacion: modo ? modo : "C",
    modoBanco: "R",
    modoDireccion: "R",
    modoCondiciones: "R",
    modificarDireccion: 0,
    modificarBanco: 0,
  });
  

  const botonesAcciones = [
    "Crear Cuenta",
    "Editar Cuenta",
    "Crear Oportunidad",
    "Crear Actividad",
    "Crear Proyecto",
  ];

  const urlAcciones = [
    "cuentas/cuenta",
    `cuentas/cuenta/${id}/W`,
    `cuentas/crearoportunidad/${id}`,
    `cuentas/crearactividad/${id}`,
    `cuentas/crearproyecto/${id}`,
  ];
  
  useEffect(() => {
    setIsLoading(false);
  }, [id]);

  const handleClickSearch = (texto) => {
    console.log(texto);
  };

  return (
    <>
      <div className="col-8 col-main-home">
        <SearchBar
          titulo="CRM"
          buscar={false}
          onClick={(a) => handleClickSearch(a)}
        />
        {!isLoading && (
          <>
            <div className="bg-cuerpo p-10px">
              <div className="bg-white p-10px">
                <div className="p-1 bg-white mb-4">
                  <h6 className="texto-comercial" id="title">
                    Formulario creación de cuenta
                  </h6>
                  <hr className="m-0 bg-comercial" />
                </div>
              </div>
              <div className="bg-white p-10px">
                <Contactos idCuenta={datos.cuenta.Id} />
              </div>
              <TableAcciones
                botones={botonesAcciones}
                filaSeleccionada={id}
                acciones={urlAcciones}
              />
            </div>
          </>
        )}
        <Loading isLoading={isLoading} />
      </div>
    </>
  );
};

export default CrearContacto;
