import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
}));

const AlertaMensaje = ({mensaje, isOpen, tipoMensaje, cerrar}) =>{
    /* tipoMensaje=['error', 'warning', 'info', 'succes'] */    
    const classes = useStyles();    
    const timeToHide = 5000    

    const handleCloseAlert = (event, reason) => { 
        if (reason === 'clickaway') {            
          return;
        }  
        cerrar(event, reason)        
    };
    
    return(
        <div className={classes.root}>
            <Snackbar anchorOrigin={ {vertical: 'bottom',  horizontal: 'right'} } open={isOpen} autoHideDuration={timeToHide} onClose={handleCloseAlert}>                
                <Alert onClose={handleCloseAlert} severity={tipoMensaje} sx={{ whiteSpace: 'pre-line' }}>
                    {mensaje}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default AlertaMensaje;