import React, { useState, useEffect  } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {useOperacion} from './operacion-context'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ListadoTDROperacion({listadoDatos}) {    
    const { id, modo } = useParams(); 
    const { documentosTDR, setDocumentosTDR } = useOperacion();
    const [newRows, setNewRows] = useState([])   
    const [open, setOpen] = useState({elemento: null, abierto: false});

    const columns = [   
      { field: 'Id', headerName: 'Id', width: 90, sortable: false, hide: true },
      { field: 'Carpeta', headerName: 'Carpeta', width: 150, sortable: false },
      { field: 'Cliente', headerName: 'Cliente', width: 150, sortable: false },
      { field: 'TipoOperativa', headerName: 'Tipo de mercancía', width: 150, sortable: false },
          
      { field: 'a', headerName: 'Ver', width: 100, 
        renderCell: (params) => (
          <CloudDownloadIcon onClick={() => handleVerPdf(params.row)}/>
        ), sortable: false },

      { field: 'b', headerName: 'Eliminar', width: 100, 
        renderCell: (params) => (
          <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
        ), sortable: false },
      
  ]; 
    
    useEffect(() => {
      const fetchData = async () => {         
        const user = JSON.parse(localStorage.getItem("user"));        
        const config = {
          headers: { Authorization: `Bearer ${user.access_token}` }
        };
        const bodyParameters = {
          key: null
        }; 
        await axios(process.env.REACT_APP_API_URL + "operaciones/getdocumentostdr/" + id, 
          bodyParameters,
          config                                 
        )
        .then(response => {  
          setNewRows(response.data)

        })
        .catch(function (error) {
          if (error.response) {            
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          }
        });                       
      };
      fetchData();
      return () => { }
    }, [])

    useEffect(() => {
      setNewRows(documentosTDR)
      return () => {
      }
    }, [documentosTDR])

    const handleRemoveElement = (element) =>{
      if (modo==="R") return;
      setOpen({elemento: element, abierto: true});
    }  

    const handleVerPdf = async (element) =>{
      if (modo==="R") return;
      
      if(element){
         window.open(element.Fichero, "_blank")  
      }
       
    }     
  
    const handleClose = () => {
      setOpen({elemento: null, abierto: false});
    };

    const handleCloseDelete = (e = null) => {
      
      const fetchData = async () => {         
        const user = JSON.parse(localStorage.getItem("user"));        
        const config = {
          headers: { Authorization: `Bearer ${user.access_token}` }
        };
        const bodyParameters = {
          key: null
        }; 
        await axios(process.env.REACT_APP_API_URL + "operaciones/deletedocumentotdr/" + open.elemento.Id, 
          bodyParameters,
          config                                 
        )
        .then(response => {  
          setOpen({elemento: null, abierto: false});
          setNewRows(response.data)
        })
        .catch(function (error) {
          if (error.response) { 
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          }
        });                       
        };
      fetchData();   
    };

    return(     
      <>   
      <Grid container  spacing={0} className="mt-2 ml-1">
          <Grid item xs={12} className="" style={{height: 220}}>
              <DataGrid 
                disableColumnMenu={true}
                disableColumnReorder={true}                
                rows={newRows} 
                columns={columns} 
                pageSize={100} 
                checkboxSelection = {false}
                hideFooterPagination = {true}
                hideFooterRowCount = {true}
                hideFooterSelectedRowCount = {true}    
                getRowId={(row) => row.Id}
              />
          </Grid>          
      </Grid> 
      <div>
        <Dialog
        open={open.abierto}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Desea eliminar el documento seleccionado?"}
          </DialogTitle>       
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCloseDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      </div>
      </>     
  )


}