import React, { useState } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import AgendaNotas from './AgendaNotas';
import AgendaCalendario from './AgendaCalendario';
//import AgendaEventos from './AgendaEventos';
import AgendaContactos from './AgendaContactos';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '96%',
      maxHeight: '93vh',
      /*height: '93vh', overflow: 'auto',*/
      position: 'absolute',
      zIndex: 10,
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
    },
    cabecera: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignContent: 'center',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    agenda: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
    },
    fecha: {
      fontFamily: 'Open Sans',
      color: '#90b9e8',
      fontWeight: 600,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    image: {
      position: 'relative',
      height: 200,
      [theme.breakpoints.down('xs')]: {
        width: '100% !important', 
        height: 100,
      },
      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.15,
        },
        '& $imageMarked': {
          opacity: 0,
        },
        '& $imageTitle': {
          border: '4px solid currentColor',
        },
      },
    },
    focusVisible: {},
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
    },
    imageSrc: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
      position: 'relative',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
    },
    root2: {
      width: 90,
      minWidth: 90,
      maxWidth: 90, 
      padding: 5,
      flex:1,
  
    },
    media: {
      height: 90,
    },
    root3: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 500,
      height: 450,
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 5,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const Agenda = (props) => {
    const classes = useStyles();
    const [numAvisos, setNumAvisos] = useState(0)

    let fecha = new Date();
    let meses = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
    let fechaTexto = fecha.getDate()+" "+ meses[fecha.getMonth()] + " " + fecha.getFullYear();

    const getNumNotas = (numNotas) => {
      setNumAvisos(numNotas)      
    }
  
    return (
        <>
          {/* AGENDA */}
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <div className={classes.cabecera}>
                <StyledBadge badgeContent={numAvisos} color="secondary">
                  <Typography className={`${classes.heading} ${classes.agenda} mr-4`}>AGENDA</Typography>
                </StyledBadge>
                
                <Typography className={`${classes.heading} ${classes.fecha} ml-4`}>{fechaTexto}</Typography>
              </div>
              </AccordionSummary>
              <AccordionDetails className="d-block" >
                <AgendaNotas handleNumNotas={getNumNotas}/>
                <AgendaCalendario />                
                <AgendaContactos />
              </AccordionDetails>
            </Accordion>
          </div> 
          </>
        
    );
}
export default Agenda;