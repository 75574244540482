import axios from "axios";
import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

export default function FormFieldComboUncontrolled({propiedades, onChangeValue=null, valor=null, control=null}) {

  const [open, setOpen] = useState(false);  
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [selectedCategory, setSelectedCategory] = useState(propiedades.value || (propiedades.multiple === true ? [] : ''));
  const { modo } = useParams();
  
  useEffect(() => {   
    let active = true;
    if (!loading) {
      //return undefined;
    }
    (async () => {
      const respClientes = await axios(process.env.REACT_APP_API_URL + propiedades.urlData);      
      if (active) {
          if(respClientes.data.length > 0 ){
            setOptions(Object.keys(respClientes.data).map((key) => respClientes.data[key]))
          } else {
            const etiquetas = propiedades.campoEtiqueta.split(',')
            const etiqueta = etiquetas[0];
            const sinDatos = [{ 
              Id: -1,
              [etiqueta]: "Sin datos"
            }]
            setOptions(sinDatos)
          }
      }
    })();
    return () => {
      active = false;
    };
  }, [loading, propiedades.urlData, propiedades.campoEtiqueta]);

  useEffect(() => {
    if (!open) {      
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    setSelectedCategory(propiedades.value)
  }, [propiedades.value])

  const renderInput = (params) => {  

    const multipleRequired = Array.isArray(selectedCategory) && selectedCategory.length === 0
    const singleRequired = (selectedCategory === "" || selectedCategory === null) && propiedades.required;
    const isRequired = (singleRequired || multipleRequired) && propiedades.required
   
   return (
    <TextField  
      {...params}
      label={propiedades.label}
      required={isRequired}
      variant="outlined"      
      size="small"
      fullWidth      
      InputProps={{
        ...params.InputProps,
        endAdornment: (
        <>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {params.InputProps.endAdornment}
        </>
      ),
    }}             
    />
    )
  }
  
  const handleChange = (v) => { 
    if(onChangeValue)
      onChangeValue(v)        

    if(propiedades.onChangeValue)
      propiedades.onChangeValue(v)

    setSelectedCategory(v)
  }  

  const getOptionLabel = (option) => {   
    let etiqueta = "";
    if(option){      
      const etiquetas = propiedades.campoEtiqueta.split(',')
      etiquetas.forEach(element => {
        etiqueta += option[element] + " " 
      });      
    }  
    return etiqueta  
  } 

  const renderOption = (option) => {    
    let etiqueta = "";
    const etiquetas = propiedades.campoEtiqueta.split(',')
      etiquetas.forEach(element => {
        etiqueta += option[element] + " " 
      });  
    
    let descripcion = false;
    if(typeof(option["Descripcion"]) !== 'undefined'){
        descripcion = option["Descripcion"].length > 0
    }

    return(
      <React.Fragment>        
        {etiqueta}
        {descripcion && 
          <LightTooltip title={option['Descripcion']}>
            <IconButton aria-label="delete">
              <InfoIcon />
            </IconButton>
          </LightTooltip>
          
        }
      </React.Fragment>
    )
  } 

  const getOptionSelected = (option) => {      
    
    const campoId = propiedades.campoId ? propiedades.campoId : "Id"    

    if(!selectedCategory) return true; 
    
    if(propiedades.multiple === true){
      const estaArray = selectedCategory.filter(selected => selected[campoId] == option[campoId])
      return estaArray.length > 0
    }   

    
    
    return option[campoId] == selectedCategory[campoId]
  }

  const getOptionDisabled = (option) => {
    const campoDesactivar = propiedades.campoDesactivar ? propiedades.campoDesactivar : "Desactivar"
    
    return parseInt(option[campoDesactivar]) === 0
  }

  const deshabilitado = (
    typeof(propiedades.disabled) === "undefined" ? 
    modo==="R" ?  true : false : propiedades.disabled
  )
  
  return (    
    <Grid item xs={propiedades.xs} className="" >
    <Controller    
    name={propiedades.name}    
    control={control}    
    defaultValue={ selectedCategory }    
    render={({onChange, ...props}) => (             
          <Autocomplete
            multiple = {propiedades.multiple}     
            id={'_'+propiedades.name}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option) => getOptionSelected(option)}
            getOptionDisabled={(option) => getOptionDisabled(option)}
            renderInput={renderInput}
            renderOption={renderOption}
            disabled={deshabilitado}
            onChange={(e, data) => {
              handleChange(data)              
              onChange(data)
              }}
            value={ selectedCategory }
           
          />        
      )}    
      />
    </Grid>
    
  )
}