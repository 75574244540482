import React, { useState } from "react";
import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export default function FiltroListado({urlfecha, fechaInicial, fechaFinal}) { 

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  }));
  
  const classes = useStyles();
  const[fechaInicio, setFechaInicio]=useState(fechaInicial);
  const[fechaFin, setFechaFin]=useState(fechaFinal);    
      
  const StyledButton = withStyles((theme) => ({
    root: {
      color: 'black',
      backgroundColor: '#c4c4c4',
      height: 32,
      marginTop: 0,          
      width: 80,
      '&:hover': {
        backgroundColor: '#777777',
      },
    },
  }))(Button);

  let propiedadesFechaInicio = {
    required: true,
    label: "Fecha Inicio",
    name: "fechaInicio",
    type: "date",    
    value: fechaInicio,
    xs:12
  }
      
  let propiedadesFechaFin = {
    required: true,
    label: "Fecha Fin",
    name: "fechaFin",
    type: "date",    
    value: fechaFin,
    xs:12
  }

  const handleChangeFechaInicio = (valor) => {
    setFechaInicio(valor)
  }

  const handleChangeFechaFin = (valor) => {
    setFechaFin(valor)
  }

  const handleGetFecha = async () => { 
    const SERVER_URL = "?fechainicial=" + fechaInicio + "&fechafinal=" + fechaFin;    
    urlfecha({url: SERVER_URL, fechaInicio: fechaInicio, fechaFin: fechaFin})
  }

  const handleSetFecha = async () => { 
    const SERVER_URL = "";   
    setFechaInicio('') 
    setFechaFin('')
    urlfecha({url: SERVER_URL, fechaInicio: '', fechaFin: ''})
  }

  return(
    <Grid container spacing={1} className="">

      <Grid item xs={5} className="" >
      </Grid>
      
      <Grid item xs={2} className="" >
        <FormFieldDateUncontrolled propiedades={propiedadesFechaInicio} onChangeValue={handleChangeFechaInicio} />
      </Grid> 

      <Grid item xs={2} className="" >   
        <FormFieldDateUncontrolled propiedades={propiedadesFechaFin} onChangeValue={handleChangeFechaFin} />
      </Grid>

      <Grid item xs={1} className="" >
        <StyledButton size="small" className={classes.margin}
          onClick={handleGetFecha}>
          Filtrar
        </StyledButton>
      </Grid>      

      <Grid item xs={1} className="ml-1" >
        <StyledButton size="small" className={classes.margin}
          onClick={handleSetFecha}>
          Limpiar
        </StyledButton>
      </Grid> 

    </Grid>
  )
}

