const columnsProductos = [
    {
        name: "Id",
        label: "id",
        options: {
         filter: false,
         sort: true,
         display: 'excluded',
        }
       }, 
       {
        name: "Operativa",
        label: "Operativa",
        options: {
         filter: true,
         sort: true,
        }
       }, 
    {
      name: "categoria",
      label: "Categoría",
      options: {
       filter: true,
       sort: true,
      }
     },  
    {
        name: "Subcategoria",
        label: "Subcategoría",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "codigo",
        label: "Código",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "nombre",
        label: "Nombre Producto",
        options: {
         filter: false,
         sort: true,
        }
       },
       {
        name: "estado",
        label: "Estado",
        options: {
         filter: true,
         sort: true,
        }
       },  
       {
        name: "unidad_medida",
        label: "Unidad",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "periodicidad",
        label: "Periodicidad",
        options: {
         filter: true,
         sort: true,
         
        }   
       },  
       {
          name: "tarifa",
          label: "Tarifa",
          options: {
           filter: false,
           sort: true,
           
          }  
        },   
         
    ];
export {columnsProductos};
  
const columnsTarifas = [
    {
      name: "Id",
      label: "Nº",
      options: {
       filter: false,
       sort: true,
      }
    },
    {
        name: "CodigoCliente",
        label: "Código cliente",
        options: {
         filter: false,
         sort: true,
        }
    },
    {
        name: "Razon_Social",
        label: "Cliente",
        options: {
         filter: true,
         sort: true,
        }
    },
    {
        name: "Codigo",
        label: "Código tarifa",
        options: {
         filter: false,
         sort: true,
        }
    },
    {
        name: "Fecha_Alta",
        label: "Fecha Alta",
        options: {
         filter: false,
         sort: true,
        }
    },
    {
        name: "ficheros",
        label: "Documento",
        options: {
         filter: false,
         sort: true,
        }
    },
    {
        name: "Estado",
        label: "Estado",
        options: {
         filter: true,
         sort: true,
        }
    },
];
export {columnsTarifas};