import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1, 
      padding: theme.spacing(1),
      margin: '10px',        
      backgroundColor: '#FFF',
  },    
}))

function compare( a, b ) {
  if ( parseFloat(a.total) < parseFloat(b.total) ){
    return 1;
  }
  if ( parseFloat(a.total) > parseFloat(b.total) ){
    return -1;
  }
  return 0;
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  
  const convierteFormatoNumericoCon2Decimales = (datoNumerico) => {
    const formatter = new Intl.NumberFormat('de-DE', {
      decimalSeparator: ",",
      minimumFractionDigits: 2,
    });
    return formatter.format(datoNumerico);
  }

  const total = convierteFormatoNumericoCon2Decimales(row.total);
  const porcentaje = convierteFormatoNumericoCon2Decimales(row.porcentaje);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.name}</TableCell>
        <TableCell align="right">{total}</TableCell>
        <TableCell align="right">{porcentaje}</TableCell>        
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, margin: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Categoría</TableCell>
                    <TableCell align="center">€</TableCell>
                    <TableCell align="center">%</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(row.categorias) && row.categorias.map((clienteRow) => (
                    <TableRow key={clienteRow.categoria}>
                      <TableCell component="th" scope="row">{clienteRow.categoria}</TableCell>
                      <TableCell align="right">{convierteFormatoNumericoCon2Decimales(clienteRow.total)}</TableCell>
                      <TableCell align="right">{convierteFormatoNumericoCon2Decimales(clienteRow.total / row.total * 100)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    porcentaje: PropTypes.number.isRequired,
    categorias: PropTypes.arrayOf(
      PropTypes.shape({
        categoria: PropTypes.string.isRequired,
        cliente: PropTypes.string.isRequired,
        total: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};


export default function RentabilidadXClientes({filtroFechas, returnData = null}) {
  const classes = useStyles();
  const [datosVentas, setDatosVentas] = useState([]) 

  useEffect(() => {         
    const fetchData = async () => {                     
      const respdatos = await axios(`${process.env.REACT_APP_API_URL}informes/rentabilidadxclientes${filtroFechas}`)               
      if(returnData) returnData(respdatos.data)
      setDatosVentas(respdatos.data)
    };    
    fetchData();  
  }, [filtroFechas])

  const initialValue = 0;
  const sumTotalWithInitial = datosVentas.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.total), initialValue);

  const sumsByCustomer = datosVentas.reduce((acc, obj, cI) => {    
    const customer = obj.cliente;
    acc[customer] = acc[customer] ? 
    {name: customer, total: acc[customer].total + parseFloat(obj.total), porcentaje: ((acc[customer].total + parseFloat(obj.total))/sumTotalWithInitial)*100}: 
    {name: customer, total: parseFloat(obj.total), porcentaje: (parseFloat(obj.total)/sumTotalWithInitial)*100};
    return acc;
  }, {});

  const propertyNames = Object.keys(sumsByCustomer);
  
  let arrayOfValues = [];
  if(Array.isArray(propertyNames)){
    propertyNames.forEach(function (arrayItem) {
      let datosVentaCliente = datosVentas.filter(obj => {
        return obj.cliente === arrayItem
      })
      datosVentaCliente.sort( compare );      
      arrayOfValues.push({ ...sumsByCustomer[arrayItem], categorias: datosVentaCliente });
    })
  }
  arrayOfValues.sort( compare );
  
  return (
    <div className={classes.root}>
        <Table id="tablaClientes" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Cliente</TableCell>
              <TableCell align="center">€</TableCell>
              <TableCell align="center">%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(arrayOfValues) && arrayOfValues.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
    </div>
  );
}