import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import FormularioOportunidad from "./FormularioOportunidad"
import FichaOportunidad from "./FichaOportunidad"

import MigasDePan  from "../../../../../components/Common/MigasDePan";

const Oportunidad = (props) => {
    let { id, modo } = useParams();
    const [codigo, setCodigo] = useState("")

      const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar Oportunidad" : "Cancelar Edición",
          url: 
          typeof id === "undefined" ? `/crm/oportunidades/` :
          modo === "R" ? 
            `/crm/oportunidades/oportunidad/${id}/W` : 
            `/crm/oportunidades/oportunidad/${id}/R`,
          activo: true,
        },
      ];

      const urlsMigasdepan = [
        {titulo: "Oportunidades", colorTexto: "texto-comercial", urlTo: "/crm/oportunidades"},
        {titulo: "Oportunidad", colorTexto: "texto-comercial", urlTo: `/crm/oportunidades/oportunidad/${id}/${modo}`},
      ]
      

      const changeCode = (code) => {      
        setCodigo(code)
      }

      return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="CRM"
              claseCabecera = "bg-comercial"
              buscar={false}
              imagenCabecera={"4-1-2-oportunidades.png"}
            />    
            
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">                   
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>                                        
                      <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                        {modo === "R" && <FichaOportunidad setCodigo={changeCode}/>}    
                        {modo !== "R" && <FormularioOportunidad setCodigo={changeCode}/>}   
                      </div>
                  </div>
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>            
          </div>
        </>
      );
}
export default Oportunidad;