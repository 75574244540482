import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));
  
  const FormularioCuentaIdentificacion = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false); 
    const [cuenta, setCuenta] = useState([])
    const [datosdesplegables, setDatosDesplegables] = useState({
      estados: null,
      tipologias: null,
      empresas: null,
      sectores: null,
    });
    const [campos, setCampos] = useState([]) 
    const [estado, setEstado] = useState()

    const handleChangeEstado = (valor) => {
      console.log(valor)
      setEstado(valor)
    }
    
    useEffect(() => {

      const fetchData = async () => { 
        
        setDatosCargados(false)  
              
        const respDatosFormulario = await axios(
          `${process.env.REACT_APP_API_URL}dic/diccionarios/formulariocuentaidentificacion`
        )

        if (modo === "W") {
          const respCuenta = await axios(
              `${process.env.REACT_APP_API_URL}cuentas/${id}`
          );    
          setCuenta(respCuenta.data)  
        }

        setDatosDesplegables({
              estados: respDatosFormulario.data.Estados,
              tipologias: respDatosFormulario.data.Tipologias,
              empresas: respDatosFormulario.data.Cuentas,
              sectores: respDatosFormulario.data.Sectores,
        });

        let Estados = respDatosFormulario.data.Estados ? respDatosFormulario.data.Estados : []
        let Tipologias = respDatosFormulario.data.Tipologias ? respDatosFormulario.data.Tipologias : []
        let Cuentas = respDatosFormulario.data.Cuentas ? respDatosFormulario.data.Cuentas : []
        let Sectores = respDatosFormulario.data.Sectores ? respDatosFormulario.data.Sectores : []

        const Activo = [{'Id': 1, 'Etiqueta': "Activa"}, {'Id': 0, 'Etiqueta': "Inactiva"}]
        
        setCampos([
          {
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Situación",
            name: "estado",
            disabled: false,
            type: "comboArray", 
            options:Estados, 
            multiple:false,   
            value: "",
            onChangeValue: handleChangeEstado,
            xs:4
          },    
          {
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Tipología",
            name: "tipologia",
            disabled: false,
            type: "comboArray", 
            options:Tipologias, 
            multiple:true,   
            value: [],
            xs:4
          }, 
          {
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Sector",
                name: "sector",
                disabled: false,
                type: "comboArray", 
                options:Sectores, 
                multiple:true,   
                value: [],
                xs:4
          },
          {
                required: true,
                label: "CIF",
                name: "cif",
                type: "textCIF",              
                value: "",
                xs:4
          },
          {
                required: false,
                label: "VAT",
                name: "vat",
                type: "text",              
                value: "",
                xs:4
          },
          {
            required: false,
            label: "Cod. Agente Poseidón",
            name: "codAgente",
            type: "text",              
            value: "",
            xs:4
          },
          {
                required: false,
                label: "Nombre Comercial",
                name: "nombreComercial",
                type: "text",              
                value: "",
                xs:8
          },
          {
                required: true,
                label: "Teléfono",
                name: "telefono",
                type: "text",              
                value: "",
                xs:4
          },
          {
                required: true,
                label: "Razón Social",
                name: "razonSocial",
                type: "text",              
                value: "",
                xs:8
          },
          {
                required: true,
                label: "Email",
                name: "email",
                type: "text",              
                value: "",
                xs:4
          }, 
          {
                required: false,
                label: "Grupo",
                name: "grupo",
                type: "text",
                value: "",
                xs:4
          },       
          {
                required: false,
                label: "WEB",
                name: "web",
                type: "text",              
                value: "",
                xs:4
          },
          {
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Estado",
            name: "activo",
            disabled: false,
            type: "comboArray", 
            options:Activo, 
            multiple:false,   
            value: '',
            xs:4
          },    
          {
                required: false,
                label: "Observaciones",
                name: "observaciones",
                type: "textArea",              
                value: "",
                xs:12
          },                
        ])

        setDatosCargados(true) 
            
      }; 
      
      fetchData();
      
      return () => {
        setCuenta([]) 
        //setDatosCargados(false);
      }
        
    },[]);

    useEffect(() => {  
        
      let idsS = []   
      let idsT = [];
        
      if (typeof campos !== "undefined" && Object.keys(campos).length > 0){
          if (typeof cuenta !== "undefined" && Object.keys(cuenta).length > 0) { 
             
            if (typeof cuenta.Sectores !== "undefined"){
                idsS = cuenta.Sectores.map((s) => {
                    return s.Id;
                });     
            }     

            if (typeof cuenta.Tipologias !== "undefined"){
                idsT = cuenta.Tipologias.map((t) => {
                    return t.Id;
                });      
            } 

            console.log(cuenta)

            const estado =  cuenta ? cuenta.Fk_Estado ? cuenta.Fk_Estado : "" : ""
            const cif =  cuenta ? cuenta.CIF ? cuenta.CIF : "" : ""
            const vat =  cuenta ? cuenta.Vat_Number ? cuenta.Vat_Number : "" : ""
            const nombre =  cuenta ? cuenta.Nombre_Comercial ? cuenta.Nombre_Comercial : "" : ""
            const telefono =  cuenta ? cuenta.Telefono ? cuenta.Telefono : "" : ""
            const razon =  cuenta ? cuenta.Razon_Social ? cuenta.Razon_Social : "" : ""
            const email =  cuenta ? cuenta.Email ? cuenta.Email : "" : ""
            const grupo =  cuenta ? cuenta.Grupo ? cuenta.Grupo : "" : ""
            const web =  cuenta ? cuenta.Web ? cuenta.Web : "" : ""
            const observaciones =  cuenta ? cuenta.Observaciones ? cuenta.Observaciones : "" : ""
            const codAgente =  cuenta ? cuenta.CodAgente ? cuenta.CodAgente : "" : ""

            const estaActiva = cuenta ? cuenta.Activo === 1 ? {'Id': 1, 'Etiqueta': "Activa"} : {'Id': 0, 'Etiqueta': "Inactiva"} : {'Id': 0, 'Etiqueta': "Inactiva"}
           
            console.log(cuenta)
            console.log(cuenta.CIF, cif)
            setDatosCargados(false);
                  
            campos[0].value = cuenta.Estado //estado
            campos[1].value = cuenta.Tipologias //idsT
            campos[2].value = cuenta.Sectores //idsS
            campos[3].value = cif
            campos[4].value = vat
            campos[5].value = codAgente
            campos[6].value = nombre
            campos[7].value = telefono
            campos[8].value = razon
            campos[9].value = email
            campos[10].value = grupo
            campos[11].value = web
            campos[12].value = estaActiva
            campos[13].value = observaciones

            if(cuenta.Estado){
              if(cuenta.Estado.Etiqueta === "POTENCIAL"){
                campos[1].required = false
                campos[2].required = false
                campos[3].required = false
                campos[7].required = false
                campos[9].required = false
    
              }
              if(cuenta.Estado.Etiqueta === "REAL"){
                campos[1].required = true
                campos[2].required = true
                campos[3].required = true
                campos[7].required = true
                campos[9].required = true
              }
    
            }

            
            setDatosCargados(true);
          }
          
      }

    },[campos, cuenta])

    useEffect(() => {
      console.log(campos, cuenta)
      if(campos.length > 0){
        if(estado){
          if(estado.Etiqueta === "POTENCIAL"){
            campos[1].required = false
            campos[2].required = false
            campos[3].required = false
            campos[7].required = false
            campos[9].required = false

          }
          if(estado.Etiqueta === "REAL"){
            campos[1].required = true
            campos[2].required = true
            campos[3].required = true
            campos[7].required = true
            campos[9].required = true
          }

        }
        console.log("CAMBIAR ESTADO CAMPOS")
      }
      return () => {
        setEstado()
      }
    }, [estado])
  
    if (datoscargados){
      return (  
        <>
        <h6 className="texto-comercial" id="title">
          Identificación
        </h6>
        <FormularioEntidad 
          urlForm = "cuentas"
          urlGoto = "/crm/cuentas/cuenta/"
          campos={campos}
        /> 
        </>
      )
    }
    else {
      return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
      );
    }
  }

  export default FormularioCuentaIdentificacion;