import React, { useState, useEffect } from "react";
import {usePlanificacion} from './planificacion-context'
import Loading from "../../../../../components/Common/Loading";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import { useParams } from "react-router-dom";
import FormularioPlanificacionManoMercancias from './FormularioPlanificacionManoMercancias'
import FormularioPlanificacionManoDatos from './FormularioPlanificacionManoDatos'
//import FormularioPlanificacionManoRecursos from './FormularioPlanificacionManoRecursos'
import { v4 as uuidv4 } from 'uuid';

function TabPanel(props) {
  const { children, value, index, ...other } = props;    
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
  
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const FormularioPlanificacionMano = ({index}) => {      
  let { id, modo } = useParams();
  const [mercanciaDisabled, setMercanciaDisabled] = useState(false)  
  const [openAlert, setOpenAlert] = useState(false);  
  const [isLoading, setIsLoading] = useState(false);   
  const [value, setValue] = React.useState(0);
  

  const {planificacion, manosPlanificacion, datosJornadas, setDatosJornadas, manosMercancia, 
    setManosMercancia, setCostesDirectos, setManosPlanificacion, datosMercancia, setDatosMercancia} = usePlanificacion();
  
  const calculaCosteTotalManos = (mano=0) => {
    let costeTotalMano = 0;  
    let ajusteCPE = 0;
    if(datosJornadas.length > 0 ){       
      datosJornadas.forEach(element => { 
        if(mano){
          if(mano === parseInt(element.elementos.mano)){
            let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
            let cantidad = parseFloat(element.elementos.cantidad)
            costeTotalMano +=  coste * cantidad;     
            ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0
          } 
        }else{
          let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
          let cantidad = parseFloat(element.elementos.cantidad)
          costeTotalMano +=  coste * cantidad;     
          ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0             
        }
      });  
    }
    return (costeTotalMano+ajusteCPE).toFixed(2)
  }  

  const {setManoActiva} = usePlanificacion();
  useEffect(() => {       
    setManoActiva(index)    
  },[index])

  useEffect(() => {  
    const desactivarPaneles = modo === "W" && id ? false : true;
    setMercanciaDisabled(desactivarPaneles)
  },[modo, id])

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert(false);
  };

  const handleChange = (event, newValue) => {
    let errores = false;
    
    
    if(errores === true) {            
        alert("Debe rellenar los campos obligatorios de los datos del pedido")
        return;
    }
    setValue(newValue);
  };

  const setAlert = (v) => {   
    setOpenAlert(v)
  }

  
    
  let costeTotalMano = 0;
  costeTotalMano = calculaCosteTotalManos(index)
  
  const options2 = { style: 'currency', currency: 'EUR' };
  const numberFormat2 = new Intl.NumberFormat('es-ES', options2);
  costeTotalMano = numberFormat2.format(costeTotalMano)

  return (
    <>    
    {!isLoading && (
      <>
      <Paper className="mt-0" square>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example">
          <Tab label="DATOS" />
          <Tab label="RECURSOS" disabled={mercanciaDisabled}/>     
          {/*<Tab label="RECURSOS" disabled={mercanciaDisabled}/>*/}               
        </Tabs>
            
      </Paper>
      <Paper className="mt-0" square>
      <div className="col-12 mt-4 mb-2  float-right">
          <span className="ml-1 costeManoRecursos float-right">
            {costeTotalMano}
          </span>
          <span className="tituloRecursos mr-2 float-right">Coste Mano {index}</span> 
        </div>
        
      </Paper>
        
      <div className="">   

        {/* DATOS PANEL */}
        <TabPanel value={value} index={0}> 
          <FormularioPlanificacionManoDatos index={index} setAlert={setAlert}/>               
        </TabPanel>

        {/* MERCANCIA PANEL */}
        <TabPanel value={value} index={1}>
          <FormularioPlanificacionManoMercancias index={index} /> 
        </TabPanel>

        {/* RECURSOS PANEL */}
        {/*
        <TabPanel value={value} index={2}>
          <FormularioPlanificacionManoRecursos index={index} />        
        </TabPanel>
        */}

        {openAlert && 
        <AlertaMensaje 
          mensaje={"Datos guardados correctamente"} 
          isOpen={openAlert} 
          tipoMensaje="success"
          cerrar={handleCloseAlert}/>
        }     
              
      </div>

      </>
    )}
    <div>{isLoading && <Loading isLoading={isLoading} />}</div>

    </>
  );    
};

  export default FormularioPlanificacionMano;