import React, { useState } from "react";
import {columnsMaterialesListado,columnsMaterialesEntrada,columnsMaterialesSalida} from "./LogisticaConf";
import {botoneraMaterialesListado,botoneraMaterialesEntradas, botoneraMaterialesSalidas} from "./BotonesConf";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";

const Materiales = (props) => {  
  const [idSubOperacion, setIdSubOperacion]=useState(parseInt(localStorage.getItem('idOperacionMateriales')) || 0);    
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);

  let columnasCuerpoTabla = columnsMaterialesListado;
  let urlDatosCuerpoTabla = "materials";
  let urlDoubleClickCuerpoTabla = "/logistica/materials/material/";
  let columnIdCuerpoTabla = "id";
  let botonaccionCuerpoTabla = botoneraMaterialesListado;

  if(idSubOperacion === 1){
    botonaccionCuerpoTabla= botoneraMaterialesEntradas
    columnasCuerpoTabla = columnsMaterialesEntrada      
    urlDatosCuerpoTabla = "entradamaterials"
    urlDoubleClickCuerpoTabla = "/logistica/materials/entradas/"
    columnIdCuerpoTabla="id"
  }

  if(idSubOperacion === 2){
    botonaccionCuerpoTabla= botoneraMaterialesSalidas
    columnasCuerpoTabla = columnsMaterialesSalida
    urlDatosCuerpoTabla = "salidamaterials"
    urlDoubleClickCuerpoTabla = "/logistica/materials/salidas/"
    columnIdCuerpoTabla="id"
  }

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }
  
  const handleOperacion = (idO) =>{
    localStorage.setItem('idOperacionMateriales', idO);  
    setIdSubOperacion(idO)
  }   
      
  return (           
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Logística interna"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-1-2-materiales.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Materiales
              </h6>
            </div>
            <hr className="m-0 bg-comercial mb-1" />
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                <Pasos pasos={['Listado', 'Entradas', 'Salidas']} idPasoActivo={idSubOperacion} changePasoActivo={handleOperacion} />
                
                <CuerpoTablaPiezas
                columnas = {columnasCuerpoTabla}
                urlDatos = {urlDatosCuerpoTabla}
                urlDoubleClick = {urlDoubleClickCuerpoTabla}
                columnId = {columnIdCuerpoTabla}
                getFilaSeleccionada = {marcarFila}
                /> 

            </div>
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
            <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla}
              />   
            </div>
          </div>

        </div>
          
      </div>   
    
  );
}
export default Materiales;