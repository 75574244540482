import React from "react";
import RecursosManoDetalleMaquinariaDesgloce from "./RecursosManoDetalleMaquinariaDesgloce"


const getCosteTotalMaquinaria = (datosJornadas) => {
    let maquinariaPropia = []
    let maquinariaExterna = []

    if(datosJornadas && datosJornadas.length > 0){
        maquinariaPropia = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo principal propio")
        maquinariaExterna = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo principal externo")  
    }

    let costeTotalMaquinaria = 0;
    let costeTotalMaquinariaPropia = 0;
    let costeTotalMaquinariaExterna = 0;
    let unidadesTotalMaquinariaPropia=0;
    let unidadesTotalMaquinariaExterna=0;    

    maquinariaPropia.forEach(maquinaria => {        
        const coste = parseFloat(maquinaria.elementos.Coste ? maquinaria.elementos.Coste : 0);
        const cantidad = parseFloat(maquinaria.elementos.cantidad ? maquinaria.elementos.cantidad : 0);        
        costeTotalMaquinaria += coste * cantidad
        costeTotalMaquinariaPropia += coste * cantidad
        unidadesTotalMaquinariaPropia += cantidad        
    })

    maquinariaExterna.forEach(maquinaria => {
        const coste = parseFloat(maquinaria.elementos.Importe ? maquinaria.elementos.Importe : 0);
        const cantidad = parseFloat(maquinaria.elementos.cantidad ? maquinaria.elementos.cantidad : 0);
       
        costeTotalMaquinaria += coste * cantidad
        costeTotalMaquinariaExterna += coste * cantidad
        unidadesTotalMaquinariaExterna += cantidad
    })

    return {
        costeTotalMaquinaria: costeTotalMaquinaria, 
        costeTotalMaquinariaPropia: costeTotalMaquinariaPropia,
        costeTotalMaquinariaExterna: costeTotalMaquinariaExterna,
        unidadesTotalMaquinariaPropia: unidadesTotalMaquinariaPropia,
        unidadesTotalMaquinariaExterna: unidadesTotalMaquinariaExterna,        
    }
}


const RecursosManoDetalleMaquinariaCabecera = ({manos, datosJornadas}) =>
{
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);

    const costeTotalMaquinariaAgrupado = getCosteTotalMaquinaria(datosJornadas)    
    const costeTotalMaquinaria = numberFormat2.format(costeTotalMaquinariaAgrupado.costeTotalMaquinaria)    

    return (
        <>
        <div className="col-12 mt-4 d-flex overflow-auto justify-content-center">
            <span className="col-11 float-left">Maquinaria</span><span className="col-1 float-right">{costeTotalMaquinaria}</span>            
        </div>
        <hr style={{height:1, width:'100%'}} className="m-0 bg-comercial mb-4" />

        {manos &&  manos.map((mano, index) => (
            <React.Fragment key={index}>
            <div className="borderFormulario">
                <div className="col-12 d-flex overflow-auto justify-content-center borderFormulario mb-4">
                    <div className="col-1 float-left">Jornada </div>
                    <div className="col-5 float-right">{mano.Jornada}</div>
                    <div className="col-3 float-left"></div>
                    <div className="col-1 float-left">Mano </div>
                    <div className="col-2 float-right">{mano.Mano}</div>
                </div>

                <RecursosManoDetalleMaquinariaDesgloce                    
                    datosJornadas={datosJornadas} 
                    mano = {mano.Mano}                 
                />
                
            </div>
            </React.Fragment>
        ))}          
        </>
    )

}
export default RecursosManoDetalleMaquinariaCabecera;