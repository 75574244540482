import React from "react";
import CuerpoTabla from "../../../../../components/Common/CuerpoTabla";

const Buques = () => {
  const urlDatos = "buques"
  const BOTONERA_ACCIONES = [
    { etiqueta: "Crear Buque", url: "/crm/buque", activo: true },
    { etiqueta: "Editar buque", url: "/crm/buques/filaSeleccionada/W", activo: false, }, 
  ]; 

  return (
    <CuerpoTabla
      tituloSearchBar = "CRM"
      claseBGSearchBar = "bg-comercial"
      titulo="Buques"
      columnas = {COLUMNS_BUQUES}
      botonera = {BOTONERA_ACCIONES}
      urlDatos ={urlDatos}
      urlDoubleClick = "/crm/buques/"
      columnId="Id"
      imagenCabecera={"4-1-3-actividades.png"}
    />
  )  
};

export default Buques;

const COLUMNS_BUQUES = [
  {
    name: "Id",
    label: "Id",
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
     }
  },
  {
    name: "IMO",
    label: "IMO",
    options: {
     filter: true,
     sort: true,
    }
   },
    {
     name: "Nombre",
     label: "Nombre",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "CodBuque",
      label: "Codigo Buque",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
     name: "Fk_Tipo_Buque",
     label: "Tipo de buque",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "Eslora",
     label: "Eslora",
     options: {
      filter: true,
      sort: true,
     }
    },      
    {
      name: "Manga",
      label: "Manga",
      options: {
       filter: true,
       sort: true,
      }   
    }, 
    {
      name: "Calado",
      label: "Calado",
      options: {
       filter: true,
       sort: true,
      }   
    },  
];