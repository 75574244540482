import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useCotizacion} from './cotizacion-context'
import { useParams } from "react-router-dom";
import axios from "axios";
import FormFieldFiles from "../../../../../components/Common/FormFieldFiles";
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import NumberFormat from "react-number-format";
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablaPresupuestoCotizacionProductos from "../../../../../components/Common/TablaPresupuestoCotizacionProductos";
import FormularioPresupuestoCotizacionProductos from "../../../../../components/Common/FormularioPresupuestoCotizacionProductos";
/*
Lo ideal sería obtener los valores de la BBDD
*/
const listadoExclusiones = [
    {index: 1, label: 'Nominación de mano para realizar el destrincaje/trincaje de la carga y material.'},
    {index: 2, label: 'Superficie y guardería.'},
    {index: 3, label: 'Remociones (bordo-bordo, tierra-tierra, bordo-tierra).'},
    {index: 4, label: 'Posterior entrega de la mercancía / previa recepción'},
    {index: 5, label: 'Retraso que pueda sufrir la operativa por motivos ajenos a la Terminal (tripulación, estiba mercancía, estado de la mercancía, falta de camiones, meteorológicas, etc).'},
    {index: 6, label: 'Retraso llegada buque.'},
    {index: 7, label: 'Manipulación de elementos del buque (tapas de bodega, escala, etc).'},
    {index: 8, label: 'Tasa portuarias, cuota estiba, tasa actividad, costes aduaneros.'}
]

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 16,
    },
}))(Tooltip);

const PresupuestoCotizacion = () => {   
    let { id, modo } = useParams(); 
    const { control, handleSubmit, setValue, getValues } = useForm(); 
    const { cotizacion, costesDirectos, setCostesDirectos, 
        datosCotizacion, datosPresupuesto, datosJornadas, setDatosPresupuesto } = useCotizacion();   
    const [ciPorcentaje, setCIPorcentaje] = useState(10)
    const [biPorcentaje, setBIPorcentaje] = useState(20)
    const [eurosTon, setEurosTon] = useState(0)
    const [toneladas, setToneladas] = useState(0)
    const [manos, setManos] = useState(0)
    const modoLectura = modo === "R" ? true : false
    const [openAlert, setOpenAlert] = useState(false);
    const [pdfCotizacion, setPdfCotizacion] = useState([{
        Nombre: datosCotizacion.pdfCotizacion ? datosCotizacion.Codigo + ".pdf" : "",
        Ubicacion: datosCotizacion.pdfCotizacion
    }])
    const [valuesRadioButton, setValuesRadioButton] = React.useState({etiqueta1: '', etiqueta2: ''});
    const [listadoProductos, setListadoProductos] = useState([])

    
    const handleRadioChange = (event) => {
        //console.log(event.target.value)
        let etiqueta = ""; //event.target.value === "€/mano" ? "Manos" : "Toneladas"
        switch(event.target.value)
        {
            case "€/mano":
                etiqueta = "Manos"
                break;
            case "€/tonelada":
                etiqueta = "Toneladas"
                break;
            case "€/total":
                etiqueta = "Total"
                break;
            case "Sin total":
                etiqueta = "Sin total"
                break;
            default:
                etiqueta = ""
        }
        setValuesRadioButton({etiqueta1: etiqueta, etiqueta2: event.target.value});
      };
    
    const generaPDF = async () => {            
        
        let datos=[];
        if(id){
            datos["idCotizacion"] = id; 
        }   
        else{
            datos["idCotizacion"] = cotizacion; 
        }       
        
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'cotizaciones/generapdf/' + datos["idCotizacion"];
              
        const res = await axios.get(
            SERVER_URL 
            /*, 
            { headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
            },            
            datos }*/).
            then((response) => {
                if(response.data){
                    //console.log(response.data)
                    setPdfCotizacion([
                        {Nombre: datosCotizacion.Codigo + ".pdf",
                        Ubicacion: response.data}])
                }
            })
            .catch((error) => {
                console.log("error " + error);
                console.log(error.response.data); // => the response payload 
                console.log(error.response.data.message)
                if(error.response.data.message === "Division by zero")
                    alert("Debe indicar el número de manos/toneladas")

            });       
    }  
    
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }  
        setOpenAlert(false);
      }; 

    const handleSubmitPost = async (datos) => { 
        //console.log(valuesRadioButton)
        if((valuesRadioButton.etiqueta1 === "Toneladas" || valuesRadioButton.etiqueta1 === "Manos") && parseFloat(manos) === 0){            
            alert("Debe indicar el número de " + valuesRadioButton.etiqueta1);
            return;
        }

        if(id){
            datos["idCotizacion"] = id; 
        }   
        else{
            datos["idCotizacion"] = cotizacion; 
        }        

        //{etiqueta1: 'Toneladas', etiqueta2: '€/tonelada'}
        //{etiqueta1: 'Manos', etiqueta2: '€/mano'}
        //{etiqueta1: '', etiqueta2: '€/total'}
        let manosOtoneladas = 0;

        switch(valuesRadioButton.etiqueta2){
            case  "€/tonelada":
                manosOtoneladas = 1;
                break;
            case "€/mano":
                manosOtoneladas = 2;
                break;
            
            case "€/total":
                manosOtoneladas = 3;
                break;

            case "Sin total":
                manosOtoneladas = 4;
                break;

            default:
                manosOtoneladas = 4;
        }

        
        //manosOtoneladas = valuesRadioButton.etiqueta2 === "€/mano" ? 2 : valuesRadioButton.etiqueta2 === "€/tonelada" ? 1 : null

        let exclusiones =  +datos["exclusion1"] + ',' + +datos["exclusion2"] + ',' + +datos["exclusion3"] + ',' + +datos["exclusion4"] + ',' + +datos["exclusion5"] + ','+ +datos["exclusion6"] + ','+ +datos["exclusion7"] + ',' + +datos["exclusion8"];
        

        datos["costesindirectosporcentaje"] = ciPorcentaje
        datos["beneficioporcentaje"] = biPorcentaje
        datos["toneladaspresupuesto"] = manos
        datos["manospresupuesto"] = manosOtoneladas
        datos["exclusiones"] = exclusiones
        
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'cotizaciones/grabapresupuesto';
        //console.log(datos)
        const res = await axios.put(
            SERVER_URL , 
            { headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
            datos });                
            //console.log(res.data)
        if(!isNaN(res.data)){
            //console.log(res.data)
            generaPDF();
            setDatosPresupuesto(
                {
                    Beneficio_Industrial: datos["beneficioporcentaje"],
                    Costes_Indirectos: datos["costesindirectosporcentaje"],
                    Descripcion: datos["descripcion"],
                    Fecha_Presupuesto: datos["fechapresupuesto"],
                    costesDirectos: datos["costesdirectos"],
                    precioportonelada: datos["manospresupuesto"],
                    toneladaspresupuesto: datos["toneladaspresupuesto"],
                    exclusiones: datos["exclusiones"]
                }
            )
            setOpenAlert(true);
        }    else { console.log(res.data) }    
    }   
    
    //const numberFormat2 = new Intl.NumberFormat('es-ES');
    const costeDirecto = costesDirectos
        
    useEffect(() => {      
        let CostesDirectos = 0;        
        CostesDirectos = calculaCosteTotalManos()        
        //console.log(CostesDirectos)
        setCostesDirectos(CostesDirectos)
             
    },[datosJornadas])

    useEffect(() => {    
        //console.log(datosPresupuesto)
        if(Object.keys(datosPresupuesto).length > 0){
            if(datosPresupuesto.Costes_Indirectos){
                setCIPorcentaje(parseInt(datosPresupuesto.Costes_Indirectos))
            }
            if(datosPresupuesto.Beneficio_Industrial){
                setBIPorcentaje(parseInt(datosPresupuesto.Beneficio_Industrial))
            }        

            setValue("fechapresupuesto", datosPresupuesto.Fecha_Presupuesto)
            setValue("descripcion", datosPresupuesto.Descripcion)
            //console.log(costesDirectos)
            setValue("costesdirectos", costesDirectos/*datosPresupuesto.costesDirectos*/)
            let costesInd = /*datosPresupuesto.costesDirectos*/ costesDirectos * parseInt(ciPorcentaje) / 100;
            let costesTot = parseFloat(costesDirectos/*datosPresupuesto.costesDirectos*/) + parseFloat(costesInd)
            let beneficioInd = costesTot * parseInt(biPorcentaje) / 100
            setValue("costesindirectos", costesInd.toFixed(2))
            setValue("costestotales",  costesTot.toFixed(2))
            setValue("beneficioindustrial", beneficioInd.toFixed(2))
            setValue("presupuesto", (costesTot + beneficioInd).toFixed(2))
            //setValue("precioportonelada", datosPresupuesto.precioportonelada)

            let etiqueta1 = "";
            let etiqueta2 = "";
            switch(datosPresupuesto.precioportonelada){
                case  1:
                    etiqueta1 = "Toneladas";
                    etiqueta2 = "€/tonelada";
                    break;
                case 2:
                    etiqueta1 = "Manos";
                    etiqueta2 = "€/mano";
                    break;
                
                case 3:
                    etiqueta1 = "Total";
                    etiqueta2 = "€/total";
                    break;

                case 4:
                    etiqueta1 = "Sin total";
                    etiqueta2 = "Sin total";
                    break;

                default:
                    etiqueta1 = "Sin total";
                    etiqueta2 = "Sin total";
            }
            //let etiqueta1 = datosPresupuesto.precioportonelada === 1 ? "Toneladas" : datosPresupuesto.precioportonelada === 2 ? "Manos" : "";
            //let etiqueta2 = datosPresupuesto.precioportonelada === 1 ? "€/tonelada" : datosPresupuesto.precioportonelada === 2 ? "€/mano" : "";


            
            setValuesRadioButton({etiqueta1: etiqueta1, etiqueta2: etiqueta2});
            setValue("toneladaspresupuesto", datosPresupuesto.toneladaspresupuesto)

            setManos(datosPresupuesto.toneladaspresupuesto)

            if(datosPresupuesto.toneladaspresupuesto ){
                //console.log(parseFloat((costesTot + beneficioInd).toFixed(2)/datosPresupuesto.toneladaspresupuesto))
                setEurosTon(parseFloat((costesTot + beneficioInd)/datosPresupuesto.toneladaspresupuesto))
                setValue("toneladaspresupuesto", datosPresupuesto.toneladaspresupuesto)
                setToneladas(datosPresupuesto.toneladaspresupuesto)
            }
        }

    }, [costesDirectos, datosPresupuesto,biPorcentaje,ciPorcentaje])

    const changeValueCI = (event) => {  
        setCIPorcentaje(event.target.value)
        datosPresupuesto.Costes_Indirectos = event.target.value
        
        let costesInd = costeDirecto * parseInt(event.target.value) / 100;                
        let costesTot = parseFloat((costeDirecto) + parseFloat(costesInd))

        let beneficioInd = costesTot * parseInt(biPorcentaje) / 100
        setValue("costesindirectos", costesInd.toFixed(2))
        setValue("costestotales",  costesTot.toFixed(2))
        setValue("beneficioindustrial", beneficioInd.toFixed(2))
        setValue("presupuesto", (costesTot + beneficioInd).toFixed(2))
    }    

    const changeValueBI = (event) => {  
        setBIPorcentaje(event.target.value)  
        datosPresupuesto.Beneficio_Industrial = event.target.value      
        let costesInd = costeDirecto * parseInt(ciPorcentaje) / 100;
        let costesTot = parseFloat(costeDirecto) + parseFloat(costesInd)
        let beneficioInd = costesTot * parseInt(biPorcentaje) / 100        
        setValue("beneficioindustrial", beneficioInd.toFixed(2))
        setValue("presupuesto", (costesTot + beneficioInd).toFixed(2))
    }  

    const changeValueTon = (event) => {       
        const presupuesto = getValues("presupuesto");
        setEurosTon((presupuesto / event.target.value))
        setToneladas(event.target.value)
        setValue("toneladaspresupuesto", event.target.value)
        
    } 
    
    const changeValueManos = (event) => {       
        const presupuesto = getValues("presupuesto");
        setEurosTon((presupuesto / event.target.value))
        setManos(event.target.value)
        setValue("manospresupuesto", event.target.value)
    }   
    
    const changeTonManosPresupuesto = ( event ) => {
        //console.log(event)
        if(event.target.value)
            setManos(event.target.value)
    }

    const calculaCosteTotalManos = (mano=0) => {
        let costeTotalMano = 0;  
        let ajusteCPE = 0;
        if(datosJornadas.length > 0 ){       
          datosJornadas.forEach(element => { 
            if(mano){
              if(mano === parseInt(element.elementos.mano)){
                let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
                let cantidad = parseFloat(element.elementos.cantidad)
                costeTotalMano +=  coste * cantidad;     
                ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0
              } 
            }else{
              let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
              let cantidad = parseFloat(element.elementos.cantidad)
              costeTotalMano +=  coste * cantidad;     
              ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0             
            }
          });  
        }
        return (costeTotalMano+ajusteCPE).toFixed(2)
    }

    const handleRemoveElement = async  (element) =>{   

        let urlPostData = ""
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}`
        if (modo==="W") {      
        await axios
          .delete(`${SERVER_URL}/${element.id}`, {
                  headers: {        
                    "Content-Type": "application/json",
                    Authorization: AuthStr,
                  },                
          })
          .then((response) => {                  
            if(!isNaN(response.data)){
              if(parseInt(response.data) === parseInt(element.id)){   
                const elementosNoEliminar = listadoProductos.filter(el => el.id !== element.id)                      
                setOpenAlert({estado: true, mensaje: "¡Producto eliminado correctamente!", tipo:"success"})                      
                setListadoProductos(elementosNoEliminar)
              }
            }
          })
          .catch((error) => {
            console.log("error " + error);
          });
        }else{setOpenAlert({estado: true, mensaje: "Para eliminar un producto debe estar en modo edición.", tipo:"warning"})}
  
        
      }  
  
      const handleSaveData = async (producto) => {
        
        let datos = {};
        datos.productoActualizar = producto
        let urlPostData = ""
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}/${producto.id}`
        //console.log(datos)
        //console.log(SERVER_URL)
        if (modo==="W") {
          await axios
          .put(`${SERVER_URL}`, {
            headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {       
            //console.log(response.data)
            if(Array.isArray(response.data))
            {
              setListadoProductos(response.data)
              setOpenAlert({estado: true, mensaje: "¡Producto modificado correctamente!", tipo:"success"})
            }
            
            })
            .catch((error) => {
              console.log("error " + error);
        });
        }else{setOpenAlert({estado: true, mensaje: "Para añadir un producto debe estar en modo edición.", tipo:"warning"})}
  
        
      }

    const ficheroCotizacion = pdfCotizacion[0].Nombre ? true : false;

    let costesInd = parseFloat(costeDirecto) * parseFloat(ciPorcentaje) / 100;
    let costesTot = parseFloat(costeDirecto) + parseFloat(costesInd)
    let beneficioInd = parseFloat(costesTot) * parseFloat(biPorcentaje) / 100 
    const beneficioTotal = parseFloat(costesTot) + parseFloat(beneficioInd) - parseFloat(costeDirecto)
    const beneficioTotalPorcentaje = (( ( (parseFloat(costesTot) + parseFloat(beneficioInd)) / parseFloat(costeDirecto) ))*100)-100
    const propiedadesFicheroPresupuesto = {
        tabla:"", xs:6, ficheros:pdfCotizacion
    }

    //console.log(valuesRadioButton)
    
return (
<>     
    <div className="">
        <form
        onSubmit={handleSubmit((datos) => {
        handleSubmitPost(datos);
        })}
        className="row"
        >
        <div className="col-7 mt-2 mb-2  ">
        <div className="row">
            {/* Fecha de Mano */}
            <div className="col-6 mt-2 mb-2  ">
            <Controller                        
                as={TextField}
                required
                disabled={modoLectura}
                control={control}
                id="fechapresupuesto"
                name="fechapresupuesto"
                type="date"
                label="Fecha presupuesto"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue=""
                className=""
                InputLabelProps={{
                shrink: true,
                }}
            />
            </div> 

            {/* Descripción */} 
            <div className="col-12 mt-2 mb-2  ">                
                <Controller
                    as={TextField}
                    disabled={modoLectura}
                    required
                    control={control}
                    id="descripcion"
                    name="descripcion"
                    label="Texto adicional"
                    variant="outlined"
                    multiline
                    rows={3}
                    size="small"
                    fullWidth
                    defaultValue=""
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div> 

            <div className="col-12 mt-2 mb-2  ">                  
                <FormularioPresupuestoCotizacionProductos urlGetData={"cotizaciones"} urlPostData={"cotizacionesoperativaspresupuestos"} />
            </div>       

            <div className="col-9 mt-2 mb-2  ">
            <FormControl component="fieldset">
            <RadioGroup row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={valuesRadioButton.etiqueta2}
                onChange={handleRadioChange}
            >
                <FormControlLabel value="€/tonelada" control={<Radio />} label="€/tonelada" />
                <FormControlLabel value="€/mano" control={<Radio />} label="€/mano"/>
                <FormControlLabel value="€/total" control={<Radio />} label="€/total"/>
                <FormControlLabel value="Sin total" control={<Radio />} label="Sin total"/>
            </RadioGroup>
            </FormControl>

            </div>

            <div className="col-3 mt-2 mb-2  ">
            <Controller
                control={control}
                type="number"                
                id="manospresupuesto"
                name="manospresupuesto"
                defaultValue={manos}
                
                render={({ onChange, value}) => (
                    <NumberFormat
                        customInput={TextField}
                        value= {manos}
                        className="formato-numerico"
                        thousandSeparator={true}
                        suffix={''}
                        disabled={modoLectura}
                        label={valuesRadioButton.etiqueta1}                
                        fullWidth  
                        size="small"                    
                        variant="outlined" 
                        id="manospresupuesto"
                        name="manospresupuesto"
                        onChange={changeValueManos}
                    />
                /*
                <TextField
                    type="number"
                    disabled={modoLectura}
                    label={valuesRadioButton.etiqueta1}                     
                    fullWidth  
                    size="small"                    
                    variant="outlined"  
                    onChange={changeValueManos}  
                    value={manos}                    
                />
                */
                )}
            /> 
            </div>

           
        </div>
        </div>
            
        <div className="col-5 mt-2 mb-2  ">   
            <div className="row">
            {/* Costes directos operativa */}
            <div className="col-6 offset-1 mt-2 mb-2  ">
            <NumberFormat
                customInput={TextField}
                value= {costeDirecto}
                className="formato-numerico"
                thousandSeparator={true}
                suffix={'€'}
                disabled
                label="Costes directos"                   
                fullWidth  
                size="small"                    
                variant="outlined" 
                id="costesdirectos"
                name="costesdirectos"
            />
            </div>
            
            {/* Costes indirectos operativa */}
            <div className="col-6 offset-1 mt-2 mb-2  ">
            <NumberFormat
                customInput={TextField}
                value= {costesInd.toFixed(2)}
                className="formato-numerico"
                thousandSeparator={true}
                suffix={'€'}
                disabled
                label="Costes indirectos"                   
                fullWidth  
                size="small"                    
                variant="outlined" 
                id="costesindirectos"
                name="costesindirectos"
            />
            </div>

            {/* % Costes indirectos operativa */}
            <div className="col-4 mt-2 mb-2  ">  
            <Controller
                control={control}
                type="number"                
                id="costesindirectosporcentaje"
                name="costesindirectosporcentaje"
                defaultValue={ciPorcentaje}
                
                render={({ onChange, value}) => (
                <TextField
                    type="number"
                    disabled={modoLectura}
                    label="%"                   
                    fullWidth  
                    size="small"                    
                    variant="outlined"  
                    onChange={changeValueCI}  
                    value={ciPorcentaje}                    
                />
                )}
            />  
            </div>

            {/* Costes totales operativa */}
            <div className="col-6 offset-1 mt-2 mb-2  ">
            <NumberFormat
                customInput={TextField}
                value= {costesTot.toFixed(2)}
                className="formato-numerico"
                thousandSeparator={true}
                suffix={'€'}
                disabled
                label="Costes totales"                   
                fullWidth  
                size="small"                    
                variant="outlined" 
                id="costestotales"
                name="costestotales"
            />
            </div>

            {/* Beneficio industrial */}
            <div className="col-6 offset-1 mt-2 mb-2  ">
            <NumberFormat
                customInput={TextField}
                value= {beneficioInd.toFixed(2)}
                className="formato-numerico"
                thousandSeparator={true}
                suffix={'€'}
                disabled
                label="Beneficio industrial"                   
                fullWidth  
                size="small"                    
                variant="outlined" 
                id="beneficioindustrial"
                name="beneficioindustrial"
            />
            </div>

            {/* % Beneficio industrial */}
            <div className="col-4 mt-2 mb-2  ">
            <Controller
                control={control}
                type="number"                
                id="beneficioporcentaje"
                name="beneficioporcentaje"     
                defaultValue={biPorcentaje} 

                render={({ onChange, value}) => (
                <TextField
                    type="number"
                    disabled={modoLectura}
                    label="%"                   
                    fullWidth  
                    size="small" 
                    variant="outlined"  
                    onChange={changeValueBI}                   
                    value={biPorcentaje} 
                />
                )}
            />                  
            </div>

            {/* Presupuesto */}
            <div className="col-6 offset-1 mt-2 mb-2  ">
            <Controller
                as={NumberFormat}
                customInput={TextField}
                className="formato-numerico"
                thousandSeparator
                suffix={'€'}
                disabled
                control={control}
                id="presupuesto"
                name="presupuesto"
                label="Presupuesto"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue=""
            />  
            </div>

            {/* Presupuesto por mano/tonelada*/}
            <div className="col-4 mt-2 mb-2  ">
            <NumberFormat
                customInput={TextField}
                value={eurosTon.toFixed(2)}
                className="formato-numerico"
                suffix={'€'}
                disabled
                label={valuesRadioButton.etiqueta2}                  
                fullWidth  
                size="small"                    
                variant="outlined" 
                id="presupuestoxtonelada"
                name="presupuestoxtonelada"
            />
            
            </div>

            {/* Beneficio total € */}
            <div className="col-6 offset-1 mt-2 mb-2  ">
            <NumberFormat
                customInput={TextField}
                //onValueChange={(values) => setNumberFormatState(values.value)}
                value={beneficioTotal.toFixed(2)}
                // you can define additional custom props that are all forwarded to the customInput e. g.
                className="formato-numerico"
                thousandSeparator={true}
                suffix={'€'}
                disabled
                label="Beneficio total"                   
                fullWidth  
                size="small"                    
                variant="outlined" 
            />
            </div>

            {/* Porcentaje beneficio total*/}
            <div className="col-4 mt-2 mb-2  ">
            <NumberFormat
                customInput={TextField}
                value={beneficioTotalPorcentaje.toFixed(2)}
                className="formato-numerico"
                thousandSeparator={true}
                suffix={'%'}
                disabled
                label="B.º"                   
                fullWidth  
                size="small"                    
                variant="outlined" 
                id="beneficiototalporcentaje"
                name="beneficiototalporcentaje"
            />
            </div>

            <div className="col-12 pt-2"> 
            <Grid container spacing={3} className="ml-2">
                <Grid item xs={12}>
                    <Typography color="inherit">EXCLUSIONES</Typography>
                </Grid>
                {listadoExclusiones.map(remocion => (
                    <Grid key = {remocion.index} item xs={1} className={"mr-2"}>
                    <LightTooltip title={remocion.label}>
                        <FormControlLabel
                            control={
                            <Controller
                                name={'exclusion' + remocion.index}
                                control={control}
                                //defaultValue={false}
                                defaultValue={datosPresupuesto.hasOwnProperty('exclusiones') && datosPresupuesto.exclusiones !== null ? Boolean(parseInt(datosPresupuesto.exclusiones.split(',')[remocion.index -1])) : true}
                                render={(props) => (
                                <Checkbox
                                    {...props}
                                    checked={props.value}
                                    onChange={(e) => props.onChange(e.target.checked)}
                                />
                                )}
                            />
                            }
                            label={remocion.index}
                        />  
                    </LightTooltip>          
                    </Grid>
                ))}
                
            </Grid>

            </div>

            <div className="col-6 mt-2 mb-2  ">
            { ficheroCotizacion && (
                <FormFieldFiles propiedades={propiedadesFicheroPresupuesto}/>
            )}  
            </div>  

            <div className="col-6 pt-2"> 
                {modo === "W" &&            
                    <button className="float-right mr-4 MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation">
                    GUARDAR
                    </button>
                }
            </div>

            </div>  
            

            {openAlert && 
              <AlertaMensaje 
                mensaje={"¡Presupuesto guardado correctamente!"} 
                isOpen={openAlert} 
                tipoMensaje="success"
                cerrar={handleCloseAlert}
              />
              }  
        </div> 
        </form>
    </div> 
</>
)
}

export default PresupuestoCotizacion;  