import React, { useState, useRef } from "react";
import axios from "axios"; 
import "semantic-ui-css/semantic.min.css";
//import CuerpoTabla from "../../../../../components/Common/CuerpoTabla";
import SearchBar from "../../../../../components/Common/SearchBar";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import Loading from "../../../../../components/Common/Loading";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

const cloumnsPlanificaciones = [
  {
    name: "Id",
    label: "Id",
    options: {
     filter: true,
     sort: true,
     display: true,
    }
   },
   {
    name: "Cuentas",
    label: "Clientes",
    options: {
     filter: true,
     sort: true,
    }
   },
   {
    name: "Operativa",
    label: "Operativa",
    options: {
     filter: true,
     sort: true,
    }
   },  
   {
    name: "buque",
    label: "Buque",
    options: {
     filter: true,
     sort: true,
    }
   },  
   {
    name: "Localizacion",
    label: "Localizacion",
    options: {
     filter: true,
     sort: true,
    }
   },
   {
    name: "Fecha_Operativa",
    label: "Fecha Operativa",
    options: {
     filter: false,
     sort: true,
     
    }   
   },  
   {
    name: "Origen",
    label: "Origen",
    options: {
     filter: false,
     sort: true,
     
    }   
   },  
   {
    name: "Estado",
    label: "Estado",
    options: {
     filter: true,
     sort: true,
     
    }   
   }, 
   {
    name: "Codigo",
    label: "Código",
    options: {
     filter: true,
     sort: true,
    }
   },  
];

const Planificaciones = () => {
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [tipoPlanificacion, setTipoPlanificacion]=useState(localStorage.getItem('idTipoPlanificacion') || "Abiertas");    
  const planificacionEliminar = useRef(-1);
  const planificacionClonar = useRef(-1);
  const [abrirDialogo, setAbrirDialogo] = useState(false)
  const [abrirDialogoPlanificacion, setAbrirDialogoPlanificacion] = useState(false)
  const [isLoading, setIsLoading] = useState(false); 

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const clonarPlanificacion = (id) => {
    planificacionClonar.current = id;
    setAbrirDialogoPlanificacion(true)
  }

  const eliminarPlanificacion = (id) => {
    planificacionEliminar.current = id       
    setAbrirDialogo(true)
  }

  const handleCerrarDialogoPlanificacion = (valor) => {
    setAbrirDialogoPlanificacion(false)
    if(valor){
      const fetchData = async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const urlDatos = `planificaciones/clonaplanificacion/${planificacionClonar.current}`

        const config = {
          headers: { Authorization: `Bearer ${user.access_token}` }
        };
      
        const bodyParameters = {
          key: null
        }; 

        await axios(process.env.REACT_APP_API_URL + urlDatos, 
          bodyParameters,
          config                                 
        )
        .then(response => {     
          if(!isNaN(response.data)){          
            if(parseInt(response.data)){  
              window.location.reload();            
            }
          }
        })
        .catch(function (error) {
          if (error.response) {                   
            console.log(error.response)
          }
        });        
      };
      fetchData();
    }
  }

  const handleCerrarDialogo = (valor) => {       
    setAbrirDialogo(false)
    if(valor){    
      const fetchData = async () => {  
        setIsLoading(true)
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'planificaciones';
        await axios
        .delete(SERVER_URL + "/" + planificacionEliminar.current, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },        
        })
        .then((response) => {         
          if(!isNaN(response.data)){ 
            if(response.data === planificacionEliminar.current){
              planificacionEliminar.current = -1 
              setIsLoading(false)
              //window.location.reload();
            }          
          } else{
            console.log(response.data) 
            alert(response.data);  
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.log("error " + error);
          setIsLoading(false)
        });  
      }
      fetchData();
    }      
  }


  const handleOperacion = (idO) =>{ 
    localStorage.setItem('idTipoPlanificacion', idO);     
    setTipoPlanificacion(idO)    
  }  
  
  let urlDoubleClick = "/operativas/planificaciones/planificacion/"
  let botoneraAcciones = [
    { etiqueta: "Clonar Planificación", function: (e) => clonarPlanificacion(e), activo: false },
    { etiqueta: "Crear Planificación", url: "/operativas/planificaciones/planificacion", activo: true },
   {
      etiqueta: "Editar Planificación",
      url: '/operativas/planificaciones/planificacion/filaSeleccionada/W',
      activo: false,
    }, 
    { etiqueta: "Eliminar Planificación", function: (e) => eliminarPlanificacion(e), activo: false },
  ];
  
  if(tipoPlanificacion === "Validadas" || tipoPlanificacion === "Todas"){
    botoneraAcciones = [
      { etiqueta: "Clonar Planificación", function: (e) => clonarPlanificacion(e), activo: false },
      { etiqueta: "Crear Planificación", url: "/operativas/planificaciones/planificacion", activo: true },
      {
        etiqueta: "Editar Planificación",
        url: '/operativas/planificaciones/planificacion/filaSeleccionada/W',
        activo: false,
      }, 
      { etiqueta: "Eliminar Planificación", function: (e) => eliminarPlanificacion(e), activo: false },
    ];
    urlDoubleClick=""
  }

  const filtroToolbar = true
  const filtroToolbarOpciones = ['Pdtes. Validar', 'Validadas', 'Todas']
  const urlDatos = tipoPlanificacion === "Pdtes. Validar" ? "planificaciones?estado=1" : ( tipoPlanificacion === "Validadas" ? "planificaciones?estado=2" : "planificaciones" );

  //console.log(urlDatos)
  return (           
    <div className="col-8 col-main-home ">            
      <SearchBar
        titulo="Operativas"
        claseCabecera = "bg-produccion"
        buscar={false}
        imagenCabecera={"5-2-1-planificacion.png"}
      />   
      
      <div className="bg-cuerpo p-10px">
        <div className="bg-white p-10px">
          <div className="p-1 bg-white mb-3">
            <h6 className="texto-gestion float-left" id="title">
              Planificaciones
            </h6>
          </div>
          <hr className="m-0 bg-gestion mb-1" />
          <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
          { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la planificación?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
          { abrirDialogoPlanificacion && (<AlertaDialogo titulo={"¿Desea clonar la planificación?"} abrir={abrirDialogoPlanificacion} cerrar={handleCerrarDialogoPlanificacion} textoBoton2={"Clonar"}/>)}
          {!isLoading && ( 
           <CuerpoTablaPiezas
              columnas = {cloumnsPlanificaciones}
              urlDatos = {urlDatos}
              urlDoubleClick = {urlDoubleClick}
              columnId = "Id"
              getFilaSeleccionada={marcarFila}
              filtroToolbar={filtroToolbar}
              filtroToolbarOpciones = {filtroToolbarOpciones}
              filtroToolbarValor = {tipoPlanificacion}
              filtroToolbarChangeValor={handleOperacion}
              filtroEjercicio = {true}
            /> 
            )}
          <div>{isLoading && <Loading isLoading={isLoading} />}</div>
          </div>
        </div>

        <div className="bg-cuerpo">
          <div className="bg-white">
            <TableAcciones                
              filaSeleccionada={filaSeleccionada}                
              botonaccion={botoneraAcciones}
            />            
          </div>
        </div>

      </div>
        
    </div>   
  );
};

export default Planificaciones;