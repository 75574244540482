import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router";

import { useAlmacenamiento } from "./almacenamiento-context";

import FormularioAlmacenamientoDatos from "./FormularioAlmacenamientoDatos";
import FormularioAlmacenamientoMateriales from "./FormularioAlmacenamientoMateriales";
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import TablaAlmacenamientoMateriales from "./TablaAlmacenamientoMateriales";

const useStyles = makeStyles((theme) => ({ 
    root: {
        flexGrow: 1,
    },
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioAlmacenamiento = ({datos}) => {    
    const methods = useForm(); 
    const classes = useStyles(); 
    const { control, handleSubmit} = useForm(); 
    const { id, modo } = useParams();
    const history = useHistory(); 
    const { listadoMaterialesAlmacenamiento } = useAlmacenamiento();

    const [datoscargados, setDatosCargados] = useState(true);     
    const [openAlert, setOpenAlert] = useState(false);
    const [ficheros, setFicheros] = useState([])
    const [nombresFicheros, setNombresFicheros] = useState([])        

    const handleChangeFicheros = (fich, nobresFich) => {
        setFicheros(fich)
        setNombresFicheros(nobresFich)
    }

    const handleSubmitPost = async (datos) => {
        datos.ficheros = ficheros
        datos.nombresFicheros = nombresFicheros
        datos.listadomateriales = listadoMaterialesAlmacenamiento
        

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}almacenamientos`

        datos.userid = user.id
        console.log(datos)
        console.log(SERVER_URL)

        if (modo==="W") {
            await axios
              .put(`${SERVER_URL}/${id}`, {
                headers: {        
                  "Content-Type": "application/json",
                  Authorization: AuthStr,
                },
                datos,
              })
              .then((response) => {       
                console.log(response.data) 
                if(!isNaN(response.data)){
                  if(parseInt(response.data) === parseInt(id)){                       
                      setOpenAlert(true)
                      setTimeout(function () {
                        window.location.reload();   
                      }, 1500);      
                  }
                }
                })
                .catch((error) => {
                  console.log("error " + error);
            });
          }
          else{
            await axios
              .post(SERVER_URL, {
                headers: {        
                  "Content-Type": "application/json",
                  Authorization: AuthStr,
                },
                datos,
              })
              .then((response) => {       
                console.log(response.data) 
                if(!isNaN(response.data)){
                  if(parseInt(response.data)){                       
                      setOpenAlert(true)
                      history.push({
                        pathname: `/operativas/almacenamientos/${response.data}/W`,
                        //action: action + Math.floor(Math.random() * (9999))
                      });
                  }
                }
                })
                .catch((error) => {
                  console.log("error " + error);
            });
          }

    }

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }  
        setOpenAlert(false);
    }; 


    console.log(datos)
    if (datoscargados){
    return (        
        <div className={classes.root}>
            <FormProvider {...methods}>
            <form
                id='formulario-almacenamiento'
                onSubmit={handleSubmit((data) => {
                handleSubmitPost(data);
                })}
                className=""
            >   
            <FormularioAlmacenamientoDatos datos={datos} changeFicheros={handleChangeFicheros} control={control}/>
            <FormularioAlmacenamientoMateriales />
            <TablaAlmacenamientoMateriales />      
            </form>
            </FormProvider>
            {openAlert && 
                <AlertaMensaje 
                    mensaje={"Recepción guardada correctamente!"} 
                    isOpen={openAlert} 
                    tipoMensaje="success"
                    cerrar={handleCloseAlert}
                />
            } 
        </div>                      
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }
}


export default FormularioAlmacenamiento;

/*
//const { id, modo } = useParams();
    //const [isLoading, setIsLoading] = useState(true);  
    //const [campos, setCampos] = useState([])  
    //const [almacenamiento, setAlmacenamiento] = useState([]) 

if (datoscargados){
    return (        
        <FormularioEntidad
            urlForm = "almacenamientos"
            urlGoto = "/operativas/almacenamientos/"
            campos={campos}
        />                       
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }
    */
    /*
    //const [secciones, setSecciones] = useState([])
    const Lista = (areasHorizontales, areasVerticales) => {
        let arrayLetra = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','Ñ','O','P','Q','R','S','T','U','V','W','X','Y','Z']
        let arrayAlmacen = []
        let contador= 0
    
        for(let i=0; i< areasHorizontales; i++){
            for(let j=1; j <= areasVerticales; j++){
                arrayAlmacen[contador] = { Id: i + '-' + j, Etiqueta: arrayLetra[i] + '-' + j }
                contador++
            }
        }
    
        return arrayAlmacen.sort()
    }
    */

    /*
    const handleChange = (valor) => {
        
        if (valor) {
            setSecciones(Lista(valor.areasHorizontal, valor.areasVertical))
        }
        else{
            setSecciones([])
        }
    }
    */