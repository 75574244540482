import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import { CircularProgress } from '@material-ui/core';
import FormularioMaterialEntradas from "./FormularioMaterialEntradas";
import MigasDePan  from "../../../../../components/Common/MigasDePan";

const Entradas = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);    
    const [datos, setDatos] = useState();

    const urlsMigasdepan = [
      {titulo: "Entradas", colorTexto: "texto-comercial", urlTo: "/logistica/materiales"},
      {titulo: "Entrada", colorTexto: "texto-comercial", urlTo: `/logistica/materiales/entradas/${id}/${modo}`},
    ]

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respMaterial = await axios( process.env.REACT_APP_API_URL + "materials/" + id);
            if (respMaterial.data) {              
              setDatos(respMaterial.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar Entrada" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/logistica/materiales/` :
          modo === "R" ? 
            `/logistica/materials/entradas/${id}/W` : 
            `/logistica/materials/entradas/${id}/R`,
          activo: true,
        },
        {
          etiqueta: "Guardar",
          form:'formulario-entrada',
          activo: modo === "R" ? false : true,
        } 

    ];

    const codigo = typeof datos !== "undefined" ? datos.codigo : "";

    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Logística interna"
              claseCabecera = "bg-produccion"
              buscar={false}
            />
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                             
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">                                         
                       <FormularioMaterialEntradas />                      
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default Entradas;