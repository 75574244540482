import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import BotonesMantenimiento from "./BotonesMantenimiento"
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from '@material-ui/core/styles';
import FormularioManteniminetoMateriales from "./FormularioMantenimientoMateriales";
import FormularioMantenimientoEmpleados from "./FormularioMantenimientoEmpleados";
import FormularioMantenimientoMaquinaria from "./FormularioMantenimientoMaquinaria";
import FormularioManteniminetoInicial from "./FormularioMantenimientoInicial";
import FormFieldComboArray from "../../../../../components/Common/FormFieldComboArray";
import { getDateToday } from "../../../../../tools/metodos";
import { useHistory } from "react-router";
import FormularioMantenimientoProveedor from "./FormularioMantenimientoProveedor";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
}));

const FormularioReparacion = ({index, tipoForm}) => { 
 
  const [reparacion, setReparacion] = useState([]) 
  const [campos, setCampos] = useState([]) 
  const { id, modo } = useParams();
  const [listadoMateriales, setListadoMateriales] = useState([]) 
  const [tipoFormulario, setTipoFormulario]=useState('empleado')
  const history = useHistory(); 

  const methods = useForm();
  
  const classes = useStyles();;
  const { control, handleSubmit} = useForm(); 
  
  const [ficheros, setFicheros] = useState([])
  const [nombresFicheros, setNombresFicheros] = useState([])

  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fecha, setFecha] = useState(getDateToday())

    const handleListadoTabla=(valor) => {      
     setListadoMateriales([...listadoMateriales,valor])
    }

    const handleRemoveElement = (element) =>{
      setListadoMateriales(element) 
  }

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };  
    
 
    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }

    useEffect(() => { 
      
      setCampos([    
        //0    
        {
          urlData: "dic/diccionarios/listadoresponsablesservicios",
          campoEtiqueta: "nombre",
          required: true,
          name: "encargado",
          disabled: modo === "R" ? true : false,
          type:'combo',
          label: "Responsable",
          value: '',
          multiple: false,
          xs: 4
        },  
        //1      
        {
          urlData: "",
          required: true,
          name: "elemento",
          disabled: true,
          label: "Área trabajo",
          type: 'text',
          value: 'Instalaciones',
          multiple: false,
          xs: 4
        },
        //2
        {
          required: true,
          label: "Fecha",
          name: "fecha",
          disabled: modo === "R" ? true : false,
          type: "date",    
          value: fecha,
          xs: 4
        },
        //3
        {
          urlData: "dic/diccionarios/getareastrabajoservicios",
          campoEtiqueta: "Etiqueta",
          required: true,
          name: "areatrabajo",          
          disabled: modo === "R" ? true : false,
          label: "Elemento",
          type:'combo',
          value: '',
          multiple: false,
          xs: 4
        },
        //4
        {
          campoEtiqueta: "Etiqueta",
          required: true,
          label: "Tipología",
          name: "tipologia",
          disabled: modo === "R" ? true : false,
          type: "comboArray", 
          options:[{Id: 'Mantenimiento', Etiqueta: 'Mantenimiento'}, {Id: 'Reparación', Etiqueta: 'Reparación'}], 
          multiple:false,   
          value: '',
          xs:4
        },   
        //5
          {
              urlData: "dic/diccionarios/serviciosestados",
              campoEtiqueta: "Etiqueta",
              required: true,
              name: "estado",
              disabled: modo === "R" ? true : false,
              type:'combo',
              label: "Estado",
              value: '',
              multiple: false,
              xs: 4
          },
          //6
          {
            urlData: "",
            required: true,
            name: "descripcion",
            disabled: modo === "R" ? true : false,
            label: "Descripción",
            type: 'text',
            value: '',
            multiple: false,
            xs: 12
          },
          //7
          {
            required: true,
            name: "observaciones",
            disabled: modo === "R" ? true : false,
            label: "Observaciones",
            value: '',
            type: 'textarea',
            lineas:3,
            xs: 12
          },
          //8
          {
            required: false,
            label: "Documentacion",
            name: "ficherosadjuntos",
            type: "filesupload",   
            tabla: "mtms_mantenimientos_adjuntos",
            //xs:6
          },
          //9
          {
            required: false,
            label: "Documentacion",
            name: "ficherosremotos",
            type: "files", 
            ficheros: reparacion.ficheros,    
            tabla: "mtms_mantenimientos_adjuntos",
            modelo: "api\\modules\\v1\\models\\reparaciones\\ReparacionAdjuntos",
            carpeta: "reparacion",
            //xs:4
          }  
      ])

      const fetchData = async () => {
        setIsLoading(true) 
        if (modo === "W" || modo === "R" ) {
            const respReparacion = await axios(
                `${process.env.REACT_APP_API_URL}reparacions/${id}`
            );      
            setReparacion(respReparacion.data)  
        }
        setIsLoading(false);
      }; 
      fetchData();     
    },[]);
    
  useEffect(() => {
    if (typeof reparacion !== "undefined") { 
      if(Object.keys(reparacion).length > 0){
        setIsLoading(true)  
        campos[0].value = reparacion.encargado;
        campos[1].value = reparacion.elemento;
        campos[2].value = reparacion.fecha;
        campos[3].value = reparacion.areatrabajo;
        campos[4].value = {Id: reparacion.tipologia, Etiqueta: reparacion.tipologia};
        campos[5].value = reparacion.estado;  
        campos[6].value = reparacion.descripcion;  
        campos[7].value = reparacion.observaciones;
        //campos[7].ficheros = reparacion.ficheros;
        campos[9].ficheros = reparacion.ficheros;      
        setListadoMateriales(reparacion.listadotabla)
        setIsLoading(false)        
      }
    }
  }, [reparacion])
  
const handleSubmitPost = async (datos) => {
  
  let datosFormulario = {};
  datosFormulario.elemento = datos.elemento
  datosFormulario.fecha = datos.fecha
  datosFormulario.areasTrabajo = datos.areatrabajo.Id
  //datosFormulario.departamentos = datos.departamentos.Id
  datosFormulario.responsable = datos.encargado.id
  datosFormulario.estado = datos.estado.Id
  datosFormulario.descripcion = datos.descripcion
  datosFormulario.observaciones = datos.observaciones
  datosFormulario.ficheros=ficheros
  datosFormulario.tipologia = datos.tipologia.Id
  datosFormulario.nombresFicheros=nombresFicheros
  datosFormulario.listadotabla=listadoMateriales

  let user = JSON.parse(localStorage.getItem("user"));
  const AuthStr = "Bearer ".concat(user.access_token);
  const SERVER_URL = `${process.env.REACT_APP_API_URL}reparacions`
  if (modo==="W") {
    await axios
      .put(`${SERVER_URL}/${id}`, {
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
        datosFormulario,
      })
      .then((response) => {    
        if(!isNaN(response.data)){
          if(parseInt(response.data) === parseInt(id)){                       
              setOpenAlert(true)   
              setTimeout(function () {
                window.location.reload();   
            }, 1000);
                         
          }
        }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
    });
  }
  else{
    await axios
      .post(SERVER_URL, {
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
        datosFormulario,
      })
      .then((response) => {  
        if(!isNaN(response.data)){
          if(parseInt(response.data)){                       
              setOpenAlert(true)
              setTimeout(function () {
                history.push({
                pathname: `/servicio/instalaciones/instalacion/${response.data}/W`,
                //action: action + Math.floor(Math.random() * (9999))
              });  
            }, 1000);
          }
        }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
    });
  }
}

const handleTipoFormulario = (valor) => {
  setTipoFormulario(valor)
}

return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-instalacion'
            onSubmit={handleSubmit((data) => {
             
            handleSubmitPost(data);
            })}
            className=""
          >

          <Grid className="borderFormulario" container spacing={1}> 
            <FormFieldCombo propiedades={campos[0]} control={control}/>
            <FormFieldText propiedades={campos[1]} control={control}/>
            <FormFieldDate propiedades={campos[2]} control={control} />
            <FormFieldCombo propiedades={campos[3]} control={control}/>
            <FormFieldComboArray propiedades={campos[4]} control={control}/>
            <FormFieldCombo propiedades={campos[5]} control={control}/>               
            <FormFieldText propiedades={campos[6]} control={control}/>
             <FormFieldTextArea propiedades={campos[7]} control={control}/>
            <FormFieldFiles propiedades={campos[9]} control={control} ficheros={handleFicheros}/>
            <FormFieldFileUpload propiedades={campos[8]} control={control} handleFicheros={handleFicheros}/>
          </Grid>

          <Grid className="borderFormulario" container spacing={1}>
              <BotonesMantenimiento tipoFormulario={tipoFormulario} changeTipoFormulario={handleTipoFormulario} maquinaria={true} />
          </Grid>

          {tipoFormulario === "empleado" && (            
            <FormularioMantenimientoEmpleados listadotabla= {handleListadoTabla}/>            
          )}

          {tipoFormulario === "maquinaria" && (
            <FormularioMantenimientoMaquinaria listadotabla= {handleListadoTabla}/>            
          )}

          {tipoFormulario === "materiales" && (
            <FormularioManteniminetoMateriales listadotabla= {handleListadoTabla}/>            
          )}

          {tipoFormulario === "proveedores" && (
            <FormularioMantenimientoProveedor listadotabla= {handleListadoTabla}/>            
          )}
          </form>
          </FormProvider>
          <FormularioManteniminetoInicial listadoMateriales={listadoMateriales} ListadoMaterialesFinal={handleRemoveElement}/>
          
          {openAlert && 
          <AlertaMensaje 
            mensaje={"¡Parte guardado correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }          
          
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
        </>
    )
};

export default FormularioReparacion;