import React, { useState } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function TipoDocumentacion({opera}) {  
 const [tipoFormulario, setTipoFormulario] = useState('Documentacion')
   
 const handleRadioChange = (e) => {  
  setTipoFormulario(e.target.value)
  opera(e.target.value)
 } 

  return (        
    <div className="d-flex flex-column align-items-end">
        <FormControl component="fieldset" className="">
            <RadioGroup row aria-label="position" name="position" value={tipoFormulario} onChange={(e) => handleRadioChange(e)}>
            <FormControlLabel
                value="Documentacion"
                control={<Radio color="primary" />}
                label="Documentación Personal"
              />
              <FormControlLabel
                value="Formacion"
                control={<Radio color="primary" />}
                label="Formación"
              />
              <FormControlLabel
                value="Contrato"
                control={<Radio color="primary" />}
                label="Contrato y Nóminas"
              />
              <FormControlLabel
                value="Seguridad"
                control={<Radio color="primary" />}
                label="Seguridad y Salud"
              />
              <FormControlLabel
                value="Tiempo"
                control={<Radio color="primary" />}
                label="Tiempo y Gastos"
              />
            </RadioGroup>
        </FormControl>
    </div>    
  );
}