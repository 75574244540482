import React, { useState, useEffect, useContext  } from 'react';
import { StateContext } from '../../../../../context'
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PdfIcon from '@material-ui/icons/PictureAsPdf';


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const DetalleOperacionCPE = ({operacion}) => {
    const { state, dispatch } = useContext(StateContext)   
    const [actualizado, setActualizado] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [pedidoEliminar, setPedidoEliminar] = useState({Id:'', NPedido:''})
  
    const handleClose = () => {
      setOpen(false);
    };
    
    useEffect(() => {          
        if(Object.keys(operacion).length > 0)
        {setActualizado(true)}       
    }, [operacion]) 
    
    const Editar = (idElemento) => {
        //console.log(idElemento)
        dispatch({type: "UPDATE_CPE_ELEMENT", payload: {tipo: "cpe", elemento: idElemento}})
    }

    const Eliminar = (idElemento) => {        
        const pedidoAEliminar = operacion.CPE.filter(pedido => pedido.Id === idElemento)
        if(pedidoAEliminar.length > 0){
            setPedidoEliminar(pedidoAEliminar[0])
            setOpen(true);
        }
    }

    const Imprimir = async (idElemento) => {
        console.log(idElemento)

        const url = 'operaciones/imprimepedidocpe/'
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${url}${idElemento}`; 
        await axios(SERVER_URL)
        .then((response) => {
            console.log(response.data)
            window.open(response.data, "_blank")
        }).
        catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
        });
    }

    const handleCloseEliminar = async (nPedido) => {    
       
        const id = nPedido;
        const url = 'operaciones/eliminapedidocpe'
        const user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}${url}`;   
        
        await axios.post(SERVER_URL, {
            headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
            },
            id
        })
        .then((response) => {
            
            if (response.data === id ){ 
                dispatch({type: "RELOAD_DETALLE", payload: true}) 
            }
          })
          .catch((error) => {
            console.log("error " + error);
          });
        setOpen(false);
    }

    const calculaCosteMano = (mano) =>{        
        let costeTotal = 0
        //console.log(operacion.CPEEspecialidades)
        operacion.CPEEspecialidades.map((especialidades) => (
            especialidades.map((especialidad) => (  
                especialidad.Fk_Operacion_Cpe === mano ?
                    costeTotal += 
                    parseFloat(especialidad.AntiguedadDirecta) + 
                    parseFloat(especialidad.Coste) + 
                    parseFloat(especialidad.Desplazamiento) + 
                    parseFloat(especialidad.Dieta) + 
                    parseFloat(especialidad.Diferido) + 
                    parseFloat(especialidad.Fasv) + 
                    //parseFloat(especialidad.IncrementoApoyoBuque) + 
                    parseFloat(especialidad.OtrosGastos) + 
                    parseFloat(especialidad.SeguridadSocial) + 
                    parseFloat(especialidad.TotalHorasExtra) + 
                    parseFloat(especialidad.TotalIncrementoPrima) + 
                    parseFloat(especialidad.TotalPrimas) + 
                    parseFloat(especialidad.TotalPuses) + 
                    parseFloat(especialidad.TotalSalario)
                : 0                                
            )) 
        ))
        costeTotal += costeTotal*4/100;
        return costeTotal.toFixed(2)
    }

    const calculaCoste = (operacion) => {   
        let costeTotal = parseFloat(operacion.AntiguedadDirecta) + 
        parseFloat(operacion.Coste) + 
        parseFloat(operacion.Desplazamiento) + 
        parseFloat(operacion.Dieta) + 
        parseFloat(operacion.Diferido) + 
        parseFloat(operacion.Fasv) + 
        //parseFloat(operacion.IncrementoApoyoBuque) + 
        parseFloat(operacion.OtrosGastos) + 
        parseFloat(operacion.SeguridadSocial) + 
        parseFloat(operacion.TotalHorasExtra) + 
        parseFloat(operacion.TotalIncrementoPrima) + 
        parseFloat(operacion.TotalPrimas) + 
        parseFloat(operacion.TotalPuses) + 
        parseFloat(operacion.TotalSalario)
        costeTotal += costeTotal*4/100;
        return costeTotal.toFixed(2)
        /*
        return (
            parseFloat(operacion.AntiguedadDirecta) + 
            parseFloat(operacion.Coste) + 
            parseFloat(operacion.Desplazamiento) + 
            parseFloat(operacion.Dieta) + 
            parseFloat(operacion.Diferido) + 
            parseFloat(operacion.Fasv) + 
            parseFloat(operacion.IncrementoApoyoBuque) + 
            parseFloat(operacion.OtrosGastos) + 
            parseFloat(operacion.SeguridadSocial) + 
            parseFloat(operacion.TotalHorasExtra) + 
            parseFloat(operacion.TotalIncrementoPrima) + 
            parseFloat(operacion.TotalPrimas) + 
            parseFloat(operacion.TotalPuses) + 
            parseFloat(operacion.TotalSalario)).toFixed(2)
            */
    }
    //console.log(operacion.CPE)
    //console.log(operacion.CPEEspecialidades)
    return(
        <React.Fragment>
            <h5>CPE</h5> 
            <Grid container spacing={1} className="mt-2 borderFormulario">
            {actualizado && (
            <React.Fragment>
            
            {operacion.CPE.map((option) => (
            <React.Fragment key={option.Id}>
            <Grid container spacing={1} className="mt-2 mb-2">                
                    <Grid item xs={2} className="mt-2" >
                        <h6>Nº Pedido:<br/>{option['NPedido']}</h6>                                    
                    </Grid>
                    <Grid item xs={2} className="mt-2" >
                        <h6>Fecha pedido:<br/>{option['Fecha_Pedido']}</h6>                      
                    </Grid>
                    <Grid item xs={2} className="mt-2" >
                        <h6>Jornada:<br/>{option['Jornada']['Codigo']}-{option['Jornada']['Dias']}</h6>                                        
                    </Grid>
                    <Grid item xs={4} className="mt-2" >
                        <h6>Mercancía:<br/>{option['FTipoMercancia']['Etiqueta']}</h6>                    
                    </Grid>  
                    
                    <Grid item xs={2} className="mt-2 text-right" >
                        {
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {event.stopPropagation(); Editar(option['Id']);}}
                            onFocus={(event) => event.stopPropagation()}
                            control={<EditIcon />}                            
                        />    
                        }
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {event.stopPropagation(); Eliminar(option['Id'])}}
                            onFocus={(event) => event.stopPropagation()}
                            control={<DeleteIcon />}                            
                        />              
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {event.stopPropagation(); Imprimir(option['Id'])}}
                            onFocus={(event) => event.stopPropagation()}
                            control={<PdfIcon />}                            
                        />                        
                    </Grid>  
                    
            {/*
            <Grid container spacing={1} className="mt-2">
            <Accordion className="mb-2" style={{width:'100%'}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
                >
                <Grid container spacing={1} className="mt-2 mb-2">                
                    <Grid item xs={2} className="mt-2" >
                        <h6>Nº Pedido:<br/>{option['NPedido']}</h6>                                    
                    </Grid>
                    <Grid item xs={2} className="mt-2" >
                        <h6>Fecha pedido:<br/>{option['Fecha_Pedido']}</h6>                      
                    </Grid>
                    <Grid item xs={2} className="mt-2" >
                        <h6>Jornada:<br/>{option['Jornada']['Codigo']}-{option['Jornada']['Dias']}</h6>                                        
                    </Grid>
                    <Grid item xs={4} className="mt-2" >
                        <h6>Mercancía:<br/>{option['FTipoMercancia']['Etiqueta']}</h6>                    
                    </Grid>  
                    
                    <Grid item xs={2} className="mt-2 " >
                        {
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {event.stopPropagation(); Editar(option['Id']);}}
                            onFocus={(event) => event.stopPropagation()}
                            control={<EditIcon />}                            
                        />    
                        }
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {event.stopPropagation(); Eliminar(option['Id'])}}
                            onFocus={(event) => event.stopPropagation()}
                            control={<DeleteIcon />}                            
                        />              
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => {event.stopPropagation(); Imprimir(option['Id'])}}
                            onFocus={(event) => event.stopPropagation()}
                            control={<PdfIcon />}                            
                        />                        
                    </Grid>  
                    
                      
                </Grid>                      
                </AccordionSummary>
                
            </Accordion>    
            
            */}
            </Grid>
            <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Desea eliminar el Pedido "} {pedidoEliminar.NPedido} {" ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Si elimina el pedido no se podrá recuperar.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleCloseEliminar(pedidoEliminar.Id)} color="primary" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            </React.Fragment>
            </React.Fragment>
            ))}
            
            </React.Fragment>
            
            )
            }
            </Grid> 
        </React.Fragment> 
    )
}

export default DetalleOperacionCPE;

/*
                <AccordionDetails>
                <Grid container spacing={1} className="mt-2 ">
                    <Grid item xs={3} className="mt-2 " >
                        <h6>Hora Inicio:<br/> {option['Hora_Inicio']}</h6>
                    </Grid>
                    <Grid item xs={3} className="mt-2 " >
                        <h6>Hora Fin:<br/> {option['Hora_Fin']}</h6>
                    </Grid>
                    <Grid item xs={3} className="mt-2 " >
                        <h6>Horas Extra:<br/> {option['Horas_Extra']}</h6>
                    </Grid>    
                    <Grid item xs={3} className="mt-2 " >
                        <h6>Coste:<br/> {calculaCosteMano(option["Id"])} {" €"}</h6>
                    </Grid>                
                    <Grid item xs={12} className="mt-2 " >
                        <h6>Observaciones: {option['Observaciones']}</h6>
                    </Grid> 

                    <h6>MERCANCÍAS</h6>  
                    <Grid item xs={12} className="mt-2" >              
                    {operacion.CPEMercancias.map((mercancias) => (
                        mercancias.map((mercancia) => (
                        mercancia['Fk_Operacion_Cpe'] === option.Id ?
                        (
                            <React.Fragment key={mercancia.Id}>
                            <h6>
                                <strong>Mercancia:</strong> {mercancia['tipoMercancia']['Etiqueta']} <br />
                                <strong>Tipo Movimiento:</strong> {mercancia['tipoMovimiento']['Etiqueta']} <br />
                                {mercancia['NumBultos'] > 0 && (<><strong>Nº Bultos:</strong> {mercancia['NumBultos']} <br /></>)}
                                {mercancia['NumKilos'] > 0 && (<><strong>Toneladas:</strong> {mercancia['NumKilos']} <br /></>)}
                            </h6>
                            </React.Fragment>
                        ) : ""   
                        ))          
                    ))
                    }</Grid> 
                
                    <h6>ESPECIALIDADES</h6>
                    <Grid item xs={12} className="mt-2" >
                    {operacion.CPEEspecialidades.map((especialidades) => (
                        especialidades.map((especialidad) => (                             
                        especialidad['Fk_Operacion_Cpe'] === option.Id ?
                        (
                            <React.Fragment key={especialidad.Id}>
                            <h6>
                                Censo: {especialidad['Censo']['Censo']} {especialidad['Censo']['Nombre']} {"  "}
                                Coste: {calculaCoste(especialidad)} {" €"}
                            </h6>
                            </React.Fragment>
                        ) : ""  
                        ))
                    ))}
                    </Grid>                    
                </Grid> 
                </AccordionDetails>
                */