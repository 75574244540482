  const CAMPOS_EVENTOS = [  
    //0
    {
      required: true,    
      label: "Título:",
      name: "titulo",
      type: "text",    
      value: "",    
      xs:6
    }, 
    //1
    {
        required: false,
        label: "Localización:",
        name: "localizacion",
        type: "text",    
        value: "",
        xs:6
      },
      //2
    {
        required: true,
        label: "Fecha de Inicio:",
        name: "fechaInicio",
        type: "date",    
        value: "",
        xs:3
    },
    //3
    {
        required: true,
        label: "Hora de Inicio:",
        name: "horaInicio",
        type: "time",    
        value: "",
        xs:3
    },
    //4
    {
        required: false,
        label: "Fecha de Fin:",
        name: "fechaFin",
        type: "date",    
        value: "",
        xs:3
    },
    //5
    {
        required: false,
        label: "Hora de Fin:",
        name: "horaFin",
        type: "time",    
        value: "",
        xs:3
    },
    //6
    {
      urlData: "dic/diccionarios/listadoempleadosactivos",
      campoEtiqueta: "nombre",
      required: true,
      label: "Destinatario 1",
      name: "destinatario1",
      disabled: false,
      type: "combo",           
      multiple:false,   
      value: '',
      xs:3,
    },
    //7
    {
      urlData: "dic/diccionarios/listadoempleadosactivos",
      campoEtiqueta: "nombre",
      required: false,
      label: "Destinatario 2",
      name: "destinatario2",
      disabled: false,
      type: "combo",           
      multiple:false,   
      value: '',
      xs:3,
    },
    //8
    {
      urlData: "dic/diccionarios/listadoempleadosactivos",
      campoEtiqueta: "nombre",
      required: false,
      label: "Destinatario 3",
      name: "destinatario3",
      disabled: false,
      type: "combo",           
      multiple:false,   
      value: '',
      xs:3,
    },
    //9
    {
      urlData: "dic/diccionarios/listadoempleadosactivos",
      campoEtiqueta: "nombre",
      required: false,
      label: "Destinatario 4",
      name: "destinatario4",
      disabled: false,
      type: "combo",           
      multiple:false,   
      value: '',
      xs:3,
    },
    //10
    {
        required: false,
        label: "Descripción",
        name: "descripcion",
        type: "textArea",
        lineas: 4,                
        value: '',
        xs:12
      },
      //11
      {
        classButton: "",
        required: false,
        label: "",
        name: "filesupload",
        type: "filesupload",   
        tabla: "mtms_agendaeventos_adjuntos",
        xs:5
      },  
      //12
      {
        xs:12,
        required: false,
        label: "",
        name: "files",
        type: "files", 
        ficheros: "",
        tabla: "mtms_agendaeventos_adjuntos",
        modelo: "api\\modules\\v1\\models\\agendaeventos\\AgendaEventosAdjuntos",
        carpeta: "agendaeventos",
      },
  ]
  export {CAMPOS_EVENTOS}