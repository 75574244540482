import React, { useState,useEffect,createRef } from "react";
import { Controller } from "react-hook-form";
import { Avatar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme)=>({
    input:{
        display: "none"
    },
}));

const FormFieldAvatar = ({propiedades, control, handleFicheros = null,}) =>{
    const [imagen,setImagen] = useState([]);
    const classes = useStyles();
    const modo = useParams();
    const [nombresImagen, setNombresImagen] = React.useState([]);
    const [image, _setImage] = useState(null);
    const inputFileRef = createRef(null);

    useEffect(() => {
        
        if(handleFicheros){
            handleFicheros( imagen, nombresImagen )
        }
        return () => {
            
        }
    }, [nombresImagen])

    const handleCapture = ({ target }) => {  

        const newImage = target?.files?.[0];

        if (newImage) {
            setImage(URL.createObjectURL(newImage));
        }
        setImagen() 
        
        for (let i=0; i<target.files.length; i++){  
          const fileReader = new FileReader();          
          fileReader.readAsDataURL(target.files[i]);
          fileReader.onload = (e) => {
            //propiedades.src = `data:image/png;base64, ${e.target.result}`
            setImagen(e.target.result)
            
            setNombresImagen(target.files[i].name)  
                 
          };
          
        }  
                   
    }; 

    const cleanup = () => {
        URL.revokeObjectURL(image);
        inputFileRef.current.value = null;
      };

    const setImage = (newImage) => {
        if (image) {
          cleanup();
        }
        _setImage(newImage);
      };


    const disabled = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )
  

    return ( 
                
        <Grid item xs={propiedades.xs} className={propiedades.classes} >     
        <Controller                         
            control={control}
            name={propiedades.name}
            defaultValue={propiedades.value}
             render={({onChange}) => (             
               <>
                <input
                ref={inputFileRef}
                multiple = {false}
                disabled={propiedades.disabled} 
                accept="image/*"
                onChange = {handleCapture}
                className={classes.input}
                id={propiedades.name}
                type="file"
               />
               <label htmlFor={propiedades.name}>
                  <Avatar 
                    src={image ||propiedades.src} 
                    style={{
                     margin: "10px",
                     width: propiedades.width || "60px",
                     height: propiedades.height || "60px",
                    }} 
                   />   
               </label> 
               </>      
            )}
            >   
           </Controller>        
        </Grid>          
        )



}
export default FormFieldAvatar;