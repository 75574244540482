import React from "react";

const getCosteTotalManodeObra = (datosJornadas, mano) => {
    let manoObraPropia = []
    let manoObraExterna = []

    if(datosJornadas && datosJornadas.length > 0){
        manoObraPropia = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Mano de obra propia" && parseInt(datosJornada.elementos.mano) === parseInt(mano))
        manoObraExterna = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Mano de obra externa" && parseInt(datosJornada.elementos.mano) === parseInt(mano))  
    }

    let costeTotalManodeObra = 0;
    let costeTotalManodeObraPropia = 0;
    let costeTotalManodeObraExterna = 0;
    let unidadesTotalManodeObraPropia=0;
    let unidadesTotalManodeObraExterna=0;    

    manoObraPropia.forEach(manoObra => {
        costeTotalManodeObra += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        costeTotalManodeObraPropia += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        unidadesTotalManodeObraPropia += parseInt(manoObra.elementos.cantidad)

    })

    manoObraExterna.forEach(manoObra => {
        costeTotalManodeObra += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        costeTotalManodeObraExterna += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        unidadesTotalManodeObraExterna += parseInt(manoObra.elementos.cantidad)
    })

    return {
        costeTotalManodeObra: costeTotalManodeObra, 
        costeTotalManodeObraPropia: costeTotalManodeObraPropia,
        costeTotalManodeObraExterna: costeTotalManodeObraExterna,
        unidadesTotalManodeObraPropia: unidadesTotalManodeObraPropia,
        unidadesTotalManodeObraExterna: unidadesTotalManodeObraExterna,
        ajustePrecioCPE: costeTotalManodeObraExterna*4/100
    }
}

const RecursosManoDetalleManoObraDesgloce = ({
    datosJornadas, mano}) => 
{  
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);
    const costeTotalManoObraAgrupado = getCosteTotalManodeObra(datosJornadas, mano)
    

    const costeTotalManoObra = numberFormat2.format(costeTotalManoObraAgrupado.costeTotalManodeObra + parseFloat(costeTotalManoObraAgrupado.ajustePrecioCPE)) 

    const ajustePrecioCPE = numberFormat2.format(parseFloat(costeTotalManoObraAgrupado.ajustePrecioCPE))
    const manoObraPropia = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Mano de obra propia" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []
    const manoObraExterna = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Mano de obra externa" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []   
    const unidadesTotalManodeObraPropia = costeTotalManoObraAgrupado.unidadesTotalManodeObraPropia;
    const unidadesTotalManodeObraExterna = costeTotalManoObraAgrupado.unidadesTotalManodeObraExterna;

    const manoObraTotalUnidades = unidadesTotalManodeObraPropia + unidadesTotalManodeObraExterna;


    return (
    <>
    <div className="col-12 d-flex overflow-auto justify-content-center mb-2">
        <div className="col-2 float-left">Empresa </div>
        <div className="col-1 float-right">N.º</div>
        <div className="col-5 float-left">Especialidad</div>
        <div className="col-2 float-left text-right">Cantidad </div>
        <div className="col-2 float-right text-right">Costes</div>
    </div>

    {manoObraPropia &&  manoObraPropia.map((manoPropia, index) => (
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">
            <div className="col-2 float-left">Gesport</div>
            <div className="col-1 float-right">{index+1}</div>
            <div className="col-5 float-left">{manoPropia.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{manoPropia.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format(manoPropia.elementos.Coste * manoPropia.elementos.cantidad)}</div>
        </div>
    ))}
                
    {manoObraExterna &&  manoObraExterna.map((manoExterna, index) => (         
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">           
            <div className="col-2 float-left">CPE</div>
            <div className="col-1 float-right">{manoObraPropia.length + index + 1}</div>
            <div className="col-5 float-left">{manoExterna.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{manoExterna.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format(manoExterna.elementos.Coste * manoExterna.elementos.cantidad)}</div>
        </div>         
    ))} 

    {manoObraExterna.length > 0 && (
    <div className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1"> 
        <div className="col-2 float-left">CPE</div>
        <div className="col-1 float-right">{manoObraPropia.length + manoObraExterna.length + 1}</div>
        <div className="col-5 float-left">Ajuste precio</div>
        <div className="col-2 float-left "></div>
        <div className="col-2 float-right text-right">{ajustePrecioCPE}</div>      
    </div>
    )}

    <div className="col-12 d-flex overflow-auto justify-content-center mt-2 mb-2 ">                    
        <div className="col-5 float-left"></div>
        <div className="col-3 float-right text-right">Total</div>                        
        <div className="col-2 float-left text-right">{manoObraTotalUnidades} </div>
        <div className="col-2 float-left text-right">{costeTotalManoObra}</div>                    
    </div>
    </>
    )
    
}

export default RecursosManoDetalleManoObraDesgloce;

