import React, { useState } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import {columnsEmpleados, columnsVacaciones, columnsPersonal} from './GestionPersonalConf';
import {botoneraEmpleados, botoneraVacaciones, botoneraPersonal } from './BotonesConf';
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import Pasos from "../../../../../components/Common/Pasos";
import SearchBar from "../../../../../components/Common/SearchBar";

const GestionPersonal = (props) => {     
  const [idOperacion, setIdOperacion]=useState(parseInt(localStorage.getItem('idOperacionGP')) || 0);
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
 
  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const verTabla = (idOperacion) => {   
    localStorage.setItem('idOperacionGP', idOperacion);  
    setIdOperacion(idOperacion)
  }  
  
  let columnasCuerpoTabla = columnsEmpleados;
  let urlDatosCuerpoTabla = "rrhhempleados";
  let urlDoubleClickCuerpoTabla = "rrhh/gestionpersonal/empleados/";
  let columnIdCuerpoTabla = "idDatosPersonales";
  let botonaccionCuerpoTabla = botoneraEmpleados;  

  if(idOperacion === 0){
    columnasCuerpoTabla = columnsEmpleados;
    urlDatosCuerpoTabla = "rrhhempleados";
    urlDoubleClickCuerpoTabla = "/rrhh/gestionpersonal/empleados/";
    columnIdCuerpoTabla = "idDatosPersonales";
    botonaccionCuerpoTabla = botoneraEmpleados
  }  

  if(idOperacion === 1){    
    columnasCuerpoTabla = columnsVacaciones;
    urlDatosCuerpoTabla = "rrhhvacaciones";
    urlDoubleClickCuerpoTabla = "/rrhh/gestionpersonal/vacaciones/";
    columnIdCuerpoTabla = "Id";
    botonaccionCuerpoTabla = botoneraVacaciones   
  } 

  if(idOperacion === 2){
  columnasCuerpoTabla = columnsPersonal;
  urlDatosCuerpoTabla = "rrhhgastospersonals";
  urlDoubleClickCuerpoTabla = "/rrhh/gestionpersonal/personal/";
  columnIdCuerpoTabla = "Id";
  botonaccionCuerpoTabla = botoneraPersonal
  } 

  /*
  if(idOperacion === 1){
    if(idSubOperacion === "Vacaciones"){
          columnasCuerpoTabla = columnsVacaciones;
          urlDatosCuerpoTabla = "rrhhvacaciones";
          urlDoubleClickCuerpoTabla = "/rrhh/gestionpersonal/vacaciones/";
          columnIdCuerpoTabla = "Id";
          botonaccionCuerpoTabla = botoneraVacaciones
    }

    if(idSubOperacion === "Personal"){
        columnasCuerpoTabla = columnsPersonal;
        urlDatosCuerpoTabla = "rrhhgastospersonals";
        urlDoubleClickCuerpoTabla = "/rrhh/gestionpersonal/personal/";
        columnIdCuerpoTabla = "Id";
        botonaccionCuerpoTabla = botoneraPersonal
    }    
  } 
  */


  console.log(idOperacion)
  console.log(urlDatosCuerpoTabla)
  console.log(botonaccionCuerpoTabla)
  return (           
  <div className="col-8 col-main-home ">            
    <SearchBar
      titulo="RRHH"
      claseCabecera = "bg-gestion"
      buscar={false}
      imagenCabecera={"6-3-2-rrhh.png"}
    />   
        
    <div className="bg-cuerpo p-10px">
      <div className="bg-white p-10px">
        <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Gestion Personal
              </h6>
            </div>
         <hr className="m-0 bg-comercial mb-1" />
        <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">             
          <Pasos pasos={['EMPLEADOS', 'VACACIONES', 'GASTOS ']} idPasoActivo={idOperacion} changePasoActivo={verTabla} />
              {/*{idOperacion===1 && ( <TipoTiempo opera={handleOperacion} subOperacion={idSubOperacion}/> )}*/}
          <CuerpoTablaPiezas
             columnas = {columnasCuerpoTabla}
                urlDatos = {urlDatosCuerpoTabla}
                urlDoubleClick = {urlDoubleClickCuerpoTabla}
                columnId = {columnIdCuerpoTabla}
             getFilaSeleccionada={marcarFila}
           /> 
        </div>
      </div>

      <div className="bg-cuerpo">
            <div className="bg-white">
              <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla}
              />            
            </div>
          </div>

    </div>
          
  </div>   
    
  );
}
export default GestionPersonal;
