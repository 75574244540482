import React, { useState, useEffect, useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";

import { useStyles } from "../../../../../styles/styles";


const FormularioCategorias = ({index, tipoForm}) => { 
 
  
  const [Categorias, setCategorias] = useState([])
  const [Subcategorias, setSubcategorias] = useState([]) 
  const [CamposCategoria, setCamposCategoria] = useState([])
  const [CamposSubcategoria, setCamposSubcategoria] = useState([])
  const { id, modo } = useParams();

  const methods = useForm();
  
  const classes = useStyles();;
  const { control, handleSubmit} = useForm(); 


  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

    
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };  

    useEffect(() => { 
      
     setCamposCategoria([
        {
            urlData: "tesoreriatipomovimientos",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "movimiento",
            disabled: false,
            label: "Tipo de Movimiento",
            type:'combo',
            value: '',
            xs: 12,
            classes:"mt-4"
          },
        {
            required: true,
            name: "categoria",
            disabled: false,
            label: "Categoria",
            type:'text',
            value: '',
            xs: 12,
            classes:"mt-4"
        },    
       
     ])

    const fetchData = async () => {
      setIsLoading(true) 
      if (modo === "W") {

          const resCategorias = await axios(
              //cambiar url cuando este el endpoint
              `${process.env.REACT_APP_API_URL}tesoreriacategorias/${id}`
          );                
          setCategorias(resCategorias.data)
          
      }
      setIsLoading(false);

  };    
  fetchData(); 
    
},[]);

useEffect(() => { 
     
    setCamposSubcategoria([
        {
          urlData: "tesoreriacategorias/listadocategorias",
          campoEtiqueta: "Etiqueta",
          required: true,
          name: "Categoria",
          disabled: false,
          label: "Categoria",
          type:'combo',
          value: '',
          xs: 12,
          classes:"mt-4"
        },
        {
          required: true,
          name: "Subcategoria",
          disabled: false,
          label: "Subcategoria",
          type:'text',
          value: '',
          xs: 12,
          classes:"mt-4"
        },
    ])

   const fetchData = async () => {
     setIsLoading(true) 
     if (modo === "W") {

         const resSubcategorias = await axios(
             //cambiar url cuando este el endpoint
             `${process.env.REACT_APP_API_URL}tesoreriasubcategorias/${id}`
         );                
         setSubcategorias(resSubcategorias.data)
         
     }
     setIsLoading(false);

 };    
 fetchData(); 
   
},[]);


//esta parte hace falta par cuando editas una operacion pendiente
useEffect(() => {
  if (typeof Categorias !== "undefined") { 
    if(Object.keys(Categorias).length > 0){
      setIsLoading(true)  
      CamposCategoria[0].value = Categorias.movimiento;
      CamposCategoria[1].value = Categorias.Categoria;
      setIsLoading(false)
    }
  }
}, [Categorias])

//esta parte hace falta par cuando editas una operacion pendiente
useEffect(() => {
    if (typeof Subcategorias !== "undefined") { 
      if(Object.keys(Subcategorias).length > 0){
        setIsLoading(true)  
        CamposSubcategoria[0].value = Subcategorias.Categoria;
        CamposSubcategoria[1].value = Subcategorias.Subcategoria;
        setIsLoading(false)
      }
    }
  }, [Subcategorias])


return (
<>    
  {!isLoading && (
  <>
    {/* FORMULARIO */}
    <div className={classes.root}>
        <FormProvider {...methods}>
        <Grid className="" container spacing={1}> 
            <Grid className="p-4" item xs={6} spacing={1}> 
                <FormularioEntidad 
                urlForm = "tesoreriacategorias"
                urlGoto = ""
                campos={CamposCategoria}
                />
            </Grid>
            <Grid className="p-4" item xs={6} spacing={1}> 
                <FormularioEntidad 
                urlForm = "tesoreriasubcategorias"
                urlGoto = ""
                campos={CamposSubcategoria}
                />
            </Grid>
        </Grid>
        </FormProvider>
        {openAlert && 
          <AlertaMensaje 
            mensaje={"¡Parte guardado correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }  
    </div>  
  </>
  )}
    <div>
      {isLoading && 
        <div className={classes.linear}>
              <LinearProgress />
        </div>
      }
    </div>
        </>
    )
};

export default FormularioCategorias;