import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
//import TableAcciones from "../Common/TableAcciones";
import TableAcciones from "../../../../components/Common/TableAcciones";
import { CircularProgress } from '@material-ui/core';

import TabsMyAccount from "./TabsMyAccount";




  const MyAccount = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const [datos, setDatos] = useState();

    useEffect(() => {
        setIsLoading(true)
    },[props.location.action])

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W" || modo === "undefined")) {
          const fetchData = async () => {
            const respAlmacen = await axios( process.env.REACT_APP_API_URL + "rrhhdatospersonales/propios/" + user.id);
            if (respAlmacen.data) {              
              setDatos(respAlmacen.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar Empleados" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/rrhh/gestionpersonal/` :
          modo === "R" ? 
            `/rrhh/gestionpersonal/empleados/${id}/W` : 
            `/rrhh/gestionpersonal/empleados/${id}/R`,
          activo: true,
        },
    ];

    const codigo = typeof datos !== "undefined" ? datos.Codigo : "";

    return (
        <>
          <div className="col-8 col-main-home ">
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <div className="p-1 bg-white mb-4">
                      <h6 className="texto-produccion float-left" id="title">
                        Mi cuenta
                      </h6>
                      <h6 className="texto-produccion float-right" id="title">
                        {codigo}
                      </h6>
                    </div>
    
                    <hr className="m-0 bg-comercial mb-4" />
    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">   
                           
                    <TabsMyAccount />   
                        
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default MyAccount;