import React from "react";

const columnsAlmacenamiento = [
  {
    name: "id",
    label: "Nº",
    options: {
     filter: false,
     sort: false,
    }
   },  
  {
      name: "cliente",
      label: "Clientes",
      options: {
       filter: true,
       sort: false,
      }
     },
     {
      name: "codigoPieza",
      label: "Pieza",
      options: {
       filter: false,
       sort: true,
       customBodyRender: (value, tableMeta, updateValue) => {   
        const url = "operativas/almacenamiento/piezas/" + tableMeta.tableData[tableMeta.rowIndex].idPieza + "/R"
        return (
          <a href={url}>{value}</a>
        );
       }

      }
     },
     {
      name: "pieza",
      label: "Nombre Pieza",
      options: {
       filter: false,
       sort: true, 
      }
     },
     {
      name: "unidades",
      label: "Cantidad",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "fechaEntrada",
      label: "Fecha Entrada",
      options: {
       filter: false,
       sort: true,
      }
     },  
     {
      name: "salidaEstimada",
      label: "Salida Estimada",
      options: {
       filter: false,
       sort: true,
      }
     },
     /*
     {
      name: "estado",
      label: "Estado",
      options: {
       filter: true,
       sort: true,
       
      }   
     }, 
     */ 
     {
        name: "estancia",
        label: "Dias Estancia",
        options: {
         filter: false,
         sort: true,
         
        }   
       }, 
       /*
       {
        name: "ubicacion",
        label: "Ubicación",
        options: {
         filter: true,
         sort: true,
         
        }   
       },
       */
       {
        name: "almacen",
        label: "Almacén",
        options: {
         filter: true,
         sort: true,
         
        }   
       }, 
       {
        name: "observaciones",
        label: "Observaciones",
        options: {
         filter: false,
         sort: true,
         
        }   
       },   
       
];

export {columnsAlmacenamiento};

const columnsPiezas = [
  {
    name: "id",
    label: "Nº",
    options: {
     filter: false,
     sort: false,
    }
   },
   {
    name: "codigo",
    label: "Código pieza",
    options: {
     filter: false,
     sort: true,
     customBodyRender: (value, tableMeta, updateValue) => {      
      const url = "operativas/almacenamiento/piezas/" + tableMeta.rowData[0] + "/R"
      return (
        <a href={url}>{value}</a>
      );
     }
    }
   },
   {
    name: "Nombre",
    label: "Nombre pieza",
    options: {
     filter: false,
     sort: true,
    }
   },
   {
    name: "categoria",
    label: "Categoría",
    options: {
     filter: true,
     sort: true,
    }
   },
   {
    name: "fechaCreacion",
    label: "Fecha creación",
    options: {
     filter: false,
     sort: true,
    }
   },  
   {
    name: "descripcion",
    label: "Descripción",
    options: {
     filter: false,
     sort: true,
    }
   },
   {
    name: "medidas",
    label: "Medidas",
    options: {
     filter: false,
     sort: true,
    }
   },
   {
    name: "peso",
    label: "Peso",
    options: {
     filter: false,
     sort: true,
     
    }   
   },  
   {
    name: "estado",
    label: "Estado",
    options: {
     filter: true,
     sort: true,
     
    }   
   },     
     
];

export {columnsPiezas};


const columnsAlmacenes = [
    {
      name: "Id",
      label: "Nº",
      options: {
       filter: false,
       sort: false,
      }
     },
     {
      name: "codigo",
      label: "Cód. almacén",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "Nombre",
      label: "Nombre Almacén",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "categoria",
      label: "Tipología",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "tamano",
      label: "Tamaño(m2)",
      options: {
       filter: false,
       sort: true,
      }
     },  
     {
      name: "areas",
      label: "Nº áreas",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "Tasa_ocupacion",
      label: "Tasa ocupación",
      options: {
       filter: false,
       sort: true,
      }
     },  
     {
      name: "estado",
      label: "Estado",
      options: {
       filter: true,
       sort: true,
       
      }   
     },     
       
];

export {columnsAlmacenes};


const columnsRecepcion = [     
     {
      name: "id",
      label: "Nº",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "Codigo",
      label: "Cód. Entrada",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "Fecha_Entrada",
      label: "Fecha Entrada",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "categoria",
      label: "Categoría",
      options: {
       filter: true,
       sort: true,
      }
     },
     
     {
      name: "pieza",
      label: "Pieza",
      options: {
       filter: false,
       sort: true,
       customBodyRender: (value, tableMeta, updateValue) => {         
        const idPiezas = tableMeta.tableData[tableMeta.rowIndex].idPiezas
        const piezas = tableMeta.tableData[tableMeta.rowIndex].codigosPiezas
        const nombrePiezas = tableMeta.tableData[tableMeta.rowIndex].pieza
        if(idPiezas){        
          const idPiezasArray = idPiezas.split(",");
          const codigoPiezasArray = piezas.split(",");
          const nombrePiezasArray = nombrePiezas.split(",");          
          let arrayFinal = [];
          codigoPiezasArray.forEach((element, index) => {
            const url = "operativas/almacenamiento/piezas/" + idPiezasArray[index] + "/R"
            arrayFinal.push(<p><a href={url}>{codigoPiezasArray[index] + ' ' + nombrePiezasArray[index]}</a></p>);
          });          
          return (arrayFinal);
        }
        return value;
       }
      }
     },   
     {
      name: "Unidades",
      label: "Cantidad",
      options: {
       filter: false,
       sort: true,
      }
     },    
     {
      name: "deposito",
      label: "Depósito",
      options: {
       filter: true,
       sort: true,
      }
     }, 
     {
      name: "estado",
      label: "Estado",
      options: {
       filter: true,
       sort: true,
       
      }   
     },     
       
];

export {columnsRecepcion};


const columnsDespacho = [
    {
      name: "id",
      label: "Nº",
      options: {
       filter: false,
       sort: false,
      }
     }, 
     {
      name: "Codigo_Salida",
      label: "Cód. Salida",
      options: {
       filter: false,
       sort: false,
      }
     }, 
     {
      name: "FechaSalida",
      label: "Fecha Salida",
      options: {
       filter: false,
       sort: false,
      }
     }, 
    {
      name: "cliente",
      label: "Clientes",
      options: {
       filter: true,
       sort: false,
      }
     },     
     {
      name: "pieza",
      label: "Pieza",
      options: {
       filter: false,
       sort: true,
       customBodyRender: (value, tableMeta, updateValue) => {         
        const idPieza = tableMeta.tableData[tableMeta.rowIndex].idPieza
        const pieza = tableMeta.tableData[tableMeta.rowIndex].codigoPieza        
        if(idPieza){ 
          const url = "operativas/almacenamiento/piezas/" + idPieza + "/R"
          return (
            <a href={url}>{pieza + ' ' + value}</a>
          );
        }
        return value;
       }
      }
     },
     {
      name: "categoria",
      label: "Categoría",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "fechaCreacion",
      label: "Fecha Creación",
      options: {
       filter: false,
       sort: true,
      }
     },  
     {
      name: "descripcion",
      label: "Descripción",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "medidas",
      label: "Medidas",
      options: {
       filter: false,
       sort: true,
      }
     },  
     {
      name: "peso",
      label: "Peso",
      options: {
       filter: false,
       sort: true,
       
      }   
     },
     /*
     {
      name: "estado",
      label: "Estado",
      options: {
       filter: true,
       sort: true,
       
      }   
     },   
     */  
       
];

export {columnsDespacho};