import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

const FormFieldNumberUncontrolled = ({ propiedades, onChangeValue=null }) => {      
    const { modo } = useParams();

    const [valor, setValor] = useState(propiedades.value);
    const handleChange = (event) => {
        if(onChangeValue)
            onChangeValue(event.target.value);
            
        setValor(event.target.value);
    };

    useEffect(() => {
        setValor(propiedades.value);
    }, [propiedades.value])

    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
      )

    return (               
    <Grid item xs={propiedades.xs} className="" >  
    <TextField  
        required = {propiedades.required}
        id={propiedades.name}
        name={propiedades.name}
        disabled = {deshabilitado}
        label={propiedades.label}
        type="number" 
        InputProps={{ inputProps: { 
            step: propiedades.step ? propiedades.step : 0.5,
            min: propiedades.min || propiedades.min === 0 ? propiedades.min : Number.MIN_SAFE_INTEGER, 
            max: propiedades.max ? propiedades.max : Number.MAX_VALUE } }}
        value={valor} 
        onChange={handleChange}
        variant="outlined"
        size="small"
        fullWidth
        helperText = {propiedades.helperText ? propiedades.helperText : ""}
        /> 
    </Grid>       
    )
}
export default FormFieldNumberUncontrolled;