import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioProyecto = ({setCodigo}) => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [proyecto, setProyecto] = useState([])  
    const [clientes, setClientes] = useState("")     
    const [otros, setOtros] = useState("")

    const classDiv3 = "col-3 mt-2 mb-2"

    const handleChangeCuenta = (cuentas) => {
      if (Array.isArray(cuentas)){
        setClientes(cuentas.map((cuenta) => cuenta.Id).join("_"))
      }
    }   
    
    useEffect(() => { 

        setCampos([
          {
            urlData: "dic/diccionarios/proyectostipos",
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Tipo",
            name: "tipo",
            disabled: false,
            type: "combo",           
            multiple:false,   
            value: '',
            xs:4,
            onChangeValue: handleChangeTipo,
          },  
          {    
            required: false,
            label: "Tipo:",
            name: "tipoOtros",
            type: "text",    
            disabled: true, 
            value: '',                
            xs:12,
            hidden: true
          },     
          {    
            required: true,
            label: "Nombre:",
            name: "nombre",
            type: "text",    
            disabled: false, 
            value: '',                
            xs:4
          },   
          {
            urlData: "cuentas/clientes",
            campoEtiqueta: "Nombre_Comercial",
            required: false,
            label: "Cliente",
            name: "nombreComercial",
            disabled: false,
            type: "combo",           
            multiple:true,   
            value: [],
            xs:4,
            onChangeValue: handleChangeCuenta
          },        
          {
            urlData: "contactos",
            campoEtiqueta: "Nombre",
            required: false,
            label: "Contacto",
            name: "contacto",
            disabled: false,
            type: "combo",           
            multiple:true,   
            value: [],
            xs:4
          },
          {
            urlData: "dic/diccionarios/listadousuarios",
            campoEtiqueta: "nombre",
            campoId:"id",
            required: false,
            label: "Empleado",
            name: "empleado",
            disabled: false,
            type: "combo",           
            multiple:true,   
            value: [],
            xs:4
          },
          {
            urlData: "dic/diccionarios/listadousuarios",
            campoEtiqueta: "nombre",
            required: false,
            label: "Supervisor",
            name: "supervisor",
            disabled: false,
            type: "combo",           
            multiple:false,   
            value: '',
            xs:4
          },
          {
            urlData: "oportunidads/listadooportunidades",
            campoEtiqueta: "Codigo",
            required: false,
            label: "Oportunidad",
            name: "oportunidad",
            disabled: false,
            type: "combo",           
            multiple:false,   
            value: '',
            xs:4
          },
          {
            urlData: "actividads/listadoactividades",
            campoEtiqueta: "Codigo",
            required: false,
            label: "Actividad",
            name: "actividad",
            disabled: false,
            type: "combo",           
            multiple:true,   
            value: [],
            xs:4
          },
          {
              urlData: "dic/diccionarios/listadovisibilidades",
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Visibilidad",
              name: "visibilidad",
              type: "combo",  
              value: '',
              multiple:false,
              xs:4
          },            
          {
            required: false,
            label: "Fecha inicio",
            name: "fechainicio",
            type: "date",  
            value: '',
            xs:3
          },
          {
              required: false,
              label: "Fecha estimada cierre",
              name: "fechacierre",
              type: "date",  
              value: '',
              xs:3
          },
          {
              urlData: "dic/diccionarios/proyectosestados",
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Estado",
              name: "estado",
              disabled: false,
              type: "combo",   
              value: '',
              multiple:false,
              xs:3
          },
          {
              urlData: "dic/diccionarios/proyectosresultados",
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Resultado",
              name: "resultado",
              disabled: false,
              type: "combo",   
              value: '',
              multiple:false,
              xs:3
          },
          {
              required: false,
              label: "Previsión de gastos",
              name: "previsiogastos",
              type: "number",              
              value: '',
              xs:3
          },
          {
              required: false,
              label: "Presupuesto asignado",
              name: "presupuesto",
              type: "switch",              
              value: 0,
              xs:3
          },
          {
              required: false,
              label: "Proyecto recurrente",
              name: "recurrente",
              type: "switch",              
              value: 0,
              xs:3
          },
          {
              required: false,
              label: "Descripción",
              name: "descripcion",
              type: "textArea",
              lineas: 4,                
              value: '',
              xs:6
          },
          {
              required: false,
              label: "Notas",
              name: "notas",
              type: "textArea",
              lineas: 4,  
              value: '',                
              xs:6
          }, 
          {
              classButton: classDiv3,
              required: false,
              label: "",
              name: "ficherosadjuntos",
              type: "filesupload",   
              tabla: "mtms_proyectos_adjuntos",
              xs:5
          },            
          {
              required: false,
              label: "",
              name: "ficherosremotos",
              type: "files", 
              ficheros: proyecto.ficheros,    
              tabla: "mtms_proyectos_adjuntos",
              modelo: "api\\modules\\v1\\models\\proyectos\\ProyectosAdjuntos",
              carpeta: "proyectos",
              xs:4
          },            
        ])   
            
        const fetchData = async () => {             
            
            if (modo === "W") {
                const respOportunidad = await axios(
                    `${process.env.REACT_APP_API_URL}proyectos/${id}`
                );                
                setProyecto(respOportunidad.data)
                setCodigo(respOportunidad.data?.Codigo)   
            }
            setDatosCargados(true);                      
        };    
        fetchData(); 

        return () => {
          setProyecto([]) 
          setDatosCargados(true);
        }

    },[]);

    useEffect(() => {            
        if (typeof proyecto !== "undefined") {        
          if(Object.keys(proyecto).length > 0){            
            setDatosCargados(false)            
            campos[0].value = proyecto.Tipo;
            setOtros(proyecto.Tipo.Etiqueta)            
            if(proyecto.TipoOtros){
              console.log(proyecto.TipoOtros)
              campos[1].required = true;
              campos[1].disabled = false;
              campos[1].value = proyecto.TipoOtros;
              campos[1].hidden = false;
            }else{
              campos[1].required = false;
              campos[1].disabled = true;
              campos[1].value = "";
              campos[1].hidden = true
            }  
            campos[2].value = proyecto.Nombre;
            campos[3].value = proyecto.Cuentas;
            campos[4].value = proyecto.Contactos;
            campos[5].value = proyecto.Empleados;
            campos[6].value = proyecto.Supervisor;
            campos[7].value = proyecto.Oportunidad;
            campos[8].value = proyecto.Actividades;
            campos[9].value = proyecto.Visibilidad;
            campos[10].value = proyecto.Fecha_Inicio;
            campos[11].value = proyecto.Fecha_Cierre;
            campos[12].value = proyecto.Estado;
            campos[13].value = proyecto.Resultado;
            campos[14].value = proyecto.Prevision;
            campos[15].value = proyecto.Presupuesto;
            campos[16].value = proyecto.Recurrente;
            campos[17].value = proyecto.Descripcion;
            campos[18].value = proyecto.Notas;
            campos[20].ficheros = proyecto.ficheros;     
            if (Array.isArray(proyecto.Cuentas)){
              setClientes(proyecto.Cuentas.map((cuenta) => cuenta.Id).join("_"))
            }        
            setDatosCargados(true)  
          }
      }
      },[proyecto])

      useEffect(() => { 
        if (typeof campos !== "undefined") {        
            if(Object.keys(campos).length > 0){
              setDatosCargados(false)
              if(clientes) campos[4].urlData = "cuentas/contactos/" + clientes
              else campos[4].urlData = "contactos" 
              setDatosCargados(true)
          }
        }
      },[clientes])

      const handleChangeTipo = (valor) => {
        if(valor){
          setOtros(valor.Etiqueta)      
        }
      }

      if (typeof campos !== "undefined") {        
        if(Object.keys(campos).length > 0){
          if(otros === "Otros"){
            campos[1].required = true;
            campos[1].disabled = false;
            campos[1].hidden = false
          }else{
            campos[1].required = false;
            campos[1].disabled = true;
            campos[1].value = "";
            campos[1].hidden = true
          }          
        }
      }
          
    if (datoscargados){
    return (          
        <FormularioEntidad 
          urlForm = "proyectos"
          urlGoto = "/crm/proyectos/proyecto/"
          campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }



  }

  export default FormularioProyecto;