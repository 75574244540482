import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import NotaAviso from './NotaAviso'
import Button from '@material-ui/core/Button';
import {CAMPOS_NOTAS} from './AgendaNotasConf';  
import DialogForm from "./DialogForm";

const useStyles = makeStyles((theme) => ({    
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      marginTop: '3px !important',
      color: '#505050'
    },
    detalleNota: {
        display: 'block',
        maxHeight: '320px',
        overflow: 'auto',
    },   
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -10,
      top: 5,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
}))(Badge);

const AgendaNotas = ({handleNumNotas}) => {
    //se actualiza cada 5 minutos
    const TIEMPO_DE_REFRESCO = 1000 * 5 * 5; //milisegundos * segundos * minutos 
    const classes = useStyles();
    const [notas, setNotas] = useState([])    
    const [open, setOpen] = React.useState(false) 
    const [campos, setCampos] = useState(CAMPOS_NOTAS)      
    const tipoFormulario = useRef('add'); 
    const [value, setValue] = useState()
    
    const fetchData = async () => {            
        let user = JSON.parse(localStorage.getItem("user"));            
        const respData = await axios( process.env.REACT_APP_API_URL + "notasavisodestinatarios/" + user.id);
        if (respData.data) {                                  
            handleNumNotas(respData.data.filter(nota => nota.Leido === 0).length)                       
            setNotas(respData.data); 
        }else{
            setNotas([])
        }   
    };

    useEffect(() => {  
        fetchData()
        const interval = setInterval(() => {
            fetchData()
        }, TIEMPO_DE_REFRESCO);

        return () => clearInterval(interval)      
    }, [])

    const handleNotas = (actualizar) => { 
        if (actualizar){
            fetchData();
        }
    }

    const notasSinLeer = notas.filter(nota => nota.Leido === 0)

    const handleClose = () => {
        let camposAux = [...campos];
        camposAux[0].value = "NOTA DE USUARIO"
        camposAux[1].value = ''
        camposAux[2].value = ''
        camposAux[3].value = ''
        camposAux[4].value = ''
        camposAux[5].value = ""
        camposAux[6].value = ""
        camposAux[7].value = "" 
        camposAux[8].ficheros = ""    
        setOpen(false)    
    };

    const accion = () =>{
        fetchData()
        handleClose()
    };

    const handleEditNota = (nota) => { 
        const numeroDeDestinatarios = nota.Destinatarios.length
        let destinatarios = []
            
        for(let i=0;i<numeroDeDestinatarios; i++){
            destinatarios[i] = nota.Destinatarios[i]
        }

        campos[0].value = nota.Asunto
        campos[0].disabled = false
        campos[1].value = destinatarios[0]
        campos[1].disabled = false
        campos[2].value = destinatarios[1] || null
        campos[2].disabled = false
        campos[3].value = destinatarios[2] || null
        campos[3].disabled = false
        campos[4].value = destinatarios[3] || null
        campos[4].disabled = false

        campos[5].value = nota.Mensaje 
        campos[5].disabled = false
        campos[6].value = nota.remitente 
        campos[6].disabled = false
        campos[8].ficheros = nota.ficheros      
        campos[8].disabled = false  
        tipoFormulario.current = "edit"
        setValue(nota.notaAvisoId)  
        setOpen(true)
    }

    const handleReplyNota = (nota) => {
        const user = JSON.parse(localStorage.getItem("user"));
        const remitente = nota.Destinatarios.filter(destinatario => destinatario.id === user.id)
        campos[0].value = "Re: " + nota.Asunto
        campos[0].disabled = false
        campos[1].value = nota.remitente
        campos[1].disabled = true
        campos[2].value = null
        campos[2].disabled = true
        campos[3].value = null
        campos[3].disabled = true
        campos[4].value = null
        campos[4].disabled = true
        campos[5].value = ""
        campos[5].disabled = false
        
        campos[6].value = remitente 
        campos[6].disabled = false
        campos[8].ficheros = null    
        campos[8].disabled = false  
        tipoFormulario.current = "edit"
        setValue(nota.notaAvisoId)  
        setOpen(true)
    }

    const handleReadNota = (nota) => {
        const numeroDeDestinatarios = nota.Destinatarios.length        
        let destinatarios = []
            
        for(let i=0;i<numeroDeDestinatarios; i++){
            destinatarios[i] = nota.Destinatarios[i]
        }

        campos[0].value = nota.Asunto
        campos[0].disabled = true  

        campos[1].value = destinatarios[0]
        campos[1].disabled = true
        campos[2].value = destinatarios[1] || null
        campos[2].disabled = true
        campos[3].value = destinatarios[2] || null
        campos[3].disabled = true
        campos[4].value = destinatarios[3] || null
        campos[4].disabled = true

        campos[5].value = nota.Mensaje 
        campos[5].disabled = true
        campos[6].value = nota.remitente 
        campos[6].disabled = true
        campos[8].ficheros = nota.ficheros      
        campos[8].disabled = true  
        tipoFormulario.current = "read"
        setOpen(true)
    }    

    return (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header">      
            <span id="title-image" className="float-left mr-2 ">
            <img
                src={`${process.env.REACT_APP_IMAGES}2-1-avisos.png`}
                width="24"
                height="24"
                alt=""
                loading="lazy"
            />
            </span>      
            <StyledBadge badgeContent={notasSinLeer.length} color="secondary">
                <Typography className={classes.heading}>Notas de Aviso</Typography>
            </StyledBadge>
        </AccordionSummary>
        <AccordionDetails className={classes.detalleNota}>     
            <Button onClick={()=>setOpen(true)}>+</Button>        
            { notas.map((nota) => (
            <NotaAviso 
                key={nota.notaAvisoDestinatarioId} 
                nota={nota} 
                actualizaNotas={handleNotas} 
                editNota={handleEditNota}
                onClick={handleReadNota}
                replyNota={handleReplyNota}
            />)) }

            <DialogForm 
                title={"Nota de aviso"}
                isOpen={open} 
                handleCloseDialog={handleClose}
                urlForm={"notasavisos"}
                action={accion}
                campos={campos}
                urlGoto={""}
                type={tipoFormulario.current}
                value={value}
                textoBoton2={"ENVIAR"}
            />
        </AccordionDetails>
    </Accordion> 
    )
}

export default AgendaNotas;