import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'
import AlertaMensaje from "../../../../../components/Common/AlertaMensaje";

const FichaActividad = ({setCodigo}) => {
    let { id } = useParams();
    const history = useHistory(); 
    const [actividad, setActividad] = useState([])  
    const [campos, setCampos] = useState([])  
    const [isLoading, setIsLoading] = useState(true);
    const [openAlert,setOpenAlert] = useState({
        open : false,
        tipoMensaje : "success",
        mensaje : ""
    }); 
    
    const handleCloseAlert = (event, reason)=>{
        if(reason === "clickaway"){
          return
        }
        setOpenAlert({...openAlert,open:false});
      }

    useEffect(() => {        
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_API_URL}actividads/${id}`;
            axios.get(url)
            .then((response) => {                
                setActividad(response.data) 
                setCodigo(response.data.Codigo)             
            })
            .catch((error) => {
                console.log(error)
                setOpenAlert({
                    open : true,
                    tipoMensaje : "error",
                    mensaje : error?.response?.data?.message
                })

                setTimeout(function () {                
                    history.push({
                    pathname: `/crm/actividades`,
                    });                
                }
                , 3000);              
            })
            .finally(() => {
                setIsLoading(false)
            })
        };
        fetchData();  
    }, [id]);

    

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(actividad).length > 0){  
            //console.log(actividad)
            const tipoOtro = actividad.TipoOtros ? actividad.TipoOtros : ""
          setCampos([
            {
                classsLabel: "",
                label: "Tipo:",
                value: actividad.Tipo.Etiqueta + " " + tipoOtro,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Nombre:",
                value: actividad.Nombre,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Visibilidad:",
                value: actividad.Visibilidad?.Etiqueta || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                campoEtiqueta: 'Razon_Social',
                label: "Cliente(s):",
                value: actividad.Cuentas,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                campoEtiqueta: 'Nombre',
                label: "Contacto(s):",
                value: actividad.Contactos,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                campoEtiqueta: 'Codigo',
                label: "Oportunidad",
                value: actividad.Oportunidad?.Codigo || '',
                classText: classText,
                xs:2
            },
            {
                classsLabel: "",
                campoEtiqueta: 'Codigo',
                label: "Proyecto",
                value: actividad.Proyecto?.Codigo || '',
                classText: classText,
                xs:2
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },            
            {
                classsLabel: "",
                campoEtiqueta: 'nombre',
                label: "Empleado(s):",
                value: actividad.Empleados,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Supervisor:",
                value: actividad.Supervisor?.nombre || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Resultado actividad:",
                value: actividad.Estado?.Etiqueta || '',
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
              },
            {
                classsLabel: "",
                label: "Fecha Inicio:",
                value: actividad.Fecha_Inicio || '',
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Hora Inicio:",
                value: actividad.Hora_Inicio || '',
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Fecha estimada cierre:",
                value: actividad.Fecha_Fin || '',
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Hora cierre:",
                value: actividad.Hora_Fin || '',
                classText: classText,
                xs:3
            },            
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Descripción:",
                value: actividad.Descripcion || '',
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Notas:",
                value: actividad.Notas || '',
                classText: classText,
                //link:`${actividad.Web}`,
                xs:6
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
              },
            {
                classsLabel: "",
                label: "Archivos adjuntos:",
                value: actividad.ficheros,
                classText: classText,
                type: "file",
                xs:7
              },
            
          ])
        }
    },[actividad]) 
    
    return(
        <>
        <h6 className="texto-comercial" id="title">
        </h6>
        {!isLoading && (
            <FichaEntidad
                campos = {campos}
                urlClick = {`/crm/actividades/actividad/${id}/W`}
            />
        )}
        {openAlert.open && (
            <AlertaMensaje 
                mensaje={openAlert.mensaje} 
                isOpen={openAlert.open} 
                tipoMensaje={openAlert.tipoMensaje}
                cerrar={handleCloseAlert}
            />                  
        )}
        </>
      )
}
export default FichaActividad;