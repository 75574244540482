import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'
import TableAcciones from "../../../../../components/Common/TableAcciones";
import MigasDePan from "../../../../../components/Common/MigasDePan";
import SearchBar from "../../../../../components/Common/SearchBar";

const FichaBuques = () => {
  let { id } = useParams();

  const [buque, setBuque] = useState([])  
  const [campos, setCampos] = useState([]) 

  useEffect(() => {        
    const fetchData = async () => {
      const respBuque = await axios(`${process.env.REACT_APP_API_URL}buques/${id}`);    
      setBuque(respBuque.data)  
    };
    fetchData();  
  }, [id]);

  useEffect(() => {      
    if(Object.keys(buque).length > 0){  
      setCampos(ActualizaValoresCampos(buque))
    }
  },[buque]) 

  const botoneraAcciones = [
    {
      etiqueta: "Editar",
      url: `/crm/buques/${id}/W`,
      activo: true,
    },
    {
      etiqueta: "Cancelar",
      url: '/crm/buques',
      activo: true,
    } 
  ];

  const urlsMigasdepan = [
    {titulo: "CRM", colorTexto: "texto-comercial", urlTo: "/crm/buques"},
    {titulo: "Buques", colorTexto: "texto-comercial",urlTo:""},
  ]

  return(
  <>
    <div className="col-8 col-main-home ">
      <SearchBar
        titulo="Buques"
        claseCabecera = "bg-comercial"
        buscar={false}
        imagenCabecera={"6-3-2-rrhh.png"}
      />
      <div className="bg-cuerpo p-10px">
        <div className="bg-white p-10px">
          <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan}/>
          <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
            <h6 className="texto-comercial" id="title">
            </h6>
            <FichaEntidad
              campos = {campos}
              urlClick = {`/crm/buques/${id}/W`}
            /> 
          </div>
        </div>
        <TableAcciones
          filaSeleccionada={-1}
          botonaccion={botoneraAcciones}
        />
      </div>
    </div>
  </>
  )
}
export default FichaBuques;

function ActualizaValoresCampos(buque){
  const classText= "Texto14"

  return ([
    {
      classsLabel: "",
      label: "IMO:",
      value:buque.IMO ,
      classText: classText,
      xs:3
    },
    {
      classsLabel: "",
      label: "Nombre:",
      value: buque.Nombre,
      classText: classText,
      xs:3
    },
    {
      classsLabel: "",
      label: "Tipo Buque:",
      value: buque.Fk_Tipo_Buque ? buque.Fk_Tipo_Buque.Etiqueta : "",
      classText: classText,
      xs:3
    },
    {
      classsLabel: "",
      label: "Codigo buque:",
      value: buque.CodBuque,
      classText: classText,
      xs:3
    },
    {
      classsLabel: "",
      label: "Eslora:",
      value: buque.Eslora,
      classText: classText,
      xs:3
    },
    {
      classsLabel: "",
      label: "Manga:",
      value:buque.Manga,
      classText: classText,
      xs:3
    },
    {
      classsLabel: "",
      label: "Calado:",
      value:buque.Calado,
      classText: classText,
      xs:3
    },
    {
      classsLabel: "",
      label: "Estado:",
      value: buque.Activo === 1 ? "Activo" : "Inactivo",
      classText: classText,
      xs:3
    }
  ])
}