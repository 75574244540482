import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import ListadoProductos from './ListadoProductos'

const FichaEntidad = ({  campos, urlClick }) =>
{     
    const obtenEtiqueta = (elemento, valor) => {  
        const campoEtiqueta = typeof elemento.campoEtiqueta !== 'undefined' ? elemento.campoEtiqueta : 'Etiqueta'        
        return(valor[campoEtiqueta])
     }     

    if(campos.length > 0){
    return(
    <>
        <div className="rounded border border-formulario p1-form">
            <div className="p-2 bg-white mb-2  m-0">
                <div className="p-1 bg-white mb-2 p3-form m-0">
                    <Grid container spacing={1}>
                        {campos.map((element, index) => (
                            
                        <React.Fragment key={index}>
                            <Grid item xs={element.xs} className="" key={index}>
                                <InputLabel className={element.classsLabel}>
                                    {element.label} 
                                </InputLabel>
                                <InputLabel className={element.classsLabel}>
                                <>
                                {!Array.isArray(element.value) && (
                                    <b className={element.classText}>
                                        {element.link && element.link.includes("http") === true && (
                                            <a 
                                            target='_blank' rel='noopener noreferrer'
                                            href={element.link}>{element.value}</a>                                            
                                        )}

                                        {element.link && element.link.includes("http") === false &&  (
                                            <a href={element.link}>{element.value}</a>                                            
                                        )}
                                        {!element.link && (
                                            element.value
                                        )}                                        
                                    </b>
                                )}

                                {typeof(element.type) !== "undefined" && element.type === "listadoProductos" && (   
                                    <ListadoProductos listadoProductos={element.value} /> 
                                )}

                                {Array.isArray(element.value) && (  
                                    element.value.map((option, index) => (                                        
                                    <li key={index}>
                                        {element.type === "file" && (
                                        <b className={option.classText}>
                                            <a 
                                                target="_blank" rel="noopener noreferrer" 
                                                href={option.Ubicacion}>{option.Nombre}</a>
                                        </b>
                                        )}  
                                        
                                        {element.type !== "file" && element.type !== "listadoProductos" && (                                            
                                            <b className={option.classText}>                                               
                                                {obtenEtiqueta(element, option)}   
                                            </b>
                                        )}
                                    </li>
                                    ))
                                )}

                                </>
                                </InputLabel>
                            </Grid>
                            
                        </React.Fragment>  
                        )           
                        )} 
                    </Grid>
                </div>
            </div>
        </div>
    </>
    )
    }
    else return (
        <>
        <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />        
        </>
      );
}

export default FichaEntidad;