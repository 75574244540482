import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";


const FormFieldNumber = ({ propiedades, control }) => {      
    const { modo } = useParams();
    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )    
   
    return (               
    <Grid item xs={propiedades.xs} className={propiedades.classes} >  
    <Controller                        
        as={TextField}   
        required = {propiedades.required}
        control={control}
        id={propiedades.name}
        name={propiedades.name}
        disabled={deshabilitado} //{modo==="R" || propiedades.disabled ?true:false}
        label={propiedades.label}
        type="number"        
        defaultValue={propiedades.value}
        InputProps={{ inputProps: { 
            step: propiedades.step ? propiedades.step : 0.5,
            min: propiedades.min || propiedades.min === 0 ? propiedades.min : Number.MIN_SAFE_INTEGER, 
            max: propiedades.max ? propiedades.max : Number.MAX_VALUE } }}
        variant="outlined"
        size="small"
        fullWidth    
        InputLabelProps={{
            shrink: true,
        }}    
        > 
    </Controller> 
    </Grid>       
    )
}
export default FormFieldNumber;