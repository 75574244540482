import React, { useState, useEffect, useRef } from "react";
import axios from "axios"; 
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
import TodayIcon from '@material-ui/icons/Today';
import PrintIcon from '@material-ui/icons/Print';
import { useReactToPrint } from "react-to-print";
import Agenda from "../../../../../components/Common/Agenda";
import { CircularProgress } from "@material-ui/core";
import SearchBar from "../../../../../components/Common/SearchBar";
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        '& > *': {
        //margin: theme.spacing(1),
        width: theme.spacing(25),
        //minHeight: theme.spacing(25),
        borderRadius: '15px !important',
        //padding: theme.spacing(1),
        },
        padding:10,
    },
    accordion: {
        minHeight: '130px !important;',
        paddingTop: '20px !important;',
        /*padding: '-1px -1px !important;',*/
        width: '100% !important',
    },
    accordionSummary: {
        marginBottom: '0px !important',
        padding: '0px 10px !important',
        minHeight: '90px !important',
    },
    /*
    cabecera: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    */
    pie: {
        marginTop: 10,
    },
    diaMes: {
        fontFamily: 'Open Sans',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        color: '#3465A4'
    },
    agenda: {
        fontFamily: 'Open Sans',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        color: '#A6A5A5'
    },
    lineHR: {
        borderTop: "1px solid #A6A5A5",
        backgroundColor: '#A6A5A5',
        marginLeft: '25px !important',
        marginRight: '25px !important',
    },
    title: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightBold,
    },
    horario: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        color: '#000'
    },
    otrosTextos: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px',
    },
    enlacePlanificacion: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '20px',
        marginTop: '20px',
        marginLeft: 20,
    },
    otrosTextosDesplazado: {
        fontSize: 12,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '20px',
        marginLeft: "16px",
    },
    estado: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: 20,
        marginTop: 10,
    },
    validada: {
        color: "green",
    },
    pendiente: {
        color: "#FF0000",
    },
    fondoDiaActual:
    {
        backgroundColor: "#EBEBEB",
    },
    bordevalidada: {
        borderColor: "green",
        borderStyle: 'groove',
    },
    bordependiente: {
        borderColor: "#FF0000",
        borderStyle: 'groove',
    },
    
}));

const PlanningSemanal = () => {
    const classes = useStyles();
    const componentRef = useRef();
    const [datosManos, setDatosManos] = useState([]); 
    const [counter, setCounter] = useState(new Date());
    const [diasSemanaActual, setDiasSemanaActual] = useState([]);
    const semanaActual = useRef({fechInicial: '', fechaFinal: '', anioActual: '', mesActual: ''});
    const [numAvisos, setNumAvisos] = useState(0)
    const [isLoading, setIsLoading] = useState(true)

    //let anioActual = '';
    //let mesActual = '';
    let firstDay = '';
    let lastDay = '';
    
    const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

    const changeDataWeek = (diasSumar = 0) => {
        let curr = new Date(); 

        if(semanaActual.current.fechInicial)
            curr = new Date(semanaActual.current.fechInicial);
        
        curr.setDate(curr.getDate() + diasSumar);

        let first = curr.getDate();// - curr.getDay() + 1; 
        let last = first + 6;

        const anioActual = curr.getFullYear();
        const mesActual = curr.getMonth();
        const numeroDiasMes = daysInMonth(anioActual, mesActual+1);

        let dias = [{},{},{},{},{},{},{}];
        let j = 1;
        for(let i=0; i<=6; i++){
            dias[i] = {dia: first + i, mes: mesActual};
            if(dias[i].dia > numeroDiasMes){
                dias[i] = {dia: j++, mes: mesActual === 11 ? 0 : mesActual + 1};
            }            
        }
        
        setDiasSemanaActual(dias);
        firstDay = new Date(curr.setDate(first)).toISOString().split('T')[0];
        lastDay = new Date(curr.setDate(last)).toISOString().split('T')[0];
        semanaActual.current = {fechInicial: firstDay, fechaFinal: lastDay, anio: anioActual, mes: mesActual}
    }
   
    const INTERVAL_TIME = 1000 * 60 * 5; // 5 Minutos 1000 * 60 * 5

    const getData = () => {
        (async () => {
            setIsLoading(true)
            const URL_DATOS = process.env.REACT_APP_API_URL + `planificaciones/planningsemanal?fechainicial=${semanaActual.current.fechInicial}&fechafinal=${semanaActual.current.fechaFinal}`
            const respDatos = await axios(URL_DATOS);
            if(Array.isArray(respDatos.data) && respDatos.data.length > 0){
                setDatosManos(respDatos.data)
            } else setDatosManos([])
            setIsLoading(false)
          })();
    }

    useEffect(() => {
        changeDataWeek();
        getData();
        setCounter(new Date());
    },[])

    useEffect(() => {
        let intervalId;
        intervalId = setInterval(() => {
            getData();
            setCounter(new Date());
          }, INTERVAL_TIME)
          return () => clearInterval(intervalId);
    }, [counter])

    
    const irASemanaAnterior = () => {
        changeDataWeek(-7)
        getData();
        setCounter(new Date());
    }

    const irASemanaSiguiente = () => {
        changeDataWeek(+7)
        getData();
        setCounter(new Date());
    }

    const irASemanaActual = () => {
        window.location.reload();
    }
    
    const imprimirPlanning = () => {
       printData();
    }

    const printData = () => {
        (async () => {
            const URL_DATOS = process.env.REACT_APP_API_URL + `planificaciones/printplanningsemanal?fechainicial=${semanaActual.current.fechInicial}&fechafinal=${semanaActual.current.fechaFinal}`
            //console.log(URL_DATOS)
            const respDatos = await axios(URL_DATOS);
            //console.log(respDatos.data)
            if(respDatos.data.includes(".pdf"))
                window.open(respDatos.data, "_blank")
            else alert(respDatos.data)
          })();
    }

    const actualizaDatos = async (datosPlanificacion) => {

        const anioInicio = parseInt(semanaActual.current.fechInicial.substring(0, 4));
        const mesInicio = parseInt(semanaActual.current.fechInicial.substring(5, 7));
        const diaInicio = parseInt(semanaActual.current.fechInicial.substring(8, 10));
        const anioFin = parseInt(semanaActual.current.fechaFinal.substring(0, 4));
        const mesFin = parseInt(semanaActual.current.fechaFinal.substring(5, 7));
        
        let mesNuevoPlanificacion = mesInicio
        let anioNuevoPlanificacion = anioInicio
        if(datosPlanificacion.dia < diaInicio){
            mesNuevoPlanificacion = mesFin
            anioNuevoPlanificacion = anioFin
        }
        
        const fecha = anioNuevoPlanificacion + '-' + mesNuevoPlanificacion.toString().padStart(2, 0) + '-' + datosPlanificacion.dia.toString().padStart(2, 0)//semanaActual.current.fechInicial.substring(0, 8) + datosPlanificacion.dia.toString().padStart(2, 0)

        let datos = {}
        datos["idPlanificacion"] = datosPlanificacion.idPlanificacion
        datos["idMano"] = datosPlanificacion.idMano
        datos["orden"] = datosPlanificacion.orden
        datos["dia"] = datosPlanificacion.dia
        datos["fecha"] = fecha
       

        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'planificaciones/actualizarplanningsemanal';
        
        await axios
        .put(SERVER_URL, {
            headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
            },
            datos,
        })
        .then((response) => {     
            if(response.data === true){ 
                getData();
            }              
        })
        .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
        });
    }

    const anioFin = parseInt(semanaActual.current.fechaFinal.substring(0, 4));
    //console.log(semanaActual.current)

    return (           
    <div className="col-12 col-main "> 
        <SearchBar
            titulo="Operativas"
            claseCabecera = "bg-produccion"
            buscar={false}
            imagenCabecera={"5-2-1-planificacion.png"}
        /> 
        <div ref={componentRef} id="planningsemanal" className="bg-cuerpo p-10px">
            <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
                <h6 className="texto-gestion float-left" id="title">
                Planning Semanal
                </h6>
            </div>
            <hr className="m-0 bg-gestion mb-1" />
            <div className="principal bg-white cuerpo-h-planning-semanal pr-2 overflow-auto overflow-hiddens table-responsive">

                <div className="row ml-0 mr-0 p-1 mt-4">
                    <div className="col-4"></div>
                    <div className="col-4 text-center">
                        {semanaActual.current.anio}
                        <IconButton aria-label="now" onClick={() => irASemanaActual() }>
                            <TodayIcon titleAccess="Semana actual"/>
                        </IconButton>
                    
                        <IconButton aria-label="imprimir" onClick={() => { imprimirPlanning() }}>
                            <PrintIcon titleAccess="Imprimir"/>
                        </IconButton>
                        
                        </div>
                    <div className="col-2"></div>
                    <div className="col-2"><Agenda/></div>

                    <div className="navegacionSemanalPlanning">
                        <IconButton aria-label="previous" onClick={() => irASemanaAnterior() }>
                            <NavigateBeforeIcon titleAccess="Semana anterior"/>
                        </IconButton>
                    </div>

                    {diasSemanaActual &&        
                        diasSemanaActual.map((dia, index) => (
                            <ComponenteDia key={dia.dia} dia={dia.dia} mes={dia.mes} anio={semanaActual.current.anio} indice={index} />  
                        ))
                    }

                    <div className="navegacionSemanalPlanning">
                        <IconButton aria-label="Semana siguiente" onClick={() => irASemanaSiguiente() }>
                            <NavigateNextIcon titleAccess="Semana siguiente"/>
                        </IconButton>
                    </div>

                </div>
                <hr className={`${classes.lineHR} m-0 mb-1`} />
                {!isLoading && (
                <div className="row ml-0 mr-0 p-1 mt-4">
                    <div className="navegacionSemanalPlanning"></div>
                    {diasSemanaActual &&        
                        diasSemanaActual.map((dia, index) => (
                             <ComponenteManosDia 
                                key={dia.dia} 
                                dia={dia.dia} 
                                mes={dia.mes} 
                                anio={semanaActual.current.anio} 
                                manos={datosManos.filter(mano => 
                                    (mano.diaMes === dia.dia && 
                                    mano.mes === dia.mes && 
                                    mano.anio === semanaActual.current.anio) || 
                                    (
                                        mano.diaMes === dia.dia && 
                                        mano.mes === dia.mes && 
                                        mano.anio === anioFin
                                    )
                                )} 
                                actualizaDatos={actualizaDatos}/>  
                        ))
                    }
                    <div className="navegacionSemanalPlanning"></div>
                </div>
                )}
                {isLoading && (
                    <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
                )} 
            </div>
            </div>
        </div>
    </div>   
  );  
}
export default PlanningSemanal;

const ComponenteDia = ({dia, mes, anio, indice}) => {
    //console.log(dia, mes, anio, indice)
    
    const classes = useStyles();
    //const nombresDiaSemana = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'];
    const nombresMeses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    const date = new Date();
    const numeroDiaMes = date.getDate()
    const mesActual = date.getMonth();
    const anioActual = date.getFullYear();
    let esHoy = false;
    if(numeroDiaMes === dia && mesActual === mes && parseInt(anioActual) === parseInt(anio)){
        esHoy = true
    }

    const dateStr = anio + '-' + (mes+1).toString().padStart(2, 0) + '-' + dia.toString().padStart(2, 0);
    const dateTo = new Date(dateStr);
    const day = dateTo.toLocaleDateString("es-ES", { weekday: 'long' });  

    return(
        <div className="anchoColumnaPlanningSemanal"
            >
            <div className={ `${classes.root} ${ esHoy ? classes.fondoDiaActual : ""} titulo-navbar }` } >
                <Typography className={`${classes.diaMes} mr-4`}>{dia} {nombresMeses[mes]}</Typography>
                <Typography className={`${classes.heading} ${classes.agenda} mr-4`}>{day}</Typography>
            </div>
        </div>
    )
}

const ComponenteManosDia = ({dia, mes, anio, manos, actualizaDatos}) => {  
    const drop = (e) => {
        e.preventDefault();    
        const div_id = "titulo-navbar-"+ dia;        
        const block = document.getElementById(div_id);
        const childrens = block.children 
        
        let dropIndex = Array.from(childrens).findIndex(
            (child) => child.getBoundingClientRect().bottom > e.clientY
        );
        dropIndex = dropIndex < 0 ? 0 : dropIndex;

        const manoActualizar = e.dataTransfer.getData("manoActualizar");
        const manoActualizarArray = manoActualizar.split("_");

        const idPlanificacion = parseInt(manoActualizarArray[0]);
        const idMano = parseInt(manoActualizarArray[1]);

        actualizaDatos({idPlanificacion: idPlanificacion, idMano: idMano, orden: dropIndex, dia: dia, mes: mes, anio: anio})
    }

    const dragOver1 = (e) => {
        e.preventDefault();
    }
    
    return(
        <div className="anchoColumnaPlanningSemanal"
        id={`listadoManos-${dia}`}
        onDrop={drop}
        onDragOver={dragOver1}
        >
        <div 
        className="titulo-navbar"
        id={`titulo-navbar-${dia}`}
        >
            {manos &&        
                manos
                .sort((a,b) => 
                
                (
                    a.ordenMano - b.ordenMano ||
                    a.ordenPlanificacon - b.ordenPlanificacon ||
                    a.horario.localeCompare(b.horario))
                
                )
                /*
                a.city.localeCompare(b.city)
                .sort((a,b) => (a.horario > b.horario) ? 1 : -1)
                .sort((a,b) => (a.ordenPlanificacon > b.ordenPlanificacon) ? 1 : -1)
                .sort((a,b) => (a.ordenMano > b.ordenMano) ? 1 : -1)
                */
                .map((mano, index) => (
                    <ComponenteMano key={index} mano={mano} />
                )

            )}
        </div>
        </div>
    )   
}

const ComponenteMano = ({mano}) => {
    const planificacionEliminar = useRef(-1);
    const planificacionValidar = useRef(-1);
    const [abrirDialogo, setAbrirDialogo] = useState(false)
    const [abrirDialogoValidacion, setAbrirDialogoValidacion] = useState(false)

    const classes = useStyles();
    const date = new Date();
    const numeroDiaMes = date.getDate()
    const mesActual = date.getMonth();
    const anioActual = date.getFullYear();
    let esHoy = false;
    
    if(numeroDiaMes === mano.diaMes && mesActual === mano.mes && parseInt(anioActual) === parseInt(mano.anio)){
        esHoy = true
    }

    const urlTo = "planificaciones/planificacion/" + mano.idPlanificacion + "/R"
    const numeroEmpleados = mano.empleados.length + (mano.supervisor.length > 0 ? 1 : 0)
    
    const textNumEmpleados = numeroEmpleados === 1 ? "Empleado" : "Empleados"

    const eliminarPlanificacion = (id) => {
        console.log(id)
        planificacionEliminar.current = id       
        setAbrirDialogo(true)
    }

    const validarPlanificacion = (id) => {
        console.log(id)
        planificacionValidar.current = id
        setAbrirDialogoValidacion(true)
    }

    const handleCerrarDialogoEliminar = (valor) => {
        console.log(valor)       
        setAbrirDialogo(false)
        if(valor){    
          const fetchData = async () => {  
            let user = JSON.parse(localStorage.getItem("user"));
            const AuthStr = "Bearer ".concat(user.access_token);
            const SERVER_URL = process.env.REACT_APP_API_URL + 'planificaciones';
            await axios
            .delete(SERVER_URL + "/" + planificacionEliminar.current, {
              headers: {        
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },        
            })
            .then((response) => {         
              if(!isNaN(response.data)){ 
                if(parseInt(response.data) === parseInt(planificacionEliminar.current)){
                  planificacionEliminar.current = -1 
                  window.location.reload();
                }          
              } else{
                alert(response.data);  
              }
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
            });  
          }
          fetchData();
        }      
    }
   

    const handleCerrarDialogoValidar = (valor) => {    
        console.log(valor)   
        setAbrirDialogoValidacion(false)        
        if(valor){    
          const fetchData = async () => {  
            let user = JSON.parse(localStorage.getItem("user"));
            const AuthStr = "Bearer ".concat(user.access_token);
            const SERVER_URL = process.env.REACT_APP_API_URL + 'planificaciones/validaplanificacion';
            const id = planificacionValidar.current
            console.log(id)
            await axios
            .post(SERVER_URL, {
              headers: {        
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },   
              id     
            })
            .then((response) => {
              if(!isNaN(response.data)){ 
                if(parseInt(response.data) === parseInt(planificacionValidar.current)){
                    planificacionValidar.current = -1 
                  window.location.reload();
                }          
              } else{
                alert(response.data);  
              }
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response.data); // => the response payload 
              console.log(error.response.data.message)
            });  
          }
          fetchData();
        }      
    }
    
    const handleDragOver = (event) => {
        event.stopPropagation();
    };

    const dragStart = (e) => {
        const target = e.target;
        e.dataTransfer.setData("div_id", target.id);
        e.dataTransfer.setData("manoActualizar", mano.idPlanificacion + "_" + mano.idMano);        
    };

    //console.log(mano)

    return(
        <div 
        className={ `${classes.root} ${ esHoy ? classes.fondoDiaActual : ""} }` }
        id={`mano-${mano.idPlanificacion}-${mano.idMano}`}
        >
            <Accordion className={ `${classes.accordion} ${mano.estado === "Validada" ? classes.bordevalidada : classes.bordependiente} ` }>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id1="panel1a-header"
                    className={classes.accordionSummary}
                    id={`draggable-accordion${mano.idPlanificacion}-${mano.idMano}`}
                    draggable
                    //onDrop={(event) => handleDrop(event)}
                    onDragOver={(event) => handleDragOver(event)}
                    //onDragEnter={(event) => handleDragEnter(event)}
                    onDragStart={(event) => dragStart(event)}
                    >
                    <div className={classes.cabecera2}>
                        <Typography className={classes.horario} color="textSecondary">
                            <ArrowRightIcon />{mano.horario}
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon />{mano.buque1}
                        </Typography>
                        <Typography className={classes.otrosTextos} color="textSecondary" >
                            <ArrowRightIcon />{mano.operativa}
                        </Typography>
                        <Typography className={classes.horario} color="textSecondary" gutterBottom>
                            <ArrowRightIcon />{numeroEmpleados} {textNumEmpleados}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails className="d-block" >
                    <div className={classes.pie}>
                        <Typography className={classes.horario} color="textSecondary" >
                            <ArrowRightIcon style={{ color: 'red' }}/>{mano.supervisor}
                        </Typography>

                        {mano.empleados &&        
                            mano.empleados.map((empleado, index) => (
                                <Typography  key={index} className={classes.otrosTextosDesplazado} color="textSecondary" >
                                {empleado} 
                                </Typography>
                                
                            ))
                        }

                        <Typography className={classes.otrosTextos} color="textSecondary" >
                                    <ArrowRightIcon />{mano.localizacion1}
                        </Typography>

                        {mano.clientes &&        
                            mano.clientes.map((cliente, index) => (
                                <Typography  key={index} className={classes.otrosTextos} color="textSecondary" >
                                <ArrowRightIcon />{cliente} 
                                </Typography>
                                
                            ))
                        }

                        <Typography className={classes.otrosTextos} color="textSecondary" gutterBottom>
                            <ArrowRightIcon />{mano.tipoOperativa}
                        </Typography>
                        
                        {mano.descripcion &&
                        <Typography className={classes.otrosTextos} color="textSecondary">
                            <ArrowRightIcon />{mano.descripcion}
                        </Typography>
                        }
                        
                        <Typography className={ `${classes.estado}  ${mano.estado === "Validada" ? classes.validada : classes.pendiente}` }>
                            {mano.estado}
                        </Typography>

                        <Typography className={classes.enlacePlanificacion} color="textSecondary">
                            <Link to={urlTo} target="_blank" rel="noopener noreferrer" >Ver planificacion</Link>
                        </Typography>
                        
                        { (mano.idEstado === "1") && (
                            <>
                            <Typography className={classes.enlacePlanificacion} color="textSecondary">
                            <Button variant="contained" onClick={() => validarPlanificacion(mano.idPlanificacion) } color="primary">Validar</Button>
                            </Typography>
                            </>
                        )}
                        

                        <Typography className={classes.enlacePlanificacion} color="textSecondary">
                        <Button variant="contained" onClick={() => eliminarPlanificacion(mano.idPlanificacion) } color="secondary">Eliminar</Button>
                        </Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la planificación?"} abrir={abrirDialogo} cerrar={handleCerrarDialogoEliminar}/>)}
            { abrirDialogoValidacion && (<AlertaDialogo titulo={"¿Desea validar la planificación?"} abrir={abrirDialogoValidacion} cerrar={handleCerrarDialogoValidar} textoBoton2={"Validar"}/>)}
        </div>
    )
}