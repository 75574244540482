import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },     
    stepper: {        
      display: "flex",
      padding: "0px"      
    },    
  }));

  const Pasos = ({pasos, idPasoActivo, changePasoActivo, clasesPasos = []}) => {      
    const [ pasoActivo, setPasoActivo ] = useState(idPasoActivo);      
    const classes = useStyles();    
    const steps = pasos; 
    const clases = clasesPasos

    useEffect(() => {
      setPasoActivo(idPasoActivo)  
      return () => {}
    }, [idPasoActivo])
       
    const handleStep = (step) => () => {
        changePasoActivo(step)
        setPasoActivo(step)         
    };

    const clase = ""; //clases.length > 0 ? clases[idPasoActivo] : ""

    return (    
      <div className="bg-white mb-2 pt-3 pl-3 pr-3 pb-0 m-0">
        <div className={classes.root}>
          <Stepper nonLinear activeStep={pasoActivo} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  className={clase}
                  onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>      
      </div>
      );
}
export default Pasos;