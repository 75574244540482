import React, { useState, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { StateContext } from '../../../../../context'

const propiedadesOrigen = {
    xs:5,
    required: false,
    label: "Origen",
    name: "origenescolta",
    type: "text",    
    value: ''
}

const propiedadesDestino = {
    xs:5,
    required: false,
    label: "Destino",
    name: "destinoescolta",
    type: "text",    
    value: ''
}

const FormularioOperacionGesportEscolta = () => {
    const { dispatch} = useContext(StateContext)

    const [origen, setOrigen] = useState('')
    const [destino, setDestino] = useState('')

    const handleChange = (event) => {
        if(event.target.name === 'origenescolta'){
            setOrigen(event.target.value)
        }
        if(event.target.name === 'destinoescolta'){
            setDestino(event.target.value)
        }
    }

    const handleClick = () => { 
        
        dispatch({type: "ADD_LISTADO_GESPORT", 
        payload: {
            recurso:'escolta',
            nombre: 'Escolta desde ' + origen + ' hasta ' + destino,
            cantidad: 1,
            horainicio: '',
            horafin: '',
            id:uuidv4()
        }})
        
        setOrigen('')
        setDestino('')
    }

    return (
        <>
        <Grid container  spacing={1} className="mt-2">
        <Grid item xs={propiedadesOrigen.xs} className="">
        <TextField                 
            required = {propiedadesOrigen.required}                
            id={propiedadesOrigen.name}
            name={propiedadesOrigen.name}
            disabled = {propiedadesOrigen.disabled}
            label={propiedadesOrigen.label}        
            value={origen} 
            onChange={handleChange} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField> 
        </Grid>

        <Grid item xs={propiedadesDestino.xs} className="">
        <TextField                 
            required = {propiedadesDestino.required}                
            id={propiedadesDestino.name}
            name={propiedadesDestino.name}
            disabled = {propiedadesDestino.disabled}
            label={propiedadesDestino.label}        
            value={destino} 
            onChange={handleChange} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField> 
        </Grid>

        <Grid item xs={2} className="" >
        <Button className="" variant="contained" color="primary" size="large" onClick={handleClick}>
            AÑADIR
        </Button> 
        </Grid>
        </Grid>
        </>
    )

}

export default FormularioOperacionGesportEscolta;