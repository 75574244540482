import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioOportunidad = ({setCodigo}) => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [oportunidad, setOportunidad] = useState([])     
    const [clientes, setClientes] = useState("") 

    const classDiv3 = "col-3 mt-2 mb-2" 

    const handleChangeCuenta = (cuentas) => {
      if (Array.isArray(cuentas)){
        setClientes(cuentas.map((cuenta) => cuenta.Id).join("_"))
      }
    }   
    
    useEffect(() => { 

        setCampos([
            {    
              required: true,
              label: "Nombre:",
              name: "nombre",
              type: "text",    
              disabled: false, 
              value: '',                
              xs:4
            },   
            {
              urlData: "cuentas/clientes",
              campoEtiqueta: "Nombre_Comercial",
              required: true,
              label: "Cliente",
              name: "nombreComercial",
              disabled: false,
              type: "combo",           
              multiple:true,   
              value: [],
              xs:4,
              onChangeValue: handleChangeCuenta
            },        
            {
              urlData: "contactos",
              campoEtiqueta: "Nombre",
              required: true,
              label: "Contacto",
              name: "contacto",
              disabled: false,
              type: "combo",           
              multiple:true,   
              value: [],
              xs:4
            },
            {
              urlData: "dic/diccionarios/listadousuarios",
              campoEtiqueta: "nombre",
              campoId:"id",
              required: false,
              label: "Empleado",
              name: "empleado",
              disabled: false,
              type: "combo",           
              multiple:true,   
              value: [],
              xs:4
            },
            {
              urlData: "dic/diccionarios/listadousuarios",
              campoEtiqueta: "nombre",
              required: false,
              label: "Supervisor",
              name: "supervisor",
              disabled: false,
              type: "combo",           
              multiple:false,   
              value: '',
              xs:4
            },
            {
              urlData: "dic/diccionarios/listadovisibilidades",
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Visibilidad",
              name: "visibilidad",
              type: "combo",  
              value: '',
              multiple:false,
              xs:4
            },
            {
              urlData: "dic/diccionarios/oportunidadesareas",
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Área de negocio",
              name: "areanegocio",
              disabled: false,
              type: "combo",           
              multiple:false,   
              value: '',
              xs:6
            },
            {
              urlData: "dic/diccionarios/listadofuentes",
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Fuente de información",
              name: "fuenteinformacion",
              disabled: false,
              type: "combo",           
              multiple:false,   
              value: '',
              xs:6
            },
            {
              required: false,
              label: "Fecha inicio",
              name: "fechainicio",
              type: "date",  
              value: '',
              xs:4
            },
            {
              required: false,
              label: "Fecha estimada cierre",
              name: "fechacierre",
              type: "date",  
              value: '',
              xs:4
            },
            {
              urlData: "dic/diccionarios/oportunidadesestados",
              campoEtiqueta: "Etiqueta",
              required: false,
              label: "Estado",
              name: "estado",
              disabled: false,
              type: "combo",   
              value: '',
              multiple:false,
              xs:4
            },
            {
              required: false,
              label: "Previsión de gastos",
              name: "previsiogastos",
              type: "number",              
              value: '',
              xs:4
            },
            {
              required: false,
              label: "Presupuesto asignado",
              name: "presupuesto",
              type: "switch",              
              value: 0,
              xs:4
            },
            {
              required: false,
              label: "Resultado oportunidad",
              name: "resultado",
              type: "select",              
              opciones: [{Id:1, Etiqueta: 'Perdida'}, {Id: 2, Etiqueta: 'Ganada'}], 
              value: '',
              multiple:false,
              xs:4
            },   
            {
              required: false,
              label: "Descripción",
              name: "descripcion",
              type: "textArea",
              lineas: 4,                
              value: '',
              xs:6
            },
            {
              required: false,
              label: "Notas",
              name: "notas",
              type: "textArea",
              lineas: 4,  
              value: '',                
              xs:6
            }, 
            {
              classButton: classDiv3,
              required: false,
              label: "",
              name: "ficherosadjuntos",
              type: "filesupload",   
              tabla: "mtms_oportunidades_adjuntos",
              xs:5
            },            
            {
              required: false,
              label: "",
              name: "ficherosremotos",
              type: "files", 
              ficheros: oportunidad.ficheros,    
              tabla: "mtms_oportunidades_adjuntos",
              modelo: "api\\modules\\v1\\models\\oportunidades\\OportunidadesAdjuntos",
              carpeta: "oportunidades",
              xs:4
            },            
        ])   
            
        const fetchData = async () => {              
            if (modo === "W") {
                const respOportunidad = await axios(
                    `${process.env.REACT_APP_API_URL}oportunidads/${id}`
                );    
                
                setOportunidad(respOportunidad.data)  
                setCodigo(respOportunidad.data?.Codigo)  

            }
            setDatosCargados(true);                     
        };            
        fetchData(); 
        
        return () => {
          setOportunidad([]) 
          setDatosCargados(true);
        }

    },[]);

    useEffect(() => {            
      if (typeof oportunidad !== "undefined") {        
          if(Object.keys(oportunidad).length > 0){
            setDatosCargados(false)            
            campos[0].value = oportunidad.Nombre;
            campos[1].value = oportunidad.Cuentas;
            campos[2].value = oportunidad.Contactos;
            campos[3].value = oportunidad.Empleados;
            campos[4].value = oportunidad.Supervisor;
            campos[5].value = oportunidad.Visibilidad;
            campos[6].value = oportunidad.AreaNegocio;
            campos[7].value = oportunidad.FuenteInformacion;
            campos[8].value = oportunidad.Fecha_Inicio;
            campos[9].value = oportunidad.Fecha_Cierre;
            campos[10].value = oportunidad.Estado;
            campos[11].value = oportunidad.Prevision;
            campos[12].value = oportunidad.Presupuesto;
            campos[13].value = oportunidad.Oportunidad;
            campos[14].value = oportunidad.Descripcion;
            campos[15].value = oportunidad.Notas;
            campos[17].ficheros = oportunidad.ficheros;
            if (Array.isArray(oportunidad.Cuentas)){
              setClientes(oportunidad.Cuentas.map((cuenta) => cuenta.Id).join("_"))
            } 
            setDatosCargados(true)  
          }
      }
      
      },[oportunidad])

    useEffect(() => { 
      if (typeof campos !== "undefined") {        
          if(Object.keys(campos).length > 0){
            setDatosCargados(false)
            if(clientes) campos[2].urlData = "cuentas/contactos/" + clientes
            else campos[2].urlData = "contactos" 
            setDatosCargados(true)
        }
      }
    },[clientes])

   
    if (datoscargados){
    return (          
        <FormularioEntidad 
          urlForm = "oportunidads"
          urlGoto = "/crm/oportunidades/oportunidad/"
          campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }



  }

  export default FormularioOportunidad;