import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";

class Header extends Component {

  render() {
    const { isAuthUser, user } = this.props.reducerApi;
    
    const handleLogOut = (e) => {
      this.props.logout()
      window.location.href = '/';
    }

    const fotoUsuario = user.foto ? user.foto : process.env.REACT_APP_IMAGES + 'blank-profile-picture.png'  
    const NombreEmpresa = process.env.REACT_APP_EMPRESA_NAME
    const appVersion = process.env.REACT_APP_VERSION
    console.log(appVersion)


    /*
    if (process.env.NODE_ENV === 'production') {
      console.log('Running in production');
      console.log(process.env)
    } else {
      console.log('Running in development');
      console.log(process.env)
    }
    */

    return (
      <nav className="navbar navbar-expand-lg justify-content-between p-0">
        <div className="container-fluid p-0">
          <div className="col-3 p-0">
            <h6>
            {isAuthUser ? 
            ( <Link to={"/"} className="text-light ml-2">
                <img
                  src={`${process.env.REACT_APP_IMAGES}mtms_512.jpg`}
                  width="32"
                  height="32"
                  alt={appVersion}
                  title={appVersion}
                  loading="lazy"
                  className = "imagen-header-mtms rounded"
                />
              </Link> ) :
              ( <Link to={"/login"} className="text-light ml-2">
                <img
                  src={`${process.env.REACT_APP_IMAGES}mtms_512.jpg`}
                  width="32"
                  height="32"
                  alt=""
                  loading="lazy"
                  className = "imagen-header-mtms rounded"
                />
              </Link> )
            }

              <span className="ml-2 mr-2 texto-header-mtms">{NombreEmpresa}</span>              
            </h6>
          </div>
          <div className="col-7 pl-0">
            <h6 className="text-left titulo-navbar">
              
            </h6>
          </div>
          <div className="col-2 pr-5">
            {isAuthUser ? (
              <div className="float-right mr-0">
                <ul className="navbar-nav mr-1">
                  <li className="nav-item dropdown">
                    <Link
                      to={"/"}
                      className="nav-link dropdown-toggle pt-1 pb-1"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <span className="mr-3">
                      {user.username}
                      </span>
                      <img
                        src={fotoUsuario}
                        width="32"
                        height="32"
                        alt=""
                        title={appVersion}
                        loading="lazy"
                        className="rounded-circle"
                      />
                    </Link>
                    <div
                      className="dropdown-menu "
                      aria-labelledby="navbarDropdown"
                    >
                      <Link className="dropdown-item" to="/my-account">
                        <Button color="inherit">Mi Perfil</Button>
                      </Link>
                      
                      <Link className="dropdown-item" to="/">
                        <Button                        
                        color="inherit"
                        onClick={(e) => handleLogOut(e)}
                        >
                          Cerrar sesión
                        </Button>
                      </Link>  
                    </div>                   
                  </li>   
                </ul>
              </div>
            ) : (
              <h6 className="float-right">
                <Link to="/denuncias" className="">
                  <span className="ml-2 mr-2">DENUNCIAS</span>
                </Link>
              </h6>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(({ reducerApi }) => ({ reducerApi }), {
  logout,
})(Header);
