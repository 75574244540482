import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from "axios";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import Avatar from '@material-ui/core/Avatar';

import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import FileCopyIcon from '@material-ui/icons/FileCopy';

import FormularioCotizacionMano from "./FormularioCotizacionMano"
import Box from "@material-ui/core/Box";
import {useCotizacion} from './cotizacion-context'
import { useParams } from "react-router-dom";
import RecursosManoDetalle from '../../../../../components/Common/RecursosManoDetalle'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import AlertaDialogo from "../../../../../components/Common/AlertaDialogo";

function SimpleDialog(props) {
  const { onClose, selectedValue, open, onClick } = props;
  
  const handleClose = () => {
    onClose(selectedValue);
  };

  const eliminarMano = (value) => {
    onClick({'accion' : 'eliminar', 'valor' : value})
    onClose(value);
  };
  const clonarMano = (value) => {
    onClick({'accion' : 'clonar', 'valor' : value})
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <List>
        <ListItem autoFocus button onClick={() => clonarMano(selectedValue)}>
          <ListItemAvatar>
            <Avatar>
              <FileCopyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Clonar" />
        </ListItem>

        <ListItem autoFocus button onClick={() => eliminarMano(selectedValue)}>
          <ListItemAvatar>
            <Avatar>
              <DeleteIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Eliminar" />
        </ListItem>
      </List>
    </Dialog>
  );
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  subroot: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
      <div {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
  );
  
}

const calculaCosteTotal = (datosJornadas) => { 
  let costeTotalMano = 0;  
  let ajusteCPE = 0;
  if(datosJornadas.length > 0 ){       
    datosJornadas.forEach(element => { 
      let coste = parseFloat(element.elementos.esPresupuesto === "1" ? element.elementos.Importe : element.elementos.Coste);
      let cantidad = parseFloat(element.elementos.cantidad)
      costeTotalMano +=  coste * cantidad;     
      ajusteCPE += element.elementos.CategoriaPadre === "Mano de obra externa" ? parseFloat(element.elementos.Coste)*parseFloat(element.elementos.cantidad)*4/100 : 0           
    });  
  }
  const options2 = { style: 'currency', currency: 'EUR' };
  const numberFormat2 = new Intl.NumberFormat('es-ES', options2);
  costeTotalMano = numberFormat2.format(costeTotalMano+ajusteCPE)
  return costeTotalMano;
}

export default function CustomizedTabs() {
  let { id, modo } = useParams();
  const classes = useStyles();
  const [value, setValue] = useState(NaN);
  const [tabList, setTabList] = useState([]);
  const { manoActiva, setManoActiva } = useCotizacion();
  const {cotizacion, manosCotizacion, setManosCotizacion, datosJornadas, setDatosJornadas, costesDirectos, setCostesDirectos} = useCotizacion();
  //const [manos, setManos] = useState([])
  const [openAlert, setOpenAlert] = useState(false);
  const [abrirDialogo, setAbrirDialogo] = useState(false)
  const manoSeleccionada = useRef(1);

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const handleTabClick = () => { 
    if(modo !== "W") {
      alert("Debe editar la cotización")
      return 
    }    
    let id = tabList.length > 0 ? tabList[tabList.length-1].id + 1 : 1;
    
    setTabList([...tabList, { key: id, id: id, label: "Mano " +  id}]);   
    setValue(id);
    setManoActiva(id);
  }

  useEffect(() => {  
    const fetchData = () => {
      let idCotizacion = id > 0 ? id : cotizacion   
      
      if(idCotizacion > 0)
      {        
        let manosDistintas = [];
        if(manosCotizacion.length > 0){
          manosCotizacion.forEach(element => {            
            manosDistintas.push(element.Mano)
          })
          
          let manosUnicas = [...new Set(manosDistintas)];
          let tabListArray = []     
         
          manosUnicas.forEach(element => {
            tabListArray.push({ key: element, id: element, label: "Mano " +  element}) 
          })      
          
          setTabList(tabListArray); 
          setValue(manoActiva ? manoActiva : manosUnicas[0])
          setManoActiva(manoActiva ? manoActiva : manosUnicas[0]);
        }
      }
      
    };
    fetchData();

    return () => {
      setTabList([])
    }

  },[id, manosCotizacion, cotizacion])
     
  const handleChange = (event, newValue) => {  
    setValue(newValue);
  };  

  const optionsTab = e => {

    e.stopPropagation();
    if(modo !== "W") {
      alert("Debe editar la cotización")
      return 
    }   
   

    let tabId = parseInt(e.target.id, e);
    
    
    if(!isNaN(tabId)){
      manoSeleccionada.current = tabId
      setOpen(true)
    }
    
  };

  const handleClonarMano = async (valor) => {
    let manoaClonar = manosCotizacion.filter(mano => mano.Mano === valor.valor)
        
    if(manoaClonar.length > 0){
      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = process.env.REACT_APP_API_URL + 'cotizacionesmanos/clonar'; //+manoaClonar[0].Id;
      let datos = [];
      datos.idMano =  manoaClonar[0].Id

      let config = {
        headers: {'Authorization': AuthStr},
        params: {
          id: manoaClonar[0].Id
        },
      }
      
      const res = await axios.get(SERVER_URL, config)
      
      
      if(!isNaN(res.data)){
        window.location.reload();
      }
    }
  }

  const handleCerrarDialogo = (valor) => {  
    
    setAbrirDialogo(false)
    
    if(valor){    
      const fetchData = async () => {          
      
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + 'cotizacionesmanos';
    
        let manoaEliminar = manosCotizacion.filter(mano => mano.Mano === manoSeleccionada.current)
        let manoNoaEliminar = manosCotizacion.filter(mano => mano.Mano !== manoSeleccionada.current)
        let datosJornadasNoaEliminar = datosJornadas.filter(datoJornada => datoJornada.elementos.mano !== manoSeleccionada.current)
        
        if(manoaEliminar.length>0){  
          axios
          .delete(SERVER_URL + "/" + manoaEliminar[0].Id, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },        
          })
          .then((response) => { 
            if(!isNaN(response.data)){   
              setManosCotizacion(manoNoaEliminar)
              setDatosJornadas(datosJornadasNoaEliminar)
              setOpenAlert(true);  
            }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
          });  
        }  else {
          setManosCotizacion(manoNoaEliminar)
          setDatosJornadas(datosJornadasNoaEliminar)
          setOpenAlert(true);  
        }
      }
      fetchData();
    }      
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }  
    setOpenAlert(false);
  };  

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleClick = (value) => {
    
    if(value.accion === "eliminar"){
      setAbrirDialogo(true)
    }

    if(value.accion === "clonar"){
      handleClonarMano(value)
    }
  }
    
  if(isNaN(costesDirectos)){setCostesDirectos(0.0)}

  let costesTotales = 0
  if(datosJornadas.length > 0)
    costesTotales = calculaCosteTotal(datosJornadas)

  let manosDistintas = [];
  if(tabList.length > 0){
      tabList.forEach(element => {            
        manosDistintas.push(element.id)
      })
  }
  const manosUnicas = [...new Set(manosDistintas)];
  
  return (
    <div className={classes.root}>     
      <div className={classes.subroot}>
      <div className="costeTotalManos">        
        <span className="tituloRecursos">{"Coste total"}</span>        
        <span className="costeTotalRecursos"> {costesTotales}</span>
      </div>
        <AntTabs 
          value={value} 
          onChange={handleChange} 
          variant="scrollable"
          scrollButtons="auto"
        >
          
          {tabList.map(tab => (
              <Tab
                key={tab.id.toString()}
                value={tab.id}
                label={tab.label}
                icon={<MenuIcon className={'MenuManos'} id={tab.id} onClick={optionsTab} />}
                className="mytab"
              />
              
          ))}

          
          <AntTab value={manosUnicas[manosUnicas.length-1]+1}  onClick={handleTabClick} label="+ Añadir mano" />
          <AntTab value={manosUnicas[manosUnicas.length-1]+2} label="Ver detalle" />    
        </AntTabs>
        
        {tabList.map(tab => (
          <TabPanel key={tab.id} value={value} index={tab.id}>
            <FormularioCotizacionMano index={tab.id} />
          </TabPanel>
        ))}    

        <TabPanel value={value} index={manosUnicas[manosUnicas.length-1]+2}>
            <RecursosManoDetalle
              manos={manosCotizacion}               
              datosJornadas={datosJornadas}  
              urlGenerarFicha={"cotizaciones/generapdfdetalle/"}             
            />  
        </TabPanel>
        <Typography className={classes.padding} />
        <SimpleDialog selectedValue={manoSeleccionada.current} open={open} onClose={handleClose} onClick={handleClick}/>
          
        {openAlert && 
              <AlertaMensaje 
                mensaje={"¡Mano eliminada correctamente!"} 
                isOpen={openAlert} 
                tipoMensaje="success"
                cerrar={handleCloseAlert}
              />
        }  
        { abrirDialogo && (<AlertaDialogo titulo={"¿Desea eliminar la mano?"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
      </div> 
    </div>
  );
}