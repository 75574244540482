import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'


const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioAccidente = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [accidente, setAccidente] = useState([]) 


    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([  
            {
                urlData: "dic/diccionarios/tipologiaspartesaccidenterrhh",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Tipologia:",
                name: "tipologia",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            }, 
            {
                urlData: "dic/diccionarios/listadoempleadosactivos",
                campoEtiqueta: "nombre",
                required: true,
                label: "Trabajador:",
                name: "trabajador",
                disabled: false,
                type: "combo",
                value: "",              
                xs:8
            }, 
            {
                urlData: "dic/diccionarios/tipotrabajadorrrhh",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Personal:",
                name: "personal",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            {
                urlData: "dic/diccionarios/lugaraccidenterrhh",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Lugar del accidente:",
                name: "lugarAccidente",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },        
            {
                required: true,
                label: "Fecha Accidente:",
                name: "fechaAccidente",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:4
            },
            {
                urlData: "dic/diccionarios/gradolesionrrhh",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Grado de la lesión:",
                name: "gradoLesion",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            {
                required: true,
                label: "Empresa:",
                name: "empresa",
                disabled: false,
                type: "text",           
                multiple:false,   
                value: "",
                xs:4
            },
            {
                required: true,
                label: "Nombre testigo:",
                name: "nombreTestigo",
                disabled: false,
                type: "text",           
                multiple:false,   
                value: "",
                xs:4
            },
            {
                required: true,
                label: "Baja médica:",
                name: "bajaMedica",
                disabled: false,
                type: "switch",           
                multiple:false,   
                value: "",
                xs:4
            },
            /*
            {
                urlData: "rrhhsspartesenfermedads",
                campoEtiqueta: "Codigo",
                required: true,
                label: "Parte de enfermedad:",
                name: "parteEnfermedad",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            */
            
            {
                required: true,
                label: "Evaluación riesgo puesto:",
                name: "evaluacionRiesgo",
                disabled: false,
                type: "switch",           
                multiple:false,   
                value: "",
                xs:4
            },
            
            
            {
                required: true,
                label: "Testigos:",
                name: "testigos",
                disabled: false,
                type: "switch",           
                multiple:false,   
                value: "",
                xs:4
            },
            {
                required: true,
                label: "Descripción accidente:",
                name: "descripcionAccidente",
                type: "textArea",
                lineas: 4,    
                disabled: false, 
                value: "",                
                xs:6
            },
            {
                required: true,
                label: "Descripción de la lesión:",
                name: "descripcionLesion",
                type: "textArea",
                lineas: 4,    
                disabled: false, 
                value: "",                
                xs:6
            },
            {
                required: true,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",
                lineas: 4,    
                disabled: false, 
                value: "",                
                xs:12
            },  
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_rrhh_partes_accidente_adjuntos",
                xs:12
              },            
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: accidente.ficheros,    
                tabla: "mtms_rrhh_partes_accidente_adjuntos",
                modelo: "api\\modules\\v1\\models\\rrhh\\RRHHPartesAccidenteAdjuntos",
                carpeta: "rrhh//partes-accidente",
                xs:12
              },                
    ])


    const fetchData = async () => { 
        if (modo === "W") {
            const respaccidente = await axios(
                `${process.env.REACT_APP_API_URL}rrhhsspartesaccidentes/${id}`
            );                
            setAccidente(respaccidente.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[]);

useEffect(() => {            
    if (typeof accidente !== "undefined") {        
      if(Object.keys(accidente).length > 0){     
               
        setDatosCargados(false)
        campos[0].value = accidente.Tipologia;
        campos[1].value = accidente.Empleado;
        campos[2].value = accidente.TipoTrabajador;
        campos[3].value = accidente.LugarAccidente;
        campos[4].value = accidente.FechaAccidente;
        campos[5].value = accidente.GradoLesion;
        campos[6].value = accidente.Empresa;
        campos[7].value = accidente.NombreTestigo;
        campos[8].value = accidente.BajaMedica === "Sí" ? 1 : 0;
        //campos[6].value = accidente.ParteEnfermedad;
        
        campos[9].value = accidente.EvaluacionRiesgoPuesto === "Sí" ? 1 : 0;
        
        
        campos[10].value = accidente.Testigo === "Sí" ? 1 : 0;
        campos[11].value = accidente.DescripcionAccidente;
        campos[12].value = accidente.DescripcionLesion;
        campos[13].value = accidente.Observaciones;            
        campos[15].ficheros = accidente.ficheros;

        setDatosCargados(true)  
      }
  }
  },[accidente])

if (datoscargados){
    return (
        
        <FormularioEntidad 
        urlForm = "rrhhsspartesaccidentes"
        urlGoto = "/rrhh/talento/accidente/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioAccidente;