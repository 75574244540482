import React from "react";
import Nav from "../../components/Common/Header";
import Menuizq from "../../components/Common/Menuizq";
import MenuDerCotizaciones from "../../components/Common/MenuDerCotizaciones";
import {CotizacionProvider} from '../views/Main/Comercial/Ventas/cotizacion-context'
import MaintenanceModeComponent from "../../components/Common/MaintenanceModeComponent";

export default ({ children }) => {     
  const maintenanceMode = children.props.children[0]?.key === 'true'

  return (
    <>
    {maintenanceMode && (
      <MaintenanceModeComponent />
    )}
    {!maintenanceMode && (
      <CotizacionProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children}
        <MenuDerCotizaciones />
      </div>
    </div>
    </CotizacionProvider>
    )}
    </>
  );
};

/*
return (
    <CotizacionProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children }
        <MenuDerCotizaciones />
      </div>
      
    </div>
    </CotizacionProvider>
  );
  */
