import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import FormFieldTextUncontrolled from "./FormFieldTextUncontrolled";
import AlertaMensaje from './AlertaMensaje'
import TablaPresupuestoCotizacionProductos from "./TablaPresupuestoCotizacionProductos";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },  
}));

const FormularioPresupuestoCotizacionProductos = ({urlGetData, urlPostData}) => { 
    const { id, modo } = useParams();
    const methods = useForm();  
    const classes = useStyles();;
    const { handleSubmit } = useForm(); 
    const [openAlert, setOpenAlert] = useState({estado: false, mensaje: "", tipo:"success"});
    const [listadoProductos, setListadoProductos] = useState([])
    const [isLoading, setIsLoading] = useState(false);   

    const [campoCantidad, setCampoCantidad] = useState({
      required: true,
      label: "Importe",      
      name: "importeOperativa",
      type: "text",  
      value: '',
      xs:12
    })

    const [campoProducto, setCampoProducto] = useState({
      required: true,
      label: "Descripción",      
      name: "textoOperativa",
      type: "text",  
      value: '',
      xs:12
    })  

    const recargarDatos = () => {
      if(id){
        (async () => {   
          setIsLoading(true)
          const respPedidoCliente = await axios(process.env.REACT_APP_API_URL + `${urlGetData}/${id}`);   
          
          if(respPedidoCliente.data.operativasPresupuesto.length > 0 ){          
            setListadoProductos(respPedidoCliente.data.operativasPresupuesto)
          } 
          setIsLoading(false)
        })();
      }else{
        setOpenAlert({estado: true, mensaje: "Debe estar en modo edición para poder añadir productos", tipo:"error"})
      }
    }

    useEffect(() => {        
      recargarDatos();
      return () => {
        setListadoProductos([])
        setIsLoading(false)
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSubmitPost = async () => {       

      let producto = {
        id:uuidv4(), 
        TextoOperativa: campoProducto.value,
        ImporteOperativa: campoCantidad.value.replace(',', '.'),                 
      }

      let datos = {};
      datos.productoInsertar = producto
      datos.idEntidad = id;

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}`
      
      if (modo==="W") {
        await axios
        .post(`${SERVER_URL}`, {
          headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => { 
          if(!isNaN(response.data)){
            if(parseInt(response.data)){ 
              producto.id = response.data
              setListadoProductos([...listadoProductos, producto])
              setCampoCantidad({...campoCantidad, value: ""})   
              setCampoProducto({...campoProducto, value: ""})
              setOpenAlert({estado: true, mensaje: "¡Producto añadido correctamente!", tipo:"success"})
            }
          }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
      });
      }else{setOpenAlert({estado: true, mensaje: "Para añadir un producto debe estar en modo edición.", tipo:"warning"})}

    }  

    const handleChangeProducto = (valor) => { 
      setCampoProducto({...campoProducto, value: valor})
    }

    const handleChangeCantidad = (valor) => { 
      setCampoCantidad({...campoCantidad, value: valor})
    }  

    const handleCloseAlert = (event, reason) => {  
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert({estado: false, mensaje: "", tipo:"success"});
    };

    const handleRemoveElement = async  (element) =>{   

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}`
      if (modo==="W") {      
      await axios
        .delete(`${SERVER_URL}/${element.id}`, {
                headers: {        
                  "Content-Type": "application/json",
                  Authorization: AuthStr,
                },                
        })
        .then((response) => {                  
          if(!isNaN(response.data)){
            if(parseInt(response.data) === parseInt(element.id)){   
              const elementosNoEliminar = listadoProductos.filter(el => el.id !== element.id)                      
              setOpenAlert({estado: true, mensaje: "¡Producto eliminado correctamente!", tipo:"success"})                      
              setListadoProductos(elementosNoEliminar)
            }
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
        });
      }else{setOpenAlert({estado: true, mensaje: "Para eliminar un producto debe estar en modo edición.", tipo:"warning"})}

      
    }  

    const handleSaveData = async (producto) => {
      
      let datos = {};
      datos.productoActualizar = producto

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}/${producto.id}`
      
      if (modo==="W") {
        await axios
        .put(`${SERVER_URL}`, {
          headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {
          if(Array.isArray(response.data))
          {
            setListadoProductos(response.data)
            setOpenAlert({estado: true, mensaje: "¡Producto modificado correctamente!", tipo:"success"})
          }
          
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
      });
      }else{setOpenAlert({estado: true, mensaje: "Para añadir un producto debe estar en modo edición.", tipo:"warning"})}

      
    }  


    if (!isLoading){
    return (    
    <>
    {/* FORMULARIO PEDIDO PRODUCTOS*/}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-presupuesto-cotizacion-producto'
            onSubmit={handleSubmit((data) => {
            handleSubmitPost(data);
            })}
            className=""
            > 
            <div className="row">
              <div className="col-7 pr-1">
              <FormFieldTextUncontrolled propiedades={campoProducto} onChangeValue={handleChangeProducto} /> 
              </div>
              <div className="col-3 pr-1">
              <FormFieldTextUncontrolled propiedades={campoCantidad} onChangeValue={handleChangeCantidad} /> 
              </div>
              <div className="col-2">
              <button 
                      className="w-100 float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation"                      
                      disabled={modo === "W" && id ? false : true}
                      >
                          AÑADIR
                      </button>  
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-12">
                <TablaPresupuestoCotizacionProductos listadoProductos={listadoProductos} ListadoProductosFinal={handleRemoveElement} actualizarDatos={handleSaveData}/>
              </div>
            </div>

          </form>
        </FormProvider>  
        {openAlert.estado && 
          <AlertaMensaje 
            mensaje={openAlert.mensaje} 
            isOpen={openAlert.estado} 
            tipoMensaje={openAlert.tipo}
            cerrar={handleCloseAlert}
          />
        }
    </div>  
    </>  
    );
    }
    else {
      return (              
          <div className={classes.linear}>
              <CircularProgress />
          </div>
      );
    }
}
export default FormularioPresupuestoCotizacionProductos; 