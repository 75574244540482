import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioActividad = ({setCodigo}) => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [actividad, setActividad] = useState([]) 
    const [clientes, setClientes] = useState("") 
    const [otros, setOtros] = useState("")
    
    const classDiv3 = "col-3 mt-2 mb-2"

    const handleChangeCuenta = (cuentas) => {
      if (Array.isArray(cuentas)){
        setClientes(cuentas.map((cuenta) => cuenta.Id).join("_"))
      }
    }   

    

    useEffect(() => { 

        setCampos([ 
            {
                urlData: "dic/diccionarios/listadoactividadestipo",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Tipo",
                name: "tipo",
                type: "combo",  
                value: '',
                multiple:false,
                xs:4,
                onChangeValue: handleChangeTipo,
            }, 
            {    
              required: false,
              label: "Tipo:",
              name: "tipoOtros",
              type: "text",    
              disabled: true, 
              value: '',                
              xs:12,
              hidden: true
            },           
            {    
              required: true,
              label: "Nombre:",
              name: "nombre",
              type: "text",    
              disabled: false, 
              value: '',                
              xs:4
            },
            {
                urlData: "dic/diccionarios/listadovisibilidades",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Visibilidad",
                name: "visibilidad",
                type: "combo",  
                value: '',
                multiple:false,
                xs:4
            },  
            {
                urlData: "cuentas/clientes",
                campoEtiqueta: "Nombre_Comercial",
                required: false,
                label: "Cliente",
                name: "nombreComercial",
                disabled: false,
                type: "combo",           
                multiple:true,   
                value: [],
                xs:4,
                onChangeValue: handleChangeCuenta
            }, 
            {
                urlData: "contactos",
                campoEtiqueta: "Nombre",
                required: false,
                label: "Contacto",
                name: "contacto",
                disabled: false,
                type: "combo",           
                multiple:true,   
                value: [],
                xs:4
            },
            {
                urlData: "oportunidads/listadooportunidades",
                campoEtiqueta: "Codigo",
                required: false,
                label: "Oportunidad",
                name: "oportunidad",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:2,
            },
            {
              urlData: "proyectos/listadoproyectos",
              campoEtiqueta: "Codigo",
              required: false,
              label: "Proyecto",
              name: "proyecto",
              disabled: false,
              type: "combo",           
              multiple:false,   
              value: '',
              xs:2,
          },
            {
              urlData: "dic/diccionarios/listadousuarios",
              campoEtiqueta: "nombre",
              campoId:"id",
              required: false,
              label: "Empleado",
              name: "empleado",
              disabled: false,
              type: "combo",           
              multiple:true,   
              value: [],
              xs:4
            },
            {
              urlData: "dic/diccionarios/listadousuarios",
              campoEtiqueta: "nombre",
              required: false,
              label: "Supervisor",
              name: "supervisor",
              disabled: false,
              type: "combo",           
              multiple:false,   
              value: '',
              xs:4
            }, 
            {
                urlData: "actividads/estadosactividad",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Resultado actividad",
                name: "resultado",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:4
            },            
            {
              required: false,
              label: "Fecha inicio",
              name: "fechainicio",
              type: "date",  
              value: '',
              xs:3
            },
            {
                required: false,
                label: "Hora inicio",
                name: "horainicio",
                type: "time",  
                value: '',
                xs:3
            },
            {
              required: false,
              label: "Fecha estimada cierre",
              name: "fechacierre",
              type: "date",  
              value: '',
              xs:3
            },
            {
                required: false,
                label: "Hora cierre",
                name: "horacierre",
                type: "time",  
                value: '',
                xs:3
            },  
            {
              required: false,
              label: "Descripción",
              name: "descripcion",
              type: "textArea",
              lineas: 4,                
              value: '',
              xs:6
            },
            {
              required: false,
              label: "Notas",
              name: "notas",
              type: "textArea",
              lineas: 4,  
              value: '',                
              xs:6
            }, 
            {
              classButton: classDiv3,
              required: false,
              label: "",
              name: "ficherosadjuntos",
              type: "filesupload",   
              tabla: "mtms_actividades_adjuntos",
              xs:5
            },            
            {
              required: false,
              label: "",
              name: "ficherosremotos",
              type: "files", 
              ficheros: actividad.ficheros,    
              tabla: "mtms_actividades_adjuntos",
              modelo: "api\\modules\\v1\\models\\actividades\\ActividadesAdjuntos",
              carpeta: "actividades",
              xs:4
            },            
        ])   
            
        const fetchData = async () => { 
            if (modo === "W") {
                const respActividad = await axios(
                    `${process.env.REACT_APP_API_URL}actividads/${id}`
                );                
                setActividad(respActividad.data)  
                setCodigo(respActividad.data.Codigo)    
            }
            setDatosCargados(true);
            /*
            else{   
                setOportunidad()
            }  
            */          
        };    
        fetchData();  
        
        return () => {
          setActividad([]) 
          setDatosCargados(true);
        }
    },[]);
    
    useEffect(() => {            
        if (typeof actividad !== "undefined") {        
          if(Object.keys(actividad).length > 0){           
            //console.log(actividad)   
            setDatosCargados(false)
            campos[0].value = actividad.Tipo;
            setOtros(actividad.Tipo.Etiqueta)            
            if(actividad.TipoOtros){
              console.log(actividad.TipoOtros)
              campos[1].required = true;
              campos[1].disabled = false;
              campos[1].value = actividad.TipoOtros;
              campos[1].hidden = false;
            }else{
              campos[1].required = false;
              campos[1].disabled = true;
              campos[1].value = "";
              campos[1].hidden = true
            }         
            campos[2].value = actividad.Nombre;
            campos[3].value = actividad.Visibilidad;
            campos[4].value = actividad.Cuentas;
            campos[5].value = actividad.Contactos;
            campos[6].value = actividad.Oportunidad;
            campos[7].value = actividad.Proyecto;

            campos[8].value = actividad.Empleados;            
            campos[9].value = actividad.Supervisor;
            campos[10].value = actividad.Estado;
            campos[11].value = actividad.Fecha_Inicio;
            campos[12].value = actividad.Hora_Inicio;
            campos[13].value = actividad.Fecha_Fin;
            campos[14].value = actividad.Hora_Fin;
            campos[15].value = actividad.Descripcion;
            campos[16].value = actividad.Notas;            
            campos[18].ficheros = actividad.ficheros;   
            if (Array.isArray(actividad.Cuentas)){
              setClientes(actividad.Cuentas.map((cuenta) => cuenta.Id).join("_"))
            }          
            setDatosCargados(true)  
          }
      }
    },[actividad])

    useEffect(() => { 
        if (typeof campos !== "undefined") {        
            if(Object.keys(campos).length > 0){
              setDatosCargados(false)
              if(clientes) campos[5].urlData = "cuentas/contactos/" + clientes
              else campos[5].urlData = "contactos" 
              setDatosCargados(true)
          }
        }
    },[clientes])

    const handleChangeTipo = (valor) => {
      if(valor){
        setOtros(valor.Etiqueta)      
      }
    }
    
    if (typeof campos !== "undefined") {        
      if(Object.keys(campos).length > 0){
        if(otros === "Otros"){
          campos[1].required = true;
          campos[1].disabled = false;
          campos[1].hidden = false
        }else{
          campos[1].required = false;
          campos[1].disabled = true;
          campos[1].value = "";
          campos[1].hidden = true
        }          
      }
    }

    if (datoscargados){
        return (          
            <FormularioEntidad 
              urlForm = "actividads"
              urlGoto = "/crm/actividades/actividad/"
              campos={campos}
            />        
        )
        }
        else {
        return (              
            <div className={classes.linear}>
                <CircularProgress />
            </div>
        );
        }

}
export default FormularioActividad;