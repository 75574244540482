import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'
import TableAcciones from "../../../../../components/Common/TableAcciones";
import MigasDePan from "../../../../../components/Common/MigasDePan";
import SearchBar from "../../../../../components/Common/SearchBar";

const FichaLocalizacion = () => {
  let { id } = useParams();

  const [localizacion, setLocalizacion] = useState([])  
  const [campos, setCampos] = useState([]) 

  useEffect(() => {        
    const fetchData = async () => {
      const respLocalizacion = await axios(`${process.env.REACT_APP_API_URL}localizacions/${id}`);    
      setLocalizacion(respLocalizacion.data)  
    };
    fetchData();  
  }, [id]);

  useEffect(() => {      
    if(Object.keys(localizacion).length > 0){  
      setCampos(ActualizaValoresCampos(localizacion))
    }
  },[localizacion]) 

  const botoneraAcciones = [
    {
      etiqueta: "Editar",
      url: `/crm/localizaciones/${id}/W`,
      activo: true,
    },
    {
      etiqueta: "Cancelar",
      url: '/crm/localizaciones',
      activo: true,
    } 
  ];

  const urlsMigasdepan = [
    {titulo: "CRM", colorTexto: "texto-comercial", urlTo: "/crm/localizaciones"},
    {titulo: "Localizaciones", colorTexto: "texto-comercial",urlTo:""},
  ]

  return(
  <>
    <div className="col-8 col-main-home ">
      <SearchBar
        titulo="Localización"
        claseCabecera = "bg-comercial"
        buscar={false}
        imagenCabecera={"4-1-3-actividades.png"}
      />
      <div className="bg-cuerpo p-10px">
        <div className="bg-white p-10px">
          <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan}/>
          <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
            <h6 className="texto-comercial" id="title">
            </h6>
            <FichaEntidad
              campos = {campos}
              urlClick = {`/crm/localizaciones/${id}/W`}
            /> 
          </div>
        </div>
        <TableAcciones
          filaSeleccionada={-1}
          botonaccion={botoneraAcciones}
        />
      </div>
    </div>
  </>
  )
}
export default FichaLocalizacion;

function ActualizaValoresCampos(localizacion){
  const classText= "Texto14"

  return ([
    {
      classsLabel: "",
      label: "Código:",
      value:localizacion.Codigo ,
      classText: classText,
      xs:3
    },
    {
      classsLabel: "",
      label: "Nombre:",
      value: localizacion.Etiqueta,
      classText: classText,
      xs:6
    },    
    {
      classsLabel: "",
      label: "Estado:",
      value: localizacion.Activo === 1 ? "Activo" : "Inactivo",
      classText: classText,
      xs:3
    }
  ])
}