import React, { useState, useEffect, useContext, useRef  } from 'react';
import axios from "axios";

import { makeStyles  } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import { StateContext } from '../../../../../context'
import FormularioOperacionMano from "./FormularioOperacionMano"
import DetalleOperacionMano from "./DetalleOperacionMano"
import AlertaDialogo from '../../../../../components/Common/AlertaDialogo';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function JornadasOperacion() {
  const classes = useStyles();
  const [tipoFormulario, setTipoFormulario] = useState('cpe')
  const [verResumen, setVerResumen] = useState(false)
  const [abrirDialogo, setAbrirDialogo] = useState(false)

  const { state } = useContext(StateContext)
  const { operacion } = state
  
  const importarProductos = useRef(-1);

  const handleRadioChange = (e) => {
    setVerResumen(false)
    setTipoFormulario(e.target.value)
  }  

  useEffect(() => {
    if(operacion && typeof(operacion) !== 'undefined' && 
       typeof(operacion.elementoActualizar) !== 'undefined' && 
      operacion.elementoActualizar !== null) {
        setVerResumen(false)
        setTipoFormulario(operacion.elementoActualizar.tipo)
    }
  }, [operacion])

  const carpetaPoseidon =  operacion?.carpetaPoseidon

  const ImportarPoseidon = () => {
    let datos={}
    datos["idOperacion"] = operacion.Id
    datos["carpeta"] = operacion.Codigo 
    datos["planificacion"] = operacion.Fk_Planificacion
    fecthDataActualizarDatos(datos);
  }

  const fecthDataActualizarDatos = async (datos) => {
    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = process.env.REACT_APP_API_URL + 'operaciones/importarproductosposeidon';

    await axios
    .put(SERVER_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthStr,
      },
      datos,
    })
    .then((response) => { 
      if(response.data === datos.carpeta)  {
        setTimeout(function () {window.location.reload();}, 500)
      }
    })
    .catch((error) => {
      console.log("error " + error);
      console.log(error.response.data); // => the response payload 
      console.log(error.response.data.message)
    });
  }

  const handleCerrarDialogo = (valor) => {       
    setAbrirDialogo(false)
    if(valor){    
      ImportarPoseidon();      
    }  
  }

  const ImportarProductosPoseidon = (carpeta) => {
    importarProductos.current = carpeta       
    setAbrirDialogo(true)
  }

  

  return (
  <>
    <div className="d-flex flex-column align-items-end">
      <FormControl component="fieldset" className="">
      
        <RadioGroup row aria-label="position" name="position" value={tipoFormulario} onChange={(e) => handleRadioChange(e)}>

        {carpetaPoseidon && (
          <Button variant="contained" color="primary" size="small" style={{marginBottom: 9, marginRight: 10}} className={classes.margin}
            onClick={() => { ImportarProductosPoseidon(carpetaPoseidon) }}
          > Importar Poseidón
          </Button>
        )}

          <FormControlLabel value="cpe" control={<Radio color="primary" />} label="CPE" />
          <FormControlLabel value="gesport" control={<Radio color="primary" />} label="Gesport" />
          <FormControlLabel value="proveedor" control={<Radio color="primary" />} label="Proveedor" />
                
          <Button size="small" style={{marginBottom: 9}} className={classes.margin}
            onClick={() => { setTipoFormulario(null); setVerResumen(true) }}
          > Ver detalle 
          </Button>

        </RadioGroup>
      </FormControl>
      { abrirDialogo && (<AlertaDialogo titulo={"¿Desea importar los movimientos desde Poseidón?"} texto={"Se actualizarán los clientes. Y se perderán todos los movimientos actuales."} textoBoton2={"Importar"} abrir={abrirDialogo} cerrar={handleCerrarDialogo}/>)}
    </div>

    <div className="">
      { !verResumen && <FormularioOperacionMano index={1} tipoForm={tipoFormulario} /> }
      { verResumen && <DetalleOperacionMano /> }
    </div>
  </>
  );
}