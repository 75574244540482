import React,{useState, useEffect}  from "react";
import axios from "axios";
import { useForm } from "react-hook-form";

import { connect } from "react-redux";
import { logout } from "../../../../actions/auth";

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@material-ui/core/Grid';
import LinearProgress from "@material-ui/core/LinearProgress";
import AlertaMensaje from "../../../../components/Common/AlertaMensaje";

const useStyles = makeStyles((theme) => ({
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  
  }));

const FormularioCambioPassword = ({reducerApi, logout}) => {
  const { user } = reducerApi;
    const [grabandoFormulario, setGrabandoFormulario] = useState(false);
    const { control, handleSubmit } = useForm();
    const [mensajeError, setMensajeError] = useState("");

    const [values, setValues] = useState({
        password: '',
        password2: '',
        showPassword: false,
      });

    const classes = useStyles();
    const clases="p-1 bg-white mb-2 p3-form m-0"

    useEffect(() => {

      if(values.password !== values.password2){
        setMensajeError("Las contraseñas deben coincidir")
      }

      if(values.password === values.password2){
        setMensajeError("")
      }
      
      return () => {
        
      }
    }, [values.password2])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleSubmitPost = async (datos) => {

      if(values.password !== values.password2){
        alert("Las contraseñas no coinciden");
        return;
      }
      
        datos.password=values.password
       
        setGrabandoFormulario(true); 
        
        //const user = JSON.parse(localStorage.getItem("user"));
        
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = `${process.env.REACT_APP_API_URL}rrhhdatospersonales/resetpassword`;
        datos.idUser = user.id;
        
  
        
          await axios
            .post(SERVER_URL, {
              headers: {
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              datos,
            })
            .then((response) => {   
              if(!isNaN(response.data)){
                logout();
              }
            })
            .catch((error) => {
              console.log("error " + error);
            });
          
        setGrabandoFormulario(false);
      }

    return(
    <>  
    <div className="rounded border border-formulario p1-form mb-3">
        <div className="p-2 bg-white mb-2  m-0">
            <div className={clases}>
            <form
                  onSubmit={handleSubmit((datos) => {
                    handleSubmitPost(datos);
                  })}
                  className="row"
                >
        <Grid container spacing={3}>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}  >
        <FormControl className={clsx(classes.margin, classes.textField)}>
          <InputLabel htmlFor="standard-adornment-password">Contraseña nueva</InputLabel>
          <Input
            id="standard-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}  >
        <FormControl className={clsx(classes.margin, classes.textField)}>
          <InputLabel htmlFor="standard-adornment-password2">Repetir contraseña nueva</InputLabel>
          <Input
            id="standard-adornment-password2"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password2}
            onChange={handleChange('password2')}
            helperText={mensajeError}
            error = {mensajeError.length > 0}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        </Grid>
                
        {/* BOTON */}
        <div className="col-12 pt-4">
            {!grabandoFormulario && (
            <>
                <button className="float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation">
                MODIFICAR CONTRASEÑA
                </button>                      
            </>
            )}
        </div>
        
        </Grid>

        </form>
        {grabandoFormulario && (
            <div className={(classes.linear, "mt-4")}>
                <LinearProgress />
            </div>
        )}
        </div>
    </div>
    </div>          
    </>      
    )

    }
    

    export default connect(({ reducerApi }) => ({ reducerApi }), {
      logout,
  })(FormularioCambioPassword);
//export default FormularioCambioPassword;