import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Doughnut} from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
        padding: theme.spacing(1),
        margin: '10px',        
        backgroundColor: '#FFF',
        //height: '310px',
    },    
    subroot: {
        flexGrow: 1, 
        padding: theme.spacing(1),
        margin: '10px',        
        backgroundColor: '#FFF',
    },    
    padding16: {
        padding: theme.spacing(1),      
    }, 
    titulo: {
        fontSize: 18,
        fontFamily: 'Open Sans',
        fontWeight: 300,
        color: '#505050'
      },  
}));

const CategoriasyClientes = ({ventasPorCategorias, ventasPorClientes}) => { 
    const classes = useStyles(); 
    const colors = ["#4B78B5", "#4EA4B5", "#C6C6C6", "#7DB94A", "#C3CC46", "#00AAFF", "#C89946", "#C47046", "#BB393D", "#A63696", ];

    return(
    <>
    <div className={classes.root}> 
        <div className="row ">
            <div className="col-7">
                <BloqueCategorias colors={colors} datosVentas={ventasPorCategorias}/>
            </div>
            <div className="col-5">
                <BloqueClientesPrincipales colors={colors} datosVentas={ventasPorClientes}/>
            </div>
        </div>
    </div>
    </>
    )
};
export default CategoriasyClientes;

const BloqueCategorias = ({colors, datosVentas}) => {    
    const numberFormatter = Intl.NumberFormat('de-DE');
    const classes = useStyles();    
    
    const etiquetasCategorias = datosVentas && datosVentas.length > 0 ? datosVentas.map(a => a.categoria) : [];
    const ventasCategorias = datosVentas && datosVentas.length > 0 ? datosVentas.map(a => a.total) : [];
       
    let datasetsObj = {}
    datasetsObj.labels = etiquetasCategorias;
    datasetsObj.datasets = [{label: '', backgroundColor: colors, data:ventasCategorias}];

    return (
        <div className={classes.subroot}>    
            <div className={classes.padding16}>      
            <Typography className={classes.titulo}>Facturación Categorías</Typography> 
            <hr></hr>
            </div>
            <div className="row">
                <div className="col-5" style={{float: 'left',width: '20%', /*height: '200px'*/}}>
                    <Doughnut data={datasetsObj} options={opciones}/>
                </div>
                <div className="col-7" style={{float: 'left',width: '20%', /*height: '200px'*/}}>
                    {datosVentas && datosVentas.map((dato, index) =>(
                        <div key={index} className="row">
                            <div className="col-5 p-1" style={{float: 'left', backgroundColor: colors[index]}}>
                                {dato.categoria}
                            </div>
                            <div className="col-3  p-1 text-right" style={{float: 'left', backgroundColor: colors[index]}}>
                                {numberFormatter.format(dato.total)}
                            </div>
                            <div className="col-2  p-1 text-right" style={{float: 'left', backgroundColor: colors[index]}}>
                                {numberFormatter.format(dato.porcentaje)}%
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
const BloqueClientesPrincipales = ({colors, datosVentas}) => {  
    const numberFormatter = Intl.NumberFormat('de-DE');
    const classes = useStyles();
    
    const otrosColores = ["#C7C7C7", "#E5E5E5"];
    let porcentajedeCorte = 0;
    let porcentajeTotal = 0;
    let porcentajeResto = 0;
    if(datosVentas && datosVentas.length > 0){
    datosVentas.forEach((dato) => {
        porcentajedeCorte = parseFloat(dato.porcentajeCorte)
        porcentajeTotal += parseFloat(dato.porcentaje)        
    })
    porcentajeResto = 100 - porcentajeTotal;
}   

    return (
        <div className={classes.subroot}>    
            <div className={classes.padding16}>      
            <Typography className={classes.titulo}>Facturación Principales Clientes</Typography> 
            <hr></hr>
            </div>
            <div className="row">
                <div className="col-12 ml-4 mb-3" style={{float: 'left',width: '20%'}}>
                    <div className="row">
                        <div className="col-6 p-1" style={{float: 'left'}}>Facturación próxima al {porcentajedeCorte.toFixed(2)}%</div>
                        <div className="col-4 p-1" style={{float: 'left', textAlign: 'right', color: "#EF7E20"}}>{porcentajeTotal.toFixed(2)}%</div>
                    </div>
                </div>
                <div className="col-12 ml-4">
                    {datosVentas && datosVentas.map((dato, index) =>(
                            <div key={index} className="row">
                                <div className="col-5 p-1" style={{float: 'left', backgroundColor: otrosColores[index % 2]}}>
                                    {dato.cliente}
                                </div>
                                <div className="col-3  p-1 text-right" style={{float: 'left', backgroundColor: otrosColores[index % 2]}}>
                                    {numberFormatter.format(dato.total.toFixed(2))}
                                </div>
                                <div className="col-2  p-1 text-right" style={{float: 'left', backgroundColor: otrosColores[index % 2]}}>
                                    {numberFormatter.format(dato.porcentaje.toFixed(2))}%
                                </div>
                            </div>
                        ))}
                </div>
                <div className="col-12 ml-4 mt-3" style={{float: 'left',width: '20%'}}>
                    <div className="row">
                        <div className="col-5 p-1" style={{float: 'left'}}>Otros clientes</div>
                        <div className="col-5 p-1" style={{float: 'left', textAlign: 'right', color: "#EF7E20"}}>{porcentajeResto.toFixed(2)}%</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const opciones={
    maintainAspectRatio: false,
    responsive: true,
    plugins: {  
        legend: {
            display: false
        }, 
        title: {
            display: false,
            text: '',
        },
    },  
}  