import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
const FormFieldText = ({ propiedades, control }) => { 
    const { modo } = useParams();
    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )
    return (               
    <Grid item xs={propiedades.xs} className="" >  
    <Controller                        
        as={TextField}   
        required = {propiedades.required}
        control={control}
        id={propiedades.name}
        name={propiedades.name}
        disabled = {deshabilitado} //{propiedades.disabled}
        label={propiedades.label}
        defaultValue={propiedades.value}
        multiline
        rows={propiedades.lineas}
        variant="outlined"
        size="small"
        fullWidth
        > 
       </Controller> 
    </Grid>       
    )
}
export default FormFieldText;