import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {Bar, Pie} from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useReactToPrint } from "react-to-print";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PrintIcon from '@material-ui/icons/Print';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const meses = ['Enero', 'Febrero', 'Marzo','Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const colors = ["#FBB965", "#76C8C6", "#F38D89", "#C5C5C5"]

const useStyles = makeStyles((theme) => ({
    
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '3px !important',
    color: '#505050'
  },

}));

const Graficas = ({subOperacion, fechaInicial, fechaFinal, showPrintButton = true, showLabelValues = false}) => { 
  
  const classes = useStyles();   
  const componentRef = useRef();

  const [datos, setDatos] = useState([]) 
  const [data, setData] = useState({
    datosResumenMensual: {labels: meses, datasets: []}, 
    datosResumenAnual: {datasets: []},
    datosDimensionesContenedor: {datasets: []},
    datosEstadoCargaContenedor: {datasets: []},
    datosTipoOperacionContenedor: {datasets: []},
    datosTipoCargaContenedor: {datasets: []},
    datosTotal: {total: 0, porcentaje: 0}
  })

  useEffect(() => {        
      const fetchData = async () => {
        let respGrafica = []
        //console.log(subOperacion)
        switch(subOperacion){
          case "Contenedor":
            //console.log(`${process.env.REACT_APP_API_URL}auditorias/resumenauditoriacontenedor?fechainicial=${fechaInicial}&fechafinal=${fechaFinal}`)
            respGrafica = await axios(`${process.env.REACT_APP_API_URL}auditorias/resumenauditoriacontenedor?fechainicial=${fechaInicial}&fechafinal=${fechaFinal}`);   
            setDatos(respGrafica.data) 
          break;
          case "Rodante":
            //console.log(`${process.env.REACT_APP_API_URL}auditorias/resumenauditoriarodante?fechainicial=${fechaInicial}&fechafinal=${fechaFinal}`)
            respGrafica = await axios(`${process.env.REACT_APP_API_URL}auditorias/resumenauditoriarodante?fechainicial=${fechaInicial}&fechafinal=${fechaFinal}`);   
            setDatos(respGrafica.data) 
          break;
          case "mGeneral":
            //console.log(`${process.env.REACT_APP_API_URL}auditorias/resumenauditoriamgeneral?fechainicial=${fechaInicial}&fechafinal=${fechaFinal}`)
            respGrafica = await axios(`${process.env.REACT_APP_API_URL}auditorias/resumenauditoriamgeneral?fechainicial=${fechaInicial}&fechafinal=${fechaFinal}`);   
            setDatos(respGrafica.data) 
          break;
          default:
            setDatos({
              datosResumenMensual: {labels: meses, datasets: []}, 
              datosResumenAnual: {datasets: []},
              datosDimensionesContenedor: {datasets: []},
              datosEstadoCargaContenedor: {datasets: []},
              datosTipoOperacionContenedor: {datasets: []},
              datosTipoCargaContenedor: {datasets: []},
              datosTotal: {total: 0, porcentaje: 0}
            }) 
        }
         
      };
      fetchData();  
  }, [subOperacion, fechaInicial, fechaFinal]);

  useEffect(() => {  
    if(Object.keys(datos).length > 0){ 
      const datosResumenMensual = getDatosResumenMensual(datos.resumenMensual);
      const datosResumenAnual = getDatosResumenAnual(datos.resumenAnual);
      const datosDimensionesContenedor = getDatosPorDimensionContenedor(datos.contenedoresPorDimension)
      const datosEstadoCargaContenedor = getDatosEstadoCargaContenedor(datos.contenedoresPorEstadoCarga)
      const datosPorTipooperacion = getDatosPorTipoOperacion(datos.contenedoresPorTipoOperacion)
      const datosPorTipoCargaContenedor =  getDatosTipoCargaContenedor(datos.contenedoresPorTipoCarga)
      const datosTotales = getDatosTotales(datos.resumenAnual)
      
      setData({...data,  
        datosResumenMensual: {labels: datosResumenMensual.labels, datasets: datosResumenMensual.datasets},
        datosResumenAnual: {labels: datosResumenAnual.labels, datasets: datosResumenAnual.datasets},
        datosDimensionesContenedor : {labels: datosDimensionesContenedor.labels, datasets: datosDimensionesContenedor.datasets},
        datosEstadoCargaContenedor : {labels: datosEstadoCargaContenedor.labels, datasets: datosEstadoCargaContenedor.datasets},
        datosTipoOperacionContenedor : {labels: datosPorTipooperacion.labels, datasets: datosPorTipooperacion.datasets},
        datosTipoCargaContenedor : {labels: datosPorTipoCargaContenedor.labels, datasets: datosPorTipoCargaContenedor.datasets},
        datosTotal: datosTotales
      }) 
    }
  },[datos]) //Cuando esto se modifica hace el useEffect

  const opciones={
      maintainAspectRatio: false,
      responsive: true,
      plugins: {   
        title: {
          display: false,
          text: '',
        },        
        datalabels: {
          color: '#36A2EB'
        }
       
      },     
  }  

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
//console.log(data.datosResumenMensual)
return(
    <React.Fragment key="graficas">
    <div>
      <div  ref={componentRef}>       
      
        <div style={{float: 'left', width: '75%', height: '200px'}}>
            <Bar data={data.datosResumenMensual} options={opciones} plugins={ChartDataLabels}/>
        </div>
        <div style={{float: 'left',width: '20%', height: '200px'}}>
            <Bar data={data.datosResumenAnual} options={opciones}/>
        </div>
        {subOperacion === "Contenedor" &&
        <>
          <div style={{float: 'left', width: '20%', height: '200px'}}>
              <Pie data={data.datosDimensionesContenedor} options={opciones}/>
          </div>
          <div style={{float: 'left', width: '20%', height: '200px'}}>
              <Pie data={data.datosEstadoCargaContenedor} options={opciones}/>
          </div>
          <div style={{float: 'left', width: '20%', height: '200px'}}>
              <Pie data={data.datosTipoOperacionContenedor} options={opciones}/>
          </div>
          <div style={{float: 'left', width: '20%', height: '200px'}}>
              <Pie data={data.datosTipoCargaContenedor} options={opciones}/>
          </div>
        </>
        }

        <div style={{float: 'right', width: '10%', marginRight: '10px'}}>
          <Typography className={classes.heading}>{data.datosTotal.total} UDS</Typography>
          <Typography className={classes.heading}>
            {data.datosTotal.porcentaje < 0 && <ArrowDropDownIcon />}
            {data.datosTotal.porcentaje > 0 && <ArrowDropUpIcon />}
            {data.datosTotal.porcentaje}%
          </Typography>
        </div>          
      </div>
      <div>
        { showPrintButton && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={() => { handlePrint() }}
          startIcon={<PrintIcon />}
        >
          Imprimir
        </Button> 
        )} 
      </div>
      
    </div>
    
    </React.Fragment>
   )}
   export default Graficas;

   const getDatosResumenMensual = (datosResumenMensual) =>
   {
    const years = datosResumenMensual.map((dato) => {
      return dato.year;
    });
    const uniqueYears = years.filter((value, index, array) => array.indexOf(value) === index);

    let finalResultadoDatasetArray = [];
    uniqueYears.forEach((year,index) => {     
      let newDatasetObject = {};
      newDatasetObject.label = year
      newDatasetObject.backgroundColor = colors[index]
      
      const resultado = datosResumenMensual.filter((res) => parseInt(res.year) === parseInt(year));       
      
      let resultadoDataset = [];
      meses.forEach((month, index) => {          
        const encontrado = resultado.find((res) => parseInt(res.mes) === parseInt(index) ) 
        let valor = encontrado ? parseFloat(encontrado.total) : 0; 
        resultadoDataset.push(valor);              
      }); 
      let finalResultadoDataset = {};
      for(let i = 0; i < resultadoDataset.length; i++ ) {
        Object.assign(finalResultadoDataset, resultadoDataset[i]);
      }
      newDatasetObject.data = resultadoDataset;
      finalResultadoDatasetArray.push(newDatasetObject);      
    });   
    return {labels: meses, datasets: finalResultadoDatasetArray};
   }
   
   const getDatosResumenAnual = (datosResumenAnual) => {
    const years = datosResumenAnual.map((dato) => {
      return dato.year;
    });
    const uniqueYears = years.filter((value, index, array) => array.indexOf(value) === index);
    let finalResultadoDatasetArray = [];
    uniqueYears.forEach((year,index) => {
      let newDatasetObject = {};
      newDatasetObject.label = year
      newDatasetObject.backgroundColor = colors[index]
      let resultadoDataset = [];
      const resultado = datosResumenAnual.filter((res) => parseInt(res.year) === parseInt(year));
      const encontrado = resultado.find((res) => parseInt(res.year) === parseInt(year) ) 
      let valor = encontrado ? parseFloat(encontrado.total) : 0; 
         
      resultadoDataset.push(valor);  
      let finalResultadoDataset = {};
      for(let i = 0; i < resultadoDataset.length; i++ ) {
        Object.assign(finalResultadoDataset, resultadoDataset[i]);
      }
      newDatasetObject.data = resultadoDataset;
      finalResultadoDatasetArray.push(newDatasetObject); 
    })
    return {labels: [''], datasets: finalResultadoDatasetArray}
   }

   const getDatosPorDimensionContenedor = (datosDimensionContenedor) => {
    if (!datosDimensionContenedor) return {labels: '', datasets: []}

    const dimensiones = datosDimensionContenedor.map((dato) => {
      return dato.tipoContenedor
    });

    const dimensionesUnicas = dimensiones.filter((value, index, array) => array.indexOf(value) === index);
    let valores  = [];
    let etiquetas = []
    dimensionesUnicas.forEach((dimension,index) => {
      etiquetas.push(isNaN(parseInt(dimension)) ? dimension + "'" : parseInt(dimension) + "'")
      const resultado = datosDimensionContenedor.filter((res) => res.tipoContenedor === dimension);
      const encontrado = resultado.find((res) => res.tipoContenedor === dimension ) 
      let valor = encontrado ? parseFloat(encontrado.total) : 0; 
      valores.push(valor)
    })

    let newDatasetObject = {};
    newDatasetObject.label = "Movimientos"
    newDatasetObject.backgroundColor = colors
    newDatasetObject.data = valores;

    let finalResultadoDatasetArray = [];
    finalResultadoDatasetArray.push(newDatasetObject); 
    //console.log(finalResultadoDatasetArray)

    return {labels: etiquetas, datasets: finalResultadoDatasetArray}
   }

   const getDatosEstadoCargaContenedor = (datosEstadoCargaContenedor) => {
    if (!datosEstadoCargaContenedor) return {labels: '', datasets: []}

    const estados = datosEstadoCargaContenedor.map((dato) => {
      return dato.estadoContenedor
    });

    const estadosUnicos = estados.filter((value, index, array) => array.indexOf(value) === index);
    let valores  = [];
    let etiquetas = []
    estadosUnicos.forEach((estado,index) => {
      etiquetas.push(estado === "E" ? "Empty" : "Full")
      const resultado = datosEstadoCargaContenedor.filter((res) => res.estadoContenedor === estado);
      const encontrado = resultado.find((res) => res.estadoContenedor === estado ) 
      let valor = encontrado ? parseFloat(encontrado.total) : 0; 
      valores.push(valor)
    })

    let newDatasetObject = {};
    newDatasetObject.label = "Movimientos"
    newDatasetObject.backgroundColor = colors
    newDatasetObject.data = valores;

    let finalResultadoDatasetArray = [];
    finalResultadoDatasetArray.push(newDatasetObject); 
    return {labels: etiquetas, datasets: finalResultadoDatasetArray}
   }

   const getDatosPorTipoOperacion = (datosTipoOperacion) => {
    //console.log(datosTipoOperacion)
    if (!datosTipoOperacion) return {labels: '', datasets: []}

    const tiposOperacion = Object.keys(datosTipoOperacion);
    let etiquetas = [];
    let valores = [];

    tiposOperacion.forEach((tipoOperacion,index) => {
      valores.push(datosTipoOperacion[tipoOperacion])
      switch(tipoOperacion){
        case "002":
          etiquetas.push("Load")
          break;
        case "004":
          etiquetas.push("Unload")
          break;
        case "REM":
          etiquetas.push("Restrow")
          break;
        default:
          etiquetas.push("Others")
      }
    })

    let newDatasetObject = {};
    newDatasetObject.label = "Movimientos"
    newDatasetObject.backgroundColor = colors
    newDatasetObject.data = valores;

    let finalResultadoDatasetArray = [];
    finalResultadoDatasetArray.push(newDatasetObject); 
    return {labels: etiquetas, datasets: finalResultadoDatasetArray}
   }

   const getDatosTipoCargaContenedor = (datosTipoCargaContenedor) => {
    if (!datosTipoCargaContenedor) return {labels: '', datasets: []}

    const estados = datosTipoCargaContenedor.map((dato) => {
      return dato.cargaContenedor
    });

    const estadosUnicos = estados.filter((value, index, array) => array.indexOf(value) === index);
    let valores  = [];
    let etiquetas = []
    
    estadosUnicos.forEach((estado,index) => {

      switch(estado){
        case "D":
          etiquetas.push("Dry")
          break;
        case "R":
          etiquetas.push("Reefer")
          break;
        default:
          etiquetas.push("Other")
      }

      const resultado = datosTipoCargaContenedor.filter((res) => res.cargaContenedor === estado);
      const encontrado = resultado.find((res) => res.cargaContenedor === estado ) 
      let valor = encontrado ? parseFloat(encontrado.total) : 0; 
      valores.push(valor)
    })

    let newDatasetObject = {};
    newDatasetObject.label = "Movimientos"
    newDatasetObject.backgroundColor = colors
    newDatasetObject.data = valores;

    let finalResultadoDatasetArray = [];
    finalResultadoDatasetArray.push(newDatasetObject); 
    return {labels: etiquetas, datasets: finalResultadoDatasetArray}
   }

   const getDatosTotales = (datosTotales) => {  
      let total = 0;
      let porcentaje = 0;

      if(datosTotales.length > 0){
        total = parseFloat(datosTotales[datosTotales.length-1].total)
      }
      
      if(datosTotales.length > 1){
        const coeficiente = parseFloat(datosTotales[datosTotales.length-2].total) > parseFloat(datosTotales[datosTotales.length-1].total) ? -1 : 1;
        const dividendo = parseFloat(datosTotales[datosTotales.length-1].total) * 100;
        const divisor = parseFloat(datosTotales[datosTotales.length-2].total);
        const calculoPorcentajeTemporal = dividendo/divisor
        porcentaje = coeficiente < 0 ? (100 - calculoPorcentajeTemporal) * coeficiente : calculoPorcentajeTemporal; 
      }     

      return {total: total.toFixed(2), porcentaje: porcentaje.toFixed(2)};
   }
   