import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';

import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: '36ch',
    },
    inline: {
      display: 'inline',
      wordBreak: 'break-word',
    },  
    noLeido: {
        backgroundColor: 'antiquewhite !important',
      },
    leido:{
        backgroundColor: 'ghostwhite !important'
    }  
}));

const NotaAviso = ({nota, actualizaNotas, editNota, onClick, replyNota}) =>{
    const classes = useStyles();     

    const handleSubmitPost = async (url, datos) => {
        let user = JSON.parse(localStorage.getItem("user"));
        const AuthStr = "Bearer ".concat(user.access_token);
        const SERVER_URL = process.env.REACT_APP_API_URL + "notasavisodestinatarios/" + url;
        
        await axios
        .post(SERVER_URL, {
            headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
            },
            datos
        })
        .then((response) => {            
            actualizaNotas(true)            
        })
        .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
        });
            
    };

    const clickRead = (nota) => {
      handleSubmitPost("setnotaleida", nota.notaAvisoDestinatarioId)
      onClick(nota)
    }
    
    const clickDelete = (id) => {
      handleSubmitPost("setnotaeliminada", id)
    }

    const clickEdit = (nota) => {  
      handleSubmitPost("setnotaleida", nota.notaAvisoDestinatarioId)
      editNota(nota)
    }

    const clickReply = (nota) => {
      handleSubmitPost("setnotaleida", nota.notaAvisoDestinatarioId)
      replyNota(nota)
    }

    const usuario = JSON.parse(localStorage.getItem("user"));
    const puedoEditar = usuario.id === nota.Fk_Remitente

    return (   
          <div className={nota.Leido === 0 ? classes.noLeido : classes.leido}>
            <List className={classes.root} dense={true} >              
                <ListItem alignItems="flex-start">  

                <ListItemText
                onClick={() => clickRead(nota)}
                  className={classes.inline}                
                  primary={nota.FechaCreacion.substr(0, 10)}

                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {nota.remitente.nombre}                            
                      </Typography>
                      <br />
                      {nota.Asunto}
                      
                      <br />
                      {nota.Enlace && (
                        <a href={`${nota.Enlace}`}>Ver</a>
                        
                      )}
                     </React.Fragment>
                  }

                />                 
                  <ListItemSecondaryAction>

                    <IconButton onClick={() => {clickDelete(nota.notaAvisoDestinatarioId) }} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                    {puedoEditar && 
                    <IconButton onClick={() => { clickEdit(nota) }} edge="end" aria-label="edit">
                      <EditIcon />
                    </IconButton>}   

                    <IconButton onClick={() => { clickReply(nota) }} edge="end" aria-label="reply">
                      <ReplyIcon />
                    </IconButton>                 
                   
                  </ListItemSecondaryAction>
                </ListItem>
            </List>
          </div>           
      );

}

export default NotaAviso;

/*
 { nota.Leido === 0 && (
                    <IconButton onClick={() => { clickRead(nota.notaAvisoDestinatarioId) }} edge="end" aria-label="read">
                      <CheckIcon />
                    </IconButton>
                    )}
*/