import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";
import SearchBar from "../../../../../components/Common/SearchBar";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import IconButton from "@material-ui/core/IconButton";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Axios from "axios";
import FiltroListado from "../../Gestion/Facturas/FiltroListado";

const Operaciones = () => {
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [tipoOperacion, setTipoOperacion]=useState(localStorage.getItem('idTipoOperacion') || "Abiertas");    
  const [url, setUrl] = useState("")

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const botoneraAcciones = [
   // { etiqueta: "Crear Operación", url: "/operativas/operaciones/operacion", activo: true },
   {
      etiqueta: "Cargar Partes",
      url: '/operativas/operaciones/operacion/filaSeleccionada/W',
      activo: false,
    }, 
  ];

  const handleOperacion = (idO) =>{  
    localStorage.setItem('idTipoOperacion', idO);     
    setTipoOperacion(idO)
    
  }  

  const handleClickOpen = (value) => {  
    
    const fetchData = async () => {
      const respFacturaUnica = await Axios( process.env.REACT_APP_API_URL + "operaciones/generapdfbeneficios/" + value);
      if (respFacturaUnica.data) {   
        window.open(respFacturaUnica.data, "_blank")
      }
    };
    fetchData();      
    //window.open(fichero, "_blank")
  };

  let tipoOp  = ""
  switch (tipoOperacion) {
    case "Abiertas":
      tipoOp = "?estado=1";
      break;
    case "En carga":
      tipoOp = "?estado=2";
      break; 
    case "A facturar":
      tipoOp = "?estado=3";
      break; 
    case "Cerradas":
      tipoOp = "?estado=4";
      break;
    case "Facturadas":
      tipoOp = "?estado=5";
      break;
  
    default:
      tipoOp  = ""
      break;
  }

  const cloumnsPlanificaciones = [
    {
      name: "Id",
      label: "Id",
      options: {
       filter: false,
       sort: true,
       display: false,
      }
     },
     {
      name: "Codigo",
      label: "Código",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Fecha_Creacion",
      label: "Fecha COP",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Cuentas",
      label: "Clientes",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Localizacion",
      label: "Localizacion",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Buque",
      label: "Buque",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Fecha_Operativa",
      label: "Fecha Operativa",
      options: {
       filter: true,
       sort: true,
       
      }   
     }, 
     {
      name: "Estado",
      label: "Estado",
      options: {
       filter: true,
       sort: true,
       
      }   
     }, 
     {
      name: "beneficio",
      label: "% b.º",
      options: {
       filter: true,
       sort: true,
       display: tipoOperacion === "Cerradas" || tipoOperacion === "Facturadas"
      }   
     }, 
     
     {
      name: "urlDocumento",
      label: "PDF b.º",
      options: {
       filter: false,
       sort: true,
       display: tipoOperacion === "Cerradas" || tipoOperacion === "Facturadas",
       customBodyRender: (value, tableMeta, updateValue) => {  
        return (
          <IconButton className="" onClick={() => handleClickOpen(tableMeta.rowData[0])}>
          <PictureAsPdfIcon className=""/>
          </IconButton>          
        );
        }
      }
     },

     {
      name: "numpartescpe",
      label: "Partes CPE",
      options: {
       filter: true,
       sort: true,
       sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseInt(obj1.data, 10);
          let val2 = parseInt(obj2.data, 10);
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
       },
       setCellProps: () => { return { align:"right"} },
      }   
     }, 

     {
      name: "numfacturas",
      label: "Facturas CPE",
      options: {
       filter: true,
       sort: true,
       sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseInt(obj1.data, 10);
          let val2 = parseInt(obj2.data, 10);
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
       },
       setCellProps: () => { return { align:"right"} },
      }   
     },

     {
      name: "importepartescpe",
      label: "€ Partes CPE",
      options: {
       filter: true,
       sort: true,
       sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
          let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
       },
       /*
       customBodyRender: function (value, tableMeta, updateValue) {
        return new Intl.NumberFormat().format(value)
       },*/
       setCellProps: () => { return { align:"right"} },
      }   
     }, 

     {
      name: "facturascpe",
      label: "€ Facturas CPE",
      options: {
       filter: true,
       sort: true,
       sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
          let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
       },
       /*
       customBodyRender: function (value, tableMeta, updateValue) {
          return new Intl.NumberFormat().format(value)
       },
       */
       setCellProps: () => { return { align:"right"} },
      }   
     }, 

     {
      name: "desviacioncpe",
      label: "€ Desviación CPE",
      options: {
       filter: true,
       sort: true,
       sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
          let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
          return (val1 - val2) * (order === 'asc' ? 1 : -1);
        };
       },
       /*
       customBodyRender: function (value, tableMeta, updateValue) {
        return new Intl.NumberFormat().format(value)
       },
       */
       setCellProps: () => { return { align:"right"} },
      }   
     }, 

  ];

  const handleUrlFecha = (url) =>{  
    setUrl(url)     
   }

  let urlDatos = "operaciones" + tipoOp + url;
  const urlDoubleClick = "operaciones/operacion/"
  const filtroToolbar = true
  const filtroToolbarOpciones = ['En carga', 'A facturar', 'Facturadas', 'Cerradas', 'Todas']
  
  const indice1 = urlDatos.indexOf("?")
  const indice2 = urlDatos.lastIndexOf("?")

  if(indice1 !== indice2){
    urlDatos = urlDatos.replaceAt(indice2, "&")
  }

return (           
    <div className="col-8 col-main-home ">            
      <SearchBar
        titulo="Operativas"
        claseCabecera = "bg-produccion"
        buscar={false}
        imagenCabecera={"5-2-2-operaciones.png"}
      />   
      
      <div className="bg-cuerpo p-10px">
        <div className="bg-white p-10px">
          <div className="p-1 bg-white mb-3">
            <h6 className="texto-gestion float-left" id="title">
              Operaciones
            </h6>
          </div>
          <hr className="m-0 bg-gestion mb-1" />
          <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
          <FiltroListado urlfecha={handleUrlFecha}/>
           <CuerpoTablaPiezas
              columnas = {cloumnsPlanificaciones}
              urlDatos = {urlDatos}
              urlDoubleClick = {urlDoubleClick}
              columnId = "Id"
              getFilaSeleccionada={marcarFila}
              
              filtroToolbar={filtroToolbar}
              filtroToolbarOpciones = {filtroToolbarOpciones}
              filtroToolbarValor = {tipoOperacion}
              filtroToolbarChangeValor={handleOperacion}
              filtroEjercicio = {true}
            /> 
          </div>
        </div>

        <div className="bg-cuerpo">
          <div className="bg-white">
            <TableAcciones                
              filaSeleccionada={filaSeleccionada}                
              botonaccion={botoneraAcciones}
            />            
          </div>
        </div>

      </div>
        
    </div>   
  );
};

export default Operaciones;

String.prototype.replaceAt = function(index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}