import React from "react";

const getCosteTotalMaquinaria = (datosJornadas, mano) => {
    let maquinariaPropia = []
    let maquinariaExterna = []

    if(datosJornadas && datosJornadas.length > 0){
        maquinariaPropia = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo principal propio" && parseInt(datosJornada.elementos.mano) === parseInt(mano))
        maquinariaExterna = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo principal externo" && parseInt(datosJornada.elementos.mano) === parseInt(mano))  
    }

    let costeTotalMaquinaria = 0;
    let costeTotalMaquinariaPropia = 0;
    let costeTotalMaquinariaExterna = 0;
    let unidadesTotalMaquinariaPropia=0;
    let unidadesTotalMaquinariaExterna=0;    

    maquinariaPropia.forEach(maquinaria => {
        const coste = parseFloat(maquinaria.elementos.Coste ? maquinaria.elementos.Coste : 0);
        const cantidad = parseFloat(maquinaria.elementos.cantidad ? maquinaria.elementos.cantidad : 0);
        
        costeTotalMaquinaria += coste * cantidad
        costeTotalMaquinariaPropia += coste * cantidad
        unidadesTotalMaquinariaPropia += cantidad
    })

    maquinariaExterna.forEach(maquinaria => {
        const coste = parseFloat(maquinaria.elementos.Importe ? maquinaria.elementos.Importe : 0);
        const cantidad = parseFloat(maquinaria.elementos.cantidad ? maquinaria.elementos.cantidad : 0);
        
        costeTotalMaquinaria += coste * cantidad
        costeTotalMaquinariaExterna += coste * cantidad
        unidadesTotalMaquinariaExterna += cantidad
    })

    return {
        costeTotalMaquinaria: costeTotalMaquinaria, 
        costeTotalMaquinariaPropia: costeTotalMaquinariaPropia,
        costeTotalMaquinariaExterna: costeTotalMaquinariaExterna,
        unidadesTotalMaquinariaPropia: unidadesTotalMaquinariaPropia,
        unidadesTotalMaquinariaExterna: unidadesTotalMaquinariaExterna,        
    }
}

const RecursosManoDetalleMaquinariaDesgloce = ({ 
    datosJornadas, mano }) => 
{  
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);
    
    const costeTotalManoObraAgrupado = getCosteTotalMaquinaria(datosJornadas, mano)
    
    const costeTotalMaquinaria = numberFormat2.format(costeTotalManoObraAgrupado.costeTotalMaquinaria)    
    const maquinariaPropia = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo principal propio" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []
    const maquinariaExterna = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo principal externo" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []   
    const unidadesTotalMaquinariaPropia = costeTotalManoObraAgrupado.unidadesTotalMaquinariaPropia;
    const unidadesTotalMaquinariaExterna = costeTotalManoObraAgrupado.unidadesTotalMaquinariaExterna;
    const maquinariaTotalUnidades = unidadesTotalMaquinariaPropia + unidadesTotalMaquinariaExterna;
    
    return (        
    <>
    <div className="col-12 d-flex overflow-auto justify-content-center mb-2">
        <div className="col-2 float-left">Empresa </div>
        <div className="col-1 float-right">N.º</div>
        <div className="col-5 float-left">Tipología</div>
        <div className="col-2 float-left text-right">Cantidad </div>
        <div className="col-2 float-right text-right">Costes</div>
    </div>

    {maquinariaPropia &&  maquinariaPropia.map((maquinaria, index) => (
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">
            <div className="col-2 float-left">Gesport</div>
            <div className="col-1 float-right">{index+1}</div>
            <div className="col-5 float-left">{maquinaria.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{maquinaria.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format((maquinaria.elementos.Coste ? maquinaria.elementos.Coste : 0 )* maquinaria.elementos.cantidad)}</div>
        </div>
    ))}

    {maquinariaExterna &&  maquinariaExterna.map((maquinaria, index) => (
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">
            <div className="col-2 float-left">Externo</div>
            <div className="col-1 float-right">{maquinariaPropia.length + index+1}</div>
            <div className="col-5 float-left">{maquinaria.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{maquinaria.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format(parseFloat((maquinaria.elementos.Coste?maquinaria.elementos.Coste:0)) * parseInt(maquinaria.elementos.cantidad))}</div>
        </div>
    ))}

    <div className="col-12 d-flex overflow-auto justify-content-center mt-2 mb-2 ">                    
        <div className="col-5 float-left"></div>
        <div className="col-3 float-right text-right">Total</div>                        
        <div className="col-2 float-left text-right">{maquinariaTotalUnidades} </div>
        <div className="col-2 float-left text-right">{costeTotalMaquinaria}</div>                    
    </div> 
    </>    
    )
}

export default RecursosManoDetalleMaquinariaDesgloce;