import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router";

import FormularioFacturaProductos from '../../../../../components/Common/FormularioFacturaProductos'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import TablaFacturaProductos from '../../../../../components/Common/TablaFacturaProductos'
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from '@material-ui/core/styles';
//import FormularioFacturaTotal from "./FormularioFacturaTotal";

import {useFacturaVenta} from './facturaventa-context'
import FormularioFacturaRecurrenteDatos from "./FormularioFacturaRecurrenteDatos";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },  
}));

const FormularioFacturaRecurrente = ({index, tipoForm}) => {  
  const { listadoProductosFacturaVenta, totalFacturaVenta, setListadoProductosFacturaVenta } = useFacturaVenta(); 
  const methods = useForm(); 
  const classes = useStyles();
  const { control, handleSubmit} = useForm(); 
  const { id, modo } = useParams();   
  const [FacturaRecurrente, setFacturaRecurrente] = useState({}) 
  const [ficheros, setFicheros] = useState([])
  const [nombresFicheros, setNombresFicheros] = useState([])
  const [cliente, setCliente] = useState({})
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory(); 
  
  const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
  };  

  useEffect(() => { 
    const fetchData = async () => {
      setIsLoading(true) 
      if (modo === "W") {        
        const resFacturaRecurrente = await axios(
            `${process.env.REACT_APP_API_URL}facturasrecurrentes/${id}`
        );
        setFacturaRecurrente(resFacturaRecurrente.data)  
      }
      setIsLoading(false);
    };        
      fetchData();     
  },[]);  

  const handleChangeCliente = (valor) => {
      setCliente(valor)  
  }
   
  const handleChangeFicheros = (fich, nobresFich) => {
    setFicheros(fich)
    setNombresFicheros(nobresFich)
  }

  useEffect(() => {
    if (typeof FacturaRecurrente !== "undefined") { 
      if(Object.keys(FacturaRecurrente).length > 0){   
        setIsLoading(true)            
        setCliente(FacturaRecurrente.Cliente ? FacturaRecurrente.Cliente : {})
        setListadoProductosFacturaVenta(FacturaRecurrente.productos)   
        //setListadoVentas(FacturaRecurrente.productos);
        //setBaseImponible(FacturaRecurrente.BaseImponible)        
        setIsLoading(false)        
      }
    }
  }, [FacturaRecurrente])

  const handleSubmitPost = async (datos) => { 
      
      datos.FechaFactura = datos.fechaFactura
      datos.Cliente = datos.Cliente.Id
      datos.Periodicidad = datos.Periodicidad ? datos.Periodicidad.Id : null
      datos.cop = datos.cop ? datos.cop.Id : null
      datos.Estado = datos.Estado.Id
      datos.Observaciones = datos.observaciones
      datos.ficheros=ficheros
      datos.nombresFicheros=nombresFicheros

      datos.baseImponible = totalFacturaVenta.baseImponible
      datos.totalImpuestos = totalFacturaVenta.igic
      datos.totalIRPF = totalFacturaVenta.irpf
      datos.totalFactura = totalFacturaVenta.total     
      datos.listadotabla=listadoProductosFacturaVenta      

      let user = JSON.parse(localStorage.getItem("user"));
      //console.log(user)
      datos.userid = user.id
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}facturasrecurrentes`

      console.log(datos)
      //return;
      
      if (modo==="W") {        
          await axios
            .put(`${SERVER_URL}/${id}`, {
              headers: {        
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              datos,
            })
            .then((response) => {       
              //console.log(response.data) 
              if(!isNaN(response.data)){
                if(parseInt(response.data) === parseInt(id)){                       
                    setOpenAlert(true)
                    setTimeout(function () {
                     window.location.reload();   
                    }, 500);
                }
              }
              })
              .catch((error) => {
                console.log("error " + error);
                console.log(error.response.data); // => the response payload 
                console.log(error.response.data.message)
          });
      }
      else{
          await axios
            .post(SERVER_URL, {
              headers: {        
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              datos,
            })
            .then((response) => {       
              //console.log(response.data) 
              if(!isNaN(response.data)){
                if(parseInt(response.data)){                       
                    setOpenAlert(true)
                    history.push({
                      pathname: `/administracion/ventas/facturasrecurrentes/${response.data}/W`,
                      //action: action + Math.floor(Math.random() * (9999))
                    });  
                    setTimeout(function () {
                      window.location.reload();
                    }, 500);
                    
                }
              }             
              })
              .catch((error) => {
                console.log("error " + error);
          });
      }
  }    
  
  //console.log(FacturaRecurrente)
  const mensajeAlerta = FacturaRecurrente && FacturaRecurrente.plantilla === 0 ? "Factura guardada correctamente!" : "Plantilla guardada correctamente!"
    
    return (
    <>    
      {!isLoading && (
        <div className={classes.root}>
          <FormProvider {...methods}>
            <form
              id='formulario-FacturaRecurrente'
              onSubmit={handleSubmit((data) => {
              handleSubmitPost(data);
              })}
              className=""
            >      
              <FormularioFacturaRecurrenteDatos datosFactura={FacturaRecurrente} changeCliente={handleChangeCliente} changeFicheros={handleChangeFicheros} control={control} />                     
              <FormularioFacturaProductos cliente={cliente} />
              <TablaFacturaProductos tipoFactura={"recurrente"}/>
              
           </form>
          </FormProvider>
          {openAlert && 
          <AlertaMensaje 
            mensaje={mensajeAlerta} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }     
        </div>        
      )}
        <div>
          {isLoading && 
            <div className={classes.linear}>
                  <LinearProgress />
            </div>
          }
        </div>
            </>
    )
};

export default FormularioFacturaRecurrente;