import React from "react";
import CuerpoTabla from "../../../../components/Common/CuerpoTabla";



const ConfigTablas =  () => {

  const BOTONERA_ACCIONES = [
    { etiqueta: "Mostrar Tabla ", url: "/tablas/", activo: true },
    {
      etiqueta: "Editar Tabla",
      url: "/tablas/",
      activo: false,
    }, 
  ];  

  const COLUMNS_TABLASEDIT = [
      {
      name: "id",
      label: "Id",
      options: {
       filter: false,
       sort: true,
       display: true,
      }
     },
     {
      name: "nombre_tabla",
      label: "Nombre tabla",
      options: {
       filter: false,
       sort: true,
       display: true,
      }
     }
  ]

  return (
    <CuerpoTabla
      tituloSearchBar = "Configuracion"
      claseBGSearchBar = "bg-comercial"
      titulo="Tablas editables"
      columnas = {COLUMNS_TABLASEDIT}
      botonera = {BOTONERA_ACCIONES}
      urlDatos ="dicconfigs"
      urlDoubleClick = "tablas/"
      columnId="id"
      dobleclick = {false}
    />
  )  
}

export default ConfigTablas;