import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";
import FormFieldFileUpload from "../../../../../components/Common/FormFieldFileUpload";
import TablaProductosTarifa from './TablaProductosTarifa';
import { useHistory } from "react-router";
import FormFieldAutoCompleteUncontrolled from "../../../../../components/Common/FormFieldAutoCompleteUncontrolled";
import Button from '@material-ui/core/Button';
import { getData } from "../../../../../axios";
import FormFieldNumberUncontrolled from "../../../../../components/Common/FormFieldNumberUncontrolled";
import FormFieldFiles from "../../../../../components/Common/FormFieldFiles";
import FormFieldText from "../../../../../components/Common/FormFieldText";
import { useStyles } from "../../../../../styles/styles";


const FormularioMultiple = ({index, tipoForm}) => { 
  const { register, getValues } = useForm();
  const [multiple, setMultiple] = useState([]) 
  const [campos, setCampos] = useState([]) 
  const { id, modo } = useParams();
  const methods = useForm();
  const classes = useStyles();
  const { control, handleSubmit} = useForm(); 
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [productos, setProductos] = useState([])
//const [listadoProductos, setListadoProductos] = useState([])
  const history = useHistory(); 
  const inputEl = useRef([]);   
  const porcentaje = useRef(0);   
  const [ficheros, setFicheros] = useState([])
  const [nombresFicheros, setNombresFicheros] = useState([])

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };  
 
    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 
      setCampos([
        //0
        {
          urlData: "cuentas/listadoclientes",
          campoEtiqueta: "Razon_Social",
          required: true,
          label: "Cliente:",
          name: "Cliente",
          disabled: false,
          type: "combo",
          value: "",              
          xs:4
      },
      //1
      {
          required: true,
          label: "Fecha:",
          name: "Fecha_Alta",
          type: "date",  
          value: '',
          xs:2
      },  
      //2
      {
          urlData: "dic/diccionarios/estadostarifacliente",
          campoEtiqueta: "Etiqueta",
          required: true,
          label: "Estado:",
          name: "Estado",
          disabled: false,
          type: "combo",
          value: "",              
          xs:2
      },
      //3
      {
        required: false,
        label: "% Variación",      
        name: "porcentajevariacion",
        type: "number",  
        value: '',
        disabled : false,
        xs:2
      },
      //4
      {
        required: true,
        label: "Título",
        name: "Titulo",
        type: "text",                        
        value: '',
        xs:12
      },
      //5
      {
        required: false,
        label: "Nota",
        name: "Nota",
        type: "text",                        
        value: '',
        xs:12
      },
      //6
      {
          required: false,
          label: "Observaciones",
          name: "Observaciones",
          type: "textArea",
          lineas: 4,                
          value: '',
          xs:12
      },
      //7
      {
          classButton: classDiv3,
          required: false,
          label: "",
          name: "filesupload",
          type: "filesupload",   
          tabla: "mtms_tarifas_adjuntos",
          xs:5
      }, 
      //8         
      {   
          label: "",
          name: "files",
          type: "files", 
          ficheros: '',   
          tabla: "mtms_tarifas_adjuntos",
          modelo: "api\\modules\\v1\\models\\tarifas\\TarifaAdjuntos",
          carpeta: "tarifa",
          xs:12
      },
      ])

      const fetchData = async () => {
        setIsLoading(true) 
        if (modo === "W") {
            const respTarifas = await axios(
                `${process.env.REACT_APP_API_URL}tarifas/${id}`
            );                
            setMultiple(respTarifas.data)  
        }
        setIsLoading(false);
      };    
      fetchData();   
      inputEl.current = []

    },[]);
    
    useEffect(() => {
      if (typeof multiple !== "undefined") { 
        if(Object.keys(multiple).length > 0){
          setIsLoading(true)  
          campos[0].value = multiple.Cliente;
          campos[1].value = multiple.Fecha_Alta;
          campos[2].value = multiple.Estado;
          campos[4].value = multiple.Titulo;
          campos[5].value = multiple.Nota;
          campos[6].value = multiple.Observaciones;
          campos[8].ficheros = multiple.ficheros;
          setIsLoading(false)        
        }
      }
    }, [multiple])

    const handleSubmitPost = async (datos,evento) => {      
      evento.preventDefault();
      let datosFormulario = {};
      datosFormulario.Cliente = datos.Cliente
      datosFormulario.Fecha_Alta = datos.Fecha_Alta
      datosFormulario.Estado = datos.Estado
      datosFormulario.Observaciones = datos.Observaciones
      datosFormulario.Titulo = datos.Titulo 
      datosFormulario.Nota = datos.Nota 
      datosFormulario.ficheros = ficheros
      datosFormulario.nombresFicheros = nombresFicheros     

      datosFormulario.listadotabla= inputEl.current

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}tarifas`

      

      if (modo==="W") {
        await axios
        .put(`${SERVER_URL}/${id}`, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datosFormulario,
        })
        .then((response) => { 
          if(!isNaN(response.data)){           
            if(parseInt(response.data) === parseInt(id)){
              //setProductos([])
              //inputEl.current=[]
              setOpenAlert(true)              
              setTimeout(function () {
                window.location.reload();   
              }, 1500);              
              //history.push({
              //  pathname: `/ventas/productos/tarifa/${response.data}/R`,
              //  //action: action + Math.floor(Math.random() * (9999))
              //});       
            }
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
          setProductos([])
          inputEl.current=[]
        });
      }
      else{
        await axios
        .post(SERVER_URL, {
          headers: {        
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datosFormulario,
        })
        .then((response) => {     
         
          if(!isNaN(response.data)){    
            history.push({
              pathname: `/ventas/productos/tarifa/${response.data}/R`,
              //action: action + Math.floor(Math.random() * (9999))
            });            
          }  
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
        });
      }
      //setIsLoading(false)
    }

    const [campoOperativas, setCampoOerativas] = useState({
      urlData: "dic/diccionarios/operativas",
      campoEtiqueta: "Etiqueta",
      required: false,
      name: "operativa",      
      label: "Operativa",
      value: "",
      multiple: false,
      xs:4,
    })

    const [campoCategorias, setCampoCategorias] = useState(
      {
        urlData: "dic/diccionarios/categoriasproducto",
        campoEtiqueta: "Etiqueta",
        required: false,
        name: "categoria",        
        label: "Categoría",
        value: "",
        multiple: false,
        xs:4,
      },
    )

    const [campoSubcategorias, setCampoSubcategorias] = useState({
      urlData: "dic/diccionarios/subcategoriasproducto/0",
      campoEtiqueta: "Etiqueta",
      required: false,
      name: "subcategoria",      
      label: "Subcategoría",
      value: "",
      multiple: false,
      xs:4,
    })

    const [campoProductos, setCampoProductos] = useState({
      urlData: "productos",
      campoEtiqueta: "nombre",
      required: false,
      name: "producto",      
      label: "Productos",
      value: "",
      multiple: false,
      xs:8,
    })    

    const handleChangeComboOperativa = (valor) => { 
      setCampoOerativas({...campoOperativas, value: valor })
    } 

    const handleChangeComboCategoria = (valor) => { 
      setCampoCategorias({...campoCategorias, value: valor})
    }

    const handleChangeComboSubcategoria = (valor) => { 
      setCampoSubcategorias({...campoSubcategorias, value: valor})
    }

    const handleChangeComboProducto = (valor) => { 
      setCampoProductos({...campoProductos, value: valor})
    } 

    useEffect(() => { 
      
      if(campoOperativas.value){ 
        
        setCampoCategorias({...campoCategorias,urlData: `dic/diccionarios/categoriasproductoxoperativa/${campoOperativas.value.Id}`, value: null})
        setCampoSubcategorias({...campoSubcategorias,urlData: `dic/diccionarios/subcategoriasproductoxoperativa/${campoOperativas.value.Id}`, value: null})
        setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativa/${campoOperativas.value.Id}`, campoEtiqueta: "Nombre", value: null})
      }
      else{
        
        setCampoCategorias({...campoCategorias,urlData: "dic/diccionarios/categoriasproducto", value: null})
        setCampoSubcategorias({...campoSubcategorias,urlData: "dic/diccionarios/subcategoriasproducto/0", value: null})
        setCampoProductos({...campoProductos,urlData: "productos", campoEtiqueta: "nombre", value: null})
      }
    },[campoOperativas])

    useEffect(() => { 
      
      if(campoCategorias.value){ 
        
        setCampoSubcategorias({...campoSubcategorias,urlData: `dic/diccionarios/subcategoriasproductoxoperativaycategoria/${campoOperativas.value.Id}/${campoCategorias.value.Id}`, value: null})
        setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativaycategoria/${campoOperativas.value.Id}/${campoCategorias.value.Id}`, campoEtiqueta: "Nombre", value: null})
      }
      else{
        if(campoOperativas.value){ 
          
          setCampoSubcategorias({...campoSubcategorias,urlData: `dic/diccionarios/subcategoriasproductoxoperativa/${campoOperativas.value.Id}`, value: null})
          setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativa/${campoOperativas.value.Id}`, campoEtiqueta: "Nombre", value: null})
        }
        else{
          
          setCampoSubcategorias({...campoSubcategorias,urlData: "dic/diccionarios/subcategoriasproducto/0", value: null})
          setCampoProductos({...campoProductos,urlData: "productos", campoEtiqueta: "nombre", value: null})
        }
      }
    },[campoCategorias])

    useEffect(() => { 
      if(campoSubcategorias.value){ 
        setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativaysubcategoria/${campoOperativas.value.Id}/${campoSubcategorias.value.Id}`, campoEtiqueta: "Nombre", value: null})
      }
      else{
        if(campoCategorias.value){ 
          setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativaycategoria/${campoOperativas.value.Id}/${campoCategorias.value.Id}`, campoEtiqueta: "Nombre", value: null})
        }
        else{
          if(campoOperativas.value){ 
            setCampoProductos({...campoProductos,urlData: `productos/listadoproductosxoperativa/${campoOperativas.value.Id}`, campoEtiqueta: "Nombre", value: null})
          }
          else{
            setCampoProductos({...campoProductos,urlData: "productos", campoEtiqueta: "nombre", value: null})
          }
        }
        //setCampoProductos({...campoProductos,urlData: "productos", campoEtiqueta: "nombre", value: null})
      }

    },[campoSubcategorias])

    const handleListadoProductos = (listado) => { 
      let productoAux = inputEl.current.find(producto => parseInt(producto.Id) === parseInt(listado[2]))     
      if(productoAux){         
        productoAux.tarifa = listado[10]
      }    
    }

    const setNewListadoProductos = (productos) => {      
      inputEl.current = productos
    }

    const addProductos = async ()  => {   
      if(campoProductos.value){           
        //AÑADIR VALORES CATEGORIA, ETC
        
        let nuevoCampo = {}//campoProductos.value 
        nuevoCampo.Activo = 1
        nuevoCampo.FechaAlta =  campoProductos.value.FechaAlta
        nuevoCampo.Id =  campoProductos.value.Id
        nuevoCampo.Operativa =  campoOperativas.value ? campoOperativas.value.Etiqueta : ""   
        nuevoCampo.Subcategoria =  campoSubcategorias.value ? campoSubcategorias.value.Etiqueta : ""
        nuevoCampo.categoria =  campoCategorias.value ? campoCategorias.value.Etiqueta : "" 
        nuevoCampo.codigo =  campoProductos.value.Codigo
        nuevoCampo.estado =  campoProductos.value.estado
        nuevoCampo.idTarifa = modo === "W" ? id : -1
        nuevoCampo.idTarifaDetalle = -1  
        nuevoCampo.nombre =  campoProductos.value.Nombre
        nuevoCampo.periodicidad =  null
        nuevoCampo.tarifa =  campoProductos.value.Tarifa
        nuevoCampo.unidad_medida =  null                
        
        inputEl.current.push(nuevoCampo)
  
        setProductos(nuevoCampo)    

      }else{
      async function getProductos() {
        const result = await getData(campoProductos.urlData); 
        let nuevosProductos = []
        result.forEach(element => {
          let nuevoCampo = element
          nuevoCampo.idTarifaDetalle = -1  
          nuevoCampo.idTarifa = modo === "W" ? id : -1 
          nuevoCampo.categoria =  element.Categoria
          nuevoCampo.Subcategoria =  element.SubCategoria
          nuevoCampo.codigo =  element.Codigo
          nuevoCampo.nombre =  element.Nombre
          nuevoCampo.unidad_medida =  element.Unidad
          nuevoCampo.periodicidad =  element.Periodicidad
          nuevoCampo.tarifa =  element.Tarifa
          inputEl.current.push(nuevoCampo)
          nuevosProductos.push(nuevoCampo)
        });
        setProductos(nuevosProductos)   
      }
        await getProductos();
      }
    }

    const actualizarPorcentajeTarifaProductos = async () => { 
      inputEl.current.forEach(element => {        
        element.tarifa = (parseFloat(element.tarifa) * (1 + parseFloat(porcentaje.current)/100)).toFixed(3);
      });  
      setProductos(inputEl.current)      
    }

    const handleChangePorcentaje = (valor) => {
      porcentaje.current = valor
    }

    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }

return (
<>    
  {!isLoading && (
  <>
    <div className={classes.root}>
      <FormProvider {...methods}>
        <form
          id='formulario-multiple'
          onSubmit={handleSubmit((data,e) => {
          handleSubmitPost(data,e);
          })}
          className=""
        >
        <Grid className="borderFormulario" container spacing={1}> 
          <FormFieldCombo propiedades={campos[0]} control={control}/>
          <FormFieldDate propiedades={campos[1]} control={control} />
          <FormFieldCombo propiedades={campos[2]} control={control}/>
          <FormFieldNumberUncontrolled propiedades={campos[3]} onChangeValue={handleChangePorcentaje} />
          <Grid item xs={2} className="" >
          <Button 
          variant="contained"
          disabled={modo === "R" ? true : false}
          onClick={() => actualizarPorcentajeTarifaProductos()}
          >Actualizar</Button>
                                        
          </Grid>    
          <FormFieldText propiedades={campos[4]} control={control}/>
          <FormFieldText propiedades={campos[5]} control={control}/>
          <FormFieldTextArea propiedades={campos[6]} control={control}/>
          <FormFieldFileUpload propiedades={campos[7]} control={control} handleFicheros={handleFicheros}/>
          <FormFieldFiles propiedades={campos[8]} />
        </Grid>

        <Grid container  spacing={1} className="mt-2">            
          <FormFieldAutoCompleteUncontrolled propiedades={campoOperativas} onChangeValue={handleChangeComboOperativa} />                
          <FormFieldAutoCompleteUncontrolled propiedades={campoCategorias} onChangeValue={handleChangeComboCategoria} />                
          <FormFieldAutoCompleteUncontrolled propiedades={campoSubcategorias} onChangeValue={handleChangeComboSubcategoria} />                
          <FormFieldAutoCompleteUncontrolled propiedades={campoProductos} onChangeValue={handleChangeComboProducto} />                

          <Grid item xs={2} className="" >
          <Button 
          variant="contained"
          disabled={modo === "R" ? true : false}
          onClick={() => addProductos()}
          >Añadir</Button>
                                        
          </Grid>    
        </Grid> 

        </form>
      </FormProvider>
      <div className="mt-4">  
        <TablaProductosTarifa productosTabla={inputEl.current} getListadoProductos={handleListadoProductos} setListadoProductos={setNewListadoProductos}/>
        {openAlert && 
          <AlertaMensaje 
            mensaje={"!Tarifa guardada correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
        }  
      </div>
    </div>  
  </>
  )}
  <div>
    {isLoading && 
      <div className={classes.linear}>
        <LinearProgress />
      </div>
    }
  </div>
</>
)
};

export default FormularioMultiple;