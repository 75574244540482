const COLUMNS_COTIZACIONES = [
    {
      name: "Id",
      label: "Id",
      options: {
       filter: false,
       sort: false,
       display: false,
      }
     },
    {
     name: "Fecha_Solicitud",
     label: "Fecha Solicitud",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "Codigo",
      label: "Código",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
     name: "Cuenta",
     label: "Cuenta",
     options: {
      filter: true,
      sort: true,
     }
    },    
     {
      name: "Fecha_Operativa",
      label: "Fecha Operativa",
      options: {
       filter: true,
       sort: true,
       
      }   
     },
     {
      name: "Responsables",
      label: "Responsable",
      options: {
       filter: true,
       sort: true,
       
      }   
     }, 
     {
      name: "Importe",
      label: "Importe",
      options: {
       filter: true,
       sort: true,
       
      }   
     },
     {
      name: "Enviado",
      label: "Enviado",
      options: {
       filter: true,
       sort: true,
       
      }   
     },  
     {
      name: "Resultado",
      label: "Resultado",
      options: {
       filter: true,
       sort: true,
       
      }   
     }, 
];
export {COLUMNS_COTIZACIONES};
