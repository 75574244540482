export default function operacionReducer (state, action){
    switch(action.type){

        case 'SET_OPERACION':
            let idsClientes = action.payload.CuentasCliente.map((s) => {
                return s.Id;
            });
            return {
                    ...state,
                    Fecha_Creacion: action.payload.Fecha_Creacion,
                    Fecha_Operativa: action.payload.Fecha_Operativa,
                    Codigo: action.payload.Codigo,
                    CPE: action.payload.CPE,
                    CPEEspecialidades: action.payload.CPEEspecialidades,
                    CPEMercancias: action.payload.CPEMercancias,
                    Gesport: action.payload.Gesport,
                    GesportListado: action.payload.GesportListado, 
                    Proveedores: action.payload.Proveedores,
                    cliente: idsClientes,
                    CuentasCliente: action.payload.CuentasCliente,
                    contacto: action.payload.Fk_Contacto,              
                    Fk_Localizacion: action.payload.Fk_Localizacion,
                    Fk_Localizacion2: action.payload.Fk_Localizacion2,
                    Fk_Planificacion: action.payload.Fk_Planificacion,                           
                    buque: action.payload.Fk_Buque,     
                    Buque : action.payload.Buque,  
                    Localizacion: action.payload.Localizacion,
                    buque2: action.payload.Fk_Buque2,     
                    Buque2 : action.payload.Buque2,  
                    Localizacion2: action.payload.Localizacion2,
                    Operativas: action.payload.Operativas,
                    Adjuntos: action.payload.Adjuntos,
                    carpetaPoseidon: action.payload.carpetaPoseidon,
                    listadoProductos: action.payload.listadoProductos
            };

        case 'SET_MANOS_OPERACION':
            return (
                typeof state === 'undefined' ?  
                {...state} : 
                {...state, manos: action.payload } 
            )
        
        case 'SET_DATOS_PEDIDO':
            return ({
                ...state, 
                datosPedido: {...state.datosPedido, ...action.payload}
            })

        case 'ADD_LISTADO_ESPECIALIDADES_CPE': 
            if(state.listadoPersonal && state.listadoPersonal.length > 0){
                const index = state.listadoPersonal.findIndex(object => {
                    return object.censoCPE === action.payload.censoCPE;
                }); 
                if(index>=0){
                    const removed = state.listadoPersonal.splice(index, 1, action.payload)
                    if(removed.length > 0)
                    {
                        return (
                            {...state, listadoPersonal: state.listadoPersonal }
                        )

                    }
                }
            }
            
            return (
                typeof state.listadoPersonal === 'undefined' ?  
                {...state, listadoPersonal: [action.payload] } : 
                {...state, listadoPersonal: [...state.listadoPersonal, action.payload] }
            )
            

        case 'REMOVE_LISTADO_ESPECIALIDADES_CPE':
            const listadoP = state.listadoPersonal.filter( personal => personal.censoCPE !== action.payload.censoCPE )
            return (
                typeof state.listadoPersonal === 'undefined' ?  
                {...state } : 
                {...state, listadoPersonal:  listadoP }
            )
        
        case 'CLEAR_LISTADO_ESPECIALIDADES_CPE':
            return (
                typeof state.listadoPersonal === 'undefined' ?  
                {...state } :  {...state, listadoPersonal:  [] }
            )

        case 'ADD_LISTADO_MERCANCIAS_CPE':
            if(state.listadoMercancias && state.listadoMercancias.length > 0){
                const index = state.listadoMercancias.findIndex(object => {
                    return object.id === action.payload.id;
                }); 
                if(index>=0){
                    const arrayForSort = [...state.listadoMercancias]
                    let removed = arrayForSort.splice(index, 1, action.payload)
                    if(removed.length > 0)
                    {
                        return (
                            {...state, listadoMercancias: arrayForSort }
                        )
                    }
                }
            }

            return (
                typeof state.listadoMercancias === 'undefined' ?  
                {...state, listadoMercancias: [action.payload] } : 
                {...state, listadoMercancias: [...state.listadoMercancias, action.payload] }
            )

        case 'REMOVE_LISTADO_MERCANCIAS_CPE':
            const listadoM = state.listadoMercancias.filter( mercancia => parseInt(mercancia.id) !== parseInt(action.payload.id) )
            return (
                typeof state.listadoMercancias === 'undefined' ?  
                {...state } : 
                {...state, listadoMercancias:  listadoM }
            )

        case 'CLEAR_LISTADO_MERCANCIAS_CPE':
            return (
                typeof state.listadoMercancias === 'undefined' ?  
                {...state } :  {...state, listadoMercancias:  [] }
            )
        
        case 'ADD_LISTADO_GESPORT':
            return (
                typeof state.listadoGesport === 'undefined' ?  
                {...state, listadoGesport: [action.payload] } : 
                {...state, listadoGesport: [...state.listadoGesport, action.payload] }
            )
            
        case 'REMOVE_LISTADO_GESPORT':
            const listadoG = state.listadoGesport.filter( recurso => (recurso.id !== action.payload.id) )
            return (
                typeof state.listadoGesport === 'undefined' ?  
                {...state } : 
                {...state, listadoGesport:  listadoG }
            )
        
        case 'CLEAR_LISTADO_GESPORT':
            return (
                typeof state.listadoGesport === 'undefined' ?  
                {...state } :  {...state, listadoGesport:  [] }
            )
        
        case 'ADD_LISTADO_PROVEEDOR':
            return (
                typeof state.listadoProveedor === 'undefined' ?  
                {...state, listadoProveedor: [action.payload] } : 
                {...state, listadoProveedor: [...state.listadoProveedor, action.payload] }
            )
                
        case 'REMOVE_LISTADO_PROVEEDOR':
            const listadoPro = state.listadoProveedor.filter( recurso => (recurso.id !== action.payload.id) )
            return (
                typeof state.listadoProveedor === 'undefined' ?  
                {...state } : 
                {...state, listadoProveedor:  listadoPro }
            )

        case 'CLEAR_LISTADO_PROVEEDOR':
            return (
                typeof state.listadoProveedor === 'undefined' ?  
                {...state } :  {...state, listadoProveedor:  [] }
            )
        
        case 'ADD_PEDIDO_CPE':            
            return (
                {...state, pedidoCPE: action.payload } 
            )
        
        case 'CLEAR_PEDIDO_CPE':
            return (
                typeof state.pedidoCPE === 'undefined' ?  
                {...state } :  {...state, pedidoCPE:  [] }
            )
        
        case 'ADD_PEDIDO_GESPORT':
            return (
                {...state, pedidoGesport: action.payload } 
            )
       
        case 'CLEAR_PEDIDO_GESPORT':
            return (
                {...state, pedidoGesport: [] } 
            )

        case 'CREATE_OPERACION':
            break;

        case 'UPDATE_OPERACION':     
            switch(action.payload){
                case "cpe":
                    return (
                        {
                        ...state, 
                        datosPedido: {},
                        pedidoCPE: [],
                        listadoPersonal: [],
                        listadoMercancias: [],
                        elementoActualizar: null,
                        }
                    )  
                case "gesport":
                    return (
                        {
                        ...state, 
                        listadoGesport: [],
                        pedidoGesport: [],
                        }
                    )
                case "proveedor":
                    return (
                        {...state, listadoProveedor: [] }
                    )

                default: 
                return ( state )
            }
        case 'DELETE_OPERACION':
            return null;
            

        case 'UPDATE_CPE_ELEMENT':
            const PedidoCPE = state.CPE.filter((elemento) => parseInt(elemento.Id) === parseInt(action.payload.elemento))
            if( PedidoCPE.length > 0){
                const pedidoActualizar = {
                    'IdpedidoCPE': PedidoCPE[0].Id,
                    'fechaPedido': PedidoCPE[0].Fecha_Pedido,
                    'manoPlanificada': PedidoCPE[0].ManoPlanificada, 
                    'jornada': PedidoCPE[0].Jornada,
                    'numPedido': PedidoCPE[0].NPedido,
                    'modalidad': PedidoCPE[0].ModalidadTrabajo,
                    'operativa': PedidoCPE[0].FTipoMercancia,
                    'horaInicio': PedidoCPE[0].Hora_Inicio,
                    'horaFin': PedidoCPE[0].Hora_Fin,
                    'horasTrabajadas': PedidoCPE[0].Horas_Trabajadas,
                    'horasExtra': PedidoCPE[0].Horas_Extra,
                    'observaciones': PedidoCPE[0].Observaciones
                }
                return (
                    {...state, elementoActualizar: action.payload, datosPedido: pedidoActualizar } 
                )
            }      
            return (
                {...state, 
                    elementoActualizar: null,
                    datosPedido: {},
                    pedidoCPE: [],
                    listadoPersonal: [],
                    listadoMercancias: [] 
                } 
            )
        case "RELOAD_DETALLE":            
            return (
                {...state, actualizarDetalle: action.payload } 
            )
        default: 
            return state;
    }
}