import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import SearchBar from "../../../../../components/Common/SearchBar";
import FiltroListado from "../../CMO/FiltroListado";
import EjercicioAnual from "../../CMO/EjercicioAnual";
import CategoriasyClientes from "../../CMO/CategoriasyClientes";

import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'


import Graficas from "../../Produccion/Postventa/Graficas";
import { Grid, Typography } from "@material-ui/core";
import RentabilidadXCategorias from "./RentabilidadXCategorias";
import RentabilidadXClientes from "./RentabilidadXClientes";
import InformeOperativas from "./InformeOperativas";

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1, 
      padding: theme.spacing(1),
      margin: '10px',        
      backgroundColor: '#FFF',
      //height: '310px',
  },    
  subroot: {
      flexGrow: 1, 
      padding: theme.spacing(1),
      margin: '10px',        
      backgroundColor: '#FFF',
  },    
  padding16: {
      padding: theme.spacing(1),      
  }, 
  titulo: {
      fontSize: 18,
      fontFamily: 'Open Sans',
      fontWeight: 300,
      color: '#505050'
    }, 
  rootProgress: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    }, 
  fondoBlancoTitulos:{
    backgroundColor: '#FFF',
    margin: '10px', 
    padding: theme.spacing(1),
  }
}));

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
}
  
let date = new Date();
let firstDay = new Date(date.getFullYear(), 0, 1);
let lastDay = date; // ((new Date(date.getFullYear(), date.getMonth() + 1, 0);
firstDay = formatDate(firstDay);
lastDay = formatDate(lastDay);

const General = () => { 
    const classes = useStyles();   
    const [esDirectivo, setEsDirectivo]=useState(false); 
    const [url, setUrl] = useState(`?fechainicial=${firstDay}&fechafinal=${lastDay}`)
    const [datoVentas, setDatosVentas] = useState({
        resumenAnual: {total: 0, porcentaje: 0},
        resumenMensual: [],
        resumenCategorias: [],
        resumenClientes: []        
    }) 
    const [datosInformeRentabilidad, setDatosInformeRentabilidad] = useState([])
    const [datosInformeOperativas, setDatosInformeOperativas] = useState([])
    const [isPrinting, setIsPrinting] = useState(false)
    const [datosEmpresa, setDatosEmpresa] = useState()

    const ejercicioAnual = useRef(null);
    const informeAuditoriaContenedor = useRef(null);
    const informeAuditoriaRodante = useRef(null);
    const informeAuditoriaMGeneral = useRef(null);
    const informeRentabilidadXCategorias = useRef(null);
    const informeRentabilidadXClientes = useRef(null);
    const informeOperativas = useRef(null);  
    const cabeceraInforme = useRef(null);  

    useEffect(() => { 
        const fetchData = async () => {  
          const user = JSON.parse(localStorage.getItem("user"));
          const respdatos = await axios(`${process.env.REACT_APP_API_URL}rrhhdatoslaborales/${user.id}`);   
          const datos = respdatos.data
          if(datos && datos.Puesto_Trabajo && datos.Puesto_Trabajo.Etiqueta && datos.Puesto_Trabajo.Etiqueta === "DIRECTIVO"){
            setEsDirectivo(true)
          } 

          const respdatosEmpresa = await axios(`${process.env.REACT_APP_API_URL}dic/diccionarios/datosempresa`);
          setDatosEmpresa(respdatosEmpresa.data)          
        };    
      fetchData();    
    }, [])

    useEffect(() => { 
        if(esDirectivo){
          const fetchData = async () => { 
            const respdatos = await axios(`${process.env.REACT_APP_API_URL}cuadromandos/situacionactualventas${url}`)   
            setDatosVentas(respdatos.data)        
          };    
          fetchData();    
        }
    }, [esDirectivo, url])

    const handleUrlFecha = (url) =>{  
        setUrl(url)     
    }

    const handleRentabilidadXCategoriasData = (data) => {          
    }

    const handleRentabilidadXClientesData = (data) => {      
      setDatosInformeRentabilidad(data)      
    }

    const handleInformeOperativas = (data) => {
      setDatosInformeOperativas(data)
    }

    const handlePrintGeneralReport = async (url) => {
      setIsPrinting(true)
      const pdf = new jsPDF("l", "mm", "a4");
      const imgWidth = 295; 
      const pageHeight = 210;
      let position = 5;


      pdf.setProperties({
        title: "Informe General"
      });

      pdf.setFontSize(12);
      pdf.setTextColor(50,50,50, 0.5);

      const cabeceraInformeElement = await html2canvas(cabeceraInforme.current);     
      const imageCabeceraInforme = cabeceraInformeElement.toDataURL("image/png", 1.0);
      let imgHeightCabecera = cabeceraInformeElement.height * imgWidth / cabeceraInformeElement.width;
      pdf.addImage(imageCabeceraInforme, "JPEG", 0, position, imgWidth, imgHeightCabecera, '', 'NONE');

      position += 30;
      const ejercicioAnualElement = await html2canvas(ejercicioAnual.current);     
      const imageEjercicioAnual = ejercicioAnualElement.toDataURL("image/png", 1.0);
      let imgHeight = ejercicioAnualElement.height * imgWidth / ejercicioAnualElement.width;
      pdf.addImage(imageEjercicioAnual, "JPEG", 0, position, imgWidth, imgHeight, '', 'NONE');

      position = 10 
      pdf.addPage();
      const informeAuditoriaContenedorElement = await html2canvas(informeAuditoriaContenedor.current);
      const imageInformeAuditoriaContenedor = informeAuditoriaContenedorElement.toDataURL("image/png", 1.0);
      imgHeight = informeAuditoriaContenedorElement.height * imgWidth / informeAuditoriaContenedorElement.width;
      pdf.addImage(imageInformeAuditoriaContenedor, "JPEG", 0, position, imgWidth, imgHeight, '', 'NONE');
     
      
      pdf.addPage();
      const informeAuditoriaMGeneralElement = await html2canvas(informeAuditoriaMGeneral.current);
      const imageInformeAuditoriaMGeneral = informeAuditoriaMGeneralElement.toDataURL("image/png", 1.0);
      imgHeight = informeAuditoriaMGeneralElement.height * imgWidth / informeAuditoriaMGeneralElement.width;
      pdf.addImage(imageInformeAuditoriaMGeneral,"JPEG",0, position, imgWidth, imgHeight );      
      
      position += imgHeight;
      const informeAuditoriaRodanteElement = await html2canvas(informeAuditoriaRodante.current);
      const imageInformeAuditoriaRodante = informeAuditoriaRodanteElement.toDataURL("image/png", 1.0);
      imgHeight = informeAuditoriaRodanteElement.height * imgWidth / informeAuditoriaRodanteElement.width;
      pdf.addImage(imageInformeAuditoriaRodante,"JPEG",0, position, imgWidth, imgHeight );     
      
      pdf.setFontSize(12);
      pdf.setTextColor(50,50,50, 0.5);

      
      pdf.addPage("a4", "l");
      pdf.text(15, 10, "Ventas por categorías");
      autoTable(pdf, { html: '#tablaCategorias', styles: { marginTop: 20, cellPadding: 0.5, fontSize: 10 } })

      pdf.addPage("a4", "l");
      pdf.text(15, 10, "Ventas por clientes");
      autoTable(pdf, { html: '#tablaClientes', styles: { cellPadding: 0.5, fontSize: 10 } })

      pdf.addPage("a4", "l"); 
      pdf.text(15, 10, "Rentabilidad de operativas");
      autoTable(pdf, {        
        body: datosInformeOperativas,
        columns: [          
          { header: 'Código', dataKey: 'Codigo' },
          { header: 'Clientes', dataKey: 'Cuentas' },
          { header: 'Localización', dataKey: 'Localizacion' },
          { header: 'Buque', dataKey: 'Buque' },
          { header: 'Fecha Operativa', dataKey: 'Fecha_Operativa' },
          { header: 'Jornada', dataKey: 'Jrnadas' },
          { header: 'Ventas €', dataKey: 'ventas' },
          { header: 'Costes €', dataKey: 'costes' },
          { header: 'Margen Bruto €', dataKey: 'margenBrutoImp' },
          { header: 'Margen Bruto %', dataKey: 'margenBrutoPor' }
        ],
      })

    
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      pdf.save(`informe-general-${day}-${month}-${year}.pdf`);
      setIsPrinting(false)
    }

    return (
    <div className="col-main-home ">            
        <SearchBar
            titulo="INICIO"
            claseCabecera = "bg-comercial"
            buscar={false}
            imagenCabecera={"1-1-cuadro-de-mandos.png"}  
        />   

        <div className="bg-cuerpo p-10px">
        <div className="bg-white cuerpo-h-max">   
          <div className="principal bg-cuerpo cuerpo-h-max overflow-auto overflow-hiddens table-responsive mx-auto">
          {esDirectivo && (
            <>              
              <FiltroListado urlfecha={handleUrlFecha} firstDay={firstDay} lastDay={lastDay} showPrintButton={true} handlePrintReport={handlePrintGeneralReport}/>
              <div>
                {isPrinting && (
                  <div className={classes.rootProgress}>
                    <CircularProgress />
                  </div>
                )}
                
                <Grid ref={cabeceraInforme} container spacing={0}>
                  <div className={classes.subroot}>
                    <div align="left" style={{width: '70%', float: 'left', height: '110px'}}>
                        <div style={{marginBottom: '0px'}}>                        
                            <img src={`${process.env.REACT_APP_IMAGES}logo_gesport_peq.png`} width="64" alt="imagen-mtms" id="logo-mtms" style={{marginRight: '20px', marginTop: '0px'}} />
                            <span className="m bold">{datosEmpresa?.nombre}</span><br />
                        </div>

                        <div style={{marginBottom: '0px'}}>
                            <span className="m">{datosEmpresa?.cif}</span><br />            
                            <span className="xs">{datosEmpresa?.direccion}<br />{datosEmpresa?.cp} - {datosEmpresa?.ciudad}<br /></span>                      
                            <span className="xs">{datosEmpresa?.telefono}<br />{datosEmpresa?.email}</span>      
                        </div>      
                    </div>
            
                    <div align="left" style={{width: '30%', float: 'right'}}>
                        <div style={{textAlign: 'center', height: '36px', width:'178px', backgroundColor: '#c4c4c4', borderRadius: '5px', color: '#000'}}>
                            <span className="titulo">INFORME GENERAL</span><br />
                            <span className="titulo">DEL {firstDay} AL {lastDay}</span>
                        </div>
                    </div>
                  </div> 
                </Grid>

                <Grid ref={ejercicioAnual} container spacing={0}>
                  <Grid item xs={12} className="">
                    <EjercicioAnual ventas={datoVentas.resumenMensual} />
                  </Grid>
                  <Grid item xs={12} className="">
                    <CategoriasyClientes ventasPorCategorias={datoVentas.resumenCategorias} ventasPorClientes={datoVentas.resumenClientes}/>            
                  </Grid>
                </Grid>

                <div ref={informeAuditoriaContenedor} className={classes.subroot}>    
                  <div className={classes.padding16}>      
                    <Typography className={classes.titulo}>Informe Auditoría Contenedor</Typography> 
                    <hr></hr>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Graficas subOperacion={'Contenedor'} fechaInicial={firstDay} fechaFinal={lastDay} showPrintButton={false} showLabelValues={true}/>
                    </div>
                  </div>
                </div>

                <div ref={informeAuditoriaMGeneral} className={classes.subroot}>    
                  <div className={classes.padding16}>      
                    <Typography className={classes.titulo}>Informe Auditoría Mercancía General</Typography> 
                    <hr></hr>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Graficas subOperacion={'mGeneral'} fechaInicial={firstDay} fechaFinal={lastDay} showPrintButton={false} showLabelValues={true}/>
                    </div>
                  </div>
                </div>

                <div ref={informeAuditoriaRodante} className={classes.subroot}>    
                  <div className={classes.padding16}>      
                    <Typography className={classes.titulo}>Informe Auditoría Rodante</Typography> 
                    <hr></hr>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Graficas subOperacion={'Rodante'} fechaInicial={firstDay} fechaFinal={lastDay} showPrintButton={false} showLabelValues={true}/>
                    </div>
                  </div>
                </div>                

                <Grid container spacing={0}>                
                  <Grid item xs={6} className="" ref={informeRentabilidadXCategorias}>
                    <div className={classes.fondoBlancoTitulos}>      
                      <Typography className={classes.titulo}>Ventas por categorías</Typography> 
                      <hr></hr>                    
                    <RentabilidadXCategorias filtroFechas={url} returnData={handleRentabilidadXCategoriasData}/>
                    </div>
                  </Grid>

                  <Grid item xs={6} className="" ref={informeRentabilidadXClientes}>
                    <div className={classes.fondoBlancoTitulos}>      
                      <Typography className={classes.titulo}>Ventas por Clientes</Typography> 
                      <hr></hr>   
                      <RentabilidadXClientes filtroFechas={url} returnData={handleRentabilidadXClientesData}/>
                    </div>
                  </Grid>
                </Grid>

                <Grid ref={informeOperativas} container spacing={0}>
                  <Grid item xs={12} className="" ref={informeOperativas}>
                    <div className={classes.fondoBlancoTitulos}>      
                      <Typography className={classes.titulo}>Rentabilidad de Operativas</Typography> 
                      <hr></hr>   
                      <InformeOperativas urlInforme={'operativas'} filtroFechas={url} returnData={handleInformeOperativas}/>
                    </div>
                  </Grid>
                </Grid>
              </div> 
            </>
          )}

          </div> 
        </div>
      </div>
    </div> 
    )
}
export default General;

