import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'


const FichaDocumentacion = () => {
    let { id } = useParams();

    const [documentacion, setSeleccion] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSeleccion = await axios(`${process.env.REACT_APP_API_URL}rrhhdocumentacions/${id}`);    
          setSeleccion(respSeleccion.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(documentacion).length > 0){  
        
            setCampos([
            {
                classsLabel: "",
                label: "Nombre:",
                value: documentacion.Nombre,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Descripción:",
                value: documentacion.Descripcion,
                classText: classText,
                xs:12
            },
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: documentacion.ficheros,
                 classText: classText,
                 type: "file",
                 xs:4
                },
            
        ])
    }
},[documentacion]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/rrhh/talento/documentacion/${id}/W`}
    />
    </>
  )
}
export default FichaDocumentacion;

