import React, { useState } from "react";
import axios from "axios";
import FiltroListado from "../Facturas/FiltroListado";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import { columnsFacturasVentas } from "./CobrosPagosConf";
import Opciones from "../../Comercial/Ventas/Opciones";

const Cobros = () => {
    const [url, setUrl] = useState("")
    const [filaSeleccionada, setFilaSeleccionada] = useState(-1);    
    const [actualizar, setActualizar] = useState(false)

    const handleUrlFecha = (url) =>{ 
        setUrl(url)     
    }

    const marcarFila = (fila) => {
        setFilaSeleccionada(fila);
    }

    const handleOperacion = (idO) =>{          
        //localStorage.setItem('idTipoFacturaRecurrente', idO);     
    }

    const columnaEstado = {
        name: "Estado",
        label: "Estado",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <Opciones
                        value={value}
                        index={tableMeta.columnIndex}
                        opciones = {["Abierta", "Enviada", "Cobrada"]}
                        change={event => {
                            updateValue(event)
                            actualizaValor(event, tableMeta.rowData[0], tableMeta.rowData[1])
                        }}
                    />
                );
            },  
        }   
    }

    const actualizaValor = (valor, indice, tipo) => {
        let nuevoEstado = 1;
        let postUrl = "";
        if(valor === "Abierta") nuevoEstado = 1;
        if(valor === "Enviada") nuevoEstado = 2;
        if(valor === "Cobrada") nuevoEstado = 3;
        if(tipo === "ÚNICA") postUrl = "facturasunicas/estableceestado";
        if(tipo === "RECTIFICATIVA") postUrl = "facturasrectificativas/estableceestado";
        if(tipo === "RECURRENTE") postUrl = "facturasrecurrentes/estableceestado";

        const fetchData = async () => {
            setActualizar(false)
            let datos = {id: indice, estado: nuevoEstado};
            let user = JSON.parse(localStorage.getItem("user"));
            const AuthStr = "Bearer ".concat(user.access_token);
            await axios.put( 
                process.env.REACT_APP_API_URL + postUrl,
                { 
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: AuthStr,
                    },
                    datos
                }
            ).then((response) => {
                if(response.data){
                    setActualizar(true)
                }
            })
            .catch((error) => {
                console.log("error " + error);
                console.log(error.response.data); // => the response payload 
                console.log(error.response.data.message)                
            });
            //setActualizar(false);
        };
        fetchData();
        
    }


    const columnasCuerpoTabla = [...columnsFacturasVentas, columnaEstado];
    const urlDatos = "dic/diccionarios/listadocompletofacturasventaspendientes" + url
    const urlDoubleClickCuerpoTabla = ""
    const columnIdCuerpoTabla = "Id";
    const filtroToolbar = false
    const filtroToolbarOpciones = []
    const tipoFacturaRecurrente = ""
    
    const footer = [
        {campo: 'Base_Imponible', titulo: 'Base Imponible'},
        {campo: 'Total_Impuestos', titulo: 'Impuestos'},
        {campo: 'IRPF', titulo: 'Irpf'},
        {campo: 'Total_Factura', titulo: 'Total'},
    ]
    //console.log(urlDatos)
    return (
        <>         
        <FiltroListado urlfecha={handleUrlFecha}/>
        <CuerpoTablaPiezas
            columnas = {columnasCuerpoTabla}
            urlDatos = {urlDatos}
            urlDoubleClick = {urlDoubleClickCuerpoTabla}
            columnId = {columnIdCuerpoTabla}
            getFilaSeleccionada={marcarFila}

            filtroToolbar={filtroToolbar}
            filtroToolbarOpciones = {filtroToolbarOpciones}
            filtroToolbarValor = {tipoFacturaRecurrente}
            filtroToolbarChangeValor={handleOperacion}
            recargar={actualizar}
            customFooter={footer} 
            filtroEjercicio = {true}
        /> 
        </>
   
    )
}

export default Cobros;