import React from "react";
import { TEXT_LABELS} from "../../../../../configuraciones/conf"
import AddButtonToolbar from "../../../../../components/Common/AddButtonToolBar"
import paisesJSON from "../../../../../datos/mtms_dic_paises.json";
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const COLUMNS_BANCOS = [  
    {
      name: "Id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
       }
    },
    {
     name: "Entidad",
     label: "Entidad",
     options: {
      filter: true,
      sort: true,     
     }
    },
    {
     name: "Direccion",
     label: "Dirección",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "N_Cuenta_Corriente",
      label: "Numero Cuenta Corriente",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
     name: "IBAN",
     label: "IBAN",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "Editar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => (          
            <AddButtonToolbar 
              title = "Formulario Bancos"              
              type="edit"
              urlForm = "cuenta/bancos"
              action = {() => window.location.reload()}
              urlGoto = "/crm/cuentas/cuenta/"
              showDeleteDialog = {true}
              campos = {[
                {
                  required: true,
                  disabled: false,
                  label: "Entidad:",
                  name: "Entidad",
                  type: "text",    
                  value: tableMeta.rowData[1],
                  xs:6
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Dirección Sucursal:",
                  name: "Direccion",
                  type: "text",    
                  value: tableMeta.rowData[2],
                  xs:12
                }, 
                {
                  required: true,
                  disabled: false,
                  label: "Nº Cuenta Corriente:",
                  name: "N_Cuenta_Corriente",
                  type: "text",    
                  value: tableMeta.rowData[3],
                  xs:6
                },
                {
                  required: true,
                  disabled: false,
                  label: "Código IBAN:",
                  name: "IBAN",
                  type: "text",    
                  value: tableMeta.rowData[4],
                  xs:6
                },]}
              value={tableMeta.rowData[0]}
            /> 
                       
        )
      }
    },
    {
      name: "Ver",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => (          
            <AddButtonToolbar 
              title = "Formulario Bancos"
              toolTipTitle="Ver"
              type="view"
              urlForm = "cuenta/bancos"
              action = "refrescarBancos"
              urlGoto = "/crm/cuentas/cuenta/"
              campos = {[
                {
                  required: true,
                  disabled: true,
                  label: "Entidad:",
                  name: "Entidad",
                  type: "text",    
                  value: tableMeta.rowData[1],
                  xs:6
                }, 
                {
                  required: true,
                  disabled: true,
                  label: "Dirección Sucursal:",
                  name: "Direccion",
                  type: "text",    
                  value: tableMeta.rowData[2],
                  xs:12
                }, 
                {
                  required: true,
                  disabled: true,
                  label: "Nº Cuenta Corriente:",
                  name: "N_Cuenta_Corriente",
                  type: "text",    
                  value: tableMeta.rowData[3],
                  xs:6
                },
                {
                  required: true,
                  disabled: true,
                  label: "Código IBAN:",
                  name: "IBAN",
                  type: "text",    
                  value: tableMeta.rowData[4],
                  xs:6
                },]}
              value={tableMeta.rowData[0]}
            /> 
                       
        )
      }
    },
];
export {COLUMNS_BANCOS}
  
const CAMPOS_BANCOS = [
  {
    required: true,
    disabled: false,
    label: "Entidad:",
    name: "Entidad",
    type: "text",    
    value: "",
    xs:6
  }, 
  {
    required: false,
    disabled: false,
    label: "Dirección Sucursal:",
    name: "Direccion",
    type: "text",    
    value: "",
    xs:12
  }, 
  {
    required: true,
    disabled: false,
    label: "Nº Cuenta Corriente:",
    name: "N_Cuenta_Corriente",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: true,
    disabled: false,
    label: "Código IBAN:",
    name: "IBAN",
    type: "text",    
    value: "",
    xs:6
  },

]

const CAMPOS_DIRECCIONES = [
  {
    required: true,
    disabled:false,
    label: "Tipología:",
    name: "Tipologia",
    type: "select",    
    value: "",
    xs:6,
    opciones: [
      {Id: 1, Etiqueta: 'Dirección comercial'},
      {Id: 2, Etiqueta: 'Dirección fiscal'},
    ],
  }, 
  {
    required: true,
    disabled:false,
    label: "Calle:",
    name: "Calle",
    type: "text",    
    value: "",
    xs:12
  }, 
  {
    required: true,
    disabled:false,
    label: "Código Postal:",
    name: "Codigo_Postal",
    type: "text",    
    value: "",
    xs:6
  },  
  {
    required: true,
    disabled:false,
    label: "Ciudad:",
    name: "Ciudad",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: true,
    disabled:false,
    label: "Provincia:",
    name: "Provincia",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: true,
    disabled:false,
    label: "País:",
    name: "Pais",
    type: "select",  
    value: "",  
    opciones:paisesJSON,
    xs:6
  },
  {
    required: false,
    disabled:false,
    label: "Letra:",
    name: "Letra",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: true,
    disabled:false,
    label: "Número:",
    name: "Numero",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: false,
    disabled:false,
    label: "Piso:",
    name: "Piso",
    type: "text",    
    value: "",
    xs:6
  },
]

const CAMPOS_CONTACTOS = [  
  {
    required: true,    
    disabled: false,
    label: "Nombre:",
    name: "Nombre",
    type: "text",    
    value: "",    
    xs:6
  }, 
  {
    required: false,
    disabled: false,
    label: "Apellidos:",
    name: "Apellidos",
    type: "text",    
    value: "",
    xs:12
  }, 
  {
    required: false,
    disabled: false,
    label: "Departamento:",
    name: "Departamento",
    type: "text",    
    value: "",
    xs:6
  },  
  {
    required: false,
    disabled: false,
    label: "Cargo:",
    name: "Cargo",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: false,
    disabled: false,
    label: "Movil:",
    name: "Movil",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: false,
    disabled: false,
    label: "Email:",
    name: "Email",
    type: "text",  
    value: "", 
    xs:6
  },
  {
    required: false,
    disabled: false,
    label: "Fecha de Nacimiento:",
    name: "FechaNacimiento",
    type: "date",    
    value: "",
    xs:6
  },
  {
    required: false,
    disabled: false,
    label: "Linkedin:",
    name: "Linkedin",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: true,
    disabled: false,
    label: "Teléfono:",
    name: "Telefono",
    type: "text",    
    value: "",
    xs:6
  },
  {
    required: false,
    disabled: false,
    label: "Añadir a agenda personal:",
    name: "agendapersonal",
    type: "switch",    
    value: "",
    xs:6
  },
]
export {CAMPOS_CONTACTOS}
  
const OPTIONS_BANCOS = {
    filterType: 'checkbox',
    print: false,
    download: false,
    pagination: false,
    selectableRows: 'single',
    customToolbar: () => {
      return (
        <AddButtonToolbar 
        title = "Formulario Bancos"
        urlForm = "cuenta/bancos"
        action = {() => window.location.reload()}
        urlGoto = "/crm/cuentas/cuenta/"
        campos = {CAMPOS_BANCOS}
        toolTipTitle = "Añadir cuenta bancaria"
        />
      );
    },
    
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    expandableRowsHeader: false,  
    elevation:0,
    textLabels: TEXT_LABELS
};
export {OPTIONS_BANCOS}
  
const COLUMNS_CONTACTOS = [
  {
    name: "Id",
    label: "Id",
    options: {
      filter: false,
      sort: false,
      display: 'excluded',
     }
  },
    {
     name: "Nombre",
     label: "Nombre",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "Apellidos",
     label: "Apellidos",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "Departamento",
     label: "Departamento",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "Cargo",
     label: "Cargo",
     options: {
      filter: true,
      sort: true,
     }   
    },
    {
      name: "Movil",
      label: "Movil",
      options: {
       filter: true,
       sort: true,
      }   
     },
     {
      name: "Email",
      label: "Email",
      options: {
       filter: true,
       sort: true,
      }   
     },
     {
      name: "FechaNacimiento",
      label: "Fecha de Nacimiento",
      options: {
       filter: true,
       sort: true,
       display: false
      }   
     },
     {
      name: "Linkedin",
      label: "Linkedin",
      options: {
       filter: true,
       sort: true,
       display: false
      }   
     },
     {
      name: "Telefono",
      label: "Teléfono",
      options: {
       filter: true,
       sort: true,
       display: false
      }   
     },
     {
      name: "Editar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => (          
            <AddButtonToolbar 
              title = "Formulario Contactos"
              type="edit"
              urlForm = "contactos"
              action = {() => window.location.reload()}
              urlGoto = "/crm/cuentas/cuenta/"
              showDeleteDialog = {true}
              campos = {[
                {
                  required: true,
                  disabled: false,
                  label: "Nombre:",
                  name: "Nombre",
                  type: "text",    
                  value: tableMeta.rowData[1],
                  xs:6
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Apellidos:",
                  name: "Apellidos",
                  type: "text",    
                  value: tableMeta.rowData[2],
                  xs:12
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Departamento:",
                  name: "Departamento",
                  type: "text",    
                  value: tableMeta.rowData[3],
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Cargo:",
                  name: "Cargo",
                  type: "text",    
                  value: tableMeta.rowData[4],
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Móvil:",
                  name: "Movil",
                  type: "text",    
                  value: tableMeta.rowData[5],
                  xs:6
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Email:",
                  name: "Email",
                  type: "text",    
                  value: tableMeta.rowData[6],
                  xs:12
                }, 
                {
                  required: false,
                  disabled: false,
                  label: "Fecha de Nacimiento:",
                  name: "FechaNacimiento",
                  type: "text",    
                  value: tableMeta.rowData[7],
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Linkedin:",
                  name: "Linkedin",
                  type: "text",    
                  value: tableMeta.rowData[8],
                  xs:6
                },
                {
                  required: true,
                  disabled: false,
                  label: "Teléfono:",
                  name: "Telefono",
                  type: "text",    
                  value: tableMeta.rowData[9],
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Añadir a agenda personal:",
                  name: "agendapersonal",
                  type: "switch",    
                  value: tableMeta.rowData[10],
                  xs:6
                },
                ]}
              value={tableMeta.rowData[0]}
            /> 
                       
        )
      }
    },
    {
      name: "Ver",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => (          
            <AddButtonToolbar 
              title = "Formulario Contactos"
              toolTipTitle="Ver"
              type="view"
              urlForm = "contactos"
              action = "refrescarContactos"
              urlGoto = "/crm/cuentas/cuenta/"
              campos = {[
                {
                  required: true,
                  disabled: true,
                  label: "Nombre:",
                  name: "Nombre",
                  type: "text",    
                  value: tableMeta.rowData[1],
                  xs:6
                }, 
                {
                  required: false,
                  disabled: true,
                  label: "Apellidos:",
                  name: "Apellidos",
                  type: "text",    
                  value: tableMeta.rowData[2],
                  xs:12
                }, 
                {
                  required: false,
                  disabled: true,
                  label: "Departamento:",
                  name: "Departamento",
                  type: "text",    
                  value: tableMeta.rowData[3],
                  xs:6
                },
                {
                  required: false,
                  disabled: true,
                  label: "Cargo:",
                  name: "Cargo",
                  type: "text",    
                  value: tableMeta.rowData[4],
                  xs:6
                },
                {
                  required: false,
                  disabled: true,
                  label: "Móvil:",
                  name: "Movil",
                  type: "text",    
                  value: tableMeta.rowData[5],
                  xs:6
                }, 
                {
                  required: false,
                  disabled: true,
                  label: "Email:",
                  name: "Email",
                  type: "text",    
                  value: tableMeta.rowData[6],
                  xs:12
                }, 
                {
                  required: false,
                  disabled: true,
                  label: "Fecha de Nacimiento:",
                  name: "FechaNacimiento",
                  type: "text",    
                  value: tableMeta.rowData[7],
                  xs:6
                },
                {
                  required: false,
                  disabled: true,
                  label: "Linkedin:",
                  name: "Linkedin",
                  type: "text",    
                  value: tableMeta.rowData[8],
                  xs:6
                },
                {
                  required: true,
                  disabled: true,
                  label: "Teléfono:",
                  name: "Telefono",
                  type: "text",    
                  value: tableMeta.rowData[9],
                  xs:6
                },
                {
                  required: false,
                  disabled: true,
                  label: "Añadir a agenda personal:",
                  name: "agendapersonal",
                  type: "switch",    
                  value: tableMeta.rowData[10],
                  xs:6
                },
                ]}
              value={tableMeta.rowData[0]}
            /> 
                       
        )
      }
    },
];
export {COLUMNS_CONTACTOS}

const OPTIONS_CONTACTOS = {
    filterType: 'checkbox',
    print: false,
    download: false,
    pagination: false,
    selectableRows: 'single',
    expandableRows: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    elevation:0,
    customToolbar: () => {
      return (
        <AddButtonToolbar 
        title = "Formulario Contactos"        
        urlForm = "contactos"
        action = {() => window.location.reload()}
        urlGoto = "/crm/cuentas/cuenta/"
        campos = {CAMPOS_CONTACTOS}
        toolTipTitle = "Crear contacto"
        type = "add"
        
        />
      );
    },    
  textLabels: TEXT_LABELS
};
export {OPTIONS_CONTACTOS}

const COLUMNS_DIRECCIONES = [  
    {
      name: "Id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      }
    },
    {
      name: "Tipologia",
      label: "Tipología",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
     name: "Calle",
     label: "Calle",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "Codigo_Postal",
      label: "Código Postal",
      options: {
       filter: true,
       sort: true,
      }
     },
    {
     name: "Ciudad",
     label: "Ciudad",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "Provincia",
      label: "Provincia",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Pais",
      label: "País",
      options: {
       filter: true,
       sort: true,
      }
     },
     {
      name: "Letra",
      label: "Letra",
      options: {
       filter: false,
       sort: false,display: 'excluded',
      }
     },
     {
      name: "Numero",
      label: "Número",
      options: {
       filter: false,
       sort: false,display: 'excluded',
      }
     },
     {
      name: "Piso",
      label: "Piso",
      options: {
       filter: false,
       sort: false,
       display: 'excluded',
      }
     },
     {
      name: "Fk_Tipologia",
      label: "IdTipologia",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      }
    },
    {
      name: "Fk_Pais",
      label: "IdPais",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      }
    },
    {
      name: "PaisAF",
      label: "PaisAF",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      }
    },
     {
      name: "Editar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => ( 
            <AddButtonToolbar 
              title = "Formulario Direcciones"
              type="edit"
              urlForm = "cuenta/direcciones"
              action = {() => window.location.reload()}
              urlGoto = "/crm/cuentas/cuenta/"
              campos = {[
                {
                  required: true,
                  label: "Tipología:",
                  name: "Tipologia",
                  type: "select",   
                  disabled: false, 
                  value: tableMeta.rowData[10],
                  opciones: [
                    {Id: 1, Etiqueta: 'Dirección comercial'},
                    {Id: 2, Etiqueta: 'Dirección fiscal'},
                  ],
                  xs:6
                }, 
                {
                  required: true,
                  label: "Calle:",
                  name: "Calle",
                  type: "text",    
                  disabled: false,
                  value: tableMeta.rowData[2],
                  xs:12
                }, 
                {
                  required: true,
                  label: "Código Postal:",
                  name: "Codigo_Postal",
                  type: "text",  
                  disabled: false,  
                  value: tableMeta.rowData[3],
                  xs:6
                },  
                {
                  required: true,
                  label: "Ciudad:",
                  name: "Ciudad",
                  type: "text",   
                  disabled: false, 
                  value: tableMeta.rowData[4],
                  xs:6
                },
                {
                  required: true,
                  label: "Provincia:",
                  name: "Provincia",
                  type: "text",    
                  disabled: false,
                  value: tableMeta.rowData[5],
                  xs:6
                },
                {
                  urlData: "dic/diccionarios/paises",
                  required: true,
                  label: "País:",
                  name: "Pais",
                  type: "combo", 
                  campoEtiqueta: "etiqueta",
                  disabled: false,
                  value: tableMeta.rowData[12],  
                  //options:paisesJSON,
                  xs:6
                },
                {
                  required: false,
                  label: "Letra:",
                  name: "Letra",
                  type: "text",  
                  disabled: false,  
                  value: tableMeta.rowData[7],
                  xs:6
                },
                {
                  required: true,
                  label: "Número:",
                  name: "Numero",
                  type: "text",    
                  disabled: false,
                  value: tableMeta.rowData[8],
                  xs:6
                },
                {
                  required: false,
                  disabled: false,
                  label: "Piso:",
                  name: "Piso",
                  type: "text",    
                  value: tableMeta.rowData[9],
                  xs:6
                },                
              ]}
              value={tableMeta.rowData[0]}
              showDeleteDialog = {true}
            />                        
        )
      }    
    },
    {
      name: "Ver",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => ( 
            <AddButtonToolbar 
              title = "Formulario Direcciones"
              toolTipTitle = "Ver"
              type="view"
              urlForm = "cuenta/direcciones"
              action = ""
              urlGoto = "/crm/cuentas/cuenta/"
              campos = {[
                {
                  required: true,
                  label: "Tipología:",
                  name: "Tipologia",
                  type: "select",    
                  disabled: true,
                  value: tableMeta.rowData[10],
                  opciones: [
                    {Id: 1, Etiqueta: 'Dirección comercial'},
                    {Id: 2, Etiqueta: 'Dirección fiscal'},
                  ],
                  xs:6
                }, 
                {
                  required: true,
                  label: "Calle:",
                  name: "Calle",
                  type: "text",    
                  disabled: true,
                  value: tableMeta.rowData[2],
                  xs:12
                }, 
                {
                  required: true,
                  label: "Código Postal:",
                  name: "Codigo_Postal",
                  type: "text",       
                  disabled: true,
                  value: tableMeta.rowData[3],
                  xs:6
                },  
                {
                  required: true,
                  label: "Ciudad:",
                  name: "Ciudad",
                  type: "text",       
                  disabled: true,
                  value: tableMeta.rowData[4],
                  xs:6
                },
                {
                  required: true,
                  label: "Provincia:",
                  name: "Provincia",
                  type: "text",       
                  disabled: true,
                  value: tableMeta.rowData[5],
                  xs:6
                },
                {
                  urlData: "dic/diccionarios/paises",
                  required: true,
                  label: "País:",
                  name: "Pais",
                  type: "combo", 
                  campoEtiqueta: "etiqueta",
                  disabled: true,
                  value: tableMeta.rowData[12],  
                  xs:6
                },
                {
                  required: true,
                  label: "Letra:",
                  name: "Letra",
                  type: "text",    
                  disabled: true,
                  value: tableMeta.rowData[7],
                  xs:6
                },
                {
                  required: true,
                  label: "Número:",
                  name: "Numero",
                  type: "text",       
                  disabled: true,
                  value: tableMeta.rowData[8],
                  xs:6
                },
                {
                  required: true,
                  label: "Piso:",
                  name: "Piso",
                  type: "text",     
                  disabled: true,  
                  value: tableMeta.rowData[9],
                  xs:6
                },                
                ]}
              value={tableMeta.rowData[0]}
            />                        
        )
      }
    }, 
    /* 
    {
      name: "Eliminar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => ( 
          <DeleteIcon onClick={() => {            
            console.log(tableMeta.rowData[0])
            //.log(value, tableMeta, updateValue)
          }}/>
        )
      }
    }, 
    */  
];
export {COLUMNS_DIRECCIONES}

const OPTIONS_DIRECCIONES = {
    filterType: 'checkbox',
    print: false,
    download: false,
    pagination: false,
    selectableRows: 'single',
    expandableRows: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    elevation:0,
    
    customToolbar: () => {
      return (
        <AddButtonToolbar 
        title = "Formulario Direcciones"
        urlForm = "cuenta/direcciones"
        action = {() => window.location.reload()}
        urlGoto = "/crm/cuentas/cuenta/"
        campos = {CAMPOS_DIRECCIONES}
        toolTipTitle = "Añadir dirección"
        type="add"
        />
      );
    },    
    textLabels: TEXT_LABELS
};
export {OPTIONS_DIRECCIONES}




