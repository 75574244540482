import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Controller } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

const FormFieldSwitch = ({ propiedades, control, onChangeValue=null }) => {  

    const [valor, setValor] = useState((parseInt(propiedades.value) === 1 || propiedades.value === true) ? true : false)
    const { modo } = useParams();
    const deshabilitado = (
        typeof(propiedades.disabled) === "undefined" ? 
        modo==="R" ?  true : false : propiedades.disabled
    )

    useEffect(() => {
      
      setValor((parseInt(propiedades.value) === 1 || propiedades.value === true) ? true : false)
      return () => {
        
      }
    }, [propiedades.value])
      
    const handleChange = (value) => {       
      if(onChangeValue)
        onChangeValue(value)

      if(propiedades.onChangeValue)
        propiedades.onChangeValue(value) 
        
      setValor(value)       
    };

    return (               
    <Grid item xs={propiedades.xs} className={propiedades.classes} > 
      <FormControlLabel   
        control={
        <Controller
            control={control}
            name={propiedades.name}
            defaultValue={valor} 
           
            render={({ onChange, value={valor}, name}) => (         
              <Switch  
                  onChange={(e) => {
                    handleChange(e.target.checked)
                    onChange(e.target.checked)                    
                  }}   
                  checked={valor}    
                  color="primary" 
                  disabled = {deshabilitado} //{propiedades.disabled}
                />
            )}
          />
        }
        label={propiedades.label}
        labelPlacement="end"
      /> 
    </Grid>       
    )   
}
export default FormFieldSwitch;