import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import FormFieldNumberUncontrolled from './FormFieldNumberUncontrolled'
import FormFieldAutoCompleteUncontrolled from './FormFieldAutoCompleteUncontrolled'
import AlertaMensaje from './AlertaMensaje'
import TablaPedidoProductos from "./TablaPedidoProductos";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },  
}));

const FormularioPedidoProductos = ({urlGetData, urlPostData}) => { 
    const { id, modo } = useParams();
    const methods = useForm();  
    const classes = useStyles();;
    const { handleSubmit } = useForm(); 
    const [openAlert, setOpenAlert] = useState({estado: false, mensaje: "", tipo:"success"});
    const [listadoProductos, setListadoProductos] = useState([])
    const [isLoading, setIsLoading] = useState(false); 

    /*
    const [campoOperativas, setCampoOerativas] = useState({
      urlData: "dic/diccionarios/operativas",
      campoEtiqueta: "Etiqueta",
      required: true,
      name: "operativa",      
      label: "Operativa",
      value: "",
      multiple: false,      
      xs:4,
    })
    */

    /*
    const [campoCategorias, setCampoCategorias] = useState(
      {
        urlData: "dic/diccionarios/categoriasproducto",
        campoEtiqueta: "Etiqueta",
        required: false,
        name: "categoria",        
        label: "Categoría",
        value: "",
        multiple: false,
        xs:4,
      },
    )
    */
    /*
    const [campoSubcategorias, setCampoSubcategorias] = useState({
      urlData: "dic/diccionarios/subcategoriasproducto/0",
      campoEtiqueta: "Etiqueta",
      required: false,
      name: "subcategoria",      
      label: "Subcategoría",
      value: "",
      multiple: false,
      xs:4,
    })
    */

    const [campoProductos, setCampoProductos] = useState({
      urlData: "productos",
      campoEtiqueta: "nombreCompleto",
      required: true,
      name: "producto",      
      label: "Productos",
      value: "",
      multiple: false,
      xs:12,
    })

    const [campoCantidad, setCampoCantidad] = useState({
      required: true,
      label: "Cantidad",      
      name: "cantidad",
      type: "number",  
      value: '',
      xs:4
    })

    const [campoJornadas, setCampoJornadas] = useState({
      urlData: "dic/diccionarios/listadojornadas",
      campoEtiqueta: "Etiqueta",
      required: true,
      name: "jornada",      
      label: "Jornada",
      value: "",
      multiple: false,      
      xs:4,
    })

    const recargarDatos = () => {
      if(id){
        (async () => {   
          setIsLoading(true)
          const respPedidoCliente = await axios(process.env.REACT_APP_API_URL + `${urlGetData}/${id}`);   
          
          if(respPedidoCliente.data.listadoProductos.length > 0 ){          
            setListadoProductos(respPedidoCliente.data.listadoProductos)
          } 
          setIsLoading(false)
        })();
      }else{
        setOpenAlert({estado: true, mensaje: "Debe estar en modo edición para poder añadir productos", tipo:"error"})
      }
    }

    useEffect(() => {        
      recargarDatos();
      return () => {
        setListadoProductos([])
        setIsLoading(false)
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSubmitPost = async () => {   

      if(!campoProductos.value.idOperativa)
      {
        setOpenAlert({estado: true, mensaje: "El producto que intenta añadir no tiene Operativa definida.", tipo:"error"})
        return;
      }


      let producto = {
        id:uuidv4(), 
        //idOperativa:campoOperativas.value.Id,
        idOperativa: campoProductos.value.idOperativa,
        //operativa:campoOperativas.value.Etiqueta,
        operativa:campoProductos.value.Operativa,
        //categoria: campoCategorias.value ? campoCategorias.value.Etiqueta : "", 
        categoria: campoProductos.value.categoria,
        //subcategoria: campoSubcategorias.value ? campoSubcategorias.value.Etiqueta : "",
        subcategoria: campoProductos.value.Subcategoria,
        idProducto: campoProductos.value.Id,
        //producto: campoProductos.value[campoProductos.campoEtiqueta], 
        producto: campoProductos.value.nombre, 
        cantidad: campoCantidad.value,
        jornada: campoJornadas ? campoJornadas.value ? campoJornadas.value.Codigo + ' ' + campoJornadas.value.Etiqueta : "" : "",  
        //jornadaObject: campoJornadas ? campoJornadas.value ? campoJornadas.value : "" : "",              
      }
   

      let datos = {};
      datos.productoInsertar = producto
      datos.idEntidad = id;

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}`
      
      if (modo==="W") {
        await axios
        .post(`${SERVER_URL}`, {
          headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
          
          if(!isNaN(response.data)){
            if(parseInt(response.data)){ 
              producto.id = response.data
              setListadoProductos([...listadoProductos, producto])
              
              setCampoCantidad({...campoCantidad, value: ""})   
              setOpenAlert({estado: true, mensaje: "¡Producto añadido correctamente!", tipo:"success"})
            }
          }
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
      });
      }else{setOpenAlert({estado: true, mensaje: "Para añadir un producto debe estar en modo edición.", tipo:"warning"})}

    }

   
    const handleChangeComboProducto = (valor) => { 
      setCampoProductos({...campoProductos, value: valor})
    } 

    const handleChangeComboJornada = (valor) => { 
      setCampoJornadas({...campoJornadas, value: valor})
    } 

    const handleChangeComboCantidad = (valor) => { 
      setCampoCantidad({...campoCantidad, value: valor})
    }  

    const handleCloseAlert = (event, reason) => {  
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert({estado: false, mensaje: "", tipo:"success"});
    };

    const handleRemoveElement = async  (element) =>{   

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}`
      if (modo==="W") {      
      await axios
        .delete(`${SERVER_URL}/${element.id}`, {
                headers: {        
                  "Content-Type": "application/json",
                  Authorization: AuthStr,
                },                
        })
        .then((response) => {                  
          if(!isNaN(response.data)){
            if(parseInt(response.data) === parseInt(element.id)){   
              const elementosNoEliminar = listadoProductos.filter(el => el.id !== element.id)                      
              setOpenAlert({estado: true, mensaje: "¡Producto eliminado correctamente!", tipo:"success"})                      
              setListadoProductos(elementosNoEliminar)
            }
          }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
          console.log(error.response.data.message)
        });
      }else{setOpenAlert({estado: true, mensaje: "Para eliminar un producto debe estar en modo edición.", tipo:"warning"})}

      
    }  

    const handleSaveData = async (producto) => {
      
      let datos = {};
      datos.productoActualizar = producto

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}${urlPostData}/${producto.id}`
      
      if (modo==="W") {
        await axios
        .put(`${SERVER_URL}`, {
          headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {       
         
          if(Array.isArray(response.data))
          {
            setListadoProductos(response.data)
            setOpenAlert({estado: true, mensaje: "¡Producto modificado correctamente!", tipo:"success"})
          }
          
          })
          .catch((error) => {
            console.log("error " + error);
            console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
      });
      }else{setOpenAlert({estado: true, mensaje: "Para añadir un producto debe estar en modo edición.", tipo:"warning"})}

      
    }
    
    
    if (!isLoading){
    return (    
    <>
    {/* FORMULARIO PEDIDO PRODICTOS*/}
    <div className={classes.root}>
        <FormProvider {...methods}>
          <form
            id='formulario-pedido-producto'
            onSubmit={handleSubmit((data) => {
            handleSubmitPost(data);
            })}
            className=""
            > 
            <Grid container  spacing={1} className="mt-2"> 
                         
                  <FormFieldAutoCompleteUncontrolled propiedades={campoProductos} onChangeValue={handleChangeComboProducto} />                
                  {urlGetData === "operaciones" && (
                  <FormFieldAutoCompleteUncontrolled propiedades={campoJornadas} onChangeValue={handleChangeComboJornada} />                
                  )}                 
                  <FormFieldNumberUncontrolled propiedades={campoCantidad} onChangeValue={handleChangeComboCantidad} />                

                  <Grid item xs={2} className="" >
                      <button 
                      className="w-100 float-right MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation"                      
                      disabled={modo === "W" && id ? false : true}
                      >
                          AÑADIR
                      </button>                            
                  </Grid>    
                </Grid> 
            <Grid container  spacing={1} className="mt-2">
              <TablaPedidoProductos listadoProductos={listadoProductos} ListadoProductosFinal={handleRemoveElement} actualizarDatos={handleSaveData}/>
            </Grid>          

          </form>
        </FormProvider>  
        {openAlert.estado && 
          <AlertaMensaje 
            mensaje={openAlert.mensaje} 
            isOpen={openAlert.estado} 
            tipoMensaje={openAlert.tipo}
            cerrar={handleCloseAlert}
          />
        }
    </div>  
    </>  
    );
    }
    else {
      return (              
          <div className={classes.linear}>
              <CircularProgress />
          </div>
      );
    }
}
export default FormularioPedidoProductos; 