import React from "react";
import RecursosManoDetalleAuxiliarDesgloce from "./RecursosManoDetalleAuxiliarDesgloce"

const getCosteTotal = (datosJornadas) => {
    let auxiliarPropio = []
    let auxiliarExterno = []

    if(datosJornadas && datosJornadas.length > 0){
        auxiliarPropio = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo auxiliar propio")
        auxiliarExterno = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo auxiliar externo")  
    }
    
    let costeTotalAuxiliar = 0;
    let costeTotalAuxiliarPropio = 0;
    let costeTotalAuxiliarExterno = 0;
    let unidadesTotalAuxiliarPropio=0;
    let unidadesTotalAuxiliarExterno=0;

    auxiliarPropio.forEach(manoObra => {
        costeTotalAuxiliar += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        costeTotalAuxiliarPropio += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        unidadesTotalAuxiliarPropio += parseInt(manoObra.elementos.cantidad)
    });     

    auxiliarExterno.forEach(manoObra => {
        costeTotalAuxiliar += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        costeTotalAuxiliarExterno += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        unidadesTotalAuxiliarExterno += parseInt(manoObra.elementos.cantidad)
    })

    return {
        costeTotalAuxiliar: costeTotalAuxiliar, 
        costeTotalAuxiliarPropio: costeTotalAuxiliarPropio,
        costeTotalAuxiliarExterno: costeTotalAuxiliarExterno,
        unidadesTotalAuxiliarPropio: unidadesTotalAuxiliarPropio,
        unidadesTotalAuxiliarExterno: unidadesTotalAuxiliarExterno,        
    }
}

const RecursosManoDetalleAuxiliarCabecera = ({manos, datosJornadas}) => 
{ 
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);

    const costeTotalAgrupado = getCosteTotal(datosJornadas)
    const costeTotalEquipoAuxiliar = numberFormat2.format(costeTotalAgrupado.costeTotalAuxiliar)    
    
    return (
        <>
        <div className="col-12 mt-4 d-flex overflow-auto justify-content-center">
            <span className="col-11 float-left">Equipo Auxiliar</span><span className="col-1 float-right">{costeTotalEquipoAuxiliar}</span>            
        </div>
        <hr style={{height:1, width:'100%'}} className="m-0 bg-comercial mb-4" />

        {manos &&  manos.map((mano, index) => (
            <React.Fragment key={index}>
            <div className="borderFormulario">
                <div className="col-12 d-flex overflow-auto justify-content-center borderFormulario mb-4">
                    <div className="col-1 float-left">Jornada </div>
                    <div className="col-5 float-right">{mano.Jornada}</div>
                    <div className="col-3 float-left"></div>
                    <div className="col-1 float-left">Mano </div>
                    <div className="col-2 float-right">{mano.Mano}</div>
                </div>

                <RecursosManoDetalleAuxiliarDesgloce 
                    datosJornadas={datosJornadas} 
                    mano = {mano.Mano}              
                />
                
            </div>
            </React.Fragment>
        ))}   
        </>
    )

}

export default RecursosManoDetalleAuxiliarCabecera;