import React from "react";

export default ({ children }) => {
  return (
    <div className="col-8 col-main-home p-0">
      <div className="bg-cuerpo cuerpo-h">Cuerpo Home</div>
      <div className="bg-white h-auto mt-2 p-4">Acciones</div>
    </div>
  );
};
