import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioProductos = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 
    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([]);  
    const [productos, setProductos] = useState([]); 
    const [categoria, setCategoria] = useState(0);
    //const [subcategoria, setSubcategoria] = useState(0);
    const [operativa, setOperativa] = useState("");

    const handleChangeCategoria=(valor) => {
        setCategoria(valor);
    }
    const handleChangeOperativa=(valor) => {
        setOperativa(valor);
    }
    /*
    const handleChangeSubcategoria=(valor) => {
        setSubcategoria(valor);
    }
    */

    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 
        setCampos([
            //0
            {
                urlData: "dic/diccionarios/operativas",
                campoEtiqueta: "Etiqueta",
                required: true,
                name: "operativa",      
                label: "Operativa",
                disabled: false,
                type: "combo",
                value: "",
                multiple: false,      
                xs:4,
                onChangeValue: handleChangeOperativa,
            },
            //1
            {
                urlData: "dic/diccionarios/categoriasproducto",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Categoría:",
                name: "Categoria",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4,
                onChangeValue: handleChangeCategoria,
            },
            //2
            {
                urlData: `dic/diccionarios/subcategoriasproducto/0`,
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Subcategoría:",
                name: "SubCategoria",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4,
                //onChangeValue: handleChangeSubcategoria
            },
            //3
            {
                required: true,
                label: "Nombre producto:",
                name: "Nombre",
                type: "text",  
                value: '',
                xs:12
            },
            //4
            {
                required: false,
                label: "Nombre producto (Inglés):",
                name: "NombreIngles",
                type: "text",  
                value: '',
                xs:12
            },
            //5     
            {
                urlData: "dic/diccionarios/estadosproducto",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Estado:",
                name: "Estado",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            }, 
            //6
            {
                required: true,
                label: "Fecha:",
                name: "Fecha_Alta",
                type: "date",  
                value: '',
                xs:4
            },
            
            //7
            {
                urlData: "dic/diccionarios/unidadproducto",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Unidad:",
                name: "Unidad",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            }, 
            //8
            {
                urlData: "dic/diccionarios/periodicidadproducto",
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Periodicidad:",
                name: "Periodicidad",
                disabled: false,
                type: "combo",
                value: "",              
                xs:3
            }, 
            //9
            {
                required: true,
                label: "Tarifa:",
                name: "Tarifa",
                type: "number",  
                min:0,
                max:999999,
                step: 0.001,
                value: '',
                xs:3
            },
            //10
            {
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Extra coste",
                name: "extraCoste",
                type: "comboArray", 
                options:[{Id: '1', Etiqueta: 'SÍ'}, {Id: '0', Etiqueta: 'NO'}], 
                multiple:false,   
                value: "",
                xs:3
            },  
            //11
            {
                required: false,
                label: "Cod. Poseidón",
                name: "codPoseidon",
                type: "text",  
                value: '',
                xs:3
            },
            //12
            {
                required: false,
                label: "Observaciones",
                name: "Observaciones",
                type: "textArea",
                lineas: 4,                
                value: '',
                xs:12
            },
            //13
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "filesupload",
                type: "filesupload",   
                tabla: "mtms_productos_adjuntos",
                xs:5
            }, 
            //14    
            {
                required: false,
                label: "Documentacion",
                name: "ficheros",
                type: "files", 
                ficheros: productos.ficheros,    
                tabla: "mtms_productos_adjuntos",
                modelo: "api\\modules\\v1\\models\\productos\\ProductoAdjuntos",
                carpeta: "productos",
                xs:12
            },
    ])

    const fetchData = async () => { 
        if (modo === "W") {
            const respProductos = await axios(
                `${process.env.REACT_APP_API_URL}productos/${id}`
            );                
            setProductos(respProductos.data)  
        }
        setDatosCargados(true);
    };    
    fetchData();       
    },[]);

    /*
    useEffect(() => {
        if (Object.keys(campos).length > 0){
            if (typeof operativa !== "undefined" && operativa) { 
                setDatosCargados(false)       
                if(Object.keys(operativa).length > 0 ){ 
                    campos[1].urlData = `dic/diccionarios/categoriasproductoxoperativa/${operativa.Id}`
                    campos[2].urlData = `dic/diccionarios/subcategoriasproductoxoperativa/${operativa.Id}`
                }
                else{
                    campos[1].urlData = "dic/diccionarios/categoriasproducto/"
                    campos[2].urlData = "dic/diccionarios/subcategoriasproducto/0"
                }
                console.log(campos[1].urlData)
                console.log(campos[2].urlData)
                setDatosCargados(true) 
            }
        }
        return () => {}
    }, [operativa])
    */

    useEffect(() => {   
        console.log(categoria,operativa) 
        if (Object.keys(campos).length > 0){
            if (typeof categoria !== "undefined" && categoria) { 
                setDatosCargados(false)       
                if(categoria && Object.keys(categoria).length > 0){   
                    campos[2].urlData = `dic/diccionarios/subcategoriasproducto/${categoria.Id}`;
                }else{
                    campos[2].urlData = "dic/diccionarios/subcategoriasproducto/0";
                }
                console.log(campos[2].urlData)
                setDatosCargados(true)
            }
        }
    },[categoria])
    
    useEffect(() => {            
        if (typeof productos !== "undefined") {        
        if(Object.keys(productos).length > 0){ 
            const options= [{Id: 0, Etiqueta: 'NO'}, {Id: 1, Etiqueta: 'SÍ'}];
            const activo = options.filter((element)=> element.Id === productos.extraCoste);
            console.log(productos)
            setDatosCargados(false)
            campos[0].value = productos.Operativas[0];
            campos[1].value = productos.Categoria;
            campos[2].value = productos.SubCategoria;
            campos[3].value = productos.Nombre;
            campos[4].value = productos.NombreIngles;

            campos[5].value = productos.Estado;
            campos[6].value = productos.Fecha_Alta;
            
            campos[7].value = productos.Unidad;
            campos[8].value = productos.Periodicidad;
            campos[9].value = productos.Tarifa;
            campos[10].value = activo[0];
            campos[11].value = productos.codPoseidon;         
            campos[12].value = productos.Observaciones;            
            campos[14].ficheros = productos.ficheros;
            setCategoria(productos.Categoria);
            setDatosCargados(true)  
        }
    }
    },[productos])


if (datoscargados){
    return (
        <FormularioEntidad 
        urlForm = "productos"
        urlGoto = "/ventas/productos/portfolio/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }
}


export default FormularioProductos;