import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import LinearProgress from "@material-ui/core/LinearProgress";
import {usePlanificacion} from './planificacion-context'
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'
import DialogImportData from '../../../../../components/Common/DialogImportData'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import { convertDate } from "../../../../../tools/metodos";
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
  linear: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  }));

let productos = [];

const FormularioPlanificacion = () => {  

    const {
      datosFormulario, setDatosFormulario, datosDesplegables
    } = usePlanificacion(); 

    const { id, modo } = useParams();
    const classes = useStyles();

    const [grabandoFormulario, setGrabandoFormulario] = useState(false);
    const [datoscargados, setDatosCargados] = useState(false);
    const [openNotaPedido, setOpenNotaPedido] = useState(false);  
    const [openCotizacion, setOpenCotizacion] = useState(false);
    const [openPoseidon, setOpenPoseidon] = useState(false);      
    const [openAlert, setOpenAlert] = useState(false);    
    const [clientes, setClientes] = useState("") 

    const handleChangeCuenta = (cuentas) => {
      if (Array.isArray(cuentas)){
        setClientes(cuentas.map((cuenta) => cuenta.Id).join("_"))
      }
    }  

    const handleCloseNotaPedido = () => {
      setOpenNotaPedido(false);
    };

    const handleCloseCotizacion = () => {
      setOpenCotizacion(false);
    };

    const handleClosePoseidon = () => {
      setOpenPoseidon(false);
    };

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    };

    const handleClickOpenPedido = () => {  
      setOpenNotaPedido(true);
    };

    const handleClickOpenCotizacion = () => { 
      setOpenCotizacion(true);
    };

    const handleClickOpenPoseidon = () => {
      console.log("handleClickPoseidon")
      setOpenPoseidon(true)
    }

    const handleNotaPedido = (notapedido) => {   
      if (Object.keys(notapedido).length > 0) {  
          setDatosCargados(false)     
          setDatosFormulario({
            Fecha_Solicitud: convertDate(notapedido.Fecha_Solicitud),
            canal: notapedido.Fk_Fuente,
            Fk_Pedido_Cliente: notapedido.Id,
            Clientes:  [notapedido.Cuenta],
            Contactos: notapedido.Contacto,
            Referencia_Cliente: notapedido.Referencia_Cliente,
            Fecha_Operativa: notapedido.Fecha_Servicio,
            Localizacion: notapedido.Localizacion,
            Buque: notapedido.Buque,
            Localizacion2: notapedido.Localizacion2,
            Buque2: notapedido.Buque2,
            Descripcion: notapedido.Descripcion,              
            pedidoCliente: notapedido.Codigo,   
            notapedido: notapedido.Codigo,       
            ficheros: notapedido.ficheros,
            listadoProductos: notapedido.listadoProductos
          })
          if (Array.isArray([notapedido.Cuenta])){
            setClientes([notapedido.Cuenta].map((cuenta) => cuenta.Id).join("_"))
          } 
          setDatosCargados(true)
      };     
    }

    const handleCotizacion = (cotizacion) => {  
      if (Object.keys(cotizacion).length > 0) { 
        console.log(cotizacion)
        setDatosCargados(false)
        setDatosFormulario({
          Fecha_Solicitud: cotizacion.Fecha_Solicitud,
          canal: cotizacion.Fk_Fuente,
          Fk_Cotizacion: cotizacion.Id,
          Clientes:  cotizacion.Cuenta,
          Contactos: cotizacion.Contacto[0],
          Referencia_Cliente: cotizacion.Referencia_Cliente,
          Fecha_Operativa: cotizacion.Fecha_Operativa,
          Localizacion: cotizacion.Localizacion,
          Buque: cotizacion.Buque,   
          Localizacion2: cotizacion.Localizacion2,
          Buque2: cotizacion.Buque2,         
          Descripcion: cotizacion.Descripcion,              
          cotizacion: cotizacion.Codigo,           
          ficheros: cotizacion.ficheros,
          listadoProductos: cotizacion.listadoProductos 
        })
        if (Array.isArray(cotizacion.Cuenta)){
          setClientes(cotizacion.Cuenta.map((cuenta) => cuenta.Id).join("_"))
        } 
        setDatosCargados(true)
      };
      
    }

    const getProductosDesdePoseidon = (elementos) => {
     console.log(elementos)
      const idOperativa = 7
      const Operativa = "Contenedor"
      const categoria = "Operaciones"
      const subcategoria = "CARGA / DESCARGA"
      
      const productosPoseidon = ["disimp20E", "disimp20F", "disimp40E", "disimp40F",
      "disimp45E", "disimp45F", "distrans20E", "distrans20F", "distrans40E", "distrans40F",
      "distrans45E", "distrans45F", "loadexp20E", "loadexp20F", "loadexp40E", "loadexp40F",
      "loadexp45E", "loadexp45F", "loadtrans20E", "loadtrans20F", "loadtrans40E", "loadtrans40F",
      "loadtrans45E", "loadtrans45F", "pines", "remobb20E", "remobb20F", "remobb40E", "remobb40F",
      "remobb45E", "remobb45F", "remobq20E", "remobq20F", "remobq40E", "remobq40F", "remobq45E",
      "remobq45F", "remoqb20E", "remoqb20F","remoqb40E", "remoqb40F", "remoqb45E", 
      "remoqb45F", "tapas"]

      const jornadas = [
        "J1 lunes a viernes 08:00 14:00",
        "J2 lunes a viernes 14:00 20:00",
        "J3 lunes a viernes 20:00 02:00",
        "J8 lunes a viernes 02:00 08:00",
        "J7 sábado 08:00 14:00",
        "J4 festivo 08:00 14:00",
        "J5 festivo 14:00 20:00",
        "J6 festivo 20:00 02:00",
        "J9 festivo 02:00 08:00"
      ]

      productosPoseidon.forEach(element => {
        if(parseInt(elementos[element]) > 0){
          const producto = datosDesplegables.productos.find(producto => producto.codPoseidon === element)
          if(!producto) return;
          const finicio = new Date(elementos.finicio)
          //console.log(finicio)
          const diaInicio = finicio.getDay()
          //console.log(diaInicio)

          const tramo = elementos.Tramo.split("-")
          const tramoInicial = tramo[0] + ":00"
          const tramoFinal= tramo[1] + ":00"

          let diaSemana=""
          switch (diaInicio){
            case 0: 
              diaSemana = "festivo"
              break
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
              diaSemana = "lunes a viernes"
              break
            case 6:
              if(tramoInicial === "08:00" && tramoFinal === "14:00")
                diaSemana = "sábado"
              else
                diaSemana = "festivo"
              break
            default:
              diaSemana = "lunes a viernes"
          }
          //console.log(diaSemana)
          //console.log(elementos.Tramo)
          
          const tramoCompleto = diaSemana + " " + tramoInicial + " " + tramoFinal
          //console.log(tramoCompleto)
          let jornadaTrabajada = ""
          jornadas.forEach(jornada => {
            //console.log(jornada)
            if(jornada.includes(tramoCompleto)){
              jornadaTrabajada = jornada
              return
            }
          })
          //console.log(element)

          if (productos.some(e => e.idProducto === producto.Id && element.operador === elementos.operador)) {      
            productos.forEach(element => {
              if(element.idProducto === producto.Id && element.operador === elementos.operador){
                element.cantidad++            
              }
            }) 
          }else{
            productos.push({
              cantidad: parseInt(elementos[element]),
              categoria: categoria,
              id:uuidv4(),
              idOperativa: idOperativa,
              idProducto: producto.Id,
              operativa: Operativa,
              producto: producto.Nombre,
              subcategoria: subcategoria,
              operador: elementos.operador,
              jornada: jornadaTrabajada
            })
          }
        }
      });
      return productos
    }

    const handleNewPoseidon = (elementos) => {
      console.log(elementos)
      let clientesArray = []
      let buque = {}
      let errores=0
      let erroresMSG=""

      
      clientesArray = elementos.clientes.slice();
      //buque = datosDesplegables.buques.find(buque => buque.CodBuque === elementos.buque)
      buque = elementos.buque

      if(clientesArray.length === 0){
        errores++; 
        erroresMSG += "Ningún cliente asociado a Poseidón para esta operativa"
      }

      if(!buque){
        errores++; 
        erroresMSG += "Ningún buque asociado a Poseidón para esta operativa"
      }

      if(errores > 0) {alert(erroresMSG); return;}

      let descripcion = ""
        if(buque)
          descripcion = "Planificación para Operativa de CONTENEDORES del buque " + buque.Nombre
              
        setDatosCargados(false)
        const clientes = Array.from(new Set(clientesArray));

        let fechaOperativa = elementos.fecha 
        //elementos[0].Fecha.substr(6, 4) + '-' + elementos[0].Fecha.substr(3, 2) + '-' + elementos[0].Fecha.substr(0, 2);
        console.log(fechaOperativa)
        setDatosFormulario({
          Fecha_Solicitud: convertDate(fechaOperativa, "yyyy-MM-dd"),
          Fecha_Operativa: convertDate(fechaOperativa, "yyyy-MM-dd"),
          eta: elementos.fechaLlegaBuque,//.substr(11, 5),
          Clientes: clientes,
          Destino: null,
          Buque: buque,
          Descripcion: descripcion,
          codCarpetaPoseidon: elementos.codCarpeta,
          listadoProductos: elementos.productos
        })
        if (Array.isArray(clientes)){
          setClientes(clientes.map((cuenta) => cuenta.Id).join("_"))
        } 

        setDatosCargados(true)
        productos = [];

    }
    

    
    const handlePoseidon = (carpeta) => {
      console.log("handlePoseidon")
      console.log(carpeta)

      if (typeof carpeta === 'object' && !Array.isArray(carpeta) && carpeta !== null
      ) {
          //executeSomeCode();
          console.log("NUEVO METODO")
          handleNewPoseidon(carpeta)
          return;
      }

      let clientesArray = []
      let buque = {}
      let errores=0
      let erroresMSG=""

      //let productosPoseidon = []

      if(carpeta.length > 0){
        carpeta.forEach(element => {
          /*
          console.log(element)
          //codoperador
          //Servicio
          //console.log(element.codoperador)
          console.log(datosDesplegables)
          */
          let found = datosDesplegables.clientes.find(cliente => cliente.CodAgente === element.codoperador);
          //console.log(found)
          if(found){       
            //console.log(camposFormulario[5])
            found[camposFormulario[5].campoEtiqueta] = found.Etiqueta
            clientesArray.push(found)
          }
          //else{errores++; erroresMSG += "Ningún cliente asociado a Poseidón para esta operativa"}

          getProductosDesdePoseidon(element)
          buque = datosDesplegables.buques.find(buque => buque.CodBuque === element.Buque)
        })

        if(clientesArray.length === 0){
          erroresMSG += "Ningún cliente asociado a Poseidón para esta operativa"
        }
        if(!buque){
          errores++; 
          erroresMSG += "Ningún buque asociado a Poseidón para esta operativa"
        }

        //console.log(productosPoseidon)
        console.log(productos)


        if(errores > 0) {alert(erroresMSG); return;}

        let descripcion = ""
        if(buque)
          descripcion = "Planificación para Operativa de CONTENEDORES del buque " + buque.Nombre
              
        setDatosCargados(false)
        const clientes = Array.from(new Set(clientesArray));

        /*
        console.log(convertDate(carpeta[0].Fecha.substr(0, 10), "yyyy-MM-dd"))
        console.log(carpeta[0].FechaLlegaBuque.substr(11, 5))
        console.log(clientes)
        console.log(buque)
        console.log(carpeta[0].CodCarpeta)
        */
        //console.log(productos)
        //carpeta[0].Fecha.substr(0, 10)
        //console.log(carpeta[0].Fecha)
        let fechaOperativa = carpeta[0].Fecha.substr(6, 4) + '-' + carpeta[0].Fecha.substr(3, 2) + '-' + carpeta[0].Fecha.substr(0, 2);
        //console.log(fechaOperativa)
        setDatosFormulario({
          Fecha_Solicitud: convertDate(fechaOperativa, "yyyy-MM-dd"),
          Fecha_Operativa: convertDate(fechaOperativa, "yyyy-MM-dd"),
          eta: carpeta[0].FechaLlegaBuque.substr(11, 5),
          Clientes: clientes,
          Destino: null,
          Buque: buque,
          Descripcion: descripcion,
          codCarpetaPoseidon: carpeta[0].CodCarpeta,
          listadoProductos: productos
        })
        if (Array.isArray(clientes)){
          setClientes(clientes.map((cuenta) => cuenta.Id).join("_"))
        } 

        setDatosCargados(true)
        productos = [];

      }else{
        console.log("Carpeta sin datos")
        alert("Carpeta sin datos")          
      }
    }
  
    const classDiv3 = "col-3 mt-2 mb-2" 
    
    useEffect(() => { 
      setDatosCargados(false)      
        if(Object.keys(datosDesplegables).length > 0)
        { 
          if(modo === "W" || Object.keys(datosFormulario).length > 3){            
            if(Object.keys(datosFormulario).length > 3)
            //console.log(datosFormulario)
            { 
              setCamposFormulario([
                //0
                {
                  xs:4, 
                  required: true,
                  label: "Fecha de operativa",
                  name: "fechasoperativa",
                  type: "date",    
                  value: datosFormulario.Fecha_Operativa ? datosFormulario.Fecha_Operativa : "" 
                },
                //1
                {
                  xs:2,
                  required: true,
                  label: "Hora inicio",
                  name: "eta",
                  type: "time",              
                  value: datosFormulario.eta ? datosFormulario.eta : "",              
                },
                //2
                {
                  xs:2,
                  required: false,              
                  label: "Nota de pedido",
                  name: "notapedido",
                  type: "text",  
                  disabled: true,            
                  value: datosFormulario.notapedido ? datosFormulario.notapedido : ""
                },
                //3
                {
                  xs:2,
                  required: false,              
                  label: "Cotizacion",
                  name: "cotizacion",
                  type: "text",  
                  disabled: true,            
                  value: datosFormulario.cotizacion ? datosFormulario.cotizacion : ""
                },
                //4
                {
                  xs:2,
                  required: false,              
                  label: "Carpeta poseidón",
                  name: "poseidon",
                  type: "text",  
                  disabled: true,            
                  value: datosFormulario.codCarpetaPoseidon ? datosFormulario.codCarpetaPoseidon : "",  
                },
                //5
                {
                  urlData: "cuentas/clientes",
                  campoEtiqueta: "Nombre_Comercial",
                  required: true,
                  label: "Cliente",
                  name: "cliente",
                  disabled: false,
                  type: "combo",           
                  multiple:true,   
                  value: datosFormulario.Clientes ? datosFormulario.Clientes : [],
                  xs:4,
                  onChangeValue: handleChangeCuenta
                },   
                //6            
                {
                  urlData: "contactos",
                  campoEtiqueta: "Nombre",
                  required: false,
                  label: "Contacto",
                  name: "contacto",
                  disabled: false,
                  type: "combo",           
                  multiple:false,   
                  value: datosFormulario.Contactos ? datosFormulario.Contactos : "",
                  xs:4,

                  urlDataDialogForm: "contactos",
                  DialogForm: [
                    {
                      required: false,
                      disabled: true,
                      label: "Cuenta:",
                      name: "Fk_Cuenta",
                      type: "number",    
                      value: '',
                      xs:6,
                      classes: 'd-none'
                    }, 
                    {
                      required: true,
                      disabled: false,
                      label: "Nombre:",
                      name: "Nombre",
                      type: "text",    
                      value: "",
                      xs:6
                    }, 
                    {
                      required: false,
                      disabled: false,
                      label: "Apellidos:",
                      name: "Apellidos",
                      type: "text",    
                      value: "",
                      xs:12
                    }, 
                    {
                      required: false,
                      disabled: false,
                      label: "Departamento:",
                      name: "Departamento",
                      type: "text",    
                      value: "",
                      xs:6
                    },
                    {
                      required: false,
                      disabled: false,
                      label: "Cargo:",
                      name: "Cargo",
                      type: "text",    
                      value: "",
                      xs:6
                    },
                    {
                      required: false,
                      disabled: false,
                      label: "Móvil:",
                      name: "Movil",
                      type: "text",    
                      value: "",
                      xs:6
                    }, 
                    {
                      required: false,
                      disabled: false,
                      label: "Email:",
                      name: "Email",
                      type: "text",    
                      value: "",
                      xs:12
                    }, 
                    {
                      required: false,
                      disabled: false,
                      label: "Fecha de Nacimiento:",
                      name: "FechaNacimiento",
                      type: "text",    
                      value: "",
                      xs:6
                    },
                    {
                      required: false,
                      disabled: false,
                      label: "Linkedin:",
                      name: "Linkedin",
                      type: "text",    
                      value: "",
                      xs:6
                    },
                    {
                      required: true,
                      disabled: false,
                      label: "Teléfono:",
                      name: "Telefono",
                      type: "text",    
                      value: "",
                      xs:6
                    },
                    {
                      required: false,
                      disabled: false,
                      label: "Añadir a agenda personal:",
                      name: "agendapersonal",
                      type: "switch",    
                      value: "",
                      xs:6
                    },
                  ]


                }, 
                {
                  xs:4,
                  required: false,
                  label: "Referencia de Cliente",
                  name: "referenciacliente",
                  type: "text",              
                  value: datosFormulario.Referencia_Cliente ? datosFormulario.Referencia_Cliente : ""
                },                 
                /*
                {
                  campoEtiqueta: "Etiqueta",
                  required: false,
                  label: "Procedencia",
                  name: "procedencia",
                  disabled: false,
                  type: "comboArray", 
                  options:datosDesplegables.puertos, 
                  multiple:false,   
                  value: datosFormulario.Procedencia ? datosFormulario.Procedencia : "",
                  xs:3
                },               
                {
                  campoEtiqueta: "Etiqueta",
                  required: false,
                  label: "Destino",
                  name: "destino",
                  disabled: false,
                  type: "comboArray", 
                  options:datosDesplegables.puertos, 
                  multiple:false,   
                  value: datosFormulario.Destino ? datosFormulario.Destino : "",
                  xs:3
                },      
                */         
                {
                  campoEtiqueta: "Etiqueta",
                  required: true,
                  label: "Localización",
                  name: "muelle",
                  disabled: false,
                  type: "comboArray", 
                  options:datosDesplegables.muelles, 
                  multiple:false,   
                  value: datosFormulario.Localizacion ? datosFormulario.Localizacion : "",
                  xs:4
                },
                {
                  campoEtiqueta: "Nombre",
                  required: true,
                  label: "Buque",
                  name: "buque",
                  disabled: false,
                  //type: "comboArray", 
                  type: "combo", 
                  //options:datosDesplegables.buques, 
                  multiple:false,   
                  value: datosFormulario.Buque ? datosFormulario.Buque : "",
                  xs:4,
                  urlData: "buques",
                  DialogForm: [
                  {                
                    required: true,
                    label: "Nombre:",
                    name: "Nombre",
                    disabled: false,
                    type: "text",
                    value: "",   
                    xs:12
                  }]
                }, 
                {
                  xs:4,
                  required: false,              
                  label: "Operativa",
                  name: "operativa",
                  type: "text",  
                  disabled: false,            
                  value: datosFormulario.Operativa ? datosFormulario.Operativa : ""
                }, 
                {
                  campoEtiqueta: "Etiqueta",
                  required: false,
                  label: "Localización 2",
                  name: "muelle2",
                  disabled: false,
                  type: "comboArray", 
                  options:datosDesplegables.muelles, 
                  multiple:false,   
                  value: datosFormulario.Localizacion2 ? datosFormulario.Localizacion2 : "",
                  xs:4
                },
                {
                  campoEtiqueta: "Nombre",
                  required: false,
                  label: "Buque 2",
                  name: "buque2",
                  disabled: false,
                  //type: "comboArray", 
                  type: "combo", 
                  //options:datosDesplegables.buques, 
                  multiple:false,   
                  value: datosFormulario.Buque2 ? datosFormulario.Buque2 : "",
                  xs:4,
                  urlData: "buques",
                  DialogForm: [
                  {                
                    required: true,
                    label: "Nombre:",
                    name: "Nombre",
                    disabled: false,
                    type: "text",
                    value: "",   
                    xs:12
                  }]
                }, 
                {
                  xs:12,
                  required: false,
                  label: "Descripción",
                  name: "descripcion",
                  type: "textArea",  
                  lineas:4,            
                  value: datosFormulario.Descripcion ? datosFormulario.Descripcion : ""
                }, 
                {
                  xs:12,
                  classButton: classDiv3,
                  required: false,
                  label: "",
                  name: "filesupload",
                  type: "filesupload", 
                  //ficheros: pedido.ficheros,    
                  tabla: "mtms_planificacio_adjuntos"
                },            
                {
                  xs:12,
                  required: false,
                  label: "",
                  name: "files",
                  type: "files", 
                  ficheros: datosFormulario.ficheros ? datosFormulario.ficheros : "",
                  tabla: "mtms_pedidos_clientes_adjuntos",
                  modelo: "api\\modules\\v1\\models\\planificaciones\\PlanificacionesAdjuntos",
                  carpeta: "planificaciones",
                },
                {    
                  required: false,
                  label: "",
                  name: "listadoProductos",
                  type: "text",    
                  disabled: false, 
                  value: datosFormulario.listadoProductos ? datosFormulario.listadoProductos : [],                
                  xs:4,
                  classes: 'd-none',
                  hidden: false,
                }
              ]) 
              if (Array.isArray(datosFormulario.Clientes)){
                setClientes(datosFormulario.Clientes.map((cuenta) => cuenta.Id).join("_"))
              } 
            }

          }else{  
            setCamposFormulario([
              {
                xs:4, 
                required: true,
                label: "Fecha de operativa",
                name: "fechasoperativa",
                type: "date",    
                value: "" 
              },
              {
                xs:2,
                required: false,
                label: "Hora inicio",
                name: "eta",
                type: "time",              
                value: "",              
              },
              {
                xs:2,
                required: false,              
                label: "Nota de pedido",
                name: "notapedido",
                type: "text",  
                disabled: true,            
                value: ""
              },
              {
                xs:2,
                required: false,              
                label: "Cotizacion",
                name: "cotizacion",
                type: "text",  
                disabled: true,            
                value: ""
              },
              {
                xs:2,
                required: false,              
                label: "Carpeta poseidón",
                name: "poseidon",
                type: "text",  
                disabled: true,            
                value: ""
              },
              {
                urlData: "cuentas/clientes",
                campoEtiqueta: "Nombre_Comercial",
                required: true,
                label: "Cliente",
                name: "cliente",
                disabled: false,
                type: "combo",           
                multiple:true,   
                value: [],
                xs:4,
                onChangeValue: handleChangeCuenta
              },               
              {
                urlData: "contactos",
                campoEtiqueta: "Nombre",
                required: false,
                label: "Contacto",
                name: "contacto",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: "",
                xs:4,

                urlDataDialogForm: "contactos",
                DialogForm: [
                  {
                    required: false,
                    disabled: true,
                    label: "Cuenta:",
                    name: "Fk_Cuenta",
                    type: "number",    
                    value: '',
                    xs:6,
                    classes: 'd-none'
                  }, 
                  {
                    required: true,
                    disabled: false,
                    label: "Nombre:",
                    name: "Nombre",
                    type: "text",    
                    value: "",
                    xs:6
                  }, 
                  {
                    required: false,
                    disabled: false,
                    label: "Apellidos:",
                    name: "Apellidos",
                    type: "text",    
                    value: "",
                    xs:12
                  }, 
                  {
                    required: false,
                    disabled: false,
                    label: "Departamento:",
                    name: "Departamento",
                    type: "text",    
                    value: "",
                    xs:6
                  },
                  {
                    required: false,
                    disabled: false,
                    label: "Cargo:",
                    name: "Cargo",
                    type: "text",    
                    value: "",
                    xs:6
                  },
                  {
                    required: false,
                    disabled: false,
                    label: "Móvil:",
                    name: "Movil",
                    type: "text",    
                    value: "",
                    xs:6
                  }, 
                  {
                    required: false,
                    disabled: false,
                    label: "Email:",
                    name: "Email",
                    type: "text",    
                    value: "",
                    xs:12
                  }, 
                  {
                    required: false,
                    disabled: false,
                    label: "Fecha de Nacimiento:",
                    name: "FechaNacimiento",
                    type: "text",    
                    value: "",
                    xs:6
                  },
                  {
                    required: false,
                    disabled: false,
                    label: "Linkedin:",
                    name: "Linkedin",
                    type: "text",    
                    value: "",
                    xs:6
                  },
                  {
                    required: true,
                    disabled: false,
                    label: "Teléfono:",
                    name: "Telefono",
                    type: "text",    
                    value: "",
                    xs:6
                  },
                  {
                    required: false,
                    disabled: false,
                    label: "Añadir a agenda personal:",
                    name: "agendapersonal",
                    type: "switch",    
                    value: "",
                    xs:6
                  },
                ]
              }, 
              {
                xs:4,
                required: false,
                label: "Referencia de Cliente",
                name: "referenciacliente",
                type: "text",              
                value: ""
              },              
              /*
              {
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Procedencia",
                name: "procedencia",
                disabled: false,
                type: "comboArray", 
                options:datosDesplegables.puertos, 
                multiple:false,   
                value: "",
                xs:3
              },               
              {
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Destino",
                name: "destino",
                disabled: false,
                type: "comboArray", 
                options:datosDesplegables.puertos, 
                multiple:false,   
                value: "",
                xs:3
              },   
              */            
              {
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Localización",
                name: "muelle",
                disabled: false,
                type: "comboArray", 
                options:datosDesplegables.muelles, 
                multiple:false,   
                value: "",
                xs:4
              },
              {
                campoEtiqueta: "Nombre",
                required: false,
                label: "Buque",
                name: "buque",
                disabled: false,

                //type: "comboArray", 
                type: "combo", 
                //options:datosDesplegables.buques, 
                multiple:false,   
                value: "",
                xs:4,
                urlData: "buques",
                DialogForm: [
                {                
                  required: true,
                  label: "Nombre:",
                  name: "Nombre",
                  disabled: false,
                  type: "text",
                  value: "",   
                  xs:12
                }]
               
              },    
              {
                xs:4,
                required: false,              
                label: "Operativa",
                name: "operativa",
                type: "text",  
                disabled: false,            
                value: datosFormulario.Operativa ? datosFormulario.Operativa : ""
              },
              {
                campoEtiqueta: "Etiqueta",
                required: false,
                label: "Localización 2",
                name: "muelle2",
                disabled: false,
                type: "comboArray", 
                options:datosDesplegables.muelles, 
                multiple:false,   
                value: "",
                xs:4
              },
              {
                campoEtiqueta: "Nombre",
                required: false,
                label: "Buque 2",
                name: "buque2",
                disabled: false,

                //type: "comboArray", 
                type: "combo", 
                //options:datosDesplegables.buques, 
                multiple:false,   
                value: "",
                xs:4,
                urlData: "buques",
                DialogForm: [
                {                
                  required: true,
                  label: "Nombre:",
                  name: "Nombre",
                  disabled: false,
                  type: "text",
                  value: "",   
                  xs:12
                }]
               
              },  
              {
                xs:6,
                required: false,
                label: "Descripción",
                name: "descripcion",
                type: "textArea",  
                lineas:4,            
                value: ""
              }, 
              {
                xs:5,
                classButton: classDiv3,
                required: false,
                label: "",
                name: "filesupload",
                type: "filesupload", 
                //ficheros: pedido.ficheros,    
                tabla: "mtms_pedidos_clientes_adjuntos"
              },            
              {
                xs:8,
                required: false,
                label: "",
                name: "files",
                type: "files", 
                ficheros: "",
                tabla: "mtms_pedidos_clientes_adjuntos"
              },
              {    
                required: false,
                label: "",
                name: "listadoProductos",
                type: "text",    
                disabled: false, 
                value: [],                
                xs:4,
                classes: 'd-none',
                hidden: false,
              }
            ]) 

          }                     
        }  
      setDatosCargados(true)
    },[datosFormulario, datosDesplegables])   

    const [camposFormulario, setCamposFormulario] = useState([]) 
   
    useEffect(() => {  
      if (typeof camposFormulario !== "undefined") {                   
          if(Object.keys(camposFormulario).length > 0){  
            setDatosCargados(false)  
            if(clientes) {
              camposFormulario[6].urlData = "cuentas/contactos/" + clientes
              camposFormulario[6].DialogForm[0].value = clientes
              camposFormulario[6].value = ""
            }
            else {
              camposFormulario[6].urlData = "contactos" 
              camposFormulario[6].value = ""
              camposFormulario[6].DialogForm[0].value = ""
            }
            setDatosCargados(true) 
        }        
      }      
    },[clientes])
    
    if (datoscargados)
      return (
        <>     
        {typeof id === 'undefined' && (
        <div className="row justify-content-md-center">
          <Button 
              className="mx-auto mt-3 mb-3 boton-secundario"
              variant="contained" 
              disableElevation
              size="small"
              onClick={handleClickOpenPedido}              
          >
            Cargar nota de pedido
          </Button>
          <Button 
              className="mx-auto mt-3 mb-3 boton-secundario"
              variant="contained" 
              disableElevation
              size="small"
              onClick={handleClickOpenCotizacion}              
          >
            Cargar cotización
          </Button>
          <Button 
              className="mx-auto mt-3 mb-3 boton-secundario"
              variant="contained" 
              disableElevation
              size="small"
              onClick={handleClickOpenPoseidon}              
          >
            Importar desde Poseidon
          </Button>
        </div> 
        )}

        <FormularioEntidad 
          urlForm = "planificaciones"
          urlGoto = "/operativas/planificaciones/planificacion/"
          campos={camposFormulario}
        /> 

        {openNotaPedido &&
        <DialogImportData 
          isOpen = {openNotaPedido}
          handleCloseDialog={handleCloseNotaPedido}
          url_datos = "pedidoclientes/getpedidosnoimportados"
          titulo="Cargar Pedido de Cliente"
          descripcion="Seleccione el pedido de cliente que desee importar para cargarla en el formulario."
          tituloSelect="Código de pedido de cliente"
          SetDatos={handleNotaPedido}
        />}

        {openCotizacion &&
        <DialogImportData 
          isOpen = {openCotizacion}
          handleCloseDialog={handleCloseCotizacion}
          url_datos = "cotizaciones/getcotizacionesnoimportadas"
          titulo="Cargar Cotización"
          descripcion="Seleccione la cotización que desee importar para cargarla en el formulario."
          tituloSelect="Código de cotizacion"
          SetDatos={handleCotizacion}
        />}

        {openPoseidon &&
        <DialogImportData 
          isOpen = {openPoseidon}
          handleCloseDialog={handleClosePoseidon}
          url_datos = "poseidons/listadocarpetasimportarplanificacion?carpeta="
          url_datos_seleccionados = "poseidons/listadocarpetasimportarplanificaciondetalle?carpeta="
          titulo="Cargar Poseidón"
          descripcion="Seleccione la carpeta que desee importar para cargarla en el formulario."
          tituloSelect="Código de carpeta"
          SetDatos={handlePoseidon}
          campoId="CodCarpeta"
          campoCodigo="CodCarpeta"
          getDataFromServer={false}
        />}

        {openAlert && 
          <AlertaMensaje 
            mensaje={"¡Parte guardado correctamente!"} 
            isOpen={openAlert} 
            tipoMensaje="success"
            cerrar={handleCloseAlert}
          />
          }     

        {grabandoFormulario && (
          <div className={(classes.linear, "mt-4")}>
            <LinearProgress />
          </div>
        )}        
        </>
      );
    else {
      return (
        <>
          <div className={classes.linear}>
            <LinearProgress />
          </div>
        </>
      );
    }
  };

  export default FormularioPlanificacion;


  /*
  const handlePoseidon_OLD_VERSION = (carpeta) => {
      console.log("handlePoseidon")
      console.log(carpeta)
      
      let clientesArray = []
      let puerto = {}
      let buque = {}
      let manos = 0;
      let productosPoseidon = []    
      let errores=0
      let erroresMSG=""

      if(carpeta.length > 0){
        carpeta.forEach(element => {
          console.log(element)

        let found = datosDesplegables.clientes.find(cliente => cliente.CodAgente === element.Servicio);
        
        if(found){        console.log(camposFormulario[5])
          found[camposFormulario[5].campoEtiqueta] = found.Etiqueta
          clientesArray.push(found)
        }else{errores++; erroresMSG += "Ningún cliente asociado a Poseidón para esta operativa"}

        productosPoseidon = productosPoseidon.concat(getProductosDesdePoseidon(element))
        puerto = datosDesplegables.puertos.find(puerto => puerto.Codigo == element.Destino)
        buque = datosDesplegables.buques.find(buque => buque.CodBuque === element.Buque)

        
        if(!puerto){
          errores++; 
          erroresMSG += "Ningún puerto asociado a Poseidón para esta operativa"
        }
        

        if(!buque){
          errores++; 
          erroresMSG += "Ningún buque asociado a Poseidón para esta operativa"
        }
        manos++              
      })

      if(errores > 0) {alert(erroresMSG); return;}

      let descripcion = ""
      if(buque)
        descripcion = "Planificación para Operativa de " + carpeta[0].TipoOperaciones + " del buque " + buque.Nombre + " compuesta por " + manos + (manos > 1 ? " manos." : " mano.")
              
      setDatosCargados(false)

      const clientes = Array.from(new Set(clientesArray));
      
      setDatosFormulario({
        Fecha_Solicitud: convertDate(carpeta[0].FechaAtraque.substr(0, 10), "yyyy-MM-dd"),
        Fecha_Operativa: convertDate(carpeta[0].FechaAtraque.substr(0, 10), "yyyy-MM-dd"),
        eta: carpeta[0].FechaAtraque.substr(11, 5),
        Clientes: clientes,
        Destino: puerto,
        Buque: buque,
        Descripcion: descripcion,
        codCarpetaPoseidon: carpeta[0].CodCarpeta,
        listadoProductos: productosPoseidon
      })
      if (Array.isArray(clientes)){
        setClientes(clientes.map((cuenta) => cuenta.Id).join("_"))
      } 

      setDatosCargados(true)
      }else{
        console.log("Carpeta sin datos")
        alert("Carpeta sin datos")          
      }
      

        
          canal: cotizacion.Fk_Fuente,
          Fk_Cotizacion: cotizacion.Id,
          Clientes:  cotizacion.Cuenta,
          Contactos: cotizacion.Contacto[0],
          Referencia_Cliente: cotizacion.Referencia_Cliente,
          
          Localizacion: cotizacion.Localizacion,
          Buque: cotizacion.Buque,          
          Descripcion: cotizacion.Descripcion,              
          cotizacion: cotizacion.Codigo,           
          ficheros: cotizacion.ficheros,
          listadoProductos: cotizacion.listadoProductos 
          

        }
      */

//if(modo==="W"){
  //}
        /*
      else{
        setCamposFormulario([
          {
            xs:4, 
            required: true,
            label: "Fecha de operativa",
            name: "fechasoperativa",
            type: "date",    
            value: "" 
          },
          {
            xs:4,
            required: true,
            label: "ETA",
            name: "eta",
            type: "time",              
            value: "",              
          },
          {
            xs:2,
            required: true,              
            label: "Nota de pedido",
            name: "notapedido",
            type: "text",  
            disabled: true,            
            value: ""
          },
          {
            xs:2,
            required: true,              
            label: "Cotizacion",
            name: "cotizacion",
            type: "text",  
            disabled: true,            
            value: ""
          },
          {
            urlData: "cuentas/clientes",
            campoEtiqueta: "Razon_Social",
            required: true,
            label: "Cliente",
            name: "cliente",
            disabled: false,
            type: "combo",           
            multiple:true,   
            value: [],
            xs:4,
            onChangeValue: handleChangeCuenta
          },               
          {
            urlData: "contactos",
            campoEtiqueta: "Nombre",
            required: true,
            label: "Contacto",
            name: "contacto",
            disabled: false,
            type: "combo",           
            multiple:false,   
            value: "",
            xs:4
          }, 
          {
            xs:4,
            required: true,
            label: "Referencia de Cliente",
            name: "referenciacliente",
            type: "text",              
            value: ""
          }, 
          {
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Procedencia",
            name: "procedencia",
            disabled: false,
            type: "comboArray", 
            options:datosDesplegables.puertos, 
            multiple:false,   
            value: "",
            xs:3
          },               
          {
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Destino",
            name: "destino",
            disabled: false,
            type: "comboArray", 
            options:datosDesplegables.puertos, 
            multiple:false,   
            value: "",
            xs:3
          },               
          {
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Muelle",
            name: "muelle",
            disabled: false,
            type: "comboArray", 
            options:datosDesplegables.muelles, 
            multiple:false,   
            value: "",
            xs:3
          },
          {
            campoEtiqueta: "Nombre",
            required: true,
            label: "Buque",
            name: "buque",
            disabled: false,
            type: "comboArray", 
            options:datosDesplegables.buques, 
            multiple:false,   
            value: "",
            xs:3
          },               
          {
            xs:6,
            required: true,
            label: "Descripción",
            name: "descripcion",
            type: "textArea",  
            lineas:4,            
            value: ""
          }, 
          {
            xs:5,
            classButton: classDiv3,
            required: false,
            label: "",
            name: "filesupload",
            type: "filesupload", 
            //ficheros: pedido.ficheros,    
            tabla: "mtms_pedidos_clientes_adjuntos"
          },            
          {
            xs:8,
            required: false,
            label: "",
            name: "files",
            type: "files", 
            ficheros: "",
            tabla: "mtms_pedidos_clientes_adjuntos"
          },
        ])  
        setDatosCargados(true)
      }   
      */  

      /*
      const getProductosDesdePoseidon_ANTERIOR = (elemento) => {
      
      const idOperativa = 7
      const Operativa = "Contenedor"
      const categoria = "Operaciones"
      const subcategoria = "CARGA / DESCARGA"

      

      const codigosProductos = [
        "disimp20F", "disimp40F", "disimp45F",
        "disimp20R", "disimp40R", "disimp45R",
        "loadexp20F", "loadexp40F", "loadexp45F",
        "loadexp20R", "loadexp40R", "loadexp45R",
      ]

      console.log(elemento)

      const prefijoCargaDescarga = elemento.TipoMovimiento === "DESCARGA" ? "disimp" : "loadexp"
      const claseTam = elemento.Descripcion.includes("OPEN TOP") || 
      elemento.Descripcion.includes("OPEN-TOP") || elemento.Descripcion.includes("OPEN_TOP") ? "45" : elemento.Clase;
      const reefer = elemento.Descripcion.includes("REFRIGERATED");
      const productoABuscar = prefijoCargaDescarga + claseTam + (reefer ? "R" : "F")

      const producto = datosDesplegables.productos.find(producto => producto.codPoseidon === productoABuscar)
          
      if (productos.some(e => e.idProducto === producto.Id)) {      
        productos.forEach(element => {
          if(element.idProducto === producto.Id){
            element.cantidad++            
          }
        }) 
      }else{
        productos.push({
          cantidad: 1,
          categoria: categoria,
          id:uuidv4(),
          idOperativa: idOperativa,
          idProducto: producto.Id,
          operativa: Operativa,
          producto: producto.Nombre,
          subcategoria: subcategoria,
          operador: elemento.OperadorFactura
        })
      }
      
    }

    const getProductosDesdePoseidon_OLD_VERSION = (elementos) => {
     
      let productos = []
      const idOperativa = 7
      const Operativa = "Contenedor"
      const categoria = "Operaciones"
      const subcategoria = "CARGA / DESCARGA"
      
      const productosPoseidon = ["disimp20E", "disimp20F", "disimp40E", "disimp40F",
      "disimp45E", "disimp45F", "distrans20E", "distrans20F", "distrans40E", "distrans40F",
      "distrans45E", "distrans45F", "loadexp20E", "loadexp20F", "loadexp40E", "loadexp40F",
      "loadexp45E", "loadexp45F", "loadtrans20E", "loadtrans20F", "loadtrans40E", "loadtrans40F",
      "loadtrans45E", "loadtrans45F", "pines", "remobb20E", "remobb20F", "remobb40E", "remobb40F",
      "remobb45E", "remobb45F", "remobq20E", "remobq20F", "remobq40E", "remobq40F", "remobq45E",
      "remobq45F", "remoqb20E", "remoqb20F","remoqb40E", "remoqb40F", "remoqb45E", 
      "remoqb45F", "tapas"]

      productosPoseidon.forEach(element => {
        if(parseInt(elementos[element]) > 0){
          const producto = datosDesplegables.productos.find(producto => producto.codPoseidon === element)
          
          if(producto){
            productos.push({
              cantidad: parseInt(elementos[element]),
              categoria: categoria,
              id:uuidv4(),
              idOperativa: idOperativa,
              idProducto: producto.Id,
              operativa: Operativa,
              producto: producto.Nombre,
              subcategoria: subcategoria
            })
          }
        }
      });
      return productos
    }
    */
