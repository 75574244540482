import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";

const propiedadesUsuarios = {
    urlData: "dic/diccionarios/listadooperariosservicios",
    campoEtiqueta: "nombre",
    required: true,
    name: "combo-usuarios",    
    label: "Empleados",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesFecha = {
    xs:3,
    required: false,
    label: "Fecha",
    name: "fecha",
    type: "date",    
    value: ''
}

const propiedadesHoraInicio = {
    xs:2,
    required: false,
    label: "Hora inicio",
    name: "horainicio",
    type: "time",    
    value: ''
}
  
  const propiedadesHoraFin = {
    xs:2,
    required: false,
    label: "Hora fin",
    name: "horafin",
    type: "time",    
    value: ''
}

const getDateToday = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    let hoy = yyyy + '-' + mm + '-' + dd;
    return hoy;
}

const getTimeNow = (horasMas=0) => {
    let today = new Date();
    let hh = String(today.getHours() + horasMas).padStart(2, '0');
    let mm = String(today.getMinutes() + 1).padStart(2, '0');    
    let now = hh + ':' + mm;
    return now;
}

function toDate(dStr,format) {
    var now = new Date();
    if (format == "h:m") {
        now.setHours(dStr.substr(0,dStr.indexOf(":")));
        now.setMinutes(dStr.substr(dStr.indexOf(":")+1));
        now.setSeconds(0);
        return now;
    }else 
        return "Invalid Format";
}

const FormularioMantenimientoEmpleados = ({listadotabla}) => {   
    const { id, modo } = useParams();
    const [open, setOpen] = useState(false)
    const [selectedEmpleado, setSelectedEmpleado] = useState(null)
    const [empleados, setEmpleados] = useState([]);
    const [horaInicio, setHoraInicio] = useState(getTimeNow())
    const [horaFin, setHoraFin] = useState(getTimeNow(1))
    const [fecha, setFecha] = useState(getDateToday())
    const loading = open && empleados.length === 0;

    

    useEffect(() => {  
        (async () => {
            const respEmpleados = await axios(process.env.REACT_APP_API_URL + propiedadesUsuarios.urlData);
            setEmpleados(Object.keys(respEmpleados.data).map((key) => respEmpleados.data[key]))           
          })();

          return () => {
            setEmpleados([]); // This worked for me
          };  
    }, [])

    const getOptionLabelEmpleado = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesUsuarios.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedEmpleado = (option, value) => {        
        if(value){
            if( option.id === value.Id ) 
            {
                setSelectedEmpleado(value)
            }
            return option.id === value.Id
        }
    }

    const handleChangeEmpleado = (e, v) => { 
        setSelectedEmpleado(v)
    }

    const handleChangeHoraInicio = (event) => {         
        setHoraInicio(event.target.value)
    }

    const handleChangeHoraFin = (event) => { 
        setHoraFin(event.target.value)
    }

    const handleChangeFecha = (event) => {
        setFecha(event.target.value)
    }

    const renderInputEmpleado = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesUsuarios.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const handleClick = () => {   
        if(selectedEmpleado){
            
            let fecha1 = horaInicio
            let fechaInicio = toDate(fecha1,"h:m")
            let fecha2 = horaFin
            let fechaFin = toDate(fecha2,"h:m")

            let diffDays1 = fechaFin - fechaInicio; 
            let horasTrabajadas = diffDays1/60/60/1000
            
            let costeHora = parseFloat(selectedEmpleado.Coste) / 6
            let costeTotalTrabajado = costeHora * horasTrabajadas
            
            listadotabla({
                id:uuidv4(),
                recursos:"Empleados",
                descripcion:selectedEmpleado.nombre,
                fecha:fecha,
                horainicio:horaInicio,
                horafin:horaFin,
                codigo:selectedEmpleado.id,
                deposito:"",
                cantidad:horasTrabajadas.toFixed(2),
                tarifa:costeHora.toFixed(2),
                coste: costeTotalTrabajado.toFixed(2)
            })
            setSelectedEmpleado()  
        }      
    }

    return (
    <>
    <Grid container  spacing={1} className="mt-2">
    <Grid item xs={propiedadesUsuarios.xs} className="">
        <Autocomplete
            id={propiedadesUsuarios.name}
            name={propiedadesUsuarios.name}
            getOptionLabel= { getOptionLabelEmpleado }
            getOptionSelected={(option, value) => getOptionSelectedEmpleado(option, value)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            label={propiedadesUsuarios.label}        
            renderInput={renderInputEmpleado}
            onChange={(e, v, r) => handleChangeEmpleado(e, v, r)}  
            options={empleados}
            value={selectedEmpleado}
            style={{  marginLeft: 0 }}
            disabled={ modo === "R" ? true : false }
        />            
    </Grid>

    <Grid item xs={propiedadesFecha.xs} className="" >
    <TextField                 
        required = {propiedadesFecha.required}                
        id={propiedadesFecha.name}
        name={propiedadesFecha.name}
        disabled={ modo === "R" ? true : false }
        label={propiedadesFecha.label}        
        value={fecha} 
        type="date"
        onChange={handleChangeFecha} 
        variant="outlined"
        size="small"        
        InputLabelProps={{
            shrink: true,
        }}
        keyboardbuttonprops={{
            'aria-label': 'change date',
          }}
        fullWidth
    >
    </TextField>  
    </Grid>

    <Grid item xs={propiedadesHoraInicio.xs} className="" >
    <TextField                 
        required = {propiedadesHoraInicio.required}                
        id={propiedadesHoraInicio.name}
        name={propiedadesHoraInicio.name}
        disabled={ modo === "R" ? true : false }
        label={propiedadesHoraInicio.label}        
        value={horaInicio} 
        type="time"
        onChange={handleChangeHoraInicio} 
        variant="outlined"
        size="small"        
        InputLabelProps={{
            shrink: true,
        }}
        keyboardbuttonprops={{
            'aria-label': 'change time',
          }}
        fullWidth
    >
    </TextField>  
    </Grid>

    <Grid item xs={propiedadesHoraFin.xs} className="" >
    <TextField                 
        required = {propiedadesHoraFin.required}                
        id={propiedadesHoraFin.name}
        name={propiedadesHoraFin.name}
        disabled={ modo === "R" ? true : false }
        label={propiedadesHoraFin.label}        
        value={horaFin} 
        type="time"
        onChange={handleChangeHoraFin} 
        variant="outlined"
        size="small"        
        InputLabelProps={{
            shrink: true,
        }}
        fullWidth
    >
    </TextField>      
    </Grid>

    <Grid item xs={2} className="" >
    <Button disabled={ modo === "R" ? true : false } className="" variant="outlined" size="large" onClick={handleClick}>
        AÑADIR
    </Button> 
    </Grid>
    </Grid>
    </>
    )
}
export default FormularioMantenimientoEmpleados;