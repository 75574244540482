import React from "react";
import { v4 as uuidv4 } from 'uuid';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from "../../../../../components/Common/FormFieldCombo";
import FormFieldNumber from "../../../../../components/Common/FormFieldNumber";
import { useForm } from "react-hook-form";

const propiedadesArticulo = {
  urlData: "materials/listadomateriales",
  campoEtiqueta: "Nombre",
  campoId: "id",
  required: false,
  name: "descripcion",
  disabled: false,
  label: "Materiales",
  value: "",
  multiple: false,
  xs:4,
}

const propiedadesCantidad = {
  xs:2,
  required: false,
  label: "Cantidad",
  name: "cantidad",
  type: "number",    
  value: ''
}

const propiedadesPrecioUnidad = {
  xs:2,
  required: false,
  label: "Precio .Ud",
  name: "precio",
  type: "number",    
  value: ''
}

const propiedadesIgic = {
  urlData: "dic/diccionarios/igic",
  campoEtiqueta: "Etiqueta,Valor",
  campoId: "Id",
  required: false,
  name: "igic",
  disabled: false,
  label: "Igic",
  value: "",
  multiple: false,
  xs:4,
}

const propiedadesDeposito = {
  urlData: "depositos",
  campoEtiqueta: "nombre",
  campoId: "id",
  required: false,
  name: "deposito",
  disabled: false,
  label: "Almacén",
  value: "",
  multiple: false,
  xs:4,
}

  const FormularioEntrada = ({listadotabla}) => {   
    const { control, setValue, getValues} = useForm(); 

    const handleClick = () => { 

        const material = getValues("descripcion")
        const cantidad = getValues("cantidad")
        const precio = getValues("precio")        
        const deposito = getValues("deposito")
        const igic = getValues("igic")  
        console.log(material)    
       
        listadotabla({    
          id:uuidv4(),
          idArticulo:material.id,
          idDeposito:deposito.id,
          idIgic: igic.Id,
          codigo:material.ean,
          descripcion:material.Nombre,
          unidad:material.tipoUnidad,
          precioUnidad:precio,
          precioTotal:cantidad*precio,
          cantidad:cantidad,
          depositos:deposito.nombre,            
          igic: igic.Valor,   
        })

        setValue("descripcion", "")
        setValue("cantidad", "")
        setValue("precio", "")
        setValue("igic", "")      
        setValue("deposito", "") 
    }

    return (
      <>
    <Grid container  spacing={1} className="mt-2 borderFormulario"> 
        <FormFieldCombo propiedades={propiedadesArticulo} control={control}/>         
        <FormFieldNumber propiedades={propiedadesCantidad} control={control}/> 
        <FormFieldNumber propiedades={propiedadesPrecioUnidad} control={control}/>
        <FormFieldCombo propiedades={propiedadesIgic} control={control}/>        
        <FormFieldCombo propiedades={propiedadesDeposito} control={control}/>
    
        <Grid item xs={4} className="" >
        <Button className="" variant="outlined" size="large" onClick={handleClick}>
            AÑADIR
        </Button> 
        </Grid>
    </Grid>
    </>
    )
}
export default FormularioEntrada;

//const [selectedMaterial, setSelectedMaterial] = useState(null);
    //const [selectedDeposito, setSelectedDeposito] = useState(null);
    //const [material, setMaterial] = useState([])
    //const { modo } = useParams();
    //const [cantidad, setCantidad] = useState()
    //const [fechaEntrada, setFechaEntrada] = useState()
    //const [deposito, setDeposito] = useState([])
    
    /*
    const propiedadesProveedor = {
  urlData: "cuentas/proveedores",
  campoEtiqueta: "Razon_Social",
  campoId: "Id",
  required: false,
  name: "proveedor",
  disabled: false,
  label: "Proveedor",
  value: "",
  multiple: false,
  xs:4,
}

    useEffect(() => {  
        (async () => {
            const respMateriales = await axios(process.env.REACT_APP_API_URL + propiedadesArticulo.urlData );
            setMaterial(Object.keys(respMateriales.data).map((key) => respMateriales.data[key])) 

            const respDepositos = await axios(process.env.REACT_APP_API_URL + propiedadesDeposito.urlData );
            setDeposito(Object.keys(respDepositos.data).map((key) => respDepositos.data[key])) 

          })();

          return () => {
            setMaterial([]); 
            setDeposito([]); 
          };   
    }, [])
    */
/*
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    
const loading = open && material.length === 0;
    const loadingDeposito = open2 && deposito.length === 0;
    
const [controlDeshabilitado, setControlDeshabilitado] = useState(modo === "R" ? true : false)
    
    useEffect(() => {
      setControlDeshabilitado(modo === "R" ? true : false)
    },[modo])
*/
/*
const propiedadesMaterial = {
    urlData: "materials",
    campoEtiqueta: "Nombre",
    required: true,
    name: "combo-nombre",
    disabled: false,
    label: "Material",
    value: "",
    multiple: false,
    xs:3,
  }
  
  const propiedadesCantidad = {
    xs:2, 
    required: true,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    disabled: false,
    value: ''
  }

  const propiedadesPrecioUnidad = {
    xs:2,
    required: false,
    label: "Precio .Ud",
    name: "precio",
    type: "number",    
    value: ''
}

  const propiedadesFechaEntrada = {
    xs:3, 
    required: true,
    label: "Fecha Entrada",
    name: "fechaEntrada",
    type: "date",    
    disabled: false,
    value: ''
  }
  const propiedadesDeposito = {
    urlData: "depositos",
    campoEtiqueta: "nombre",
    required: true,
    name: "combo-deposito",
    disabled: false,
    label: "Deposito",
    value: "",
    multiple: false,
    xs:2,
  }
  */
/*
    return (
    <>
    <Grid container spacing={1} className="mt-4 ml-4 borderFormulario">  

    <Grid item xs={propiedadesMaterial.xs} className="">
        <Autocomplete
            id={propiedadesMaterial.name}
            name={propiedadesMaterial.name}
            getOptionLabel= { getOptionLabelMaterial }
            getOptionSelected={(option, value) => getOptionSelectedMaterial(option, value)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            label={propiedadesMaterial.label}        
            renderInput={renderInputMaterial}
            onChange={(e, v, r) => handleChangeMaterial(e, v, r)}  
            options={material}
            value={selectedMaterial}
            style={{  marginLeft: 0 }}
            disabled = {controlDeshabilitado}
        />            
    </Grid>
   
    <Grid item xs={propiedadesFechaEntrada.xs} className="" >
    <TextField                 
        required = {propiedadesFechaEntrada.required}                
        id={propiedadesFechaEntrada.name}
        name={propiedadesFechaEntrada.name}
        disabled = {controlDeshabilitado}
        label={propiedadesFechaEntrada.label}        
        value={fechaEntrada} 
        type="date"
        onChange={handleChangeFechaEntrada} 
        variant="outlined"
        size="small" 
        style={{  marginLeft: 0 }}       
        InputLabelProps={{
            shrink: true,
        }}
    >
    </TextField>  
    </Grid>

    <Grid item xs={propiedadesCantidad.xs} className="" >
    <TextField                 
        required = {propiedadesCantidad.required}                
        id={propiedadesCantidad.name}
        name={propiedadesCantidad.name}
        disabled = {controlDeshabilitado}
        label={propiedadesCantidad.label}        
        value={cantidad} 
        type="number"
        onChange={handleChangeCantidad} 
        variant="outlined"
        size="small" 
        style={{  marginLeft: 0 }}
        InputLabelProps={{
            shrink: true,
        }}       
    >
    </TextField>  
    </Grid>

    <Grid item xs={propiedadesDeposito.xs} className="" >
    <Autocomplete
            id={propiedadesDeposito.name}
            name={propiedadesDeposito.name}
            getOptionLabel= { getOptionLabelDeposito }
            getOptionSelected={(option, value) => getOptionSelectedDeposito(option, value)}
            open={open2}
            loadingText="Cargando..."
            onOpen={() => { setOpen2(true); }}
            onClose={() => { setOpen2(false); }}   
            loading={loadingDeposito}
            label={propiedadesDeposito.label}        
            renderInput={renderInputDeposito}
            onChange={(e, v, r) => handleChangeDeposito(e, v, r)}  
            options={deposito}
            value={selectedDeposito}
            style={{  marginLeft: 0 }}
            disabled = {controlDeshabilitado}
            
        />              
    </Grid>

    <Grid item xs={2} className="" >
    <Button className="" variant="outlined" size="large" onClick={handleClick} disabled = {controlDeshabilitado}>
        AÑADIR
    </Button> 
    </Grid>
    </Grid>
    </>
    )
    */
/*
    const handleChangeMaterial = (e, v) => {         
        setSelectedMaterial(v)
    }
    const handleChangeCantidad = (event) => {         
        setCantidad(event.target.value)
    }

    const handleChangeFechaEntrada = (event) => { 
        setFechaEntrada(event.target.value)
    }
    const handleChangeDeposito = (e, v) => {         
        setSelectedDeposito(v)
    }

    const getOptionLabelMaterial = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesMaterial.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionLabelDeposito = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesDeposito.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedMaterial = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedMaterial(value)
        }
        return option.Id === value.Id
    }

    const getOptionSelectedDeposito = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedDeposito(value)
        }
        return option.Id === value.Id
    }

    const renderInputMaterial = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesMaterial.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const renderInputDeposito = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesDeposito.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }
    */