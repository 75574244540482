const columnsEmpleadosCPE = [
    {
        name: "Id",
        label: "Nº",
        options: {
            filter: false,
            sort: true,
            sortCompare: (order) => {
                return (obj1, obj2) => {
                  let val1 = parseInt(obj1.data, 10);
                  let val2 = parseInt(obj2.data, 10);
                  return (val1 - val2) * (order === 'asc' ? 1 : -1);
                };
            }
        }
    }, 
    {
        name: "Censo",
        label: "Censo",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    {
        name: "Nombre",
        label: "Nombre",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    {
        name: "Apellido1",
        label: "Apellido 1",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    {
        name: "Apellido2",
        label: "Apellido 2",
        options: {
            filter: false,
            sort: true,
        }
    }, 
    {
        name: "Antiguedad",
        label: "Antigüedad",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "Codigo",
        label: "Código",
        options: {
            filter: true,
            sort: true,
        }
    }, 
    {
        name: "Categoria",
        label: "Categoría",
        options: {
            filter: true,
            sort: true,
        }
    }, 
    {
        name: "Situacion",
        label: "Situación",
        options: {
            filter: true,
            sort: true,
        }
    },  

]
export {columnsEmpleadosCPE};

const botoneraCensoCPE = [
     {
       etiqueta: "Editar",
       url: '/rrhh/gestioncpe/personalcpe/filaSeleccionada/W',
       activo: false,
     },
     {
       etiqueta: "Crear",
       url: '/rrhh/gestioncpe/personalcpe',
       activo: true,
     }, 
   ];

   export {botoneraCensoCPE};
