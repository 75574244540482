import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { recoveryPassword } from "../../../actions/auth";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from '@material-ui/core/CircularProgress';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default connect(({ reducerApi }) => ({ reducerApi }), {
  recoveryPassword,
})((props) => {  
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [isLoading, setIsLoading] = useState(props.reducerApi.isLoading);

  const submitForm = () => {
    setError("")
    setMensaje("")
    if (email === "") {
      setError("Debe rellenar el campo");
      return;
    }
    props.recoveryPassword({ email });
  };

  useEffect(() => {   
    setIsLoading(props.reducerApi.isLoading)    
    return () => {}
  }, [props.reducerApi.isLoading])

  useEffect(() => {    
    if(props.reducerApi.error){
      setError(props.reducerApi.error.message)
    }
    return () => {}
  }, [props.reducerApi.error])

  useEffect(() => {    
    if(props.reducerApi.mensaje){
      setMensaje(props.reducerApi.mensaje.message)
    }
    return () => {}
  }, [props.reducerApi.mensaje])

  return (
    <div className="container ">
      <div className="row align-items-center max-vh-height">
        <div className="col-6 offset-3 mr-auto bg-white p-4 rounded">
          <span className="font-weight-bolder">Recuperar contraseña</span>
          <hr></hr>
          <div className="">
            <form>
              <div className="row">
                <label
                  htmlFor="email"
                  className="col-sm-6 col-form-label mt-2 text-right"
                >
                  Correo electrónico
                </label>
                <div className="col-sm-6">
                  <TextField
                    id="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    className="form-input mt-2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className=" row">
                <Link
                  to={"/login"}
                  className="col-sm-6 col-form-label mt-2 text-right"
                >
                  <label htmlFor="" className="">
                    Acceder
                  </label>
                </Link>
                <div className="col-sm-6">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="form-input mt-2"
                    onClick={submitForm}
                  >
                    RECUPERAR CONTRASEÑA
                  </Button>
                </div>
              </div>

              <div className=" col-sm-12 mt-3">                

                {error && (
                  <Alert severity="error" onClick={() => setError(null)}>
                    {error}
                  </Alert>
                )}

                {mensaje && (
                  <Alert severity="success" onClick={() => setMensaje(null)}>
                    {mensaje}
                  </Alert>
                )}

                {isLoading && (
                  <CircularProgress />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
