import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { StateContext } from '../../../../../context'

const propiedadesMaquinaria = {
    urlData: "dic/diccionarios/listadomaquinariapropia",
    campoEtiqueta: "Nombre",
    required: true,
    name: "combo-maquinaria",
    disabled: false,
    label: "Maquinaria",
    value: "",
    multiple: false,
    xs:4,
}

const propiedadesHoraInicioGesport = {
    xs:2, 
    required: false,
    label: "Hora inicio",
    name: "horainiciogesport",
    type: "time",    
    disabled: false,
    value: ''
}
  
  const propiedadesHoraFinGesport = {
    xs:2, 
    required: false,
    label: "Hora fin",
    name: "horafingesport",
    type: "time",    
    disabled: false,
    value: ''
}

const propiedadesCantidad = {
    xs:2,
    required: false,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    value: ''
}

const FormularioOperacionGesportMaquinaria = ({ getListadoRecursos }) => {
    const {state, dispatch} = useContext(StateContext)

    const [open, setOpen] = useState(false)
    const [selectedMaquinaria, setSelectedMaquinaria] = useState(null)
    const [maquinaria, setMaquinaria] = useState([]);
    const [horaInicio, setHoraInicio] = useState()
    const [horaFin, setHoraFin] = useState()
    const [cantidad, setCantidad] = useState(0)
    const loading = open && maquinaria.length === 0;

    useEffect(() => {
        (async () => {
            const respMaquinaria = await axios(process.env.REACT_APP_API_URL + propiedadesMaquinaria.urlData);
            setMaquinaria(Object.keys(respMaquinaria.data).map((key) => respMaquinaria.data[key]))           
          })();
    }, [])

    const getOptionLabelMaquinaria = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesMaquinaria.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedMaquinaria = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedMaquinaria(value)
        }
        return option.Id === value.Id
    }

    const handleChangeMaquinaria = (e, v) => { 
        setSelectedMaquinaria(v)
    }

    const handleChangeHoraInicio = (event) => {         
        setHoraInicio(event.target.value)
    }

    const handleChangeHoraFin = (event) => { 
        setHoraFin(event.target.value)
    }

    const handleChangeCantidad = (event) => { 
        setCantidad(event.target.value)
    }

    const renderInputMaquinaria = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesMaquinaria.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const handleClick = () => { 

        dispatch({type: "ADD_LISTADO_GESPORT", 
        payload: {
            recurso:'maquinaria',
            nombre: selectedMaquinaria.Nombre,
            id: uuidv4(),
            cantidad: cantidad,
            horainicio: horaInicio,
            horafin: horaFin,
            coste: selectedMaquinaria.Coste,
            total: selectedMaquinaria.Coste * cantidad
        }})
        setSelectedMaquinaria(null)
        setHoraInicio('')
        setHoraFin('')
        setCantidad(0)
    }

    return (
    <>
    <Grid container  spacing={1} className="mt-2">
        
    <Grid item xs={propiedadesMaquinaria.xs} className="">              
        <Autocomplete
            id={propiedadesMaquinaria.name}
            name={propiedadesMaquinaria.name}
            getOptionLabel= { getOptionLabelMaquinaria }
            getOptionSelected={(option, value) => getOptionSelectedMaquinaria(option, value)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            label={propiedadesMaquinaria.label}        
            renderInput={renderInputMaquinaria}
            onChange={(e, v, r) => handleChangeMaquinaria(e, v, r)}  
            options={maquinaria}
            value={selectedMaquinaria}
            style={{  marginLeft: 0 }}
        />            
    </Grid>

    <Grid item xs={propiedadesHoraInicioGesport.xs} className="">       
        <TextField                 
            required = {propiedadesHoraInicioGesport.required}                
            id={propiedadesHoraInicioGesport.name}
            name={propiedadesHoraInicioGesport.name}
            disabled = {propiedadesHoraInicioGesport.disabled}
            label={propiedadesHoraInicioGesport.label}        
            value={horaInicio} 
            type="time"
            onChange={handleChangeHoraInicio} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            keyboardbuttonprops={{
                'aria-label': 'change time',
            }}
            fullWidth
        >
        </TextField>  
    </Grid>

    <Grid item xs={propiedadesHoraFinGesport.xs} className="">
        <TextField                 
            required = {propiedadesHoraFinGesport.required}                
            id={propiedadesHoraFinGesport.name}
            name={propiedadesHoraFinGesport.name}
            disabled = {propiedadesHoraFinGesport.disabled}
            label={propiedadesHoraFinGesport.label}        
            value={horaFin} 
            type="time"
            onChange={handleChangeHoraFin} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField>      
    </Grid>
    
    <Grid item xs={propiedadesCantidad.xs} className="">
        <TextField                 
            required = {propiedadesCantidad.required}                
            id={propiedadesCantidad.name}
            name={propiedadesCantidad.name}
            disabled = {propiedadesCantidad.disabled}
            label={propiedadesCantidad.label}        
            value={cantidad} 
            type="number"
            onChange={handleChangeCantidad} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField>      
    </Grid>
    
    <Grid item xs={2} className="" >
    <Button className="" variant="contained" color="primary" size="large" onClick={handleClick}>
        AÑADIR
    </Button> 
    </Grid>

    </Grid>
    </>
    )
}
export default FormularioOperacionGesportMaquinaria;