import React, { useState, useEffect } from "react";

const TableHeader = (props) => {
  const [titulos] = useState(props.titulos);
  const [filas, setFilas] = useState(props.cuentasMH);
  const [lastFieldOrder, setLastFieldOrder] = useState(props.lastFieldOrderMH);
  const [orderASC, setOrderASC] = useState(props.orderASCMH);

  useEffect(() => {
    setFilas(props.cuentasMH);
  }, [props.cuentasMH]);

  function compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      if (comparison === 0) {
        if (varA) {
          comparison = 1;
        }
        if (varB) {
          comparison = -1;
        }
      }

      return order === "desc" ? comparison * -1 : comparison;
    };
  }

  return titulos.map((key, index) => {
    return (
      <th
        key={index}
        className={
          lastFieldOrder === key.campo
            ? orderASC
              ? "border-0 dropdown-down"
              : "border-0 dropdown-up"
            : "border-0"
        }
        onClick={() => {
          setLastFieldOrder(key.campo);
          setOrderASC(!orderASC);
          let orderBy = orderASC ? "asc" : "desc";         
          if (filas) {            
            setFilas(filas.sort(compareValues(key.campo, orderBy)));
            props.onClick();
          }
        }}
      >
        {key.etiqueta}
      </th>
    );
  });
};
export default TableHeader;
