import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaFormacion = () => {
    let { id } = useParams();

    const [reconocimiento, setSeleccion] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSeleccion = await axios(`${process.env.REACT_APP_API_URL}rrhhssrecmedicos/${id}`);    
          setSeleccion(respSeleccion.data)  
        };
        fetchData();  
    }, [id]);
 
    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(reconocimiento).length > 0){  
        
            setCampos([
            {
                classsLabel: "",
                label: "Tipologia:",
                value: reconocimiento.Tipologia.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha:",
                value: reconocimiento.Fecha,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Trabajador:",
                value: reconocimiento.NombreEmpleado,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Realizado:",
                value: reconocimiento.Realizado,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: reconocimiento.Observaciones,
                classText: classText,
                xs:12
            },
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: reconocimiento.ficheros,
                 classText: classText,
                 type: "file",
                 xs:4
                },
            
        ])
    }
},[reconocimiento]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/rrhh/talento/recmed/${id}/W`}
    />
    </>
  )
}
export default FichaFormacion;