import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import FormularioCotizacion from "./FormularioCotizacion"
import FichaCotizacion from "./FichaCotizacion"
import JornadasCotizacion from "./JornadasCotizacion"
import PresupuestoCotizacion from "./PresupuestoCotizacion"
import FormularioPedidoProductos from "../../../../../components/Common/FormularioPedidoProductos"
import {useCotizacion} from './cotizacion-context'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    }, 
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepper: {        
      display: "flex",
      padding: "12px"      
    }
}));  

const PasosCotizacion = (props) => {  
    let { modo } = useParams(); 
    const { pasoActivo, setPasoActivo } = useCotizacion();    
    const classes = useStyles(); 

    function getSteps() {
      if(modo !== "R" && modo !== "W")
      {
        return ['Datos'];
      }else{
        return ['Datos', 'Productos', 'Jornadas', 'Presupuesto'];
      }
    }   
    const steps = getSteps(); 
       
    const handleStep = (step) => () => {
      setPasoActivo(step)   
    };
    
    const getStepContent = (step) => {     
      switch (step) {
        case 0:          
          if(modo === "R") return <FichaCotizacion />; 
          else  return <FormularioCotizacion />;          
        case 1:
            return <FormularioPedidoProductos urlGetData={"cotizaciones"} urlPostData={"cotizacionesproductos"} />
        case 2:
          return <JornadasCotizacion />;
        case 3:
          return <PresupuestoCotizacion />;
        default:
          return <FichaCotizacion />;
      }    
    }   
    return (    
      <div className="bg-white mb-2 pt-3 pl-3 pr-3 pb-0 m-0">
        <div className={classes.root}>
          <Stepper nonLinear activeStep={pasoActivo} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepButton
                  onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>  
            
        <div>
          <div className={classes.instructions}>{getStepContent(pasoActivo)}</div>
        </div>                    
      </div>
      );
}
export default PasosCotizacion;