import React, { useEffect, useReducer, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
      maxWidth: '100%',
      padding: '20px',
    },
    media: {
      height: 82,
      backgroundSize: 'contain'
    },
});

const MaintenanceModeComponent = () => { 
    const classes = useStyles();

  return (
  <div className="container-fluid">
    <div className="row justify-content-center mt-5">
      <div className="col"></div>
        <div className="col mt-5">
          <div className="row justify-content-center mt-5">
            <div className="col-12 mt-5"></div>
              <div className="col-12 mt-5">
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image="imagenes/mtms_512.jpg"
                      title="Modo Mantenimiento"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h4" component="h1">
                      Modo Mantenimiento
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                       Estamos trabajando para mejorar la aplicación.
                       <br></br>
                       Rogamos disculpen las molestias.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </div>
        </div>
      <div className="col"></div>
    </div>
  </div>
  )
}
export default MaintenanceModeComponent;