import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {Bar} from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
        padding: theme.spacing(1),
        margin: '10px',        
        backgroundColor: '#FFF',
    },    
    padding16: {
        padding: theme.spacing(1),      
    }, 
    triangulo: {
        width: 0, 
        height: 0,
        borderLeft: '100px solid #f0ad4e',
        borderTop: '50px solid transparent',
        borderBottom: '50px solid transparent'
   },
   cuadrado: {
        width: '20px',
        height: '20px',
        background: '#428bca',
        display: 'flex',
        float: 'left',
        marginRight: '10px',
   },
   ejercicioanual: {
    fontSize: 18,
    fontFamily: 'Open Sans',
    fontWeight: 300,
    color: '#505050'
  },  
     
}));

const EjercicioAnual = ({ventas}) => {  
    const classes = useStyles();
    const colors = ["#FD7E27", "#373049"];
    //console.log(ventas)

    return (
        <div className={classes.root}>    
            <div className={classes.padding16}>      
            <Typography className={classes.ejercicioanual}>Ejercicio anual</Typography> 
            <hr></hr>
            </div>
            <DatosVentasEjercicios colors={colors} datosVentas={ventas}/>
            <DatosVentasMensuales colors={colors} datosVentas={ventas}/>
        </div>
    );
};
export default EjercicioAnual;

const DatosVentasEjercicios = ({colors, datosVentas}) => {
    const numberFormatter = Intl.NumberFormat('de-DE');
    const meses = datosVentas.length > 0 ? Object.entries(datosVentas[0]).slice(1).map(entry => entry[0]) : [];
    
    return(
        <>
        <div className="row">
        {meses && 
            meses.map((mes, index) => (
                <div key={index} className="col-1">
                    <Typography style={{color: "#000"}} className="text-left" gutterBottom>
                        {mes.substring(0,3)}
                    </Typography>
                </div> 
            ))
        }
        </div>
        {datosVentas && datosVentas.map((datoEjercicio, index) => (
            <div key={index} className="row">
                <div className="col-12">
                    <div className="row">
                        {meses && 
                            meses.map((mes, index2) => (
                                <div key={index2} className="col-1">
                                    <Typography style={{color: colors[index]}} className="text-left" gutterBottom>
                                        {numberFormatter.format(datoEjercicio[mes])}
                                    </Typography>
                                </div> 
                            ))
                        }
                    </div>
                </div>
                
            </div>
        ))
        }
        </>
    )
}

const DatosVentasMensuales = ({colors, datosVentas}) => {
    const meses = datosVentas.length > 0 ? Object.entries(datosVentas[0]).slice(1).map(entry => entry[0]) : [];
    let datasets = []
    let datasetsObj = {}

    datosVentas.map((dato, index) => {
        let dataset = {}
        dataset.label = dato.ejercicio;
        dataset.backgroundColor = colors[index]
        dataset.data = Object.entries(dato).slice(1).map(entry => entry[1]);
        datasets.push(dataset)
    })
    datasetsObj.datasets = datasets
    datasetsObj.labels = meses

    const opciones={
        maintainAspectRatio: false,
        responsive: true,
        plugins: {   
          title: {
            display: false,
            text: '',
          },
        },  
    }  

    return(
        <div className="row">
         <div className="col-12" style={{float: 'left',width: '20%', height: '200px'}}>
            <Bar data={datasetsObj} options={opciones}/>
        </div>
        </div>
    )
}