import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
//Ficha entidad general para reutilizar en cada ficha
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad';

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioSoporte = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [soporte, setSoporte] = useState([]) 
    const [clientes, setClientes] = useState("") 

    const handleChangeCuenta = (cuentas) => {
        if (cuentas){
          setClientes(cuentas.Id)
        }
      }   

    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([    
            //0
            {
                urlData: "dic/diccionarios/tipologiassoporte",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Tipología:",
                name: "tipologia",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: '',
                xs:4
            },
            //1
            {
                urlData: "dic/diccionarios/getdepartamentos",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Departamento:",
                name: "departamento",
                type: "combo",    
                disabled: false, 
                value: '',                
                xs:4
            },
            //2
            {
                required: true,
                label: "Fecha:",
                name: "fecha",
                type: "date",  
                value: '',
                xs:4
            },
            //3
            {
                urlData: "cuentas",
                campoEtiqueta: "Razon_Social",
                required: true,
                label: "Tercero:",
                name: "cuenta",
                type: "combo",    
                disabled: false, 
                value: '',                
                xs:4,
                onChangeValue: handleChangeCuenta
            },
            //4
            {
                urlData: "contactos",
                campoEtiqueta: "Nombre",
                required: true,
                label: "Contactos:",
                name: "contacto",
                type: "combo",    
                disabled: false, 
                value: '',                
                xs:4
            },
            //5
            {
                required: true,
                label: "Declaración cliente:",
                name: "declaracion",
                type: "textArea",    
                disabled: false, 
                value: '', 
                lineas: 3,               
                xs:12
            },
            //6
            {
                required: true,
                label: "Descripción causa:",
                name: "descripcion",
                type: "textArea",    
                disabled: false, 
                value: '',  
                lineas: 3,              
                xs:12
            },
            //7
            {
                required: true,
                label: "Soluciones a adoptar:",
                name: "solucion",
                type: "textArea",    
                disabled: false, 
                value: '',  
                lineas: 3,              
                xs:12
            },
            //8
            {
                urlData: "dic/diccionarios/listadousuarios",
                campoEtiqueta: "nombre",
                required: true,
                label: "Responsable:",
                name: "responsable",
                type: "combo",    
                disabled: false, 
                value: '',                
                xs:3
            },
            //9
            {
                required: true,
                label: "Inicio ejecución:",
                name: "fecha_ejecucion_inicial",
                type: "date",  
                value: '',
                xs:3
            },
            //10
            {
                required: true,
                label: "Fin ejecución:",
                name: "fecha_ejecucion_final",
                type: "date",  
                value: '',
                xs:3
            },
            //11
            {
                urlData: "dic/diccionarios/estadossoporte",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Estado:",
                name: "estado",
                type: "combo",    
                disabled: false, 
                value: '',                
                xs:3
            },
            //12
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_actividades_adjuntos",
                xs:12
              },    
              //13        
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: soporte.ficheros,    
                tabla: "mtms_actividades_adjuntos",
                modelo: "api\\modules\\v1\\models\\soporte\\SoporteAdjuntos",
                carpeta: "soporte",
                xs:12
              },

    ])

//////////////////////////////////////////////////////////////////////////////////////////////////////
    const fetchData = async () => { 
        if (modo === "W") {
            const respSoporte = await axios(
                `${process.env.REACT_APP_API_URL}soportes/${id}`
            );                
           setSoporte(respSoporte.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[]);

useEffect(() => {            
    if (typeof soporte !== "undefined") {        
      if(Object.keys(soporte).length > 0){              
        setDatosCargados(false)
        campos[0].value = soporte.Tipologia;
        campos[1].value = soporte.Departamento;
        campos[2].value = soporte.Fecha;
        campos[3].value = soporte.Cuenta;
        campos[4].value = soporte.Contacto;
        campos[5].value = soporte.Declaracion; 
        campos[6].value = soporte.Descripcion;           
        campos[7].value = soporte.Solucion;
        campos[8].value = soporte.Responsable;
        campos[9].value = soporte.Fecha_Ejecucion_Inicial;
        campos[10].value = soporte.Fecha_Ejecucion_Final;
        campos[11].value = soporte.Estado;
        campos[13].ficheros = soporte.ficheros;
        if (soporte.Cuenta){
            setClientes(soporte.Cuenta.Id)
        } 

 
        setDatosCargados(true)  
      }
  }
  },[soporte])

  useEffect(() => { 
    if (typeof campos !== "undefined") {        
        if(Object.keys(campos).length > 0){
          setDatosCargados(false)
          if(clientes) campos[4].urlData = "cuentas/contactos/" + clientes
          else campos[4].urlData = "contactos" 
          setDatosCargados(true)
      }
    }
  },[clientes])

if (datoscargados){
    return (
        //Es otro fichero aún sin crear y nombre de urlGoto para cambiar       
        <FormularioEntidad 
        urlForm = "soportes"
        urlGoto = "/postventa/soportes/soporte/"
        campos={campos}
/>    
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioSoporte;

