import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormControl from '@material-ui/core/FormControl';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
//import FormFieldDateUncontrolled from '../../../../../components/Common/FormFieldDateUncontrolled'
//import FormFieldTextUncontrolled from '../../../../../components/Common/FormFieldTextUncontrolled'

//import { StateContext } from '../../../../../context'



const FormularioSalidaInicial = ({listadoSalidas,ListadoSalidaFinal}) => { 
  

    const [listadoSalidaInicial, setListadoSalidaInicial] = useState([])
    const { id, modo } = useParams();
    //const [refrescar, setRefrescar] = useState(false)
    
    
        

    const columns = [
        //columnas de la tabla
        { field: 'ean', headerName: 'Código EAN', width: 120 },
        { field: 'descripcion', headerName: 'Descripción artículo', width: 200 },
        { field: 'unidad', headerName: 'Unidad', width: 100 },
        { field: 'cantidad', headerName: 'Cantidad', width: 100 },
        { field: 'fechaSalida', headerName: 'Fecha Salida', width: 120 },
        { field: 'deposito', headerName: 'Depósito', width: 120 },
        { field: '', headerName: '', width: 30, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
              ), },
    ];

    
    const resetForm = () => {
        setListadoSalidaInicial([])
    }

    useEffect(() => {
        if(  typeof listadoSalidas === 'undefined' ||
            (Array.isArray(listadoSalidas) && listadoSalidas.length === 0 )
        ){        
            resetForm()
        }else{
            setListadoSalidaInicial(listadoSalidas)
        }
       
    },[listadoSalidas])



    const handleRemoveElement = (element) =>{
        if (modo==="R") return;
        
        const elementoAEliminar = listadoSalidaInicial.filter (el => {
            return el.id !== element.id
        })
        //setListadoMaterialesInicial(elementoAEliminar)
        ListadoSalidaFinal(elementoAEliminar)
    }

   
    
    return(
        <>
        <Grid container  spacing={1} className="mt-2">
            <Grid item xs={12} className="" style={{height: 280}}>
                <DataGrid 
                    rows={listadoSalidaInicial} 
                    columns={columns} 
                    pageSize={100} 
                    checkboxSelection = {false}
                    hideFooter = {true}
                    hideFooterPagination = {true}
                    hideFooterRowCount = {true}
                    hideFooterSelectedRowCount = {true}
                />
            </Grid>
        </Grid>
        </>
    )
}
export default FormularioSalidaInicial;