import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";

const propiedadesMaquinaria = {
    urlData: "dic/diccionarios/listadomaquinariapropia",
    campoEtiqueta: "Nombre",
    required: true,
    name: "combo-maquinaria",
    disabled: false,
    label: "Maquinaria",
    value: "",
    multiple: false,
    xs:3,
}

const propiedadesFecha = {
    xs:2,
    required: false,
    label: "Fecha",
    name: "fecha",
    type: "date",    
    value: ''
}

const propiedadesHoraInicio = {
    xs:2, 
    required: false,
    label: "Hora inicio",
    name: "horainicio",
    type: "time",    
    disabled: false,
    value: ''
}
  
const propiedadesHoraFin = {
    xs:2, 
    required: false,
    label: "Hora fin",
    name: "horafin",
    type: "time",    
    disabled: false,
    value: ''
}

const propiedadesCantidad = {
    xs:1,
    required: false,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    value: ''
}

const getDateToday = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    let hoy = yyyy + '-' + mm + '-' + dd;
    return hoy;
}

const getTimeNow = (horasMas=0) => {
    let today = new Date();
    let hh = String(today.getHours() + horasMas).padStart(2, '0');
    let mm = String(today.getMinutes() + 1).padStart(2, '0');
    
    let now = hh + ':' + mm;
    return now;
}

const FormularioMantenimientoMaquinaria = ({listadotabla}) => {   
    const { id, modo } = useParams();
    const [open, setOpen] = useState(false)
    const [selectedMaquinaria, setSelectedMaquinaria] = useState(null)
    const [maquinaria, setMaquinaria] = useState([]);
    const [horaInicio, setHoraInicio] = useState(getTimeNow())
    const [horaFin, setHoraFin] = useState(getTimeNow(1))
    const [fecha, setFecha] = useState(getDateToday())
    const [cantidad, setCantidad] = useState(1)    
    const loading = open && maquinaria.length === 0;  

    useEffect(() => {
        (async () => {
            const respMaquinaria = await axios(process.env.REACT_APP_API_URL + propiedadesMaquinaria.urlData);
            setMaquinaria(Object.keys(respMaquinaria.data).map((key) => respMaquinaria.data[key]))           
          })();
        
          return () => {
            setMaquinaria([]); // This worked for me
          };
    }, [])

    const getOptionLabelMaquinaria = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesMaquinaria.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedMaquinaria = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedMaquinaria(value)
        }
        return option.Id === value.Id
    }

    const handleChangeMaquinaria = (e, v) => { 
        setSelectedMaquinaria(v)
    }

    const handleChangeHoraInicio = (event) => {         
        setHoraInicio(event.target.value)
    }

    const handleChangeHoraFin = (event) => { 
        setHoraFin(event.target.value)
    }

    const handleChangeCantidad = (event) => { 
        setCantidad(event.target.value)
    }

    const handleChangeFecha = (event) => {
        setFecha(event.target.value)
    }

    const renderInputMaquinaria = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesMaquinaria.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const handleClick = () => {   
    if(selectedMaquinaria){
        listadotabla({
            id:uuidv4(),
            recursos:"Maquinaria",
            descripcion:selectedMaquinaria.Nombre,
            fecha:fecha,
            horainicio:horaInicio,
            horafin:horaFin,
            codigo:selectedMaquinaria.Id,
            deposito:"",
            cantidad:cantidad,
            coste: selectedMaquinaria.Coste
        })
    }
}


    return (
    <>
    <Grid container  spacing={1} className="mt-2">
        
    <Grid item xs={propiedadesMaquinaria.xs} className="">              
        <Autocomplete
            id={propiedadesMaquinaria.name}
            name={propiedadesMaquinaria.name}
            getOptionLabel= { getOptionLabelMaquinaria }
            getOptionSelected={(option, value) => getOptionSelectedMaquinaria(option, value)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            label={propiedadesMaquinaria.label}        
            renderInput={renderInputMaquinaria}
            onChange={(e, v, r) => handleChangeMaquinaria(e, v, r)}  
            options={maquinaria}
            value={selectedMaquinaria}
            style={{  marginLeft: 0 }}
            disabled={ modo === "R" ? true : false }
        />            
    </Grid>

    <Grid item xs={propiedadesFecha.xs} className="" >
    <TextField                 
        required = {propiedadesFecha.required}                
        id={propiedadesFecha.name}
        name={propiedadesFecha.name}
        disabled={ modo === "R" ? true : false }
        label={propiedadesFecha.label}        
        value={fecha} 
        type="date"
        onChange={handleChangeFecha} 
        variant="outlined"
        size="small"        
        InputLabelProps={{
            shrink: true,
        }}
        keyboardbuttonprops={{
            'aria-label': 'change time',
          }}
        fullWidth
    >
    </TextField>  
    </Grid>

    <Grid item xs={propiedadesHoraInicio.xs} className="">       
        <TextField                 
            required = {propiedadesHoraInicio.required}                
            id={propiedadesHoraInicio.name}
            name={propiedadesHoraInicio.name}
            disabled={ modo === "R" ? true : false }
            label={propiedadesHoraInicio.label}        
            value={horaInicio} 
            type="time"
            onChange={handleChangeHoraInicio} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            keyboardbuttonprops={{
                'aria-label': 'change time',
            }}
            fullWidth
        >
        </TextField>  
    </Grid>

    <Grid item xs={propiedadesHoraFin.xs} className="">
        <TextField                 
            required = {propiedadesHoraFin.required}                
            id={propiedadesHoraFin.name}
            name={propiedadesHoraFin.name}
            disabled={ modo === "R" ? true : false }
            label={propiedadesHoraFin.label}        
            value={horaFin} 
            type="time"
            onChange={handleChangeHoraFin} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField>      
    </Grid>
    
    <Grid item xs={propiedadesCantidad.xs} className="">
        <TextField                 
            required = {propiedadesCantidad.required}                
            id={propiedadesCantidad.name}
            name={propiedadesCantidad.name}
            disabled={ modo === "R" ? true : false }
            label={propiedadesCantidad.label}        
            value={cantidad} 
            type="number"
            onChange={handleChangeCantidad} 
            variant="outlined"
            size="small"        
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
        >
        </TextField>      
    </Grid>
    
    <Grid item xs={2} className="" >
    <Button disabled={ modo === "R" ? true : false } className="" variant="outlined" size="large" onClick={handleClick}>
        AÑADIR
    </Button> 
    </Grid>

    </Grid>
    </>
    )
}
export default FormularioMantenimientoMaquinaria;