import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'


const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const Formularioseleccion = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [seleccion, setseleccion] = useState([]) 


    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([           
            {
                urlData: "dic/diccionarios/candidaturas",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Candidatura:",
                name: "candidatura",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            {
                required: true,
                label: "Código Candidatura:",
                name: "codigoCandidatura",
                type: "text",    
                disabled: false, 
                value: "",                
                xs:4
            },
            {
                urlData: "dic/diccionarios/estadoscandidaturas",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Estado:",
                name: "estado",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            {
                required: true,
                label: "Fecha Recepción:",
                name: "fechaRecepcion",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:4
            },
            {
                urlData: "dic/diccionarios/puestostrabajo",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Puesto:",
                name: "puesto",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: "",
                xs:4
            },
            {
                urlData: "dic/diccionarios/getdepartamentos",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Departamento:",
                name: "departamento",
                disabled: false,
                type: "combo",           
                multiple:false,   
                value: "",
                xs:4
            },
            {
                required: true,
                label: "Nombre:",
                name: "nombre",
                type: "text",    
                disabled: false, 
                value: "",                
                xs:4
            },         
            {
                required: true,
                label: "Apellidos:",
                name: "apellidos",
                type: "text",    
                disabled: false, 
                value: "",                
                xs:4
            },
            {
                required: true,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",    
                disabled: false, 
                value: "",
                lineas:4,               
                xs:12
                
            },
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_rrhh_seleccion_adjuntos",
                xs:12
              },            
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: seleccion.ficheros,    
                tabla: "mtms_rrhh_seleccion_adjuntos",
                modelo: "api\\modules\\v1\\models\\rrhh\\RRHHSeleccionAdjuntos",
                carpeta: "rrhh//seleccion",
                xs:12
              },
            
            
         
    ])

    const fetchData = async () => { 
        if (modo === "W") {
            const respseleccion = await axios(
                `${process.env.REACT_APP_API_URL}rrhhseleccions/${id}`
            );                
            setseleccion(respseleccion.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[]);

useEffect(() => {            
    if (typeof seleccion !== "undefined") {        
      if(Object.keys(seleccion).length > 0){              
        setDatosCargados(false)
        campos[0].value = seleccion.Candidatura;
        campos[1].value = seleccion.Codigo_Candidatura;
        campos[2].value = seleccion.Estado;
        campos[3].value = seleccion.Fecha_Recepcion;
        campos[4].value = seleccion.Puesto;
        campos[5].value = seleccion.Departamento;
        campos[6].value = seleccion.Nombre;
        campos[7].value = seleccion.Apellidos;
        campos[8].value = seleccion.Observaciones;            
        campos[10].ficheros = seleccion.ficheros;

        setDatosCargados(true)  
      }
  }
  },[seleccion])

if (datoscargados){
    return ( 
        <FormularioEntidad 
        urlForm = "rrhhseleccions"
        urlGoto = "/rrhh/talento/seleccions/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default Formularioseleccion;