import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'
import AlertaMensaje from "../../../../../components/Common/AlertaMensaje";

const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioVacaciones = () => {
    const { id, modo } = useParams();
    const classes = useStyles();     
    const [datoscargados, setDatosCargados] = useState(false);  
    const [dias, setDias] = useState(0)
    const [campos, setCampos] = useState([])  
    const [seleccion, setseleccion] = useState([]) 
    const fechaInicio = useRef(''); 
    const fechaFin = useRef('');
    const classDiv3 = "col-3 mt-2 mb-2"  
    const [openAlert,setOpenAlert] = useState({
        open : false,
        tipoMensaje : "success",
        mensaje : ""
      });  

      const handleCloseAlert = (event, reason)=>{
        if(reason === "clickaway"){
          return
        }
        setOpenAlert({...openAlert,open:false});
      }
    

useEffect(() => { 

    setCampos([  
        //0         
            {
                urlData: "dic/diccionarios/listadousuarios",
                campoEtiqueta: "nombre",
                required: true,
                label: "Trabajador:",
                name: "trabajador",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            //1
            {
                urlData: "dic/diccionarios/getdepartamentos",
                campoEtiqueta: "Etiqueta",
                required: true,
                label: "Departamento:",
                name: "departamento",
                disabled: false,
                type: "combo",
                value: "",              
                xs:4
            },
            //2
            {
                urlData: "dic/diccionarios/listadousuarios",
                campoEtiqueta: "nombre",
                required: false,
                label: "Validar:",
                name: "Validacion",
                disabled: false,
                type: "combo",
                multiple: false,
                value: "",              
                xs:4
            },  
            //3
            {
                required: true,
                label: "Fecha Inicio:",
                name: "fechaInicio",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:4,
                onChangeValue: handleChangeFechaInicio
            },
            //4
            {
                required: true,
                label: "Fecha Fin:",
                name: "fechaFin",
                disabled: false,
                type: "date",           
                multiple:false,   
                value: "",
                xs:4,
                onChangeValue: handleChangeFechaFin
            },
            /*
            {
                required: false,
                label: "Días:",
                name: "dias",
                type: "text",    
                disabled: true, 
                value: dias,                
                xs:4
            },  
            */
           //5
            {
                required: true,
                label: "Observaciones:",
                name: "observaciones",
                type: "textArea",    
                disabled: false, 
                value: "",
                lineas:4,               
                xs:12
                
            },
            //6
            {
                classButton: classDiv3,
                required: false,
                label: "Documentación",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_rrhh_seleccion_adjuntos",
                xs:12
              },    
              //7        
              {
                required: false,
                label: "Documentación",
                name: "ficheros",
                type: "files", 
                ficheros: seleccion.ficheros,    
                tabla: "mtms_rrhh_seleccion_adjuntos",
                modelo: "api\\modules\\v1\\models\\rrhh\\RrhhVacacionesAdjuntos",
                carpeta: "rrhh//vacaciones",
                xs:12
              },             
    ])

    const fetchData = async () => { 
        if (modo === "W") {
            const respseleccion = await axios(
                `${process.env.REACT_APP_API_URL}rrhhvacaciones/${id}`
            );       
            setseleccion(respseleccion.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[]);

const handleChangeFechaInicio = (valor) => {        
    fechaInicio.current = new Date(valor);
    let diffDays = fechaFin.current.getDate() - fechaInicio.current.getDate()
    setOpenAlert({
        open : true,
        tipoMensaje : "info",
        mensaje : `${diffDays} de vacaciones asignados. No se olvide de guardar los cambios`
    })
    //setDias(diffDays + 1)
}

const handleChangeFechaFin = (valor) => {    
    console.log(valor)    
    fechaFin.current = new Date(valor);
    let diffDays = fechaFin.current.getDate() - fechaInicio.current.getDate()
    
    setOpenAlert({
        open : true,
        tipoMensaje : "info",
        mensaje : `${diffDays} de vacaciones asignados. No se olvide de guardar los cambios`
    })
    //setDias(diffDays + 1)      
}

useEffect(() => {            
    if (typeof seleccion !== "undefined") {        
      if(Object.keys(seleccion).length > 0){        
           
        let fechaIni = new Date(seleccion.Fecha_Inicio);
        fechaInicio.current = fechaIni 
        let fechaF = new Date(seleccion.Fecha_Fin);
        fechaFin.current = fechaF 
        let diffDays = fechaF.getDate() - fechaIni.getDate() + 1;
        setDias(diffDays)
        
        setDatosCargados(false)
        campos[0].value = seleccion.Trabajador;
        campos[1].value = seleccion.Departamento;
        campos[2].value = seleccion.Valida || '';
        campos[3].value = seleccion.Fecha_Inicio;
        campos[4].value = seleccion.Fecha_Fin;              
        campos[5].value = seleccion.Observaciones;            
        campos[7].ficheros = seleccion.ficheros;
        setDatosCargados(true)  
      }
  }
},[seleccion])
 
if (datoscargados){
    return ( 
        <>
        <FormularioEntidad 
        urlForm = "rrhhvacaciones"
        urlGoto = "/rrhh/gestionpersonal/vacaciones/"
        campos={campos}
        />  
        {
            openAlert.open && (
                <AlertaMensaje 
                    mensaje={openAlert.mensaje} 
                    isOpen={openAlert.open} 
                    tipoMensaje={openAlert.tipoMensaje}
                    cerrar={handleCloseAlert}
                />                  
            )
        }   
        </>
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }
}
export default FormularioVacaciones;