import React, { useState, useEffect } from "react";
import axios from "axios";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
//import { StateContext } from '../../../../../context'
//import { v4 as uuidv4 } from 'uuid';



const propiedadesMaterial = {
    urlData: "materials",
    campoEtiqueta: "Nombre",
    required: true,
    name: "combo-nombre",
    disabled: false,
    label: "Material",
    value: "",
    multiple: false,
    xs:3,
  }
  
  const propiedadesCantidad = {
    xs:2, 
    required: true,
    label: "Cantidad",
    name: "cantidad",
    type: "number",    
    disabled: false,
    value: ''
  }
  const propiedadesFechaSalida = {
    xs:3, 
    required: true,
    label: "Fecha Salida",
    name: "fechaSalida",
    type: "date",    
    disabled: false,
    value: ''
  }
  const propiedadesDeposito = {
    urlData: "depositos",
    campoEtiqueta: "nombre",
    required: true,
    name: "combo-deposito",
    disabled: false,
    label: "Deposito",
    value: "",
    multiple: false,
    xs:2,
  }

  const FormularioSalida = ({listadotabla}) => {
  
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedDeposito, setSelectedDeposito] = useState(null);
    const [material, setMaterial] = useState([])

    const [cantidad, setCantidad] = useState()
    const [fechaSalida, setFechaSalida] = useState()
    const [deposito, setDeposito] = useState([])
    const loading = open && material.length === 0;
    const loadingDeposito = open2 && deposito.length === 0;

    useEffect(() => {   

        (async () => {
            const respMateriales = await axios(process.env.REACT_APP_API_URL + propiedadesMaterial.urlData );
            setMaterial(Object.keys(respMateriales.data).map((key) => respMateriales.data[key])) 

            const respDepositos = await axios(process.env.REACT_APP_API_URL + propiedadesDeposito.urlData );
            setDeposito(Object.keys(respDepositos.data).map((key) => respDepositos.data[key])) 

          })();

          return () => {
            setMaterial([]); 
            setDeposito([]); 
          };
          


    }, [])
 
    const handleChangeMaterial = (e, v) => {         
        setSelectedMaterial(v)
    }
    const handleChangeCantidad = (event) => {         
        setCantidad(event.target.value)
    }

    const handleChangeFechaSalida = (event) => { 
        setFechaSalida(event.target.value)
    }
    const handleChangeDeposito = (e, v) => {         
        setSelectedDeposito(v)
    }

    const getOptionLabelMaterial = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesMaterial.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionLabelDeposito = (option) => {
        let etiqueta = "";
        if(option){
          const etiquetas = propiedadesDeposito.campoEtiqueta.split(',')
          etiquetas.forEach(element => etiqueta += option[element] + " ");
        }        
        return etiqueta  
    }

    const getOptionSelectedMaterial = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedMaterial(value)
        }
        return option.Id === value.Id
    }

    const getOptionSelectedDeposito = (option, value) => {
        if( option.Id === value.Id ) 
        {
            setSelectedDeposito(value)
        }
        return option.Id === value.Id
    }

    const renderInputMaterial = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesMaterial.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }

    const renderInputDeposito = (params) => {    
        return (
            <TextField  {...params}
              label={propiedadesDeposito.label}
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}             
            />
        )
    }


    const handleClick = () => { 
        listadotabla({
            id:selectedMaterial.id,
            ean:selectedMaterial.ean,
            descripcion:selectedMaterial.descripcion,
            unidad:selectedMaterial.tipoUnidad,
            cantidad:cantidad,
            fechaSalida:fechaSalida,
            deposito:selectedDeposito.nombre,
            idDeposito:selectedDeposito.id
        })
    }
   
    return (
    <>
    <Grid container  spacing={1} className="mt-4 ml-4 borderFormulario">  

    <Grid item xs={propiedadesMaterial.xs} className="">
        <Autocomplete
            id={propiedadesMaterial.name}
            name={propiedadesMaterial.name}
            getOptionLabel= { getOptionLabelMaterial }
            getOptionSelected={(option, value) => getOptionSelectedMaterial(option, value)}
            open={open}
            loadingText="Cargando..."
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}   
            loading={loading}
            label={propiedadesMaterial.label}        
            renderInput={renderInputMaterial}
            onChange={(e, v, r) => handleChangeMaterial(e, v, r)}  
            options={material}
            value={selectedMaterial}
            style={{  marginLeft: 0 }}
        />            
    </Grid>

    <Grid item xs={propiedadesFechaSalida.xs} className="" >
    <TextField                 
        required = {propiedadesFechaSalida.required}                
        id={propiedadesFechaSalida.name}
        name={propiedadesFechaSalida.name}
        disabled = {propiedadesFechaSalida.disabled}
        label={propiedadesFechaSalida.label}        
        value={fechaSalida} 
        type="date"
        onChange={handleChangeFechaSalida} 
        variant="outlined"
        size="small"  
        style={{  marginLeft: 0 }}      
        InputLabelProps={{
            shrink: true,
        }}
    >
    </TextField>  
    </Grid>

    <Grid item xs={propiedadesCantidad.xs} className="" >
      <TextField                 
        required = {propiedadesCantidad.required}                
        id={propiedadesCantidad.name}
        name={propiedadesCantidad.name}
        disabled = {propiedadesCantidad.disabled}
        label={propiedadesCantidad.label}        
        value={cantidad} 
        type="number"
        onChange={handleChangeCantidad} 
        variant="outlined"
        size="small" 
        style={{  marginLeft: 0 }}       
        InputLabelProps={{
            shrink: true,
        }}
      >
    </TextField>  
    </Grid>

    <Grid item xs={propiedadesDeposito.xs} className="" >
      <Autocomplete
            id={propiedadesDeposito.name}
            name={propiedadesDeposito.name}
            getOptionLabel= { getOptionLabelDeposito }
            getOptionSelected={(option, value) => getOptionSelectedDeposito(option, value)}
            open={open2}
            loadingText="Cargando..."
            onOpen={() => { setOpen2(true); }}
            onClose={() => { setOpen2(false); }}   
            loading={loadingDeposito}
            label={propiedadesDeposito.label}        
            renderInput={renderInputDeposito}
            onChange={(e, v, r) => handleChangeDeposito(e, v, r)}  
            options={deposito}
            value={selectedDeposito}
            style={{  marginLeft: 0 }}
      />              
    </Grid>

    <Grid item xs={2} className="" >
    <Button className="" variant="outlined" size="large" onClick={handleClick}>
        AÑADIR
    </Button> 
    </Grid>
    </Grid>
    </>
    )
}
export default FormularioSalida;