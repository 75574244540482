import React, { useState } from "react";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";
import {columnsReparacion} from "./ServicioConf";
import {botoneraReparacion} from "./BotonesConf";
import SearchBar from "../../../../../components/Common/SearchBar";

const Instalaciones = (props) => {         
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [tipoInstalacion, setTipoInstalacion]=useState(localStorage.getItem('idTipoInstalacion') || "Abiertas");    
   

  const marcarFila = (fila) => {
    setFilaSeleccionada(fila);
  }

  const handleOperacion = (idO) =>{  
    //console.log(idO)      
    localStorage.setItem('idTipoInstalacion', idO);     
    setTipoInstalacion(idO)
  } 

  let columnasCuerpoTabla = columnsReparacion;
  let urlDatosCuerpoTabla = "reparacions";
  let urlDoubleClickCuerpoTabla = "instalaciones/instalacion/";
  let columnIdCuerpoTabla = "Id";
  let botonaccionCuerpoTabla = botoneraReparacion;

  const filtroToolbar = true
  const filtroToolbarOpciones = ['Abiertas', 'Cerradas', 'Todas']
  const urlDatos = tipoInstalacion === "Abiertas" ? "reparacions?estado=1" : (tipoInstalacion === "Cerradas" ? "reparacions?estado=2" : "reparacions");

  return (           
      <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Servicios"
          claseCabecera = "bg-produccion"
          buscar={false}
          imagenCabecera={"5-4-2-reparaciones.png"}
        />   
        
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <div className="p-1 bg-white mb-3">
              <h6 className="texto-comercial float-left" id="title">
                Instalaciones
              </h6>
            </div>
            <hr className="m-0 bg-comercial mb-1" />
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
             

             <CuerpoTablaPiezas
                columnas = {columnasCuerpoTabla}
                urlDatos = {urlDatos}
                urlDoubleClick = {urlDoubleClickCuerpoTabla}
                columnId = {columnIdCuerpoTabla}
                getFilaSeleccionada={marcarFila}
                ordenColumnas = {[0,1,2,4,3,5,6]}
                filtroToolbar={filtroToolbar}
                filtroToolbarOpciones = {filtroToolbarOpciones}
                filtroToolbarValor = {tipoInstalacion}
                filtroToolbarChangeValor={handleOperacion}
                filtroEjercicio = {true}
              /> 
            </div>
          </div>

          <div className="bg-cuerpo">
            <div className="bg-white">
              <TableAcciones                
                filaSeleccionada={filaSeleccionada}                
                botonaccion={botonaccionCuerpoTabla}
              />            
            </div>
          </div>

        </div>
          
      </div>   
    
  );
}
export default Instalaciones;