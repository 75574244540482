import React, { createContext, useState, useMemo, useContext } from "react";

const FacturaVentaContext = createContext();


export function FacturaVentaProvider(props) {
    const [listadoProductosFacturaVenta, setListadoProductosFacturaVenta] = useState([])
    const [operacion, setOperacion] = useState([])
    const [datosFactura, setDatosFactura] = useState({})
    const [totalFacturaVenta, setTotalFacturaVenta] = useState({baseImponible: 0, igic: 0, irpf: 0, total: 0, 
        extaCosteJ3: 0, extaCosteJ4: 0, extaCosteJ5: 0, extaCosteJ6: 0, extaCosteJ7: 0, extaCosteJ8: 0, extaCosteJ9: 0, extaCosteJ10: 0})

    const value = useMemo(() => {
        return ({
            listadoProductosFacturaVenta, setListadoProductosFacturaVenta,
            operacion, setOperacion,
            datosFactura, setDatosFactura,
            totalFacturaVenta, setTotalFacturaVenta

        })
    },[
        listadoProductosFacturaVenta, setListadoProductosFacturaVenta,
        operacion, setOperacion,
        datosFactura, setDatosFactura,
        totalFacturaVenta, setTotalFacturaVenta
    ])

    return <FacturaVentaContext.Provider value={value} {...props} />
}


export function useFacturaVenta(){
    const context = useContext(FacturaVentaContext)

    if(!context){
        throw new Error('useFacturaVenta debe estar dentro del proveedor FacturaVentaContext')
    }

    return context;
}