import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'


const useStyles = makeStyles((theme) => ({ 
    linear: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
}));

const FormularioDocumentacion = () => {
    const { id, modo } = useParams();
    const classes = useStyles(); 

    const [datoscargados, setDatosCargados] = useState(false);  
    const [campos, setCampos] = useState([])  
    const [documentacion, setDocumentacion] = useState([]) 


    const classDiv3 = "col-3 mt-2 mb-2"

    useEffect(() => { 

        setCampos([         
            {
                required: true,
                label: "Nombre:",
                name: "Nombre",
                disabled: false,
                type: "text",           
                value: "",
                xs:6
            },
            {
                required: true,
                label: "Descripción:",
                name: "descripcion",
                type: "textArea",
                lineas: 4,    
                disabled: false, 
                value: "",                
                xs:12
            },  
            {
                classButton: classDiv3,
                required: false,
                label: "Documentacion",
                name: "ficherosadjuntos",
                type: "filesupload",   
                tabla: "mtms_rrhh_documentacion_adjuntos",
                xs:12
              },            
              {
                required: false,
                label: "Documentacion",
                name: "ficherosremotos",
                type: "files", 
                ficheros: documentacion.ficheros,    
                tabla: "mtms_rrhh_documentacion_adjuntos",
                modelo: "api\\modules\\v1\\models\\rrhh\\RRHHDocumentacionAdjuntos",
                carpeta: "rrhh//documentacion",
                xs:12
              },                
    ])

    const fetchData = async () => { 
        if (modo === "W") {
            const respdocumentacion = await axios(
                `${process.env.REACT_APP_API_URL}rrhhdocumentacions/${id}`
            );                
            setDocumentacion(respdocumentacion.data)  
        }
        setDatosCargados(true);
  
    };    
    fetchData();       
},[]);

useEffect(() => {            
    if (typeof documentacion !== "undefined") {        
      if(Object.keys(documentacion).length > 0){              
        setDatosCargados(false)
        campos[0].value = documentacion.Nombre;
        campos[1].value = documentacion.Descripcion;            
        campos[3].ficheros = documentacion.ficheros;

        setDatosCargados(true)  
      }
  }
  },[documentacion])

if (datoscargados){
    return (
        <FormularioEntidad 
        urlForm = "rrhhdocumentacions"
        urlGoto = "/rrhh/talento/documentacion/"
        campos={campos}
        />        
    )
    }
    else {
    return (              
        <div className={classes.linear}>
            <CircularProgress />
        </div>
    );
    }

}


export default FormularioDocumentacion;