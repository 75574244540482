import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from '@material-ui/core/Grid';

const FormFieldSelectUncontrolled = ({ propiedades, onChangeValue=null }) => { 
    
    useEffect(() => {
        return () => {            
        }
    }, [propiedades])

    const handleChange = (event) => {   
        if(onChangeValue){
           onChangeValue(event.target.value)
        } 
    }

    return (               
    <Grid item xs={propiedades.xs} className={propiedades.classes} >  
    <TextField
        name={propiedades.name}
        id={propiedades.name}
        select
        label={propiedades.label}
        required = {propiedades.required}
        disabled = {propiedades.disabled}
        variant="outlined"
        size="small"
        fullWidth
        InputLabelProps={{
            shrink: true,
        }}
        value={propiedades.value}
        onChange={handleChange}       
    >
        {propiedades.opciones.map((option) => (
            <MenuItem key={option.Id} value={option.Id}>
            {option.Etiqueta}
            </MenuItem>
        ))} 
    </TextField>
    </Grid>       
    )
}
export default FormFieldSelectUncontrolled;