import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormularioEntradaInicial from "./FormularioEntradaInicial";
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";
import FormularioEntrada from "./FormularioEntrada";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },    
}));

const FormularioMaterialEntradas = ({index, tipoForm}) => {
  
    const [entrada, setEntrada] = useState([]) 
    const [campos, setCampos] = useState([]) 
    const { id, modo } = useParams();

    const [listadoEntradas, setListadoEntradas] = useState([])
    const [ficheros, setFicheros] = useState([])
    const [nombresFicheros, setNombresFicheros] = useState([])
    const [openAlert, setOpenAlert] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const classes = useStyles();
    const methods = useForm();
    const { control, handleSubmit, setValue} = useForm();
    
    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }
    
    const handleListadoTabla=(valor) => {
      console.log(valor)
      console.log(listadoEntradas)
      if(valor){
      if(listadoEntradas)
        setListadoEntradas([...listadoEntradas,valor])
      else setListadoEntradas([valor])
      }
    }

    const handleRemoveElement = (element) =>{
      setListadoEntradas(element) 
    }

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert(false);
    }; 

    useEffect(() => {     
      
      setCampos([
       
          {
            urlData: "dic/diccionarios/notaentradaalmacentipologia",
            campoEtiqueta: "Etiqueta",
            required: true,
            name: "tipologia",
            label: "Tipologia",
            type: 'combo',
            value: '',
            multiple: false,
            xs: 4
          },
          {
            urlData: "ordenescompras/cerradas",
            campoEtiqueta: "Codigo",
            campoId: "Id",
            required: true,
            name: "codigo",
            label: "Codigo",
            type: 'combo',
            value: '',
            multiple: false,
            xs: 4
          },
          {
            required: true,
            label: "Fecha",
            name: "fecha",
            type: "date", 
            value: '',
            xs: 4
          },
          {
            urlData: "cuentas/proveedores",
            campoEtiqueta: "Nombre_Comercial",
            required: true,
            name: "tercero",
            label: "Proveedor",
            value: '',
            type:'combo',
            multiple: false,            
            xs: 4
          },
          {
            urlData: "dic/diccionarios/notaentradaalmacenestado",
            campoEtiqueta: "Etiqueta",
            required: true,
            label: "Estado",
            name: "estado",
            type: 'combo',    
            value: '',
            multiple: false,
            xs: 4
          },        
          {
            required: true,
            name: "numeroAlbaran",
            label: "Numero Albaran",
            value: '',
            multiple: false,
            xs: 4
          },
          {
            campoEtiqueta: "observaciones",
            required: false,
            name: "observaciones",
            label: "Observaciones",
            value: '',
            multiple: false,
            type: 'textarea',
            lineas: 4,
            xs: 12
          },
          {
            required: false,
            label: "Documentacion",
            name: "filesupload",
            type: "filesupload",   
            tabla: "mtms_actividades_adjuntos",
            xs:12
          },
          {
            required: false,
            label: "Documentacion",
            name: "files",
            type: "files", 
          // ficheros: material.ficheros,    
            tabla: "mtms_actividades_adjuntos",
            modelo: "api\\modules\\v1\\models\\notaentrada\\NotaentradaAlmacenAdjuntos",
            carpeta: "nota-entrada-almacen",
            xs:12
          }
      ])

      const fetchData = async () => {
        setIsLoading(true) 
        if (modo === "W" || modo === "R") {
            const respEntrada = await axios(
                `${process.env.REACT_APP_API_URL}entradamaterials/${id}`
            );   
            setEntrada(respEntrada.data)              
        }
        setIsLoading(false);
      };    
      fetchData();       
    },[]);

  

    useEffect(() => {
        if (typeof entrada !== "undefined") { 
          if(Object.keys(entrada).length > 0){     
            console.log(entrada)       
            setIsLoading(true)           
            campos[0].value = entrada.tipologia; 
            if(entrada.origen){
              if(entrada.origen.Codigo){             
                campos[1].campoEtiqueta = "Codigo"
              }
            }         
            campos[1].value = entrada.origen;
            campos[2].value = entrada.fecha;
            campos[3].value = entrada.cuenta;
            campos[4].value = entrada.estado;
            campos[5].value = entrada.nAlbaran;           
            campos[6].value = entrada.observaciones;
            campos[8].ficheros = entrada.ficheros;
            setListadoEntradas(entrada.materiales)
            setIsLoading(false)        
          }
        }
    }, [entrada])

    const handleChangeTipologia = (valor) => {    
      let url="ordenescompras/cerradas";
      let etiqueta="Codigo" 
      if(valor){
        switch(valor.Etiqueta){
          case "COMPRA":
            url = "ordenescompras/cerradas"
            etiqueta = "Codigo"
            break;
          case "DEVOLUCIÓN":
            url = "operaciones"
            etiqueta = "Codigo"
            break;
          case "REPOSICIÓN":
            url = "salidamaterials"
            etiqueta = "notaSalida"
            break;  
          
          default: 
            url = "ordenescompras/cerradas"
            etiqueta = "Codigo" 
        }       
      } 
      campos[1].urlData = url
      campos[1].campoEtiqueta = etiqueta    
    }

    const handleChangeOrigen = (valor) => {
      if (typeof valor !== "undefined") { 
        if(Object.keys(valor).length > 0){
          console.log(valor)  
          setListadoEntradas(valor.articulos)
        }
      } 
    }

    const handleSubmitPost = async (datos) => {    
      
      let datosFormulario = {};
      datosFormulario.tipologia = datos.tipologia.Id
      datosFormulario.codigo = datos.codigo
      datosFormulario.fecha = datos.fecha
      datosFormulario.tercero = datos.tercero.Id
      datosFormulario.estado = datos.estado.Id
      datosFormulario.numeroAlbaran = datos.numeroAlbaran
      datosFormulario.observaciones = datos.observaciones
      datosFormulario.ficheros = ficheros
      datosFormulario.nombresFicheros = nombresFicheros
      //datosFormulario.ficherosadjuntos = datos.ficherosadjuntos
      //array de valores donde esten todos los variables de la tabla
      datosFormulario.listadotabla=listadoEntradas
      console.log(datos)
    
      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}entradamaterials`
    
      console.log(datosFormulario)
        if (modo === "W") {
          await axios
            .put(`${SERVER_URL}/${id}`, {
              headers: {        
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              datosFormulario,
            })
            .then((response) => {       
              console.log(response.data) 
              if(!isNaN(response.data.Id)){
                if(parseInt(response.data) === parseInt(id)){                       
                    setOpenAlert(true)
                }
              }
              })
              .catch((error) => {
                console.log("error " + error);
          });
        }
        else{
          await axios
            .post(SERVER_URL, {
              headers: {        
                "Content-Type": "application/json",
                Authorization: AuthStr,
              },
              datosFormulario,
            })
            .then((response) => {       
              console.log(response.data) 
              if(!isNaN(response.data)){
                //if(parseInt(response.data.Id) === parseInt(id)){                       
                    setOpenAlert(true)
               //}
              }
              })
              .catch((error) => {
                console.log("error " + error);
          });
        }    
    }
  
  return (
    <>    
      {!isLoading && (
      <>
        {/* FORMULARIO */}
        <div className={classes.root}>
            <FormProvider {...methods}>
              <form
                id='formulario-entrada'
                onSubmit={handleSubmit((data) => {
                handleSubmitPost(data);
                })}
                className=""
              >
    
              <Grid className="borderFormulario" container spacing={1}> 
                  <FormFieldCombo propiedades={campos[0]} control={control} onChangeValue={handleChangeTipologia}/>
                  <FormFieldCombo propiedades={campos[1]} control={control} onChangeValue={handleChangeOrigen}/>
                  <FormFieldDate propiedades={campos[2]} control={control}/>
                  <FormFieldCombo propiedades={campos[3]} control={control}/>
                  <FormFieldCombo propiedades={campos[4]} control={control}/>
                  <FormFieldText propiedades={campos[5]} control={control}/>
                  <FormFieldTextArea propiedades={campos[6]} control={control}/>
                  <FormFieldFiles propiedades={campos[8]} control={control} ficheros={handleFicheros}/>
                  <FormFieldFileUpload propiedades={campos[7]} control={control} handleFicheros={handleFicheros}/>
              </Grid>
              </form>
              </FormProvider>
              <FormularioEntrada listadotabla= {handleListadoTabla}/>
              <FormularioEntradaInicial listadoEntradas={listadoEntradas} ListadoEntradaFinal={handleRemoveElement} />
             
              {openAlert && 
              <AlertaMensaje 
                mensaje={"¡Parte guardado correctamente!"} 
                isOpen={openAlert} 
                tipoMensaje="success"
                cerrar={handleCloseAlert}
              />
              }    
        </div>  
      </>
      )}
        
      {isLoading && 
        <div className={classes.linear}>
          <LinearProgress />
        </div>
      }
       
    </>
  )

  };

  export default FormularioMaterialEntradas;