import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//import { DataGrid } from '@material-ui/data-grid';
import { DataGrid } from '@mui/x-data-grid';

import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { TextField } from "@material-ui/core";

const TablaCompra = ({listadoCompra,ListadoCompraFinal}) => {  

    const [listadoCompraInicial, setListadoCompraInicial] = useState([])          
    const { modo } = useParams();

    const columns = [       
        { field: 'codigo', headerName: 'Código', width: 100 },
        { field: 'descripcion', headerName: 'Descripción Artículo', width: 150 },
        { field: 'unidad', headerName: 'Unidad', width: 90 },
        { field: 'cantidad', headerName: 'Cantidad',  type: 'number',            
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),          
        width: 90, sortable: false, editable: true },
        { field: 'precioUnidad', headerName: 'Precio UD', type: 'number',
        renderCell: (params) => ( <TextField type="number" value={params.value}  /> ),
        width: 100, sortable: false, editable: true },
        { field: 'igic', headerName: 'IGIC. %', width: 80, sortable: false, },  
        { field: 'irpf', headerName: 'IRPF. %', width: 80, type: 'number', sortable: false },
        { field: 'precioTotal', headerName: 'Precio Total', width: 100, type: 'number', sortable: false,
        valueFormatter: (params) => {
            const valueFormatted = Number(params.value).toLocaleString();
            return `${valueFormatted} €`;
          },
        },
        //{ field: 'cantidad', headerName: 'Cantidad', width: 90 },
        //{ field: 'precioUnidad', headerName: 'Precio UD', width: 120 },
        //{ field: 'igic', headerName: '% IGIC', width: 90 },
        //{ field: 'irpf', headerName: '% IRPF', width: 90 },
        //{ field: 'precioTotal', headerName: 'Precio Total', width: 120 },
        { field: 'proveedores', headerName: 'Proveedor', width: 120 },
        { field: 'depositos', headerName: 'Almacén', width: 100 },
        { field: '', headerName: '', width: 80, 
            renderCell: (params) => (
                  <DeleteIcon onClick={() => handleRemoveElement(params.row)}/>
              ), },
    ];
    
    const resetForm = () => {
        setListadoCompraInicial([])
    }

    useEffect(() => {
        if(  typeof listadoCompra === 'undefined' ||
            (Array.isArray(listadoCompra) && listadoCompra.length === 0 )
        ){        
            resetForm()
        }else{
            setListadoCompraInicial(listadoCompra)
        }       
    },[listadoCompra])

    const handleRemoveElement = (element) =>{
        if (modo==="R") return;

        const elementoAEliminar = listadoCompraInicial.filter (el => {
            return el.id !== element.id
        })
        ListadoCompraFinal(elementoAEliminar)
    }  
    
    const handleCellEditCommit = React.useCallback(
        ({ id, field, value }) => {            
          console.log(id, field, value)

          if (field === 'cantidad') {                       
            const updatedRows = listadoCompraInicial.map((row) => {
              if (row.id === id) {               
                return { ...row,  cantidad: value, precioTotal: parseFloat(value*row.precioUnidad)};
              }
              return row;
            });              
            ListadoCompraFinal(updatedRows)         
          }

          if (field === 'precioUnidad') {                    
            const updatedRows = listadoCompraInicial.map((row) => {
              if (row.id === id) {
                return { ...row,  precioUnidad: value, precioTotal: parseFloat(value*row.cantidad)};
              }
              return row;
            });            
            ListadoCompraFinal(updatedRows)                   
          }         
        },
        [listadoCompraInicial],
    );

    let unidades = 0
    listadoCompraInicial.forEach(element => unidades += parseFloat(element.precioTotal))
    
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('de-DE', options2);
    const total = numberFormat2.format(unidades)
    
    return(
        <>
        <Grid container  spacing={0} className="mt-2">
            <Grid item xs={12} className="" style={{height: 240}}>
                <DataGrid 
                    rows={listadoCompraInicial} 
                    columns={columns} 
                    density = {'compact'}
                    onCellEditCommit={handleCellEditCommit}
                    components={{
                    Footer: () => <div style={{padding : 5, marginRight: 20, float: 'right', fontSize: 14}}>TOTAL: {total}</div>,
                }}
                />
            </Grid>
        </Grid>
        </>
    )
}
export default TablaCompra;