import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
const FormFieldTextUncontrolled = ({ propiedades, onChangeValue=null }) => { 
    const { modo } = useParams();
    const handleChange = (v) => {
        if(onChangeValue){
            onChangeValue(v)
        } 
    }
    
    return (               
    <Grid item xs={propiedades.xs} className={propiedades.classes} > 
        <TextField
            required = {propiedades.required}
            id={propiedades.name}
            disabled={modo==="R" || propiedades.disabled ?true:false}
            label={propiedades.label}
            variant="outlined"
            size="small"            
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            value={propiedades.value}
            onChange={data => handleChange(data.target.value)}
        />
    </Grid>       
    )
}
export default FormFieldTextUncontrolled;
