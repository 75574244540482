import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { TEXT_LABELS } from "../../../../../configuraciones/conf";


const FichaPiezas = () => {
    let { id } = useParams();

    const [pieza, setPieza] = useState([])  
    const [campos, setCampos] = useState([]) 
    const [recepciones, setRecepciones] = useState()
    const [isLoading, setIsLoading] = useState(true)

    
    
    useEffect(() => {        
        const fetchData = async () => {          
          setIsLoading(true)
          const respPieza = await axios(`${process.env.REACT_APP_API_URL}piezas/${id}`);    
          setPieza(respPieza.data)  
          setIsLoading(false)          
        };
        fetchData();  
    }, [id]); 
    
   
    
    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(pieza).length > 0){ 
            setIsLoading(true)        
            setCampos([
            {
                classsLabel: "",
                label: "Codigo Pieza:",
                value: pieza.codigo,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha creación:",
                value: pieza.fechaCreacion,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Peso:",
                value: pieza.peso,
                classText: classText,
                xs:4
            },

            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Nombre Pieza:",
                value: pieza.nombre,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Categoria:",
                value: pieza.categoria.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Medidas Largo x Ancho x Alto:",
                value: pieza.largo+"x"+pieza.ancho+"x"+pieza.alto,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            },            
            {
                classsLabel: "",
                label: "Descripción:",
                value: pieza.descripcion,
                classText: classText,
                xs:12
            },            
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            }, 
            {
                classsLabel: "",
                label: "Observaciones:",
                value: pieza.observaciones,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "",
                value: "",
                classText: classText,
                xs:12
            }, 
            {
                classsLabel: "",
                label: "Documentación:",
                value: pieza.ficheros,
                classText: classText,
                type: "file",
                xs:12
            },

            ])
            setIsLoading(false)
    }
    },[pieza])  
    

    useEffect(() => {
        const fetchData = async () => {  
            setIsLoading(true)
            const respRecepciones = await axios(`${process.env.REACT_APP_API_URL}almacenamientos/recepcionesxpieza/${id}`);            
            setRecepciones(respRecepciones.data); 
            setIsLoading(false)
        };
        fetchData();  
    },[id])
    
    const columnsRecepcion = [     
        {
         name: "id",
         label: "Nº",
         options: {
          filter: false,
          sort: true,
         }
        },    
        {
         name: "codigo",
         label: "Código",
         options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {         
            const idEntrada = tableMeta.tableData[tableMeta.rowIndex].id
            if(idEntrada){ 
                const url = "operativas/almacenamientos/" + idEntrada + "/R"
                return(<a href={url}>{value}</a>)
            }
            return value;
           }
         }
        },        
        {
         name: "fechaEntrada",
         label: "Fecha Entrada",
         options: {
          filter: false,
          sort: true,
         }
        },  
        {
            name: "salidaEstimada",
            label: "Salida Estimada",
            options: {
             filter: false,
             sort: true,
            }
        },          

        {
            name: "salida",
            label: "Fecha Salida",
            options: {
             filter: false,
             sort: true,
            }
        },   
        {
            name: "almacen",
            label: "Almacén",
            options: {
             filter: false,
             sort: true,
            }
        },   
        {
            name: "unidades",
            label: "Unidades",
            options: {
             filter: false,
             sort: true,
            }
        },             
    ];

    const OPTIONS_COLUMNS = {
        print: false,
        download: false,
        pagination: false,
        selectableRows: 'single',  
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        elevation:0,          
        responsive: "vertical",
        textLabels: TEXT_LABELS
    };    
    

return( 
    <> 
    {!isLoading && (
        <>        
        <FichaEntidad
        campos = {campos}    
        urlClick = {`/produccion/operativas/piezas/${id}/W`}
        />           
        {recepciones && recepciones.length > 0 && (
            <Grid container  spacing={0} className="mt-1 rounded border border-formulario">
            <Grid item xs={12} className="" style={{height: 230}}>
            <MUIDataTable
                title={"RECEPCIONES"}
                className={"mt-1 mb-2"}
                data={recepciones}
                columns={columnsRecepcion}
                options={OPTIONS_COLUMNS}
            />           
            </Grid>
            </Grid> 
        )}    
        </>    
    )}
    {isLoading && (
    <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} /> 
    )}       
    </>
  )
}
export default FichaPiezas;