import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";

import { CircularProgress } from '@material-ui/core';
import FichaSeleccion from "./FichaSeleccion";
import FormularioSeleccion from "./FormularioSeleccion";
import MigasDePan  from "../../../../../components/Common/MigasDePan";

const Seleccions = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [datos, setDatos] = useState();

    useEffect(() => {
        setIsLoading(true)
    },[props.location.action])

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respAlmacen = await axios( process.env.REACT_APP_API_URL + "rrhhseleccions/" + id);
            if (respAlmacen.data) {              
              setDatos(respAlmacen.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar Seleccion" : "Cancelar",
          url: 
          typeof id === "undefined" ? `/rrhh/talento/` :
          modo === "R" ? 
            `/rrhh/talento/seleccions/${id}/W` : 
            `/rrhh/talento/seleccions/${id}/R`,
          activo: true,
        },
    ];

    const urlsMigasdepan = [
      {titulo: "Talento", colorTexto: "texto-comercial", urlTo: "/rrhh/talento"},
      {titulo: "Selección", colorTexto: "texto-comercial", urlTo: `/rrhh/talento/seleccions/${id}/${modo}`},
    ] 

    const codigo = typeof datos !== "undefined" ? datos.Codigo : "";

    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Talento"
              claseCabecera = "bg-gestion"
              buscar={false}
              imagenCabecera={"6-3-1-talento.png"}
            />
    
            {!isLoading && (
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                  <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>
                  
    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                          
                      {modo === "R" && 
                        <FichaSeleccion />}    
                      
                      {modo !== "R" && 
                        <FormularioSeleccion />}   
                          
                    </div>
                  </div>
                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>
            )}
            <div>{isLoading && <CircularProgress />}</div>
          </div>
        </>
      );

}
export default Seleccions;