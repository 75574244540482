import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import SearchBar from "../../../../../components/Common/SearchBar";
import { CircularProgress } from '@material-ui/core';


const VistaDiaria = (props) => {
    let { id, modo } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [datos, setDatos] = useState();

/*

    useEffect(() => {           
        if (isLoading && (modo === "R" || modo === "W")) {
          const fetchData = async () => {
            const respAlmacen = await axios( process.env.REACT_APP_API_URL + "almacens/" + id);
            if (respAlmacen.data) {              
              setDatos(respAlmacen.data);
              setIsLoading(false);
            }
          };
          fetchData();
        }  
    
    
        if (!modo) {      
          setIsLoading(false);
        }
    }, [isLoading, datos, modo, id]);



    const codigo = typeof datos !== "undefined" ? datos.Codigo : "";
*/
    return (
        <>

              <>
                <div className="principal bg-white  pr-2 overflow-auto overflow-hiddens">
                  {/*Insertar tabla y resto de componentes*/}
                  Insertar tabla y resto de componentes
                </div>
              </>
            

        </>
      );

}
export default VistaDiaria;