import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import Menuizq from "./Common/Menuizq";
import Home from "../containers/views/Main/Home";
import { setMode } from "../actions/ui";

const AuthRoute = (props) => {

  const { type } = props;
  const { isAuthUser } = props.reducerApi;
  const { path } = props

  const getData=()=>{
    fetch('manifest.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
    .then(function(response){
      return response.json();
      })
    .then(function(myJson) {
      props.dispatch(setMode({ state: myJson.maintenance_mode === 'true' }));
    });
  }
  
  useEffect(()=>{
    getData()
  },[path])

  if (type === "guest" && isAuthUser)
    return (
      <Redirect
        to={{
          path: "/",
          exact: true,
          sidebar: () => <Menuizq />,
          main: () => <Home />,
        }}
      />
  );
  else if (type === "private" && !isAuthUser) return <Redirect to="/login" />;
  
  return <Route {...props} key="****" />;
};

const mapStateToProps = ({ reducerApi }) => ({
  reducerApi
});

export default connect(mapStateToProps)(AuthRoute);