import React, { useState, useEffect, useContext  } from 'react';

import { StateContext } from '../../../../../context'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useForm } from "react-hook-form";
import axios from "axios";
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldSelect from '../../../../../components/Common/FormFieldSelect'
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import { useParams } from "react-router-dom";
//import { ConstructionOutlined } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({    
    paper: {
      width: "100%",
      padding: 10,
    },    
  }));

  const opcionesSiNo = [{Id:'Sí', Etiqueta: 'Sí'}, {Id:'No', Etiqueta: 'No'}]
  
  /*
  let propiedadesCarpetasPoseidon = {
    urlData: "poseidons/listadocarpetas",
    campoEtiqueta: "CodCarpeta",
    required: true,
    name: "carpetasposeidon",
    disabled: false,
    label: "Carpeta",
    value: '',
    multiple: false,
    xs: 4,
    classes: 'mt-1',
    type:"combo"
  }
  */

  let propiedadesClientes = {
    urlData: "cuentas/clientes",
    campoEtiqueta: "Nombre_Comercial",
    required: true,
    name: "clientes",
    label: "Cliente",
    value: '',
    multiple: false,
    xs: 4,
    classes: 'mt-1',
    opciones: [],
    campoId: 'Id',    
  }

  let propiedadesFechaOperacion = {
    required: false,
    label: "Fecha de operación",
    name: "fechaoperativa",
    type: "date",    
    value: '',
    xs: 4,
    classes: 'mt-1'
  }

  let propiedadesUsuarios = {
    urlData: "dic/diccionarios/listadousuarios",
    campoEtiqueta: "nombre",
    required: false,
    name: "combousuarios",
    label: "Empleado",
    value: "",
    multiple: false,
    xs: 4,
    classes: 'mt-1',
    type:"combo"
  }

  let propiedadesIdentificacion = {
    urlData: "dic/diccionarios/listadopinidentificacion",    
    campoEtiqueta: "Etiqueta",
    required: false,
    name: "identificacion",
    label: "Identificación",
    value: '',
    multiple: false,
    xs: 4,
    classes: 'mt-1',
    type:"combo"
  }

  let propiedadesAreas = {
    urlData: "dic/diccionarios/listadopinareas",    
    campoEtiqueta: "Etiqueta",
    required: false,
    name: "area",
    label: "Área",
    value: '',
    multiple: false,
    xs: 4,
    classes: 'mt-1',
    type:"combo"
  }
  
  let propiedadesNPin = {
    required: false,
    name: "numeropin",
    label: "Nº PIN",
    value: "",
    xs: 4,
    classes: 'mt-1'
  }

  let propiedadesCargo = {
    required: false,
    name: "cargo",
    label: "¿Cargo?",
    value: "",
    multiple: false,
    xs: 4,
    classes: 'mt-1',
    opciones: opcionesSiNo,
    type:"select"
  }

  let propiedadesSeguro = {
    required: false,
    name: "seguro",
    label: "¿Seguro?",
    value: "",
    multiple: false,
    xs: 4,
    classes: 'mt-1',
    opciones: opcionesSiNo,
    type:"select"
  }

  let propiedadesFechaPIN = {
    required: true,
    label: "Fecha PIN",
    name: "fechapin",
    type: "date",    
    value: '',
    xs: 4,
    classes: 'mt-1'
  }
  
  let propiedadesNFactura = {
    required: false,
    name: "numerofactura",
    label: "Nº Factura",
    value: "",
    xs: 4,
    classes: 'mt-1',
    type:"text"
  }

  let propiedadesNExpediente = {
    required: false,
    name: "numeroexpediente",
    label: "Nº Expediente",
    value: "",
    xs: 4,
    classes: 'mt-1',
    type:"text"
  }

  let propiedadesObservaciones = {
    xs:6, 
    required: false,
    label: "Observaciones",
    name: "observaciones",
    type: "textArea",    
    value: '',
    lineas: 4
  }

  let propiedadesSolucion = {
    xs:6, 
    required: false,
    label: "Solución",
    name: "solucion",
    type: "textArea",    
    value: '',
    lineas: 4
  }

  let propiedadesFicheros = {
    xs:6,
    required: false,
    label: "",
    name: "filesupload",
    type: "filesupload", 
    //ficheros: datosFormulario.ficheros,    
    tabla: "mtms_operaciones_pin_adjuntos"
  }

  let propiedadesAdjuntos = {
    xs:6,
    required: false,
    label: "",
    name: "files",
    type: "files", 
    //ficheros: datosFormulario.ficheros,    
    tabla: "mtms_operaciones_pin_adjuntos",
    modelo: "api\\modules\\v1\\models\\operaciones\\OperacionesPinAdjuntos",
    carpeta: "operaciones\\pin",
  }

export default function FormularioPINOperacion({actualizaListado}) {
    const {state, dispatch} = useContext(StateContext)
    const { operacion } = state
    const classes = useStyles();  
    const [ficheros, setFicheros] = useState([])
    const [nombresFicheros, setNombresFicheros] = useState([])
    const { control, handleSubmit} = useForm();
    const { id } = useParams();
    const [operaciones, setOperaciones] = useState(null);
    

    useEffect(() => { 
      if(operaciones) propiedadesClientes.opciones = operaciones.CuentasCliente //clientes
      
      return () => {        
      }
    }, [operaciones])

    useEffect(() => { 
      if (operacion) setOperaciones(operacion)
    }, [operacion])

    const handleSubmitPost = async (datos) => {    
      let data = {};
      data.idOperacion = id
      data.area = datos.area.Id
      data.cargo = datos.cargo 
      
      data.cliente = datos.clientes
      data.empleado = datos.combousuarios.id 
      data.fechaoperativa = datos.fechaoperativa
      data.fechapin = datos.fechapin
      data.identificacion = datos.identificacion.Id 
      data.numeroexpediente = datos.numeroexpediente 
      data.numerofactura = datos.numerofactura
      data.observaciones = datos.observaciones
      data.seguro = datos.seguro
      data.solucion = datos.solucion
      data.ficheros = ficheros
      data.nombresFicheros = nombresFicheros      

      let user = JSON.parse(localStorage.getItem("user"));
      const AuthStr = "Bearer ".concat(user.access_token);
      const SERVER_URL = `${process.env.REACT_APP_API_URL}operacionespins`
      
      await axios
      .post(SERVER_URL, {
        headers: {        
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
        data,
      })
      .then((response) => {  
        
        if(!isNaN(response.data)){
          actualizaListado(response.data)
        }
        })
        .catch((error) => {
          console.log("error " + error);
          console.log(error.response.data); // => the response payload 
            console.log(error.response.data.message)
    });

    }

    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }

    if(operaciones && operaciones.CuentasCliente){
      propiedadesClientes.opciones = operaciones.CuentasCliente
    }
    
    return (       
      
    <Paper elevation={0} className={classes.paper} >
    
    <form
        onSubmit={handleSubmit((datos) => {
            handleSubmitPost(datos);
        })}
        className="row ml-2"
    >
    
    <Grid className="" container spacing={1}>       
        {/*
        <FormFieldCombo propiedades={propiedadesCarpetasPoseidon} control={control}/>
        */}
        <FormFieldSelect propiedades={propiedadesClientes} control={control}/>
        <FormFieldDate propiedades={propiedadesFechaOperacion} control={control} />
        
        <FormFieldCombo propiedades={propiedadesUsuarios} control={control}/>  
        <FormFieldCombo propiedades={propiedadesIdentificacion} control={control}/>
        <FormFieldCombo propiedades={propiedadesAreas} control={control}/>
        
        
        <FormFieldText propiedades={propiedadesNPin} control={control} />
        <FormFieldSelect propiedades={propiedadesCargo} control={control}/>
        <FormFieldSelect propiedades={propiedadesSeguro} control={control}/>
        <FormFieldDate propiedades={propiedadesFechaPIN} control={control} />
        <FormFieldText propiedades={propiedadesNFactura} control={control} />
        <FormFieldText propiedades={propiedadesNExpediente} control={control} />

        <FormFieldTextArea propiedades={propiedadesObservaciones} control={control} />                
        <FormFieldTextArea propiedades={propiedadesSolucion} control={control} /> 
        <FormFieldFiles propiedades={propiedadesFicheros} control={control} ficheros={handleFicheros}/>
        <FormFieldFileUpload propiedades={propiedadesAdjuntos} control={control} handleFicheros={handleFicheros}/>
        <button style={{marginLeft: 20, maxHeight:33}} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary ">
            GUARDAR
        </button>  
       
    </Grid>
    </form>
    </Paper>            
  );

}