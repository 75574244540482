import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaAccidente = () => {
    let { id } = useParams();

    const [accidente, setSeleccion] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSeleccion = await axios(`${process.env.REACT_APP_API_URL}rrhhsspartesaccidentes/${id}`);    
          setSeleccion(respSeleccion.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(accidente).length > 0){  
        
            setCampos([
            {
                classsLabel: "",
                label: "Tipología:",
                value: accidente.Tipologia.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Trabajador:",
                value: accidente.NombreEmpleado,
                classText: classText,
                xs:8
            },
            {
                classsLabel: "",
                label: "Personal:",
                value: accidente.TipoTrabajador.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Lugar del accidente:",
                value: accidente.LugarAccidente.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha Accidente:",
                value: accidente.FechaAccidente,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Grado de la lesión:",
                value: accidente.GradoLesion.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Empresa:",
                value: accidente.Empresa,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Nombre testigo:",
                value: accidente.NombreTestigo,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Baja médica:",
                value: accidente.BajaMedica,
                classText: classText,
                xs:4
            },
            /*
            {
                classsLabel: "",
                label: "Parte de enfermedad:",
                value: accidente.ParteEnfermedad.Codigo,
                classText: classText,
                xs:4
            },
            */
            {
                classsLabel: "",
                label: "Evaluación riesgo puesto:",
                value: accidente.EvaluacionRiesgoPuesto,
                classText: classText,
                xs:4
            },
            
            {
                classsLabel: "",
                label: "Testigos:",
                value: accidente.Testigo,
                classText: classText,
                xs:4
            },
           
            {
                classsLabel: "",
                label: "Descripción accidente:",
                value: accidente.DescripcionAccidente,
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Descripción de la lesión:",
                value: accidente.DescripcionLesion,
                classText: classText,
                xs:6
            },
            {
                classsLabel: "",
                label: "Observaciones:",
                value: accidente.Observaciones,
                classText: classText,
                xs:12
            },
            {                
                 classsLabel: "",
                 label: "Archivos adjuntos:",
                 value: accidente.ficheros,
                 classText: classText,
                 type: "file",
                 xs:4
                },
            
        ])
    }
},[accidente]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/rrhh/talento/accidentes/${id}/W`}
    />
    </>
  )
}
export default FichaAccidente;