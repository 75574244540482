import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TableAcciones from "../../../../../components/Common/TableAcciones";
import SearchBar from "../../../../../components/Common/SearchBar";
import FormularioActividad from "./FormularioActividad"
import FichaActividad from "./FichaActividad"

import MigasDePan  from "../../../../../components/Common/MigasDePan";

const Actividad = (props) => {
    let { id, modo } = useParams();    
    const [codigo, setCodigo] = useState("")

    const botoneraAcciones = [    
        {
          etiqueta: modo === "R" ? "Editar Actividad" : "Cancelar Edición",
          url: 
          typeof id === "undefined" ? `/crm/actividades/` :
          modo === "R" ? 
            `/crm/actividades/actividad/${id}/W` : 
            `/crm/actividades/actividad/${id}/R`,
          activo: true,
        },
    ];

    const urlsMigasdepan = [
      {titulo: "Actividades", colorTexto: "texto-comercial", urlTo: "/crm/actividades"},
      {titulo: "Actividad", colorTexto: "texto-comercial", urlTo: `/crm/actividades/actividad/${id}/${modo}`},
    ]

    const changeCode = (code) => {      
      setCodigo(code)
    }

    return (
        <>
          <div className="col-8 col-main-home ">
            <SearchBar
              titulo="CRM"
              claseCabecera = "bg-comercial"
              buscar={false}     
              imagenCabecera={"4-1-3-actividades.png"}         
            />
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigo}/>    
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">                          
                      {modo === "R" && <FichaActividad setCodigo={changeCode}/>}    
                      {modo !== "R" && <FormularioActividad setCodigo={changeCode}/>}                          
                    </div>                    
                  </div>                  
                  <TableAcciones
                    filaSeleccionada={-1}
                    botonaccion={botoneraAcciones}
                  />
                </div>
              </>           
          </div>
        </>
    );

}
export default Actividad;