import React, { createContext, useState, useMemo, useContext } from "react";

const CotizacionContext = createContext();

export function CotizacionProvider(props) {
    const [cotizacion, setCotizacion] = useState(0)
    const [codigoCotizacion, setCodigoCotizacion] = useState("")
    const [datosFormulario, setDatosFormulario] = useState({ operativa: [], servicios: [] })
    const [datosJornadas, setDatosJornadas] = useState([])
    const [datosPresupuesto, setDatosPresupuesto] = useState({})
    const [pasoActivo, setPasoActivo] = useState(0)
    const [manoActiva, setManoActiva] = useState(0)
    const [costesDirectos, setCostesDirectos] = useState(0)
    const [datosCotizacion, setDatosCotizacion] = useState({})
    const [datosDesplegables, setDatosDesplegables] = useState({})
    const [manosCotizacion, setManosCotizacion] = useState([])
    const [manosMercancia, setManosMercancia] = useState([])    

    const value = useMemo(() => {
        return ({
            cotizacion, setCotizacion,
            codigoCotizacion, setCodigoCotizacion,
            datosFormulario, setDatosFormulario,
            datosJornadas, setDatosJornadas,
            datosPresupuesto, setDatosPresupuesto,
            pasoActivo, setPasoActivo,
            manoActiva, setManoActiva,
            costesDirectos, setCostesDirectos,
            datosCotizacion, setDatosCotizacion,
            datosDesplegables, setDatosDesplegables,
            manosCotizacion, setManosCotizacion,
            manosMercancia, setManosMercancia,            
        })
    }, [
        cotizacion, setCotizacion, 
        codigoCotizacion, setCodigoCotizacion,
        datosFormulario, setDatosFormulario,
        datosJornadas, setDatosJornadas,
        datosPresupuesto, setDatosPresupuesto,
        pasoActivo, setPasoActivo,
        manoActiva, setManoActiva,
        costesDirectos, setCostesDirectos,
        datosCotizacion, setDatosCotizacion,
        datosDesplegables, setDatosDesplegables,
        manosCotizacion, setManosCotizacion,
        manosMercancia, setManosMercancia,        
    ])

    return <CotizacionContext.Provider value={value} {...props} />
}

export function useCotizacion(){
    const context = useContext(CotizacionContext)

    if(!context){
        throw new Error('useCotizacion debe estar dentro del proveedor CotizacionContext')
    }

    return context;
}
