import React from "react";
import Nav from "../../components/Common/Header";
import Menuizq from "../../components/Common/Menuizq";
import MenuDerFacturaCompra from "../../components/Common/MenuDerFacturaCompra";
import {FacturaCompraProvider} from '../views/Main/Gestion/Compras/facturacompra-context'
import MaintenanceModeComponent from "../../components/Common/MaintenanceModeComponent";

export default ({ children }) => {   
  const maintenanceMode = children?.props?.children[0]?.key === 'true'

  return (
    <>
    {maintenanceMode && (
      <MaintenanceModeComponent />
    )}
    {!maintenanceMode && (
      <FacturaCompraProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children}
        <MenuDerFacturaCompra />
      </div>
    </div>
    </FacturaCompraProvider>
    )}
    </>
  );  
};

/*
return (
    <FacturaCompraProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children }
        <MenuDerFacturaCompra />
      </div>
      
    </div>
    </FacturaCompraProvider>
  );
  */
