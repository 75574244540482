import React from "react";
import CuerpoTabla from "../../../../../components/Common/CuerpoTabla";
import {COLUMNS_CUENTAS, BOTONERA_ACCIONES} from './CuentasConf'

const Cuentas = () => {

  return (
    <CuerpoTabla
      tituloSearchBar = "CRM"
      claseBGSearchBar = "bg-comercial"
      titulo="Cuentas"
      columnas = {COLUMNS_CUENTAS}
      botonera = {BOTONERA_ACCIONES}
      urlDatos ="cuentas"
      urlDoubleClick = "/crm/cuentas/cuenta/"
      columnId="Numero"
      imagenCabecera={"4-1-1-cuentas.png"}
    />
  )  
};

export default Cuentas;