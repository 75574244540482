const CAMPOS_NOTAS = [
    
      /*{
        urlData: "dic/diccionarios/listadousuarios",
        campoEtiqueta: "nombre",
        required: true,
        label: "Remitente",
        name: "remitente",
        disabled: false,
        type: "combo",           
        multiple:false,   
        value: "",
        xs:6
      } ,
      {
        required: true,    
        label: "Modulo:",
        name: "modulo",
        type: "text",    
        value: "",    
        xs:6
      },
      */
     //0
      {
        required: true, 
        disabled: false,   
        label: "Asunto:",
        name: "asunto",
        type: "text",    
        value: "NOTA DE USUARIO",    
        xs:12
      }, 
      //1
      {
        urlData: "dic/diccionarios/listadoempleadosactivos",
        campoEtiqueta: "nombre",
        required: true,
        label: "Destinatario 1",
        name: "destinatario1",
        disabled: false,
        type: "combo",           
        multiple:false,   
        value: '',
        xs:3
      }, 
      //2
      {
        urlData: "dic/diccionarios/listadoempleadosactivos",
        campoEtiqueta: "nombre",
        required: false,
        label: "Destinatario 2",
        name: "destinatario2",
        disabled: false,
        type: "combo",           
        multiple:false,   
        value: '',
        xs:3
      }, 
      //3
      {
        urlData: "dic/diccionarios/listadoempleadosactivos",
        campoEtiqueta: "nombre",
        required: false,
        label: "Destinatario 3",
        name: "destinatario3",
        disabled: false,
        type: "combo",           
        multiple:false,   
        value: '',
        xs:3
      }, 
      //4
      {
        urlData: "dic/diccionarios/listadoempleadosactivos",
        campoEtiqueta: "nombre",
        required: false,
        label: "Destinatario 4",
        name: "destinatario4",
        disabled: false,
        type: "combo",           
        multiple:false,   
        value: '',
        xs:3
      },       
      //5
      {
        required: true,  
        disabled: false,  
        label: "Mensaje:",
        name: "mensaje",
        type: "textArea",
        lineas: 4,    
        value: "",    
        xs:12
      },
      //6
      {
        required: true,    
        disabled: false,
        label: "Remitente:",
        name: "remitente",
        type: "object",    
        value: "",    
        xs:6
      },
      //7
      {
        classButton: "",
        required: false,
        disabled: false,
        label: "",
        name: "filesupload",
        type: "filesupload",   
        tabla: "",
        xs:5
      },
      //8
      {
        xs:12,
        required: false,
        label: "",
        name: "files",
        type: "files", 
        ficheros: "",
        tabla: "mtms_notas_aviso_adjuntos",
        modelo: "api\\modules\\v1\\models\\notasaviso\\NotasAvisoAdjuntos",
        carpeta: "notasaviso",
      },
  
]

export {CAMPOS_NOTAS};