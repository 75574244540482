import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaPersonal = () => {
    let { id } = useParams();

    const [personal, setPersonal] = useState([])  
    const [campos, setCampos] = useState([]) 

    useEffect(() => {        
        const fetchData = async () => {
          const respSeleccion = await axios(`${process.env.REACT_APP_API_URL}censocpes/${id}`);    
          //console.log(respSeleccion.data)
          setPersonal(respSeleccion.data)  
        };
        fetchData();  
    }, [id]);

    const classText= "Texto14"
    useEffect(() => {      
        if(Object.keys(personal).length > 0){  
            //console.log(personal)
                setCampos([
                {
                    classsLabel: "",
                    label: "Censo:",
                    value: personal.Censo,
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Antigüedad:",
                    value: personal.Antiguedad,
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Situación:",
                    value: personal.Situacion.Etiqueta,
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Activo:",
                    value: personal.Activo === 1 ? "SÍ" : "NO",
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Nombre:",
                    value: personal.Nombre,
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Apellido 1:",
                    value: personal.Apellido1,
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Apellido 2:",
                    value: personal.Apellido2,
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "",
                    value: "",
                    classText: classText,
                    xs:3
                },
                {
                    classsLabel: "",
                    label: "Categoría",
                    value: `${personal.Categoria.Codigo} - ${personal.Categoria.Etiqueta}` ,
                    classText: classText,
                    xs:6
                },
                {
                    classsLabel: "",
                    label: "Especialidades",
                    value: personal.Especialidades ,
                    classText: classText,
                    xs:6
                },
                
                
            ])
        }
    },[personal]) 

    return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    urlClick = {`/rrhh/talento/personal/${id}/W`}
    />
    </>
  )
}
export default FichaPersonal;