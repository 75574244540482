import React, { useState, useEffect } from "react";
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";


const InformeOperativas = ({urlInforme, filtroFechas, returnData = null}) => {    
    
    const urlDoubleClick = ""
    const filtroToolbar = false    
    const cloumnsPlanificaciones = [
      {
        name: "Id",
        label: "Id",
        options: {
         filter: false,
         sort: true,
         display: false,
        }
       },
       {
        name: "Codigo",
        label: "Código",
        options: {
         filter: true,
         sort: true,
        }
       },       
       {
        name: "Cuentas",
        label: "Clientes",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Localizacion",
        label: "Localizacion",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Buque",
        label: "Buque",
        options: {
         filter: true,
         sort: true,
        }
       },
       {
        name: "Fecha_Operativa",
        label: "Fecha Operativa",
        options: {
         filter: true,
         sort: true,
         
        }
       },
       {
        name: "Jornadas",
        label: "Jornadas",
        options: {
         filter: true,
         sort: true,
         
        }
       },
       {
        name: "totalMovimientos",
        label: "Nª Movimientos",
        options: {
          filter: true,
          sort: true,
          sortCompare: (order) => {
           return (obj1, obj2) => {
             let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
             let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
             return (val1 - val2) * (order === 'asc' ? 1 : -1);
           };
          },        
          setCellProps: () => { return { align:"right"} }
        }
       },
       {
        name: "totalHoras",
        label: "Nª Horas",
        options: {
          filter: true,
          sort: true,
          sortCompare: (order) => {
           return (obj1, obj2) => {
             let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
             let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
             return (val1 - val2) * (order === 'asc' ? 1 : -1);
           };
          },        
          setCellProps: () => { return { align:"right"} }
        }
       },
       {
        name: "ventas",
        label: "Ventas €",
        options: {
         filter: true,
         sort: true,
         sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
         },        
         setCellProps: () => { return { align:"right"} },
        }   
       }, 
       {
        name: "costes",
        label: "Costes €",
        options: {
         filter: true,
         sort: true,
         sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
         },         
         setCellProps: () => { return { align:"right"} },
        }   
       },
       {
        name: "margenBrutoImp",
        label: "€ Margen Bruto",
        options: {
         filter: true,
         sort: true,
         sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
         },         
         setCellProps: () => { return { align:"right"} },
        }   
       }, 
       {
        name: "margenBrutoPor",
        label: "% Margen Bruto",
        options: {
         filter: true,
         sort: true,
         sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = parseFloat(obj1.data.replace(".", "").replace(",", "."));
            let val2 = parseFloat(obj2.data.replace(".", "").replace(",", "."));
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
         },
         setCellProps: () => { return { align:"right"} },
        }   
       }, 
       
    ];

    const urlDatos = `informes/${urlInforme}${filtroFechas}`
    return(
      <CuerpoTablaPiezas
        columnas = {cloumnsPlanificaciones}
        urlDatos = {urlDatos}
        urlDoubleClick = {urlDoubleClick}
        columnId = "Id"
        getFilaSeleccionada={null}        
        filtroToolbar={filtroToolbar}
        filtroToolbarOpciones = {null}
        filtroToolbarValor = {''}
        filtroToolbarChangeValor={null}
        filtroEjercicio = {false}
        paginacion = {true}
        returnData={returnData}
      /> 
    )
}

export default InformeOperativas;