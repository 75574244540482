import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from '@material-ui/core/Grid';
import FormFieldCombo from '../../../../../components/Common/FormFieldCombo'
import FormFieldDate from '../../../../../components/Common/FormFieldDate'
import FormFieldText from '../../../../../components/Common/FormFieldText'
import FormFieldFiles from '../../../../../components/Common/FormFieldFiles'
import FormFieldTextArea from '../../../../../components/Common/FormFieldTextArea'
import FormFieldFileUpload from '../../../../../components/Common/FormFieldFileUpload'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";
import FormularioTablaFactura from "./FormularioTablaFactura";
import TablaFacturaCompra from "./TablaFacturaCompra";
import FormFieldNumber from '../../../../../components/Common/FormFieldNumber'
import FormFieldComboArray from '../../../../../components/Common/FormFieldComboArray'
import { useHistory } from "react-router";
import FormFieldLineSeparator from '../../../../../components/Common/FormFieldLineSeparator'
import { v4 as uuidv4 } from 'uuid';
import { useStyles } from "../../../../../styles/styles";


const FormularioFacturaDeCompra = ({index, tipoForm}) => { 
    const { control, handleSubmit, setValue} = useForm();   
    const methods = useForm();  
    const classes = useStyles();
    const { id, modo } = useParams();  
   const history = useHistory();  
    //const ordenesdeCompra = useRef([]);
    //const carpetasOperativa = useRef([]);
    const [FacturaCompra, setFacturaCompra] = useState([]) 
    const [ordenCompra, setOrdenCompra] = useState([])
    const [carpetaOperativa, setCarpetaOperativa] = useState([])
    const [tipoFactura, setTipoFactura] = useState('')
    const [campos, setCampos] = useState([])   
    const [listadoCompra, setListadoCompra] = useState([])
    const [ficheros, setFicheros] = useState([])
    const [nombresFicheros, setNombresFicheros] = useState([])  
    //const [openAlert, setOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [proveedor, setProveedor] = useState()
    const [cuentaAnalitica, setCuentaAnalitica] = useState(false)
    const [cuentaPyG, setCuentaPyG] = useState(false)
    const [tipoFacturaProductosyServicios, setTipoFacturaProductosyServicios] = useState('')

    const [openAlert,setOpenAlert] = useState({
      open : false,
      tipoMensaje : "success",
      mensaje : ""
    });  

    const handleListadoTabla=(valor) => {      
      setListadoCompra([...listadoCompra,valor])
    }

    const handleRemoveElement = (element) =>{
      setListadoCompra(element) 
    }
      
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpenAlert({...openAlert,open:false});
    }; 
    
    const handleFicheros = (files, filenames) => {      
      setFicheros(files)
      setNombresFicheros(filenames)
    }
 
    useEffect(() => {      
      
      setCampos([   
          //0 ordenCompra     
          {            
              urlData: "ordenescompras/cerradas",
              campoEtiqueta: "Codigo",
              campoId: "Id",
              required: false,
              name: "ordenCompra",
              disabled: false,
              label: "Orden de compra",
              type:'combo',
              value: '',
              multiple: false,
              xs: 2,
              hidden: false,
          }, 
          //1 Solicitante
          {
            urlData: "dic/diccionarios/listadousuarios",
            campoEtiqueta: "nombre",
            campoId: "id",
            required: false,
            name: "nombre",
            disabled: false,
            label: "Solicitud",
            type:'combo',
            value: '',
            multiple: false,
            xs: 2,
          },
          //2 fecha Valor        
          {
            required: false,
            label: "Fecha Valor",
            name: "fechaValor",
            type: "date",    
            value: '',
            xs: 2
          }, 
          //3 numero factura 
          {
            required: true,
            name: "numeroFactura",
            disabled: false,
            label: "Nº Factura",
            type:'text',
            value: '',
            multiple: false,
            xs: 2
          },
          //4 fecha factura           
          {
              required: true,
              label: "Fecha Factura",
              name: "fechaFactura",
              type: "date",    
              value: '',
              xs: 2
          }, 
          //5 Proveedor        
          {
              urlData: "cuentas/proveedores",
              campoEtiqueta: "Nombre_Comercial",
              required: true,
              name: "proveedor",
              disabled: false,
              type:'combo',
              label: "Proveedor",
              value: '',
              multiple: false,
              xs: 2
          },
          //6 Estado
          {
              urlData: "dic/diccionarios/estadosfacturacompra",
              campoEtiqueta: "Etiqueta",
              required: true,
              name: "estado",
              disabled: false,
              type:'combo',
              label: "Estado",
              value: '',
              multiple: false,
              xs: 2
          },
          //7 observaciones
          {
            required: false,
            name: "observaciones",
            disabled: false,
            label: "Observaciones",
            value: '',
            type: 'textarea',
            lineas:3,
            xs: 12
          },
          //8 Adjuntos
          {
            required: false,
            label: "Documentación",
            name: "ficherosadjuntos",
            type: "filesupload",   
            tabla: "mtms_factura_compra_adjuntos",
            xs:6
          },
          //9 ficheros
          {
            required: false,
            label: "Documentación",
            name: "ficheros",
            type: "files", 
            ficheros:"",    
            tabla: "mtms_factura_compra_adjuntos",
            modelo: "api\\modules\\v1\\models\\facturacompra\\FacturaCompraAdjuntos",
            carpeta: "factura-compra",
            xs:12
          },
          //10 Tipo de factura tiposfacturadecompra
          {
            urlData: "dic/diccionarios/tiposfacturadecompra",
            campoEtiqueta: "Tipo",
            required: true,
            name: "tipoFactura",
            disabled: false,
            type:'combo',
            label: "Tipo de Factura",
            value: '',
            multiple: false,
            xs: 2
          },
          /*
          {
            campoEtiqueta: "Etiqueta",
            required: false,
            label: "Tipo de Factura",
            name: "tipologia",
            disabled: modo === "R" ? true : false,
            type: "comboArray", 
            options:[{Id: 'CPE', Etiqueta: 'CPE'}, {Id: 'Materiales', Etiqueta: 'Materiales'}, {Id: 'Productos', Etiqueta: 'Productos'}, {Id: 'Servicios', Etiqueta: 'Servicios'}], 
            multiple:false,   
            value: '',
            xs:2
          },   
          */
          //11 Cuentas analíticas
          {
            urlData: "dic/diccionarios/cuentasanaliticas/0",
            campoEtiqueta: "Codigo,Etiqueta",
            campoId: "Id",
            campoDesactivar: "Mostrar",
            required: false,
            name: "cuentasAnaliticas",
            disabled: false,
            label: "Cuentas analíticas",
            type:'combo',
            value: '',
            multiple: false,
            xs: 3
          },
          //12 cuentas PyG 
          {
            urlData: "dic/diccionarios/cuentaspyg/0",
            campoEtiqueta: "Codigo,Etiqueta",
            campoId: "Id",
            campoDesactivar: "Mostrar",
            required: false,
            name: "cuentasPyG",
            disabled: false,
            label: "Cuentas pérdidas y ganancias",
            type:'combo',
            value: '',
            multiple: false,
            xs: 3
          }, 
          //13 Supervisor
          {
            urlData: "dic/diccionarios/listadousuarios",
            campoEtiqueta: "nombre",
            campoId: "id",
            required: false,
            name: "supervisor",
            disabled: false,
            label: "Supervisor",
            type:'combo',
            value: '',
            multiple: false,
            xs: 2
          }, 
          //14  Director  
          {
            urlData: "dic/diccionarios/listadousuarios",
            campoEtiqueta: "nombre",
            campoId: "id",
            required: false,
            name: "director",
            disabled: false,
            label: "Dirección",
            type:'combo',
            value: '',
            multiple: false,
            xs: 2
          },  
          //15 Carpeta operativa   - parte cpe
          {
            urlData: "dic/diccionarios/partescarpetaoperativa",
            campoEtiqueta: "Carpeta,NPedido",
            campoId: "Id",
            campoDesactivar: "Desactivar",
            required: false,
            name: "carpetaOperativa",
            disabled: false,
            label: "Parte CPE",
            type:'combo',
            value: '',
            multiple: false,
            xs: 2,
            hidden: false,
            classes: "d-none"
          }, 
          //16  Carpeta operativa cerrada
          {
            urlData: "dic/diccionarios/carpetaoperativacerradas",
            campoEtiqueta: "Codigo,Fecha_Operativa",
            campoId: "Id",          
            required: false,
            name: "carpetaOperativacerrada",
            disabled: false,
            label: "Carpeta operativa",
            type:'combo',
            value: '',
            multiple: false,
            xs: 2,
            hidden: false,
            classes: "d-none"
          }, 
          //17 factura recapitulativa cpe   
          {
            required: false,
            name: "recapitulativaCPE",
            disabled: false,
            label: "Factura recapitulativa",
            type:'text',
            value: '',
            multiple: false,
            xs: 2,
            classes: ""
          }, 
          //18 carpeta maquinaria
          {
            urlData: "mantenimientos/cerradas",
            campoEtiqueta: "codigo,tipologia,area",
            campoId: "Id",          
            required: false,
            name: "carpetaMaquinaria",
            disabled: false,
            label: "Carpeta maquinaria",
            type:'combo',
            value: '',
            multiple: false,
            xs: 2,
            hidden: false,
            classes: "d-none"
          }, 
          //19 carpeta instalacion 
          {
            urlData: "reparacions/cerradas",
            campoEtiqueta: "codigo,tipologia,area",
            campoId: "Id",          
            required: false,
            name: "carpetaInstalacion",
            disabled: false,
            label: "Carpeta instalación",
            type:'combo',
            value: '',
            multiple: false,
            xs: 2,
            hidden: false,
            classes: "d-none"
          }, 
          //20 Nº Periodos
          {
            xs:2,
            required: false,
            label: "Nª Periodos",
            name: "numPeriodos",
            type: "number",    
            value: ''
        }

      ])

        const fetchData = async () => {
            setIsLoading(true) 
            if (modo === "W"||modo==="R") {
                const respFacturaCompra = await axios(
                    `${process.env.REACT_APP_API_URL}facturascompras/${id}`
                );    
                setFacturaCompra(respFacturaCompra.data)              
            }
            setIsLoading(false);
        };    
        fetchData();  
    },[]);
    
    useEffect(() => {
      if (typeof FacturaCompra !== "undefined") { 
        if(Object.keys(FacturaCompra).length > 0){
          console.log(FacturaCompra)
          setIsLoading(true)          
          campos[0].value = FacturaCompra.OrdenesCompra;
          setOrdenCompra(FacturaCompra.OrdenesCompra)
          campos[1].value = FacturaCompra.Solicita;
          campos[2].value = FacturaCompra.Fecha_Valor;
          campos[3].value = FacturaCompra.NumFactura;
          campos[4].value = FacturaCompra.Fecha_Factura;
          campos[5].value = FacturaCompra.Proveedor; 
          setProveedor(FacturaCompra.Proveedor)
          campos[6].value = FacturaCompra.Estado;           
          campos[7].value = FacturaCompra.Observaciones;
          campos[8].ficheros = FacturaCompra.ficheros;
          campos[9].ficheros = FacturaCompra.ficheros;
          campos[10].value = FacturaCompra.Tipo_Factura; 
          handleChangeTipoFactura(FacturaCompra.Tipo_Factura)
          
          campos[11].value = FacturaCompra.CuentaAnalitica;
          campos[12].value = FacturaCompra.CuentaPyG;
          campos[11].urlData = `dic/diccionarios/cuentasanaliticas/${FacturaCompra.Fk_Proveedor}`
          campos[12].urlData = `dic/diccionarios/cuentaspyg/${FacturaCompra.Fk_Proveedor}` 
          campos[13].value = FacturaCompra.Supervisor;
          campos[14].value = FacturaCompra.Director;         
          campos[15].value = FacturaCompra.NPedidoCPE;
          campos[16].value = FacturaCompra.CarpetaOperativa;
          campos[17].value = FacturaCompra.RecapitulaticaCPE;
          campos[18].value = FacturaCompra.carpetaMaquinaria;
          campos[19].value = FacturaCompra.carpetaInstalaciones;
          campos[20].value = FacturaCompra.numPeriodos;
          setListadoCompra(FacturaCompra.articulos);
          setCuentaAnalitica(FacturaCompra.CuentaAnalitica ? true : false)
          setCuentaPyG(FacturaCompra.CuentaPyG ? true : false)

          setIsLoading(false)        
        }
      }
    }, [FacturaCompra])

  useEffect(() => {    
    if (typeof carpetaOperativa !== "undefined") {     
      if(Object.keys(carpetaOperativa).length > 0){ 
        
        const fetchData = async () => {
          const especialidadescpe = await axios(
            `${process.env.REACT_APP_API_URL}dic/diccionarios/especialidadescpe`
          );                  

          const especialidades = carpetaOperativa.CPEEspecialidades//[0]
          let arrayProductos = []
          especialidades.forEach(parte => {  
            parte.forEach(element => {

              const especialidad = especialidadescpe.data.find(espec => espec.Id === element.Fk_Especialidad)
              
              const precio = 
              parseFloat(element.AntiguedadDirecta) +
              parseFloat(element.Coste) +
              parseFloat(element.Desplazamiento) +
              parseFloat(element.Dieta) +
              parseFloat(element.Diferido) +
              parseFloat(element.Fasv) +
              parseFloat(element.IncrementoApoyoBuque) +
              parseFloat(element.OtrosGastos) +
              parseFloat(element.SeguridadSocial) +
              parseFloat(element.TotalHorasExtra) +
              parseFloat(element.TotalIncrementoPrima) +
              parseFloat(element.TotalPrimas) +
              parseFloat(element.TotalPuses) +
              parseFloat(element.TotalSalario) 
              let producto = {
                id:uuidv4(),
                idArticulo:-1,
                idCentroCoste:2,
                idIgic: null,
                idIrpf: null,
                idCuentaAnalitica: null,
                idCuentaPyG: null,
                idEspecialidadCPE: element.Id,
                codigo:'CPE',
                descripcion: `${element.NCenso}: ${especialidad.Codigo}-${especialidad.Etiqueta}`,
                unidad:null,
                cantidad:1,
                precioUnidad:precio,            
                precioTotal:precio,
                centroCoste:"TALLER PRODUCCIÓN",
                igic: null,
                irpf: null,
                cuentaAnalitica: null,
                cuentaPyG: null,
              }
              arrayProductos.push(producto)   
              

            })
          });

          const gesport = carpetaOperativa.GesportListado

          gesport.forEach(parteGesport => {              
            parteGesport.forEach(element => {               
              
              let producto = {
                id:uuidv4(),
                idArticulo:-1,
                idCentroCoste:2,
                idIgic: null,
                idIrpf: null,
                idCuentaAnalitica: null,
                idCuentaPyG: null,
                idEspecialidadCPE: null,
                codigo:'Gesport',
                descripcion: element.Nombre,
                unidad:null,
                cantidad:parseFloat(element.Cantidad),
                precioUnidad:parseFloat(element.Coste),            
                precioTotal:parseFloat(element.Coste) * parseFloat(element.Cantidad),
                centroCoste:"TALLER PRODUCCIÓN",
                igic: null,
                irpf: null,
                cuentaAnalitica: null,
                cuentaPyG: null,
              }
              arrayProductos.push(producto)   
              

            })
          })

          setListadoCompra(arrayProductos)

                    
        };    
        fetchData();            
      }
    }
  },[carpetaOperativa])

  const handleSubmitPost = async (datos) => { 

    let hayFallos = !cuentaAnalitica &&  !cuentaPyG    
    let hayFallosEnProductos = false

    if(hayFallos){
      listadoCompra.forEach(element => {
        if(element.idCuentaAnalitica && element.idCuentaPyG){
          hayFallosEnProductos = true
        }
      });
    }

    if(hayFallos || hayFallosEnProductos){
      setOpenAlert({
        open: true,
        tipoMensaje: "error",
        mensaje: "Seleccione Cuenta Analítica y Pérdida y Ganancias."
      })
      return;
    }

    datos.ordenCompra = ordenCompra;
    datos.proveedor = datos.proveedor.Id
    datos.estado = datos.estado.Id  
    datos.ficheros=ficheros
    datos.nombresFicheros=nombresFicheros  
    datos.listadotabla=listadoCompra  

    //console.log(datos)
    //return

    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    const SERVER_URL = `${process.env.REACT_APP_API_URL}facturascompras`

    console.log(datos)
    //return

    if (modo==="W") {
        await axios
          .put(`${SERVER_URL}/${id}`, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => { 
            if(!isNaN(response.data)){
              if(parseInt(response.data) === parseInt(id)){                       
                  setOpenAlert({
                    open : true,
                    tipoMensaje : "success",
                    mensaje : "¡Factura guardada correctamente!"
                  }) 
                  setTimeout(function () {
                    window.location.reload();
                    
                  }
                  , 1000);
                  
              }
            }
            })
            .catch((error) => {
              console.log("error " + error);
              //console.log(error.response.data); // => the response payload 
              //console.log(error.response.data.message)
              setOpenAlert({
                open: true,
                tipoMensaje: "error",
                mensaje: "Ha ocurrido un fallo al guardar los datos."
              })
        });
      }
      else{
        await axios
          .post(SERVER_URL, {
            headers: {        
              "Content-Type": "application/json",
              Authorization: AuthStr,
            },
            datos,
          })
          .then((response) => {   
            if(!isNaN(response.data)){ 
              setOpenAlert({
                open : true,
                tipoMensaje : "success",
                mensaje : "¡Factura guardada correctamente!"
              }) 
              setTimeout(function () {               
                history.push({
                  pathname: `/administracion/compras/facturascompras/${response.data}/W`,
                }); 
                window.location.reload();                
              }
              , 1000);
            }
            })
            .catch((error) => {
              console.log("error " + error);
              console.log(error.response)
              //.log(error.response.data); // you can get the response like this
              //console.log(error.response.status); 
              console.error(error);
              setOpenAlert({
                open: true,
                tipoMensaje: "error",
                mensaje: "Ha ocurrido un fallo al guardar los datos."
              })
        });
      }
  }

  const handleChangeTipoFactura = (valor) =>{ 
    if (valor) {    
        setTipoFactura(valor) 
        if(valor.Tipo.toUpperCase() === "MATERIALES" || "INVERSIÓN" || "RECIBOS"){
          campos[0].disabled = false
          campos[0].classes = ""
          campos[15].disabled = true   
          campos[15].classes = "d-none"       
          campos[16].disabled = true
          campos[16].classes = "d-none"
          campos[17].disabled = true
          campos[18].disabled = true
          campos[18].classes = "d-none"
          campos[19].disabled = true
          campos[19].classes = "d-none"
        }
        if(valor.Tipo.toUpperCase() === "SERVICIOS"){
          campos[0].disabled = true
          campos[0].classes = "d-none"
          campos[15].disabled = true  
          campos[15].classes = "d-none"  
          campos[16].disabled = false
          campos[16].classes = ""
          campos[17].disabled = true
          campos[18].disabled = false
          campos[18].classes = ""
          campos[19].disabled = false
          campos[19].classes = ""
        }  
        if(valor.Tipo.toUpperCase() === "PRODUCTOS"){
          campos[0].disabled = true
          campos[0].classes = "d-none"
          campos[15].disabled = true  
          campos[15].classes = "d-none"  
          campos[16].disabled = false
          campos[16].classes = ""
          campos[17].disabled = true
          campos[18].disabled = false
          campos[18].classes = ""
          campos[19].disabled = false
          campos[19].classes = ""
        }  
        if(valor.Tipo.toUpperCase() === "CPE"){
          campos[0].disabled = true
          campos[0].classes = "d-none"
          campos[15].disabled = false   
          campos[15].classes = ""  
          campos[16].disabled = true
          campos[16].classes = "d-none"
          campos[17].disabled = false
          campos[17].classes = "d-non"
          campos[18].disabled = true
          campos[18].classes = "d-none"
          campos[19].disabled = true
          campos[19].classes = "d-none"
        }  
    }else {
      setTipoFactura('')
      campos[0].disabled = false
      campos[15].disabled = false
      campos[16].disabled = false
      campos[17].disabled = false
      campos[18].disabled = false
      campos[19].disabled = false
      campos[0].classes = "d-none" 
      campos[15].classes = "d-none" 
      campos[16].classes = "d-none" 
      campos[18].classes = "d-none" 
      campos[19].classes = "d-none" 
    }
  }

  const handleChangeOrdenCompra = (ordenCompraBBDD) => {   
    if (typeof ordenCompraBBDD !== "undefined") {    
  //SELECT fcd.Id, fcd.Fk_FacturaCompra, fcd.Cantidad, fcd.Precio, fcd.Precio_Total, fcd.Fk_Igic, fcd.Fk_Irpf, fcd.Igic_Importe FROM `mtms_factura_compra_detalles` fcd where Fk_Igic = 7 AND Igic_Importe > round((fcd.Cantidad * fcd.Precio * 0.07),2);
  if(ordenCompraBBDD){         
           //console.log(ordenCompraBBDD)
          setValue("nombre", ordenCompraBBDD.Solicita) 
          setOrdenCompra(ordenCompraBBDD)              
          const articulosArray = ordenCompraBBDD.articulos
          let arrayProductos = []  
          let arrayProveedores = []    
          articulosArray.forEach(element => {  
            //console.log(element)
            //console.log( (element.cantidad * element.precioUnidad) * (1 + (element.igic /100)))
            element.Fk_OrdenCompra =  ordenCompraBBDD.Id
            element.precioTotal =  (element.cantidad * element.precioUnidad) * (1 + (element.igic /100))
            element.igicImporte = (element.cantidad * element.precioUnidad) * (element.igic /100)
            arrayProductos.push(element)            
            arrayProveedores.push(element.proveedor)
          });
          //console.log(arrayProductos)
          setListadoCompra(arrayProductos)
        } 
      } else {       
        setListadoCompra([]) 
        setOrdenCompra([])              
        setValue("nombre", '') 
      }
  }  
  
  const handleChangeCarpetaOperativa = (carpetaOperativa) => {
    setTipoFacturaProductosyServicios(carpetaOperativa)
    if(carpetaOperativa){
      campos[18].disabled = true
      campos[19].disabled = true
    }else{
      campos[18].disabled = false
      campos[19].disabled = false
    }
  }

  const handleChangeCarpetaMaquinaria = (carpetaMaquinaria) => {
    setTipoFacturaProductosyServicios(carpetaMaquinaria)
    if(carpetaMaquinaria){
      campos[16].disabled = true
      campos[19].disabled = true
    }else{
      campos[16].disabled = false
      campos[19].disabled = false
    }
  }

  const handleChangeCarpetaInstalacion= (carpetaInstalacion) => {
    setTipoFacturaProductosyServicios(carpetaInstalacion)
    if(carpetaInstalacion){
      campos[16].disabled = true
      campos[18].disabled = true
    }else{
      campos[16].disabled = false
      campos[18].disabled = false
    } 
  }

  const handleChangeProveedor = (proveedor) => {    
    setProveedor(proveedor)
  }  

  const handleChangeCuentaAnalitica = (cuenta) => {    
    if(cuenta)
      setCuentaAnalitica(true)
    else setCuentaAnalitica(false)
  }

  const handleChangeCuentaPyG = (cuenta) => {    
    if(cuenta)
      setCuentaPyG(true)
    else setCuentaPyG(false)
  }

  const desactivaCuentas = cuentaAnalitica && cuentaPyG  
console.log(ordenCompra)

  return (
  <>    
    {!isLoading && (
    <>
      {/* FORMULARIO */}
      <div className={classes.root}>
          <FormProvider {...methods}>
            <form
              id='formulario-FacturaCompra'
              onSubmit={handleSubmit((data) => {
              handleSubmitPost(data);
              })}
              className=""
            >
            <Grid className="borderFormulario" container spacing={1}> 
                <FormFieldCombo propiedades={campos[10]} control={control} onChangeValue={handleChangeTipoFactura}/>
                <FormFieldDate propiedades={campos[2]} control={control}/>
                <FormFieldText propiedades={campos[3]} control={control}/>
                <FormFieldText propiedades={campos[17]} control={control}/>                
                <FormFieldDate propiedades={campos[4]} control={control}/>
                
                <FormFieldCombo propiedades={campos[5]} control={control} onChangeValue={handleChangeProveedor}/>
                
                {/*SERVICIOS*/}
                <FormFieldCombo propiedades={campos[16]} control={control} onChangeValue={handleChangeCarpetaOperativa}/>
                <FormFieldCombo propiedades={campos[18]} control={control} onChangeValue={handleChangeCarpetaMaquinaria}/>
                <FormFieldCombo propiedades={campos[19]} control={control} onChangeValue={handleChangeCarpetaInstalacion}/>
                
                {/*MATERIALES*/}
                <FormFieldCombo propiedades={campos[0]} control={control} onChangeValue={handleChangeOrdenCompra}/>
                
                {/*CPE*/}                
                <FormFieldCombo propiedades={campos[15]} control={control} onChangeValue={handleChangeCarpetaOperativa}/>
                

                <FormFieldCombo propiedades={campos[1]} control={control} />
                <FormFieldNumber propiedades={campos[20]} control={control}/>

                <FormFieldLineSeparator propiedades={{xs:12}} />

                <FormFieldCombo propiedades={campos[6]} control={control}/>                
                <FormFieldCombo propiedades={campos[12]} control={control} onChangeValue={handleChangeCuentaPyG}/>
                <FormFieldCombo propiedades={campos[13]} control={control} />
                <FormFieldCombo propiedades={campos[14]} control={control} />
                
                <FormFieldTextArea propiedades={campos[7]} control={control}/>
                <FormFieldFiles propiedades={campos[9]} control={control} ficheros={handleFicheros}/>
                <FormFieldFileUpload propiedades={campos[8]} control={control} handleFicheros={handleFicheros}/>                

            </Grid>
            <FormularioTablaFactura listadotabla= {handleListadoTabla} tipoFactura={tipoFactura} proveedor={proveedor} desactivaCuentas={desactivaCuentas}/>                        
            </form>
            </FormProvider>
            <TablaFacturaCompra listadoCompra={listadoCompra} ListadoCompraFinal={handleRemoveElement} />
            {openAlert && 
              <AlertaMensaje 
                    mensaje={openAlert.mensaje} 
                    isOpen={openAlert.open} 
                    tipoMensaje={openAlert.tipoMensaje}
                    cerrar={handleCloseAlert}
                  />
            /*
            <AlertaMensaje 
              mensaje={"¡Factura guardada correctamente!"} 
              isOpen={openAlert} 
              tipoMensaje="success"
              cerrar={handleCloseAlert}
            />
            */

            }  
      </div>  
    </>
    )}
      <div>
        {isLoading && 
          <div className={classes.linear}>
                <LinearProgress />
          </div>
        }
      </div>
  </>
  )
};

export default FormularioFacturaDeCompra;