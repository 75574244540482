import React, { useState } from "react";
import { useParams } from "react-router-dom";

import TableAcciones from "../../../../../components/Common/TableAcciones";
import PasosPedido from "./PasosPedido"
import MigasDePan  from "../../../../../components/Common/MigasDePan";
import SearchBar from "../../../../../components/Common/SearchBar";

const Pedido = (props) => {
    let { id, modo } = useParams();  
    const [codigoPedido, setCodigoPedido] =  useState('');

    const botoneraAcciones = [ 
      /*   
        {
          etiqueta: "Crear Pedido",
          url: "/ventas/pedidos/pedido",  
          activo: true,
        },
        */
        {
          etiqueta: modo === "R" ? "Editar Nota de Pedido" : "Cancelar Edición",
          url: 
          typeof id === "undefined" ? `/ventas/pedidos/` :
          modo === "R" ? 
            `/ventas/pedidos/pedido/${id}/W` : 
            `/ventas/pedidos/pedido/${id}/R`,
          activo: true,
        }, 
        /*       
        {
          etiqueta: "Eliminar Pedido",          
          url: `/crm/cuentas/crearoportunidad/${id}`,
          activo: true,
        },
        {
          etiqueta: "Crear Cotización",
          url: "/ventas/cotizaciones/cotizacion/",
          activo: true,
        },
        {
          etiqueta: "Crear Carpeta",
          url: "/operativas/operaciones/carpeta/",
          activo: true,
        },
        */
    ];

    const urlsMigasdepan = [
      {titulo: "Pedidos clientes", colorTexto: "texto-comercial", urlTo: "/ventas/pedidos"},
      {titulo: "Nota de Pedido", colorTexto: "texto-comercial", urlTo: `/ventas/pedidos/pedido/${id}/${modo}`},
    ]    

    const getCodigoPedido = (valor) => {
      setCodigoPedido(valor)
    }    

    return (
        <>
        <div className="col-8 col-main-home ">            
        <SearchBar
          titulo="Ventas"
          claseCabecera = "bg-comercial"
          buscar={false}
          imagenCabecera={"4-2-1-pedidos_clientes.png"}
        />   
        <>
        <div className="bg-cuerpo p-10px">
          <div className="bg-white p-10px">
            <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigoPedido}/>
              
            <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
              <PasosPedido getCodigo={getCodigoPedido}/>
            </div>
          </div>
          <TableAcciones
            filaSeleccionada={-1}
            botonaccion={botoneraAcciones}
          />
        </div>
        </>       
      </div>  
      </>
    );
}

export default Pedido;

/*
import FormularioPedido from "./FormularioPedido"
import FichaPedido from "./FichaPedido"

 <div className="col-8 col-main-home ">
            <SearchBar
              titulo="Ventas"
              claseCabecera = "bg-comercial"
              buscar={false}
            />             
              <>
                <div className="bg-cuerpo p-10px">
                  <div className="bg-white p-10px">
                    <MigasDePan clases="texto-comercial float-left" urls={urlsMigasdepan} codigo={codigoPedido}/>
                      
                    <div className="principal bg-white cuerpo-h pr-2 overflow-auto overflow-hiddens table-responsive">
                      {modo !== "R" && <FormularioPedido getCodigo={getCodigoPedido}/>}
                      {modo === "R" && <FichaPedido getCodigo={getCodigoPedido}/>}
                    </div>
              </div>
              <TableAcciones
                filaSeleccionada={-1}
                botonaccion={botoneraAcciones}
              />
            </div>
            </>            
      </div>
*/