import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';

const FormFieldTextUncontrolled = ({ propiedades, onChangeValue=null }) => { 
    const [valor, setValor] = useState(propiedades.value);

    const handleChange = (event) => {
        onChangeValue(event.target.value);
        setValor(event.target.value);
    };

    useEffect(() => {
        setValor(propiedades.value);
    }, [propiedades.value])

    return (               
    <Grid item xs={propiedades.xs} className="" > 
    <TextField  
        required = {propiedades.required}
        id={propiedades.name}
        name={propiedades.name}
        disabled = {propiedades.disabled}
        label={propiedades.label}
        type="number" 
        value={valor} 
        multiline
        onChange={handleChange}
        rows={propiedades.lineas}
        variant="outlined"
        size="small"
        fullWidth
    />  
    </Grid>       
    )
}
export default FormFieldTextUncontrolled;