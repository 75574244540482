import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FormularioEntidad from '../../../../../components/Common/FormularioEntidad'

const FormularioCajas = ({ index, tipoForm }) => {

  const [CajasBancos, setCajasBancos] = useState([])
  const [campos, setCampos] = useState([])
  const { id, modo } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    setCampos([
      {
        required: true,
        name: "Entidad",
        disabled: false,
        label: "Entidad",
        type: 'text',
        value: '',
        xs: 6,
      },
      {
        required: true,
        name: "Direccion",
        disabled: false,
        label: "Direccion",
        type: 'text',
        value: '',
        xs: 6,
      },
      {
        required: true,
        name: "Cuenta",
        disabled: false,
        label: "Nº Cuenta",
        type: 'text',
        value: '',
        xs: 6,
      },
      {
        required: true,
        name: "Iban",
        disabled: false,
        label: "IBAN",
        type: 'text',
        value: '',
        xs: 6,
      },


    ])

    const fetchData = async () => {
      if (modo === "W" || modo === "R") {
        setIsLoading(true)
        const resCajasBancos = await axios(
          `${process.env.REACT_APP_API_URL}tesoreriacajas/${id}`
        );
        setCajasBancos(resCajasBancos.data)
        setIsLoading(false);
      }
    };
    fetchData();


  }, []);

  useEffect(() => {
    if (typeof CajasBancos !== "undefined") {
      if (Object.keys(CajasBancos).length > 0) {
        setIsLoading(true)
        campos[0].value = CajasBancos.Entidad;
        campos[1].value = CajasBancos.Direccion;
        campos[2].value = CajasBancos.Cuenta;
        campos[3].value = CajasBancos.IBAN;
        if (modo === "R") {
          campos[0].disabled = true;
          campos[1].disabled = true;
          campos[2].disabled = true;
          campos[3].disabled = true;
        }
        setIsLoading(false)
      }
    }
  }, [CajasBancos])
  console.log(isLoading, CajasBancos)
  return (
    <>
      {!isLoading && (
        <>
          <FormularioEntidad
            urlForm="tesoreriacajas"
            urlGoto="/finanzas/tesoreria/cajasbancos/"
            campos={campos}
            type={modo === 'R' ? "read" : modo === 'W' ? "edit" : "add"}
          />
        </>
      )}
    </>
  )
};

export default FormularioCajas;

/*
import { makeStyles } from '@material-ui/core/styles';

import { useForm, FormProvider } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import FormFieldText from '../../../../../components/Common/FormFieldText'
import AlertaMensaje from '../../../../../components/Common/AlertaMensaje'
import LinearProgress from "@material-ui/core/LinearProgress";


const methods = useForm();

  const classes = useStyles();;
  const { control, handleSubmit } = useForm();


  const [openAlert, setOpenAlert] = React.useState(false);



  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };


const handleSubmitPost = async (datos) => {

    //cambiar los nombres de los campos dependiendo de como los mandes en el controlador
    datos.Entidad = datos.Entidad
    datos.Cuenta = datos.Direccion
    datos.Documentos = datos.NumeroCuenta
    datos.Tercero = datos.IBAN

    let user = JSON.parse(localStorage.getItem("user"));
    const AuthStr = "Bearer ".concat(user.access_token);
    //cambiar el endpoint cuando este
    const SERVER_URL = `${process.env.REACT_APP_API_URL}facturasunicas`

    console.log(datos)
    if (modo === "W") {
      await axios
        .put(`${SERVER_URL}/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {
          //console.log(response.data)
          if (!isNaN(response.data.Id)) {
            if (parseInt(response.data.Id) === parseInt(id)) {
              setOpenAlert(true)
            }
          }
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }
    else {
      await axios
        .post(SERVER_URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          datos,
        })
        .then((response) => {
          console.log(response.data)
          if(!isNaN(response.data.Id)){
            if(parseInt(response.data.Id) === parseInt(id)){
                setOpenAlert(true)
            }
          }
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }



  }
return (
    <>
      {!isLoading && (
        <>

          <div className={classes.root}>
            <FormProvider {...methods}>
              <form
                id='formulario-Operaciones'
                onSubmit={handleSubmit((data) => {
                  handleSubmitPost(data);
                })}
                className=""
              >
                <Grid className="borderFormulario" container spacing={1}>

                  <FormFieldText propiedades={campos[0]} control={control} />
                  <FormFieldText propiedades={campos[1]} control={control} />
                  <FormFieldText propiedades={campos[2]} control={control} />
                  <FormFieldText propiedades={campos[3]} control={control} />

                </Grid>
              </form>
            </FormProvider>
            {openAlert &&
              <AlertaMensaje
                mensaje={"¡Parte guardado correctamente!"}
                isOpen={openAlert}
                tipoMensaje="success"
                cerrar={handleCloseAlert}
              />
            }
          </div>
        </>
      )}
      <div>
        {isLoading &&
          <div className={classes.linear}>
            <LinearProgress />
          </div>
        }
      </div>
    </>
  )
  */