import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SearchBar from "../../../../components/Common/SearchBar";
import VentasActuales from "./VentasActuales";
import EjercicioAnual from "./EjercicioAnual";
import CategoriasyClientes from "./CategoriasyClientes";
import FiltroListado from "./FiltroListado";

const useStyles = makeStyles((theme) => ({
  root: {flexGrow: 1,},
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',      
  },
  padding16: {
    padding: theme.spacing(2),
  }, 
  control: {
    padding: theme.spacing(2),
  }, 
  titulo: {
    padding: theme.spacing(2),
  },
  objetivo: {
    padding: theme.spacing(10),
  } 
}));

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

let date = new Date();
let firstDay = new Date(date.getFullYear(), 0, 1);
//let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
let lastDay = date; // ((new Date(date.getFullYear(), date.getMonth() + 1, 0);
firstDay = formatDate(firstDay);
lastDay = formatDate(lastDay);

const CuadroDeMandos = () => {   
  const classes = useStyles();  
  const [esDirectivo, setEsDirectivo]=useState(false); 
  const [url, setUrl] = useState(`?fechainicial=${firstDay}&fechafinal=${lastDay}`)
  const [datoVentas, setDatosVentas] = useState({
    resumenAnual: {total: 0, porcentaje: 0},
    resumenMensual: [],
    resumenCategorias: [],
    resumenClientes: []
  })  
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => { 
    const fetchData = async () => {  
      const user = JSON.parse(localStorage.getItem("user"));
      const respdatos = await axios(`${process.env.REACT_APP_API_URL}rrhhdatoslaborales/${user.id}`);   
      const datos = respdatos.data
      if(datos && datos.Puesto_Trabajo && datos.Puesto_Trabajo.Etiqueta && datos.Puesto_Trabajo.Etiqueta === "DIRECTIVO"){
        setEsDirectivo(true)
      }         
    };    
  fetchData();    
  }, [])

  useEffect(() => { 
    if(esDirectivo){
      const fetchData = async () => { 
        const respdatos = await axios(`${process.env.REACT_APP_API_URL}cuadromandos/situacionactualventas${url}`)   
        setDatosVentas(respdatos.data)        
      };    
      fetchData();    
    }
  }, [esDirectivo, url])

  const handleUrlFecha = (url) =>{  
    setUrl(url)     
  }

  let botoneraPresupuesto = [{
    etiqueta: "Imprimir Informe General",
    function: (e) => handlePrintReport(), 
    activo: true    
  },]

  const handlePrintReport = () => {

    const fetchData = async () => {
        setIsLoading(true);
        try{
            const respdatos = await axios(`${process.env.REACT_APP_API_URL}cuadromandos/imprimeinformegeneral${url}`)          
          console.log(respdatos.data)
            if (respdatos.data) {
            if(respdatos.data.includes(".pdf"))
                window.open(respdatos.data, "_blank")                
        }
        }catch(error){
            console.log(error)
        }
        finally{
            setIsLoading(false);
        }
        };
        fetchData();      
  }

  const initialValue = 0;
  const sumWithInitial = datoVentas.resumenCategorias.reduce(
    (accumulator, currentValue) => accumulator+ currentValue.total,
    initialValue,
  );

  return (          
    <div className="col-main-home ">            
      <SearchBar
        titulo="INICIO"
        claseCabecera = "bg-comercial"
        buscar={false}
        imagenCabecera={"1-1-cuadro-de-mandos.png"}  
      />   
      
      <div className="bg-cuerpo p-10px">
        <div className="bg-white cuerpo-h-max">   
          <div className="principal bg-cuerpo cuerpo-h-max overflow-auto overflow-hiddens table-responsive mx-auto">
          
          {!esDirectivo && (
            <>
            <ComercialButtons />
            <ProductionButtons />
            <GestionButtons />
            </>
          )}

          {esDirectivo && (
            <>
              <FiltroListado urlfecha={handleUrlFecha} firstDay={firstDay} lastDay={lastDay}/>
              <VentasActuales 
                totalVentas={datoVentas.resumenAnual.total} 
                porcentajeTotalVentas={datoVentas.resumenAnual.porcentaje} 
                portcentajeObjetivoVentas={datoVentas.resumenAnual.porcentaje} 
                fechas={url} 
              />
              <EjercicioAnual ventas={datoVentas.resumenMensual} />
              <CategoriasyClientes ventasPorCategorias={datoVentas.resumenCategorias} ventasPorClientes={datoVentas.resumenClientes}/>
            </>
          )}
          {/*
          {esDirectivo && (           
            <div className="bg-cuerpo">
              <div className="bg-white">
                <TableAcciones botonaccion={botoneraPresupuesto} />
              </div>    
            </div>
            )}
            */}
         

          <Grid container className={classes.root} spacing={0}>            

          </Grid> 
          </div> 
        </div>
      </div>
    </div> 
  );
}
export default CuadroDeMandos;

const ComercialButtons = () => {
  const history = useHistory(); 

  const handleClickToUrl = (url) => {
    history.push({
      pathname: `${url}`,       
    });
  }

  return(
    <Grid container spacing={3} className={"p-5"}>        
        <Grid item xs={3} sm={3}>
            <Button className={"bg-comercial w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('crm/cuentas') }}>
            <ImagenBoton imagen={'4-1-1-cuentas.png'} />               
              CUENTAS
            </Button>              
        </Grid>
        
        <Grid item xs={3} sm={3}>
          <Button className={"bg-comercial w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('ventas/pedidos') }}>
          <ImagenBoton imagen={'4-2-1-pedidos_clientes.png'} />             
              PEDIDOS
          </Button>              
        </Grid>

        <Grid item xs={3} sm={3}>              
            <Button className={"bg-comercial w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('ventas/cotizaciones') }}>
            <ImagenBoton imagen={'4-2-2-cotizaciones.png'} />            
              COTIZACIONES
            </Button>              
        </Grid>

        <Grid item xs={3} sm={3}>              
            <Button className={"bg-comercial w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('ventas/productos') }}>
            <ImagenBoton imagen={'4-2-3-productos.png'} />            
              PRODUCTOS
            </Button>              
        </Grid>                
      </Grid>
  )
}

const ProductionButtons = () => {
  const history = useHistory(); 

  const handleClickToUrl = (url) => {
    history.push({
      pathname: `${url}`,       
    });
  }

  return(
    <Grid container spacing={3} className={"p-5"}>  

      <Grid item xs={3} sm={3}>              
        <Button className={"bg-produccion w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('logistica/materiales') }}>
          <ImagenBoton imagen={'5-1-2-materiales.png'} /> 
            MATERIALES
          </Button>              
      </Grid> 

        <Grid item xs={3} sm={3}>              
            <Button className={"bg-produccion w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('operativas/planificaciones') }}>
            <ImagenBoton imagen={'5-2-1-planificacion.png'} />                         
              PLANIFICACIONES
            </Button>              
        </Grid>
        
        <Grid item xs={3} sm={3}>              
            <Button className={"bg-produccion w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('operativas/operaciones') }}>
            <ImagenBoton imagen={'5-2-2-operaciones.png'} />   
              OPERACIONES
            </Button>              
        </Grid>
        
        <Grid item xs={3} sm={3}>              
            <Button className={"bg-produccion w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('ventas/cotizaciones') }}>
            <ImagenBoton imagen={'5-2-3-almacenamiento.png'} />               
              ALMACENAMIENTO
            </Button>              
        </Grid>

      </Grid>
  )
}

const GestionButtons = () => {
  const history = useHistory(); 

  const handleClickToUrl = (url) => {
    history.push({
      pathname: `${url}`,       
    });
  }

  return(
    <Grid container spacing={3} className={"p-5"}>        
        <Grid item xs={3} sm={3}>              
            <Button className={"bg-gestion w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('administracion/compras') }}>            
            <ImagenBoton imagen={'6-1-1-compras.png'} /> 
              COMPRAS
            </Button>              
        </Grid>

        <Grid item xs={3} sm={3}>              
            <Button className={"bg-gestion w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('administracion/ventas') }}>
            <ImagenBoton imagen={'6-1-2-facturas-ventas.png'} />
              FACTURAS VENTAS
            </Button>              
        </Grid>

        <Grid item xs={3} sm={3}>              
            <Button className={"bg-gestion w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('finanzas/explotacion') }}>
            <ImagenBoton imagen={'6-2-2-cuenta_explotacion.png'} />
              EXPLOTACIÓN
            </Button>              
        </Grid>

        <Grid item xs={3} sm={3}>              
            <Button className={"bg-gestion w-100"} variant="contained" color="primary" onClick={() => { handleClickToUrl('rrhh/gestionpersonal') }}>
            <ImagenBoton imagen={'6-3-2-rrhh.png'} />
              GESTIÓN PERSONAL
            </Button>              
        </Grid>                
      </Grid>
  )
}

const ImagenBoton = ({imagen}) => {
  return(
    <img
      src={`${process.env.REACT_APP_IMAGES}${imagen}`}
      width="32"
      height="32"
      alt=""
      loading="lazy"
    />
  )
}