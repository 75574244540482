import React, { useState } from "react"
import Grid from '@material-ui/core/Grid';

import TablaCuentasVentasCategorias from "./TablaCuentasVentasCategorias";

const TablasCuentasVentas = () => {

    const [categoria, setCategoria] = useState(0)

    const handleClickCategorias = (idCategoria) => {        
        if(idCategoria)
            setCategoria(idCategoria)
    }
    return (
    <>
    <div className="mt-2">
        <Grid className="borderFormulario" container spacing={1}>
            <Grid item xs={6} className="" >
                <TablaCuentasVentasCategorias tipo={1} urlController={"dic/diccategoriasproductos"} urlGetData={"dic/diccionarios/categoriasproductocontabilidad"} handleClick={handleClickCategorias}/>
            </Grid>
            <Grid item xs={6} className="" >
                <TablaCuentasVentasCategorias tipo={2} urlController={"dic/diccategoriasproductos"} urlGetData={"dic/diccionarios/subcategoriasproductocontabilidad/" + categoria}/>
            </Grid>
        </Grid>
    </div>
    </>
    )
}

export default TablasCuentasVentas;