import API from './server';

export async function getData(url) {
    try {
        let user = JSON.parse(localStorage.getItem("user"));

        const config = {
            headers: { Authorization: `Bearer ${user.access_token}` }
        };
    
        const bodyParameters = {
            key: null
        }; 

        const response = await API.get(url, bodyParameters, config);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function createData(url, data) {
    try {
        let user = JSON.parse(localStorage.getItem("user"));

        const config = {
            headers: { Authorization: `Bearer ${user.access_token}` }
        };
    
        const bodyParameters = {
            data: data
        }; 

        const response = await API.post(url, bodyParameters, config);
        return response.data;
        //return alert("Exito");
    } catch (error) {
        console.log(error);
    }
}

export async function deleteData(url){
    try {
        let user = JSON.parse(localStorage.getItem("user"));

        const config = {
            headers: { Authorization: `Bearer ${user.access_token}` }
        };
    
        const bodyParameters = {
            data: null
        }; 

        const response = await API.delete(url, bodyParameters, config);
        return response.data;
        
    } catch (error) {
        console.log(error);
    }
}