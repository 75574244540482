import React, { useState, useEffect, useContext  } from 'react';
import { StateContext } from '../../../../../context'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import OperacionChildNode from "./OperacionChildNode" 
import FormularioPINOperacion from "./FormularioPINOperacion"
import ListadoPINOperacion from "./ListadoPINOperacion"
import FormularioTDROperacion from "./FormularioTDROperacion"
import ListadoTDROperacion from "./ListadoTDROperacion"
import FormularioDOPOperacion from './FormularioDOPOperacion';
import ListadoDOPOperacion from './ListadoDOPOperacion';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function DocumentacionOperacion() {
    const classes = useStyles();
    const [tipo, setTipo] = useState() 

    const actualizaTipo = (valor) => {        
        setTipo(valor)
    }

    const claseDOP = tipo === "DOP" ? 'bg-produccion text-white' : ''
    const clasePin = tipo === "PIN" ? 'bg-produccion text-white' : ''
    const claseTDR = tipo === "TDR" ? 'bg-produccion text-white' : ''

    return (  
      <div className={classes.root}>      
          <ButtonGroup color="primary" aria-label="contained primary button group">
              <Button className={ claseDOP } onClick={(event) => {event.stopPropagation(); actualizaTipo("DOP")}}>Documentación Operativa </Button>
              <Button className={ clasePin } onClick={(event) => {event.stopPropagation(); actualizaTipo("PIN")}}>Parte de Inicidencias </Button>
              <Button className={ claseTDR } onClick={(event) => {event.stopPropagation(); actualizaTipo("TDR")}}>Terminal Departure Report</Button>                   
          </ButtonGroup>   

          { tipo === "DOP" && 
            <OperacionChildNode  
              Formulario={FormularioDOPOperacion}
              Listado={ListadoDOPOperacion}
              widthF={12}
              widthL={12}              
            />
          }

          { tipo === "PIN" && 
            <OperacionChildNode  
              Formulario={FormularioPINOperacion}
              Listado={ListadoPINOperacion}
              widthF={12}
              widthL={12}              
            />
          }

          { tipo === "TDR" && 
            <OperacionChildNode
              Formulario={FormularioTDROperacion}
              Listado={ListadoTDROperacion}
              widthF={12}
              widthL={12}
            />
          }
                     
      </div>       
  );

}