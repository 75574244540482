import React from "react";
import {TEXT_LABELS} from "../../../../../configuraciones/conf"

const classDiv3 = "col-3 mt-2 mb-2";

const OPTIONS_ACTIVIDADES = {
    filterType: 'checkbox',
    print: false,
    download: false,
    pagination: false,
    selectableRows: 'single',
    expandableRows: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    elevation:0,
    textLabels: TEXT_LABELS,
};

const CAMPOS_ACTIVIDADES = [
  {
    urlData: "dic/diccionarios/listadoactividadestipo",
    campoEtiqueta: "Etiqueta",
    required: true,
    label: "Tipo",
    name: "tipo",
    type: "combo",  
    value: '',
    disabled: false, 
    multiple:false,
    xs:4
  },            
  {    
    required: true,
    label: "Nombre:",
    name: "nombre",
    type: "text",    
    disabled: false, 
    value: '',                
    xs:4
  },
  {
    urlData: "dic/diccionarios/listadovisibilidades",
    campoEtiqueta: "Etiqueta",
    required: false,
    label: "Visibilidad",
    name: "visibilidad",
    type: "combo",  
    disabled: false, 
    value: '',
    multiple:false,
    xs:4
  },  
  {
    urlData: "cuentas/clientes",
    campoEtiqueta: "Nombre_Comercial",
    required: false,
    label: "Cliente",
    name: "nombreComercial",
    disabled: false,
    type: "combo",        
    multiple:true,   
    value: [],
    xs:4,
  }, 
  {
    urlData: "contactos",
    campoEtiqueta: "Nombre",
    required: false,
    label: "Contacto",
    name: "contacto",
    disabled: false,
    type: "combo",           
    multiple:true,   
    value: [],
    xs:4
  },
/*{
    urlData: "oportunidads/listadooportunidades",
    campoEtiqueta: "Codigo",
    required: true,
    label: "Oportunidad",
    name: "oportunidad",
    disabled: false,
    type: "combo",           
    multiple:false,   
    value: '',
    xs:4
},*/
  {
    urlData: "dic/diccionarios/listadousuarios",
    campoEtiqueta: "nombre",
    campoId:"id",
    required: false,
    label: "Empleado",
    name: "empleado",
    disabled: false,
    type: "combo",           
    multiple:true,   
    value: [],
    xs:4
  },
  {
    urlData: "dic/diccionarios/listadousuarios",
    campoEtiqueta: "nombre",
    required: false,
    label: "Supervisor",
    name: "supervisor",
    disabled: false,
    type: "combo",           
    multiple:false,   
    value: '',
    xs:4
  }, 
  {
    urlData: "actividads/estadosactividad",
    campoEtiqueta: "Etiqueta",
    required: false,
    label: "Resultado actividad",
    name: "resultado",
    disabled: false,
    type: "combo",           
    multiple:false,   
    value: '',
    xs:4
  },  
  {
    type: "lineseparator",
    xs:4
  },          
  {
    required: false,
    label: "Fecha inicio",
    name: "fechainicio",
    type: "date",  
    disabled: false, 
    value: '',
    xs:4
  },
  {
    required: false,
    label: "Hora inicio",
    name: "horainicio",
    disabled: false, 
    type: "time",  
    value: '',
    xs:2
  },
  {
    required: false,
    label: "Fecha estimada cierre",
    name: "fechacierre",
    disabled: false, 
    type: "date",  
    value: '',
    xs:4
  },
  {
    required: false,
    label: "Hora cierre",
    name: "horacierre",
    disabled: false, 
    type: "time",  
    value: '',
    xs:2
  },  
  {
    required: false,
    label: "Descripción",
    name: "descripcion",
    disabled: false, 
    type: "textArea",
    lineas: 4,                
    value: '',
    xs:6
  },
  {
    required: false,
    label: "Notas",
    name: "notas",
    disabled: false, 
    type: "textArea",
    lineas: 4,  
    value: '',                
    xs:6
  }, 
  {
    classButton: classDiv3,
    required: false,
    disabled: false, 
    label: "",
    name: "ficherosadjuntos",
    type: "filesupload",   
    tabla: "mtms_actividades_adjuntos",
    xs:5
  },            
  {
    required: false,
    label: "",
    disabled: false, 
    name: "ficherosremotos",
    type: "files", 
    ficheros: [],    
    tabla: "mtms_actividades_adjuntos",
    //modelo: "api\\modules\\v1\\models\\actividades\\ActividadesAdjuntos",
    //carpeta: "actividades",
    xs:4
  },
]
 
const COLUMNS_ACTIVIDADES = [
    {
      name: "Id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
       }
    },
    {
      //
      name: "Codigo",
      label: "Código",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value, tableMeta, updateValue) => {
         
        return (
          <a href={`/crm/actividades/actividad/${tableMeta.rowData[0]}/R`}>{value}</a>
        );
        }
      }
     },
      {
       name: "Tipo",
       label: "Tipo",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "Nombre",
        label: "Nombre",
        options: {
         filter: true,
         sort: true,
        }
       },
      {
       name: "Descripcion",
       label: "Descripcion",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
       name: "Supervisor",
       label: "Supervisor",
       options: {
        filter: true,
        sort: true,
       }
      },      
      {
        name: "Fecha_Inicio",
        label: "Fecha inicio",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
      {
        name: "Fecha_Fin",
        label: "Fecha fin",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
      {
        name: "Hora_Inicio",
        label: "Hora Inicio",
        options: {
         filter: true,
         sort: true,
        }   
      },
      {
        name: "Hora_Fin",
        label: "Hora Fin",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
      {
        name: "Estado",
        label: "Estado de la actividad",
        options: {
         filter: true,
         sort: true,
        }   
      },
      {
        name: "Visibilidad",
        label: "Visibilidad ",
        options: {
         filter: true,
         sort: true,
        }   
      }
      
      

];
  
export {COLUMNS_ACTIVIDADES, CAMPOS_ACTIVIDADES, OPTIONS_ACTIVIDADES};