const COLUMNS_ACTIVIDADES = [
    {
      name: "Id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
       }
    },
    {
      name: "Codigo",
      label: "Código",
      options: {
       filter: true,
       sort: true,
      }
     },
      {
       name: "Tipo",
       label: "Tipo",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
        name: "Fecha_Inicio",
        label: "Fecha inicio",
        options: {
         filter: true,
         sort: true,
        }
       },
      {
       name: "Fecha_Fin",
       label: "Fecha cierre",
       options: {
        filter: true,
        sort: true,
       }
      },
      {
       name: "Cuentas",
       label: "Cuentas",
       options: {
        filter: true,
        sort: true,
       }
      },      
      {
        name: "Contactos",
        label: "Contactos",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
      {
        name: "Empleados",
        label: "Empleados",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
      {
        name: "Resultado",
        label: "Resultado",
        options: {
         filter: true,
         sort: true,
        }   
      }, 
];
export {COLUMNS_ACTIVIDADES};

const BOTONERA_ACCIONES = [
  { etiqueta: "Crear Actividad", url: "/crm/actividades/actividad", activo: true },
  {
    etiqueta: "Editar Actividad",
    url: "/crm/actividades/actividad/filaSeleccionada/W",
    activo: false,
  }, 
  /*
  { etiqueta: "Eliminar Actividad", function: (e) => eliminarEntidad(e), activo: false },
  */
]; 
export {BOTONERA_ACCIONES}