import React, { useState } from "react";
import TipoConfiguracion from "./TipoConfiguracion";
import { columnsCajas, columnsCategorias, columnsConceptos, columnsInstrumentos } from './FinanzasConf'
import CuerpoTablaPiezas from "../../../../../components/Common/CuerpoTablaPiezas";

const Configuracion = ({idSubOp, idFilaMarcada}) => {
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1);
  const [idSubOperacion, setIdSubOperacion] = useState(localStorage.getItem('idSubOpConfiguracionTesoreria') || "Cajas");

  const handleOperacion = (idO) => {
    localStorage.setItem('idSubOpConfiguracionTesoreria', idO)
    idSubOp(idO)
    setIdSubOperacion(idO)
  }

  const marcarFila = (fila) => {
    idFilaMarcada(fila); 
    setFilaSeleccionada(fila);
  }

  let columnasCuerpoTabla = [];
  let urlDatosCuerpoTabla = "";
  let urlDoubleClickCuerpoTabla = "";
  let columnIdCuerpoTabla = "";

  if (idSubOperacion === "Cajas") {
    columnasCuerpoTabla = columnsCajas;
    urlDatosCuerpoTabla = "tesoreriacajas";
    urlDoubleClickCuerpoTabla = "/finanzas/tesoreria/cajasbancos/";
    columnIdCuerpoTabla = "Id";
  }

  if (idSubOperacion === "Categorias") {
    columnasCuerpoTabla = columnsCategorias
    urlDatosCuerpoTabla = "tesoreriacategorias"
    urlDoubleClickCuerpoTabla = "/finanzas/tesoreria/categorias/"
    columnIdCuerpoTabla = "Id"
  }

  if (idSubOperacion === "Conceptos") {
    columnasCuerpoTabla = columnsConceptos
    urlDatosCuerpoTabla = "tesoreriaconceptos"
    urlDoubleClickCuerpoTabla = "/finanzas/tesoreria/conceptos/"
    columnIdCuerpoTabla = "Id"
  }

  if (idSubOperacion === "Instrumentos") {
    columnasCuerpoTabla = columnsInstrumentos
    urlDatosCuerpoTabla = "tesoreriainstrumentos"
    urlDoubleClickCuerpoTabla = "/finanzas/tesoreria/instrumentos/"
    columnIdCuerpoTabla = "Id"
  }

  return (
    <>
      <TipoConfiguracion opera={handleOperacion} pasoActivo={idSubOperacion} />
      <CuerpoTablaPiezas
        columnas={columnasCuerpoTabla}
        urlDatos={urlDatosCuerpoTabla}
        urlDoubleClick={urlDoubleClickCuerpoTabla}
        columnId={columnIdCuerpoTabla}
        getFilaSeleccionada={marcarFila}
      />
    </>
  );



}
export default Configuracion;