import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
//Nosotros usamos otra ficha
import FichaEntidad from '../../../../../components/Common/FichaEntidad'

const FichaSoporte = () => {
    let { id } = useParams();

    const [soporte, setSoporte] = useState([])  
    const [campos, setCampos] = useState([]) 
   
    useEffect(() => {        
        const fetchData = async () => {
          const respSoporte = await axios(`${process.env.REACT_APP_API_URL}soportes/${id}`);    
          setSoporte(respSoporte.data)  
        };
        fetchData();  
    }, [id]);

 const classText= "Texto14"
    //Imprimimos los datos 
    useEffect(() => {      
        if(Object.keys(soporte).length > 0){  
            console.log(soporte)
            setCampos([
                
            {
                classLabel: "",
                label: "Codigo:",
                value: soporte.Codigo,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Tipologia:",
                value: soporte.Tipologia.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Departamento:",
                value: soporte.Departamento.Etiqueta,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Fecha:",
                value: soporte.Fecha,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Tercero:",
                value: soporte.Cuenta.Razon_Social,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Contactos:",
                value: soporte.Contacto.Nombre,
                classText: classText,
                xs:4
            },
            {
                classsLabel: "",
                label: "Declaración cliente:",
                value: soporte.Declaracion,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Descripción causa:",
                value: soporte.Descripcion,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Solución a adoptar:",
                value: soporte.Solucion,
                classText: classText,
                xs:12
            },
            {
                classsLabel: "",
                label: "Responsable:",
                value: soporte.Responsable.nombre,
                classText: classText,
                xs:3
            },

            {
                classsLabel: "",
                label: "Inicio ejecución:",
                value: soporte.Fecha_Ejecucion_Inicial,
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Fin ejecución:",
                value: soporte.Fecha_Ejecucion_Final,
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Estado:",
                value: soporte.Estado.Etiqueta,
                classText: classText,
                xs:3
            },
            {
                classsLabel: "",
                label: "Documentación:",
                value: soporte.ficheros,
                type: "file",
                classText: classText,
                xs:4
            },

        ])
    }
},[soporte]) 

return(
    <>
    <h6 className="texto-comercial" id="title">          
    </h6>
    <FichaEntidad
    campos = {campos}
    //Es otro urlClick donde redirige para editar
    urlClick = {`/postventa/soportes/soporte/${id}/W`}
    />
    </>
  )
}
export default FichaSoporte;