import React from "react";

const getCosteTotal = (datosJornadas, mano) => {
    let auxiliarPropio = []
    let auxiliarExterno = []

    if(datosJornadas && datosJornadas.length > 0){
        auxiliarPropio = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo auxiliar propio" && parseInt(datosJornada.elementos.mano) === parseInt(mano))
        auxiliarExterno = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo auxiliar externo" && parseInt(datosJornada.elementos.mano) === parseInt(mano))  
    }
    
    let costeTotalAuxiliar = 0;
    let costeTotalAuxiliarPropio = 0;
    let costeTotalAuxiliarExterno = 0;
    let unidadesTotalAuxiliarPropio=0;
    let unidadesTotalAuxiliarExterno=0;

    auxiliarPropio.forEach(manoObra => {
        costeTotalAuxiliar += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        costeTotalAuxiliarPropio += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        unidadesTotalAuxiliarPropio += parseInt(manoObra.elementos.cantidad)
    });     

    auxiliarExterno.forEach(manoObra => {
        costeTotalAuxiliar += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        costeTotalAuxiliarExterno += parseFloat(manoObra.elementos.Coste) * parseInt(manoObra.elementos.cantidad)
        unidadesTotalAuxiliarExterno += parseInt(manoObra.elementos.cantidad)
    })

    return {
        costeTotalAuxiliar: costeTotalAuxiliar, 
        costeTotalAuxiliarPropio: costeTotalAuxiliarPropio,
        costeTotalAuxiliarExterno: costeTotalAuxiliarExterno,
        unidadesTotalAuxiliarPropio: unidadesTotalAuxiliarPropio,
        unidadesTotalAuxiliarExterno: unidadesTotalAuxiliarExterno,        
    }
}

const RecursosManoDetalleAuxiliarDesgloce = ({
    datosJornadas, mano}) => 
{  
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);  
    const costeTotalManoObraAgrupado = getCosteTotal(datosJornadas, mano)
      

    const costeTotalAuxiliar = numberFormat2.format(costeTotalManoObraAgrupado.costeTotalAuxiliar)    
    const maquinariaPropia = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo auxiliar propio" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []
    const maquinariaExterna = datosJornadas ? datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Equipo auxiliar externo" && parseInt(datosJornada.elementos.mano) === parseInt(mano)) : []   
    const unidadesTotalMaquinariaPropia = costeTotalManoObraAgrupado.unidadesTotalAuxiliarPropio;
    const unidadesTotalMaquinariaExterna = costeTotalManoObraAgrupado.unidadesTotalAuxiliarExterno;
    const auxiliarTotalUnidades = unidadesTotalMaquinariaPropia + unidadesTotalMaquinariaExterna;
    
    return (        
    <>
    <div className="col-12 d-flex overflow-auto justify-content-center mb-2">
        <div className="col-2 float-left">Empresa </div>
        <div className="col-1 float-right">N.º</div>
        <div className="col-5 float-left">Tipología</div>
        <div className="col-2 float-left text-right">Cantidad </div>
        <div className="col-2 float-right text-right">Costes</div>
    </div>

    {maquinariaPropia &&  maquinariaPropia.map((maquinaria, index) => (
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">
            <div className="col-2 float-left">Gesport</div>
            <div className="col-1 float-right">{index+1}</div>
            <div className="col-5 float-left">{maquinaria.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{maquinaria.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format(maquinaria.elementos.Coste * maquinaria.elementos.cantidad)}</div>
        </div>
    ))}

    {maquinariaExterna &&  maquinariaExterna.map((maquinaria, index) => (
        <div key={index} className="col-12 d-flex overflow-auto justify-content-center mt-1 mb-1">
            <div className="col-2 float-left">Externo</div>
            <div className="col-1 float-right">{maquinariaPropia.length + index+1}</div>
            <div className="col-5 float-left">{maquinaria.elementos.Nombre}</div>
            <div className="col-2 float-left text-right">{maquinaria.elementos.cantidad} </div>
            <div className="col-2 float-right text-right">{numberFormat2.format(parseFloat(maquinaria.elementos.Coste) * parseInt(maquinaria.elementos.cantidad))}</div>
        </div>
    ))}
    <div className="col-12 d-flex overflow-auto justify-content-center mt-2 mb-2 ">                    
        <div className="col-5 float-left"></div>
        <div className="col-3 float-right text-right">Total</div>                        
        <div className="col-2 float-left text-right">{auxiliarTotalUnidades} </div>
        <div className="col-2 float-left text-right">{costeTotalAuxiliar}</div>                    
    </div> 
    </>    
    )
}

export default RecursosManoDetalleAuxiliarDesgloce;