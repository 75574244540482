import React from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";

const BotonAccion = (props) => {    
  let { id } = useParams(); 

  const handleClick = (fichero) => {
    const oDate = new Date();    
    window.open(fichero +'?qt='+oDate.getTime(), "_blank")
  };

  return props.botonaccion.map((boton, index) => {
    //console.log(boton)
    const modoActivo = 
    boton.activo || 
    (!boton.activo && props.filaSeleccionada > 0) || 
    (boton.activo && typeof id !== "undefined")    
    const modoDesactivado =  !modoActivo
    const nombreClase = "btn-accion" + (modoDesactivado ? "-inactivo" : "")
    //console.log(modoDesactivado)
    let clases = "col ml-2 mr-2 btn-accion-bordes"

    if(boton.url){
      let urlTo = modoActivo ? boton.url.replace('filaSeleccionada', props.filaSeleccionada) : ""
      return ( 
          <div key={index} className={clases} >
            <Link 
              to={urlTo}
              className={modoDesactivado ? "disabled-link" : "" }
            >
              <Button  
                className={"btn-sm " + nombreClase}
                disabled={ modoDesactivado }
              > {boton.etiqueta}
              </Button>
            </Link>
          </div>
      );
    }
    else if(boton.form){
      return (      
          <div key={index} className={clases} >
            <Button
              className={"btn-sm " + nombreClase}
              form={modoActivo && boton.form}
              disabled={ modoDesactivado }
            > {boton.etiqueta}
            </Button>         
          </div>
       )
    }
    else if(boton.function){
      return ( 
          <div key={index} className={clases} onClick={() => { modoActivo && boton.function(props.filaSeleccionada) } } >
            <Button 
              className={"btn-sm " + nombreClase}
              disabled={ modoDesactivado }
            > {boton.etiqueta}
            </Button>         
          </div>
      )
    }
    else if(boton.file){
      return ( 
          <div  key={index} className={clases} >           
            <Button
                className={"btn-sm " + nombreClase}
                disabled={ modoDesactivado  }
                onClick={() => {modoActivo && handleClick(boton.file)} }
              > {boton.etiqueta}
            </Button> 
          </div>
      )
    }
  });
};

const TableAcciones = (props) => { 
  if (props.botonaccion) {
    return (
      <div className="row bg-white h-auto mt-2 p-4 no-gutters">
        <BotonAccion {...props} />
      </div>
      
    );
  }
};

export default TableAcciones;
