import React from "react";
import Nav from "../../components/Common/Header";
import Menuizq from "../../components/Common/Menuizq";
import MenuDerFacturaVenta from "../../components/Common/MenuDerFacturaVenta";
import {FacturaVentaProvider} from '../views/Main/Gestion/Facturas/facturaventa-context'
import MaintenanceModeComponent from "../../components/Common/MaintenanceModeComponent";

export default ({ children }) => {   
  const maintenanceMode = children?.props?.children[0]?.key === 'true'

  return (
    <>
    {maintenanceMode && (
      <MaintenanceModeComponent />
    )}
    {!maintenanceMode && (
      <FacturaVentaProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children}
        <MenuDerFacturaVenta />
      </div>
    </div>
    </FacturaVentaProvider>
    )}
    </>
  );    
};
/*
return (
    <FacturaVentaProvider>
    <div style={{maxHeight: "99vh", minHeight: "99vh"}}>
      <Nav />
      <div className="row min-vh-90 ml-0 mr-0">
        <Menuizq />
        {children }
        <MenuDerFacturaVenta />
      </div>
      
    </div>
    </FacturaVentaProvider>
  );
  */
