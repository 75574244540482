import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { StateContext } from '../../context'
import Agenda from './Agenda';
import Axios from "axios";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      color: '#505050'
    },
    subheading1: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        marginLeft: 10,
        color: '#505050'
    },
    subheading2: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
        marginLeft: 20,        
        color: '#9c9c9c'
    },
    cabecera: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
  
}));

const calculaTotalCPE = (operacion) => {    

    let salariosCPE = 0
    let primasCPE = 0
    let incrementoPrimasCPE = 0
    let plusesCPE = 0
    let complementosCPE = 0
    let aportacionCPE = 0
    let otrosGastos = 0
    let fasv = 0
    let seguridadSocial = 0

    if(operacion){ 
        salariosCPE = 0
        primasCPE = 0
        incrementoPrimasCPE = 0
        plusesCPE = 0
        complementosCPE = 0
        aportacionCPE = 0
        otrosGastos = 0
        fasv = 0
        seguridadSocial = 0
                    
        if(typeof operacion.listadoPersonal !== "undefined"){
            operacion.listadoPersonal.forEach(
                function(a){        
                    salariosCPE += parseFloat(a.costes.totalSalario);
                    primasCPE += parseFloat(a.costes.totalPrimas); // + parseFloat(a.costes.totalIncrementoPrima);
                    incrementoPrimasCPE += parseFloat(a.costes.totalIncrementoPrima);
                    plusesCPE += parseFloat(a.costes.totalPluses);
                    complementosCPE += parseFloat(a.costes.diferido);
                    aportacionCPE += parseFloat(a.costes.antiguedadDirecta);
                    otrosGastos += parseFloat(a.costes.otrosGastos);
                    fasv += parseFloat(a.costes.fasv);
                    seguridadSocial += parseFloat(a.costes.seguridadSocial);
                });            
        }        

        if(typeof operacion.CPEEspecialidades !== "undefined"){
            operacion.CPEEspecialidades.forEach(                
                function(a){    
                    a.forEach(
                        function(b){             
                            if(typeof operacion.listadoPersonal !== "undefined"){
                                let operacionesInterseccion = operacion.listadoPersonal.filter(                                
                                        n => b.Id === n.Id                                
                                ) 
                                if(operacionesInterseccion.length > 0){return}
                            }   
                                                                                             
                            salariosCPE += parseFloat(b.TotalSalario)+parseFloat(b.TotalHorasExtra);
                            primasCPE += parseFloat(b.TotalPrimas); // + parseFloat(b.TotalIncrementoPrima);
                            incrementoPrimasCPE += parseFloat(b.TotalIncrementoPrima);
                            plusesCPE += parseFloat(b.TotalPuses);
                            complementosCPE += parseFloat(b.Diferido);
                            aportacionCPE += parseFloat(b.AntiguedadDirecta);
                            otrosGastos += parseFloat(b.OtrosGastos);
                            fasv += parseFloat(b.Fasv);
                            seguridadSocial += parseFloat(b.SeguridadSocial);
                        }
                    )}                    
            );            
        }        
    }

    let ajusteEmpresaTotal= (salariosCPE + primasCPE + incrementoPrimasCPE + plusesCPE + complementosCPE + aportacionCPE + otrosGastos + fasv + seguridadSocial)*4/100
   
    return {
        salariosCPE: salariosCPE.toFixed(2),
        primasCPE: primasCPE.toFixed(2),
        incrementoPrimasCPE: incrementoPrimasCPE.toFixed(2),
        plusesCPE: plusesCPE.toFixed(2),
        complementosCPE: complementosCPE.toFixed(2),
        aportacionCPE: aportacionCPE.toFixed(2),
        otrosGastos: otrosGastos.toFixed(2),
        fasv: fasv.toFixed(2),
        seguridadSocial: seguridadSocial.toFixed(2),
        ajusteEmpresa: ajusteEmpresaTotal.toFixed(2),
        totalCPE: (salariosCPE + primasCPE + incrementoPrimasCPE + plusesCPE + complementosCPE + aportacionCPE + otrosGastos + fasv + seguridadSocial + ajusteEmpresaTotal).toFixed(2)
    }

}

const calculaTotalProveedores = (operacion) => {    
    let CosteProveedores = 0
    if(operacion){
        if(typeof operacion.Proveedores !== "undefined"){
            operacion.Proveedores.forEach(
                function(a){                        
                    CosteProveedores += parseFloat(a.Coste)
                }
            )
        }            
    }    
    return CosteProveedores.toFixed(2);
}

const calculaTotalGesport = (operacion) => {    

    let personalGesport = 0
    let maquinariaGesport = 0
    let consumiblesGesport = 0
    let escoltaGesport = 0

    if(operacion){
        if(typeof operacion.GesportListado !== "undefined"){
            operacion.GesportListado.forEach(                
                function(a){                            
                    a.forEach(
                        function(b){         
                            switch (b.Tipo_Recurso){
                                case "empleado":
                                    personalGesport += parseFloat(b.Coste) * parseFloat(b.Cantidad)
                                    break;
                                case "maquinaria":
                                    maquinariaGesport += parseFloat(b.Coste) * parseFloat(b.Cantidad)
                                    break;
                                case "consumible":
                                    consumiblesGesport += parseFloat(b.Coste) * parseFloat(b.Cantidad)
                                    break;
                                case "escolta":
                                    escoltaGesport += parseFloat(b.Coste) * parseFloat(b.Cantidad)
                                    break;                                
                                default: 
                                    break;
                            }
                        }
                    )  
                }
            )
        }            
    } 

    let totalGesport = personalGesport + maquinariaGesport + consumiblesGesport + escoltaGesport
    
    return {
        totalGesport: totalGesport.toFixed(2),
        personalGesport: personalGesport.toFixed(2),
        maquinariaGesport: maquinariaGesport.toFixed(2),
        consumiblesGesport: consumiblesGesport.toFixed(2),
        escoltaGesport: escoltaGesport.toFixed(2)
    }
}


const MenuDerOperaciones = (props) => {
    let { id } = useParams(); 
    
    const {state, dispatch} = useContext(StateContext)
    const { operacion } = state    
    const [operaciones, setOperaciones] = useState();
    const [ventas, setVentas] = useState(0)
    
    const { isAuthUser } = props.reducerApi;
    const classes = useStyles();    

    let fecha = new Date();
    let meses = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
    let fechaTexto = fecha.getDate()+" "+ meses[fecha.getMonth()] + " " + fecha.getFullYear();
       

    useEffect(() => {
        setOperaciones(operacion)    
    },[])

    useEffect(() => {
        setOperaciones(operacion)  
        calculaTotalVentas(id)    
    },[operacion])

    useEffect(() => {
        //console.log(operaciones)            
    },[operaciones])

    const calculaTotalVentas = async (id) => {
        if(id){
            const fetchData = async () => {
                const respOportunidad = await Axios(`${process.env.REACT_APP_API_URL}operaciones/calculaventasoperativa/${id}`);   
                setVentas(respOportunidad.data);
              };
              fetchData();  
        }
    }
    
    const TOTALCPE = calculaTotalCPE(operaciones)  
    const totalProveedor = calculaTotalProveedores(operaciones) 
    const totalGesport = calculaTotalGesport(operaciones)    
    const totalCostes = (parseFloat(TOTALCPE.totalCPE) + parseFloat(totalGesport.totalGesport) + parseFloat(totalProveedor)).toFixed(2)
    
    const beneficio = ventas-parseFloat(totalCostes);
    const porcentajerBeneficio = (ventas !== 0 ? beneficio/ventas*100 : 0)
    
    if (isAuthUser) {
        return (
          <>          
          <div className="col col-menu-der p-0 pl-2 pr-2">
            <Agenda />
            <div className="col p-0 pl-0 pr-0 col-menu-der-recursos">          

                {/*COSTES*/}
                <div className="bg-cuerpo p-10px mt-2">
                    <div className="bg-white p-10px">
                    
                        <div className={classes.cabecera}>
                            <Typography className={classes.heading}>COSTES TOTALES</Typography>
                            <Typography className={classes.heading}>{totalCostes} €</Typography>                    
                        </div>
                        <hr></hr>
                        
                        <div className="p-1 bg-white cuerpo-menu-der">
                            <div className={`${classes.cabecera} mb-1`}>
                                <Typography className={classes.subheading1}>CPE</Typography>
                                <Typography className={classes.subheading1}>{TOTALCPE.totalCPE} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Salarios</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.salariosCPE} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Primas</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.primasCPE} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Incremento primas</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.incrementoPrimasCPE} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Pluses</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.plusesCPE} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Colectivos</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.complementosCPE} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Aportación empresa</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.aportacionCPE} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Otros Gastos</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.otrosGastos} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-FASV</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.fasv} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Seguridad Social</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.seguridadSocial} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Ajuste de empresa</Typography>
                                <Typography className={classes.subheading2}>{TOTALCPE.ajusteEmpresa} €</Typography>
                            </div>

                            <div className={`${classes.cabecera} mt-4 mb-1`}>
                                <Typography className={classes.subheading1}>GESPORT</Typography>
                                <Typography className={classes.subheading1}>{totalGesport.totalGesport} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Personal</Typography>
                                <Typography className={classes.subheading2}>{totalGesport.personalGesport} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Maquinaria</Typography>
                                <Typography className={classes.subheading2}>{totalGesport.maquinariaGesport} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Consumibles</Typography>
                                <Typography className={classes.subheading2}>{totalGesport.consumiblesGesport} €</Typography>
                            </div>
                            <div className={classes.cabecera}>
                                <Typography className={classes.subheading2}>-Escolta</Typography>
                                <Typography className={classes.subheading2}>{totalGesport.escoltaGesport} €</Typography>
                            </div>

                            <div className={`${classes.cabecera} mt-4`}>
                                <Typography className={classes.subheading1}>PROVEEDORES</Typography>
                                <Typography className={classes.subheading1}>{totalProveedor} €</Typography>
                            </div>   
                            
                            {operacion && operacion.Proveedores && operacion.Proveedores.map((element, index) => (
                                <React.Fragment key={index}>
                                    <div className={classes.cabecera}>
                                        <Typography className={classes.subheading2}>-{element.Descripcion}</Typography>
                                        <Typography className={classes.subheading2}>{element.Coste} €</Typography>
                                    </div>      
                                </React.Fragment>
                            )
                            )}  
                            
                            {/* INGRESOS Y BENEFICIOS */}
                            <div className="bg-white p-10px">
                            <hr></hr>
                                <div className={classes.cabecera}>
                                    <Typography className={classes.heading}>INGRESOS TOTALES</Typography>
                                    <Typography className={classes.heading}>{ventas.toFixed(2)} €</Typography>                    
                                </div>
                                <hr></hr>
                                <div className={classes.cabecera}>
                                    <Typography className={classes.heading}>BENEFICIO</Typography>
                                    <Typography className={classes.heading}>{beneficio.toFixed(2)} €</Typography> 
                                    <Typography className={classes.heading}>{porcentajerBeneficio.toFixed(2)} %</Typography>
                                                      
                                </div>
                                <hr></hr>
                            </div>
                                                
                        </div>


                    </div>
                </div>

            </div>
          </div>          
          </>
        )
    } else {
        return  null
    }
}

export default connect(({ reducerApi }) => ({ reducerApi }))(MenuDerOperaciones);
