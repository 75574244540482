import React from "react";
import RecursosManoDetalleOtrosDesgloce from "./RecursosManoDetalleOtrosDesgloce"

const getCosteTotal = (datosJornadas) => {    
    let consumibles = []
    let herramientas = []
    let otros = []

    if(datosJornadas && datosJornadas.length > 0)
    {
        consumibles = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Consumible propio")
        herramientas = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Herramientas") 
        otros = datosJornadas.filter(datosJornada => datosJornada.elementos.CategoriaPadre === "Otros gastos")   
    }

    let costeTotal = 0;
    let costeTotalConsumibles = 0;
    let costeTotalHerramientas = 0;
    let costeTotalOtros = 0;
    let unidadesTotalConsumibles=0;
    let unidadesTotalHerramientas=0;
    let unidadesTotalOtros=0;

    consumibles.forEach(consumibe => {
        costeTotal += parseFloat(consumibe.elementos.Coste) * parseInt(consumibe.elementos.cantidad)
        costeTotalConsumibles += parseFloat(consumibe.elementos.Coste) * parseInt(consumibe.elementos.cantidad)
        unidadesTotalConsumibles += parseInt(consumibe.elementos.cantidad)
    }); 
    
    herramientas.forEach(herramienta => {
        costeTotal += parseFloat(herramienta.elementos.Coste) * parseInt(herramienta.elementos.cantidad)
        costeTotalConsumibles += parseFloat(herramienta.elementos.Coste) * parseInt(herramienta.elementos.cantidad)
        unidadesTotalConsumibles += parseInt(herramienta.elementos.cantidad)
    }); 

    otros.forEach(otro => {
        costeTotal += parseFloat(otro.elementos.Coste) * parseInt(otro.elementos.cantidad)
        costeTotalOtros += parseFloat(otro.elementos.Coste) * parseInt(otro.elementos.cantidad)
        unidadesTotalOtros += parseInt(otro.elementos.cantidad)
    });   

    return {
        costeTotal: costeTotal, 
        costeTotalConsumibles: costeTotalConsumibles,
        costeTotalHerramientas: costeTotalHerramientas,
        costeTotalOtros: costeTotalOtros,
        unidadesTotalConsumibles: unidadesTotalConsumibles,
        unidadesTotalHerramientas: unidadesTotalHerramientas, 
        unidadesTotalOtros: unidadesTotalOtros       
    }
}

const RecursosManoDetalleOtrosCabecera = ({manos, datosJornadas}) => 
{ 
    const options2 = { style: 'currency', currency: 'EUR' };
    const numberFormat2 = new Intl.NumberFormat('es-ES', options2);

    const costeTotalAgrupado = getCosteTotal(datosJornadas)
    const costeTotal = numberFormat2.format(costeTotalAgrupado.costeTotal)    
   
    return (
        <>
        <div className="col-12 mt-4 d-flex overflow-auto justify-content-center">
            <span className="col-11 float-left">Otros costes</span><span className="col-1 float-right">{costeTotal}</span>            
        </div>
        <hr style={{height:1, width:'100%'}} className="m-0 bg-comercial mb-4" />

        {manos &&  manos.map((mano, index) => (
            <React.Fragment key={index}>
            <div className="borderFormulario">
                <div className="col-12 d-flex overflow-auto justify-content-center borderFormulario mb-4">
                    <div className="col-1 float-left">Jornada </div>
                    <div className="col-5 float-right">{mano.Jornada}</div>
                    <div className="col-3 float-left"></div>
                    <div className="col-1 float-left">Mano </div>
                    <div className="col-2 float-right">{mano.Mano}</div>
                </div>

                <RecursosManoDetalleOtrosDesgloce 
                    datosJornadas={datosJornadas}
                    mano = {mano.Mano}              
                />
                
            </div>
            </React.Fragment>
        ))}   
        </>
    )

}

export default RecursosManoDetalleOtrosCabecera;